import React from 'react'
import PlantillaSimpleEmarsys from 'components/Emarsys/Recomendation'
import MenuRecomendationEmarsys from 'components/Emarsys/MenuRecomendation'
import Slider from 'react-slick'
import { emarsysProductAdapter } from 'adapters/products_Adapter'


// Container type Grid
export function GridContainerProducts(props) {
    const {
        title,
        typeView,
        btnType,
        addRecomend,
        recomendProduct
    } = props

    const addRecomendData = () => { addRecomend() }

    return (
        <div className="container contRecomendation">
            <h2>{title}</h2>
            {/*title && <div className="borderRed"></div>*/}
            <div className="contProductoRecomendGrid">
                <div className={`productScrollContent ${typeView}`}>
                    {recomendProduct?.map(({ item, c_descuento, image, price, msrp, title, link, category, brand, c_tienda, c_reviewsscore, c_reviewstotal, shipping_price, c_fulfillment, c_t1_months, c_t1_price, c_telmex_months, c_telmex_price }, index) =>
                        <PlantillaSimpleEmarsys
                            key={item}
                            item={item}
                            c_descuento={c_descuento}
                            image={image}
                            price={price}
                            msrp={msrp}
                            title={title}
                            link={link}
                            //brand={c_tienda}
                            brand={brand}
                            categoriesEmar={category}
                            review={c_reviewsscore}
                            comments={Number(c_reviewstotal)}
                            shipping_price={shipping_price}
                            fulfillment={c_fulfillment}
                            clasee={typeView}
                            creditCardMonths={c_t1_months}
                            creditCardPrice={c_t1_price}
                            telmexMonths={c_telmex_months}
                            telmexPrice={c_telmex_price}
                            index={index}
                            productTotal={emarsysProductAdapter(recomendProduct[index])}
                        />
                    )}
                </div>
            </div>
            { btnType === 'add' && <div className="btn rojo plus" onClick={addRecomendData}>Ver más</div>}
        </div>
    )
}

// Container type Block
export function BlockContainerProducts(props) {
    const {
        title,
        typeView,
        btnType,
        addRecomend,
        recomendProduct
    } = props

    const addRecomendData = () => { addRecomend() }

    return (
        <div className="container contRecomendation">
            <h2>{title}</h2>
            {/*title && <div className="borderRed"></div>*/}
            <div className="contProductoRecomend">
                <div className={`productScrollContent ${typeView}`}>
                    {recomendProduct.map(({ item, c_descuento, image, price, msrp, title, link, category, brand, c_tienda, c_reviewsscore, c_reviewstotal, shipping_price, c_fulfillment, c_t1_months, c_t1_price, c_telmex_months, c_telmex_price }, index) =>
                        <PlantillaSimpleEmarsys
                            key={item}
                            item={item}
                            c_descuento={c_descuento}
                            image={image}
                            price={price}
                            msrp={msrp}
                            title={title}
                            link={link}
                            //brand={c_tienda}
                            brand={brand}
                            categoriesEmar={category}
                            review={c_reviewsscore}
                            comments={Number(c_reviewstotal)}
                            shipping_price={shipping_price}
                            fulfillment={c_fulfillment}
                            clasee={typeView}
                            creditCardMonths={c_t1_months}
                            creditCardPrice={c_t1_price}
                            telmexMonths={c_telmex_months}
                            telmexPrice={c_telmex_price}
                            index={index}
                            productTotal={emarsysProductAdapter(recomendProduct[index])}
                        />
                    )}
                </div>
            </div>
            { btnType === 'add' && <div className="btn rojo plus" onClick={addRecomendData}>Ver más</div>}
        </div>
    )
}

// Container type Slider
export function SliderContainerProducts(props) {
    const {
        title,
        typeView,
        btnType,
        addRecomend,
        recomendProduct
    } = props

    //console.log(recomendProduct)

    const settings = {
        infinite: false,
        arrows: true,
        dots: false,
        speed: 500,
        swipe: true,
        touchMove: true,
        centerPadding: "60px",
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        accessibility: false,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            }
        ]
    };

    const addRecomendData = () => { addRecomend() }

    return (
        <div className="container contRecomendation">
            <h2>{title}</h2>
            {/*title && <div className="borderRed"></div>*/}
            <div className="contProductoRecomend">
                <div className={`productContent ${typeView}`}>
                    <Slider {...settings}>{
                        recomendProduct?.map(({ item, c_descuento, image, price, msrp, title, link, category, brand, c_tienda, c_reviewsscore, c_reviewstotal, shipping_price, c_fulfillment, c_t1_months, c_t1_price, c_telmex_months, c_telmex_price }, index) =>
                            <PlantillaSimpleEmarsys
                                key={item}
                                item={item}
                                c_descuento={c_descuento}
                                image={image}
                                price={price}
                                msrp={msrp}
                                title={title}
                                link={link}
                                //brand={c_tienda}
                                brand={brand}
                                categoriesEmar={category}
                                review={c_reviewsscore}
                                comments={Number(c_reviewstotal)}
                                shipping_price={shipping_price}
                                fulfillment={c_fulfillment}
                                creditCardMonths={c_t1_months}
                                creditCardPrice={c_t1_price}
                                telmexMonths={c_telmex_months}
                                telmexPrice={c_telmex_price}
                                index={index}
                                productTotal={emarsysProductAdapter(recomendProduct[index])}
                            />
                        )}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

// Container type Scroll
export function ScrollContainerProducts(props) {
    const {
        title,
        typeView,
        btnType,
        addRecomend,
        recomendProduct
    } = props

    const addRecomendData = () => { addRecomend() }

    return (
        <div className="container contRecomendation">
            <h2>{title}</h2>
            {/*title && <div className="borderRed"></div>*/}
            <div className="contProductoRecomend">
                <div className={`productScrollContent ${typeView}`}>
                    {recomendProduct?.map(({ item, c_descuento, image, price, msrp, title, link, category, brand, c_tienda, c_reviewsscore, c_reviewstotal, shipping_price, c_fulfillment, c_t1_months, c_t1_price, c_telmex_months, c_telmex_price }, index) =>
                        <PlantillaSimpleEmarsys
                            key={item}
                            item={item}
                            c_descuento={c_descuento}
                            image={image}
                            price={price}
                            msrp={msrp}
                            title={title}
                            link={link}
                            //brand={c_tienda}
                            brand={brand}
                            categoriesEmar={category}
                            review={c_reviewsscore}
                            comments={Number(c_reviewstotal)}
                            shipping_price={shipping_price}
                            fulfillment={c_fulfillment}
                            creditCardMonths={c_t1_months}
                            creditCardPrice={c_t1_price}
                            telmexMonths={c_telmex_months}
                            telmexPrice={c_telmex_price}
                            index={index}
                            productTotal={emarsysProductAdapter(recomendProduct[index])}
                        />
                    )}
                </div>
            </div>
            { btnType === 'add' && <div className="btn rojo plus" onClick={addRecomendData}>Ver más</div>}
        </div>
    )
}


// Container type Recomendation
export function RecomendationContainerProducts(props) {
    const {
        title,
        typeView,
        btnType,
        addRecomend,
        recomendProduct,
        howViewClass,
        changeRecomend,
        showMoreRecomend,
        gadget
    } = props


    const addRecomendData = () => { addRecomend() }

    const showMoreRecomendData = () => { showMoreRecomend() }

    // boton para agregar mas productos
    const changeRecomendData = (val) => { changeRecomend(val) }

    return (
        <div className="container contRecomendation">
            <h2>{title}</h2>
            {/*title && <div className="borderRed"></div>*/}
            <MenuRecomendationEmarsys changeMenu={changeRecomendData} gadget={gadget} />
            <div className="contProductoRecomend">
                <div className={`productScrollContent ${typeView} ${howViewClass}`}>
                    {recomendProduct?.map(({ item, c_descuento, image, price, msrp, title, link, category, brand, c_tienda, c_reviewsscore, c_reviewstotal, shipping_price, c_fulfillment, c_t1_months, c_t1_price, c_telmex_months, c_telmex_price }, index) =>
                        <PlantillaSimpleEmarsys
                            key={item}
                            item={item}
                            c_descuento={c_descuento}
                            image={image}
                            price={price}
                            msrp={msrp}
                            title={title}
                            link={link}
                            //brand={c_tienda}
                            brand={brand}
                            categoriesEmar={category}
                            review={c_reviewsscore}
                            comments={Number(c_reviewstotal)}
                            shipping_price={shipping_price}
                            fulfillment={c_fulfillment}
                            typeCard={'recomendation'}
                            creditCardMonths={c_t1_months}
                            creditCardPrice={c_t1_price}
                            telmexMonths={c_telmex_months}
                            telmexPrice={c_telmex_price}
                            index={index}
                            productTotal={emarsysProductAdapter(recomendProduct[index])}
                        />
                    )}
                </div>
            </div>
            {btnType === 'show'
                ?
                <div className="btn rojo" onClick={showMoreRecomendData}>Ver más</div>
                : null
            }
            {btnType === 'add'
                ?
                <div className="btn rojo" onClick={addRecomendData}>Ver más</div>
                : null
            }
        </div>
    )
}