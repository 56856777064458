import React, { useState, useEffect } from 'react';
import { getPedido, LoginAyudaDinamica } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import './ayudaDinamicaDesktop.sass';
import { LABEL_PROCESO_RECOLECCION, LABEL_EN_CAMINO } from 'constants/general';
import Loading from 'components/Loading';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { removeDuplicates } from 'functions/removeDuplicates';
import { USERNAME, PASSWORD, PUBLICKEY } from 'constants/general';
import { useStorage64 } from 'hooks/useStorageBase64';

export function SectionProcesoRecoleccionEnCaminoDesktop(props) {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [, setEstatusPedido] = useState(0);
    const [statusProducto, setStatusProducto] = useState(0);
    const [saveStatusProducto, setSaveStatusProducto] = useStorage64('saveStatusProducto', '');
    const [loading, setLoading] = useState(false);
    let urlAlert = "/img/alerta.png";
    let urlLogo = "/img/claroshop.svg";
    const [ima, setima] = useState([]);

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    // useEffect(() => {
    //     if (resultadoPedido && resultadoPedido.pedido) {
    //         setLoading(false);
    //     }
    //     else {
    //         setLoading(true);
    //     }
    // }, [resultadoPedido])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // obtiene servicio pedido
    // useEffect(() => {
    //     getPedido(idPedido)
    //         .then(setResultadoPedido)
    // }, [])

    // useEffect(() => {
    //     let productoStatus = [];
    //     let imagenProducto = [];

    //     if (resultadoPedido) {
    //         if (resultadoPedido.pedido) {
    //             // recorre por producto
    //             if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {
    //                 if (resultadoPedido.pedido[0]) {
    //                     setEstatusPedido(resultadoPedido.pedido[0].axxi.status_axxi_id)
    //                     resultadoPedido.pedido[0].products.map((elem, i) =>
    //                         Number(elem.related_product_id) === Number(idProductoEstate) && productoStatus.push({ "status": elem.status })
    //                     )
    //                     setStatusProducto(productoStatus[0].status);

    //                     resultadoPedido.pedido[0].products.map((elem, i) =>
    //                         Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem.images[0].url, "id": elem.related_product_id })
    //                     )
    //                     let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
    //                     setima(limpioImagen);
    //                 }
    //             }
    //         }
    //         else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
    //             LoginAyudaDinamica({ username: USERNAME, password: PASSWORD, publicKey: PUBLICKEY })
    //             setTimeout(() => {
    //                 getPedido()
    //                     .then(setResultadoPedido)
    //             }, 1000);
    //         }
    //     }
    // }, [resultadoPedido])

    // regresar a pagina pedidos
    const RegresarPedidos = () => {
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    const handleBack = () => {
        history.goBack(-1);
    }

    return (
        <>
            {loading && <Loading />}

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="miCuentaContainer">
                    <div className="containerAD">
                        <div className="boxBtnRegresarDktop">
                            <div className="icoBack" onClick={handleBack}></div>
                            <button className="btnBackAD" onClick={handleBack}>Atrás</button>
                        </div>


                        <div className="boxGeneralAD">
                            {/* <div className="boxLeftAD">
                                <div className="contSectionRed">
                                    <div className={ima.length > 1 ? "imaDesktop more" : "imaDesktop"}>
                                        {ima.length > 0 && ima.map((elemento, i) => i <= 3 && <img key={i} src={elemento.url} alt={"imagen" + i} width={70} />)}
                                    </div> */}

                                    {/* <div className="textDektop"> <p>Rechaza la entrega</p> </div> */}

                                {/* </div>
                            </div> */}

                            <div className="boxRightAD marginAD">
                                <div className="boxCancelacionExitosa">
                                    <div className="boxContenido sinMarginTop">
                                        {/* bloque pedido en proceso de recolección */}
                                        {LABEL_PROCESO_RECOLECCION === String(saveStatusProducto).toLowerCase() &&
                                            <>
                                                <img alt="check-eliminado" src={urlAlert} />
                                                <h3>La mensajería ya va en camino por tu producto, el envío ya no puede ser detenido. Por favor rechaza la entrega cuando llegue a tu domicilio.</h3>
                                            </>
                                        }

                                        {/* bloque pedido en camino */}
                                        {LABEL_EN_CAMINO === String(saveStatusProducto).toLowerCase() &&
                                            <>
                                                <img alt="check-eliminado" src={urlAlert} />
                                                <h3>El producto ya se encuentra en camino, por favor rechaza la entrega cuando llegue a tu domicilio.</h3>
                                            </>
                                        }

                                        <div className="boxBtnRegresar">
                                            <button className="btnRegresar" onClick={RegresarPedidos}>Regresar a mis pedidos</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </main>
        </>
    )
}