import React from "react";

export function HomeSkeletonQuickA(props) {
  const { position } = props;

  const cantidadItems = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];

  return (
    <>
      <section className={`homePosition${position}`}>
        <div className="contentQuickAccess ">
          <div className="container">
            <div className="quickAccess quickMovil skeleton">
              {cantidadItems
                ? cantidadItems.map(({}, index) => (
                    <span className="aSkeletonQA" key={index}>
                      <div className="img bones" width="78" height="78" />
                      <p className="h4 bones"></p>
                    </span>
                  ))
                : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default React.memo(HomeSkeletonQuickA);
