import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.sass';

export default function BoxLegal() {

    const [menuView, setMenuView] = useState(false);

    const toggleClass = () => {
        setMenuView(!menuView);
    };

    return (
        <div className="boxClaroShop">
            <p onClick={toggleClass} className={menuView ? 'active' : null}>Legal</p>
            <ul>
                <li><Link to={"/terminos-condiciones/"}>Términos y Condiciones</Link></li>
                <li><Link to={"/aviso-privacidad/"}>Aviso de privacidad</Link></li>
            </ul>
        </div>
    )
}
