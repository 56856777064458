import React, { Fragment } from 'react'
import '../style.sass'
import { Link } from "react-router-dom"
import Slider from 'react-slick'
import ProgressiveImage from 'react-progressive-graceful-image'
import { repareImage, repareTitleSEO } from 'functions/repareDataService'
import { IMAGE_THUMB_HOME, SIZE_IMAGE_THUMB_BANNER } from 'constants/general'



//- slider Actual de la aplicacion Desktop Home V1
export function AppVersionSlider({sliders}){
    
    const settings = {
        dots: false,
        autoplay: true,
        infinite: true,
        centerMode: false,//true,
        swipe: true,
        touchMove: true,
        centerPadding: "60px",
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 760,
                settings: {
                    autoplay: true,
                    infinite: true,
                    centerMode: true,
                    swipe: true,
                    touchMove: true,
                    centerPadding: "60px",
                    slidesToShow: 1
                }
            }
        ]
    };

    return(
        <div className="contSlider sliderPrincipal">
            <div className="container contSlideP">
                    {sliders.length !== 0
                        ?
                            <Slider {...settings}>
                                {
                                    sliders?.map( ({nombre, enlace, contenido, enlaceWebApp}) =>
                                        // <Link to={enlace = enlace.replace(`${VARIANTS_PER_PORTAL.domain}`,'')} title={nombre} key={enlaceWebApp} className="cardSliderHome">
                                        <Link to={`${enlaceWebApp}/`} title={nombre} key={enlaceWebApp} className="cardSliderHome">
                                            <img loading="lazy" src={repareImage(contenido)} alt={nombre} width="595" height="335" importance="low"/>
                                            <p>{nombre}</p>
                                        </Link>
                                    )
                                }
                            </Slider>
                        : null
                    }

            </div>
        </div>
    )
}

//- slider Actual de la aplicacion Desktop Home V2
export function AppVersionSliderNew({sliders}){

    // console.log('sliders ->', sliders)
    const settings = {
        dots: false,
        autoplay: true,
        infinite: true,
        centerMode: false,//true,
        swipe: true,
        touchMove: true,
        centerPadding: "60px",
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 760,
                settings: {
                    autoplay: true,
                    infinite: true,
                    centerMode: true,
                    swipe: true,
                    touchMove: true,
                    centerPadding: "60px",
                    slidesToShow: 1
                }
            }
        ]
    };

    return(
        <div className="contSlider sliderPrincipal">
            <div className="container contSlideP">
                {sliders.length !== 0
                    ?
                        <Slider {...settings}>
                            {
                                sliders?.map( ({name, enlace, image, link, id},ind) =>
                                    // <Link to={enlace = enlace.replace(`${VARIANTS_PER_PORTAL.domain}`,'')} title={name} key={link} className="cardSliderHome">
                                    <Fragment key={id}>
                                        {link?.split('/')[1] === "c"
                                            ? 
                                                <a href={`${link}`} title={name}  className="cardSliderHome">
                                                    {IMAGE_THUMB_HOME
                                                        ?
                                                            <ProgressiveImage
                                                                src={`${repareImage(image).replace('publicidad', 'mkt')}?scale=1800`}
                                                                placeholder={`${repareImage(image).replace('publicidad', 'mkt')}?scale=${SIZE_IMAGE_THUMB_BANNER}`}
                                                            >
                                                                {(src) => <img
                                                                    loading={ind === 0 ? "eager" : "lazy"}
                                                                    src={src}
                                                                    alt={name}
                                                                    width="1500"
                                                                    height="700"
                                                                    decoding="async"
                                                                    fetchpriority={ind === 0 ? "high" : "low"}
                                                                    />
                                                                }
                                                            </ProgressiveImage>
                                                        :
                                                            <img
                                                                loading={ind === 0 ? "eager" : "lazy"}
                                                                src={`${repareImage(image).replace('publicidad', 'mkt')}?scale=1800`}
                                                                alt={name}
                                                                width="1500"
                                                                height="700"
                                                                decoding="async"
                                                                fetchpriority={ind === 0 ? "high" : "low"}
                                                            />
                                                    }
                                                    <p>{name}</p>
                                                </a>
                                            :
                                                <Link to={`${link?.replace('/l/', '/landing/').replace('/p/', '/landing/').replace(/\/$/g, '')}/`} title={name} className="cardSliderHome">
                                                    {IMAGE_THUMB_HOME
                                                        ?
                                                            <ProgressiveImage
                                                                src={`${repareImage(image).replace('publicidad', 'mkt')}?scale=1500`}
                                                                placeholder={`${repareImage(image).replace('publicidad', 'mkt')}?scale=${SIZE_IMAGE_THUMB_BANNER}`}
                                                            >
                                                                {(src) => <img
                                                                    loading={ind === 0 ? "eager" : "lazy"}
                                                                    src={src}
                                                                    alt={name}
                                                                    width="1500"
                                                                    height="700"
                                                                    decoding="async"
                                                                    fetchpriority={ind === 0 ? "high" : "low"}
                                                                    />
                                                                }
                                                            </ProgressiveImage>
                                                        :
                                                            <img
                                                                loading={ind === 0 ? "eager" : "lazy"}
                                                                src={`${repareImage(image).replace('publicidad', 'mkt')}?scale=600`}
                                                                alt={name}
                                                                width="1500"
                                                                height="700"
                                                                decoding="async"
                                                                fetchpriority={ind === 0 ? "high" : "low"}
                                                            />
                                                    }
                                                    <p>{name}</p>
                                                </Link>
                                        }
                                    </Fragment>
                                )
                            }
                        </Slider>
                    : null
                }
            </div>
        </div>
    )
}

//- slider Actual de la aplicacion movil Home V1
export function AppVersionSliderMobile({sliders}){
    const settings = {
        dots: false,
        autoplay: true,
        infinite: true,
        centerMode: true,
        swipe: true,
        touchMove: true,
        centerPadding: "60px",
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    autoplay: true,
                    infinite: true,
                    centerMode: false,
                    swipe: true,
                    touchMove: true,
                    centerPadding: "60px",
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 680,
                settings: {
                    autoplay: true,
                    infinite: true,
                    centerMode: false,
                    swipe: true,
                    touchMove: true,
                    //centerPadding: "60px",
                    slidesToShow: 1
                }
            }
        ]
    };

    return(
        <div className="contSlider sliderPrincipal">
            <div className="container contSlideP">
                {sliders.length !== 0
                    ?
                        <Slider {...settings}>
                            {
                                sliders?.map( ({nombre, enlace, contenido, enlaceWebApp}) =>
                                    // <Link to={enlace = enlace.replace(`${VARIANTS_PER_PORTAL.domain}`,'')} title={nombre} key={enlaceWebApp} className="cardSliderHome">
                                    <Link to={`${enlaceWebApp}/`} title={nombre} key={enlaceWebApp} className="cardSliderHome">
                                        <img loading="lazy" src={repareImage(contenido)} alt={nombre} width="595" height="335" importance="low"/>
                                        <p>{nombre}</p>
                                    </Link>
                                )
                            }
                        </Slider>
                    : null
                }
            </div>
        </div>
    )
}

//- slider Actual de la aplicacion movil Home V2
export function AppVersionSliderMobileNew(props){
    const {
        sliders,
        styleView
    } = props

    const settings = {
        dots: false,
        arrows: false,
        autoplay: true,
        infinite: true,
        centerMode: true,
        swipe: true,
        touchMove: true,
        centerPadding: "60px",
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    autoplay: true,
                    infinite: true,
                    centerMode: false,
                    swipe: true,
                    touchMove: true,
                    centerPadding: "60px",
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 680,
                settings: {
                    autoplay: true,
                    infinite: true,
                    centerMode: true,
                    swipe: true,
                    touchMove: true,
                    centerPadding: "30px",
                    slidesToShow: 1
                }
            }
        ]
    };

    return(
        <div className="contSlider sliderPrincipal">
            <div className="container contSlideP">
                {sliders.length !== 0
                    ?
                        <Slider {...settings}>
                            {
                                sliders?.map( ({name, image, link, status}, ind) =>
                                    // <Link to={enlace = enlace.replace(`${VARIANTS_PER_PORTAL.domain}`,'')} title={name} key={link} className="cardSliderHome">
                                    status === true &&
                                        <Fragment key={ind}>
                                            {typeof(link) !== 'function'
                                                ?
                                                    link?.split('/')[1] === "c"
                                                        ? 
                                                            <a href={`${link}`} title={name} className="cardSliderHome">
                                                                <img loading="lazy" src={repareImage(image)} alt={name} width="595" height="335" importance="low"/>
                                                                <p>{name}</p>
                                                            </a>
                                                        :
                                                            <Link to={`${link?.replace('/l/', '/landing/').replace('/p/', '/landing/').replace(/\/$/g, '')}/`} title={name} className="cardSliderHome">
                                                                <img loading="lazy" src={repareImage(image)} alt={name} width="595" height="335" importance="low"/>
                                                                <p>{name}</p>
                                                            </Link>
                                                : null
                                            }
                                        </Fragment>
                                )
                            }
                        </Slider>
                    : null
                }
            </div>
        </div>
    )
}

//- Slider Principal para moviles, 3 imagenes
export function OldVersionSlider({sliders}){
    return(
        <div className="contSlider">
            <div className="container oldVersionSlider">
                {sliders.length !== 0
                    ?
                    sliders?.map( ({nombre, enlace, contenido, enlaceWebApp}, ind) =>
                    <Fragment  key={enlaceWebApp}>
                        {ind <= 2
                            ?
                                <Link to={`${enlaceWebApp}/`} title={nombre} className="cardSliderHome">
                                    {IMAGE_THUMB_HOME
                                        ?
                                            <ProgressiveImage
                                                src={`${repareImage(contenido).replace('publicidad', 'mkt')}?scale=800`}
                                                placeholder={`${repareImage(contenido).replace('publicidad', 'mkt')}?scale=${SIZE_IMAGE_THUMB_BANNER}`}
                                            >
                                                {(src) => <img
                                                    loading={ind === 0 ? "eager" : "lazy"}
                                                    src={src}
                                                    alt={nombre}
                                                    width="595"
                                                    height="335"
                                                    decoding="async"
                                                    fetchpriority={ind === 0 ? "high" : "low"}
                                                    />
                                                }
                                            </ProgressiveImage>
                                        :
                                            <img
                                                loading={ind === 0 ? "eager" : "lazy"}
                                                src={`${repareImage(contenido).replace('publicidad', 'mkt')}?scale=600`}
                                                alt={nombre}
                                                width="595"
                                                height="335"
                                                decoding="async"
                                                fetchpriority={ind === 0 ? "high" : "low"}
                                            />
                                    }
                                    <p>{nombre}</p>
                                </Link>
                            : null
                        }
                    </Fragment>
                )            
                    : null
                }
            </div>
        </div>
    )
}






//- slider Actual de la aplicacion Desktop Home V2
export function VersionSliderDesktop(props){

    const {
        sliders,
        styleView
    } = props

    //console.log('sliders ->', sliders)
    
    const settings = {
        dots: false,
        autoplay: true,
        infinite: true,
        centerMode: false,//true,
        swipe: true,
        touchMove: true,
        centerPadding: "60px",
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 760,
                settings: {
                    autoplay: true,
                    infinite: true,
                    centerMode: true,
                    swipe: true,
                    touchMove: true,
                    centerPadding: "60px",
                    slidesToShow: 1
                }
            }
        ]
    };

    return(
        <div className="contSlider sliderPrincipal">
            <div className="container contSlidePFull">
                {sliders.length !== 0
                    ?
                    <Slider {...settings}>
                        {sliders?.map( ({image, link, name, status},ind) =>
                            status === true &&
                                <Fragment key={ind}>
                                    {typeof(link) !== 'function'
                                        ?
                                            link?.split('/')[1] === "c"
                                                ?
                                                    <a href={`${link}`} title={name} className="cardSliderHome">
                                                        {IMAGE_THUMB_HOME
                                                            ?
                                                                <ProgressiveImage
                                                                    src={`${repareImage(image).replace('publicidad', 'mkt')}?scale=1500`}
                                                                    placeholder={`${repareImage(image).replace('publicidad', 'mkt')}?scale=${SIZE_IMAGE_THUMB_BANNER}`}
                                                                >
                                                                    {(src) => <img
                                                                        loading={ind === 0 ? "eager" : "lazy"}
                                                                        src={src}
                                                                        alt={name}
                                                                        width="1500"
                                                                        height="700"
                                                                        decoding="async"
                                                                        fetchpriority={ind === 0 ? "high" : "low"}
                                                                        />
                                                                    }
                                                                </ProgressiveImage>
                                                            :
                                                                <img
                                                                    loading={ind === 0 ? "eager" : "lazy"}
                                                                    src={`${repareImage(image).replace('publicidad', 'mkt')}?scale=1500`}
                                                                    alt={name}
                                                                    width="1500"
                                                                    height="700"
                                                                    decoding="async"
                                                                    fetchpriority={ind === 0 ? "high" : "low"}
                                                                />
                                                        }
                                                        <p>{name}</p>
                                                    </a>
                                                :
                                                    <Link to={`${link?.replace('/l/', '/landing/').replace('/p/', '/landing/').replace(/\/$/g, '')}/`} title={name}>
                                                        {IMAGE_THUMB_HOME
                                                            ?
                                                                <ProgressiveImage
                                                                    src={`${repareImage(image).replace('publicidad', 'mkt')}?scale=1500`}
                                                                    placeholder={`${repareImage(image).replace('publicidad', 'mkt')}?scale=${SIZE_IMAGE_THUMB_BANNER}`}
                                                                >
                                                                    {(src) => <img
                                                                        loading={ind === 0 ? "eager" : "lazy"}
                                                                        src={src}
                                                                        alt={name}
                                                                        width="1500"
                                                                        height="700"
                                                                        decoding="async"
                                                                        fetchpriority={ind === 0 ? "high" : "low"}
                                                                        />
                                                                    }
                                                                </ProgressiveImage>
                                                            :
                                                                <img
                                                                    loading={ind === 0 ? "eager" : "lazy"}
                                                                    src={`${repareImage(image).replace('publicidad', 'mkt')}?scale=1500`}
                                                                    alt={name}
                                                                    width="1500"
                                                                    height="700"
                                                                    decoding="async"
                                                                    fetchpriority={ind === 0 ? "high" : "low"}
                                                                />
                                                        }
                                                    </Link>
                                        : null
                                    }
                                </Fragment>
                            )
                        }

                        </Slider>
                    : null
                }
            </div>
        </div>
    )
}
