import React, { useEffect, useState, Fragment } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from "react-helmet"
import { decodeStorage } from 'functions/storageBase64'
import Header from 'pages/Header/HeaderNavegacion'
import BarraCP from 'components/BarCP/BarHeaderCP'
import Footer from 'pages/Footer/FooterDesktop'
import Loading from 'components/Loading'
import BarFilter from 'components/BarFilter'
import CardProduct from 'components/CardProduct/ClasicCard'
import ShareButton from 'components/Share'
import ErrorType from 'pages/Error/ErrorType'
import { removeStorage } from 'functions/storageBase64'
import { VARIANTS_PER_PORTAL } from 'constants/general'
import { repareImage } from 'functions/repareDataService'
import { getCorner } from 'services/Corner'


function OficialStore(props) {
    const {
        storeImg,
        storeTitle
    } = props

    return (
        <div className="contOficalStore">
            <div className="stOficial">
                <img loading="lazy" src={repareImage(storeImg) } alt={storeTitle} width="110" height="50" />
                <div className="textOficial">
                    <h1 className="storeO">{storeTitle}</h1>
                    <p>Tienda Oficial</p>
                </div>
            </div>
            <ShareButton title={storeTitle} />
        </div>
    )
}

export function CornerPage(props) {
    const { } = props

    const params = useParams()
    // console.log('-------')
    // console.log(params)


    const codigoP = decodeStorage('codigoPostal')
    const [codigoPostal, setCodigoPsotal] = useState(codigoP)
    const [loading, setLoading] = useState(false)
    const [isShowing, setIsShowing] = useState(false);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Corner List Page');
        window.scrollTo(0, 0)

        removeStorage('wishlistAfter')
        removeStorage('productAfter')
        removeStorage('typeButton')

    }, [])

    const [vista, setVista] = useState(false)
    const seEnviaVista = (vistaS) => {
        setVista(vistaS)
    }

    //- Va por el servicio de Corners
    const [response, setResponse] = useState()
    useEffect(() => {
        getCorner(params.id_Corner)
        .then(setResponse)
    }, [])

    //- Separa las respuestas en states usables
    const [statusCode, setStatusCode] = useState()
    const [data, setData] = useState()
    const [errors, setErrors] = useState('')
    useEffect(() => {
        if (response) {
            setStatusCode(response.statusCode)
            setErrors(response.error)
            setData(response.data)
        }
    }, [response])

    const [productsCorner, setProductsCorner] = useState()

    useEffect(() => {
        if (statusCode === 200) {
            if (data) {
                setProductsCorner(data.data.products)
            }
        }
        else{
            setErrors({'error': statusCode ,'response': data})
        }
    }, [statusCode])


    
    // const returnBtn = (val) =>{
        //     history.push(val)
        // }
        
        // console.log('data',data)
        // console.log('statusCode',statusCode)
        // console.log('errors',errors)
        
    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
	const [updateCPBar, setUpdateCPBar] = useState()
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val)
        setUpdateCPBar(val)
    }
    const [upCP, setUpCP] = useState()
    useEffect(()=>{
        if(upCP){
            //console.log('')
        }
    },[upCP,updateCPBar])

    return (
        <>
            { loading && <Loading />}
            <Helmet>
                <title>Solo Sanborns</title>
                <link rel="canonical" href={`${VARIANTS_PER_PORTAL.domain}/${params.id_Corner}/${params.seo}/`} />
                <meta name="Description" content="SANBORNS.COM.MX | Envíos a todo México · Más de 500 mil productos con todas las Formas de Pago  Compras 100% Seguras · Lo mejor en Moda, Línea blanca, Pantallas, Computo, Celulares, Juguetes, Muebles, Ferretería y más · Click & Recoge en Tienda" />
            </Helmet>

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar}/>
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                {data &&
                    <section className="sectionCategoryProducts">
                        <div className="container">
                            <div className="contProductCategory">
                                {data.data &&
                                    <>
                                        <BarFilter
                                            results={data.data.total_items}
                                            disableFilters={1}
                                            isShowing={isShowing}
                                            setIsShowing={setIsShowing}
                                            seEnviaVista={seEnviaVista}
                                        />
                                        <OficialStore
                                            storeTitle={data.data.products[0].store.name}
                                            storeImg={data.data.products[0].store.logo}
                                        />
                                    </>
                                }
                                {productsCorner &&
                                    <>
                                        {productsCorner.map((producto,ind) =>
                                            producto.telmex_months.length 
                                                ?
                                                    producto.payment_methods 
                                                        ?
                                                            producto.payment_methods.find(idProd => idProd.id === 4468).promotion.length
                                                                ?
                                                                    <CardProduct
                                                                        key={producto.id} id={producto.id}
                                                                        nombre={producto.title} nombreSEO={producto.title_seo}
                                                                        precio={producto.sale_price} precioLista={producto.price}
                                                                        foto={producto.images[0].thumbnail} descuento={producto.discount}
                                                                        store={producto.store.name} review={producto.reviews.score}
                                                                        comments={producto.reviews.total_reviews} shipping_price={producto.shipping.is_free}
                                                                        fulfillment={producto.features.fulfillment} brand={producto.brand}
                                                                        categories={producto.categories} telmexMonths={producto.telmex_months[producto.telmex_months.length -1].month}
                                                                        telmexPrice={producto.telmex_months[producto.telmex_months.length -1].price} index={ind}
                                                                        creditCardMonths={producto.payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments[producto.payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments.length -1].months}
                                                                        creditCardPrice={producto.payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments[producto.payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments.length -1].price}
                                                                    />
                                                                :
                                                                    <CardProduct
                                                                        key={producto.id} id={producto.id}
                                                                        nombre={producto.title} nombreSEO={producto.title_seo}
                                                                        precio={producto.sale_price} precioLista={producto.price}
                                                                        foto={producto.images[0].thumbnail} descuento={producto.discount}
                                                                        store={producto.store.name} review={producto.reviews.score}
                                                                        comments={producto.reviews.total_reviews} shipping_price={producto.shipping.is_free}
                                                                        fulfillment={producto.features.fulfillment} brand={producto.brand}
                                                                        categories={producto.categories} telmexMonths={producto.telmex_months[producto.telmex_months.length -1].month}
                                                                        telmexPrice={producto.telmex_months[producto.telmex_months.length -1].price} index={ind}
                                                                    />
                                                        :
                                                            <CardProduct
                                                                key={producto.id} id={producto.id}
                                                                nombre={producto.title} nombreSEO={producto.title_seo}
                                                                precio={producto.sale_price} precioLista={producto.price}
                                                                foto={producto.images[0].thumbnail} descuento={producto.discount}
                                                                store={producto.store.name} review={producto.reviews.score}
                                                                comments={producto.reviews.total_reviews} shipping_price={producto.shipping.is_free}
                                                                fulfillment={producto.features.fulfillment} brand={producto.brand}
                                                                categories={producto.categories} telmexMonths={producto.telmex_months[producto.telmex_months.length -1].month}
                                                                telmexPrice={producto.telmex_months[producto.telmex_months.length -1].price} index={ind}
                                                            />
                                                : 
                                                    <CardProduct
                                                        key={producto.id} id={producto.id}
                                                        nombre={producto.title} nombreSEO={producto.title_seo}
                                                        precio={producto.sale_price} precioLista={producto.price}
                                                        foto={producto.images[0].thumbnail} descuento={producto.discount}
                                                        store={producto.store.name} review={producto.reviews.score}
                                                        comments={producto.reviews.total_reviews} shipping_price={producto.shipping.is_free}
                                                        fulfillment={producto.features.fulfillment} brand={producto.brand}
                                                        categories={producto.categories} index={ind}
                                                    />

                                            
                                        )}
                                    </>
                                }
                            </div>

                        </div>

                    </section>
                }
                {errors &&
                    <ErrorType
                        codeStatus={errors.error}
                        errorText={errors.response}
                    />
                }
            </main>
            <Footer />
        </>

    )
}