import React, { useState, useEffect, useRef } from "react";
import "./style.sass";
import getSearch from "services/getSearchAutocomplete";
import $ from "jquery";
import HistorySearch from "components/HistorySearch";
import { decodeStorage } from "functions/storageBase64";
import { useIsMobile } from "hooks/useIsMobile";
import { useHistory } from "react-router-dom";
import { AutocompleteComponent } from 'components/SearchHeader';

export function SearchHeaderMobile({ ...props }) {
    const history = useHistory();

    const url = history.location.pathname;
    const urlSearchM = history.location.search;
    const { isMobile } = useIsMobile();
    const [typeSearch, setTypeSearch] = useState("");
    const [autocomplete, setAutocomplete] = useState([]);
    const [show, setShow] = useState(false);
    const [totalArray, setTotalArray] = useState([]);
    const [palabraBuscada, setPalabraBuscada] = useState("");
    const [, setMostrarHistorial] = useState(false);
    const [, setEnFocus] = useState(false);
    const [inputVacio, setInputVacio] = useState(false);
    var arrayBusquedas = decodeStorage("busquedas");

    useEffect(() => {
        if (typeSearch?.length >= 1) {
            getSearch({ typeSearch }).then((autocomplete) =>
                setAutocomplete(autocomplete.suggestions)
            );
        }
    }, [typeSearch]);

    // buscar texto con con boton buscar
    const SearchText = (evt) => {
        if (typeSearch?.length > 2 && url != "/") {
            //history.push(`/resultados?query=${encodeURIComponent(typeSearch)}`)
            //window.location.href = `/resultados?query=${encodeURIComponent(String(typeSearch.replace("%","")))}`;
            console.log( `/resultados?query=${encodeURIComponent(String(typeSearch.replace("%","")))}`)
            console.log('1')
        }
        if (typeSearch?.length > 2 && url === "/") {
            //history.push(`/resultados?query=${encodeURIComponent(typeSearch)}`)
            //window.location.href = `/resultados?query=${encodeURIComponent(String(typeSearch.replace("%","")))}`;
            console.log(`/resultados?query=${encodeURIComponent(String(typeSearch.replace("%","")))}`)
            console.log('2')
        }
    };

    // ajuste de iOS
    useEffect(()=>{
        let busqueda = decodeURI(urlSearchM?.split('?search=')[1])

        if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            //console.log(urlSearchM?.split('?search=')[1])
            if(String(busqueda) !==  "undefined"){
                window.location.href = `/resultados?query=${encodeURIComponent(String(busqueda))}`;
            }
        }

    },[typeSearch, url])

    // asignar valor al input
    const SearchChange = (evt) => {
        let limpiarTexto = String(evt.target.value).trim();
        if (limpiarTexto?.length > 0) {
            setTypeSearch(String(evt.target.value));
        }
        else{
            setTypeSearch("");
        }
    };

    useEffect(() => {
        const arrayTotalCoincidencia = [];
        const total = [];

        if (autocomplete?.length > 0) {
            autocomplete.map((auto, i) => {
                return auto.clase === "word_item" && arrayTotalCoincidencia?.length < 8
                    ? arrayTotalCoincidencia.push({
                        clase: auto.clase,
                        nombre: auto.nombre,
                    })
                    : null;
            });
        }

        // if (autocomplete.length > 0) {
        //     var contadorCate = [];
        //     autocomplete.map((auto, i) => {
        //         return auto.clase === "category_item"
        //             ? contadorCate.push(auto.clase)
        //             : null;
        //     });

        //     if (contadorCate.length > 0)
        //         arrayTotalCoincidencia.push({
        //             clase: "disclaimerNotice",
        //             nombre: "Por categoría",
        //         });

        //     autocomplete.map((auto, i) => {
        //         return auto.clase === "category_item" &&
        //             arrayTotalCoincidencia.length < 9
        //             ? arrayTotalCoincidencia.push({
        //                 clase: auto.clase,
        //                 nombre: auto.nombre,
        //             })
        //             : null;
        //     });
        // }

        // if (autocomplete.length > 0) {
        //     var contadorStore = [];
        //     autocomplete.map((auto, i) => {
        //         return auto.clase === "store_item"
        //             ? contadorStore.push(auto.clase)
        //             : null;
        //     });

        //     if (contadorStore.length > 0)
        //         arrayTotalCoincidencia.push({
        //             clase: "disclaimerNotice",
        //             nombre: "Por tienda",
        //         });

        //     autocomplete.map((auto, i) => {
        //         return auto.clase === "store_item" &&
        //             arrayTotalCoincidencia.length <= 10
        //             ? arrayTotalCoincidencia.push({
        //                 clase: auto.clase,
        //                 nombre: auto.nombre,
        //             })
        //             : null;
        //     });
        // }

        total.push(arrayTotalCoincidencia);
        setTotalArray(total[0]);
    }, [autocomplete]);

    const ClearTextLink = (text) => {
        const texto = text;
        const limpio1 = texto.replace(/(<([^>]+)>)/gi, "").trim();
        const longitud = limpio1?.length;

        // if (limpio1.substring(0, 14) === "Ir a la Tienda") {
        //     return <p>{limpio1.substring(15, longitud)}</p>;
        // }
        if (limpio1.substring(0, 17) === "Ir a la Categoría") {
            return <p>{limpio1.substring(18, longitud)}</p>;
        }
        return texto.replace(/(<([^>]+)>)/gi, "").trim();
    };

    function ClearTextLink2(text) {
        const texto = text;

        const limpio2 = texto.replace(/(<([^>]+)>)/gi, "").trim();
        const longitud = limpio2?.length;

        // if (limpio2.substring(0, 14) == "Ir a la Tienda") {
        //     return limpio2.substring(15, longitud).trim();
        // } else 
        if (limpio2.trim().substring(0, 15) == "Ir a la Categor") {
            return limpio2.substring(18, longitud).trim();
        }

        return texto.replace(/(<([^>]+)>)/gi, "").trim();
    }

    //- Funcion de la respuesta del autocompletado para mandar a la busqueda
    const hiddenAutocomplete = (e) => {
        enviarPalabraBuscada(e);
        setAutocomplete([]);

        var buscarTexto = String(e.target.value);
        var urlRedirigir = e.target.id;

        setTypeSearch(buscarTexto);
        setTimeout(() => {
            if (String(url) !== "/") {
                history.replace(String(url).replace("%",""), null);
                //window.location.href = String(urlRedirigir).replace("%","");
                window.location.href = String(urlRedirigir)
                console.log( String(urlRedirigir) )
                console.log('3')
            }
            if (String(url) === "/") {
                //window.location.href = String(urlRedirigir).replace("%","");
                console.log( String(urlRedirigir) )
                console.log('4')
            }
        }, 300);
    };

    function enviarPalabraBuscada(evt) {
        evt.preventDefault();

        if (String(evt.target.value) != "") {
            //setPalabraBuscada(String(evt.target.value).replace("%",""));
            setPalabraBuscada(String(evt.target.value))
            console.log('5')
        }
    }

    const guardarTexto = (e) => {
        enviarPalabraBuscada(e);

        setTimeout(() => {
            if (url !== "/" && typeSearch?.length > 2) {
                history.replace(String(url).replace("%",""), null);
                //history.push(`/resultados?query=${encodeURIComponent(typeSearch)}`)
                window.location.href = `/resultados?query=${encodeURIComponent(String(typeSearch)?.replace("%",""))}`;
                console.log(`/resultados?query=${encodeURIComponent(String(typeSearch).replace("%",""))}`)
                console.log('6')
            }
            if (url === "/" && typeSearch?.length > 2) {
                history.push(`/resultados?query=${encodeURIComponent(typeSearch)}`)
                window.location.href = `/resultados?query=${encodeURIComponent(String(typeSearch)?.replace("%",""))}`;
                console.log(`/resultados?query=${encodeURIComponent(String(typeSearch).replace("%",""))}`)
                console.log('7')
            }
        }, 500);
    };

    const [movimientoSubirBajar, setMovimientoSubirBajar] = useState(0);

    const handleKeyMove = (evt) => {
        if (evt.keyCode == 38) {
            if (movimientoSubirBajar > 0)
                setMovimientoSubirBajar(movimientoSubirBajar - 1);
        } else if (evt.keyCode == 40) {
            setMovimientoSubirBajar(movimientoSubirBajar + 1);
        }
    };
    //----------------------------------------------------------

    useEffect(() => {
        document.addEventListener("mousedown", handleMouseDown);
        return document.removeEventListener("mousedown", handleMouseDown);
    });

    const handleMouseDown = (event) => {
        let x = $(document).scrollLeft() + event.clientX; // event.offsetX
        let y = $(document).scrollTop() + event.clientY; // event.offsetY

        //no hizo clic en la entrada de búsqueda o en la lista de sugerencias
        if (
            show &&
            !checkXYInElement(x, y, ".contAutocomplete") &&
            !checkXYInElement(x, y, ".input")
        ) {
            setShow(false);
        }
    };

    const checkXYInElement = (x, y, className) => {
        let elem = $(className);
        if (elem?.length == 0) {
            return false;
        }
        let rect = {
            x: elem.offset().left,
            y: elem.offset().top,
            w: elem.outerWidth(),
            h: elem.outerHeight(),
        };
        if (
            x < rect.x ||
            y < rect.y ||
            x > rect.x + rect.w ||
            y > rect.y + rect.h
        ) {
            return false;
        }
        return true;
    };

    const handleFocusSearcherInput = () => setShow(true);

    const handleBlurSearcherInput = () => {
        setTimeout(function () {
            setShow(false);
        }, 500);
    };
    useEffect(() => {
        if (show) {
            setMostrarHistorial(true);
            if (url !== "/buscador") {
                document
                    .querySelector(".headerSup form.search")
                    .classList.add("searchFocus");
            } else {
                document.querySelector("form.search").classList.add("searchFocus");
            }
        } else {
            setMostrarHistorial(false);
            if (url !== "/buscador") {
                document
                    .querySelector(".headerSup form.search")
                    .classList.remove("searchFocus");
            } else {
                document.querySelector("form.search").classList.add("searchFocus");
            }
        }
    }, [show]);

    const handleSearcherInputKeyDown = (event) => {
        if (show) {
            // use keyboard to select the suggesions
            handleSelectSuggestions(event);
        } else {
            // just show the suggestions list
            setShow(true);
        }
    };

    // use teclados para seleccionar las sugerencias
    const handleSelectSuggestions = (event) => {
        let li = $(".searcher-suggs-word.selected");
        let tienda = "Por tienda";
        let categoria = "Por categoría";

        if (event.keyCode == 40 || event.keyCode == 38) {
            // 40 => down, 38 => up
            event.preventDefault();

            if (li?.length == 0) {
                $(".searcher-suggs-word:first-child").toggleClass("selected");
            } else if (event.keyCode == 40) {
                if (
                    String(li.text()).trim() == tienda ||
                    String(li.text()).trim() == categoria
                ) {
                    li.removeClass("selected");
                    li.next().addClass("selected");
                } else {
                    li.removeClass("selected");
                    li.next().addClass("selected");
                }
            } else {
                if (
                    String(li.text()).trim() === tienda ||
                    String(li.text()).trim() === categoria
                ) {
                    li.removeClass("selected");
                    li.prev().addClass("selected");
                } else {
                    li.removeClass("selected");
                    li.prev().toggleClass("selected");
                }
            }
        } else {
            if (
                String(li.text()).trim() !== tienda &&
                String(li.text()).trim() !== categoria
                ) {
                if (event.keyCode === 13) {
                    if (li.text()) {
                        setTypeSearch(String(li.text()).trim());
                    }
                }
            } else {
                if (event.keyCode == 13) {
                    event.preventDefault();
                }
            }
        }
    };

    // evento flotante en la lista de sugerencias
    const handleHoverSearcherSuggestions = () => {
        $(".searcher-suggs-word.selected").removeClass("selected");
        $(".searcher-suggs-word:focus").removeClass("selected");
        $(".searcher-suggs-word:hover").addClass("selected");
    };

    function regresar() {
        history.go(-1);
    }

    // saber si el input esta limpio / vacio
    useEffect(() => {
        var elemento = document.getElementById("inputBusqueda").value;

        if (elemento == "") {
            setInputVacio(true);
        } else {
            setInputVacio(false);
        }
    });

    function focus() {
        if (document.activeElement.name === "search") {
            setEnFocus(true);
        } else {
            setEnFocus(false);
        }
    }

    // agrega el valor de la palabra seleccionada en el input de busqueda
    const ReplicarEnBuscador = (e) => {
        e.preventDefault();
        var valor = String(e.target.value).replace("%","");
        console.log('8')
        setTypeSearch(valor);
        
    };

    const nameInput = useRef();
    useEffect(() => {
        if (isMobile != "desktop") {
            nameInput.current.focus();
        }
    }, []);

    return (
        <div className="moduleSearchAutocomplete mobileComponent">
            <form onSubmit={SearchText} className="search">
                <div className="searchMobileComponent">
                    <div onClick={regresar} className="searchBack"></div>
                    <label className="line">
                        <input
                            type="search"
                            value={typeSearch}
                            className="input"
                            onChange={SearchChange}
                            onKeyDown={handleKeyMove}
                            onKeyDown={handleSearcherInputKeyDown}
                            onFocus={handleFocusSearcherInput}
                            onBlur={handleBlurSearcherInput}
                            name="search"
                            placeholder="Buscar en Sanborns"
                            aria-label="Campo de busqueda"
                            autoComplete="off"
                            id="inputBusqueda"
                            ref={nameInput}
                        />
                    </label>
                    <button
                        className="btn rojo button"
                        value={typeSearch}
                        onClick={(e) => guardarTexto(e)}
                        aria-label="boton buscar"
                    >
                        Buscar
          </button>
                </div>

                <div className="listado"> 
                {/* style={{ display: "none" }}> */}
                    <HistorySearch
                        buscar={show}
                        sebusco={palabraBuscada}
                        setTypeSearch={setTypeSearch}
                        onFocus={focus}
                        name="historial"
                    />
                </div>

                {isMobile
                    ? (isMobile !== "desktop" && isMobile !== "mobile"
                        ? (autocomplete &&
                            (<ul className="contAutocomplete" onFocus={focus} name="sugerencias" >
                                {totalArray.map(({ clase, nombre }, ind) => (
                                    <li key={ind} className="searcher-suggs-word" onMouseOver={handleHoverSearcherSuggestions} >
                                        {clase === "word_item" ? (
                                            <>
                                                <button
                                                    onClick={hiddenAutocomplete}
                                                    id={`/resultados?query=${encodeURIComponent(ClearTextLink(nombre))}`}
                                                    className={clase}
                                                    value={ClearTextLink(nombre)}
                                                    aria-label="palabra a buscar"
                                                >
                                                    {ClearTextLink(nombre)}
                                                </button>
                                                {/* <button
                                                    className="ir"
                                                    onClick={(e) => ReplicarEnBuscador(e)}
                                                    value={ClearTextLink(nombre)}
                                                    aria-label="ir a"
                                                ></button> */}
                                            </>
                                        ) 
                                        // : clase === "category_item" ? (
                                        //     <>
                                        //         <button
                                        //             className={clase}
                                        //             onClick={hiddenAutocomplete}
                                        //             value={ClearTextLink2(nombre)}
                                        //             id={`/categoria/${nombre.split("/")[2]}/${nombre.split("/")[3]}`}
                                        //             aria-label="palabra a buscar"
                                        //         >
                                        //             {ClearTextLink2(nombre)}
                                        //         </button>
                                        //         {/* <button
                                        //             className="ir"
                                        //             onClick={ReplicarEnBuscador}
                                        //             value={ClearTextLink2(nombre)}
                                        //             aria-label="ir a"
                                        //         ></button> */}
                                        //     </>
                                        // ) 
                                        // : clase === "store_item" ? (
                                        //     <>
                                        //         <button
                                        //             className={clase}
                                        //             onClick={hiddenAutocomplete}
                                        //             value={ClearTextLink2(nombre)}
                                        //             id={`/tienda/${nombre.split("/")[2]}/${nombre.split("/")[3]}`}
                                        //             aria-label="palabra a buscar"
                                        //         >
                                        //             {ClearTextLink2(nombre)}
                                        //         </button>
                                        //         <button
                                        //             className="ir"
                                        //             onClick={ReplicarEnBuscador}
                                        //             value={ClearTextLink2(nombre)}
                                        //             aria-label="ir a"
                                        //         ></button>
                                        //     </>
                                        // )
                                         : clase === "disclaimerNotice"
                                            ? <span className={clase}>{ClearTextLink(nombre)} </span>
                                            : null
                                        }
                                    </li>
                                ))}
                            </ul>
                            )
                        ) : arrayBusquedas?.length > 0 && inputVacio === true
                            ? <HistorySearch buscar={show} sebusco={palabraBuscada} setTypeSearch={setTypeSearch} name="historial" />
                            : (autocomplete &&
                                <AutocompleteComponent
                                    totalArray={autocomplete}
                                    focus={focus}
                                    handleHoverSearcherSuggestions={handleHoverSearcherSuggestions}
                                    hiddenAutocomplete={hiddenAutocomplete}
                                    ClearTextLink={ClearTextLink}
                                    ClearTextLink2={ClearTextLink2}
                                    ReplicarEnBuscador={ReplicarEnBuscador}
                                />
                            )
                    ) : null
                }
            </form>
        </div>
    );
}
