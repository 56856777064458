import React from 'react';
import { CardVistosMobile } from 'components/CardProduct/CardVistos'
import ErrorType from 'pages/Error/ErrorType';
import { NO_IMAGE } from 'constants/general';

export function VistoslistTemplateMobile(props) {
    const {
        rawPixelProducts,
        pixelProducts,
        errorText,
        updateDelete,
        addToCart
    } = props;

    return (
        <>
            <main>
                <section className="miCuentaContainer">                   
                    <div className="container">
                    { ( rawPixelProducts.statusCode >= 200 && rawPixelProducts.statusCode <= 299 )
                            ? <div className="">
                                {pixelProducts?.products &&   
                                    pixelProducts?.products.map(({ month, products }, ind) =>
                                        products &&
                                            <>
                                                <h2 className='text-left'>{month}</h2>
                                                <div className="contWishlist">
                                                    {products.map(({ id, images, title, price, sale_price, discount, size_color, status, stock, shipping,  features, brand }, ind) =>
                                                        <CardVistosMobile
                                                            key={ind}
                                                            id={id}                                            
                                                            images={images?.length >= 1 ? images[0]?.thumbnail : NO_IMAGE}
                                                            title={title}
                                                            size_color={size_color}
                                                            price={price}
                                                            sale_price={sale_price}
                                                            discount={discount}
                                                            status={status}
                                                            stock={stock}                                            
                                                            shipping_price={shipping?.is_free}                                                
                                                            features={features}
                                                            upDelete={updateDelete}
                                                            upAddCart={addToCart}
                                                            brand={brand}
                                                            index={ind}
                                                            
                                                        />
                                                    )
                                                }
                                            </div>
                                        </>
                                    )
                                }
                                
                                {pixelProducts?.total_items === 0
                                    ? <ErrorType
                                        section="pixelProducts"
                                        codeStatus={404}
                                        errorText={errorText}
                                    />
                                    : null
                                }
                            </div>
                            : null
                        }

                        <ErrorType section="pixelProducts" codeStatus={rawPixelProducts?.statusCode} errorText={errorText} />
                    </div>
                </section>
            </main>
        </>
    )
}