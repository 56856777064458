import React, {useState, useEffect} from 'react'
import './style.sass'

export function BurbleCredit(){
    const burbleCred = window.localStorage.getItem('showBC')
    const [showMenu, setShowMenu] = useState(false)

    useEffect(()=>{
        if(!burbleCred){
            //setShowMenu(true)
            setShowMenu(false)
        }else{
            setShowMenu(false)
        } 
    },[])
    
    const closeBtn = () => {
        window.localStorage.setItem('showBC', "false")
        setShowMenu(false)
    }

    return(
        showMenu &&
            <div className="contFloatMenu">
                <div className="blackModal" onClick={closeBtn}></div>
                <div className="floatMenu">
                    <div className="closeBtn" onClick={closeBtn}>X</div>
                    <p>¡Explora Crédito SANBORNS!</p>
                    <ul>
                    <li>Consulta tu estado de cuenta</li>
                    <li>Paga tu tarjeta</li>
                    <li>Revisa tus movimientos</li>
                    <li>Solicita tu crédito SANBORNS</li>
                    </ul>
                </div>
            </div>
    )
}