import React, { useState, useEffect } from 'react';
// import { BASE_PATH_PORTAL } from 'constants/general';
import Header from 'pages/Header/HeaderNavegacion';
import Footer from 'pages/Footer/FooterDesktop';

import BarraCP from 'components/BarCP/BarHeaderCP';
import Loading from 'components/Loading';
// import { MenuMiCuenta } from 'components/MenuMiCuenta';
// import Switch from 'components/Forms/Switch';

import { decodeStorage } from 'functions/storageBase64';
import { Link } from 'react-router-dom';
import { useLogout } from 'hooks/useLogin';

import './style.sass';
import { useIsMobile } from 'hooks/useIsMobile';
import { useUserInfo } from 'hooks/usePersonalData'
import { MenuMiCuenta } from 'components/MenuMiCuenta';
import { BASE_PORTAL } from 'constants/general';

export function CardMyAccount(props) {

    const {
        ico,
        title,
        linksNav,
        internalList,
        actionClick
    } = props;

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'My Account');
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="cardMyAccount">
            <p className="titleMyAccount">
                <span className={`imgMyAccount ${ico}`}></span>
                <span className="text">{title}</span>
            </p>
            {internalList &&
                <ul className="contList">
                    {
                        internalList.map(({ url, type, text }, index) => {
                            return (
                                type === "link"
                                    ? <Link key={index} className="link" to={url} aria-label={text} alt={text}>{text}</Link>
                                    : type === "anchor"
                                        ? <a key={index} className="link" href={url} rel="noopener noreferrer" aria-label={text} alt={text}>{text}</a>
                                        : type === "div"
                                            ? <p key={index} onClick={actionClick}>{text}</p>
                                            : null
                            )
                        }
                        )
                    }
                </ul>
            }
            {linksNav &&
                <div className="contLinks">
                    {
                        linksNav.map(({ url, type, text }, index) => {
                            return (
                                type === "link"
                                    ? <Link key={index} className="link" to={url} aria-label={text} alt={text}>{text}</Link>
                                    : type === "anchor"
                                        ? <a key={index} className="link" href={url} rel="noopener noreferrer" aria-label={text} alt={text}>{text}</a>
                                        : type === "div"
                                            ? <div key={index} className="link" onClick={actionClick}>{text}</div>
                                            : null
                            )
                        }
                        )
                    }
                </div>
            }
        </div>
    )
}

export default function MiCuenta() {

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'My Account Page');
    }, [])

    const { isMobile } = useIsMobile();

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <MiCuentaDesktop />
                    : isMobile === 'movile'
                        ? null
                        : <MiCuentaMobile />
                : null
            }
        </>
    )
}

export function MiCuentaMobile() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);
    const [loading,] = useState(false);
    //const [profileData] = useStorage64('profileData', '');
    const { profileGet: profileData } = useUserInfo();

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    const storageActivo = decodeStorage("activarNotificaciones");
    //const perfil = decodeStorage('profileData');
    //const { profileGet:perfil } = useUserInfo()

    useEffect(() => { }, [storageActivo])

    return (
        <>
            {loading && <Loading />}
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} headerEnCategoria={"headerListaDeseos"} tituloPagina="Mi Cuenta" />

            <main>
                <section className="miCuentaContainerMobile">
                    <div className="headerMiCuentaRed">
                        <div className='iconoMiCuenta'></div>
                        <div className='informacion'>
                            <p className="nameC">¡Hola {profileData.nombre}!</p>
                            <p className="correoC"> {profileData.correo}</p>
                        </div>
                    </div>
                    <MenuMiCuenta />
                </section>
            </main>
        </>
    )
}

export function MiCuentaDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);
    const [loading,] = useState(false);
    //const [profileData] = useStorage64('profileData', '');
    const { profileGet: profileData } = useUserInfo()
    const { logOut } = useLogout()

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    //- Evalua si es dark mode dependiendo de la seleccion del usuario
    // useEffect(() => {
    //     if (document.body.className === "is-dark-mode") {
    //         document.querySelector('#darkMode').checked = true;
    //     } else {
    //         document.querySelector('#darkMode').checked = false;
    //     }
    // }, [])

    // const valChange = () => {
    //     let darkModeEval = document.querySelector('#darkMode').checked;
    //     encodeStorage('darkMode', darkModeEval);
    //     if (decodeStorage('darkMode')) { document.body.classList.add('is-dark-mode'); }
    //     else { document.body.classList.remove('is-dark-mode'); }
    //     sessionStorage.setItem('SelectUser', true);
    // }


    const storageActivo = decodeStorage("activarNotificaciones");
    //const perfil = decodeStorage('profileData');
    //const { profileGet:perfil } = useUserInfo()
    //const correo = perfil.correo;
    // const [resultadosUsuario, setResultadosUsuario] = useState(false);
    // const [tokens, setTokens] = useState([]);
    //console.log(" token: ", decode);//, "   correo usuario logueado---> ", correo);

    // useEffect(() => {
    //     if (resultadosUsuario) {
    //         setTokens(resultadosUsuario.email.token)
    //     }
    // }, [resultadosUsuario])

    useEffect(() => { }, [storageActivo])

    return (
        <>
            {loading && <Loading />}

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="miCuentaContainer">
                    <div className="container miCuentaBox">
                        <h1>Mi Cuenta</h1>

                        <div className="content myAccountContent">
                            <CardMyAccount
                                ico='boxPerfil'
                                title='Mi Perfil'
                                internalList={[{ "type": "div", "text": `Nombre: ${profileData.nombre}` }]}
                                //linksNav={[{"type": "anchor", "url": `${BASE_PATH_PORTAL}/mi-cuenta/mis-datos/`, "text": "Editar"},{"type": "div", "text": "Cerrar sesión"}]}
                                linksNav={[{ "type": "div", "text": "Cerrar sesión" }]}
                                actionClick={logOut}
                            />

                            <CardMyAccount
                                ico='boxDirecciones'
                                title='Mis direcciones de envío'
                                internalList={[{ "type": "div", "text": "Ver y editar tu información de envíos." }]}
                                linksNav={[{ "type": "link", "url": "/mi-cuenta/direcciones-envio/", "text": "Ver todo" }]}
                            />


                            <CardMyAccount
                                ico='boxCredito'
                                title='Mi crédito SANBORNS'
                                linksNav={[{ "type": "anchor", "url": "https://credito.sanborns.com.mx/", "text": "Ver todo" }]}
                            />

                            <CardMyAccount
                                ico='boxPedidos'
                                title='Mis Pedidos'
                                content={['Ver todos mis pedidos']}
                                internalList={[{ "type": "div", "text": "Ver todos mis pedidos" }]}
                                //internalList={[{"type": "anchor", "url": "/micuenta/mis-pedidos/filter_date=all/filter_status=pendig_payment/page=1", "text": "Pendientes de Pago"},{"type": "anchor", "url": "/micuenta/mis-pedidos/filter_date=all/filter_status=pendig_authorization/page=1", "text": "Pendientes de autorizar"},{"type": "anchor", "url": "/micuenta/mis-pedidos/filter_date=all/filter_status=preparing/page=1", "text": "Preparando tu producto"},{"type": "anchor", "url": "/micuenta/mis-pedidos/filter_date=all/filter_status=in_route/page=1", "text": "En camino"},{"type": "anchor", "url": "/micuenta/mis-pedidos/filter_date=all/filter_status=pick_up_pendig/page=1", "text": "Pendientes de recoger"},{"type": "anchor", "url": "/micuenta/mis-pedidos/filter_date=all/filter_status=delivered/page=1", "text": "Entregados"},{"type": "anchor", "url": "/micuenta/mis-pedidos/filter_date=all/filter_status=cancel/page=1", "text": "Cancelados / reembolsados"}]}
                                linksNav={[{ "type": "link", "url": "/mi-cuenta/mis-pedidos/dias=30/pagina=1", "text": "Ver todo" }]}
                            // linksNav={[{"type": "anchor", "url": `${BASE_PATH_PORTAL}/mi-cuenta/mis-pedidos/1/estatus/2/`, "text": "Ver todo"}]}
                            />

                            <CardMyAccount
                                ico='boxDeseos'
                                title='Mi lista de deseos'
                                linksNav={[{ "type": "link", "url": "/mi-cuenta/listadeseos/", "text": "Ver todo" }]}
                            />


                            <CardMyAccount
                                ico='boxHistorial'
                                title='Historial de Navegación'
                                linksNav={[{ "type": "link", "url": "/mi-cuenta/historial-navegacion/", "text": "Ver todo" }]}
                            />

                            {/* <CardMyAccount
                                ico='boxPago'
                                title='Métodos de Pago'
                                internalList={[{"type": "anchor", "url": `${BASE_PATH_PORTAL}/mi-cuenta/formas-pagoT1/`, "text": "Tarjetas de crédito / débito"}]}
                                //internalList={[{"type": "anchor", "url": `${BASE_PATH_PORTAL}/mi-cuenta/formas-pagoT1/`, "text": "Tarjetas de crédito / débito"},{"type": "anchor", "url": `${BASE_PATH_PORTAL}/mi-cuenta/formas-pagoT1/`, "text": "Tarjetas Sears / Sanborns"},{"type": "anchor", "url": `${BASE_PATH_PORTAL}/mi-cuenta/formas-pagoT1/`, "text": "Monedero Claroshop"}]}
                            /> */}

                            {/* <CardMyAccount
                                ico='boxCupones'
                                title='Mis cupones'
                                linksNav='/mi-cuenta/cupon/'
                                linksNav={[{"type": "anchor", "url": `${BASE_PATH_PORTAL}/mi-cuenta/cupon/`, "text": "Ver todo"}]}
                            /> */}
                            {/* <div className="cardMyAccount">
                                <p className="titleMyAccount">
                                    <span className={`imgMyAccount darkMode`}></span>
                                    <span className="text">Modo oscuro</span>
                                </p>
                                <ul className="contList">
                                    <Switch title="Modo oscuro" name="darkMode" position="right" type="darkMode" change={valChange} />
                                </ul>
                            </div> */}

                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}