import React, { useEffect, useState } from 'react'
import { repareTextSimple } from 'functions/repareDataService'
import { onPromoClick } from 'functions/dataLayerFunction'
import { VARIANTS_PER_PORTAL } from 'constants/general'

export function SirenaWhatsApp(props){
    const {
        category,
        store,
        price,
        sale_price,
        stock,
        status
    } = props

    const actualUrl = window.location.href
    const priceMinimum = 100
    const categories_choise = ["Deportes","Electrónica y Tecnología","Ferretería y Jardinería","Hogar","Línea Blanca y Electrodomésticos","Telefonía","Videojuegos","Muebles","Pasatiempo y Diversión","Belleza","Niños y Bebés","Zapatería","Él","Ella"]
    // const sellers_permitidos = ["SEARS", "DGL LATAM","TELMOV","IDESAIN.MX","BERTOLINI","Tatric","Tienda Mercadazo","Grupo Notebookinc Mexico","TecnoStore","XALLY","Fralugio","GadgetsMX","Refa 24","BABYS-MALL","BABYS-MALL","Blancos Luz","SPG-ONLINE","WIVIT","Somos Reyes","TOMACO","TEXTIL MARFRAN","POLTRONE MUEBLES"]
    const sellers_permitidos = ["SANBORNS"]

    const openWhatsapp = () => {
        try{
            onPromoClick(actualUrl, 'Producto', 'Sirena', 1);
        }catch(e){
            console.log('onPromoClick: ',e)
        }
        let marketPlace = ""
        // if(store){
        //     if(store.name){
        //         if(store.name.toLocaleLowerCase() === 'sanborns'){
        //             marketPlace = 'SANBORNS'
        //         }
        //     }
        // }
        window.open(`https://api.whatsapp.com/send?phone=${VARIANTS_PER_PORTAL.sirenaNumero}&text=Quiero%20informaci%C3%B3n%20sobre%20${actualUrl}${marketPlace}`, '_blank');
    }
    

    const [showSirena, setShowSirena] = useState(false)
    useEffect(()=>{
        // if(stock && status > 0){
            if(store){
                if(store?.name){
                    if( sellers_permitidos.find((seller) => repareTextSimple(seller).toLocaleLowerCase() === repareTextSimple(store.name).toLocaleLowerCase()) && sale_price >= priceMinimum  ){
                        setShowSirena(true)
                    }else{
                        setShowSirena(false)
                    }
                }
                else if(category){
                    let catPadre = [0]
                    if(category.length >= 3 ){ catPadre = category[2].name }
                    else if(category.length === 2 ){ catPadre = category[1].name }
                    else if(category.length === 1 ){ catPadre = category[0].name }
                    else{ catPadre = "sin categoria" }
                    
                    console.log('category: ',catPadre)
                    if( categories_choise.find((cat) => repareTextSimple(cat).toLocaleLowerCase() === repareTextSimple(catPadre).toLocaleLowerCase()) && sale_price >= priceMinimum  ){
                        setShowSirena(true)
                    }else{
                        setShowSirena(false)
                    }
                }
            }
        // }
    },[store, category])


    const [scrollActual, setScrollActual] = useState(window.scrollY)
    const mostrarOcultar = () => {
        scrollActual <= window.scrollY ? document.querySelector('.whatsapp-container .text').classList.add('close') : document.querySelector('.whatsapp-container .text').classList.remove('close');
        setScrollActual(window.scrollY)
    }
    useEffect(() => {
        if(showSirena){
            window.addEventListener("scroll", mostrarOcultar);
        }
        return () => window.removeEventListener("scroll", mostrarOcultar);
    },[scrollActual, showSirena])

    //console.log("entro a sirena")
    return(
        showSirena &&
            <div id='waSanborns'>
                <div className="whatsapp-container">
                    <div className="text">
                        <div className="triangle"></div>
                        <p>Contacta a un vendedor!</p>
                    </div>
                    <div className="whatsapp-icon-container" onClick={openWhatsapp} aria-label="Boton ayuda WhastApp">
                        <i className="fa fa-whatsapp"></i>
                    </div>
                </div>
            </div>
    )
}


export function WhatsAppAyuda(props){
    const {
        section
    } = props
    const promoClick = () => {
        
        try{
            onPromoClick('ayuda-whatsapp', section,'Sirena',1)
        }catch(e){
            console.log('onPromoClick: ',e)
        }

       window.open(`https://api.whatsapp.com/send?phone=${VARIANTS_PER_PORTAL?.sirenaNumero}`, '_blank');
    }

    return(
        <div onClick={promoClick} aria-label="Boton ayuda WhastApp">
            <div className="flex-column-container float">
                <i className="fa fa-whatsapp"></i>
                <p className="text">Ayuda</p>
            </div>
        </div>
    )
}


export function WhatsBuscador(props){
    const {
        busqueda,
        seccion
    } = props

    const [message, setMessage] = useState()
    const [secc, setSecc] = useState()
    const [seccTit, setSeccTit] = useState()

    const [showSirena, setShowSirena] = useState(false)
    useEffect(()=>{
        if(seccion === "buscador"){
            setMessage(`Necesito%20ayuda%20con%20mi%20b%C3%BAsqueda:%20`)
            setSecc('Buscador')
            setSeccTit('Busqueda Resultado')
        }
        else if(seccion === "categoria"){
            setMessage(`Necesito%20ayuda%20para%20buscar%20un%20producto%20de%20la%20categoría:%20`)
            setSecc('Categoria')
            setSeccTit('Busqueda Categoria')
        }
        else{
            setMessage(`Necesito%20ayuda%20con%20mi%20b%C3%BAsqueda:%20`)
        }
    },[busqueda, seccion])

    const promoClick = () => {

        //console.log('WhatsBuscador: ', busqueda)
        
        try{
            onPromoClick(seccTit, secc, 'Sirena', 1)
        }catch(e){
            console.log('onPromoClick: ',e)
        }

        window.open(`https://api.whatsapp.com/send?phone=${VARIANTS_PER_PORTAL.sirenaNumero}&text=${message}${busqueda.replace(/-/g, ' ')}`, '_blank');
    }


    const [scrollActual, setScrollActual] = useState(window.scrollY)
    const mostrarOcultar = () => {
        scrollActual <= window.scrollY ? document.querySelector('.whatsapp-container .text').classList.add('close') : document.querySelector('.whatsapp-container .text').classList.remove('close');
        setScrollActual(window.scrollY)
    }
    useEffect(() => {
        if(VARIANTS_PER_PORTAL?.sirenaSearch){
            window.addEventListener("scroll", mostrarOcultar);
        }
        return () => window.removeEventListener("scroll", mostrarOcultar);
    },[scrollActual, VARIANTS_PER_PORTAL?.sirenaSearch])

    return(
        VARIANTS_PER_PORTAL?.sirenaSearch
            ?
                <div id='waSanborns'>
                    <div className="whatsapp-container">
                        <div className="text">
                            <div className="triangle"></div>
                            <p>Contacta a un vendedor!</p>
                        </div>
                        <div className="whatsapp-icon-container" onClick={promoClick} aria-label="Boton ayuda WhastApp">
                            <i className="fa fa-whatsapp"></i>
                        </div>
                    </div>
                </div>
            : null
    )
}


export function WhatsBuscadorCard(props){
    const {
        busqueda
    } = props

    const [text1, setText1] = useState()
    const [text2, setText2] = useState()

    useEffect(()=>{
        if(busqueda !== ""){
            setText1("¿No encuentras lo que necesitas?")
            setText2("Contáctanos")
        }else{
            setText1("Contáctanos.")
            setText2("Te ayudamos a encontrar lo que necesitas")
        }
    },[busqueda])

    const promoClick = () => {

        console.log('WhatsBuscador: ', busqueda)
        
        try{
            onPromoClick('Busqueda Resultado', 'Buscador', 'Sirena', 1)
        }catch(e){
            console.log('onPromoClick: ',e)
        }

        window.open(`https://api.whatsapp.com/send?phone=${VARIANTS_PER_PORTAL.sirenaNumero}&text=Necesito%20ayuda%20con%20mi%20b%C3%BAsqueda:%20${busqueda}`, '_blank');

    }

    return(
        VARIANTS_PER_PORTAL?.sirenaSearch
            ?
                <div onClick={promoClick} aria-label="Boton ayuda WhastApp" className="buscaWhats">
                    <div className="card">
                        <i className="fa fa-whatsapp"></i>
                        <div className="contentText">
                            <p className="text">{text1}</p>
                            <p className="text2">{text2}</p>
                        </div>
                    </div>
                </div>
            : null
    )
}