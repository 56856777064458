import React, { useState, useEffect } from 'react';
import { getAction, getPedido, LoginAyudaDinamica } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import './ayudaDinamicaDesktop.sass';
import { removeDuplicates } from 'functions/removeDuplicates';
import Loading from 'components/Loading';
import { useStorage64 } from 'hooks/useStorageBase64';
import { removeStorage } from 'functions/storageBase64';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { USERNAME, PASSWORD, PUBLICKEY } from 'constants/general';

export function CambioDevolucionDesktop(props) {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [ima, setima] = useState([]);

    const [loading, setLoading] = useState(false);
    const [action, setAction] = useState(false);
    const [listAction, setListAction] = useState(false);
    const [, setStorageCD] = useStorage64('storageCD', '');
    const [, setActionId] = useStorage64("storageActionId", "");

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if ((action && action.action) && (resultadoPedido && resultadoPedido.pedido)) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido, action])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // obtiene servicio pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido)
    }, [])

    useEffect(() => {
        let imagenProducto = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido) {
                // recorre por producto
                if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {
                    if (resultadoPedido.pedido[0]) {
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem.images[0].url, "id": elem.related_product_id })
                        )

                        let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                        setima(limpioImagen);
                    }
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
                LoginAyudaDinamica({ username: USERNAME, password: PASSWORD, publicKey: PUBLICKEY })
                setTimeout(() => {
                    getPedido()
                        .then(setResultadoPedido)
                }, 1000);
            }
        }
    }, [resultadoPedido])

    // obtengo list action id
    useEffect(() => {
        let reasonId = 9;

        getAction(reasonId)
            .then(setAction)
    }, [])

    // almaceno listado de action
    useEffect(() => {
        let arrayAction = [];
        let reasonId = 9;

        if (action) {
            if (action.action) {
                action.action.map((act, i) =>
                    arrayAction.push({ "id": act.id, "name": act.name })
                )
                setListAction(arrayAction);
            }
            else if (action.statusCode && action.statusCode === 401) {
                LoginAyudaDinamica({ username: USERNAME, password: PASSWORD, publicKey: PUBLICKEY })
                setTimeout(() => {
                    getAction(reasonId)
                        .then(setAction)
                }, 1000);
            }
        }
    }, [action])


    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    // redirecciona a cual es tu motivo
    const handleContinuarAseleccion = (id) => {
        removeStorage('storageCD');
        setStorageCD(id);
        setActionId(id);

        // lleva a solicitar cambio 9 --- lleva a solicitar devolucion 10
        history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/solicitar-cambio-devolucion");
    }

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            {loading && <Loading />}

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="miCuentaContainer">
                    <div className="containerAD">
                        <div className="boxBtnRegresarDktop">
                            <div className="icoBack" onClick={onClose}></div>
                            <button className="btnBackAD" onClick={onClose}>Atrás</button>
                        </div>

                        <div className="boxGeneralAD">

                            <div className="boxLeftAD">
                                <div className="contSectionRed">
                                    <div className={ima.length > 1 ? "imaDesktop more" : "imaDesktop"}>
                                        {ima.length > 0 && ima.map((elemento, i) => i <= 3 && <img key={i} src={elemento.url} alt={"imagen" + i} width={70} />)}
                                    </div>

                                    <div className="textDektop"> <p>¿Qué quieres hacer?</p> </div>

                                </div>
                            </div>

                            <div className="boxRightAD">
                                <div className="boxUlRazonesAD">
                                    <ul className="">
                                        {listAction && listAction.map((problem) =>
                                            <li id={problem.id} key={problem.id} onClick={() => handleContinuarAseleccion(problem.id)}>
                                                <span className="nombreRazon">{problem.name}</span>
                                                <span className="iconoRazon"></span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}