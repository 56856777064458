import React, { useEffect, useState } from 'react';
import './style.sass';
import ModalCategoria from './modalCategoria';
import ModalMarca from './modalMarca';
import ReactDOM from 'react-dom';
import '../Modal/style.sass';
import ModalDiscount from './modalDiscount';
import { ComponentViewTypeMobile } from 'components/filtros/BoxVistas';
import InputRange from 'react-input-range';

export default function PageFilters({ isShowing, toggle, activarOrdenar, valor, filtrar, limpiar, listaCategorias, listaMarcas,
    categoriaVisible, setCategoriaVisible, idCategoria, categoria, seleccionarCategoria, limpiarCategoria,
    limpiarMarca, filtrarMarcas, setMarcasVisible, checkedItem, marcasVisible, seleccionarMarca,
    precioMenor, PrecioMax, precioMin, precioMax, minimo, maximo, setCategoria,
    HandleActivarEstrellas, activarEstrellas, handleEnvioGratis, activarEnvioGratis,
    activarFullfilment, handleActivarFullfilment,
    setDiscountVisible, discountVisible, limpiarDiscount, checkedDiscount, seleccionarDiscount, setCheckedDiscount,
    params, tieneEnvioGratis, tieneFulfillment, tieneReview, vistaMosaico, valorDescuento,

    actualizarMaxDesdeBarra, actualizarMinDesdeBarra
}) {

    // abrir modal categorias
    const verCategorias = () => {
        setCategoriaVisible(!categoriaVisible);
        setMarcasVisible(false);
        setDiscountVisible(false);
    };

    // abrir modal marcas
    const verMarcas = () => {
        setMarcasVisible(!marcasVisible);
        setCategoriaVisible(false);
        setDiscountVisible(false);
    };

    // abrir modal descuento
    const verDiscount = () => {
        setDiscountVisible(!discountVisible);
        setCategoriaVisible(false);
        setMarcasVisible(false);
    };

    // funcion para solo permitir numeros en los inputs de precios
    const onKeyDown = (event) => {
        if ((event.keyCode < 48 || event.keyCode > 57)
            && (event.keyCode < 96 || event.keyCode > 105)
            && event.keyCode !== 190 && event.keyCode !== 110 && event.keyCode !== 8 && event.keyCode !== 9) {
            event.preventDefault();
        }
    }

    const [valorBarra, setValorBarra] = useState({ min: 0, max: 0 },);
    useEffect(() => {
        setValorBarra({ min: minimo, max: maximo });
    }, [minimo, maximo])

    const cambiarBarraPrecios = (e) => {
        setValorBarra(e);
    }

    useEffect(() => {
        // =! minimo
        if (Number(valorBarra.min)) {
            actualizarMinDesdeBarra(valorBarra.min);
        }
        // =! maximo
        if (Number(valorBarra.max)) {
            actualizarMaxDesdeBarra(valorBarra.max);
        }
    }, [valorBarra.min, valorBarra.max])

    // console.log("valor de ordenamiento-> ", valor)

    return (
        ReactDOM.createPortal(
            <>
                {isShowing
                    ? <div className="modalFiltros full ventanaFiltros">
                        <div className="contentModal">
                            <div className="contentClose">
                                <div className="izquierda">
                                    <span onClick={toggle} className=""> </span>
                                    <h2>Filtros</h2>
                                </div>
                                <div className="derecha">
                                    <span className="limpiarText" onClick={limpiar}></span>
                                </div>
                            </div>

                            <div className="dataModal">
                                <div className="contentFilters">
                                    <div className="seccionOrdenar">
                                        <div className="titulo">
                                            <h4>Ordenar por</h4>
                                        </div>

                                        <div className="pushOrden">
                                            <span className={valor === 1 ? "activar" : ' push'} onClick={activarOrdenar} id="1">Relevancia</span>
                                            <span className={valor === 3 ? "activar" : ' push'} onClick={activarOrdenar} id="3">Mayor precio</span>
                                            <span className={valor === 2 ? "activar" : ' push'} onClick={activarOrdenar} id="2">Menor precio</span>
                                        </div>
                                    </div>

                                    <ComponentViewTypeMobile />

                                    {listaCategorias && <div className="seccionCategoria" onClick={verCategorias}>
                                        <p className="pCategoria">Categorías <span className="flechaRight"></span></p>
                                        <p className={categoria !== '' ? "seleccionadaC" : "ocultar"}>

                                            {idCategoria !== null
                                                ? listaCategorias.map((cat) => Number(cat.id) === Number(idCategoria) && setCategoria(cat.name))
                                                : null
                                            }
                                            {categoria}

                                        </p>
                                    </div>
                                    }

                                    {listaMarcas && <div className="seccionMarca" onClick={verMarcas}>
                                        <p className="pMarca">Marcas <span className="flechaRight"></span></p>
                                        <p className={checkedItem !== '' ? "seleccionadaM" : " ocultar"}>
                                            {checkedItem !== null &&
                                                <> {decodeURI(checkedItem)}</>
                                            }
                                        </p>
                                    </div>
                                    }

                                    {valorDescuento > 0 && <div className="seccionDiscount" onClick={verDiscount}>
                                        <p className="pDiscount">Descuento <span className="flechaRight"></span></p>
                                        <p className={(String(checkedDiscount) !== '' && checkedDiscount > 0) ? "seleccionada" : " ocultar"}>{checkedDiscount}%</p>
                                    </div>
                                    }

                                    {tieneReview === true
                                        ? <div className="seccionEstrellasM">
                                            <div className="cajaEstrellas">
                                                <div className="estrellas">
                                                    <span className="startYellow" id="1"></span>
                                                    <span className="startYellow" id="2"></span>
                                                    <span className="startYellow" id="3"></span>
                                                    <span className="startYellow" id="4"></span>
                                                    <p>o más</p>
                                                </div>

                                                <div className="cajaSwitch">
                                                    <label>
                                                        <input type="checkbox" className="input" checked={activarEstrellas} onChange={HandleActivarEstrellas}></input>
                                                        <div className="toggle">
                                                            <span className="selector"></span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {tieneEnvioGratis === true
                                        ? <div className="seccionEnvioFiltros">
                                            <div className="cajaEnvio">
                                                <div className="cajaTexto">
                                                    <p> Envío gratis</p>
                                                </div>

                                                <div className="cajaSwitch">
                                                    <label>
                                                        <input type="checkbox" className="input" checked={activarEnvioGratis} onChange={handleEnvioGratis}></input>
                                                        <div className="toggle">
                                                            <span className="selector"></span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {tieneFulfillment === true
                                        ? <div className="seccionFulfillmentM">
                                            <div className="cajaFulfillment">
                                                <div className="cajaTexto">
                                                    <img className="express" src="./img/Express.svg" alt="express"></img>
                                                </div>

                                                <div className="cajaSwitch">
                                                    <label>
                                                        <input type="checkbox" className="input" checked={activarFullfilment} onChange={handleActivarFullfilment}></input>
                                                        <div className="toggle">
                                                            <span className="selector"></span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="barraFull"></div>
                                        </div>
                                        : null
                                    }

                                    {(precioMenor || PrecioMax)
                                        && <div className="texto tituloPrecio">
                                            <p>Precio</p>
                                        </div>
                                    }
                                    {maximo && minimo
                                        ? <div className="sliderPrecio">
                                            <InputRange
                                                maxValue={maximo}
                                                minValue={minimo}
                                                value={valorBarra}
                                                onChange={(e) => cambiarBarraPrecios(e)}
                                                step={2}
                                            />
                                        </div>
                                        : null
                                    }

                                    <div className="seccionPrecio">
                                        <div className="precios">
                                            {precioMenor && Number(precioMenor) !== 0 && minimo

                                                ? <div className='fL'>
                                                    <label>$</label>
                                                    <input type="number" placeholder={"Min $" + minimo.toLocaleString("en-US")} onChange={precioMin} value={precioMenor} pattern="[0-9]{0,13}" onKeyDown={onKeyDown}></input>
                                                </div>
                                                : minimo
                                                    ? <div className='fL'>
                                                        <label>$</label>
                                                        <input type="number" placeholder={"Min $" + minimo.toLocaleString("en-US")} onChange={precioMin} value="" pattern="[0-9]{0,13}" onKeyDown={onKeyDown}></input>
                                                    </div>
                                                    : null
                                            }
                                            {PrecioMax && Number(PrecioMax) !== 0 && maximo
                                                ? <div className='fR'>
                                                    <label className='labelR'>$</label>
                                                    <input className="precioR" type="number" placeholder={"Max $" + maximo.toLocaleString("en-US")} onChange={precioMax} value={PrecioMax} pattern="[0-9]{0,13}" onKeyDown={onKeyDown}></input>
                                                </div>
                                                : maximo
                                                    ? <div className='fR'>
                                                        <label className='labelR'>$</label>
                                                        <input className="precioR" type="number" placeholder={"Max $" + maximo.toLocaleString("en-US")} onChange={precioMax} value="" pattern="[0-9]{0,13}" onKeyDown={onKeyDown}></input>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>

                                </div>
                                <div className="botonFiltrar">
                                    <button className="bFiltrar" onClick={() => filtrar(idCategoria, checkedItem, activarEstrellas, activarEnvioGratis, checkedDiscount, valor, vistaMosaico, activarFullfilment)}>Aplicar filtros</button>
                                </div>

                                {categoriaVisible &&
                                    <ModalCategoria idCategoria={idCategoria} limpiarCategoria={limpiarCategoria} verCategorias={verCategorias} seleccionarCategoria={seleccionarCategoria} listaCategorias={listaCategorias} />
                                }

                                {marcasVisible &&
                                    <ModalMarca checkedItem={checkedItem} verMarcas={verMarcas} limpiarMarca={limpiarMarca} seleccionarMarca={seleccionarMarca} listaMarcas={listaMarcas} filtrarMarcas={filtrarMarcas} />
                                }

                                {discountVisible &&
                                    <ModalDiscount valorDescuento={valorDescuento} setCheckedDiscount={setCheckedDiscount} params={params} checkedDiscount={checkedDiscount} limpiarDiscount={limpiarDiscount} verDiscount={verDiscount} seleccionarDiscount={seleccionarDiscount} />
                                }
                            </div>
                        </div>
                    </div>
                    : null
                }
            </>, document.getElementById('modal-root')
        )
    )
}