import React from 'react';
import '../detallePedido/detallePedido.sass';
import './ayudaDinamica.sass';

export function ModalEstasSeguroProducto(props) {
    const {
        remvoeAction,
        awaitAction
    } = props

    const elimiar = () => remvoeAction(true)

    const recapacitar = () => awaitAction(true)

    return (
        <>
            <div className="modalRemoveAddress">
                <div className="boxTextos">
                    <h3>¿Estás seguro que deseas cancelar este producto?</h3>
                </div>

                <div className="contBtns">
                    <div className="btn blanco" onClick={recapacitar}>Cancelar</div>
                    <div className="btnContinuar" onClick={elimiar}>Si, continuar</div>
                </div>
            </div>
        </>
    )

}

export function ModalEstasSeguro(props) {
    const {
        remvoeAction,
        awaitAction
    } = props

    const elimiar = () => remvoeAction(true)

    const recapacitar = () => awaitAction(true)

    return (
        <>
            <div className="modalRemoveAddress">
                <div className="boxTextos">
                    <h3>¿Estás seguro que deseas cancelar tu pedido?</h3>

                    <p>Se cancelarán todos los productos de tu pedido.</p>
                </div>

                <div className="contBtns">
                    <div className="btn blanco" onClick={recapacitar}>Cancelar</div>
                    <div className="btnContinuar" onClick={elimiar}>Si, continuar</div>
                </div>
            </div>
        </>
    )

}

export function ModalError(props) {
    const {
        okRegresar
    } = props

    return (
        <>
            <div className="modalRemoveAddress">
                <div className="boxTextos">
                    <h4>Estamos procesando tu solicitud. Revisa el estatus en Mis Pedidos, si no ves reflejado el cambio intenta nuevamente.</h4>
                </div>

                <div className="contBtns">
                    <div className="btnContinuar" onClick={okRegresar}>Regresar a mis pedidos</div>
                </div>
            </div>
        </>
    )

}

export function ModalOcurrioUnError(props){
    const {
        okRegresar
    } = props

    return (
        <>
            <div className="modalRemoveAddress">
                <div className="boxTextos">
                    <h4>¡Algo salió mal! Intenta nuevamente.</h4>
                </div>

                <div className="contBtnsEnterado">
                    <div className="btnContinuar" onClick={okRegresar}>Enterado</div>
                </div>
            </div>
        </>
    )
}