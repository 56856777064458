import { decodeStorage } from 'functions/storageBase64';
import { ENVIO_GRATIS } from 'constants/general'

//New
export const getCartAdapter = (data) =>{

    const cart_Hash = decodeStorage('idCarrito')

    let newData, statusC
    if(data?.length >= 1){
        statusC = 200
        let total_active = data.filter(product => product?.is_active === true &&  product?.stock >= 1)
        let free_active = total_active.filter(freeA => freeA?.shipping?.is_free === true)
        let totalPrice = total_active.map(price => {
            return price?.pricing?.sales_price * price?.cart_quantity
        }).reduce((prev, curr) => prev + curr, 0)
        let productArray = data?.map(product => {
            let producto = {
                ...product,
                status: product?.is_active,
                is_digital: product?.features?.digital ? product?.features?.digital : false,
                price: product?.pricing?.list_price,
                sale_price: product?.pricing?.sales_price,
                discount: product?.pricing?.percentage_discount,
                selected_size_color: {},
                size_color: [],
                images: [{
                    order: 1,
                    thumbnail: product?.pictures[0]?.thumbnail,
                    url: product?.pictures[0]?.source
                }]
            }
            return producto
        })

        newData = {
            hash: cart_Hash,
            products: productArray,
            shipping: {
                is_free: Boolean( free_active?.length  ) || totalPrice >= ENVIO_GRATIS
            },
            total_price: totalPrice,
            total_items: total_active?.length,
        }
    }else{
        statusC = 404
    }

    // console.log('new statusC: ', statusC)
    // console.log('new data: ', data)
    // console.log('new newData: ', newData)

    return {
        headers: {'content-type': 'application/json'},
        statusCode: statusC,
        data:{
            data: newData,
            metadata: {
                http_status: statusC
            }
        },
        error: ''

    }
}

//Old
export const getCartAdapterOld = (data) =>{
    
    let newData
    if(data){
        newData = {
            ...data
        }
    }

    // console.log('clasic data: ', data)
    // console.log('clasic newData: ', newData)

    return data  
}
