import React, { useState, useEffect } from 'react'
import { encodeStorage, decodeStorage, encodeStorageLatin } from 'functions/storageBase64'
import { removeStorage } from 'functions/storageBase64'
import{ useHistory, useLocation} from 'react-router-dom'
import Toast from 'components/Toast'
import postProductCart from 'services/postProductCart'
import Loading from 'components/Loading'
import { dataLayerCartAdd, dataLayerCartAddOld } from 'functions/dataLayerFunction'
import ModalPre from 'components/Modal'
import { ModalPreLogin } from 'components/LoginRegistro/modals'

import { useCart } from 'hooks/useCart'

export default function ButtonBuyNow({...props}){
    const {
        id,
        cantProduct,
        sizeColorSKU = null,
        size_colorExist,
        title,
        sale_price,
        brand,
        category,
        variant,
        tallaText
    } = props

    const history = useHistory()
    
    const location = useLocation()
    const urlLink = window.location.href
    const hashLink = window.location.hash
    
    //const hostName = window.location.href
    const hostName = window.location.origin + location.pathname

    const isLoged = decodeStorage('session_token')
    const productAfter = decodeStorage('productAfter')
    const okBuyProductAfter = decodeStorage('okBuyProductAfter')

    const [loading, setLoading ] = useState(false)
    const [addCart, setAddCart] = useState(false)
    const [responseCart, SetResponseCart] = useState({})
    const [toast, setToast ] = useState(false)
    const [messageToast, setMessageToast] = useState({type:'', time:'', textMessage:''})

    const { 
        cartCount, 
        carrito,
        okAdd,
        Product_Add,
        Product_Update,
        Product_Delete
    } = useCart({firstActive: false, name: "btn-buyNow", timeRef: 1000})


    useEffect(()=>{
        if(okAdd){
            // console.log('btn-buyNow add: ', okAdd)
            // console.log('btn-buyNow: ',carrito)
            SetResponseCart(carrito)
            history.push('/carrito/detalle')
        }
      },[okAdd, carrito])

   
    // console.log("sizeColorSKU ", sizeColorSKU, " size_colorExist:: ", size_colorExist, " variant--> ", variant, "  talla: ",tallaText)


    useEffect(()=>{
        if(isLoged){
            if(addCart){
                if(size_colorExist){
                    if(productAfter && okBuyProductAfter){
                        Product_Add([productAfter])
                        // postProductCart(productAfter)
                        // .then(SetResponseCart)
                        removeStorage('productAfter')
                        removeStorage('okBuyProductAfter')
                    }else{
                        Product_Add([{id_product:id, children_sku: sizeColorSKU?.sku, quantity: cantProduct}])
                        // postProductCart({id_product:id, children_sku: sizeColorSKU.sku, quantity: cantProduct})
                        // .then(SetResponseCart)
                    }
                }else{
                    if(productAfter && okBuyProductAfter){ 
                        Product_Add([productAfter])
                        // postProductCart(productAfter)
                        // .then(SetResponseCart)
                        removeStorage('productAfter')
                        removeStorage('okBuyProductAfter')
                    }else{
                        Product_Add([{id_product:id, children_sku: 0, quantity: cantProduct}])
                        // postProductCart({id_product:id, children_sku: 0, quantity: cantProduct})
                        // .then(SetResponseCart)
                    }
                }
                setAddCart(false)
            }
        }
    },[addCart])


    //- dataleyer agrega item
    const findRemoveItem = () =>{
        if(id){

            let catPadre, catHija, catNieta, variante
            
            let nombre = title
            let idProd = id
            let precio = sale_price
            let marca = brand
            let cantidad = cantProduct
            
            if(category){
                // console.log('categories-BN: ', category)
                if(category?.length === 1){
                    catPadre = ""
                    catHija = ""
                    catNieta = category[0].name
                }
                else if(category?.length === 2){
                    catPadre = ""
                    catHija = category[1]?.name
                    catNieta = category[0]?.name
                }
                else if(category?.length >= 3){
                    catPadre = category[2]?.name
                    catHija = category[1]?.name
                    catNieta = category[0]?.name
                } else{
                    catPadre = ""
                    catHija = ""
                    catNieta = ""
                }
            }    
            if(variant){
                variante = variant
            }else{
                variante = ""
            }
            //console.log('asdasdsa: ',{
            dataLayerCartAdd({
                item: [
                    {
                        item_name: String(nombre),
                        item_id: String(idProd),
                        price: String(precio),
                        item_brand: String(marca),
                        item_category: String(catPadre),
                        item_category2: String(catHija),
                        item_category3: String(catNieta),
                        item_variant: String(variante),
                        quantity: String(cantidad)
                    }
                ]
            })
            dataLayerCartAddOld({
                item: [
                    {
                        name: String(nombre),
                        id: String(id),
                        price: String(precio),
                        brand: String(marca),
                        category: String(catPadre+'/'+catHija+'/'+catNieta),
                        variant: String(variante),
                        quantity: String(cantidad)
                    }
                ]
            })
        }
    }

    useEffect(()=>{
        if(responseCart){
            // console.log('responseCart:  ',responseCart)
            if(Object.keys(responseCart)?.length >0){
                encodeStorage('total_items',responseCart?.total_items)
                try{
                    encodeStorage('cartFormEm', responseCart?.products)
                }
                catch{
                    encodeStorageLatin('cartFormEm', responseCart?.products)
                }
                findRemoveItem()
                setTimeout(function(){
                    history.push('/carrito/detalle')
                },300)
            }else{
                setTimeout(function(){
                    setLoading(false)
                },500)
            }
        }else{
            setTimeout(function(){
                setLoading(false)
            },500)
        }
    },[responseCart])


    const sendProduct = () =>{
        removeStorage('wishlistAfter')
        if(size_colorExist){
            if(sizeColorSKU !== null && tallaText !== null){
                if(isLoged){
                    setLoading(true)
                    setAddCart(true)
                }else{
                    encodeStorage('productAfter', {id_product:id, children_sku: sizeColorSKU.sku, quantity: cantProduct})
                    encodeStorage('typeButton', 'buttonBuyNow')
                    //history.push(`/login?redirect=${hostName}`)
                    history.push('#modalLoginShop')
                    setClickButon(true)
                    
                }
            }
            else if(sizeColorSKU !== null && tallaText == null){
                if(isLoged){
                    setLoading(true)
                    setAddCart(true)
                }else{
                    encodeStorage('productAfter', {id_product:id, children_sku: sizeColorSKU?.sku, quantity: cantProduct})
                    encodeStorage('typeButton', 'buttonBuyNow')
                    //history.push(`/login?redirect=${hostName}`)
                    history.push('#modalLoginShop')
                    setClickButon(true)
                }

            }else{
                // console.log('agrega talla color---- id:', id ," sizeColorSKU:",  sizeColorSKU?.sku, " cantidad: ", cantProduct)
                setMessageToast({type:'warning', time:'2.5', textMessage:'Falta seleccionar Talla / Color'})
                setToast(true)
            }
        }
        else{
            if(isLoged){
                setLoading(true)
                setAddCart(true)
            }else{
                encodeStorage('productAfter', {id_product:id, children_sku: 0, quantity: cantProduct})
                encodeStorage('typeButton', 'buttonBuyNow')
                //history.push(`/login?redirect=${hostName}`)
                history.push('#modalLoginShop')
                setClickButon(true)
            }
        }
    }

    useEffect(()=>{
        if(isLoged && productAfter && okBuyProductAfter){
            setLoading(true)
            setAddCart(true)
        }
    },[])

    const handleToeast = () =>{
        setToast(false)
    }

    //-modal
    const [showModal, setShowModal] = useState(false)
    const [clickButon, setClickButon] = useState(false)

    useEffect(()=>{
        if(hashLink === "#modalLoginShop"){
            if(clickButon){
                setShowModal(true)
            }
            else{
                ///history.replace(location.pathname)
            }
        }else{
            setShowModal(false)
        }
    },[hashLink, urlLink, clickButon])


    const handleClose = () => {
        setShowModal(false)
        history.replace(location.pathname)
    }
    
    // const showModalLogin = () =>{
    //     history.push('#modalLoginShop')
    //     setClickButon(true)

    // }


    return(
        <>
            <button className="btn rojoSanborns buy" onClick={sendProduct}>
                Comprar ahora
            </button>
            { loading && <Loading /> }
            {toast &&
                <Toast
                    type={messageToast.type}
                    time={messageToast.time}
                    onClose={handleToeast}
                >
                    {messageToast.textMessage}
                </Toast>
            }
            {showModal &&
                <ModalPre onClose={handleClose} type="" title="">
                    <ModalPreLogin
                        icon="img/cart_modal.svg"
                        title="Para continuar con tu compra primero inicia sesión." 
                        linkLogin={`/login?redirect=${hostName}`}
                        linkRegister={`/registro?redirect=${hostName}`}
                    />
                </ModalPre>
            }
        </>
    )
}