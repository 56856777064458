import { SEARCH_ANTEATER, PRODUCTS_PER_PAGINATION } from 'constants/general'
import axios from 'axios'

export default async function getStore({ idtienda = '', subCategory = '', searchText = '', id_Category = '', brand = '', higherPrice = '', lowerPrice = '', shipping = '', start = '', order = '', fulfillment = '', discount = '', pagination = '' } = {}) {
    var prices, brands, byOreder, byCategory, dots, dotsShip, dotsSt, byPagination, starts, shippings, id_Tienda, dotsTienda, dotsCategoria, full, dotsFull, disc, dotsDisc
    // console.log("full en servicio-->", fulfillment, " brand--> ", brand, "id_Category--> ", id_Category)
    // ${brands}${dots}${prices}${dotsSt}${starts}${dotsShip}${shippings}${dotsTienda}${id_Tienda}${dotsCategoria}${byCategory}
    brand && higherPrice && lowerPrice // si marca y precios
        ? dots = `.`
        : dots = ''

    brand && start // si marca y estrellas
        ? dotsSt = `.`
        : higherPrice && lowerPrice && start // si precios y estrellas
            ? dotsSt = `.`
            : dotsSt = ''

    shipping && idtienda && !start // si envio, tienda 
        ? dotsTienda = `.`
        : start && idtienda && !shipping // si estrellas, tienda
            ? dotsTienda = `.`
            : idtienda && higherPrice && lowerPrice && !start && !shipping // si tienda y precios
                ? dotsTienda = `.`
                : idtienda && start && shipping // si tienda, estrellas y envio
                    ? dotsTienda = `.`
                    : idtienda && brand
                        ? dotsTienda = `.`
                        : dotsTienda = ''

    brand && shipping // marca y envio
        ? dotsShip = `.`
        : start && shipping // estrellas y envio
            ? dotsShip = `.`
            : higherPrice && lowerPrice && shipping // precios y envio
                ? dotsShip = `.`
                : dotsShip = ''

    brand && id_Category
        ? dotsCategoria = `.`
        : higherPrice && lowerPrice && id_Category
            ? dotsCategoria = `.`
            : start && id_Category
                ? dotsCategoria = `.`
                : shipping && id_Category
                    ? dotsCategoria = `.`
                    : idtienda && id_Category
                        ? dotsCategoria = `.`
                        : dotsCategoria = ''

    brand && fulfillment
        ? dotsFull = `.`
        : higherPrice && lowerPrice && fulfillment
            ? dotsFull = `.`
            : start && fulfillment
                ? dotsFull = `.`
                : shipping && fulfillment
                    ? dotsFull = `.`
                    : idtienda && fulfillment //&& !brand && !higherPrice && !lowerPrice && !shipping && !start && !id_Category
                        ? dotsFull = `.`
                        : fulfillment && !brand && !higherPrice && !lowerPrice && !shipping && !start && !id_Category
                            ? dotsFull = `.`
                            : dotsFull = ''

    brand && discount
        ? dotsDisc = `.`
        : higherPrice && lowerPrice && discount
            ? dotsDisc = `.`
            : start && discount
                ? dotsDisc = `.`
                : shipping && discount
                    ? dotsDisc = `.`
                    : fulfillment && discount
                        ? dotsDisc = `.`
                        : discount && !fulfillment && !brand && !higherPrice && !lowerPrice && !shipping && !start && !id_Category
                            ? dotsDisc = `.`
                            : id_Category && discount
                                ? dotsDisc = `.`
                                : dotsDisc = ''

    higherPrice && lowerPrice
        ? prices = `sale_price>=${lowerPrice}.sale_price<=${higherPrice}`
        : prices = ''

    brand
        ? brands = `attribute_marca:${brand.replace(/\-/g,'\\-')}`
        : brands = ''

    order
        ? byOreder = `meta:sale_price:${order}`
        : byOreder = ''

    start
        ? starts = `review>3`
        : starts = ''

    shipping
        ? shippings = `shipping_price:1`
        : shippings = ''

    fulfillment
        ? full = `fulfillment:1`
        : full = ''

    discount
        ? disc = `discount>=${discount}`
        : disc = ''

    id_Category
        ? byCategory = `categories->id:${id_Category}`
        : byCategory = ''

    pagination
        ? byPagination = pagination - 1
        : byPagination = 0

    idtienda
        ? id_Tienda = `store_id:${idtienda}`
        : id_Tienda = 0

    // const apiURL = SEARCH_ANTEATER + `&proxystylesheet=xml2json&oe=UTF-8&getfields=*&start=0&num=50&requiredfields=${id_Tienda}&ds=marcas:attribute_marca:0:0:8:0:1.sale_precio:sale_price:1:1:1000.ranking:review:0:0:8:0:1.free:shipping_price:0:0:8:0:1&do=breadcrumbs:breadcrumbs:id,name,padre:10:1`
    const apiURL = SEARCH_ANTEATER + `&proxystylesheet=xml2json&oe=UTF-8&getfields=*&sort=${byOreder}&start=${byPagination * PRODUCTS_PER_PAGINATION}&num=${PRODUCTS_PER_PAGINATION}&requiredfields=${brands}${dots}${prices}${dotsSt}${starts}${dotsShip}${shippings}${dotsTienda}${id_Tienda}${dotsFull}${full}${dotsDisc}${disc}&ds=marcas:attribute_marca:0:0:200:1:0.sale_precio:sale_price:1:1:1000.ranking:review:0:0:8:0:1.full:fulfillment:0:0:8:0:1.free:shipping_price:0:0:8:0:1.discount:discount:0:0:1000:0:1&do=breadcrumbs:breadcrumbs:id,name,padre:100:1&requiredobjects=${byCategory}`

    const config = {
        method: 'get',
        url: apiURL,
        timeout: 5000,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
        }
    };

    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res

            const searchResult = data
            if (status === 200) {
                if (!Array.isArray(searchResult)) {
                    return { searchResult }
                }
                else {
                    return { 'error': status, 'response': data }
                }
            }
            return []
        })
        .catch(error => {
            //console.log(error.request.status)
            if (error.message === 'Network Error') {
                console.log('Error de conexión')
            }
            else if (error.request) {
                console.log(error.request.responseText)
            }
            if (error.request.status === 0) {
                console.log('request 0')
            } else {
                if (error.response.status === 401) {
                    console.log(error.response.data.msg)
                    return { error: error.response.status, message: error.response.data.msg }
                }
                else if (error.response.status === 404) {
                    console.error(error.response);
                    console.log('request 404')
                }
                else if (error.response.status === 440) {
                    console.log('request 440')
                }
                else if (error.response.status >= 500) {
                    console.error(error.response);
                    console.log('request 500')
                }
                else {
                    console.error(error.response.status);
                    console.error(error.response);
                }
            }
        })
}