import React, { useState, useEffect } from "react";
import { postBoletin } from "services/Boletin";
import "./style.sass";

export default function FooterNeswletter(props) {
  const { title = "¡Regístrate a nuestro Newsletter!" } = props;

  const [email, setEmail] = useState();
  const [genero, setGenero] = useState();
  const [emailEmpty, setEmailEmpty] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [emailSend, setEmailSend] = useState();
  const [emailSuccess, setEmailSuccess] = useState();

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const submitBoletin = (event) => {
    event.preventDefault();

    if (email) {
      if (email.length >= 1) {
        if (validateEmail(email)) {
          setEmailEmpty(email);
          //console.log({correo: email, genero: genero })
          postBoletin({ raw: { correo: email, genero: genero } }).then(
            setEmailSend
          );
        } else {
          setErrorMessage("El formato de correo electrónico es inválido");
        }
      } else {
        setErrorMessage("Por favor ingresa un correo electrónico");
      }
    } else {
      setErrorMessage("Por favor ingresa un correo electrónico");
      setEmailEmpty(null);
    }
  };
  const handleChangeEmail = (val) => {
    setEmail(val.currentTarget.value);
  };
  const handleChange = (val) => {
    setGenero(val.currentTarget.value);
  };

  useEffect(() => {
    if (emailSend) {
      if (emailSend.data) {
        if (emailSend.data.status) {
          if (emailSend.data.status === "success") {
            setEmailSuccess("se ha enviado correctamente");
            setEmail("");
            setTimeout(function () {
              setEmailSuccess();
            }, 2500);
          } else if (emailSend.data.status === "error") {
            setEmailEmpty();
            setErrorMessage("Por favor intenta más tarde.");
            setTimeout(function () {
              setEmailEmpty("1");
            }, 2500);
          }
        }
      } else {
        setEmailEmpty();
        setErrorMessage("Por favor intenta más tarde.");
        setTimeout(function () {
          setEmailEmpty("1");
        }, 2500);
      }
    }
  }, [emailSend]);

  return (
    <div className="foterNewsletter">
      <div className="newsletterBlock">
        <div className="nlMessage">
          <p className="h5">{title}</p>
        </div>
        {!emailEmpty && (
          <div
            id="messageBoletin"
            className="ventana_emergente erroresEmergen active"
          >
            {errorMessage}
          </div>
        )}
        {emailSuccess && (
          <div
            id="messageBoletin"
            className={
              emailSuccess ? "ventana_sussess active" : "ventana_sussess"
            }
          >
            {emailSuccess}
          </div>
        )}
        <form onSubmit={submitBoletin} className="nlform">
          <label aria-label="Tu correo electrónico">
            <input
              onChange={handleChangeEmail}
              value={email}
              type="text"
              name="newsletter"
              placeholder="Tu correo electrónico"
              className="form-control"
            />
          </label>
          <button
            name="subscription"
            onClick={handleChange}
            value="2"
            className="btn-nh"
          >
            Para Mujeres
          </button>
          <button
            name="subscription"
            onClick={handleChange}
            value="1"
            className="btn-nh"
          >
            Para Hombres
          </button>
        </form>
      </div>
    </div>
  );
}
