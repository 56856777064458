import React, {useState, useEffect} from 'react';
import CardCategory from 'components/CardCategory';
import ErrorType from 'pages/Error/ErrorType';


export function CategoriaMobile(props){
    const { response, setLoading  } = props;

    const [categorias, setCategorias] = useState([]);
    const [ errors, setErrors ] = useState('');

    //- Guarda en un estado la respuesta y evalua que sea 200 si no manda el error
    useEffect(()=>{
        if(response){
            // console.log(response.statusCode)
            if(response.statusCode === 200){
                if(typeof(response.data) === 'object'){
                    setCategorias({
                        'categoriasMenu': response.data
                    })
                }
                else{
                    setCategorias({})
                    setErrors({'error': response.statusCode ,'response': response.data.response})
                }
            }
            else if(response.statusCode){
                setErrors({'error': response.statusCode ,'response': response.data})
                setLoading(false)
            }
        }
        else if(response.statusCode !== undefined){
            setCategorias({})
            setErrors({'error': response.statusCode ,'response': response.data})
            setLoading(false)
        }
        
    },[response])

    console.log('response', response)
    console.log('categorias', categorias)
    
    useEffect( () => {
        if(categorias){
            if(Object.keys(categorias).length > 0){
                setLoading(false)
            }
        }
    },[categorias])

    return(
        <main>
            <section className="SectionCategory">
                {response.statusCode === 200
                    ?
                        categorias.categoriasMenu &&
                            <>
                                {
                                    //categorias.categoriasMenu.map(({nombre, icon, seo, id_cat, subcat, estatus, visible}) => 
                                    categorias.categoriasMenu.map(({name: nombre, icon: icon, seo, external_id: id_cat, children: subcat, status: estatus, visible: visible}) => 
                                        estatus !== 0  && visible !== 0
                                        ?
                                            <CardCategory
                                                key={id_cat}
                                                nombre={nombre}
                                                icon={icon}
                                                seo={seo}
                                                subcat={subcat}
                                                id_cat={id_cat}
                                            />
                                        :
                                            null
                                    )
                                }
                            </>
                    : null
                }
                <ErrorType
                    codeStatus={errors.error}
                    errorText={errors.response}
                />
            </section>
        </main>
    )
}