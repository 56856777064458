import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuPoliticas } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { Link, useHistory } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';

export function AvisoPrivacidadConsultorioSanbornsDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Consultorio Sanborns');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuPoliticas />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Aviso de Privacidad para Consultorio Sanborns</p>
                            </div>

                            <div className="contentPreguntas">
                                <div className="question1">
                                    <h2>¿Quién dará tratamiento a tus datos personales?</h2>

                                    <p>Los datos personales proporcionados por usted serán utilizados por Sanborn Hermanos, S. A. de C. V. (en adelante “Sanborns”) con domicilio en Lago Zurich 245, edificio Presa Falcón piso 7, colonia Ampliación Granada, Alcaldía Miguel Hidalgo, C.P. 11529, México, Ciudad de México.</p>
                                    <p>Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos a la dirección electrónica <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a>.</p>
                                </div>

                                <div className="question1">
                                    <h2>¿Qué información vamos a recabar?</h2>

                                    <p>Sanborns para cumplir con las finalidades señaladas en el presente Aviso tratará datos personales de identificación, incluyendo una fotografía de usted, datos personales de contacto, datos personales laborales y datos personales fiscales, patrimoniales y financieros.</p>
                                </div>

                                <div className="question1">
                                    <h2>No tratamiento de datos personales de menores de edad.</h2>

                                    <p>Sanborns no recaba datos personales o información directamente de menores de edad. En el supuesto de que se proporcionen datos relacionados con personas menores de edad asumiremos que la persona que los proporciona es el padre, madre y/o tutor legal, quien reconoce haber leído y aceptado el presente aviso de privacidad.</p>
                                </div>

                                <div className="question1">
                                    <h2>¿Qué información sensible vamos a recabar?</h2>

                                    <p>A fin de poder brindar atención médica Sanborns tratará los datos personales sensibles que se requieran para tal efecto: antecedes de salud, antecedentes no patológicos, padecimientos pasados y presentes, síntomas, medicación y tratamientos médicos.</p>
                                </div>

                                <div className="question1">
                                    <h2>¿Para qué fines usamos su información?</h2>

                                    <p>Los datos personales que nos ha proporcionado serán utilizados por Sanborns para las siguientes actividades primarias y necesarias:</p>

                                    <ul className="ulQuestionDisc">
                                        <li>Identificarlo y prestarle la atención debida como cliente de SANBORNS;</li>
                                        <li>Creación y gestión de su cuenta de usuario; </li>
                                        <li>Integrar su expediente clínico en términos de la normatividad aplicable; </li>
                                        <li>Brindarle atención médica; </li>
                                        <li>Contactarle a través de diversos medios de contacto físicos y/o electrónicos para atender y resolver sus dudas relacionadas con la consulta;</li>
                                        <li>Procesar pagos;</li>
                                        <li>Aclaraciones bancarias; </li>
                                        <li>Realizar consultas, investigaciones y revisiones en relación a quejas o reclamaciones; </li>
                                        <li>Para las gestiones de facturación relacionadas con los servicios proporcionados;</li>
                                        <li>Llevar un histórico de sus tratamientos médicos, transacciones y compras; </li>
                                        <li>Acumulación de puntos a través de monederos electrónicos o similares; </li>
                                        <li>Dar cumplimiento a las obligaciones legales derivadas de los servicios solicitados por usted.</li>
                                        <li>Dar cumplimiento a los requerimientos de autoridades competentes en los casos legalmente previstos; </li>
                                    </ul>
                                </div>

                                <div className="question1">
                                    <h2>¿Qué otros usos podemos darle a su información?</h2>
                                    <p>De manera adicional, si usted no se opone, SANBORNS podrá mantener su información personal para las siguientes finalidades que no son necesarias para el servicio que nos solicite, pero que nos permiten y facilitan brindarle una mejor atención:</p>

                                    <ul className="ulQuestionDisc">
                                        <li>Para posibles contactos posteriores.</li>
                                        <li>Informarle del lanzamiento o cambios de nuevos productos, bienes, servicios, promociones, eventos y ofertas de acuerdo a sus intereses, incluyendo el envío de comunicaciones a través de correos electrónicos, llamadas telefónicas, mensajes cortos y demás medios de comunicación físicos y/o electrónicos;</li>
                                        <li>Para enviarle publicidad y comunicaciones con fines de mercadotecnia, tele-marketing entre otras.</li>
                                        <li>Posibles contactos posteriores para ofrecimiento de productos, bienes y/o servicios que ofrezca Sanborns y/o Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común;</li>
                                        <li>Compartir sus perfiles y hábitos de consumo con nuestros socios comerciales y/o Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común para que éstas puedan contactarle y enviarle información de carácter publicitario, promocional y/o informativo que consideren de su interés y/o para que puedan valorar, conforme a sus procedimientos internos, si usted es susceptible de obtener el servicio que hubiere solicitado ante dicho tercero;</li>
                                        <li>Para enviarle publicidad y comunicaciones con fines de mercadotecnia, tele-marketing entre otras.</li>
                                    </ul>

                                    <p>En caso de que no desee que sus datos personales sean tratados para alguna o todas las finalidades adicionales, desde este momento usted nos puede comunicar lo anterior al correo <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>
                                    <p>La negativa para el uso de sus datos personales para fines adicionales, no podrá ser un motivo para negarle los servicios solicitados o dar por terminada la relación establecida con nosotros.</p>
                                </div>

                                <div className="question1">
                                    <h2>¿A qué terceros podemos transferir su información?</h2>

                                    <p>Sanborns podrá transferir, sin requerir de su consentimiento, los datos personales necesarios a autoridades competentes en los casos legalmente previstos, especialmente a autoridades en materia de salud para dar cumplimiento a lo dispuesto en la Ley General de Salud, así como a cualquier empresa Subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común, las cuales operan bajo políticas de privacidad que cumplen con las disposiciones aplicables correspondientes, para dar cumplimiento a las finalidades señaladas en el presente aviso.</p>
                                    <p>Para conocer cuáles son las Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común a quienes sus datos son transferidos puede consultarlo en http://www.gsanborns.com.mx/perfil-corporativo.html</p>
                                    <p>En caso de que no desee que sus datos personales sean transferidos para alguna o todas las finalidades señaladas, desde este momento usted nos puede comunicar lo anterior al correo <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>
                                </div>

                                <div className="question1">
                                    <h2>¿Cómo puede ejercer sus derechos ARCO y/o revocar tu consentimiento?</h2>

                                    <p>Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en lo sucesivo “derechos Arco”), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Protección de Datos de SANBORNS a la dirección electrónica arco@sanborns.com.mx  Su petición deberá ser realizada a través del Formato de Solicitud de Derechos ARCO, el cual debe ser solicitado a la dirección de correo electrónico antes señalada. Para que el Departamento de Protección de Datos de SANBORNS pueda darle seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo que es necesario que complete todos los campos indicados en el Formato y lo acompañe con copia de alguna de las identificaciones oficiales vigentes que se señalan en el mismo. Para obtener mayor información sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento contacte al Departamento de Protección de Datos de Sanborns.</p>
                                </div>

                                <div className="question1">
                                    <h2>¿Cómo puede limitar el uso y/o divulgación de sus datos?</h2>

                                    <p>Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la dirección <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a>. En caso de que su Solicitud sea procedente se le registrará en el listado de exclusión propio de SANBORNS. Para mayor información favor de contactar al Departamento de Protección de Datos de SANBORNS.</p>
                                </div>

                                <div className="question1">
                                    <h2>¿Cómo le notificaremos de los cambios que efectuemos al presente Aviso de Privacidad?</h2>

                                    <p>Sanborns le notificará de cualquier cambio a su aviso de privacidad a través de nuestra página de internet  <Link to="/">www.sanborns.com.mx</Link> por lo cual le pedimos revise la misma de manera periódica.</p>
                                </div>

                                <div className="question1">
                                    <h2>¿Cómo puede hacernos llegar sus dudas?</h2>

                                    <p>Nuestro Departamento de Protección de Datos queda a sus órdenes para proporcionarle cualquier información adicional que requiera o, en su caso, para resolver cualquier duda que pudiera surgirle en materia de privacidad y protección de datos personales, para lo que podrá contactarnos a través del correo electrónico  <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>
                                </div>

                                <div className="question1">
                                    <h2>INAI</h2>

                                    <p>En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) para hacer efectivos sus derechos.</p>
                                </div>

                                <div className="question1">
                                    <h2>Aceptación de Aviso de Privacidad</h2>

                                    <p>En caso de utilizar nuestros servicios se entenderá que autoriza el uso de sus datos personales de conformidad con lo establecido en el presente Aviso de Privacidad.</p>
                                    <p className='bold'>Fecha de última actualización: 01 de Julio de 2020.</p>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}

export function AvisoPrivacidadConsultorioSanbornsMobile() {
    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Consultorio Sanborns');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN">
                        <div className="btn closeBack" onClick={onClose} ></div>
                        <p className="title">Aviso de Privacidad para Consultorio Sanborns</p>
                    </div>

                    <div className="dataModal">

                        <div className="boxAN">
                            <div className='bxSlider'>
                                <p className="pSliderTitleL">¿Quién dará tratamiento a tus datos personales?</p>
                                <p className="pSliderL">Los datos personales proporcionados por usted serán utilizados por Sanborn Hermanos, S. A. de C. V. (en adelante “Sanborns”) con domicilio en Lago Zurich 245, edificio Presa Falcón piso 7, colonia Ampliación Granada, Alcaldía Miguel Hidalgo, C.P. 11529, México, Ciudad de México.</p>
                                <p className="pSliderL">Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos a la dirección electrónica <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a>.</p>

                                <p className="pSliderTitleL">¿Qué información vamos a recabar?</p>
                                <p className="pSliderL">Sanborns para cumplir con las finalidades señaladas en el presente Aviso tratará datos personales de identificación, incluyendo una fotografía de usted, datos personales de contacto, datos personales laborales y datos personales fiscales, patrimoniales y financieros.</p>

                                <p className="pSliderTitleL">No tratamiento de datos personales de menores de edad.</p>
                                <p className="pSliderL">Sanborns no recaba datos personales o información directamente de menores de edad. En el supuesto de que se proporcionen datos relacionados con personas menores de edad asumiremos que la persona que los proporciona es el padre, madre y/o tutor legal, quien reconoce haber leído y aceptado el presente aviso de privacidad.</p>

                                <p className="pSliderTitleL">¿Qué información sensible vamos a recabar?</p>
                                <p className="pSliderL">A fin de poder brindar atención médica Sanborns tratará los datos personales sensibles que se requieran para tal efecto: antecedes de salud, antecedentes no patológicos, padecimientos pasados y presentes, síntomas, medicación y tratamientos médicos.</p>

                                <p className="pSliderTitleL">¿Para qué fines usamos su información?</p>
                                <p className="pSliderL">Los datos personales que nos ha proporcionado serán utilizados por Sanborns para las siguientes actividades primarias y necesarias:</p>

                                <ul className="ulDeclaracionesDisc">
                                    <li>Identificarlo y prestarle la atención debida como cliente de SANBORNS;</li>
                                    <li>Creación y gestión de su cuenta de usuario; </li>
                                    <li>Integrar su expediente clínico en términos de la normatividad aplicable; </li>
                                    <li>Brindarle atención médica; </li>
                                    <li>Contactarle a través de diversos medios de contacto físicos y/o electrónicos para atender y resolver sus dudas relacionadas con la consulta;</li>
                                    <li>Procesar pagos;</li>
                                    <li>Aclaraciones bancarias; </li>
                                    <li>Realizar consultas, investigaciones y revisiones en relación a quejas o reclamaciones; </li>
                                    <li>Para las gestiones de facturación relacionadas con los servicios proporcionados;</li>
                                    <li>Llevar un histórico de sus tratamientos médicos, transacciones y compras; </li>
                                    <li>Acumulación de puntos a través de monederos electrónicos o similares; </li>
                                    <li>Dar cumplimiento a las obligaciones legales derivadas de los servicios solicitados por usted.</li>
                                    <li>Dar cumplimiento a los requerimientos de autoridades competentes en los casos legalmente previstos; </li>
                                </ul>

                                <p className="pSliderTitleL">¿Qué otros usos podemos darle a su información?</p>
                                <p className="pSliderL">De manera adicional, si usted no se opone, SANBORNS podrá mantener su información personal para las siguientes finalidades que no son necesarias para el servicio que nos solicite, pero que nos permiten y facilitan brindarle una mejor atención:</p>

                                <ul className="ulDeclaracionesDisc">
                                    <li>Para posibles contactos posteriores.</li>
                                    <li>Informarle del lanzamiento o cambios de nuevos productos, bienes, servicios, promociones, eventos y ofertas de acuerdo a sus intereses, incluyendo el envío de comunicaciones a través de correos electrónicos, llamadas telefónicas, mensajes cortos y demás medios de comunicación físicos y/o electrónicos;</li>
                                    <li>Para enviarle publicidad y comunicaciones con fines de mercadotecnia, tele-marketing entre otras.</li>
                                    <li>Posibles contactos posteriores para ofrecimiento de productos, bienes y/o servicios que ofrezca Sanborns y/o Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común;</li>
                                    <li>Compartir sus perfiles y hábitos de consumo con nuestros socios comerciales y/o Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común para que éstas puedan contactarle y enviarle información de carácter publicitario, promocional y/o informativo que consideren de su interés y/o para que puedan valorar, conforme a sus procedimientos internos, si usted es susceptible de obtener el servicio que hubiere solicitado ante dicho tercero;</li>
                                    <li>Para enviarle publicidad y comunicaciones con fines de mercadotecnia, tele-marketing entre otras.</li>
                                </ul>

                                <p className="pSliderL">En caso de que no desee que sus datos personales sean tratados para alguna o todas las finalidades adicionales, desde este momento usted nos puede comunicar lo anterior al correo <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>
                                <p className="pSliderL">La negativa para el uso de sus datos personales para fines adicionales, no podrá ser un motivo para negarle los servicios solicitados o dar por terminada la relación establecida con nosotros.</p>

                                <p className="pSliderTitleL">¿A qué terceros podemos transferir su información?</p>
                                <p className="pSliderL">Sanborns podrá transferir, sin requerir de su consentimiento, los datos personales necesarios a autoridades competentes en los casos legalmente previstos, especialmente a autoridades en materia de salud para dar cumplimiento a lo dispuesto en la Ley General de Salud, así como a cualquier empresa Subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común, las cuales operan bajo políticas de privacidad que cumplen con las disposiciones aplicables correspondientes, para dar cumplimiento a las finalidades señaladas en el presente aviso.</p>
                                <p className="pSliderL">Para conocer cuáles son las Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común a quienes sus datos son transferidos puede consultarlo en http://www.gsanborns.com.mx/perfil-corporativo.html</p>
                                <p className="pSliderL">En caso de que no desee que sus datos personales sean transferidos para alguna o todas las finalidades señaladas, desde este momento usted nos puede comunicar lo anterior al correo <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>

                                <p className="pSliderTitleL">¿Cómo puede ejercer sus derechos ARCO y/o revocar tu consentimiento?</p>
                                <p className="pSliderL">Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en lo sucesivo “derechos Arco”), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Protección de Datos de SANBORNS a la dirección electrónica arco@sanborns.com.mx  Su petición deberá ser realizada a través del Formato de Solicitud de Derechos ARCO, el cual debe ser solicitado a la dirección de correo electrónico antes señalada. Para que el Departamento de Protección de Datos de SANBORNS pueda darle seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo que es necesario que complete todos los campos indicados en el Formato y lo acompañe con copia de alguna de las identificaciones oficiales vigentes que se señalan en el mismo. Para obtener mayor información sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento contacte al Departamento de Protección de Datos de Sanborns.</p>

                                <p className="pSliderTitleL">¿Cómo puede limitar el uso y/o divulgación de sus datos?</p>
                                <p className="pSliderL">Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la dirección <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a>. En caso de que su Solicitud sea procedente se le registrará en el listado de exclusión propio de SANBORNS. Para mayor información favor de contactar al Departamento de Protección de Datos de SANBORNS.</p>

                                <p className="pSliderTitleL">¿Cómo le notificaremos de los cambios que efectuemos al presente Aviso de Privacidad?</p>
                                <p className="pSliderL">Sanborns le notificará de cualquier cambio a su aviso de privacidad a través de nuestra página de internet  <Link to="/">www.sanborns.com.mx</Link> por lo cual le pedimos revise la misma de manera periódica.</p>

                                <p className="pSliderTitleL">¿Cómo puede hacernos llegar sus dudas?</p>
                                <p className="pSliderL">Nuestro Departamento de Protección de Datos queda a sus órdenes para proporcionarle cualquier información adicional que requiera o, en su caso, para resolver cualquier duda que pudiera surgirle en materia de privacidad y protección de datos personales, para lo que podrá contactarnos a través del correo electrónico  <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>

                                <p className="pSliderTitleL">INAI</p>
                                <p className="pSliderL">En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) para hacer efectivos sus derechos.</p>

                                <p className="pSliderTitleL">Aceptación de Aviso de Privacidad</p>
                                <p className="pSliderL">En caso de utilizar nuestros servicios se entenderá que autoriza el uso de sus datos personales de conformidad con lo establecido en el presente Aviso de Privacidad.</p>
                                <p className='pSliderL bold'>Fecha de última actualización: 01 de Julio de 2020.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function AvisoPrivacidadConsultorioSanborns() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Consultorio Sanborns');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {isMobile &&
                isMobile === 'desktop'
                ? <AvisoPrivacidadConsultorioSanbornsDesktop />

                : isMobile === 'movile'
                    ? null
                    : <AvisoPrivacidadConsultorioSanbornsMobile />
            }
        </>
    )
}