import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import "./style.sass";

export function MenuAutocenter() {

    return (
        <nav className="menuAutocenter">
            <ul className="ulAuto">
                <li className="liAuto alone">
                    <p>Ayuda</p>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/como-comprar/" activeClassName="active">¿Cómo comprar?</NavLink>
                </li>
                <li className="liAuto alone">
                    <NavLink to="/preguntas-frecuentes" activeClassName="active">Preguntas Frecuentes</NavLink>
                </li>
                <li className="liAuto alone">
                    <NavLink to="/sitio-de-interes" activeClassName="active">Sitio de interés</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/departamentos/" activeClassName="active">Categorías</NavLink>
                </li>
            </ul>
        </nav>
    )
}

export function MenuPoliticas() {

    return (
        <nav className="menuAutocenter">
            <ul className="ulAuto">
                <li className="liAuto alone">
                    <p>Legales</p>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/aviso-privacidad" activeClassName="active" >Aviso de Privacidad</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/aviso-privacidad-store-BTS" activeClassName="active">Aviso de Privacidad Evento Pop Up Store BTS</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/aviso-privacidad-prospectos" activeClassName="active">Aviso de Privacidad Prospectos</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/aviso-privacidad-redes-sociales" activeClassName="active">Aviso de Privacidad Redes Sociales</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/aviso-privacidad-consultorio-sanborns" activeClassName="active">Aviso de Privacidad para Consultorio Sanborns</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/aviso-privacidad-tecolotes-sanborns" activeClassName="active">Aviso de Privacidad para APP Los Tecolotes de Sanborns</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/aviso-privacidad-circulo-de-bienestar" activeClassName="active">Aviso de Privacidad Círculo de Bienestar</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/aviso-privacidad-circulo-plus" activeClassName="active">Aviso de Privacidad Círculo Plus</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/aviso-privacidad-prospecto-credito" activeClassName="active">Aviso de Privacidad Prospecto de Crédito</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/contrato-credito" activeClassName="active">Contrato Crédito</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/terminos-condiciones/" activeClassName="active" >Términos y Condiciones</NavLink>
                </li>
                <li className="liAuto alone">
                    <NavLink to="/legal" activeClassName="active" >Información Legal</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/politica-devolucion" activeClassName="active">Política de Devolución</NavLink>
                </li>
                <li className="liAuto alone">
                    <NavLink to="/politica-seguridad" activeClassName="active">Política de Seguridad</NavLink>
                </li>
            </ul>
        </nav>
    )
}

export function MenuNuestraCompania() {
    return (
        <nav className="menuAutocenter">
            <ul className="ulAuto">
                <li className="liAuto alone">
                    <p>Nuestra Compañía</p>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/acerca-nosotros" activeClassName="active">Acerca de Nosotros</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/localizador-tiendas" activeClassName="active">Localizador de Tiendas</NavLink>
                </li>

                <li className="liAuto alone">
                    <a href="http://facturaelectronica.sears.com.mx/">Facturación Electrónica</a>
                </li>

                <li className="liAuto alone">
                    <Link to="/Mesa_Regalos?utm_source=direct&utm_medium=menu-superior-home-sears-com&utm_campaign=mesa_de_regalos"
                    // "https://wap-mesa-regalos.sears.com.mx/"
                    >Mesa de Regalos</Link>
                </li>
            </ul>
        </nav>
    )
}