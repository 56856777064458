import React, { useState, useEffect } from 'react';
import 'components/ProductDetail/ShopProduct/ContentCardProduct/style.sass';
import CurrencyFormat from 'react-currency-format';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom'
import { useStorage64 } from 'hooks/useStorageBase64';
import { useIsMobile } from 'hooks/useIsMobile';
import { dataLayerOutProduct } from 'functions/dataLayerFunction';
import { decodeStorage } from 'functions/storageBase64';
import { HIDDEN_DISCOUNT, VARIANTS_PER_PORTAL } from 'constants/general'
import { ErrorBoundary } from 'components/Error/ErrorBoundary'


import ErrorType from 'pages/Error/ErrorType'
import ModalMiTelmexDetalle from 'components/MiTelmex/modaMiTelmex/modalMiTelmex'
import { ShareComponentDetailTexto } from 'components/Share'
import { BtnWishlistDetail } from 'components/Wishlist'
import ModuleWarrantyProduct from 'components/ProductDetail/Warranty';
import ModuleCreditCardSanbornsPromotion from 'components/ProductDetail/CreditCardSanbornsPromotion';
import ModuleTabsDesktop from 'components/ProductDetail/ModuleTabsDesktop';
import ButtonVenderSanborns from 'components/ProductDetail/ShopProduct/ButtonVenderSanborns';
import ModuleServicioInstalacion from 'components/ProductDetail/ModuleServicioInstalacion';
import { ModuleClickRecoge } from 'components/ProductDetail/ShopProduct/ClicyRecoge'
import { SirenaWhatsApp } from 'components/ProductDetail/SirenaWhatsApp'
import ButtonCyR from 'components/ProductDetail/ShopProduct/ButtonCyR';
import ModuleCatalogoExtendido from 'components/ProductDetail/ModuleCatalogoExtendido';
import { SectionAttributesBoock } from 'components/ProductDetail/SectionAttributesBoock';
import ErrorProductServices from 'components/Error/ErrorProduct';
import ErrorConnectionServices from 'components/Error/ErrorConection';
import ModuleSliderImageProdut from 'components/ProductDetail/SlideImageProduct';
//import ModuleShopProductCart from 'components/ProductDetail/ShopProduct/ContentCardProduct';
//import ModuleSliderImageProdut from 'components/ProductDetail/SlideImageProduct/SlideImageProductOld';
import ButtonSellinClaroshop from 'components/ProductDetail/ShopProduct/ButtonSellinClaroshop';
import ModuleShopData from 'components/ProductDetail/ShopProduct/ModuleShopData';
import ModuleShopBuy from 'components/ProductDetail/ShopProduct/ModuleShopBuy';
import ModuleFeatures from 'components/ProductDetail/Features';
import ModuleDeliveryDate from 'components/ProductDetail/DeliveryDate';
import ModuleTelmexPromotion from 'components/ProductDetail/TelmexPromotion';
import ModuleCreditCardPromotion from 'components/ProductDetail/CreditCardPromotion';
import ModuleSpecificationProduct from 'components/ProductDetail/Specification';
import { ModuleDescriptionProductDesktop } from 'components/ProductDetail/Description';
import { ModuleReviewsDesktop } from 'components/ProductDetail/Reviews/ProductReviews';
import ModulePaymentMethods from 'components/ProductDetail/PaymentMethods';
import ModuleStoreCardProduct from 'components/ProductDetail/Store';
import SlidersEmarsys from 'components/Emarsys/SlidersEmarsys/index_product';
import { PromotionProduct } from 'components/Home/PromotionProduct';
import { SinopsisModule } from 'components/ProductDetail/SectionAttributesBoock/sinopsis';


function SlidersBlocks(props) {
    const { id, category } = props;

    let catString, catStringLow, catStringTitle;
    if (category?.length > 1) {
        catStringTitle = category.filter(valor => valor?.path_length === 0)[0]?.name;
        if (category?.length >= 3) {
            //catString = category.filter(valor => valor.path_length === 2)[0].name;
            catString = category.filter(valor => valor?.path_length === 2)[0]?.name + ' > ' + category.filter(valor => valor?.path_length === 1)[0]?.name + ' > ' + category.filter(valor => valor?.path_length === 0)[0]?.name
            catStringLow = catString.toLowerCase();
        }
        else if (category?.length === 2) {
            //catString = category.filter(valor => valor.path_length === 2)[0].name;
            catString = category.filter(valor => valor.path_length === 1)[0]?.name + ' > ' + category.filter(valor => valor?.path_length === 0)[0]?.name
            catStringLow = catString.toLowerCase();
        }
        else if (category?.length === 1) {
            //catString = category.filter(valor => valor.path_length === 2)[0].name;
            catString = category.filter(valor => valor?.path_length === 0)[0]?.name
            catStringLow = catString.toLowerCase();
        }
    } else {
        catString = null;
        catStringTitle = null;
    }

    const catPadre = catString;
    const idString = String(id);
    const categoryString = catStringLow;

    return (
        <>
            <ErrorBoundary>
                {idString &&
                    VARIANTS_PER_PORTAL?.emarsys?.product_also
                    ?
                    <section id="tambienCompraronSlider">
                        <SlidersEmarsys
                            logica={'ALSO_BOUGHT'}
                            idContent={'tambienCompraronSlider'}
                            idProd={idString}
                            title={"También compraron"}
                            typeView={'sliderView'}
                            cant={50}
                        />
                    </section>
                    : null
                }
            </ErrorBoundary>

            <ErrorBoundary>
                {categoryString &&
                    VARIANTS_PER_PORTAL?.emarsys?.product_cat
                    ?
                    <section id="categorySlider">
                        <SlidersEmarsys
                            logica={'CATEGORY'}
                            idContent={'categorySlider'}
                            breadcrumb={categoryString}
                            title={`Otros de ${catStringTitle}`}
                            typeView={'sliderView'}
                            cant={16}
                        />
                    </section>
                    : null
                }
            </ErrorBoundary>
        </>
    )
}

export function ModulePaymentButtons(props){
    const { 
        catPadre,
        catHija_Hombre,
        catHija_Mujer,
        catHija_Nino,
        catHija_Nina,
        tallasNino, tallasNina, tallasHombre, tallasMujer,
        productData,
        colorText,
        tallaText,
        setSizeColorSKUprincipal,
        preseleccionar,
        updateFFVariant,
        stockSizeColorSKU,
        stockSizeColorSKUFF,
        stockSizeColorVariant,
        setTallaText,
        setColorText,
        updateFF,
        showExpress,
        messageStock,
        payment_methods,
        longitudDeliveryDate,
        libro,
        last_modified
    } = props;
    return (
        <div className="modulePaymentButtons">
            <div className="content">
                {productData?.producto?.stock && productData?.producto?.stock > 0 && productData?.producto?.status !== false
                    ? <div className="moduleShopCardProduct">
                        <div className="moduleShopPrice">
                            <div className="dataPrice">
                                <CurrencyFormat
                                    value={productData?.producto?.sale_price}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    renderText={value =>
                                        <div className="priceSale">
                                            <div className="pPrice">{value} MXN
                                                {productData?.producto?.price !== productData?.producto?.sale_price
                                                    ? <div className="contDiscount">
                                                        <CurrencyFormat
                                                            value={productData?.producto?.price}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            renderText={value => <span className="textUnderline">{value}MXN</span>}
                                                        />
                                                        {productData?.producto?.discount <= HIDDEN_DISCOUNT
                                                            ? null
                                                            : <span className="discount">-{productData?.producto?.discount}%</span>
                                                        }
                                                    </div>
                                                    : null
                                                }
                                            </div>

                                        </div>
                                    }
                                />


                                {/* {stockUD && status
                                ? <>
                                    {stockUD === 1
                                        ? <div className="lastProducts">Queda 1 pieza</div>
                                        : null
                                    }
                                    {stockUD >= 2 && stockUD <= 14
                                        ? <div className="lastProducts">Últimas {stockUD} piezas</div>
                                        : null
                                    }
                                    {stockUD >= 15 && stockUD <= 30
                                        ? <div className="lastProducts">Últimas piezas</div>
                                        : null
                                    }
                                </>
                                : null

                            } */}
                                {productData?.producto?.is_digital && <div className="stickerDigitalDetalle">Producto Digital</div>}

                            </div>
                        </div>
                    </div>
                    :
                        <div className="productoAgotado">
                            <p>{messageStock}</p>
                            <span></span>
                        </div>
                }
                <ModuleShopBuy
                    id={productData?.producto?.id}
                    size_color={productData?.producto?.size_color}
                    stock={productData?.producto?.stock}
                    brand={productData?.producto?.brand}
                    status={productData?.producto?.status}
                    title={productData?.producto?.title}
                    price={productData?.producto?.price}
                    sale_price={productData?.producto?.sale_price}
                    dataImage={productData?.producto?.images}
                    stockSizeColorSKU={stockSizeColorSKU}
                    stockSizeColorSKUFF={stockSizeColorSKUFF}
                    stockSizeColorVariant={stockSizeColorVariant}
                    is_digital={productData?.producto?.is_digital}
                    category={productData?.producto?.categories}
                    is_store_only={productData?.producto?.features?.is_store_only}
                    skuProducto={productData?.producto?.sku}
                    setTallaText={setTallaText}
                    setColorText={setColorText}
                    colorText={colorText}
                    tallaText={tallaText}
                    setSizeColorSKUprincipal={setSizeColorSKUprincipal}
                    preseleccionar={preseleccionar}
                    isfree={productData?.producto?.shipping?.is_free}
                    catPadre={catPadre}
                    catHija_Hombre={catHija_Hombre}
                    catHija_Mujer={catHija_Mujer}
                    catHija_Nino={catHija_Nino}
                    catHija_Nina={catHija_Nina}
                    tallasNino={tallasNino} tallasNina={tallasNina} tallasHombre={tallasHombre} tallasMujer={tallasMujer}
                    cex={productData?.producto?.features?.cex}
                    super_express={productData?.producto?.features?.super_express}
                    payment_methods={payment_methods}
                    longitudDeliveryDate={longitudDeliveryDate}
                    libro={libro}
                    last_modified={productData?.producto?.last_modified}
                />
                {/* <ModuleStoreCardProduct
                    id={productData?.producto?.store.id}
                    title={productData?.producto?.store.name}
                    logo={productData?.producto?.store.logo}
                /> */}
                {/* {productData?.producto?.stock && productData?.producto?.status
                    && <ButtonSellinClaroshop id={productData?.producto?.id} title={productData?.producto?.title} />
                } */}

                {productData?.producto?.stock && productData?.producto?.stock > 0
                    ?
                    <ButtonCyR
                        cyr={productData?.producto?.cyr}
                        id={productData?.producto?.id}
                        title={productData?.producto?.title}
                        dataImage={productData?.producto?.images}
                        precioLista={productData?.producto?.sale_price}
                        discount={productData?.producto?.discount}
                        skuChildren={productData?.producto?.sku}
                        skuChildrenFF={updateFFVariant}
                        store={productData?.producto?.store}
                        stock={productData?.producto?.stock}
                        categories={productData?.producto?.categories}
                        status={productData?.producto?.status}
                        size_color={productData?.producto?.size_color}
                        colorCyR={colorText}
                        tallaCyR={tallaText}
                        precio={productData?.producto?.price}
                        is_store_only={productData?.producto?.features?.is_store_only}
                        cex={productData?.producto?.features?.cex}
                    />
                    : null
                }
                {/* {productData?.producto?.stock && productData?.producto?.stock > 0
                    ? <ButtonSellinClaroshop id={productData?.producto?.id} title={productData?.producto?.title} />
                    : null
                } */}

                {/* modulo garantia */}
                {/* <ErrorBoundary>
                    {productData?.producto?.stock && productData?.producto?.stock > 0
                        ? productData?.producto?.warrantyDetails && productData?.producto?.warrantyDetails.length >= 1
                            ? <ModuleWarrantyProduct warrantyDetails={productData?.producto?.warrantyDetails} />
                            : null
                        : null
                    }
                </ErrorBoundary> */}

                {/* <ButtonVenderSanborns /> */}
                <div className="boxWishlistShareDesktop"> 
                    <div className="listaDeseos">
                        <BtnWishlistDetail
                            id={productData?.producto?.id}
                            nombre={productData?.producto?.title}
                            precioLista={productData?.producto?.sale_price}
                            brand={productData?.producto?.brand}
                            categories={productData?.producto?.categories}
                            index='1'
                        />
                    </div>
                    <div className="compartir">
                        <ShareComponentDetailTexto title={productData?.producto?.title} />
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export function ModulePaymentButtonsLibro(props){
    const { 
        catPadre,
        catHija_Hombre,
        catHija_Mujer,
        catHija_Nino,
        catHija_Nina,
        tallasNino, tallasNina, tallasHombre, tallasMujer,
        productData,
        colorText,
        tallaText,
        setSizeColorSKUprincipal,
        preseleccionar,
        updateFFVariant,
        stockSizeColorSKU,
        stockSizeColorSKUFF,
        stockSizeColorVariant,
        setTallaText,
        setColorText,
        updateFF,
        showExpress,
        messageStock,
        payment_methods,
        longitudDeliveryDate,
        libro
    } = props;
    return (
        <div className="modulePaymentButtons">
            <div className="content">
                {productData?.producto?.stock && productData?.producto?.stock > 0 && productData?.producto?.status !== false
                    ? <div className="moduleShopCardProduct">
                        <div className="moduleShopPrice">
                            <div className="dataPrice">
                                <CurrencyFormat
                                    value={productData?.producto?.sale_price}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    renderText={value =>
                                        <div className="priceSale">
                                            <p className="pPrice">{value} MXN
                                                {productData?.producto?.price !== productData?.producto?.sale_price
                                                    ? <div className="contDiscount">
                                                        <CurrencyFormat
                                                            value={productData?.producto?.price}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            renderText={value => <p className="textUnderline">{value}MXN</p>}
                                                        />
                                                        {productData?.producto?.discount <= HIDDEN_DISCOUNT
                                                            ? null
                                                            : <span className="discount">-{productData?.producto?.discount}%</span>
                                                        }
                                                    </div>
                                                    : null
                                                }
                                            </p>

                                        </div>
                                    }
                                />


                                {/* {stockUD && status
                                ? <>
                                    {stockUD === 1
                                        ? <div className="lastProducts">Queda 1 pieza</div>
                                        : null
                                    }
                                    {stockUD >= 2 && stockUD <= 14
                                        ? <div className="lastProducts">Últimas {stockUD} piezas</div>
                                        : null
                                    }
                                    {stockUD >= 15 && stockUD <= 30
                                        ? <div className="lastProducts">Últimas piezas</div>
                                        : null
                                    }
                                </>
                                : null

                            } */}
                                {productData?.producto?.is_digital && <div className="stickerDigitalDetalle">Producto Digital</div>}

                            </div>
                        </div>
                    </div>
                    :
                        <div className="productoAgotado">
                            <p>{messageStock}</p>
                            <span></span>
                        </div>
                }
                <ModuleShopBuy
                    id={productData?.producto?.id}
                    size_color={productData?.producto?.size_color}
                    stock={productData?.producto?.stock}
                    brand={productData?.producto?.brand}
                    status={productData?.producto?.status}
                    title={productData?.producto?.title}
                    price={productData?.producto?.price}
                    sale_price={productData?.producto?.sale_price}
                    dataImage={productData?.producto?.images}
                    stockSizeColorSKU={stockSizeColorSKU}
                    stockSizeColorSKUFF={stockSizeColorSKUFF}
                    stockSizeColorVariant={stockSizeColorVariant}
                    is_digital={productData?.producto?.is_digital}
                    category={productData?.producto?.categories}
                    is_store_only={productData?.producto?.features?.is_store_only}
                    skuProducto={productData?.producto?.sku}
                    setTallaText={setTallaText}
                    setColorText={setColorText}
                    colorText={colorText}
                    tallaText={tallaText}
                    setSizeColorSKUprincipal={setSizeColorSKUprincipal}
                    preseleccionar={preseleccionar}
                    isfree={productData?.producto?.shipping?.is_free}
                    catPadre={catPadre}
                    catHija_Hombre={catHija_Hombre}
                    catHija_Mujer={catHija_Mujer}
                    catHija_Nino={catHija_Nino}
                    catHija_Nina={catHija_Nina}
                    tallasNino={tallasNino} tallasNina={tallasNina} tallasHombre={tallasHombre} tallasMujer={tallasMujer}
                    cex={productData?.producto?.features?.cex}
                    super_express={productData?.producto?.features?.super_express}
                    payment_methods={payment_methods}
                    longitudDeliveryDate={longitudDeliveryDate}
                    libro={libro}
                />
                {/* <ModuleStoreCardProduct
                    id={productData?.producto?.store.id}
                    title={productData?.producto?.store.name}
                    logo={productData?.producto?.store.logo}
                /> */}
                {/* {productData?.producto?.stock && productData?.producto?.status
                    && <ButtonSellinClaroshop id={productData?.producto?.id} title={productData?.producto?.title} />
                } */}

                {productData?.producto?.stock && productData?.producto?.stock > 0
                    ?
                    <ButtonCyR
                        cyr={productData?.producto?.cyr}
                        id={productData?.producto?.id}
                        title={productData?.producto?.title}
                        dataImage={productData?.producto?.images}
                        precioLista={productData?.producto?.sale_price}
                        discount={productData?.producto?.discount}
                        skuChildren={productData?.producto?.sku}
                        skuChildrenFF={updateFFVariant}
                        store={productData?.producto?.store}
                        stock={productData?.producto?.stock}
                        categories={productData?.producto?.categories}
                        status={productData?.producto?.status}
                        size_color={productData?.producto?.size_color}
                        colorCyR={colorText}
                        tallaCyR={tallaText}
                        precio={productData?.producto?.price}
                        is_store_only={productData?.producto?.features?.is_store_only}
                        cex={productData?.producto?.features?.cex}
                    />
                    : null
                }
                {/* {productData?.producto?.stock && productData?.producto?.stock > 0
                    ? <ButtonSellinClaroshop id={productData?.producto?.id} title={productData?.producto?.title} />
                    : null
                } */}

                {/* modulo garantia */}
                {/* <ErrorBoundary>
                    {productData?.producto?.stock && productData?.producto?.stock > 0
                        ? productData?.producto?.warrantyDetails && productData?.producto?.warrantyDetails.length >= 1
                            ? <ModuleWarrantyProduct warrantyDetails={productData?.producto?.warrantyDetails} />
                            : null
                        : null
                    }
                </ErrorBoundary> */}

                {/* <ButtonVenderSanborns /> */}
                <div className="boxWishlistShareDesktop"> 
                    <div className="listaDeseos">
                        <BtnWishlistDetail
                            id={productData?.producto?.id}
                            nombre={productData?.producto?.title}
                            precioLista={productData?.producto?.sale_price}
                            brand={productData?.producto?.brand}
                            categories={productData?.producto?.categories}
                            index='1'
                        />
                    </div>
                    <div className="compartir">
                        <ShareComponentDetailTexto title={productData?.producto?.title} />
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export function DetalleProductoDesktop(props) {
    const {
        changeDirectionBar,
        // loading,
        productDataSup,
        opinionData,
        mostrarResumen,
        setLoading,
        promoDepart,
        formasPagoArray,
        formasPagoOrdenadoArray,
        catPadre,
        catHija_Hombre,
        catHija_Mujer,
        catHija_Nino,
        catHija_Nina,
        tallasNino, tallasNina, tallasHombre, tallasMujer,
        SKUsServicioTecnico,
        status,
        stock,
        id,
        libro
    } = props;

    const [jsonInfo, setJsonInfo] = useState();
    useEffect(() => {
        if (SKUsServicioTecnico) {
            setJsonInfo(SKUsServicioTecnico);
        }
    }, [SKUsServicioTecnico])

    // const history = useHistory();
    const [yaVisitoProductos, setYaVisitoProductos] = useStorage64('yaVisitoProductos', 0);
    const [yaPresionoSimilares, setYaPresionoSimilares] = useStorage64('yaPresionoSimilares', 0);
    const [flagNuevo, setFlagNuevo] = useState(false);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Product Page');
    }, [])

    const [productData, SetProductData] = useState([]);
    useEffect(() => {
        //console.log('productDataSup: ', productDataSup)
        if (productDataSup) {
            SetProductData(productDataSup);
        }
    }, [productDataSup]);

    //- remueve loading con errores
    useEffect(() => {
        if (productData) {
            /// console.log("FEATURES", productData?.producto);
            if (productData?.error) {
                setLoading(false);
                // console.log('productData?.error: ',productData?.error)
            }
        }
    }, [productData]);

    useEffect(() => {
        setYaVisitoProductos(yaVisitoProductos + 1);
    }, []);

    useEffect(() => {
        if (flagNuevo == true) {
            setYaPresionoSimilares(yaPresionoSimilares + 1);
        }
    }, [flagNuevo]);

    //- Evalua talla color stock para mostrar reglas de mensaje
    const [updateStockTC, setUpdateStockTC] = useState();
    const [updateFF, setUpdateFF] = useState();
    const stockSizeColorSKU = (val) => {
        setUpdateStockTC(val);
    };
    const stockSizeColorSKUFF = (val) => {
        setUpdateFF(val);
    };

    const [updateFFVariant, setUpdateFFVariant] = useState();
    const stockSizeColorVariant = (val) => {
        setUpdateFFVariant(val);
    };

    const [colorText, setColorText] = useState(null);
    const [tallaText, setTallaText] = useState(null);
    useEffect(() => {
        // console.log('updateFFVariant: ', updateFFVariant)
        // console.log('updateFF: ', updateFF)
        // console.log('updateStockTC: ', updateStockTC)
    }, [updateFFVariant]);

    const [sizeColorSKUprincipal, setSizeColorSKUprincipal] = useState(false);
    const [scSKUprincipal, setscSKUprincipal] = useState(false);

    useEffect(() => {

        if (sizeColorSKUprincipal) {
            setscSKUprincipal(sizeColorSKUprincipal)
        }
    }, [sizeColorSKUprincipal])

    const [preseleccionar, setPreseleccionar] = useState(false);

    //valor para promociones en tarjetas departamentales
    // if (promoDepart) {
    //     console.log('promoDepart desktop---------------------: ', promoDepart)
    // }else{

    //     console.log('promoDepart desktop---------------------: ', promoDepart)
    // }

    const { isMobile } = useIsMobile();
    const history = useHistory();
    const location = useLocation();
    const urlLink = location.href;
    const hashLink = location.hash;
    // const firstVisit = decodeStorage('firstVisit');

    const [showModal, SetShowModal] = useState(false);

    const showExpress = () => {
        SetShowModal(true);
    }

    useEffect(() => {
        if (hashLink === "#modalExpress") {
            showExpress();
        } else {
            SetShowModal(false);
        }
    }, [hashLink, urlLink])

    const handleClose = () => {
        history.push(location.pathname);
    }

    const [messageStock, setMessageStock] = useState("¡Producto Agotado!")
    useEffect(() => {
        if (!stock || stock === 0 || status === false) {
            //console.log('agotadoooooo')
            if (!stock || stock === 0) {
                setMessageStock("¡Producto Agotado!")
                dataLayerOutProduct({ id: id, stock: stock, message: 'producto_agotado' })
            } else if (stock >= 1 || status === false) {
                setMessageStock("¡Producto Agotado!")
                dataLayerOutProduct({ id: id, stock: stock, message: 'no_disponible' })
            }
        }
    }, [stock, status])

    return (
        <>
            <main className="viewDetailProduct">

                {/* resumen */}
                {mostrarResumen === true &&
                    <>
                        <section>
                            <div className="container">
                                <div className="gridTemplateProduct">

                                    {productData?.error >= 500
                                        ?
                                            <ErrorType
                                                codeStatus={productData?.error}
                                                errorText={'Código de error: ' + productData?.error}
                                            />
                                        : null
                                    }
                                    {productData?.error === 404
                                        ?
                                            <ErrorProductServices />
                                        : null
                                    }
                                    {productData?.error === '0'
                                        ?
                                            <ErrorConnectionServices errorCode={productData?.response} />
                                        : null
                                    }




                                    {productData?.producto
                                        ?
                                            <>
                                                <ErrorBoundary>
                                                    <div className="productSupModule">
                                                        <ErrorBoundary>
                                                            <ModuleSliderImageProdut
                                                                id={productData?.producto?.id}
                                                                dataImage={productData?.producto?.images}
                                                                dataVideo={productData?.producto?.videos}
                                                                title={productData?.producto?.title}
                                                                category={productData?.producto?.categories}
                                                                brand={productData?.producto?.brand}
                                                                sale_price={productData?.producto?.sale_price}
                                                            />
                                                        </ErrorBoundary>

                                                        <div className="moduleShopCardProduct">
                                                            <ErrorBoundary>

                                                                {opinionData
                                                                    ? <ModuleShopData
                                                                        id={productData?.producto?.id}
                                                                        title={productData?.producto?.title}
                                                                        store={productData?.producto?.store}
                                                                        discount={productData?.producto?.discount}
                                                                        price={productData?.producto?.price}
                                                                        sale_price={productData?.producto?.sale_price}
                                                                        is_digital={productData?.producto?.is_digital}
                                                                        reviews={opinionData?.response}
                                                                        isfree={productData?.producto?.shipping?.is_free}
                                                                        fullfilment={productData?.producto?.features}
                                                                        stock={productData?.producto?.stock}
                                                                        updateStockTC={updateStockTC}
                                                                        updateFF={updateFF}
                                                                        status={productData?.producto?.status}
                                                                        brand={productData?.producto?.brand}
                                                                        skuChildren={productData?.producto?.sku}
                                                                        colorText={colorText}
                                                                        catPadre={catPadre}
                                                                        catHija_Hombre={catHija_Hombre}
                                                                        catHija_Mujer={catHija_Mujer}
                                                                        catHija_Nino={catHija_Nino}
                                                                        catHija_Nina={catHija_Nina}
                                                                        super_express={productData?.producto?.features?.super_express}
                                                                        ean={productData?.producto?.ean}
                                                                        libro={libro}
                                                                    />
                                                                    : <ModuleShopData
                                                                        id={productData?.producto?.id}
                                                                        title={productData?.producto?.title}
                                                                        store={productData?.producto?.store}
                                                                        discount={productData?.producto?.discount}
                                                                        price={productData?.producto?.price}
                                                                        sale_price={productData?.producto?.sale_price}
                                                                        is_digital={productData?.producto?.is_digital}
                                                                        reviews={null}
                                                                        isfree={productData?.producto?.shipping?.is_free}
                                                                        fullfilment={productData?.producto?.features}
                                                                        stock={productData?.producto?.stock}
                                                                        updateStockTC={updateStockTC}
                                                                        updateFF={updateFF}
                                                                        status={productData?.producto?.status}
                                                                        brand={productData?.producto?.brand}
                                                                        skuChildren={productData?.producto?.sku}
                                                                        colorText={colorText}
                                                                        catPadre={catPadre}
                                                                        catHija_Hombre={catHija_Hombre}
                                                                        catHija_Mujer={catHija_Mujer}
                                                                        catHija_Nino={catHija_Nino}
                                                                        catHija_Nina={catHija_Nina}
                                                                        super_express={productData?.producto?.features?.super_express}
                                                                        ean={productData?.producto?.ean}
                                                                        libro={libro}
                                                                    />
                                                                }
                                                            </ErrorBoundary>

                                                            {/* <ErrorBoundary>
                                                                {productData?.producto?.telmex_months.length !== 0
                                                                    ? <ModuleTelmexPromotion telmex_months={productData?.producto?.telmex_months} />
                                                                    : null
                                                                }
                                                            </ErrorBoundary> */}

                                                            {/* sinopsis para libros con isbn */}

                                                            {libro && productData?.producto?.description ?
                                                                <SinopsisModule
                                                                    description={productData?.producto?.description}
                                                                    attributes={productData?.producto?.attributes}
                                                                    brand={productData?.producto?.brand}
                                                                />
                                                                : null
                                                            }

                                                            {/* caracteristicas libros*/}
                                                    
                                                            {libro && productData?.producto?.attributes ?
                                                                    <SectionAttributesBoock 
                                                                        attributes={productData?.producto?.attributes}
                                                                        brand={productData?.producto?.brand}
                                                                    />                            
                                                                : null
                                                            }
                                                            

                                                            <ErrorBoundary>
                                                                {productData?.producto?.stock && productData?.producto?.stock > 0
                                                                    ? promoDepart && promoDepart?.length > 0
                                                                        ? <ModuleCreditCardSanbornsPromotion credit_card_months={promoDepart} tieneFormasPago={productData?.producto?.payment_methods} />
                                                                        : null
                                                                    : null
                                                                    //                                // ? productData?.producto?.payment_methods.find(item => item.id === 4384).promotion.length > 0: null productData?.producto?.payment_methods.find(item => item.id === 4384).promotion[0].monthly_payments
                                                                }
                                                            </ErrorBoundary>

                                                            <ErrorBoundary>
                                                                {productData?.producto?.stock && productData?.producto?.stock > 0
                                                                    ? productData?.producto?.payment_methods?.find(item => item?.id === 4468)
                                                                        ? productData?.producto?.payment_methods?.find(item => item?.id === 4468)?.promotion?.length > 0
                                                                            ? <ModuleCreditCardPromotion credit_card_months={productData?.producto?.payment_methods?.find(item => item?.id === 4468)?.promotion[0]?.monthly_payments} />
                                                                            : null
                                                                        : null
                                                                    : null
                                                                }
                                                            </ErrorBoundary>

                                                            <ErrorBoundary>
                                                                {productData &&
                                                                    productData?.producto &&
                                                                    productData?.producto?.features &&
                                                                    productData?.producto?.features.cyr || productData?.producto?.features?.is_store_only
                                                                    ? <>
                                                                        <ModuleClickRecoge
                                                                            cyr={productData?.producto?.cyr}
                                                                            id={productData?.producto?.id}
                                                                            title={productData?.producto?.title}
                                                                            dataImage={productData?.producto?.images}
                                                                            precioLista={productData?.producto?.sale_price}
                                                                            discount={productData?.producto?.discount}
                                                                            skuChildren={productData?.producto?.sku}
                                                                            skuChildrenFF={updateFFVariant}
                                                                            store={productData?.producto?.store}
                                                                            stock={productData?.producto?.stock}
                                                                            categories={productData?.producto?.categories}
                                                                            status={productData?.producto?.status}
                                                                            size_color={productData?.producto?.size_color}
                                                                            colorCyR={colorText}
                                                                            tallaCyR={tallaText}
                                                                            is_store_only={productData?.producto?.features?.is_store_only}
                                                                            cex={productData?.producto?.features?.cex}
                                                                        />
                                                                    </>
                                                                    : null
                                                                }
                                                            </ErrorBoundary>

                                                            <ErrorBoundary>
                                                                {productData?.producto?.features?.cex
                                                                    ? <ModuleCatalogoExtendido />  
                                                                    : null  
                                                                }
                                                            </ErrorBoundary>
                                                        </div>

                                                        {libro
                                                            ? 
                                                                <ModulePaymentButtonsLibro
                                                                    productData={productData}
                                                                    catPadre={catPadre}
                                                                    catHija_Hombre={catHija_Hombre}
                                                                    catHija_Mujer={catHija_Mujer}
                                                                    catHija_Nino={catHija_Nino}
                                                                    catHija_Nina={catHija_Nina}
                                                                    tallasNino={tallasNino} 
                                                                    tallasNina={tallasNina} 
                                                                    tallasHombre={tallasHombre} 
                                                                    tallasMujer={tallasMujer}
                                                                    colorText={colorText}
                                                                    tallaText={tallaText}
                                                                    setSizeColorSKUprincipal={setSizeColorSKUprincipal}
                                                                    preseleccionar={preseleccionar}
                                                                    updateFFVariant={updateFFVariant}
                                                                    stockSizeColorSKU={stockSizeColorSKU}
                                                                    stockSizeColorSKUFF={stockSizeColorSKUFF}
                                                                    stockSizeColorVariant={stockSizeColorVariant}
                                                                    setTallaText={setTallaText}
                                                                    setColorText={setColorText}
                                                                    updateFF={updateFF}
                                                                    showExpress={showExpress}
                                                                    messageStock={messageStock}
                                                                    payment_methods={productData?.producto?.payment_methods}
                                                                    longitudDeliveryDate={productData?.producto?.delivery_date?.length}
                                                                    libro={libro}
                                                                />
                                                            :
                                                                <ModulePaymentButtons 
                                                                    productData={productData}
                                                                    catPadre={catPadre}
                                                                    catHija_Hombre={catHija_Hombre}
                                                                    catHija_Mujer={catHija_Mujer}
                                                                    catHija_Nino={catHija_Nino}
                                                                    catHija_Nina={catHija_Nina}
                                                                    tallasNino={tallasNino} 
                                                                    tallasNina={tallasNina} 
                                                                    tallasHombre={tallasHombre} 
                                                                    tallasMujer={tallasMujer}
                                                                    colorText={colorText}
                                                                    tallaText={tallaText}
                                                                    setSizeColorSKUprincipal={setSizeColorSKUprincipal}
                                                                    preseleccionar={preseleccionar}
                                                                    updateFFVariant={updateFFVariant}
                                                                    stockSizeColorSKU={stockSizeColorSKU}
                                                                    stockSizeColorSKUFF={stockSizeColorSKUFF}
                                                                    stockSizeColorVariant={stockSizeColorVariant}
                                                                    setTallaText={setTallaText}
                                                                    setColorText={setColorText}
                                                                    updateFF={updateFF}
                                                                    showExpress={showExpress}
                                                                    messageStock={messageStock}
                                                                    payment_methods={productData?.producto?.payment_methods}
                                                                    longitudDeliveryDate={productData?.producto?.delivery_date?.length}
                                                                    libro={libro}
                                                                />
                                                        }
                                                    </div>       
                                                </ErrorBoundary>

                                                <ErrorBoundary>
                                                    <ModuleTabsDesktop
                                                        id={productData?.producto?.id}
                                                        description={productData?.producto?.description}
                                                        attributes={productData?.producto?.attributes}
                                                        payment_methods={productData?.producto?.payment_methods}
                                                        promoDepart={promoDepart}
                                                        formasPagoArray={formasPagoArray}
                                                        formasPagoOrdenadoArray={formasPagoOrdenadoArray}
                                                        isbn={libro}
                                                    // warrantyDetails={productData?.producto?.warrantyDetails}
                                                    />
                                                </ErrorBoundary>

                                                <ErrorBoundary>
                                                    {VARIANTS_PER_PORTAL?.emarsys?.product_related
                                                        ?
                                                            <div id="relatedSlider">
                                                                <SlidersEmarsys
                                                                    logica={'RELATED'}
                                                                    idContent={'relatedSlider'}
                                                                    idProd={String(productData?.producto?.id)}
                                                                    title={"Productos similares"}
                                                                    typeView={'sliderView'}
                                                                    cant={16}
                                                                />
                                                            </div>
                                                        : null
                                                    }
                                                </ErrorBoundary>

                                                <ErrorBoundary>
                                                    {opinionData && opinionData?.response && opinionData?.response?.reviews?.length >= 1
                                                        ? <ModuleReviewsDesktop response={opinionData?.response} />
                                                        : null
                                                    }
                                                </ErrorBoundary>

                                                <ErrorBoundary>
                                                    <ModulePaymentMethods
                                                        payment_methods={productData?.producto?.payment_methods}
                                                        formasPagoArray={formasPagoArray}
                                                        formasPagoOrdenadoArray={formasPagoOrdenadoArray}
                                                    />
                                                </ErrorBoundary>

                                                <ErrorBoundary>
                                                    {VARIANTS_PER_PORTAL?.emarsys?.product_personal
                                                        ?
                                                            <section id="personalSlider">
                                                                <SlidersEmarsys
                                                                    logica={'PERSONAL'}
                                                                    idContent={'personalSlider'}
                                                                    title={"Productos basados en tu navegación"}
                                                                    typeView={'sliderView'}
                                                                    cant={16}
                                                                />
                                                            </section>
                                                        : null
                                                    }
                                                </ErrorBoundary>
                                            </>
                                        : null
                                    }
                                </div>
                            </div>
                        </section>

                        <ErrorBoundary>
                            {productData?.producto
                                ? <SlidersBlocks
                                    id={productData?.producto?.id}
                                    category={productData?.producto?.categories}
                                />
                                : null
                            }
                        </ErrorBoundary>

                        <ErrorBoundary>
                            {productData?.producto
                                ? <SirenaWhatsApp
                                    store={productData?.producto?.store}
                                    category={productData?.producto?.categories}
                                    price={productData?.producto?.price}
                                    sale_price={productData?.producto?.sale_price}
                                    stock={productData?.producto?.stock}
                                    status={productData?.producto?.status}
                                />
                                : null
                            }
                        </ErrorBoundary>
                    </>
                }
            </main>
        </>
    )
}