import React from 'react'
import './style.sass'
import {Link, useHistory} from 'react-router-dom'

export default function ErrorProductServices (props) {
    const {
        errorText
    } = props
    const history = useHistory()

    const btnRegresar = () => history.goBack()

    return(
        <div className="errorProductServices">
            <img src="/img/maintenanceError.svg" alt="Página no encontrada" width="450" height="450" loading="lazy"/>
            <p className="titleError">{errorText ? errorText : "Página no encontrada"}</p>
            <p className="suggestion">Lo sentimos, el artículo o la información que buscas no están disponibles por el momento.</p>
            {errorText
                ? <div className="btn rojo" onClick={btnRegresar} ariaLabel="Regresar">Regresar</div>
                : <a className="btn rojo" href={`/`} title="Ir al inicio">Ir al inicio</a>
            }
        </div>
    )
}