import React from 'react'

export function MessageRemove(props) {
    const {
        remvoeAction,
        awaitAction
    } = props

    const elimiar = () => remvoeAction(true)
    const recapacitar = () => awaitAction(true)

    return (
        <div className="modalRemoveAddress">
            <p>¿Estas seguro que deseas eliminar este producto?</p>
            <div className="contBtns">
                <div className="btn blanco" onClick={recapacitar}>Cancelar</div>
                <div className="btn blanco" onClick={elimiar}>Eliminar</div>
            </div>
        </div>
    )
}