import React, { useState, useEffect } from "react";
import "./style.sass";
import "./icons.sass";
import { Link } from "react-router-dom";
import getCategoryMenu from "services/getCategoryMenu";
import { getCategoryMenuV1 } from "services/getCategoryMenu";
import {
  decodeStorage,
  encodeStorage,
  removeStorage,
} from "functions/storageBase64";

function MenuFirstLevel(props) {
  const { catFisrt, nombre, seo, subcat } = props;

  return (
    <dl className="menuFirstLevel">
      {String(nombre).includes("Restaurante")
        ?
          <dt>
              <a href="/c/restaurante/" className={`ico-${seo}`}>{nombre}</a>
          </dt>
        : 
          <dt>
            <Link to={`/categoria/${catFisrt}/${seo}/`} className={`ico-${seo}`}>
              {nombre}
            </Link>
            <dl className="menuSecondLevel">
              <dt>
                <dl className="menuThirdLevel">
                  <MenuSecondLevel subcat={subcat} />
                </dl>
              </dt>
            </dl>
          </dt>
        }
    </dl>
  );
}

function MenuSecondLevel(props) {
  const { subcat } = props;

  const [subCategorias, setSubCategorias] = useState(subcat);
  useEffect(() => {
    if (subcat) {
      setSubCategorias(subcat);
    }
  }, [subcat]);

  return (
    subCategorias &&
    subCategorias.map(
      ({ id_cat, nombre, seo, subcategorias, estatus, visible }) =>
        estatus !== 0 && visible !== 0 ? (
          <dt className="more" key={id_cat+"_"+nombre}>
            <Link to={`/categoria/${id_cat}/${seo}/`}>{nombre}</Link>
            <MenuThirdLevel
              subcategorias={subcategorias}
              id_parent={id_cat}
              seo_parent={seo}
            />
          </dt>
        ) : null
    )
  );
}

function MenuThirdLevel(props) {
  const { id_parent, seo_parent, subcategorias } = props;

  const [subCatFinish, setSubCatFinish] = useState(subcategorias);

  useEffect(() => {
    if (subcategorias) {
      let sinStatus = subCatFinish.filter( elem => elem.visible != 0 && elem.estatus != 0)
      setSubCatFinish(sinStatus);
    }
  }, [subcategorias]);


  return (
    <ul>
      {subCatFinish &&
        subCatFinish.map(({ id_cat, nombre, seo, estatus, visible }, index) =>
          estatus !== 0 && visible !== 0
            ?
              (
                index < 6
                  ?
                    (
                      <li key={id_cat+"_"+nombre} className="alone marginTopCa">
                        <Link to={`/categoria/${id_cat}/${seo}/`}>{nombre}</Link>
                      </li>
                    )
                  : index === 6
                    ?
                      (
                        <li key={id_cat+"_"+nombre} className="alone marginTopCa">
                          <Link to={`/categoria/${id_parent}/${seo_parent}/`}>
                            Ver más
                          </Link>
                        </li>
                      )
                    : null
              )
            : null
        )}
    </ul>
  );
}

export function MenuCategoryHeader() {
  const [response, setResponse] = useState();
  const [categorias, setCategorias] = useState([]);
  const [responseSorage, setResponseStorage] = useState([]);
  const updateCategory = sessionStorage.getItem("updateCategory");
  const [responseV1, setResponseV1] = useState();

  //- Funcion que va al servicio de categorias
  useEffect(() => {
    if (!decodeStorage("categoryTree") || updateCategory === null) {
      getCategoryMenu().then(setResponse);
      sessionStorage.setItem("updateCategory", true);
    } else {
      //- Funcion que toma las categorias del storage
      setResponseStorage(decodeStorage("categoryTree"));
    }
  }, []);

  // useEffect( () => {
  //     getCategoryMenuV1()
  //     .then(setResponseV1)
  // },[])

  useEffect(() => {
    if (response) {
      if (response.data) {
        if (response.data.length >= 2) {
          encodeStorage("categoryTree", response);
          setResponseStorage(response);
        }
      }
    } else if (responseV1) {
      if (responseV1.data) {
        // console.log('')
      }
    }
  }, [response, responseV1]);

  //- Guarda en un estado la respuesta y evalua que sea 200 si no manda el error
  useEffect(() => {
    if (responseSorage) {
      if (responseSorage.statusCode === 200) {
        if (typeof responseSorage.data === "object") {
          let allCategorias = responseSorage.data;
          //Se agrega Restaurante a categorias 
          allCategorias.unshift({
              "id_cat": 1,
              "icon": "",
              "nombre": "Restaurante",
              "estatus": 1,
              "visible": 1,
              "seo": "restaurante",
              "subcat": []
          });
          //console.log("ALL CATEGORIAS",allCategorias)
          setCategorias({
              'categoriasMenu': allCategorias
          })
        } else {
          setCategorias({});
        }
      }
    } else if (responseSorage.statusCode !== undefined) {
      setCategorias({});
    }
  }, [responseSorage]);

  //- valida si todo el arbol esta desactivado o invisible
  useEffect(() => {
    setTimeout(function () {
      if (document.querySelector(".subMenu > *") === null && responseSorage) {
        if (String(decodeStorage("validMenuCat")).length === 0) {
          setTimeout(function () {
            getCategoryMenu().then(setResponse);
            sessionStorage.setItem("updateCategory", true);
            encodeStorage("validMenuCat", Date.now() + 15 * 60000);
          }, 3000);
        } else if (String(decodeStorage("validMenuCat")).length >= 10) {
          if (Number(decodeStorage("validMenuCat")) <= Date.now()) {
            setTimeout(function () {
              getCategoryMenu().then(setResponse);
              sessionStorage.setItem("updateCategory", true);
              encodeStorage("validMenuCat", Date.now() + 15 * 60000);
            }, 3000);
          }
        }
      } else {
        removeStorage("validMenuCat");
      }
    }, 1000);
  }, [categorias]);

  return (
    <div className="menuHeader">
      <div className="subMenu">
        {responseSorage && responseSorage.statusCode === 200
          ? categorias.categoriasMenu && (
              <>
                {categorias.categoriasMenu.map(
                  ({ nombre, icon, seo, id_cat, subcat, estatus, visible }) =>
                    estatus !== 0 &&
                    visible !== 0 &&
                    String(nombre).toLowerCase() != "marketplace" ? (
                        <MenuFirstLevel
                          key={id_cat+"_"+nombre}
                          catFisrt={id_cat}
                          nombre={nombre}
                          icon={icon}
                          seo={seo}
                          subcat={subcat}
                        />
                    ) : null
                )}
              </>
            )
          : null}
      </div>
    </div>
  );
}
