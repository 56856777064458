import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import { NO_IMAGE } from 'constants/general'
import { getCategoryMenuTree } from 'services/getCategoryMenu'
import {ErrorBoundary} from 'components/Error/ErrorBoundary'
import { useIsMobile } from 'hooks/useIsMobile'

export function BreadcrumbCompDesktop(props){
    const {
        menuActualLevel,
        menuChildrens,
        level
    } = props

    return(
        menuActualLevel &&
            <section>
                <div className="container">
                    {level <= 1
                        ?   
                            <div className="breadcrumbContent">
                                <h1>{menuActualLevel.name}</h1>
                                {menuChildrens &&
                                    <>
                                        <div className="breacrumbSubCategory">
                                            {menuChildrens.map(({id, external_id, icon, name, seo, status, visible})=>
                                                status === 1 && visible === 1
                                                    ?
                                                        <Link title={seo} key={id} to={`/categoria/${external_id}/${seo}/pagina=1`} className="cardBreadcrumb">
                                                            <img src={icon+'?scale=40' != null ? icon+'?scale=40' : NO_IMAGE} alt={seo} width="40" height="40" loading="lazy" />
                                                            <h2>{name}</h2>
                                                        </Link>
                                                    : null
                                            )}
                                        </div>
                                    </>
                                }
                            </div>
                        : null
                    }
                </div>
            </section>
    )
}

export function BreadcrumbCompMovil(props){
    const {
        menuActualLevel,
        menuParents,
        menuParentTr,
        menuBrothers,
        menuChildrens,
        level,
        guardarCategoria
    } = props

    const [showLevelParent, setShowLevelParent] = useState(false)
    const [showLevelBrother, setShowLevelBrother] = useState(false)

    const subLevelParentBreadcrumb = () =>{
        setShowLevelParent(!showLevelParent)
        setShowLevelBrother(false)
    }
    const subLevelBrotherBreadcrumb = () =>{
        setShowLevelBrother(!showLevelBrother)
        setShowLevelParent(false)
    }

    useEffect(()=>{
        if(menuActualLevel && level === 1){
            guardarCategoria(menuActualLevel.name);
        }
        else if(menuActualLevel && level >= 2){
            if(menuParentTr ){
                guardarCategoria(menuParentTr.name);
            }
        }
    },[])

    return(
        menuActualLevel &&
            <section className="moduleBreadcrumbCategory">
                <div className="container">
                    <div className="breadcrumbContent movil">

                        {level === 1 
                            ?
                                <>
                                    <h1 onClick={subLevelParentBreadcrumb} className={showLevelParent ? "parentLevelActive active" : "parentLevelActive"}>
                                        <img src={menuActualLevel.icon+'?scale=30' != null ? menuActualLevel.icon+'?scale=30' : NO_IMAGE} alt={menuActualLevel.seo} width="30" height="30" loading="lazy" />
                                        <span>
                                            {menuActualLevel.name}
                                        </span>
                                    </h1>
                                    {showLevelParent &&
                                        <div className="subLevelParentBreadcrumb">
                                            {menuParents &&   
                                                menuParents.map(({id, external_id, icon, name, seo, status, visible})=>
                                                    status === 1 && visible === 1
                                                    ?

                                                        <Link title={seo} key={id} to={`/categoria/${external_id}/${seo}/pagina=1`} className={external_id === menuActualLevel.external_id ? "cardBreadcrumb hover" : "cardBreadcrumb"}>
                                                            <img src={icon+'?scale=30' != null ? icon+'?scale=30' : NO_IMAGE} alt={seo} width="30" height="30" loading="lazy" />
                                                            <p>{name}</p>
                                                        </Link>
                                                    : null
                                                        )

                                            }
                                        </div>
                                    }
                                </>

                            : level >= 2
                                ? 
                                    <>
                                        {menuParentTr &&
                                            <h1 onClick={subLevelParentBreadcrumb} className={showLevelParent ? "parentLevelActive active" : "parentLevelActive"}>
                                                
                                                <img src={menuParentTr.icon+'?scale=30' != null ? menuParentTr.icon+'?scale=30' : NO_IMAGE} alt={menuParentTr.seo} width="30" height="30" loading="lazy" />
                                                <span>
                                                    {menuParentTr.name}
                                                </span>
                                            </h1>
                                        }
                                        {showLevelParent &&
                                            menuParentTr &&
                                                <div className="subLevelParentBreadcrumb">
                                                    {menuParents &&   
                                                        menuParents.map(({id, external_id, icon, name, seo, status, visible})=>
                                                            status === 1 && visible === 1
                                                            ?

                                                                <Link title={seo} key={id} to={`/categoria/${external_id}/${seo}/pagina=1`} className={external_id === menuParentTr.external_id ? "cardBreadcrumb hover" : "cardBreadcrumb"}>
                                                                    <img src={icon+'?scale=30' != null ? icon+'?scale=30' : NO_IMAGE} alt={seo} width="30" height="30" loading="lazy" />
                                                                    <p>{name}</p>
                                                                </Link>
                                                            : null
                                                                )

                                                    }
                                                </div>
                                        }
                                        {showLevelBrother &&
                                            <div className="subLevelParentBreadcrumb">
                                                {menuBrothers &&   
                                                    menuBrothers.map(({id, external_id, icon, name, seo, status, visible})=>
                                                        status === 1 && visible === 1
                                                        ?

                                                            <Link title={seo} key={id} to={`/categoria/${external_id}/${seo}/pagina=1`} className={external_id === menuActualLevel.external_id ? "cardBreadcrumb hover" : "cardBreadcrumb"}>
                                                                <img src={icon+'?scale=30' != null ? icon+'?scale=30' : NO_IMAGE} alt={seo} width="30" height="30" loading="lazy" />
                                                                <p>{name}</p>
                                                            </Link>
                                                        : null
                                                            )

                                                }
                                            </div>
                                        }
                                    </>
                                : null
                                    
                        }
                        <div className="breacrumbSubCategory">
                            {menuChildrens &&
                                <>
                                        {menuChildrens.map(({id, external_id, icon, name, seo, status, visible})=>
                                            status === 1 && visible === 1
                                                ?
                                                    <Link title={seo} key={id} to={`/categoria/${external_id}/${seo}/pagina=1`} className="cardBreadcrumb">
                                                        <img src={icon+'?scale=30' != null ? icon+'?scale=30' : NO_IMAGE} alt={seo} width="30" height="30" loading="lazy" />
                                                        <h2>{name}</h2>
                                                    </Link>
                                                : null
                                                )}
                                </>
                            }
                            {level >= 2 &&
                                <div title={menuActualLevel.seo} className="cardBreadcrumb hover" onClick={subLevelBrotherBreadcrumb}>
                                    <img src={menuActualLevel.icon+'?scale=30' != null ? menuActualLevel.icon+'?scale=30' : NO_IMAGE} alt={menuActualLevel.seo} width="30" height="30" loading="lazy" />
                                    <p>{menuActualLevel.name}</p>
                                </div>
                                
                            }
                        </div>
                    </div>
                </div>
            </section>
    )
}

export function BreadcrumbCategory(props){
    const {
        idCategory,
        guardarCategoria
    } = props

    const [menuTreeCat, setMenuTreeCat] = useState()
    const [menuActualLevel, setMenuActualLevel] = useState(null)
    const [menuParents, setMenuParents] = useState(null)
    const [menuParentTr, setMenuParentTr] = useState(null)
    const [menuBrothers, setMenuBrothers] = useState(null)
    const [menuChildrens, setMenuChildrens] = useState(null)
    const [idParents, setIdParents] = useState(null)
    const { isMobile } = useIsMobile()


    useEffect(()=>{
        setMenuActualLevel(null)
        setMenuParents(null)
        setMenuParentTr(null)
        setMenuBrothers(null)
        setMenuChildrens(null)
        setIdParents(null)
        if(idCategory){
            //console.log('idCategory',idCategory)
            getCategoryMenuTree(idCategory)
            .then(setMenuTreeCat)
        }
    },[idCategory])

    //- Toma el valor de los padres e hijos
    useEffect(()=>{
        if(menuTreeCat){
            if(menuTreeCat.data){
                if(menuTreeCat.data.data){
                    if(menuTreeCat.data.data.children){
                        setMenuChildrens(menuTreeCat.data.data.children)
                    }
                    if(menuTreeCat.data.data.ancestors_tree){
                        setMenuParents(menuTreeCat.data.data.ancestors_tree.children)
                    }
                    setIdParents(menuTreeCat.data.data.parent_id)
                    setMenuActualLevel({
                        external_id: menuTreeCat.data.data.external_id,
                        icon: menuTreeCat.data.data.icon,
                        level: menuTreeCat.data.data.level,
                        name: menuTreeCat.data.data.name,
                        status: menuTreeCat.data.data.status,
                        visible: menuTreeCat.data.data.visible
                    })
                }
            }
        }
    },[menuTreeCat])

    //- Toma el valor de los hermanos
    useEffect(()=>{
        if(menuParents){
            if(idParents){
                let brother = menuParents.find( parent => parent.id == String(idParents) )
                if(brother){
                    setMenuParentTr(brother)
                    setMenuBrothers(brother.children)
                }
            }
        }
    },[menuParents, idParents])


    useEffect(()=>{
        if(menuActualLevel){
            //console.log('menuActualLevel',menuActualLevel)
        }
        if(menuChildrens){
            //console.log('menuChildrens',menuChildrens)
        }
        if(menuBrothers){
            //console.log('menuBrothers',menuBrothers)
        }
        if(menuParents){
            //console.log('menuParents',menuParents)
        }
        if(menuParentTr){
            //console.log('menuParentTr',menuParentTr)
        }
    },[menuActualLevel, menuChildrens, menuParents, menuBrothers, menuParentTr])

    return(
        isMobile &&
            isMobile === 'desktop'
                ?
                    menuActualLevel &&
                        menuChildrens &&
                            <ErrorBoundary>
                                <BreadcrumbCompDesktop
                                    menuActualLevel={menuActualLevel}
                                    menuChildrens={menuChildrens}
                                    level={menuActualLevel.level}
                                />
                            </ErrorBoundary>

                :  isMobile === 'movile'
                    ? null
                    :
                        menuActualLevel &&
                            <ErrorBoundary>
                                <BreadcrumbCompMovil
                                    menuActualLevel={menuActualLevel}
                                    menuParents={menuParents}
                                    menuParentTr={menuParentTr}
                                    menuBrothers={menuBrothers}
                                    menuChildrens={menuChildrens}
                                    level={menuActualLevel.level}
                                    guardarCategoria={guardarCategoria}
                                />
                            </ErrorBoundary>                   
    )
}