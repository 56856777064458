import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import "./style.sass";
import { useIsMobile } from 'hooks/useIsMobile';
import { Link, useHistory } from 'react-router-dom';
import Loading from 'components/Loading';
import 'react-light-accordion/demo/css/index.css';
import { useServices } from 'hooks/useServices';

export function RestauranteDesktop({ data, loading }) {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        //window.ineum('page', 'Restaurante');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])


    return (
        <>
            {loading && <Loading />}

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="container">

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Restaurante Sanborns</p>
                            </div>
                        </section>

                        <div className="contImages">

                            {data &&
                                data.map(({id, image, link}) =>
                                    link.includes("panaderia") 
                                        ? <a href={link} key={id} rel="noopener noreferrer" target="_blank">
                                            <img src={image} alt={"logo" + id} className='active'/>
                                        </a>
                                        : <Link to={"/restaurante-menu"+link} key={id}>
                                            <img src={image} alt={"logo" + id} className='active'/>
                                        </Link>
                            )}    

                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}



export function RestauranteMobile({ data, loading }) {
    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        //window.ineum('page', 'Restaurante');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN">
                        <div className="btn closeBack" onClick={onClose} ></div>
                        <p className="title">Restaurante Sanborns</p>
                    </div>

                    <div className="dataModal">

                        <div className="boxAN">

                            <div className="bxSlider">
                                <div className="contImages">

                                    {data &&
                                        data.map(({id, image, link}) =>
                                            link.includes("panaderia") 
                                                ? <a href={link} key={id} rel="noopener noreferrer" target="_blank">
                                                    <img src={image} alt={"logo" + id} className='active'/>
                                                </a>
                                                : <Link to={"/restaurante-menu"+link} key={id}>
                                                    <img src={image} alt={"logo" + id} className='active'/>
                                                </Link>
                                    )}        

                                </div>
                                                            
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export function Restaurante() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        //window.ineum('page', 'Restaurante');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const {
        headers,
        statusCode,
        data,
        error,
        loading
    } = useServices({
        method: 'get',
        url: `/staticJson/restaurante.json`,
        timeout: 25000,
        withCredentials: false,
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        }
    })

    return (
        <>
            { isMobile &&
                isMobile === 'desktop'
                ? <RestauranteDesktop data={data} loading={loading}/>

                : isMobile === 'movile'
                    ? null
                    : <RestauranteMobile data={data} loading={loading}/>
            }
        </>
    )
}