import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Header from 'pages/Header/HeaderNavegacion';
import Footer from 'pages/Footer/FooterDesktop';
import { WishlistTemplateDesktop } from 'pages/MiCuenta/MiWishlist/WishlistDesktop';
import { WishlistTemplateMobile } from 'pages/MiCuenta/MiWishlist/WishlistMobile';
import BarraCP from 'components/BarCP/BarHeaderCP';
import Loading from 'components/Loading';
import postProductCart from 'services/postProductCart';
import { getWishlist, deleteWishlist } from 'services/Wishlist';
import { useRepairResponse } from 'hooks/useRepairJson';
import { useIsMobile } from 'hooks/useIsMobile';
import { decodeStorage, encodeStorage } from 'functions/storageBase64';

import { useValidateSession } from 'hooks/useValidateSession'
import { useToken } from 'hooks/useToken';

import { useCart } from 'hooks/useCart'

export default function Wishlist() {

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'My Account | My Wishlist Page');
    }, [])

    const { isMobile } = useIsMobile();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [rawWishlistProducts, setRawWishlistProducts] = useState({});
    const [wishlistProducts, setWishlistProducts] = useState({});
    const [removeProduct, setRemoveProduct] = useState(false);
    const [wishlistProdDel, setWishlistProdDel] = useState(false);
    const [wishlistUpdate, setWishlistUpdate] = useState('');
    const [addCart, setAddCart] = useState('');
    const [responseCart, SetResponseCart] = useState(false);
    const [errorText, setErrorText] = useState('');
    const { responseData, setData } = useRepairResponse();

    const { 
        cartCount, 
        carrito,
        okAdd,
        Product_Add,
        Product_Update,
        Product_Delete
    } = useCart({firstActive: true, name: "WishlistPage", timeRef: 1000})

    useEffect(()=>{
        if(okAdd){
            SetResponseCart(carrito)
            setLoading(false)
        }
    },[okAdd])


    /*  --------------------------- Inicio --------------------------------------  */
        //  Hooks para validar tiempo y refresh token mas state de boton
        const {checkValidateSession, atExp, actTime} = useValidateSession()
        const refreshCyR = useToken({firstActive: false, name: "wishlist", timeRef: 1000})
        const [actionOK, setActionOk] = useState(false)
    /*  --------------------------- Fin --------------------------------------  */

        //manda id seleccionado a eliminar de wishlist
        useEffect(() => {
            if (removeProduct) {
                checkValidateSession(true)
                setActionOk('remove')
            }else{
                setTimeout(function(){
                    checkValidateSession(true)
                    setActionOk('ready')
                },700)
            }
        }, [removeProduct])    

    /*  --------------------------- Inicio --------------------------------------  */
        //  Ajusta Los tiempos y refresca token
        const [serviceRT, setServiceRT] = useState(false)
        const [uD, setUD] = useState(false)
        useEffect(() => {
            if(actionOK){
                let  actTimeLS = Date.now() 
                let  atExpLS = decodeStorage('access_token_exp') * 1000
                setUD(false)
                
                // console.log('------------------------------')
                // console.log('actTimeLS : ', new Date() )
                // console.log('atExp   : ', new Date (atExpLS) )
                // console.log('time ok : ', actTimeLS <= atExpLS)
                // console.log('actionOK: ', actionOK)
                
                if(actTimeLS <= atExpLS || Date.now() <= decodeStorage('access_token_exp') * 1000 ){
                    setServiceRT(true)
                }
                else{
                    setTimeout(function(){
                        refreshCyR.forceToken(true)
                    },300)
                    setTimeout(function(){
                        setUD(true)
                    },900)
                }
            }
        }, [uD, actionOK])
    /*-------------------------------------------------*/
        // hace la peticion a servicio
        useEffect(() => {
            if(actionOK === 'ready'){
                setServiceRT(false)
                getWishlist()
                .then(setRawWishlistProducts)
                setActionOk(false)
            }
            else if(actionOK === 'remove'){
                setServiceRT(false)
                deleteWishlist({ idProduct: removeProduct })
                .then(setRawWishlistProducts)
                setActionOk(false)
            }
            
        }, [serviceRT])
    /*  --------------------------- Fin --------------------------------------  */


    //- formatea la respuesta del servicio
    useEffect(() => {
        //console.log(rawWishlistProducts)
        if (rawWishlistProducts) {
            if (rawWishlistProducts?.statusCode === 200) {
                if (typeof (rawWishlistProducts?.data) === 'object') {
                    if (rawWishlistProducts?.data) {
                        if (rawWishlistProducts?.data?.data) {
                            setWishlistProducts({
                                'products': rawWishlistProducts?.data?.data?.products,
                                'total_items': rawWishlistProducts?.data?.data?.total_items
                            })
                        }
                    }
                }
                else if (typeof (rawWishlistProducts?.data) === 'string') {
                    setData(rawWishlistProducts?.data);
                }
                else {
                    setWishlistProducts({});
                    setErrorText(rawWishlistProducts?.data);
                    setLoading(false);
                }
            }
            else if (rawWishlistProducts?.statusCode !== undefined ) {
                setWishlistProducts({});
                setErrorText(rawWishlistProducts?.data.metadata?.message);
                setLoading(false);
                //console.log( rawWishlistProducts.data.metadata.message )
            }
        }
    }, [rawWishlistProducts])

    //remueve los textos de la respuesta cuando es un string
    useEffect(() => {
        if (responseData) {
            if (responseData?.data) {
                setWishlistProducts({
                    'products': responseData?.data?.products,
                    'total_items': responseData?.data?.total_items
                })
            }
        }
    }, [responseData])

    //manda id seleccionado a eliminar de wishlist
    // useEffect(() => {
    //     if (removeProduct) {
    //         // deleteWishlist({ idProduct: removeProduct })
    //         // .then(setRawWishlistProducts)
    //         checkValidateSession(true)
    //         setActionOk('remove')
    //     }
    // }, [removeProduct])



    // remueve loading una ves que carga o actualiza
    useEffect(() => {
        if (wishlistProducts?.total_items >= 1) {
            setLoading(false);
        }
        else if (wishlistProducts?.total_items === 0) {
            setLoading(false);
        }
    }, [wishlistProducts])

    // actualiza el storage para mostrar los corazones
    useEffect(() => {
        if (wishlistProdDel) {
            setWishlistProducts(wishlistUpdate);
            setWishlistProdDel(false);
        }
    }, [wishlistProdDel])

    useEffect(() => {
        if (addCart) {
            Product_Add([addCart])
            // postProductCart(addCart)
            //     .then(SetResponseCart)
        }
    }, [addCart])

    useEffect(() => {
        if (responseCart) {
            history.push('/carrito/detalle');
        }
    }, [responseCart])

    // funcion que 
    const updateDelete = (val) => {
        //console.log(val)
        setRemoveProduct(val);
        setLoading(true);
        setWishlistUpdate(val);
    }

    const addToCart = (val) => {
        setAddCart(val);
        setLoading(true);
    }

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const reciveDirection = (val) => {
        setUpdateCPBar(val);
    }

    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    const arrayWish = [];
    useEffect(() => {
        if (wishlistProducts) {
            if (wishlistProducts.products) {
                wishlistProducts.products.map(cosa =>
                    arrayWish.push(String(cosa.id))
                )
            }
        }
        encodeStorage('productsWishlist', arrayWish);
    }, [wishlistProducts])

    //recibe la direccion del CP   
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPostal] = useState(codigoP);
    const changeDirectionBar = (val) => {
        setCodigoPostal(val);
        setUpdateCPBar(val);
    };



    return (
        <>
            {loading && <Loading />}
            <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection} headerEnCategoria={"headerListaDeseos"} tituloPagina="Mi Lista de Deseos" />
            {isMobile && isMobile !== 'desktop' && isMobile !== 'movile'
                ? <div className="barraCpTienda wlbarra">
                    <section className="barCpResponsive">
                        <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
                    </section>

                    {/* <section className="barStoreResponsive">
                    <BarraSelectorTienda changeCP={changeDirectionBar} clase="barStoreHeader"/>
                </section> */}
                </div>
                : null
            }

            {isMobile
                ? isMobile === 'desktop'
                    ? <>
                        <section className="barCpResponsive">
                            <BarraCP changeCP={reciveDirection} clase="barHeaderCP" />
                        </section>
                        <WishlistTemplateDesktop
                            rawWishlistProducts={rawWishlistProducts}
                            wishlistProducts={wishlistProducts}
                            errorText={errorText}
                            setLoading={setLoading}
                            updateDelete={updateDelete}
                            addToCart={addToCart}
                        />
                    </>
                    : <WishlistTemplateMobile
                            rawWishlistProducts={rawWishlistProducts}
                            wishlistProducts={wishlistProducts}
                            errorText={errorText}
                            setLoading={setLoading}
                            updateDelete={updateDelete}
                            addToCart={addToCart}
                        />
                : null
            }
            
            <Footer />
        </>
    )
}