import React, { useState } from 'react';
import './style.sass';

export default function ShareComponent({ ...props }) {
    const {
        title
    } = props

    const urlLocation = window.location.href
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    //console.log(isMobile)

    const [showShare, setShowShare] = useState(true)

    const handleShare = () => {
        setShowShare(!showShare)
    }
    const shareFacebook = () => {
        //console.log('click F')
        window.open(`https://www.facebook.com/sharer/sharer.php?display=page&u=${urlLocation}&t=${title}`, '_blank')
        setShowShare(true)
    }
    const shareTwitter = () => {
        //console.log('click T')
        window.open(`https://twitter.com/intent/tweet?text=Viendo%20${title}%20en%20SanbornsMX!!%20${urlLocation}`, '_blank')
        setShowShare(true)
    }
    const sharePrint = () => {
        window.print()
        setShowShare(true)
    }
    const shareWhatsApp = () => {
        //console.log('click WA')
        window.open(`https://api.whatsapp.com/send?text=${urlLocation}`, '_blank')
        // window.open(`whatsapp://send?text=${urlLocation}`, '_blank')
        setShowShare(true)
    }
    const shareMail = () => {
        //console.log('click WA')
        window.open(`mailto:%20?Subject=Viendo%20${title}%20en%20SanbornsMX&body=Viendo%20${title}%20en%20SanbornsMX%20%20%20%20%20${urlLocation}`, '_blank')
        // window.open(`whatsapp://send?text=${urlLocation}`, '_blank')
        setShowShare(true)
    }

    return (
        <div className={showShare ? "shareModules" : "shareModules active"}>
            <span onClick={handleShare} className="shareBtn" aria-label="boton compartir"></span>
            <ul>
                <li onClick={shareFacebook} className="iconFacebook" aria-label="Boton compartir en Facebook"></li>
                <li onClick={shareTwitter} className="iconTwitter" aria-label="Boton compartir en Twitter"></li>
                <li onClick={sharePrint} className="iconPrint" aria-label="Boton imprimir pagina"></li>
                <li onClick={shareMail} className="iconMail" aria-label="Boton compartir por E-mail"></li>
                {/* {isMobile && */}
                <li onClick={shareWhatsApp} className="iconWhatsApp" aria-label="Boton compartir en Whatsapp"></li>
                {/* } */}
            </ul>
        </div>
    )

}


export function ShareComponentDetail({ ...props }) {
    const {
        title
    } = props

    const urlLocation = window.location.href
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    //console.log(isMobile)

    const [showShare, setShowShare] = useState(true)

    const handleShare = () => {
        setShowShare(!showShare)
    }
    const shareFacebook = () => {
        //console.log('click F')
        window.open(`https://www.facebook.com/sharer/sharer.php?display=page&u=${urlLocation}&t=${title}`, '_blank')
        setShowShare(true)
    }
    const shareTwitter = () => {
        //console.log('click T')
        window.open(`https://twitter.com/intent/tweet?text=Viendo%20${title}%20en%20SanbornsMX!!%20${urlLocation}`, '_blank')
        setShowShare(true)
    }
    const sharePrint = () => {
        window.print()
        setShowShare(true)
    }
    const shareWhatsApp = () => {
        //console.log('click WA')
        // window.open(`whatsapp://send?text=${urlLocation}`, '_blank')
        window.open(`https://api.whatsapp.com/send?text=${urlLocation}`, '_blank')
        setShowShare(true)
    }
    const shareMail = () => {
        //console.log('click WA')
        window.open(`mailto:%20?Subject=Viendo%20${title}%20en%20SanbornsMX&body=Viendo%20${title}%20en%20SanbornsMX%20%20%20%20%20${urlLocation}`, '_blank')
        // window.open(`whatsapp://send?text=${urlLocation}`, '_blank')
        setShowShare(true)
    }

    return (
        <div className={showShare ? "shareModulesDetail" : "shareModulesDetail active"}>
            <span onClick={handleShare} className="shareBtn" aria-label="boton compartir"></span>
            <ul>
                <li onClick={shareFacebook} className="iconFacebook" aria-label="Boton compartir en Facebook"></li>
                <li onClick={shareTwitter} className="iconTwitter" aria-label="Boton compartir en Twitter"></li>
                <li onClick={sharePrint} className="iconPrint" aria-label="Boton imprimir pagina"></li>
                <li onClick={shareMail} className="iconMail" aria-label="Boton compartir por E-mail"></li>
                {/* {isMobile && */}
                    <li onClick={shareWhatsApp} className="iconWhatsApp" aria-label="Boton compartir en Whatsapp"></li>
                {/* } */}
            </ul>
        </div>
    )

}


export function ShareComponentDetailTexto({ ...props }) {
    const {
        title
    } = props

    const urlLocation = window.location.href
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    //console.log(isMobile)

    const [showShare, setShowShare] = useState(true)

    const handleShare = () => {
        setShowShare(!showShare)
    }
    const shareFacebook = () => {
        //console.log('click F')
        window.open(`https://www.facebook.com/sharer/sharer.php?display=page&u=${urlLocation}&t=${title}`, '_blank')
        setShowShare(true)
    }
    const shareTwitter = () => {
        //console.log('click T')
        window.open(`https://twitter.com/intent/tweet?text=Viendo%20${title}%20en%20SanbornsMX!!%20${urlLocation}`, '_blank')
        setShowShare(true)
    }
    const sharePrint = () => {
        window.print()
        setShowShare(true)
    }
    const shareWhatsApp = () => {
        //console.log('click WA')
        // window.open(`whatsapp://send?text=${urlLocation}`, '_blank')
        window.open(`https://api.whatsapp.com/send?text=${urlLocation}`, '_blank')
        setShowShare(true)
    }
    const shareMail = () => {
        //console.log('click WA')
        window.open(`mailto:%20?Subject=Viendo%20${title}%20en%20SanbornsMX&body=Viendo%20${title}%20en%20SanbornsMX%20%20%20%20%20${urlLocation}`, '_blank')
        // window.open(`whatsapp://send?text=${urlLocation}`, '_blank')
        setShowShare(true)
    }

    return (
        <div className={showShare ? "shareModulesDetailText" : "shareModulesDetailText active"}>
            <span onClick={handleShare} className="shareBtn" aria-label="boton compartir"></span>
            <span onClick={handleShare}>Compartir</span>
            <ul>
                <li onClick={shareFacebook} className="iconFacebook" aria-label="Boton compartir en Facebook"></li>
                <li onClick={shareTwitter} className="iconTwitter" aria-label="Boton compartir en Twitter"></li>
                <li onClick={sharePrint} className="iconPrint" aria-label="Boton imprimir pagina"></li>
                <li onClick={shareMail} className="iconMail" aria-label="Boton compartir por E-mail"></li>
                {/* {isMobile && */}
                    <li onClick={shareWhatsApp} className="iconWhatsApp" aria-label="Boton compartir en Whatsapp"></li>
                {/* } */}
            </ul>
        </div>
    )

}