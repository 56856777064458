import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import { HIDDEN_DISCOUNT } from 'constants/general';
import CurrencyFormat from 'react-currency-format';
import ModalImageFulFilment from 'components/Modal';
import StarsPrincipal from 'components/StarsFive/starsPrincipal';
import { decodeStorage, encodeStorage } from 'functions/storageBase64';
import { ButtonAddToBagCircle } from 'components/ProductDetail/ShopProduct/ButtonsPayment/ButtonAddToCart';
import { repareTitleSEO, repareTextHtml } from 'functions/repareDataService';
import { useIsMobile } from 'hooks/useIsMobile';
import { dataLayerOutProduct } from 'functions/dataLayerFunction';
import { ErrorBoundary } from 'components/Error/ErrorBoundary'
import { SectionAutor } from 'components/ProductDetail/SectionAttributesBoock/sectionAutor';

export default function ModuleShopData({ ...props }) {
    const {
        id,
        title,
        store,
        brand,
        discount,
        price,
        sale_price,
        is_digital,
        reviews,
        // isfree,
        stock,
        updateStockTC,
        updateFF,
        status,
        fullfilment,
        // dataImage,
        // category,
        skuChildren,
        colorText,
        super_express,
        ean,
        attributes,
        libro
    } = props

    // console.log('ModuleShopData: ',props)
    const { isMobile } = useIsMobile();
    const history = useHistory();
    const location = useLocation();
    const urlLink = location.href;
    const hashLink = location.hash;
    // const firstVisit = decodeStorage('firstVisit');

    const [showModal, SetShowModal] = useState(false);
    const [stockUD, setStockUD] = useState(stock);

    useEffect(() => {
        // console.log("module shop data ---- ", updateStockTC)
        if (updateStockTC) {
            setStockUD(updateStockTC);
        }
    }, [stock, updateStockTC, updateFF])

    useEffect(() => {
        if (id) {
            setStockUD(stock);
        }
    }, [stock, id])

    const handleClose = () => {
        history.push(location.pathname);
    }

    const showExpress = () => {
        SetShowModal(true);
    }

    useEffect(() => {
        if (hashLink === "#modalExpress") {
            showExpress();
        } else {
            SetShowModal(false);
        }
    }, [hashLink, urlLink])


    const [superE, setSuperE] = useState(false)
    useEffect(() => {
        if (fullfilment) {
            if (fullfilment?.fulfillment) {
                if (isMobile === "desktop") {
                    if (!decodeStorage('firstVisit')) {
                        if (document.querySelector('.moduleShopCardProduct .fullFilment')) {
                            history.push(`#modalExpress`, null);
                            encodeStorage('firstVisit', true);
                        }
                    }
                }
            }
        }
        if (super_express === "super_express" || super_express === true) {
            setSuperE("true")
        }
        else {
            setSuperE(false)
        }
    }, [super_express, fullfilment])

    useEffect(() => {
        if (isMobile === "desktop") {
            if (superE === "true") {
                if (!decodeStorage('firstVisit')) {
                    if (document.querySelector('.moduleShopCardProduct .fullFilment')) {
                        history.push(`#modalExpress`, null);
                        encodeStorage('firstVisit', true);
                    }
                }
            }
        }
    }, [superE])


    const [messageStock, setMessageStock] = useState("¡Producto Agotado!")
    useEffect(() => {
        if (!stock || stock === 0 || status === false) {
            //console.log('agotadoooooo')
            if (!stock || stock === 0) {
                setMessageStock("¡Producto Agotado!")
                dataLayerOutProduct({ id: id, stock: stock, message: 'producto_agotado' })
            } else if (stock >= 1 || status === false) {
                setMessageStock("¡Producto Agotado!")
                dataLayerOutProduct({ id: id, stock: stock, message: 'no_disponible' })
            }
        }
    }, [stock, status])

    // console.log('-------------')
    // console.log(fullfilment.cyr)
    // console.log(updateFF)
    // console.log(fullfilment.is_digital)
    // console.log(fullfilment.fulfillment)
    // console.log(fullfilment.cex)
    // console.log('reviews -------------', reviews)
    const tiendaSeo = repareTitleSEO(store.name);

    return (
        <>
            <div className="moduleShopTitle">
                <h1 className="h2">{repareTextHtml(title)}</h1>
                <ErrorBoundary>
                    {!libro ? 
                        <div className="contenedorVendidoPor">
                            {/* {String(store.name).toLowerCase() !== "sanborns"
                                ? <p className="vendidoPor">Vendido por <Link to={`/tienda/${store.id}/${tiendaSeo}/pagina=1`}>{store.name}</Link></p>
                                : <p className="vendidoPor">Vendido por {store.name} </p>
                            } */}
                            <p className="vendidoPor">Marca {brand}</p>
                            {store.id === 3496
                                ? <p className="vendidoPor1">SKU {skuChildren}</p>
                                : null
                            }
                        </div>
                    :null}
                </ErrorBoundary>

                <ErrorBoundary>
                    {!libro ? 
                        <div className="contenedorVendidoPor">
                            {store.id === 3496
                                ? <p className="ean">EAN {ean}</p>
                                : null
                            }
                        </div>
                    :null}    
                </ErrorBoundary>

                <ErrorBoundary>
                    {isMobile === "desktop"
                        ?
                            <>
                                <div className={libro ? "startsMarca libro" : "startsMarca"}>
                                    {/* <div className="contenedorMarca">
                                        <p className="vendidoPor">Marca {brand}</p>
                                    </div> */}

                                    <div className="startBag">
                                        {reviews && reviews.reviews && reviews.reviews.length >= 1
                                            ? <Link to='#modalStarsProduct' className="starsAndReview">
                                                <StarsPrincipal score={reviews.bottomline.average_score} />
                                                <p className="link">{reviews.bottomline.total_review > 1 ? '' + reviews.bottomline.total_review + ' opiniones' : '' + reviews.bottomline.total_review + ' opinión'}</p>
                                            </Link>
                                            : null
                                        }

                                        {/* <ButtonAddToBagCircle
                                            id={id}
                                            cantProduct=""//{cantProduct}
                                            sizeColorSKU="" //{sizeColorSKU}
                                            size_colorExist= {3} //={size_color.length}

                                            title={title}
                                            price={price}
                                            sale_price={sale_price}
                                            dataImage={dataImage}
                                            is_digital={is_digital}
                                            category={category}
                                            brand={brand}
                                            variant="" //{colorSel}
                                        /> */}
                                    </div>
                                </div>
                            </>
                        :
                            <>
                                {/* <div className="contenedorMarca">
                                    <p className="vendidoPor">Marca {brand}</p>
                                </div> */}
                                {reviews && reviews?.reviews && reviews?.reviews?.length >= 1
                                    ?
                                    <div className="startBag libro">
                                        <Link to='#modalStarsProduct' className="starsAndReview">
                                            <StarsPrincipal score={reviews?.bottomline?.average_score} />
                                            <p className="link">{reviews?.bottomline?.total_review > 1 ? '' + reviews?.bottomline?.total_review + ' opiniones' : '' + reviews?.bottomline?.total_review + ' opinión'}</p>
                                        </Link>           
                                    </div>
                                    : null
                                }
                                <ErrorBoundary>
                                    {libro ?
                                        <SectionAutor
                                            attributes={attributes}
                                            brand={brand}
                                        />
                                        :null
                                    }
                                </ErrorBoundary>
                            </>
                    }
                </ErrorBoundary>

            </div>

            <ErrorBoundary>
                {stock && stock > 0 && status !== false
                    ? <div className="moduleShopPrice">
                        <div className="dataPrice">
                            <ErrorBoundary>
                                {isMobile !== 'desktop' &&
                                    <CurrencyFormat
                                        value={sale_price}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        renderText={value =>
                                            <div className="priceSale">
                                                <div className="pPrice">{value} MXN
                                                    {price !== sale_price
                                                        ? <div className="contDiscount">
                                                            <CurrencyFormat
                                                                value={price}
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                prefix={'$'}
                                                                renderText={value => <span className="textUnderline">{value}MXN</span>}
                                                            />
                                                            {discount <= HIDDEN_DISCOUNT
                                                                ? null
                                                                : <span className="discount">-{discount}%</span>
                                                            }
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        }
                                    />
                                }
                            </ErrorBoundary>


                            {/* {stockUD && status
                                ? <>
                                    {stockUD === 1
                                        ? <div className="lastProducts">Queda 1 pieza</div>
                                        : null
                                    }
                                    {stockUD >= 2 && stockUD <= 14
                                        ? <div className="lastProducts">Últimas {stockUD} piezas</div>
                                        : null
                                    }
                                    {stockUD >= 15 && stockUD <= 30
                                        ? <div className="lastProducts">Últimas piezas</div>
                                        : null
                                    }
                                </>
                                : null

                            } */}
                            {/*is_digital && <div className="stickerDigitalDetalle">Producto Digital</div>*/}

                            {/*<ErrorBoundary>
                                <div className="isFreeRecogeHoy">
                                    <div className="isExt">
                                         <div className="isFree">
                                        <img src="/img/envio.svg" alt="envio"></img>
                                        <p>
                                            {isfree === false || sale_price <= ENVIO_GRATIS
                                                ? <span className="noIsFree">Envío a domicilio</span>
                                                : <span className="noIsFree">Envío gratis</span>
                                            }
                                        </p>
                                    </div> 

                                    </div>
                                </div>
                            </ErrorBoundary>*/}

                            {/*<ErrorBoundary>    
                                {isMobile !== "desktop"
                                    ? fullfilment.fulfillment || updateFF === "true" || superE === "true"
                                        ? <Link to="#modalExpress" className="fullFilment" onClick={showExpress}>Express</Link>
                                        : null
                                    : null
                                }
                            </ErrorBoundary>*/}

                        </div>
                        
                        <ErrorBoundary>
                            {showModal &&
                                <ModalImageFulFilment onClose={handleClose} closeBody="modalExpress" title={isMobile === "desktop" ? "Producto Express " : "Producto Express"} type={isMobile === "desktop" ? "" : "full"} isMobile={isMobile}>
                                    <div className="contentImageFF">
                                        {/* <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/app/fullFilment/info_express_desktop.png`} loading="lazy" alt="express image" width="390" height="516" /> */}

                                        <img src={`/img/camion-de-reparto-express.svg`} loading="lazy" alt="express image" width="255" height="100" />
                                        <img src={`/img/titulo_express.png`} loading="lazy" alt="express image" width="255" height="28" />
                                        <ul>
                                            <li className="icoFF1">Disponibilidad inmediata y entrega más rápida.</li>
                                            <li className="icoFF2">Rastrea en cualquier momento.</li>
                                            <li className="icoFF3">Devoluciones más fáciles y rápidas. </li>
                                        </ul>

                                        <div className="boxEnteradoFull">
                                            <button className="btnEnteradoFull" onClick={handleClose}>Entendido</button>
                                        </div>
                                    </div>
                                </ModalImageFulFilment>
                            }
                        </ErrorBoundary>
                    </div>
                    :
                        isMobile != "desktop"
                        ?
                            <div className="productoAgotado">
                                <p>{messageStock}</p>
                                <span></span>
                            </div>
                        : null
                }
            </ErrorBoundary>
        </>
    )
}