import React, { useState, useEffect } from 'react';
import { getPedido, getReasons, postCreateTiket, LoginAyudaDinamica } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import './ayudaDinamicaDesktop.sass';
import { LABEL_PREPARANDO_PRODUCTO, LABEL_AUTORIZADO, PEDIDOS_PENDIENTES_PAGO, PEDIDOS_PENDIENTES_AUTORIZAR, PEDIDOS_PRODUCTO_ENTREGADO, FORMA_PAGO_POST_PAGO, FORMA_PAGO_REEMBOLSABLE, LABEL_PROCESO_RECOLECCION, LABEL_EN_CAMINO, PENDIENTE } from 'constants/general';
import { removeDuplicates } from 'functions/removeDuplicates';
import { ModalError, ModalEstasSeguro, ModalEstasSeguroProducto, ModalOcurrioUnError } from '../vista4-modal';
import ModalPortal from 'components/Modal';
import Loading from 'components/Loading';
import { useStorage64 } from 'hooks/useStorageBase64';
import { removeStorage } from 'functions/storageBase64';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { USERNAME, PASSWORD, PUBLICKEY } from 'constants/general';

export function SectionEnqueNecesitasAyudaDesktop(props) {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);

    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [resultadoRazones, setResultadoRazones] = useState(false);
    const [crearTicket, setCrearTicket] = useState(false);
    const [ima, setima] = useState([]);
    const [estatusPedido, setEstatusPedido] = useState(0);
    const [orderNumber, setOrderNumber] = useState(0);
    const [productos, setProductos] = useState(false);
    const [productoUnico, setProductoUnico] = useState(false);
    const [mostrarCancelar, setMostrarCancelar] = useState(false);
    const [mostrarProblemas, setMostrarProblemas] = useState(false);
    const [razones, setRazones] = useState(false);
    const [showModal, SetShowModal] = useState(false);
    const [showModalError, SetShowModalError] = useState(false);
    const [razonSeleccionada, setRazonSeleccionada] = useState(false);
    const [nombreRazonSeleccionada, setNombreRazonSeleccionada] = useState("");
    const [loading, setLoading] = useState(false);
    const [remover, setRemover] = useState(false);
    const [idClaro, setIdClaro] = useState(false);
    const profileData = useStorage64('profileData');
    const [formaPago, setFormaPago] = useState(false);
    const [, setInformacionStorage] = useStorage64("infoTicket", "");
    const [, setSaveStatus] = useStorage64('saveStatus', '');
    const [statusProducto, setStatusProducto] = useState(0);
    const [guia, setGuia] = useState(0);
    const [, setSaveStatusProducto] = useStorage64('saveStatusProducto', '');
    const [, setSaveIdStatusProducto] = useStorage64('saveIdStatusProducto', '');
    const [, setStorageIdProblem] = useStorage64("storageIdProblem", "");
    const [, setInformacionStorageEntregado] = useStorage64("infoTicketEntregado", "");
    const [idEstatusDeProducto, setIdEstatusDeProducto] = useState(0);
    const [, setActionId] = useStorage64("storageActionId", "");
    const [, setStorageMotivoEntregado] = useStorage64('storageMotivoEntregado', '');
    const [isReturnable, setIsReturnable] = useState(true);
    const [flagEstadoNoManejado, setFlagEstadoNoManejado] = useState(false);

    // obtengo el id del usuario del storage
    useEffect(() => {
        if (profileData != "undefined") {
            setIdClaro(profileData[0].idClaro);
        }
    }, [profileData])

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if ((resultadoRazones && resultadoRazones.razones) && (resultadoPedido && resultadoPedido.pedido)) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido, resultadoRazones])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // obtiene servicio pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido)
    }, [])

    useEffect(() => {
        let imagenesProductos = [];
        let productosGlobal = [];
        let imagenProducto = [];
        let producto = [];
        let productoStatus = [];
        let productoGuia = [];
        let productoIdStatus = [];
        let productoReturnable = [];

        removeStorage('storageIdProblem');
        removeStorage("infoTicket");
        removeStorage("saveStatus");
        removeStorage("saveStatusProducto");
        removeStorage("saveIdStatusProducto");
        removeStorage("storageIdProblem");
        removeStorage("storageCD");
        removeStorage("infoTicketEntregado");
        removeStorage("storageMotivoEntregado");
        removeStorage("storageActionId");
        removeStorage("storageImgProducto");

        if (resultadoPedido) {
            if (resultadoPedido.pedido) {
                // recorre por pedido
                if (resultadoPedido.pedido && Number(idProductoEstate) === 0) {

                    if (resultadoPedido.pedido[0]) {

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            imagenesProductos.push({ "url": elem.images[0].url })
                        )

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            productosGlobal.push({ "id": elem.related_product_id })
                        )

                        setima(imagenesProductos);
                        setEstatusPedido(resultadoPedido.pedido[0].axxi.status_axxi_id);
                        setSaveStatus(resultadoPedido.pedido[0].axxi.status_axxi_id);
                        setOrderNumber(resultadoPedido.pedido[0].order_number);
                        setFormaPago(resultadoPedido.pedido[0].payment_method.id);
                        setProductos(productosGlobal);
                    }
                }
                // recorre por producto
                else if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {

                    if (resultadoPedido.pedido[0]) {

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem.images[0].url, "id": elem.related_product_id })
                        )
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && producto.push({ "id": elem.related_product_id })
                        )
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoStatus.push({ "status": elem.status })
                        )
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoGuia.push({ "code": elem.tracking.code })
                        )
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoIdStatus.push({ "status_id": elem.status_id })
                        )
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoReturnable.push({ "is_returnable": elem.delivery_date.is_returnable })
                        )

                        let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                        setima(limpioImagen);
                        let limpiaProducto = removeDuplicates(producto, idProductoEstate);
                        setProductoUnico(limpiaProducto);

                        let limpiaStatus = removeDuplicates(productoStatus, idProductoEstate);
                        setSaveStatusProducto(limpiaStatus[0].status);
                        setStatusProducto(limpiaStatus[0].status);
                        let limpiaGuia = removeDuplicates(productoGuia, idProductoEstate);
                        setGuia(limpiaGuia[0].code);

                        setFormaPago(resultadoPedido.pedido[0].payment_method.id);
                        setEstatusPedido(resultadoPedido.pedido[0].axxi.status_axxi_id);
                        setSaveStatus(resultadoPedido.pedido[0].axxi.status_axxi_id);
                        setOrderNumber(resultadoPedido.pedido[0].order_number);
                        setIdEstatusDeProducto(productoIdStatus[0].status_id);
                        setSaveIdStatusProducto(productoIdStatus[0].status_id);

                        setIsReturnable(productoReturnable[0].is_returnable);
                    }
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
                LoginAyudaDinamica({ username: USERNAME, password: PASSWORD, publicKey: PUBLICKEY })
                setTimeout(() => {
                    getPedido()
                        .then(setResultadoPedido)
                }, 1000);
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 404) {
                setFlagEstadoNoManejado(true)
            }
        }
    }, [resultadoPedido])

    // obtiene servio razones de cancelacion
    useEffect(() => {
        getReasons()
            .then(setResultadoRazones)
    }, [])

    useEffect(() => {
        if (resultadoRazones) {
            if (resultadoRazones.razones) {
                setRazones(resultadoRazones.razones)
            }
            else if (resultadoRazones.statusCode && resultadoRazones.statusCode === 401) {
                LoginAyudaDinamica({ username: USERNAME, password: PASSWORD, publicKey: PUBLICKEY })
                setTimeout(() => {
                    getReasons()
                        .then(setResultadoRazones)
                }, 1000);
            }
            else if (resultadoRazones.statusCode && resultadoRazones.statusCode === 404) {
                setFlagEstadoNoManejado(true)
            }
        }
    }, [resultadoRazones])

    // regresar a la pagina anterior
    const onClose = () => {
        history.goBack(-1);
    }

    // onclick al seleccionar razon de cancelacion
    const seleccionoReason = (reasonId, reasonName) => {
        //razon id es diferente de otro
        if (Number(reasonId) !== 7) {
            // pedidos pendiente de pago, pendiente de autorizacion o 
            //pedidos:  autorizados, producto-> preparando tu producto pago diferente de oxxo, seven, deposito y tienda departamental y sin guia
            if (PEDIDOS_PENDIENTES_PAGO === estatusPedido ||
                PEDIDOS_PENDIENTES_AUTORIZAR.includes(estatusPedido) ||
                (idProductoEstate != 0 && LABEL_PREPARANDO_PRODUCTO === String(statusProducto).toLowerCase() && FORMA_PAGO_REEMBOLSABLE.includes(formaPago) && guia == null)
                || (idProductoEstate != 0 && LABEL_AUTORIZADO === String(statusProducto).toLowerCase() && FORMA_PAGO_REEMBOLSABLE.includes(formaPago) && guia == null)
                || (idProductoEstate !== 0 && !PEDIDOS_PRODUCTO_ENTREGADO.includes(idEstatusDeProducto) && FORMA_PAGO_REEMBOLSABLE.includes(formaPago))
            ) {

                SetShowModal(true);
                setRazonSeleccionada(reasonId);
                setNombreRazonSeleccionada(reasonName);
            }
            // pedido autorizado , producto preparando tu producto forma de pago oxxo, seven, tienda o tienda departamental y sin guia
            else if ((idProductoEstate != 0 && LABEL_PREPARANDO_PRODUCTO === String(statusProducto).toLowerCase() && FORMA_PAGO_POST_PAGO.includes(formaPago) && guia === null)
                || (idProductoEstate != 0 && LABEL_AUTORIZADO === String(statusProducto).toLowerCase() && FORMA_PAGO_POST_PAGO.includes(formaPago) && guia === null)
                || (idProductoEstate !== 0 && !PEDIDOS_PRODUCTO_ENTREGADO.includes(idEstatusDeProducto) && FORMA_PAGO_POST_PAGO.includes(formaPago))) {

                setInformacionStorage({
                    "reason_id": reasonId, "order_id": orderNumber, "order_number": orderNumber, "client_id": idClaro, "description": reasonName, "products_to_cancel": productoUnico,
                    "ticket_type_id": 1, "additional_details": [], "action_id": 0
                })

                history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/datos-bancarios");
            }
            else {
                setInformacionStorage({
                    "reason_id": reasonId, "order_id": orderNumber, "order_number": orderNumber, "client_id": idClaro, "description": reasonName, "products_to_cancel": productoUnico,
                    "ticket_type_id": 1, "additional_details": [], "action_id": 0
                })

                history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/datos-bancarios");
            }

        }
        else {
            if (idProductoEstate != 0) {
                history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/motivo-cancelacion");
            }
            else {
                history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/motivo-cancelacion");
            }
        }
    }

    // cerrar modal de eliminar pedidos
    const handleClose = () => SetShowModal(false);

    // cerrar modal de error
    const handleCloseError = () => SetShowModalError(false);

    // accion para si cancelar pedido
    const siRemove = () => {
        let reason_id = razonSeleccionada;
        let order_id = orderNumber;
        let order_number = orderNumber;
        let client_id = idClaro; // no llega
        let description = nombreRazonSeleccionada;
        let ticket_type_id = 1; // siempre es 1

        // pedidos pendiente de pago / pendiente de autorizacion
        let refound_details = []; // 
        let additional_details = []; // 
        let action_id = 0; // si es pendiente de pago o pendiente de autorizar

        // ocultar modal
        SetShowModal(false);
        setRemover(true);

        // enviar solicitud de cancelacion
        if (Number(razonSeleccionada) !== 7) {
            // PEDIDO---> pendiente de pago o pendiente de autorizacion 
            if ((estatusPedido === PEDIDOS_PENDIENTES_PAGO || PEDIDOS_PENDIENTES_AUTORIZAR.includes(estatusPedido))) {
                let products_to_cancel = productos;
                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id
                })
                    .then(setCrearTicket)
            }
            // PRODUCTO---> preparando tu producto
            else if ((idProductoEstate != 0 && LABEL_PREPARANDO_PRODUCTO === String(statusProducto).toLocaleLowerCase() && FORMA_PAGO_REEMBOLSABLE.includes(formaPago))
                || (idProductoEstate != 0 && LABEL_AUTORIZADO === String(statusProducto).toLocaleLowerCase() && FORMA_PAGO_REEMBOLSABLE.includes(formaPago))) {
                let products_to_cancel = productoUnico;
                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id
                })
                    .then(setCrearTicket)
            }
            else {
                if (idProductoEstate != 0) {
                    let products_to_cancel = productoUnico;
                    postCreateTiket({
                        reason_id,
                        order_id,
                        order_number,
                        client_id,
                        description,
                        products_to_cancel,
                        ticket_type_id,
                        refound_details,
                        additional_details,
                        action_id
                    })
                        .then(setCrearTicket)
                }
                else {
                    let products_to_cancel = productos;
                    postCreateTiket({
                        reason_id,
                        order_id,
                        order_number,
                        client_id,
                        description,
                        products_to_cancel,
                        ticket_type_id,
                        refound_details,
                        additional_details,
                        action_id
                    })
                        .then(setCrearTicket)
                }

            }
        }
    }

    // si la respuesta es ticket creado === 201 redirecciona a cancelacion exitosa
    useEffect(() => {
        if (crearTicket) {
            if (crearTicket.metadata) {
                if (Number(crearTicket.metadata.http_status) === 201) {
                    if (idProductoEstate === 0) {
                        history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/cancelacion-exitosa");
                    }
                    else if (idProductoEstate != 0) {
                        if ((LABEL_PROCESO_RECOLECCION === String(statusProducto).toLocaleLowerCase())
                            || (LABEL_EN_CAMINO === String(statusProducto).toLocaleLowerCase())) {
                            history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/no-puede-cancelar");
                        }
                        else {
                            history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/cancelacion-exitosa");
                        }
                    }
                }
            }
            else {
                SetShowModalError(true);
            }
        }
    }, [crearTicket])

    // mostrar el loading mientras se crea el ticket
    useEffect(() => {
        if (remover) {
            if (crearTicket) {
                setLoading(false);
                setRemover(false);
            }
            else {
                setLoading(true);
            }
        }
    }, [remover, crearTicket])

    //no cancelar -> cerrar modal y regresar a mis pedidos
    const noRemove = () => {
        SetShowModal(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    // regresar a pedido en modal error
    const regresarPedidos = () => {
        SetShowModalError(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    // cambia informacion de la pagina al presionar cancelar compra
    const handleCancelarCompra = () => {
        let reason_id = 7;
        let order_id = orderNumber;
        let order_number = orderNumber;
        let client_id = idClaro;
        let description = "solicita cancelación";
        let ticket_type_id = 1; // siempre es 1
        let refound_details = [];
        let additional_details = [];
        let action_id = 0;
        let products_to_cancel = productoUnico;

        // PRODUCTO EN PROCESO DE RECOLECCION / PRODUCTO EN CAMINO
        if ((idProductoEstate !== 0 && LABEL_PROCESO_RECOLECCION === String(statusProducto).toLocaleLowerCase())
            || (idProductoEstate !== 0 && LABEL_EN_CAMINO === String(statusProducto).toLocaleLowerCase())) {
            setRemover(true);
            postCreateTiket({
                reason_id,
                order_id,
                order_number,
                client_id,
                description,
                products_to_cancel,
                ticket_type_id,
                refound_details,
                additional_details,
                action_id
            })
                .then(setCrearTicket)
        }
        else {
            setMostrarCancelar(!mostrarCancelar);
        }
    }


    // --------------------------------para pedidos entregados-------------------------------------------------
    // activa bandera para mostrar seleccione un probleama
    const handleSelectProblema = () => {
        setMostrarProblemas(!mostrarProblemas);
    }

    // redireccion segun problema seleccionado 
    const seleccionoQuePaso = (id, name) => {
        removeStorage("storageIdProblem");
        setStorageIdProblem(id);

        if (Number(id) === 8) {
            setInformacionStorageEntregado({
                "reason_id": id, "order_id": orderNumber, "order_number": orderNumber, "client_id": idClaro, "products_to_cancel": productoUnico,
                "ticket_type_id": 1
            })

            history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/cuentanos-tu-problema/");
        }
        else if (Number(id) === 9) {
            setInformacionStorageEntregado({
                "reason_id": id, "order_id": orderNumber, "order_number": orderNumber, "client_id": idClaro, "products_to_cancel": productoUnico,
                "ticket_type_id": 1
            })
            setStorageMotivoEntregado(name);

            history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/que-quieres-hacer");
        }
        else if (Number(id) === 10) {
            setInformacionStorageEntregado({
                "reason_id": id, "order_id": orderNumber, "order_number": orderNumber, "client_id": idClaro, "products_to_cancel": productoUnico,
                "ticket_type_id": 1
            })
            setActionId(0);
            setStorageMotivoEntregado(name);

            history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/solicitar-cambio-devolucion");
        }
        else if (Number(id) === 11) {
            history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/no-funciona");
        }
    }

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])


    return (
        <>
            { loading && <Loading />}

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="miCuentaContainer">

                    <div className="containerAD">
                        <div className="boxBtnRegresarDktop">
                            {mostrarCancelar === false && mostrarProblemas === false
                                ? <>
                                    <div className="icoBack" onClick={regresarPedidos}></div>
                                    <button className="btnBackAD" onClick={regresarPedidos}>Mis pedidos</button>
                                </>
                                : mostrarCancelar === true
                                    ? <>
                                        <div className="icoBack" onClick={handleCancelarCompra}></div>
                                        <button className="btnBackAD" onClick={handleCancelarCompra}>Atrás</button>
                                    </>
                                    : mostrarProblemas &&
                                    <>
                                        <div className="icoBack" onClick={handleSelectProblema}></div>
                                        <button className="btnBackAD" onClick={handleSelectProblema}>Atrás</button>
                                    </>

                                // <div className="btn close" onClick={handleCancelarCompra} ></div>
                                // <div className="btn close" onClick={handleSelectProblema} ></div>
                            }
                        </div>

                        <div className="boxGeneralAD">
                            <div className="boxLeftAD">
                                <div className="contSectionRed">
                                    <div className={ima.length > 1 ? "imaDesktop more" : "imaDesktop"}>
                                        {ima.length > 0 && ima.map((elemento, i) => i <= 3 && <img key={i} src={elemento.url} alt={"imagen" + i} width={70} />)}
                                    </div>


                                    {mostrarCancelar === false && !mostrarProblemas
                                        ? <div className="textDektop"><p>¿En que necesitas ayuda?</p></div>
                                        : mostrarCancelar
                                            ? <div className="textDektop"><p>¿Por qué quieres cancelar?</p> </div>
                                            : mostrarProblemas && <div className="textDektop"> <p>¿Qué pasó con tu producto?</p> </div>
                                    }
                                </div>
                            </div>
                            <div className="boxRightAD">
                                {/* muestra copy cancelar mi compra  */}
                                {mostrarCancelar === false && idProductoEstate != 0 && !PEDIDOS_PRODUCTO_ENTREGADO.includes(idEstatusDeProducto) &&
                                    <div className="contenedorListadoAD" onClick={handleCancelarCompra}>
                                        <li>Cancelar mi compra</li>
                                    </div>
                                }
                                {mostrarCancelar === false && idProductoEstate === 0 && (PEDIDOS_PENDIENTES_AUTORIZAR.includes(estatusPedido) || PEDIDOS_PENDIENTES_PAGO === estatusPedido) &&
                                    <div className="contenedorListadoAD" onClick={handleCancelarCompra}>
                                        <li>Cancelar mi compra</li>
                                    </div>
                                }

                                {/* muestra copy problemas con mi producto */}
                                {mostrarProblemas === false && idProductoEstate != 0 && PEDIDOS_PRODUCTO_ENTREGADO.includes(idEstatusDeProducto) &&
                                    < div className="contenedorListadoAD" onClick={handleSelectProblema}>
                                        <li>Problemas con mi producto</li>
                                    </div>
                                }


                                {mostrarCancelar === true && !PEDIDOS_PRODUCTO_ENTREGADO.includes(idEstatusDeProducto) &&
                                    <>
                                        {(PEDIDOS_PENDIENTES_PAGO === estatusPedido && !PENDIENTE.includes(Number(formaPago))) &&
                                            <div className="copyEspera">
                                                <p>Si ya realizaste tu pago espera a que se vea reflejado antes de cancelar.</p>
                                            </div>
                                        }

                                        <div className="boxUlRazonesAD">
                                            <ul>
                                                {razones && razones.map((razon, id) =>
                                                    razon.id <= 7 &&
                                                    <li id={razon.id} key={razon.id} onClick={() => seleccionoReason(razon.id, razon.name)}>
                                                        <span className="nombreRazon">{razon.name}</span>
                                                        <span className="iconoRazon"></span>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </>
                                }

                                {/* seccion producto entregado */}
                                {idProductoEstate != 0 && PEDIDOS_PRODUCTO_ENTREGADO.includes(idEstatusDeProducto) && mostrarProblemas === true &&
                                    <>
                                        <div className="boxUlRazonesAD">
                                            <ul>
                                                {razones && razones.map((razon, id) =>
                                                    (razon.id > 7 && isReturnable === true) ?
                                                        <li id={razon.id} key={razon.id} onClick={() => seleccionoQuePaso(razon.id, razon.name)}>
                                                            <span className="nombreRazon">{razon.name}</span>
                                                            <span className="iconoRazon"></span>
                                                        </li>
                                                        : (razon.id > 8 && isReturnable === false) &&
                                                        <li id={razon.id} key={razon.id} onClick={() => seleccionoQuePaso(razon.id, razon.name)}>
                                                            <span className="nombreRazon">{razon.name}</span>
                                                            <span className="iconoRazon"></span>
                                                        </li>
                                                )}
                                            </ul>
                                        </div>
                                    </>
                                }
                            </div>

                        </div>

                        {/* mostrar modal estas seguro que deseas cancelar*/}
                        {(showModal && idProductoEstate === 0) &&
                            <ModalPortal type="modalSB" onClose={handleClose} >
                                <ModalEstasSeguro remvoeAction={siRemove} awaitAction={noRemove} />
                            </ModalPortal>
                        }

                        {/* mostrar modal estas seguro que deseas cancelar por producto*/}
                        {(showModal && idProductoEstate !== 0) &&
                            <ModalPortal type="modalSB" onClose={handleClose} >
                                <ModalEstasSeguroProducto remvoeAction={siRemove} awaitAction={noRemove} />
                            </ModalPortal>
                        }

                        {showModalError &&
                            <ModalPortal type="modalSB" onClose={handleCloseError} >
                                <ModalError okRegresar={regresarPedidos} />
                            </ModalPortal>
                        }

                        {/* diferente de producto entregado, pedido pendiente de pago , pedido pendiente de autorizar, pedido autorizado, preprarando tu producto */}
                        {flagEstadoNoManejado &&
                            <ModalPortal type="modalSB" onClose={handleCloseError} >
                                <ModalOcurrioUnError okRegresar={regresarPedidos} />
                            </ModalPortal>
                        }
                    </div>
                </section>
            </main>
        </>
    )
}