import React, { useEffect, useState } from 'react'
import './style.sass'
import { Link, useLocation } from 'react-router-dom'
import { decodeStorage, encodeStorage } from 'functions/storageBase64'
import { removeStorage } from 'functions/storageBase64'
import { postWishlist, deleteWishlist } from 'services/Wishlist'
import { dataLayerWishlistAdd } from 'functions/dataLayerFunction'
import { ModalWishlistPreLoginDetalle, ModalWishlistPreLogin } from 'components/LoginRegistro/modals'

import { useValidateSession } from 'hooks/useValidateSession'
import { useToken } from 'hooks/useToken';

export default function BtnWishlist({ ...props }) {
    const {
        id,
        nombre,
        precioLista,
        brand,
        categories,
        //index
        categoriesEmar
    } = props

    const isLoged = decodeStorage('session_token')
    var arrayProducts = decodeStorage('productsWishlist')
    const afterWhislist = id
    const location = useLocation()

    if (arrayProducts === '') {
        encodeStorage('productsWishlist', [])
    }

    const [favorite, setFavorite] = useState(false)
    const [addFavorite, setAddFavorite] = useState(false)
    const [removeWL, setRemoveWL] = useState(false)
    const [rawResponseFavorite, setRawResponseFavorite] = useState('')
    const [responseFavorite, setResponseFavorite] = useState('')
    const [blockBtn, setBlockBtn] = useState(false)
    const [updateFav, setUpdateFav] = useState(false)
    //const hostName = window.location.href 
    const hostName = window.location.origin + location.pathname


    const [updateAll, setUpdateAll] = useState()
    useEffect(() => {
        if (id) {
            if (arrayProducts.includes(String(id))) {
                document.querySelector(`.wl_${id}`).classList.add('active')
            }
            else {
                document.querySelector(`.wl_${id}`).classList.remove('active')
            }
        }
        if(updateAll === "addWL"){
            if(document.querySelectorAll(`.btnWishlistDetail`).length >= 1){
                if(document.querySelectorAll(`.btnWishlistDetail.wl_${id}`).length >= 1 ){
                    document.querySelector(`.btnWishlistDetail.wl_${id}`).classList.add('active')
                    document.querySelector(`.btnWishlistDetail.wl_${id}`).innerText = "Eliminar de lista de deseos"
                }
            }
        }else if(updateAll === "removeWL"){
            if(document.querySelectorAll(`.btnWishlistDetail`).length >= 1){
                if(document.querySelectorAll(`.btnWishlistDetail.wl_${id}`).length >= 1 ){
                    document.querySelector(`.btnWishlistDetail.wl_${id}`).classList.remove('active')
                    document.querySelector(`.btnWishlistDetail.wl_${id}`).innerText = "Agregar a Lista de deseos"
                }
            }
        }
    }, [id, updateAll])


    //- dataleyer add item
    const addWishlistDL = () => {
        if (id) {
            let catPadre, catHija, catNieta, newBrand
            if (categories) {
                if (categories[2]?.name) {
                    catPadre = categories[2]?.name
                    catHija = categories[1]?.name
                    catNieta = categories[0]?.name
                }
                else {
                    catPadre = categories[2]
                    catHija = categories[1]
                    catNieta = categories[0]
                }
            } else {
                catPadre = ""
                catHija = ""
                catNieta = ""
            }
            if (categoriesEmar) {
                let catEmarsys = categoriesEmar.split('>')
                catPadre = catEmarsys[0]
                catHija = catEmarsys[1]
                catNieta = catEmarsys[2]
            }
            if (brand) {
                newBrand = brand
            } else {
                newBrand = ""
            }
            const dataLayerData = {
                item: [
                    {
                        item_name: String(nombre),
                        item_id: String(id),
                        price: String(precioLista),
                        item_brand: String(newBrand),
                        item_category: String(catPadre),
                        item_category2: String(catHija),
                        item_category3: String(catNieta),
                        item_variant: "",
                        //index: index + 1
                        quantity: "1"
                    }
                ]
            }
            dataLayerWishlistAdd(dataLayerData)
            // console.log('products WL-> ', dataLayerData)
        }
    }

    // useEffect(()=>{
    //     if(idWish){
    //         console.log('wishlist id ->',id)
    //         console.log('wishlist idWish ->',idWish)
    //         console.log('arrayProducts',arrayProducts)
    //     }
    // },[idWish,id])

    //hace las peticiones a agregar o borrar favoritos
    const [addFav, setAddFav] = useState(false)
    const [addFavResp, setAddFavResp] = useState(false)



    /*  --------------------------- Inicio --------------------------------------  */
        //  Hooks para validar tiempo y refresh token mas state de boton
        const {checkValidateSession } = useValidateSession()
        const refreshCyR = useToken({firstActive: false, name: "btn-wishlist", timeRef: 1000})
        const [actionOK, setActionOk] = useState(false)
    /*  --------------------------- Fin --------------------------------------  */

    useEffect(() => {
        if (addFavorite) {
            if (id) {
                checkValidateSession(true)
                setActionOk('add')
            }
        }
        else if (removeWL) {
            if (id) {
                checkValidateSession(true)
                setActionOk('remove')
            }
        }
    }, [addFavorite, removeWL, id])

    /*  --------------------------- Inicio --------------------------------------  */
        //  Ajusta Los tiempos y refresca token
        const [serviceRT, setServiceRT] = useState(false)
        const [uD, setUD] = useState(false)
        useEffect(() => {
            if(actionOK){
                let  actTimeLS = Date.now() 
                let  atExpLS = decodeStorage('access_token_exp') * 1000
                setUD(false)
                
                // console.log('------------------------------')
                // console.log('actTimeLS : ', new Date() )
                // console.log('atExp   : ', new Date (atExpLS) )
                // console.log('time ok : ', actTimeLS <= atExpLS)
                // console.log('actionOK: ', actionOK)
                
                if(actTimeLS <= atExpLS){
                    setServiceRT(true)
                }
                else{
                    setTimeout(function(){
                        refreshCyR.forceToken(true)
                    },300)
                    setTimeout(function(){
                        setUD(true)
                    },900)
                }
            }
        }, [uD, actionOK])
    /*-------------------------------------------------*/
        // hace la peticion a servicio
        useEffect(() => {
            if(actionOK === 'add'){
                setServiceRT(false)
                postWishlist({ idProduct: id })
                .then(setRawResponseFavorite)
                setAddFavorite(false)
                setAddFav(true)
                setActionOk(false)
            }
            else if(actionOK === 'remove'){
                setServiceRT(false)
                deleteWishlist({ idProduct: id })
                .then(setRawResponseFavorite)
                setRemoveWL(false)
                setAddFav(false)
                setActionOk(false)
            }
        }, [serviceRT])
    /*  --------------------------- Fin --------------------------------------  */


    // ajusta la respuesta general 
    useEffect(() => {
        if (rawResponseFavorite) {
            if (rawResponseFavorite.statusCode === 200) {
                if (rawResponseFavorite.data) {
                    setResponseFavorite(rawResponseFavorite.data)
                    setUpdateAll('removeWL')
                }
            }
            else if (rawResponseFavorite.statusCode === 201) {
                if (rawResponseFavorite.data) {
                    setResponseFavorite(rawResponseFavorite.data)
                    setAddFavResp(true)
                    setUpdateAll('addWL')
                }
            }
            else if (rawResponseFavorite.statusCode !== undefined) {
                //setResponseFavorite({})
                // setErrorText(rawWishlistProducts.data.metadata.message)
                // setLoading(false)
                //console.log( rawWishlistProducts.data.metadata.message )
            }
        }
    }, [rawResponseFavorite])

    useEffect(() => {
        if (addFav && addFavResp) {
            addWishlistDL()
            setAddFav(false)
            setAddFavResp(false)
        }
    }, [addFav, addFavResp])



    // Agrga los favoritos al storage para que se visualicen en todo el portal
    useEffect(() => {
        if (responseFavorite) {
            if (typeof (responseFavorite) === 'object') {
                if (!responseFavorite.metadata.is_error) {
                    if (arrayProducts.includes(String(id))) {
                        arrayProducts.splice(arrayProducts.indexOf(String(id)), 1)
                        encodeStorage('productsWishlist', arrayProducts)
                        setBlockBtn(false)
                        setUpdateFav(true)
                    }
                    else {
                        arrayProducts.push(String(id))
                        encodeStorage('productsWishlist', arrayProducts)
                        setBlockBtn(false)
                        setUpdateFav(true)
                    }
                }
            }
            else if (typeof (responseFavorite) === 'string') {
                encodeStorage('productsWishlist', [])
                setBlockBtn(false)
                setUpdateFav(true)
            }
        }
    }, [responseFavorite])

    //evalua del arreglo interno si existe el item
    useEffect(() => {
        if (arrayProducts) {
            if (arrayProducts.includes(String(id))) {
                //console.log('si esta el', id)
                setFavorite(true)
            }
            else {
                setFavorite(false)
            }
        }
    }, [updateFav, id])

    //hace que solo se presione 1 ves el boton de wishlist
    useEffect(() => {
        if (blockBtn) {
            if (!favorite) {
                // notifica que se agregue a favoritos
                setAddFavorite(true)
            }
            else {
                // notifica que se elimine de favoritos
                setRemoveWL(true)
            }
        }
    }, [blockBtn])

    // manda al efecto que el boton fue presionado
    const handleFavorite = () => {
        setBlockBtn(true)
        setUpdateFav(false)
    }

    const saveFavorite = () => {
        removeStorage('productAfter')
        removeStorage('typeButton')
        encodeStorage('wishlistAfter', afterWhislist)
        //console.log('a.. ',afterWhislist)
    }

    return (
        <>
            {isLoged
                ?
                <div
                    className={favorite ? `btnWishlist wl_${id} active` : `btnWishlist wl_${id}`}
                    onClick={handleFavorite}
                    aria-label="Agregar a Wishlist">
                </div>
                :
                <ModalWishlistPreLogin
                    route={`?redirect=${hostName}`}
                    clase={`btnWishlist wl_${id}`}
                    saveFavorite={saveFavorite}
                    title="Agregar a Wishlist"
                />
            }
        </>
    )
}

export function BtnWishlistDetail({ ...props }) {
    const {
        id,
        nombre,
        precioLista,
        brand,
        categories,
        //index
        categoriesEmar
    } = props

    const isLoged = decodeStorage('session_token')
    var arrayProducts = decodeStorage('productsWishlist')
    const afterWhislist = id
    const location = useLocation()

    if (arrayProducts === '') {
        encodeStorage('productsWishlist', [])
    }

    const [favorite, setFavorite] = useState(false)
    const [addFavorite, setAddFavorite] = useState(false)
    const [removeWL, setRemoveWL] = useState(false)
    const [rawResponseFavorite, setRawResponseFavorite] = useState('')
    const [responseFavorite, setResponseFavorite] = useState('')
    const [blockBtn, setBlockBtn] = useState(false)
    const [updateFav, setUpdateFav] = useState(false)
    //const hostName = window.location.href 
    const hostName = window.location.origin + location.pathname


    const [updateAll, setUpdateAll] = useState()
    useEffect(() => {
        if (id) {
            if (arrayProducts.includes(String(id))) {
                document.querySelector(`.wl_${id}`).classList.add('active')
            }
            else {
                document.querySelector(`.wl_${id}`).classList.remove('active')
            }
        }
        if(updateAll === "addWL"){
            document.querySelector(`.wl_${id}`).classList.add('active')
        }else if(updateAll === "removeWL"){
            document.querySelector(`.wl_${id}`).classList.remove('active')
        }
    }, [id, updateAll])


    //- dataleyer add item
    const addWishlistDL = () => {
        if (id) {
            let catPadre, catHija, catNieta, newBrand
            if(categories){
                // console.log('categories-WL: ', categories)
                if (categories?.length === 1) {
                    if (categories[0].name) {
                        catPadre = ""
                        catHija = ""
                        catNieta = categories[0]?.name
                    }
                    else {
                        catPadre = ""
                        catHija = ""
                        catNieta = categories[0]
                    }
                } 
                else if (categories?.length === 2) {
                    if (categories[1].name) {
                        catPadre = ""
                        catHija = categories[1]?.name
                        catNieta = categories[0]?.name
                    }
                    else {
                        catPadre = ""
                        catHija = categories[1]
                        catNieta = categories[0]
                    }
                } 
                else if (categories?.length >= 3) {
                    if (categories[2].name) {
                        catPadre = categories[2]?.name
                        catHija = categories[1]?.name
                        catNieta = categories[0]?.name
                    }
                    else {
                        catPadre = categories[2]
                        catHija = categories[1]
                        catNieta = categories[0]
                    }
                } else {
                    catPadre = ""
                    catHija = ""
                    catNieta = ""
                }
            }
            if (categoriesEmar) {
                let catEmarsys = categoriesEmar.split('>')
                catPadre = catEmarsys[0]
                catHija = catEmarsys[1]
                catNieta = catEmarsys[2]
            }
            if (brand) {
                newBrand = brand
            } else {
                newBrand = ""
            }
            const dataLayerData = {
                item: [
                    {
                        item_name: String(nombre),
                        item_id: String(id),
                        price: String(precioLista),
                        item_brand: String(newBrand),
                        item_category: String(catPadre),
                        item_category2: String(catHija),
                        item_category3: String(catNieta),
                        item_variant: "",
                        //index: index + 1
                        quantity: "1"
                    }
                ]
            }
            dataLayerWishlistAdd(dataLayerData)
            // console.log('products WL-> ', dataLayerData)
        }
    }

    // useEffect(()=>{
    //     if(idWish){
    //         console.log('wishlist id ->',id)
    //         console.log('wishlist idWish ->',idWish)
    //         console.log('arrayProducts',arrayProducts)
    //     }
    // },[idWish,id])



    //hace las peticiones a agregar o borrar favoritos
    const [addFav, setAddFav] = useState(false)
    const [addFavResp, setAddFavResp] = useState(false)


    /*  --------------------------- Inicio --------------------------------------  */
        //  Hooks para validar tiempo y refresh token mas state de boton
        const {checkValidateSession } = useValidateSession()
        const refreshCyR = useToken({firstActive: false, name: "btn-wishlist-detail", timeRef: 1000})
        const [actionOK, setActionOk] = useState(false)
    /*  --------------------------- Fin --------------------------------------  */

    useEffect(() => {
        if (addFavorite) {
            if (id) {
                checkValidateSession(true)
                setActionOk('add')
            }
        }
        else if (removeWL) {
            if (id) {
                checkValidateSession(true)
                setActionOk('remove')
            }
        }
    }, [addFavorite, removeWL, id])

    /*  --------------------------- Inicio --------------------------------------  */
        //  Ajusta Los tiempos y refresca token
        const [serviceRT, setServiceRT] = useState(false)
        const [uD, setUD] = useState(false)
        useEffect(() => {
            if(actionOK){
                let  actTimeLS = Date.now() 
                let  atExpLS = decodeStorage('access_token_exp') * 1000
                setUD(false)
                
                // console.log('------------------------------')
                // console.log('actTimeLS : ', new Date() )
                // console.log('atExp   : ', new Date (atExpLS) )
                // console.log('time ok : ', actTimeLS <= atExpLS)
                // console.log('actionOK: ', actionOK)
                
                if(actTimeLS <= atExpLS){
                    setServiceRT(true)
                }
                else{
                    setTimeout(function(){
                        refreshCyR.forceToken(true)
                    },300)
                    setTimeout(function(){
                        setUD(true)
                    },900)
                }
            }
        }, [uD, actionOK])
    /*-------------------------------------------------*/
        // hace la peticion a servicio
        useEffect(() => {
            if(actionOK === 'add'){
                setServiceRT(false)
                postWishlist({ idProduct: id })
                .then(setRawResponseFavorite)
                setAddFavorite(false)
                setAddFav(true)
                setActionOk(false)
            }
            else if(actionOK === 'remove'){
                setServiceRT(false)
                deleteWishlist({ idProduct: id })
                .then(setRawResponseFavorite)
                setRemoveWL(false)
                setAddFav(false)
                setActionOk(false)
            }
        }, [serviceRT])
    /*  --------------------------- Fin --------------------------------------  */



    // ajusta la respuesta general 
    useEffect(() => {
        if (rawResponseFavorite) {
            if (rawResponseFavorite?.statusCode === 200) {
                if (rawResponseFavorite?.data) {
                    setResponseFavorite(rawResponseFavorite?.data)
                    setUpdateAll('removeWL')
                }
            }
            else if (rawResponseFavorite?.statusCode === 201) {
                if (rawResponseFavorite?.data) {
                    setResponseFavorite(rawResponseFavorite?.data)
                    setAddFavResp(true)
                    setUpdateAll('addWL')
                }
            }
            else if (rawResponseFavorite?.statusCode !== undefined) {
                //setResponseFavorite({})
                // setErrorText(rawWishlistProducts.data.metadata.message)
                // setLoading(false)
                //console.log( rawWishlistProducts.data.metadata.message )
            }
        }
    }, [rawResponseFavorite])

    useEffect(() => {
        if (addFav && addFavResp) {
            addWishlistDL()
            setAddFav(false)
            setAddFavResp(false)
        }
    }, [addFav, addFavResp])



    // Agrga los favoritos al storage para que se visualicen en todo el portal
    useEffect(() => {
        if (responseFavorite) {
            if (typeof (responseFavorite) === 'object') {
                if (!responseFavorite.metadata.is_error) {
                    if (arrayProducts.includes(String(id))) {
                        arrayProducts.splice(arrayProducts.indexOf(String(id)), 1)
                        encodeStorage('productsWishlist', arrayProducts)
                        setBlockBtn(false)
                        setUpdateFav(true)
                    }
                    else {
                        arrayProducts.push(String(id))
                        encodeStorage('productsWishlist', arrayProducts)
                        setBlockBtn(false)
                        setUpdateFav(true)
                    }
                }
            }
            else if (typeof (responseFavorite) === 'string') {
                encodeStorage('productsWishlist', [])
                setBlockBtn(false)
                setUpdateFav(true)
            }
        }
    }, [responseFavorite])

    //evalua del arreglo interno si existe el item
    useEffect(() => {
        if (arrayProducts) {
            if (arrayProducts.includes(String(id))) {
                //console.log('si esta el', id)
                setFavorite(true)
            }
            else {
                setFavorite(false)
            }
        }
    }, [updateFav, id])

    //hace que solo se presione 1 ves el boton de wishlist
    useEffect(() => {
        if (blockBtn) {
            if (!favorite) {
                // notifica que se agregue a favoritos
                setAddFavorite(true)
            }
            else {
                // notifica que se elimine de favoritos
                setRemoveWL(true)
            }
        }
    }, [blockBtn])

    // manda al efecto que el boton fue presionado
    const handleFavorite = () => {
        setBlockBtn(true)
        setUpdateFav(false)
    }

    const saveFavorite = () => {
        removeStorage('productAfter')
        removeStorage('typeButton')
        encodeStorage('wishlistAfter', afterWhislist)
        //console.log('a.. ',afterWhislist)
    }
//console.log(" favorite--> ", favorite)
    return (
        <>
            {isLoged
                ? <div className={favorite ? `btnWishlistDetail wl_${id} active` : `btnWishlistDetail wl_${id}`}
                    onClick={handleFavorite} aria-label="Agregar a Wishlist">
                    {favorite === true
                        ? "Eliminar de lista de deseos"
                        : "Agregar a Lista de deseos"
                    }
                    </div>
                : <ModalWishlistPreLoginDetalle
                    route={`?redirect=${hostName}`}
                    clase={`btnWishlistDetail wl_${id}`}
                    saveFavorite={saveFavorite}
                    title="Agregar a Wishlist"
                />
            }
        </>
    )
}