import {BASE_PATH_API, TIMEOUT_TIME, STAGE_DIRNAME, BASE_PATH_API_DEV} from 'constants/general'
import axios from 'axios'

export async function postRegister ({raw} = {}){

    let apiURL = null
    if(STAGE_DIRNAME === "dev"){
        apiURL = `${BASE_PATH_API_DEV.registro}/app/ApiRest/Registro`
    }else{
        apiURL = `${BASE_PATH_API}/app/ApiRest/Registro`
    }

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        mode: 'no-cors',
		crossdomain: true,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8'
        },
        data: raw
    };


    return await axios(config)
    .then(res => {
        const {
            // config,
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.response.data,
            'error': error.response.data.msg
        }
    })
}