import React, { useEffect, useState } from 'react'
import './style.sass'
import { Link } from "react-router-dom"
import LazyLoad from 'react-lazy-load'
import ProgressiveImage from 'react-progressive-graceful-image'
import CurrencyFormat from "react-currency-format"
import { repareImage, repareTitleSEO, repareTextHtml } from 'functions/repareDataService'
import { encodeStorage, removeStorage } from 'functions/storageBase64'
import { useDispatch } from 'context/storeProvider'
import { TYPES } from 'context/storeReducer'
import { VARIANTS_PER_PORTAL, HIDDEN_DISCOUNT, ENVIO_GRATIS, NO_IMAGE, WHITE_PIXEL, IMAGE_THUMB, SIZE_IMAGE_THUMB, LAZY_L } from 'constants/general'
import { ErrorBoundary } from 'components/Error/ErrorBoundary'


import StarsFive from 'components/StarsFive'
import BtnWishlist from 'components/Wishlist'

export default function CardProduct({ ...props }) {

    const {
        id,
        nombre,
        nombreSEO,
        precio,
        precioLista,
        foto = NO_IMAGE,
        descuento,
        store,
        review,
        comments,
        shipping_price,
        fulfillment,
        super_express,
        categories,
        categoriesEmar,
        brand,
        creditCardMonths,
        creditCardPrice,
        telmexMonths,
        telmexPrice,
        features,
        vista,
        scarabitem,
        clasee,
        parentGroup,
        index,
        clicRecoge,
        pagosSears,
        priority = 'Low',
        productTotal
    } = props

    const dispatch = useDispatch()
    // console.log(" props producto--> ", clicRecoge, " --- ", pagosSears)

    let newSeo, newShipping, newFulFillment
    if (nombreSEO) {
        newSeo = nombreSEO
    } else {
        newSeo = repareTitleSEO(nombre)
    }

    const [newFoto, setNewFoto] = useState(WHITE_PIXEL)
    const [placeH, setPlaceH] = useState(WHITE_PIXEL)
    const [updatePhoto, setUpdatePhoto] = useState(false)
    useEffect(() => {
        setPlaceH(WHITE_PIXEL)
        setNewFoto(WHITE_PIXEL)
        setTimeout(function () {
            setUpdatePhoto(true)
        }, 100)
    }, [id, foto])
    useEffect(() => {
        if (updatePhoto) {
            if (foto.indexOf('?') >= 0) {
                setPlaceH(repareImage(foto) + `&scale=${SIZE_IMAGE_THUMB}`)
                setNewFoto(repareImage(foto) + '&scale=350')
                setUpdatePhoto(false)
                //nsetNewFoto(foto)
            } else {
                setPlaceH(repareImage(foto) + `&scale=${SIZE_IMAGE_THUMB}`)
                setNewFoto(repareImage(foto) + '?scale=350')
                setUpdatePhoto(false)
                //setNewFoto(foto)
            }
        }
    }, [updatePhoto])


    if (shipping_price === '1' || shipping_price === true) {
        newShipping = '1'
    }
    if (fulfillment === '1' || fulfillment === true || fulfillment === "true" || super_express === '1' || super_express === true || super_express === "true" ) {
        newFulFillment = "1"
    }
    if (fulfillment) {
        if (fulfillment.quantity) {
            newFulFillment = "1"
        }
    }

    // console.log('shipping_price: ',shipping_price)
    // console.log('categoriesEmar: ',categoriesEmar)
    // console.log('comments: ',comments)
    // console.log('review: ', review)
    // console.log("new foto: ", newFoto, "     foto: ", foto)

    const cyr = clicRecoge; //"1"//
    const csears = pagosSears; //"112"//

    const clickCard = () =>{
        dispatch({type: TYPES.UPDATE_PRODUCT, payload: productTotal ? productTotal : {} })
        window.sessionStorage.setItem("productTotal",  JSON.stringify(productTotal ? productTotal : {}) )
        let seccion = window.location.pathname.split('/')[1]
        // console.log('location: ', window.location.pathname)
        // console.log('location: ', window.location.pathname.split('/')[1])
        if(seccion === "producto"){
            // console.log('click: ', window.scrollY)
            encodeStorage('searchBusqueda', window.scrollY)
        }else{
            // console.log('sin click: ')
            removeStorage('searchBusqueda')
        }
    }

    return (
        <article className="cardProduct" data-scarabitem={scarabitem} onClick={clickCard}>
            <BtnWishlist
                id={id}
                nombre={nombre}
                precioLista={precioLista}
                brand={brand}
                categories={categories}
                categoriesEmar={categoriesEmar}
                index={index}
            />
            {/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
                ?
                    <a href={`/producto/${id}/${nombreSEO != null ? nombreSEO : newSeo}/`} title={nombreSEO != null ? nombreSEO : newSeo}>
                        {/* 
                        {isMobile === "desktop" && descuento < HIDDEN_DISCOUNT
                            ? null
                            : <div className="boxGeneralDisc">
                                <div className="bxDisc">
                                    <span className="discoutnCard">-{descuento}%</span>
                                </div>
                            </div>
                        } */}
                        {IMAGE_THUMB
                            ?
                                <ProgressiveImage
                                    src={foto != null ? newFoto : NO_IMAGE}
                                    placeholder={foto != null ? placeH : NO_IMAGE}
                                >
                                    {(src) =>
                                        LAZY_L
                                            ?
                                                <LazyLoad height={300} width={300} offset={100} threshold={0.95}>
                                                    <img
                                                        src={src}
                                                        alt={nombreSEO != null ? nombreSEO : repareTextHtml(nombre)}
                                                        width="300"
                                                        height="300"
                                                        loading="lazy"
                                                        decoding="async"
                                                        fetchpriority={priority}
                                                    />
                                                </LazyLoad>
                                            :
                                                <img
                                                    src={src}
                                                    alt={nombreSEO != null ? nombreSEO : repareTextHtml(nombre)}
                                                    width="300"
                                                    height="300"
                                                    loading="lazy"
                                                    decoding="async"
                                                    fetchpriority={priority}
                                                />
                                    }
                                </ProgressiveImage>
                            :
                                LAZY_L
                                    ?
                                        <LazyLoad height={300} width={300} offset={100} threshold={0.95}>
                                            <img
                                                loading="lazy"
                                                src={foto != null ? newFoto : NO_IMAGE}
                                                alt={nombreSEO != null ? nombreSEO : repareTextHtml(nombre)}
                                                width="300"
                                                height="300"
                                                decoding="async"
                                                fetchpriority={priority}
                                            />
                                        </LazyLoad>
                                    :   <img
                                            src={foto != null ? newFoto : NO_IMAGE}
                                            alt={nombreSEO != null ? nombreSEO : repareTextHtml(nombre)}
                                            width="300"
                                            height="300"
                                            loading="lazy"
                                            decoding="async"
                                            fetchpriority={priority}
                                        />
                        }
                        <div className="contDataCard">
                            <h3 className="h4">{repareTextHtml(nombre)}</h3>
                            {store
                                ? <p className="h5">{store}</p>
                                : null
                            }
                            {review
                                ? <StarsFive score={review} comments={comments === 0 ? null : comments} />
                                : null
                            }
                            <div className="priceDiscountContent">
                                {descuento !== 0 && precioLista !== precio
                                    ? <div className="contenedorPrecioDescuento">
                                        <CurrencyFormat
                                            value={precio}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            renderText={value => <p className="precio11"><span className="textUnderline">{value} MXN</span></p>}
                                        />
                                        {descuento < HIDDEN_DISCOUNT
                                            ? null
                                            : <div className="boxGeneralDiscSan">
                                                {/* <div className="bxDiscMobile"> */}
                                                    <span className="discoutnCardMobile">-{descuento}%</span>
                                                {/* </div> */}
                                            </div>
                                        }
                                    </div>

                                    : null
                                }
                            </div>

                            <CurrencyFormat
                                value={precioLista}
                                displayType={'text'}
                                thousandSeparator={true} prefix={'$'} renderText={value => <p className="precio1">{value}<span className="mxn">MXN</span></p>}
                            />

                            {/* {precioLista >= ENVIO_GRATIS && shipping_price === "1"} */}
                            <div className="expressFreeCont">
                                {cyr && String(cyr) === "1"
                                    ? <img src="/img/recoger-sanborns.svg" alt="Recoger en tienda" className="cyr-resultados"></img>
                                    : null
                                }
                                {newShipping
                                    ?
                                    newShipping === "1"
                                        ? <p className={String(cyr) === "1" && newFulFillment === "1"
                                            ? "envioGratis bordeL bordeR"
                                            : String(cyr) !== "1" && newFulFillment === "1"
                                                ? "envioGratis bordeR"
                                                : String(cyr) === "1" && newFulFillment !== "1"
                                                    ? "envioGratis bordeL"
                                                    : "envioGratis"
                                        } aria-label="Envío gratis">Envío gratis</p>
                                        : null
                                    : precioLista >= ENVIO_GRATIS
                                        ? <p className={String(cyr) === "1" && newFulFillment === "1" &&  VARIANTS_PER_PORTAL?.express === true
                                            ? "envioGratis bordeL bordeR"
                                            : String(cyr) !== "1" && newFulFillment === "1" &&  VARIANTS_PER_PORTAL?.express === true
                                                ? "envioGratis bordeR"
                                                : String(cyr) === "1" && newFulFillment !== "1" 
                                                    ? "envioGratis bordeL"
                                                    : "envioGratis"
                                        } aria-label="Envío gratis">Envío gratis</p>
                                        : null
                                }
                                {newFulFillment && VARIANTS_PER_PORTAL?.express === true
                                    ? newFulFillment === "1"
                                        ? <p className={((newShipping && newShipping === "1") || (precioLista >= ENVIO_GRATIS)) ? "fullFilment" : "fullFilment bordeL"} aria-label="Express">Express</p>
                                        : null
                                    : null
                                }
                            </div>
                            <div className="tmxMonthsCont">
                                {/*      {VARIANTS_PER_PORTAL.telmex &&
                                        telmexMonths &&
                                            telmexMonths >= 1
                                                ? telmexPrice &&
                                                    <div className="tmxMonthly"> desde  
                                                        <CurrencyFormat
                                                            value={telmexPrice}
                                                            displayType={'text'}
                                                            thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>}
                                                        /> al mes
                                                    </div>
                                                : null
                                    }
                                    */}
                                {csears &&
                                    csears === "112"
                                    ?
                                    creditCardPrice &&
                                    <div className="bankSanborns"> Desde
                                        <CurrencyFormat
                                            //value={creditCardPrice}
                                            value={Number(creditCardPrice).toFixed()}
                                            displayType={'text'}
                                            thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>}
                                        /> al mes
                                    </div>
                                    : null
                                }
                                {creditCardMonths &&
                                    creditCardMonths >= 1
                                    ? creditCardPrice &&
                                    <div className="bankMonthly"> Desde
                                        <CurrencyFormat
                                            //value={creditCardPrice}
                                            value={Number(creditCardPrice).toFixed()}
                                            displayType={'text'}
                                            thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>}
                                        /> al mes
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </a>
                :
                    <>
                        <Link to={`/producto/${id}/${nombreSEO != null ? nombreSEO : newSeo}/`} title={nombreSEO != null ? nombreSEO : newSeo}>
                            {/* {descuento < HIDDEN_DISCOUNT
                                ? null
                                : <div className="boxGeneralDisc">
                                    <div className="bxDisc">
                                        <span className="discoutnCard">-{descuento}%</span>
                                    </div>
                                </div>
                            } */}
                            {IMAGE_THUMB
                                ?
                                    <ProgressiveImage
                                        src={foto != null ? newFoto : NO_IMAGE}
                                        placeholder={foto != null ? placeH : NO_IMAGE}
                                    >
                                        {(src) =>
                                            LAZY_L
                                                ?
                                                    <LazyLoad height={300} width={300} offset={100} threshold={0.95}>
                                                        <img
                                                            src={src}
                                                            alt={nombreSEO != null ? nombreSEO : repareTextHtml(nombre)}
                                                            width="300"
                                                            height="300"
                                                            loading="lazy"
                                                            decoding="async"
                                                            fetchpriority={priority}
                                                        />
                                                    </LazyLoad>
                                                :
                                                    <img
                                                        src={src}
                                                        alt={nombreSEO != null ? nombreSEO : repareTextHtml(nombre)}
                                                        width="300"
                                                        height="300"
                                                        loading="lazy"
                                                        decoding="async"
                                                        fetchpriority={priority}
                                                    />
                                        }
                                    </ProgressiveImage>
                                :
                                    LAZY_L
                                        ?
                                            <LazyLoad height={300} width={300} offset={100} threshold={0.95}>
                                                <img
                                                    loading="lazy"
                                                    src={foto != null ? newFoto : NO_IMAGE}
                                                    alt={nombreSEO != null ? nombreSEO : repareTextHtml(nombre)}
                                                    width="300"
                                                    height="300"
                                                    decoding="async"
                                                    fetchpriority={priority}
                                                />
                                            </LazyLoad>
                                        :   <img
                                                src={foto != null ? newFoto : NO_IMAGE}
                                                alt={nombreSEO != null ? nombreSEO : repareTextHtml(nombre)}
                                                width="300"
                                                height="300"
                                                loading="lazy"
                                                decoding="async"
                                                fetchpriority={priority}
                                            />
                            }
                            <div className="contDataCard">
                                <h3 className="h4">{repareTextHtml(nombre)}</h3>
                                {store
                                    ? <p className="h5">{store}</p>
                                    : null
                                }
                                {review
                                    ? <StarsFive score={review} comments={comments === 0 ? null : comments} />
                                    : null
                                }
                                <div className="priceDiscountContent">
                                    {descuento !== 0 && precioLista !== precio
                                        ?
                                        <><CurrencyFormat
                                            value={precio}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            renderText={value => <p><span className="textUnderline">{value} MXN</span>  </p>}
                                        />

                                            {descuento < HIDDEN_DISCOUNT
                                                ? null
                                                : <div className="boxGeneralDiscSan">
                                                    {/* <div className="bxDiscMobile"> */}
                                                        <span className="discoutnCardMobile">-{descuento}%</span>
                                                    {/* </div> */}
                                                </div>
                                            }
                                        </>
                                        : null
                                    }
                                </div>

                                <CurrencyFormat
                                    value={precioLista}
                                    displayType={'text'}
                                    thousandSeparator={true} prefix={'$'} renderText={value => <p className="precio1">{value}<span className="mxn">MXN</span>  </p>}
                                />

                                {/* {precioLista >= ENVIO_GRATIS && shipping_price === "1"} */}
                                <div className="expressFreeCont">
                                    {cyr && String(cyr) === "1"
                                        ? <img src="/img/recoger-sanborns.svg" alt="Recoger en tienda" className="cyr-resultados"></img>
                                        : null
                                    }
                                    {newShipping
                                        ? newShipping === "1"
                                            ? <p
                                                className={String(cyr) === "1" && newFulFillment === "1"
                                                    ? "envioGratis bordeL bordeR"
                                                    : String(cyr) !== "1" && newFulFillment === "1"
                                                        ? "envioGratis bordeR"
                                                        : String(cyr) === "1" && newFulFillment !== "1"
                                                            ? "envioGratis bordeL"
                                                            : "envioGratis"
                                                }
                                                aria-label="Envío gratis">Envío gratis</p>
                                            : null
                                        : precioLista >= ENVIO_GRATIS
                                            ? <p className={String(cyr) === "1" && newFulFillment === "1" &&  VARIANTS_PER_PORTAL.express === true
                                                ? "envioGratis bordeL bordeR"
                                                : String(cyr) !== "1" && newFulFillment === "1" &&  VARIANTS_PER_PORTAL.express === true
                                                    ? "envioGratis bordeR"
                                                    : String(cyr) === "1" && newFulFillment !== "1"
                                                        ? "envioGratis bordeL"
                                                        : "envioGratis"
                                            } aria-label="Envío gratis">Envío gratis</p>
                                            : null
                                    }
                                    {newFulFillment && VARIANTS_PER_PORTAL.express === true
                                        ? newFulFillment === "1"
                                            ? <p className={((newShipping && newShipping === "1") || (precioLista >= ENVIO_GRATIS)) ? "fullFilment" : "fullFilment bordeL"} aria-label="Express">Express</p>
                                            : null
                                        : null
                                    }
                                </div>
                                <div className="tmxMonthsCont">
                                    {/*  {VARIANTS_PER_PORTAL.telmex &&
                                        telmexMonths &&
                                            telmexMonths >= 1
                                                ? telmexPrice &&
                                                    <div className="tmxMonthly"> desde  
                                                        <CurrencyFormat
                                                            value={telmexPrice}
                                                            displayType={'text'}
                                                            thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>}
                                                        /> al mes
                                                    </div>
                                                : null
                                    }*/}
                                    {csears &&
                                        csears === "112"
                                        ? creditCardPrice &&
                                        <div className="bankSanborns"> Desde
                                            <CurrencyFormat
                                                //value={creditCardPrice}
                                                value={Number(creditCardPrice).toFixed()}
                                                displayType={'text'}
                                                thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>}
                                            /> al mes
                                        </div>
                                        : null
                                    }

                                    {creditCardMonths && creditCardMonths >= 1
                                        ? creditCardPrice &&
                                        <div className="bankMonthly"> Desde
                                            <CurrencyFormat
                                                value={Number(creditCardPrice).toFixed()}
                                                displayType={'text'}
                                                thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>}
                                            /> al mes
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        </Link>
                    </>
            }
        </article>
    )
}

export function CardProducto({ ...props }) {

    const {
        id,
        nombre,
        nombreSEO,
        precio,
        precioLista,
        foto,
        descuento,
        store,
        review,
        comments,
        shipping_price,
        fulfillment,
        super_express,
        creditCardMonths,
        creditCardPrice,
        telmexMonths,
        telmexPrice,
        vista,
        clasee,
    } = props

    let newSeo, newFoto, newShipping, newFulFillment
    if (nombreSEO) {
        newSeo = nombreSEO
    } else {
        newSeo = repareTitleSEO(nombre)
    }

    if (foto.indexOf('?') >= 0) {
        newFoto = repareImage(foto) + '&scale=350'
        //newFoto = foto
    } else {
        newFoto = repareImage(foto) + '?scale=350'
        //newFoto = foto
    }
    if (shipping_price === '1' || shipping_price === true) {
        newShipping = '1'
    }

    if (fulfillment === '1' || fulfillment === true || fulfillment === "true" || super_express === '1' || super_express === true || super_express === "true" ) {
        newFulFillment = "1"
    }

    //console.log('shipping_price: ',shipping_price)
    // console.log('comments: ',comments)
    // console.log('review: ', review)
    // console.log("new foto --> ", newFoto, "------- foto: ", foto)

    return (
        <article className="cardProduct">
            <div className="anchorCardProduct">
                <div className="cardSupImageContent">
                    <BtnWishlist id={id} />
                    <Link to={`/producto/${id}/${nombreSEO != null ? nombreSEO : newSeo}/`} title={nombreSEO != null ? nombreSEO : newSeo}>
                        <img loading="lazy" src={foto != null ? newFoto : NO_IMAGE} alt={nombreSEO != null ? nombreSEO : nombre} width="300" height="300" />
                    </Link>
                </div>
                <div className="cardInfDataContent">
                    <BtnWishlist id={id} />
                    <Link to={`/producto/${id}/${nombreSEO != null ? nombreSEO : newSeo}/`} title={nombreSEO != null ? nombreSEO : newSeo}>
                        <div className="contDataCard">
                            <h3 className="h4">{repareTextHtml(nombre)}</h3>
                            {store
                                ? <p className="h5">{store}</p>
                                : null
                            }
                            {review
                                ? <StarsFive score={review} comments={comments === 0 ? null : comments} />
                                : null
                            }
                            <div className="priceDiscountContent">
                                {descuento !== 0 && precioLista !== precio
                                    ?
                                    <CurrencyFormat
                                        value={precio}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        renderText={value => <p><span className="textUnderline">{value}</span>  {descuento < HIDDEN_DISCOUNT ? null : <span className="discoutnCard">-{descuento}%</span>}</p>}
                                    />
                                    : null
                                }
                            </div>

                            <CurrencyFormat
                                value={precioLista}
                                displayType={'text'}
                                thousandSeparator={true} prefix={'$'} renderText={value => <p className="precio1">{value}<span className="mxn">MXN</span>  </p>}
                            />

                            {/* {precioLista >= ENVIO_GRATIS && shipping_price === "1"} */}
                            {newShipping
                                ?
                                newShipping === "1"
                                    ? <p className="envioGratis" aria-label="Envío gratis">Envío gratis</p>
                                    : null
                                :
                                precioLista >= ENVIO_GRATIS
                                    ? <p className="envioGratis" aria-label="Envío gratis">Envío gratis</p>
                                    : null
                            }
                            {newFulFillment
                                ?
                                newFulFillment === "1"
                                    ? <p className="fullFilment" aria-label="Express">Express</p>
                                    : null
                                : null
                            }
                        </div>
                    </Link>
                </div>
            </div>
        </article>
    )
}