import React, { useRef, useState, useEffect } from 'react';
import CardOrder from 'components/CardProduct/CardMyAccount/CardOrder';
import PaginadorPedidos from 'components/PaginadorPedidos';
import ModalFiltrosPedidos from './modalFiltroPedido';
import { Link } from 'react-router-dom';

export function MisPedidosTemplateMobile(props) {
    const {
        pedidos,
        days,
        setDays,
        handleSubmit,
        handleSelect,
        backHistory,
        searchPedidos,
        total_items,
        items_per_page,
        current_page,
        cambiarPagina,
        pag,
        rangoDias,
        setPedidos,
        history
    } = props;

    const selectFilter = useRef(null);

    const handleClick = () => {
        setDays(Number(0));
        selectFilter.current.value = 0;

        setPedidos(
            {
                ...pedidos,
                days: 0,
                pages: 1
            }
        )
        let nuevaUrl = `/mi-cuenta/mis-pedidos/dias=0/pagina=1`;
        history.replace(nuevaUrl);
    };

    const [checkedFecha, setCheckedFecha] = useState(rangoDias);
    const [ocultarMfiltroPedido, setOcultarMfiltroPedido] = useState(false);

    // const mostrarModal = () => {
    //     setOcultarMfiltroPedido(true);
    // }

    const hashLink = window.location.hash;
    const urlLink = window.location.href;
    // revisa si la url contiene la cadena para mostrar el modal
    useEffect(() => {
        if (hashLink === "#modalFiltrosPedidos") {
            setOcultarMfiltroPedido(true);
        } else {
            setOcultarMfiltroPedido(false);
        }
    }, [hashLink, urlLink])

    // cierra modal filtrar pedidos
    const cerrarModal = () => {
        if (ocultarMfiltroPedido === true) { history.goBack(); }
    }

    return (
        <>
            <div className="agrupacion">
                <div className="sectionTitlePedidos">
                    <button onClick={backHistory}> Mis pedidos</button>
                </div>

                {pedidos?.error && Number(days) < 31 &&  Number(days) === 0
                    ? null
                    : <div className="fijo">
                        <div className="contFilterMobile">
                            <Link onSubmit={handleSubmit} to={"#modalFiltrosPedidos"} >
                                {rangoDias === "0"
                                    ? <p ref={selectFilter} name="filtros" onChange={handleSelect} className="selectFiltros" >Todos los pedidos</p>
                                    : rangoDias === "7"
                                        ? <p ref={selectFilter} name="filtros" onChange={handleSelect} className="selectFiltros" >Últimos 7 días</p>
                                        : rangoDias === "30"
                                            ? <p ref={selectFilter} name="filtros" onChange={handleSelect} className="selectFiltros" >Últimos 30 días</p>
                                            : rangoDias === "180"
                                                ? <p ref={selectFilter} name="filtros" onChange={handleSelect} className="selectFiltros" >Últimos 6 meses</p>
                                                : rangoDias === "360"
                                                    ? <p ref={selectFilter} name="filtros" onChange={handleSelect} className="selectFiltros" >Último año</p>
                                                    : null
                                }
                            </Link>

                            {ocultarMfiltroPedido &&
                                <ModalFiltrosPedidos
                                    setOcultarMfiltroPedido={setOcultarMfiltroPedido}
                                    setCheckedFecha={setCheckedFecha}
                                    checkedFecha={checkedFecha}
                                    handleSelect={handleSelect}
                                    cerrarModal={cerrarModal}
                                />
                            }
                        </div>
                    </div>
                }
            </div>

            <main className="mainMobile">
                <section className="miCuentaContainer">
                    <div className="container">
                        <div className="contPedidos">
                            {pedidos?.error &&
                                <>
                                    <p className="parrafo1">
                                        {days < 31
                                            ? Number(days) === 0
                                                ? 'Actualmente no cuentas con ningún pedido'
                                                : `No tienes pedidos de los últimos ${days} días`
                                            : `No tienes pedidos de los últimos ${days / 30} meses`}.
                                    </p>
                                    {Number(days) === 0
                                        ? <p>
                                            <Link to="/" className="btn rojo">Comenzar a comprar</Link>
                                        </p>
                                        : <p>
                                            <span onClick={handleClick} className="btn rojo">Eliminar filtro</span>
                                        </p>
                                    }
                                </>
                            }
                            {pedidos.pedidos &&
                                pedidos.pedidos.map((pedido, ind) =>
                                    pedido.products && <CardOrder
                                        key={ind}
                                        order_number={pedido.order_number}
                                        products={pedido.products}
                                        buy_date={pedido.buy_date}
                                        total={pedido.total}
                                        estatusPedido={pedido.axxi.status_axxi_id}
                                    />
                                )
                            }
                        </div>
                    </div>

                    {searchPedidos &&
                        <PaginadorPedidos
                            pagination={pag}
                            cambiarPagina={cambiarPagina}
                            total_items={total_items[0]}
                            page={current_page}
                            page_size={items_per_page[0]}
                            total={10}
                            rangoDias={rangoDias} />
                    }
                </section>
            </main>
        </>
    )
}