import React, { useState, useEffect } from 'react';
import { getPedido, postCreateTiket, LoginAyudaDinamica } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import '../detallePedido/detallePedido.sass';
import './ayudaDinamica.sass';
import ImageUploader from "react-images-upload";
import swal from 'sweetalert'
import { useStorage64 } from 'hooks/useStorageBase64';
import { ACCESS_ID, ACCESS_KEY, BUCKET_NAME, DIR_NAME, FORMA_PAGO_REEMBOLSABLE, REGION, S3_URL, USERNAME, PASSWORD, PUBLICKEY } from 'constants/general';
import S3 from 'react-aws-s3';
import Loading from 'components/Loading';
import { ModalError } from './vista4-modal';
import ModalPortal from 'components/Modal';
import { useIsMobile } from 'hooks/useIsMobile';
import { AdjuntarFotografiasDesktop } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/AD-Desktop/vista36-adjuntarFotografias-desktop.js';

export function AdjuntarFotografiasMovil() {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [cargoFotos, setCargoFotos] = useState(false);
    const [pictures, setPictures] = useState([]);
    const [storageReasonId] = useStorage64('storageIdProblem');
    const [storageActionId] = useStorage64('storageActionId');
    const [storageMotivoEntregado] = useStorage64('storageMotivoEntregado');
    const [, setStorageImgProducto] = useStorage64('storageImgProducto');
    const profileData = useStorage64('profileData');
    const [idClaro, setIdClaro] = useState(false);
    const [, setEstatusPedido] = useState(0);
    const [formaPago, setFormaPago] = useState(0);
    const [orderNumber, setOrderNumber] = useState(0);
    const [almacenarImagenes, setAlmacenarImagenes] = useState(false);
    const [loading, setLoading] = useState(false);
    const [remover, setRemover] = useState(false);
    const [crearTicket, setCrearTicket] = useState(false);
    const [showModalError, SetShowModalError] = useState(false);

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido && resultadoPedido.pedido) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido])

    // obtengo el id del usuario del storage
    useEffect(() => {
        if (profileData != "undefined") {
            setIdClaro(profileData[0].idClaro);
        }
    }, [profileData])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // get pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido)
    }, [])

    useEffect(() => {
        if (resultadoPedido) {
            if (resultadoPedido.pedido) {
                // recorre por producto
                if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {
                    setEstatusPedido(resultadoPedido.pedido[0].axxi.status_axxi_id);
                    setFormaPago(resultadoPedido.pedido[0].payment_method.id);
                    setOrderNumber(resultadoPedido.pedido[0].order_number);
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
                LoginAyudaDinamica({ username: USERNAME, password: PASSWORD, publicKey: PUBLICKEY })
                setTimeout(() => {
                    getPedido()
                        .then(setResultadoPedido)
                }, 1000);
            }
        }
    }, [resultadoPedido])

    // cerrar modal, regresa 1 en el historial
    const onClose = () => {
        history.goBack(-1);
    }

    // redirecciona a cual es tu motivo
    const handleContinuar = () => {
        let arrayImagenes = [];

        // si tiene menos de 3 imagenes
        if (pictures[pictures.length - 1] !== undefined && pictures[pictures.length - 1].length < 3) {
            swal({
                type: 'info',
                text: "¡Aún te faltan fotos! Deben ser mínimo 3. ",
                timer: "4000",
                showConfirm: false
            })
        }
        else {
            // si tiene 3 o mas y menos de 16
            if (pictures[pictures.length - 1] !== undefined && pictures[pictures.length - 1].length >= 3 && pictures[pictures.length - 1].length < 16) {
                pictures[pictures.length - 1].map((ima, i) => {
                    arrayImagenes.push({ "lastModified": ima.lastModified, "name": ima.name, "size": ima.size, "type": ima.type, "webkitRelativePath": ima.webkitRelativePath })
                })

                arrayImagenes.forEach((element, i) => {
                    let file = pictures[pictures.length - 1][i]
                    handleUpload(file);
                });

                setTimeout(function () {
                    setAlmacenarImagenes(true);
                }, 2500)
            }
            else {
                let tam = pictures[pictures.length - 1].length;
                let total = Number(tam) - 15;
                swal({
                    type: 'info',
                    text: "¡Tienes más de 15 imágenes, por favor elimina " + total + " para continuar. ",
                    timer: "4000",
                    showConfirm: false
                })
            }
        }
    }

    //----- para aws s3 -----
    const config = {
        bucketName: BUCKET_NAME,
        dirName: DIR_NAME + "/" + idClaro + "/" + orderNumber, /* optional */
        region: REGION,
        accessKeyId: ACCESS_ID,
        secretAccessKey: ACCESS_KEY,
        s3Url: S3_URL
    };
    const ReactS3Client = new S3(config);
    // carga imagenes en s3
    const handleUpload = async (file) => {
        let newFileName = file.name//.replace(/\..+$/, "");

        ReactS3Client
            .uploadFile(file, newFileName)
            .then((data) => {
                if (data.status === 204) {
                    // console.log("success");
                    // setAlmacenarImagenes(true);
                } else {
                    // console.log("fail");
                    // setAlmacenarImagenes(false);
                }
            })
        // .catch(err => console.error("-------- ", err));}
    };

    useEffect(() => {
        if (almacenarImagenes === true) {
            let urlParaImagen = S3_URL + "/" + DIR_NAME + "/" + idClaro + "/" + orderNumber + "/";
            let arrayImagenes = [];

            pictures[pictures.length - 1].map((ima, i) => {
                arrayImagenes.push({ "document_url": urlParaImagen +  encodeURIComponent(ima.name), "order": i + 1 })
            })
            setStorageImgProducto(arrayImagenes);

            //------ crear ticket ------------
            let reason_id = storageReasonId;
            let order_id = orderNumber;
            let order_number = orderNumber;
            let client_id = idClaro;
            let description = storageMotivoEntregado;
            let ticket_type_id = 1; // siempre es 1
            let refound_details = [];
            let action_id = storageActionId;
            let products_to_cancel = [{ "id": idProductoEstate }];
            let additional_details = arrayImagenes;

            if (FORMA_PAGO_REEMBOLSABLE.includes(formaPago)) {
                setRemover(true);
                // console.log(" cancela el pedido, forma de pago reembolsable...")
                // console.log("reason_id:", reason_id, " order_id: ", order_id, " order_number: ", order_number, " client_id: ", client_id, " description: ",
                //     description, " products_to_cancel: ", products_to_cancel, " ticket_type_id: ", ticket_type_id, " refound_details: ", refound_details,
                //     " additional_details: ", additional_details, " action_id: ", action_id)
                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id
                })
                    .then(setCrearTicket)
            }
            else {
                history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/datos-bancarios");
            }
        }
    }, [almacenarImagenes])

    // si la respuesta es ticket creado === 201 redirecciona a cancelacion exitosa
    useEffect(() => {
        if (crearTicket) {
            if (crearTicket.metadata) {
                if (Number(crearTicket.metadata.http_status) === 201) {
                    if (idProductoEstate === 0) {
                        history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/cancelacion-exitosa");
                    }
                    else if (idProductoEstate != 0) {
                        history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/cancelacion-exitosa");
                    }
                }
            }
            else {
                SetShowModalError(true);
            }
        }
    }, [crearTicket])

    // mostrar el loading mientras se crea el ticket
    useEffect(() => {
        if (remover) {
            if (crearTicket) {
                setLoading(false);
                setRemover(false);
            }
            else {
                setLoading(true);
            }
        }
    }, [remover, crearTicket])

    // almacena en el array las fotos subidas
    const onDrop = (picture) => {
        setPictures([...pictures, picture]);
    }

    // revisa el valor del array de pictures para activar o desactivar el boton de continuar
    useEffect(() => {
        if (pictures[pictures.length - 1] !== undefined && pictures[pictures.length - 1].length > 0) {
            setCargoFotos(true);
        }
        else {
            setCargoFotos(false);
        }
    }, [pictures])

    // detectar maximo de fotos cargado y minimo
    useEffect(() => {
        if (pictures[pictures.length - 1] !== undefined) {
            if (pictures[pictures.length - 1].length >= 15) {
                document.querySelector('.chooseFileButton').classList.add('noneButton');
                document.querySelector('.aniadePhotos').classList.add('noneButton');
            }
            else {
                document.querySelector('.chooseFileButton').classList.remove('noneButton');
                document.querySelector('.aniadePhotos').classList.remove('noneButton');
            }
        }

    }, [pictures])

    // cerrar modal de error
    const handleCloseError = () => SetShowModalError(false);

    // regresar a pedido en modal error
    const regresarPedidos = () => {
        SetShowModalError(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Adjunta las siguientes fotografías:</p>
                    </div>
                    <div className="dataModal">
                        <div className="boxGeneralAdjuntar">
                            <div className="boxAdjuntarFotografiasTexto">
                                <ul className="listFotografias">
                                    <li>1 foto de la caja en la que llegó tu producto.</li>
                                    <li>1 foto de la etiqueta de la guía que venía pegada a tu paquete.</li>
                                    <li>1 foto de la caja del producto.</li>
                                    <li>1 foto del producto (únicamente en caso de que ya esté abierto).</li>
                                </ul>
                            </div>

                            <p className="copy1">Asegurate que se vean bien los datos y el producto.</p>
                            <p className="copy2">Aceptamos jpg ó png de hasta 10 MB de tamaño.</p>

                            <div className="boxFotos">

                                <ImageUploader
                                    withIcon={false}
                                    withPreview={true}
                                    buttonText="+"
                                    onChange={onDrop}
                                    imgExtension={[".jpg", ".jpeg", ".png"]}
                                    maxFileSize={10485760} //{5242880}
                                    fileTypeError={"No es una extensión de archivo compatible, debe ser JPG ó PNG"}
                                    fileSizeError={"El tamaño del archivo es demasiado grande."}
                                />
                                <h3 className="aniadePhotos">Añade tus fotos</h3>
                            </div>

                            <div className="boxBtnContinuarFotos">
                                <button className="btnContinuarFotos" onClick={handleContinuar} disabled={cargoFotos ? false : true}>Continuar</button>
                            </div>
                        </div>


                        {showModalError &&
                            <ModalPortal type="modalSB" onClose={handleCloseError} >
                                <ModalError okRegresar={regresarPedidos} />
                            </ModalPortal>
                        }
                    </div>

                </div>
            </div >
        </>
    )
}

export function AdjuntarFotografias(props) {
    const { isMobile } = useIsMobile();

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <AdjuntarFotografiasDesktop />
                    : isMobile === 'movile'
                        ? null
                        : <AdjuntarFotografiasMovil />
                : null
            }
        </>
    )
}