
import React, { useState, useEffect } from 'react'
import './styles.sass'
import { decodeStorage, encodeStorage, encodeStorageLatin, removeStorage } from 'functions/storageBase64'
import getAddress from 'services/getAddress'
import { getCartCount, getCart } from 'services/Cart'
import { useStorage64 } from 'hooks/useStorageBase64'
import { useIsMobile } from 'hooks/useIsMobile'
import { HeaderDesktop } from 'pages/Header/HeaderNavegacion/HeaderDesktop'
import { useLocation } from 'react-router-dom'
import { ErrorBoundary } from 'components/Error/ErrorBoundary'
import { HeaderMobile } from './HeaderMobile'
import { useValidateSession } from 'hooks/useValidateSession'
import { useToken } from 'hooks/useToken'
//import getUserSingleProfile from 'services/getUserSingleProfile'
import { useUserInfo } from 'hooks/usePersonalData'
import { useTWA } from 'hooks/useTWA'
import { useCart } from 'hooks/useCart'


export default function HeaderCompleto(props) {
    const {
        page,
        setUpCP,
        upHeader = false,
        changeDirectionBar,
        searchWork,
        headerEnCategoria,
        categoriaRecibida,
        categoriaPadre,
        tituloPagina,
        claseMas
    } = props;

    const location = useLocation();
    const {isTWA} = useTWA()

    const {} = useValidateSession()
    const {} = useToken({firstActive: true, name: "Header"})
    const { profileGet:profileData } = useUserInfo()

    const { isMobile } = useIsMobile();
    const [isLoged] = useStorage64('session_token', '');
    const existDirPredeterminada = decodeStorage('existDirPredeterminada');
    const byCart = decodeStorage('idCarrito');
    const codigoPostal = decodeStorage('codigoPostal');
    //const [profileData, setProfileData] = useStorage64('profileData', '');
    const [direcctionsData, setDirecctionsData] = useStorage64('sendDirections', '');
    const [tItems] = useStorage64('total_items', '');
    const darkModeUser = decodeStorage('darkMode');
    const cartFormEmarsys = decodeStorage('cartFormEm');
    const [totalItems, setTotalItems] = useState(tItems);

    const { 
        carrito
    } = useCart({firstActive: false, name: "useCart-header", timeRef: 500})

    //- Datos que se mandan a instana
    useEffect(() => {
        if (isLoged) {
            if (profileData.idClaro) {
                window.ineum('meta', 'Id_Sears', profileData.idClaro);
                window.ineum('user', profileData.idClaro, profileData.nombre, profileData.correo);
                window.ScarabQueue.push(['setCustomerId', String(profileData.idClaro)]);
                window.ScarabQueue.push(['go']);
            }
            window.ineum('meta', 'Session_Id', isLoged);
            window.ineum('meta', 'Cart', byCart);
            if (navigator) {
                window.ineum('meta', 'User_Agent', navigator.userAgent);
                if (navigator.platform) {
                    window.ineum('meta', 'User_Platform', navigator.platform);
                }
                if (navigator.vendor) {
                    window.ineum('meta', 'User_Vendor', navigator.vendor);
                }
                if (navigator.connection) {
                    window.ineum('meta', 'User_Connection', navigator.connection.effectiveType);
                }
            }
            if (codigoPostal) {
                window.ineum('meta', 'Postal_Code', codigoPostal);
            }
            if(!decodeStorage('LoginDate')){
                // console.log('login1: ',Date.now() )
                removeStorage('LogoutDate')
                encodeStorage('LoginDate', Date.now() )
                window.ineum('meta', 'LoginDate', Date.now() );
                let reasonLastLogout =  decodeStorage('reasonLastLogout')
                if(reasonLastLogout){
                    window.ineum('meta', 'reasonLastLogout', decodeStorage('reasonLastLogout'))
                }
            }else{
                // console.log('Last Login: ', new Date( decodeStorage('LoginDate') ) )
                window.ineum('meta', 'LoginDate', decodeStorage('LoginDate') );
                let reasonLastLogout =  decodeStorage('reasonLastLogout')
                if(reasonLastLogout){
                    window.ineum('meta', 'reasonLastLogout', decodeStorage('reasonLastLogout'))
                }
            }
            if(!decodeStorage('LogoutDate')){
                // console.log('logout1: ',Date.now() )
                removeStorage('LoginDate')
                encodeStorage('LogoutDate', Date.now() )
                window.ineum('meta', 'LogoutDate', Date.now() );
                let reasonLastLogout =  decodeStorage('reasonLastLogout')
                if(reasonLastLogout){
                    window.ineum('meta', 'reasonLastLogout', decodeStorage('reasonLastLogout'))
                }
            }else{
                // console.log('Last Logout: ', new Date( decodeStorage('LogoutDate') ) )
                window.ineum('meta', 'LogoutDate', decodeStorage('LogoutDate') );

                let reasonLastLogout =  decodeStorage('reasonLastLogout')
                if(reasonLastLogout){
                    window.ineum('meta', 'reasonLastLogout', decodeStorage('reasonLastLogout'))
                }
            }
        }
        else{
            if(!decodeStorage('LogoutDate')){
                // console.log('logout1: ',Date.now() )
                removeStorage('LoginDate')
                encodeStorage('LogoutDate', Date.now() )
                window.ineum('meta', 'LogoutDate', Date.now() );
                let reasonLastLogout =  decodeStorage('reasonLastLogout')
                if(reasonLastLogout){
                    window.ineum('meta', 'reasonLastLogout', decodeStorage('reasonLastLogout'))
                }
            }else{
                // console.log('Last Logout: ', new Date( decodeStorage('LogoutDate') ) )
                window.ineum('meta', 'LogoutDate', decodeStorage('LogoutDate') );

                let reasonLastLogout =  decodeStorage('reasonLastLogout')
                if(reasonLastLogout){
                    window.ineum('meta', 'reasonLastLogout', decodeStorage('reasonLastLogout'))
                }
            }
        }
        window.ineum('wrapEventHandlers', true);
        window.ineum('wrapTimers', true);
        window.ineum('ignoreErrorMessages', [/^script error/i]);
        removeStorage('modalPostalCode');
    }, []);

    useEffect(() => {
        if (location.pathname.split('/')[1] != "producto") {
            document.querySelector('body').classList.remove('open');
            document.querySelector('body').classList.remove('openMobile');
        }
    }, [location]);

    //- Funcion que evalua el dark mode
    function changeMedia(mq) {
        if (sessionStorage.getItem('SelectUser') === "true") {
            if (darkModeUser) {
                document.body.classList.add('is-dark-mode');
                encodeStorage('darkMode', true);
            } else {
                document.body.classList.remove('is-dark-mode');
                encodeStorage('darkMode', false);
            }
        } else {
            if (mq.matches) {
                document.body.classList.add('is-dark-mode');
                encodeStorage('darkMode', false);
            } else {
                document.body.classList.remove('is-dark-mode');
                encodeStorage('darkMode', false);
            }
        }
    }

    //- Efecto que evalua si se encuentra con dark o light mode
    useEffect(() => {
        const mq = window.matchMedia('(prefers-color-scheme: dark)');
        try {
            mq.addEventListener('change', changeMedia(mq));
        }
        catch (e) {
            // console.log('error:', e);
            mq.addListener(changeMedia(mq));
        }
        if (mq.matches) { document.body.classList.add('is-dark-mode'); }
        else { document.body.classList.remove('is-dark-mode'); }
    }, [window.matchMedia('(prefers-color-scheme: dark)').matches]);

    // - seleccion dark mode en switch
    useEffect(() => {
        if (window.matchMedia('(prefers-color-scheme: dark)')) {
            if (decodeStorage('darkMode')) { document.body.classList.add('is-dark-mode'); }
            else { document.body.classList.remove('is-dark-mode'); }
        }
    }, [decodeStorage('darkMode'), window.matchMedia('(prefers-color-scheme: dark)').matches]);

    //- Servicio que va por las direcciones del usuario si no hay direcciones en el storage
    const [direcctionsGet, setDirecctionsGet] = useState(direcctionsData);
    useEffect(() => {
        if (isLoged) {
            if (!direcctionsData) {
                getAddress()
                    .then(setDirecctionsGet)
            }
        }
    }, [isLoged, direcctionsData]);

    //- guarda las direcciones en el storage despues de la repsuesta del servicio
    const [updatePredFirst, setUpdatePredFirst] = useState();
    useEffect(() => {
        if (direcctionsGet) {
            if (direcctionsGet.address) {
                if (direcctionsGet.address[0]) {
                    if (!existDirPredeterminada) {
                        encodeStorage('codigoPostal', direcctionsGet.address[0].codigoPostal);
                        if (direcctionsGet.address[0].calle) {
                            encodeStorage('codigoPostal', direcctionsGet.address[0].codigoPostal);
                            encodeStorage('dirPredeterminada', { 'nombre': direcctionsGet.address[0].nombre, 'municipio': direcctionsGet.address[0].municipio, 'calle': direcctionsGet.address[0].calle });
                        } else {
                            encodeStorage('codigoPostal', direcctionsGet.address[0].codigoPostal);
                            encodeStorage('dirPredeterminada', { 'nombre': direcctionsGet.address[0].nombre, 'municipio': direcctionsGet.address[0].municipio, 'calle': direcctionsGet.address[0].direccion });
                        }
                        encodeStorage('selectId', direcctionsGet.address[0].id);
                        encodeStorage('existDirPredeterminada', 'true');
                        setUpdatePredFirst(true);
                        setUpCP('true');
                    } else {
                        setDirecctionsData(direcctionsGet);
                    }
                }
            } else {
                removeStorage('sendDirections');
            }
        }
    }, [direcctionsGet, updatePredFirst]);



    //- Servicio que va por los datos del usuario si no los encuentra en el storage
    // const [profileGet, setProfleGet] = useState(profileData);
    // useEffect(() => {
    //     if (isLoged) {
    //         if (!profileData) {
    //             getUserSingleProfile()
    //                 .then(setProfleGet)
    //         }
    //     }
    // }, [isLoged, profileData]);

    //- guarda los datos del usuario despues de la respuesta del servicio
    // useEffect(() => {
    //     if (profileGet) {
    //         setProfileData(profileGet);
    //     }
    // }, [profileGet]);

    //- Funcion que solicita al servicio la cantidad de productos del carrito
    // const [cartCountResponse, setCartCountResponse] = useState();
    // useEffect(() => {
    //     if (isLoged) {
    //         getCartCount()
    //             .then(setCartCountResponse)
    //     }
    // }, []);

    //- llamada al carrito del portal para su payasada de emarsys
    const [respServEmar, setServRespEmar] = useState(cartFormEmarsys);
    const [okCartRespEmarsys, setOkCartRespEmarsys] = useState(false);
    useEffect(() => {
        if (isLoged) {
            if (!cartFormEmarsys) {
                // getCart()
                //     .then(setServRespEmar)
                setServRespEmar(carrito)
            }
        }
    }, []);

    useEffect(() => {
        if (respServEmar) {
            setOkCartRespEmarsys(true);
        }
    }, [respServEmar])
    useEffect(() => {
        if (okCartRespEmarsys) {
            if (respServEmar) {
                if (respServEmar?.data) {
                    if (respServEmar?.data?.data) {
                        if (respServEmar?.data?.data?.products) {
                            try {
                                encodeStorage('cartFormEm', respServEmar?.data?.data?.products)
                            }
                            catch {
                                encodeStorageLatin('cartFormEm', respServEmar?.data?.data?.products)
                            }
                            setOkCartRespEmarsys(false)
                        }
                    }
                }
            }
        }
    }, [okCartRespEmarsys]);

    // const pushCartEmarsys = [];
    // const funtionCartEmarsys = (cartEmar) => {
    //     if (cartEmar) {
    //         if (typeof cartEmar === "object") {
    //             if (cartEmar.length >= 1) {
    //                 cartEmar.map(product =>
    //                     pushCartEmarsys.push({ 'item': product.id, 'price': product.sale_price, 'quantity': product.cart_quantity })
    //                 )
    //                 window.ScarabQueue.push(['cart', pushCartEmarsys]);
    //                 window.ScarabQueue.push(['go']);
    //             } else {
    //                 window.ScarabQueue.push(['cart', []]);
    //                 window.ScarabQueue.push(['go']);
    //             }
    //         } else {
    //             window.ScarabQueue.push(['cart', []]);
    //             window.ScarabQueue.push(['go']);
    //         }
    //     }
    // }

    // useEffect(() => {
    //     if (isLoged) {
    //         if (profileData.idClaro) {
    //             if (cartFormEmarsys) {
    //                 funtionCartEmarsys(cartFormEmarsys);
    //             } else {
    //                 window.ScarabQueue.push(['cart', []]);
    //                 window.ScarabQueue.push(['go']);
    //             }
    //         }
    //     } else {
    //         window.ScarabQueue.push(['cart', []]);
    //         window.ScarabQueue.push(['go']);
    //     }
    // }, []);

    //- guarda los datos en el storage despues de la respuesta del servicio y actualiza el numero en el carrito
    // useEffect(() => {
    //     if (cartCountResponse) {
    //         if (cartCountResponse.statusCode === 200) {
    //             if (typeof (cartCountResponse.data) === 'object') {
    //                 setTotalItems(cartCountResponse.data.data.cart.total_items);
    //                 encodeStorage('total_items', cartCountResponse.data.data.cart.total_items);
    //             }
    //             else {
    //                 // console.log('cartCount', cartCountResponse);
    //             }
    //         }
    //     }
    //     else {
    //         setTotalItems(0);
    //     }
    // }, [cartCountResponse]);

    //- hace el update de productos en el icono de carrito
    useEffect(() => {
        if (totalItems) {
            if (totalItems === 'undefined') {
                setTotalItems(0);
            } else {
                //console.log('add totalItems Storage is > 0')
            }
        } else {
            if (isLoged) {
                if (!totalItems) {
                    setTotalItems(totalItems);
                }
            } else {
                setTotalItems(0);
            }
        }

    }, [isLoged, totalItems]);

    
    useEffect(() => {
        if (upHeader) {
            setTotalItems(upHeader);
        }
    }, [upHeader]);


    useEffect(() => {
        if (isMobile === 'desktop') {
            window.localStorage.setItem('isApp', 'pwa|Desktop');
        } else if (isMobile === 'movil') {
            if (/Android/i.test(navigator.userAgent)) {
                if(isTWA){
                    window.localStorage.setItem('isApp', 'Android|TWA');
                }else{
                    window.localStorage.setItem('isApp', 'pwa|Android');
                }
            } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                window.localStorage.setItem('isApp', 'pwa|iOS');
            } else {
                window.localStorage.setItem('isApp', 'pwa|Movil');
            }
        }
        if (document.getElementById('flix_hotspots')) {
            document.getElementById('flix_hotspots').remove()
        }
    }, [isMobile]);

    const [namec, setName] = useState(false);
    useEffect(()=>{
        if(categoriaRecibida){
            setName(categoriaRecibida)
        }
    },[categoriaRecibida])

    const [namep, setNamep] = useState(false);
    useEffect(()=>{
        if(categoriaPadre){
            setNamep(categoriaPadre)
        }
    },[categoriaPadre])

    return (
        <>
            {isMobile === 'desktop'
                ?
                    <ErrorBoundary>
                        <HeaderDesktop
                            isLoged={isLoged}
                            totalItems={totalItems}
                            profile={profileData}
                            changeDirectionBar={changeDirectionBar}
                            searchWork={searchWork}
                        />
                    </ErrorBoundary>
                :
                    <ErrorBoundary>
                        <HeaderMobile
                            isLoged={isLoged}
                            totalItems={totalItems}
                            page={page}
                            profile={profileData}
                            headerEnCategoria={headerEnCategoria}
                            categoriaRecibida={namec}
                            categoriaPadre={namep}
                            tituloPagina={tituloPagina}
                            searchWork={searchWork}
                            claseMas={claseMas}
                        />
                    </ErrorBoundary>
            }
        </>
    )
}