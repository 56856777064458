import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { decodeStorage } from "functions/storageBase64";
import { useIsMobile } from "hooks/useIsMobile";
import Header from "pages/Header/HeaderNavegacion";
import BarraCP from "components/BarCP/BarHeaderCP";
import Footer from "pages/Footer/FooterDesktop";
import Loading from "components/Loading";
import { TiendasOficialesTemplateDesktop } from "pages/Tienda/SeccionT/TiendasDesktop";
import { TiendasOficialesTemplateMobile } from "pages/Tienda/SeccionT/TiendasMobile";
import { getShop } from "services/Shop";
import "./style.sass";

export function TiendasOficiales(props) {
  const {} = props;

  const { isMobile } = useIsMobile();
  const history = useHistory();
  const params = history.pathname;
  const codigoP = decodeStorage("codigoPostal");
  const [codigoPostal, setCodigoPsotal] = useState(codigoP);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState("");
  const [response, setResponse] = useState();
  const [responseSuccess, setResponseSuccess] = useState();
  const [statusCode, setStatusCode] = useState();

  //- Codigo de instana para track de pagina
  useEffect(() => {
    window.ineum("page", "Store List Page");
    window.scrollTo(0, 0);
  }, []);

  //- VA por el servicio de Tiendas
  useEffect(() => {
    getShop().then(setResponse);
  }, []);

  //- Guarda el servicio de la respuesta en un estado para trabajarlo
  useEffect(() => {
    if (response) {
      if (response.statusCode === 200) {
        // console.log('!Array.isArray',!Array.isArray(response.data))
        // console.log('typeof',typeof(response.data))
        if (typeof response.data === "object") {
          setResponseSuccess(response.data.data);
          setStatusCode(response.statusCode);
          setLoading(false);
        } else if (typeof response.data === "string") {
          setErrors({ error: response.statusCode, response: response.data });
          setLoading(false);
        }
      }
    }
  }, [response]);

  //recibe la direccion del CP
  const [updateCPBar, setUpdateCPBar] = useState();
  const reciveDirection = (val) => {
    setUpdateCPBar(val);
  };
  const [upCP, setUpCP] = useState();
  useEffect(() => {
    if (upCP) {
      //console.log('')
    }
  }, [upCP, updateCPBar]);

  return (
    <>
      {loading && <Loading />}
      <Helmet>
        <title>Solo Sanborns</title>
        <link rel="canonical" href={`https://www.sanborns.com.mx${params}`} />
        <meta
          name="Description"
          content="SANBORNS.COM.MX | Envíos a todo México · Más de 500 mil productos con todas las Formas de Pago  Compras 100% Seguras · Lo mejor en Moda, Línea blanca, Pantallas, Computo, Celulares, Juguetes, Muebles, Ferretería y más · Click & Recoge en Tienda"
        />
      </Helmet>

      <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection} />

      {isMobile ? (
        isMobile === "desktop" ? (
          <>
            <section className="barCpResponsive">
              <BarraCP changeCP={reciveDirection} clase="barHeaderCP" />
            </section>
            <TiendasOficialesTemplateDesktop
              responseSuccess={responseSuccess}
              statusCode={statusCode}
              loading={loading}
              errors={errors}
            />
          </>
        ) : isMobile === "movile" ? null : (
          <TiendasOficialesTemplateMobile
            responseSuccess={responseSuccess}
            statusCode={statusCode}
            loading={loading}
            errors={errors}
          />
        )
      ) : null}

      <Footer />
    </>
  );
}
