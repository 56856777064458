import React, { useEffect, useState } from 'react';
import { decodeStorage, encodeStorage } from 'functions/storageBase64';

export function ComponentViewType(props) {
    const viewTypeCont = decodeStorage('viewTypeCont');

    // verifica la funcion a llamar segun la vista seleccionada
    useEffect(() => {
        if (viewTypeCont === "list") {
            handleChangeViewList();
        } else if (viewTypeCont === "grid") {
            handleChangeViewGrid();
        }
        else {
            handleChangeViewList();
        }
    }, [])

    // vista lista
    const handleChangeViewList = () => {
        document.querySelector('.vistaListaDesktop').classList.add('activeVista');
        document.querySelector('.vistaMosaicoDesktop').classList.remove('activeVista');
        document.querySelector('.boxProductosCategory').classList.remove('cardGrid');
        encodeStorage('viewTypeCont', "list");
    }
    // vista grid
    const handleChangeViewGrid = () => {
        document.querySelector('.vistaMosaicoDesktop').classList.add('activeVista');
        document.querySelector('.boxProductosCategory').classList.add('cardGrid');
        document.querySelector('.vistaListaDesktop').classList.remove('activeVista');
        encodeStorage('viewTypeCont', "grid");
    }

    return (
        <div className="boxVistasDesktop">
            Vistas:
            <p className="vistaListaDesktop" onClick={handleChangeViewList}></p>
            <p className="vistaMosaicoDesktop" onClick={handleChangeViewGrid}></p>
        </div>
    )
}

export function ComponentViewTypeMobile(props) {
    const viewTypeCont = decodeStorage('viewTypeCont');

    // verifica la funcion a llamar segun la vista seleccionada
    useEffect(() => {
        if (viewTypeCont === "list") {
            handleChangeViewList();
        } else if (viewTypeCont === "grid") {
            handleChangeViewGrid();
        }
        else {
            handleChangeViewList();
        }
    }, [])

    // vista lista
    const handleChangeViewList = () => {
        document.querySelector('.vistaListaMobile').classList.add('activeVista');
        document.querySelector('.vistaMosaicoMobile').classList.remove('activeVista');
        document.querySelector('.contProductCategory').classList.remove('cardGrid');
        encodeStorage('viewTypeCont', "list");
    }
    // vista grid
    const handleChangeViewGrid = () => {
        document.querySelector('.vistaMosaicoMobile').classList.add('activeVista');
        document.querySelector('.contProductCategory').classList.add('cardGrid');
        document.querySelector('.vistaListaMobile').classList.remove('activeVista');
        encodeStorage('viewTypeCont', "grid");
    }

    return (
        <div className="seccionVistas">
            <div className="seccionTitulo">Vistas</div>
            <div className="seccionOpciones">
                <div className="vistaMosaicoMobile" onClick={handleChangeViewGrid}><span>Mosaico</span></div>
                <div className="vistaListaMobile" onClick={handleChangeViewList}><span>Lista</span></div>
            </div>
        </div>
    )
}

export function ComponentViewTypeMobileBarraResults(props) {
    const viewTypeCont = decodeStorage('viewTypeCont');
    const [, setFlagVista] = useState("list");
    const [mosaico, setmosaico] = useState(false);
    const [lista, setlista] = useState(false);

    // verifica la funcion a llamar segun la vista seleccionada
    useEffect(() => {
        if (String(viewTypeCont) === "list") {
            handleChangeViewList();
        } else if (String(viewTypeCont) === "grid") {
            handleChangeViewGrid();
        } else {
            handleChangeViewList();
        }
    }, [])

    // vista lista
    const handleChangeViewList = () => {
        encodeStorage('viewTypeCont', "list");
        setFlagVista("list");
        setlista(true);
        setmosaico(false);

        if (document.querySelector('.contProductCategory')) {
            document.querySelector('.contProductCategory').classList.remove('cardGrid');
        } else {
            document.querySelector('.contProductsLanding').classList.remove('cardGrid');
        }
    }
    // vista grid
    const handleChangeViewGrid = () => {
        setlista(false);
        setmosaico(true);
        encodeStorage('viewTypeCont', "grid");
        setFlagVista("grid");

        if (document.querySelector('.contProductCategory')) {
            document.querySelector('.contProductCategory').classList.add('cardGrid');
        } else {
            document.querySelector('.contProductsLanding').classList.add('cardGrid');
        }
    }

    useEffect(() => {
        if (mosaico === true && lista === false) {
            encodeStorage('viewTypeCont', "grid");
        } else {
            encodeStorage('viewTypeCont', "list");
        }
    }, [lista, mosaico])

    return (
        <>
            <div className="seccionVistasPrincipal">
                <div className="seccionOpciones">
                    <div className={lista === true ? "vistaMosaicoMobilePrincipal" : "vistaListaMobilePrincipal"}
                        onClick={mosaico === true ? handleChangeViewList : handleChangeViewGrid}><span></span>
                    </div>
                </div>
            </div>
        </>
    )
}