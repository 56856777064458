import { BASE_PATH_API, TIMEOUT_TIME } from 'constants/general';
import { decodeStorage } from 'functions/storageBase64';
import axios from 'axios';


//- Get ProductsPixel
export async function getlistProductsPixel({ section = "general"}){
    const accessToken = "Bearer " + decodeStorage("access_token");    
    //console.log(" token: ", accessToken)     
   
    //const apiURLhome = `${BASE_PATH_API}/pixel-tracking/api/v1`;
    //const apiURL = `https://snapi-beta.dev.sanborns.com.mx/pixel-tracking/api/v1/meses`;

    let apiURL
    if(section !== "Home"){
        apiURL = `${BASE_PATH_API}/pixel-tracking/api/v1/meses`;
    }else{
        apiURL = `${BASE_PATH_API}/pixel-tracking/api/v1`;
    }

    const config = {
        method: 'get',
        url:  apiURL,
        timeout: TIMEOUT_TIME,        
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        }
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
      
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.response.data,
            'error': error.response.data.msg
        }
    })
}


//- Post Product Pixel
export async function postPixelProduct ({idProduct} = {}){

    const isLoged = decodeStorage('session_token')
    const accessToken = "Bearer " + decodeStorage("access_token");        
    const apiURL = `${BASE_PATH_API}/pixel-tracking/api/v1`; // const apiURL = `https://snapi-beta.dev.sanborns.com.mx/pixel-tracking/api/v1`
    const data = JSON.stringify({"productId":idProduct});

    // const delayPixel = ms => new Promise(
    //     resolve => setTimeout(resolve, ms)
    // );

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,        
        headers: {            
            'Content-Type': 'application/json;',
            'Authorization': accessToken
        },
        data: data
    };
    
    
    if (isLoged){
        //console.log("data pixel inicia: ")  
        // await delayPixel(15000);      
        
        return await axios(config)
        .then(res => {
            const {
                headers, 
                status, 
                data
            } = res
       
            return {
                'headers': headers,
                'statusCode': status,
                'data': data,
                'error': ''
            }
        })
        .catch(error =>{    
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.response.data,
                'error': error.response.data.msg
            }
        })

    }

}



//- Delete Product Pixel
export async function deletePixelProduct ({idProduct} = {}){

    const isLoged = decodeStorage('session_token');
    const accessToken = "Bearer " + decodeStorage("access_token");    
    const apiURL = `${BASE_PATH_API}/pixel-tracking/api/v1/${idProduct}`; //const apiURL = `${BASE_PATH_API}/pixel-tracking/api/v1/${idProduct}`
    const data = JSON.stringify({"productId":idProduct});

    const config = {
        method: 'delete',
        url: apiURL,
        timeout: TIMEOUT_TIME,        
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
        data: data
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}

