const {
	base_path,
	base_path_portal,
	base_path_api,
	base_path_caja,
	base_path_resources,
	anteater_url,
	anteater_stage,
	gtm_code,
	path_yotpo,
	path_yotpo_code,
	hidden_discount,
	envio_gratis,
	products_per_pagination,
	principal,
	secundario,
	publico,
	variants,
	stage_dirname,
	fireConfig,
	api_exception,
	keyC,
	powReviews
} = window["runConfig"]

//export const PORTAL_URL = "https://www.claroshop.com"
export const PORTAL_URL = "";
export const BASE_PATH = base_path;
export const BASE_PORTAL = base_path_portal;
export const BASE_PATH_PORTAL = "";
export const BASE_FIRE_CONFIG = fireConfig;
export const BASE_PATH_API = base_path_api;
export const BASE_PATH_API_DEV = api_exception;
export const BASE_PATH_CAJA = base_path_caja;
export const BASE_PATH_RESOURCES = base_path_resources;
export const BASE_URL_PORTAL = BASE_PORTAL
export const POWER_REVIEWS = powReviews;
export const LAZY_L = true;
export const IMAGE_THUMB_HOME = true;
export const IMAGE_THUMB = false;
export const SIZE_IMAGE_THUMB_BANNER = 300;
export const SIZE_IMAGE_THUMB = 50;

export const GTM_CODE = gtm_code;

export const ANTEATER_URL = anteater_url;
export const ANTEATER_STAGE = anteater_stage;
export const PATH_YOTPO = path_yotpo;
export const PATH_YOTPO_CODE = path_yotpo_code;

export const VARIANTS_PER_PORTAL = variants;

export const NO_IMAGE = `${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/assets/img/noImage.png`;
export const WHITE_PIXEL = `/img/whitePixel.svg`;
export const WHITE_PIXEL_LOADER = `/img/loadingGif.gif`;

export const MENU_PORTAL = `${BASE_PATH_API}/producto/categoria`;

export const URL_SERVICIO = `/c/servicio-tecnico/`;

export const SEARCH_ANTEATER = `${ANTEATER_URL}/search?cliente=${ANTEATER_STAGE}`;
export const SEARCH_AUTOCOMPLETE = `${ANTEATER_URL}/autocompletar?cliente=${ANTEATER_STAGE}`;
export const STAR_YOTPO = `${PATH_YOTPO}/v1/widget/${PATH_YOTPO_CODE}/products`;

export const HIDDEN_DISCOUNT = hidden_discount;
export const ENVIO_GRATIS = envio_gratis;
export const PRODUCTS_PER_PAGINATION = products_per_pagination;
export const TIMEOUT_TIME = 100000;
export const TIME_PIXEL = 15000;

export const PRINCIPAL = principal;
export const USERNAME = PRINCIPAL;
export const SECUNDARIO = secundario;
export const PASSWORD = SECUNDARIO;
export const PUBLICO = publico;
export const PUBLICKEY = PUBLICO;
export const SSO = keyC;


// CREDENCIALES S3
export const BUCKET_NAME = "portales-cs";
export const STAGE_DIRNAME = stage_dirname;
export const DIR_NAME = "claroshop/" + STAGE_DIRNAME + "/comprobantes"; /* optional */
export const REGION = "us-east-1";
export const ACCESS_ID = "AKIA4QYKCQNRYQAVV5AH";
export const ACCESS_KEY = "2GmpY95zmXttqCDKyIK/ltM75/OBr4DEsDbCJer1";
export const S3_URL = "https://portales-cs.s3.amazonaws.com";

// API KEY Maps
export const API_KEY = "AIzaSyCS6VemMuztlTaz3TVFLbDhzXU7JzWmUAs"
// export const API_KEY = "AIzaSyB_kICad-vidaVk39wojiYMRj50B_Br9vI";

// ESTATUS DE PEDIDOS
export const PEDIDOS_PENDIENTES_PAGO = 0;
export const PEDIDOS_PENDIENTES_AUTORIZAR = [71, 76];
export const PEDIDOS_PREPARANDO_PRODUCTO = [16, 9];
export const PEDIDOS_ENPROCESO_RECOLECCION = [22, 40];
export const PEDIDOS_ENCAMINO = [74, 60];
export const PEDIDOS_PRODUCTO_ENTREGADO = [4];
export const FORMA_PAGO_REEMBOLSABLE = [5, 4379, 4384, 4375, 4385, 3912, 4433, 4434, 4435, 4436, 4437, 4438, 344, 4466, 4467, 4468, 4469, 3158, 4379, 3364, 3452, 3442];
export const FORMA_PAGO_POST_PAGO = [4465, 4450, 1389, 4];
export const PEDIDO_CANCELADO = 7;
export const LABEL_PREPARANDO_PRODUCTO = "preparando tu producto";
export const LABEL_AUTORIZADO = "pedido autorizado";
export const LABEL_PROCESO_RECOLECCION = "en proceso de recolección";
export const LABEL_EN_CAMINO = "en camino";
export const REEMBOLSO = ["pedido de prueba", "solicita reembolso", "reembolso realizado", "reembolso autorizado", "en proceso de reembolso", "reembolsado", "devolución iniciada", "en espera del retorno del producto", "no autorizado", "solicita cancelación", "solicita cancelacion", "en espera del retorno del producto"];
export const PENDIENTE = [4375, 4379, 4384, 4385, 3364, 3452, 3442]