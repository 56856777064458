import React, { useState } from 'react';
import './style.sass';
import ReactDOM from 'react-dom';

function Modal({ ...props }) {
    const {
        children,
        onClose,
        type,
        title,
        cerrar,
        envioRadioButton
    } = props;

    const [valorRadio, setValorRadio] = useState("");

    const handleValorRadio = (valor) => {
        setValorRadio(valor.target.id);
    };

    const handleLimpiar = () => {
        setValorRadio("");
        envioRadioButton(0);
        cerrar();
    };

    const handleAplicar = () => {
        if (valorRadio === 'start1') {
            envioRadioButton(1);
        }
        if (valorRadio === 'start2') {
            envioRadioButton(2);
        }
        if (valorRadio === 'start3') {
            envioRadioButton(3);
        }
        if (valorRadio === 'start4') {
            envioRadioButton(4);
        }
        if (valorRadio === 'start5') {
            envioRadioButton(5);
        }
        cerrar();
    };

    return (
        <div className={`modal ${type}`}>
            <div className="contentModal">
                <div className="contentCloseAN2 paddingLR">
                    <div className="btn closeAN2" onClick={cerrar}></div>
                    {title ? <p className="title">{title}</p> : null}
                </div>
                <div className="dataModal11">

                    <div className="internoFiltros" aria-label="opciones" >
                        <div className="contenedorEstrellas" onChange={handleValorRadio}>
                            <p>Filtrar por: </p>
                            <label name="start5" htmlFor="start5">
                                <span className="conNumero">
                                    <span className="numero">5</span>
                                    <span className="span"></span> <span className="span"></span> <span className="span"></span> <span className="span"></span> <span className="span"></span>
                                </span>

                                <input id="start5" name="opciones" value="start5" type="radio" checked={"start5" === valorRadio ? true : false}></input>
                            </label>

                            <label name="start4" htmlFor="start4">
                                <span className="conNumero">
                                    <span className="numero">4</span>
                                    <span className="span"></span> <span className="span"></span> <span className="span"></span> <span className="span"></span>
                                </span>

                                <input id="start4" name="opciones" value="start4" type="radio" checked={"start4" === valorRadio ? true : false}></input>
                            </label>

                            <label name="start3" htmlFor="start3">
                                <span className="conNumero">
                                    <span className="numero">3</span>
                                    <span className="span"></span> <span className="span"></span> <span className="span"></span>
                                </span>

                                <input id="start3" name="opciones" value="start3" type="radio" checked={"start3" === valorRadio ? true : false}></input>
                            </label>

                            <label name="start2" htmlFor="start2">
                                <span className="conNumero">
                                    <span className="numero">2</span>
                                    <span className="span"></span> <span className="span"></span>
                                </span>

                                <input id="start2" name="opciones" value="start2" type="radio" checked={"start2" === valorRadio ? true : false}></input>
                            </label>

                            <label name="start1" htmlFor="start1">
                                <span className="conNumero">
                                    <span className="numero">1</span>
                                    <span className="span"></span>
                                </span>

                                <input id="start1" name="opciones" value="start1" type="radio" checked={"start1" === valorRadio ? true : false}></input>
                            </label>
                        </div>

                        <div className="filtrosButtons">
                            <button className="b-limpiar" onClick={handleLimpiar}>LIMPIAR FILTROS</button>
                            <button className="b-aplicar" onClick={handleAplicar}>APLICAR</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}


export default function ModalFiltros({ ...props }) {
    const {
        children,
        onClose,
        type,
        title,
        cerrar,
        envioRadioButton
    } = props

    return ReactDOM.createPortal(
        <Modal onClose={onClose} type={type} title={title} cerrar={cerrar} envioRadioButton={envioRadioButton}>
            {children}
        </Modal>,
        document.getElementById('modal-root')
    )
}