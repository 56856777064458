import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuNuestraCompania } from 'components/MenuAutocenter/menuAutocenter';
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import './acercaNosotros.sass';

export function AcercaNosotrosDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Acerca de Nosotros');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuNuestraCompania />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Acerca de Nosotros</p>
                                <img src="/img/logo-sears.svg" alt="logo-sears" width="170" className="paddingTop"></img>
                            </div>
                        </section>
                        <div className="contentPreguntas">
                            <div className="question1">
                                <p>La historia de SEARS comienza en 1886 cuando un joven de 22 años llamado Richard Sears, que era empleado en la Estación de Trenes de Nort Redwood, Minnesota, recibió un embarque de relojes para un joyero local. Este se negó a recibir el pedido y Richard Sears, en lugar de devolverlo, como lo hubiera hecho cualquier persona sin visión comercial, lo compró y vendió cada uno de los relojes obteniendo una pequeña utilidad. Animado por este éxito, Sears decide dejar su trabajo de la Estación de Trenes y comenzar un negocio de ventas de relojes por correo.</p>
                                <p>El joven Sears decide trasladarse a una ciudad más grande: Chicago. En respuesta a un anuncio que puso en el periódico, contrató los servicios de un relojero llamado Alva C. Roebuck. Así nace el nombre de la firma que nos distingue como empresa</p>
                                <p className="h3red">"SEARS ROEBUCK"</p>

                                <p>En 1899, Roebuck se retira por cuestiones de salud. En ese mismo año, Sears se asocia con un empresario y fabricante de ropa, cuyo nombre es: Julius Rosenwald. Durante este período el famoso "Catálogo SEARS" comenzó a diversificarse de tal forma que era posible adquirir casi cualquier tipo de mercancía mediante este sistema. En 1908, Sears se retira, le vende su participación a Rosenwald quien asciende a la presidencia. En 1925, las actividades de los negocios se expandieron a los campos de ventas al menudeo mediante la intervención del General Robert E. Wood, quien se había asociado el año anterior.</p>

                                <p>La primera tienda de ventas al menudeo se abrió en Chicago. Los siguientes años, continuó creciendo y en la década de los cuarenta, se consolidó como una empresa internacional al iniciar sus operaciones en México, Canadá y Puerto Rico.</p>

                                <p>El 27 de febrero de 1947, Sears abre su primer tienda en la Ciudad de México (79-101), en la Av. Insurgentes, captando la atención de los clientes con su lema:</p>

                                <p className="h3 black bold italic">“La completa satisfacción o la devolución de su dinero”</p>

                                <p>El 28 de abril de 1997, Grupo CARSO compra el 85% de las acciones en México. Esta nueva etapa contribuyó al desarrollo sostenido de la Empresa, aportando así una nueva filosofía de trabajo.</p>

                                <p>En la actualidad Sears es una empresa 100% mexicana y cuenta con más de setenta y cinco tiendas en todo el país. Ofrece servicios básicos de reparación de automóviles y contratos de servicio para los aparatos electrodomésticos que vende.</p>

                                <p>El abastecimiento de mercancías lo obtiene en su gran mayoría de proveedores nacionales. La selección de los mismos, se realiza con base en factores de calidad, servicio y precio. Básicamente las industrias con mayor prestigio en ropa, calzado, muebles y aparatos para el hogar, son proveedores de Sears México.</p>

                                <p>La compra de mercancía de importación se realiza en Estados Unidos adquiriendo aparatos para el hogar y herramientas con marcas propias (Kenmore y Crafstman). También compra en Oriente, aprovechando la variedad y novedad de productos de calidad a buen precio.</p>
                            </div>

                            <div className="question1">
                                <div className="mi-vi-va">
                                    <div className="miviva1">
                                        <img src="/img/group-mision.svg" alt="ico-mision"></img>
                                    </div>
                                    <div className="miviva2">
                                        <h2>Misión</h2>
                                        <p>Ofrecer a nuestros clientes los productos y servicios de la más alta calidad, al precio justo, en el ámbito adecuado, procurando su más amplia satisfacción a través de un esmerado servicio personalizado. El cliente es la razón de ser de nuestro trabajo.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="question1">
                                <div className="mi-vi-va">
                                    <div className="miviva1">
                                        <img src="/img/group-vision2.png" alt="ico-vision"></img>
                                    </div>
                                    <div className="miviva2">
                                        <h2>Visión</h2>
                                        <p>Consolidar y mantener el liderazgo de nuestra Empresa en el mercado, integrando los objetivos de sus clientes, personal, proveedores y accionistas.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="question1">
                                <div className="mi-vi-va">
                                    <div className="miviva1">
                                        <img src="/img/group-valores3.png" alt="ico-valores" className="valores1"></img>
                                    </div>

                                    <div className="miviva2">
                                        <h2>Valores</h2>
                                        <ul className="ulDiscva">
                                            <li>Trabajo</li>
                                            <li>Crecimiento</li>
                                            <li>Responsabilidad Social</li>
                                            <li>Eficiencia</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}

export function AcercaNosotrosMobile() {
    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    const [items, setItems] = useState([1, 2, 3, 4, 5, 6]);

    return (
        <>

            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN">
                        <div className="btn closeAN" onClick={onClose} ></div>
                        <p className="title">Acerca de Nosotros</p>
                    </div>
                    <div className="dataModal">

                        <div className="boxAN">
                            <Carousel itemsToShow={1} showArrows={false}>
                                {items.map((item, id) => (
                                    id === 0
                                        ? <div key={id} className="bxSlider">
                                            <div className="contenedorImagenes">
                                                <ul>
                                                    <img src="/img/logo-sears.svg" alt={"logo-sanborns"} width={150} />
                                                </ul>
                                            </div>

                                            <p className="pSlider">La historia de SEARS comienza en 1886 cuando un joven de 22 años llamado Richard Sears, que era empleado en la Estación de Trenes de Nort Redwood, Minnesota, recibió un embarque de relojes para un joyero local. Este se negó a recibir el pedido y Richard Sears, en lugar de devolverlo, como lo hubiera hecho cualquier persona sin visión comercial, lo compró y vendió cada uno de los relojes obteniendo una pequeña utilidad. Animado por este éxito, Sears decide dejar su trabajo de la Estación de Trenes y comenzar un negocio de ventas de relojes por correo.</p>

                                            <p className="pSlider">El joven Sears decide trasladarse a una ciudad más grande: Chicago. En respuesta a un anuncio que puso en el periódico, contrató los servicios de un relojero llamado Alva C. Roebuck. Así nace el nombre de la firma que nos distingue como empresa</p>

                                            <p className="pSlider boldSlider">"SEARS ROEBUCK"</p>
                                        </div>
                                        : id === 1
                                            ? <div key={id} className="bxSlider">
                                                <div className="contenedorImagenes">
                                                    <ul>
                                                        <img src="/img/logo-sears.svg" alt={"logo-sanborns"} width={150} />
                                                    </ul>
                                                </div>

                                                <p className="pSlider">En 1899, Roebuck se retira por cuestiones de salud. En ese mismo año, Sears se asocia con un empresario y fabricante de ropa, cuyo nombre es: Julius Rosenwald. Durante este período el famoso "Catálogo SEARS" comenzó a diversificarse de tal forma que era posible adquirir casi cualquier tipo de mercancía mediante este sistema.</p>

                                                <p className="pSlider">En 1908, Sears se retira, le vende su participación a Rosenwald quien asciende a la presidencia. En 1925, las actividades de los negocios se expandieron a los campos de ventas al menudeo mediante la intervención del General Robert E. Wood, quien se había asociado el año anterior.</p>

                                                <p className="pSlider">La primera tienda de ventas al menudeo se abrió en Chicago. Los siguientes años, continuó creciendo y en la década de los cuarenta, se consolidó como una empresa internacional al iniciar sus operaciones en México, Canadá y Puerto Rico.</p>
                                            </div>
                                            : id === 2
                                                ? <div key={id} className="bxSlider">
                                                    <div className="contenedorImagenes">
                                                        <ul>
                                                            <img src="/img/logo-sears.svg" alt={"logo-sanborns"} width={150} />
                                                        </ul>
                                                    </div>

                                                    <p className="pSlider"><p>El 27 de febrero de 1947, Sears abre su primer tienda en la Ciudad de México (79-101), en la Av. Insurgentes, captando la atención de los clientes con su lema:</p></p>

                                                    <p className="pSlider boldSlider">“La completa satisfacción o la devolución de su dinero”</p>

                                                    <p className="pSlider">El 28 de abril de 1997, Grupo CARSO compra el 85% de las acciones en México. Esta nueva etapa contribuyó al desarrollo sostenido de la Empresa, aportando así una nueva filosofía de trabajo.</p>

                                                    <p className="pSlider">En la actualidad Sears es una empresa 100% mexicana y cuenta con más de setenta y cinco tiendas en todo el país. Ofrece servicios básicos de reparación de automóviles y contratos de servicio para los aparatos electrodomésticos que vende.</p>
                                                </div>
                                                : id === 3
                                                    ? <div key={id} className="bxSlider">
                                                        <div className="sliTop">
                                                            <div>
                                                                <div className="contenedorImagenes ">
                                                                    <ul>
                                                                        <img src="/img/group-mision.svg" alt={"mision"} width={60} className="mvv" />
                                                                    </ul>
                                                                </div>
                                                                <p className="pSliderTitle">Misión</p>

                                                                <p className="pSlider">Ofrecer a nuestros clientes los productos y servicios de la más alta calidad, al precio justo, en el ámbito adecuado, procurando su más amplia satisfacción a través de un esmerado servicio personalizado. El cliente es la razón de ser de nuestro trabajo.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : id === 4
                                                        ? <div key={id} className="bxSlider">
                                                            <div className="sliTop">
                                                                <div>
                                                                    <div className="contenedorImagenes">
                                                                        <ul>
                                                                            <img src="/img/group-vision2.png" alt={"vision"} width={60} className="mvv" />
                                                                        </ul>
                                                                    </div>
                                                                    <p className="pSliderTitle">Visión</p>

                                                                    <p className="pSlider">Consolidar y mantener el liderazgo de nuestra Empresa en el mercado, integrando los objetivos de sus clientes, personal, proveedores y accionistas.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : id === 5
                                                            ? <div key={id} className="bxSlider">
                                                                <div className="sliTop">
                                                                    <div>
                                                                        <div className="contenedorImagenes">
                                                                            <ul>
                                                                                <img src="/img/group-valores3.png" alt={"valores"} width={60} className="mvv" />
                                                                            </ul>
                                                                        </div>
                                                                        <p className="pSliderTitleul">Valores</p>

                                                                        <p className="pSliderli">Trabajo</p>
                                                                        <p className="pSliderli">Crecimiento</p>
                                                                        <p className="pSliderli">Responsabilidad Social</p>
                                                                        <p className="pSliderli">Eficiencia</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function AcercaNosotros() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Acerca de nosotros');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            { isMobile &&
                isMobile === 'desktop'
                ? <AcercaNosotrosDesktop />

                : isMobile === 'movile'
                    ? null
                    : <AcercaNosotrosMobile />
            }
        </>
    )
}