import React, { useEffect, useState } from 'react';
import ModuleQuantity from 'components/ProductDetail/Quantity';
import SizeColor from 'components/ProductDetail/SizeColor';
import ButtonBuyNow from 'components/ProductDetail/ShopProduct/ButtonsPayment/ButtonBuyNow';
import ButtonAddToCart
    // , { ButtonAddToBagCircle }
    from 'components/ProductDetail/ShopProduct/ButtonsPayment/ButtonAddToCart';
import ButtonSellinClaroshop from 'components/ProductDetail/ShopProduct/ButtonSellinClaroshop';
import ModuleCreditCardPromotion from 'components/ProductDetail/CreditCardPromotion';
import { ErrorBoundary } from 'components/Error/ErrorBoundary';
import ModuleCreditCardSanbornsPromotion from 'components/ProductDetail/CreditCardSanbornsPromotion';
import ModuleDeliveryDate from 'components/ProductDetail/DeliveryDate';
import ModuleServicioInstalacion from 'components/ProductDetail/ModuleServicioInstalacion';
import { useIsMobile } from 'hooks/useIsMobile'
import { ModuleClickRecoge } from 'components/ProductDetail/ShopProduct/ClicyRecoge'
import ButtonCyR from '../ButtonCyR';
import ModuleCatalogoExtendido from 'components/ProductDetail/ModuleCatalogoExtendido';
import { HeaderBarMovile } from 'pages/Header/HeaderNavegacion/HeaderBarMovile';

export default function ModuleShopBuy(props) {
    const {
        id,
        size_color,
        tallaColor,
        stock,
        cyr,
        status,
        title,
        price,
        sale_price,
        dataImage,
        brand,
        stockSizeColorSKU,
        stockSizeColorSKUFF,
        stockSizeColorVariant,
        // ifShopBuy,
        is_digital,
        category,
        is_store_only,
        payment_methods,
        longitudDeliveryDate,
        skuChildren,
        changeDirectionBar,
        updateStockTC,
        updateFF,
        fullfilment,
        skuProducto,
        skuChildrenFF,
        setTallaText,
        setColorText,
        store,
        discount,

        colorText,
        tallaText,
        setSizeColorSKUprincipal,
        preseleccionar,

        promoDepart,
        isfree,
        catPadre,
        catHija_Hombre,
        catHija_Mujer,
        catHija_Nino,
        catHija_Nina,
        tallasNino, tallasNina, tallasHombre, tallasMujer,
        SKUsServicioTecnico,
        cex,
        super_express,
        libro
    } = props;

    const { isMobile } = useIsMobile();
    const [stockProduct, setStockProduct] = useState();
    const [cantProduct, setCantProduct] = useState();
    const [sizeColorSKU, setSizeColorSKU] = useState();
    const [update, setUpdate] = useState(false);
    const [updateId, setUpdateID] = useState(false);
    const [longitudTallas, setLongitudTallas] = useState();

    const [jsonInfo, setJsonInfo] = useState();
    useEffect(() => {
            if (SKUsServicioTecnico) {
                setJsonInfo(SKUsServicioTecnico);
            }
    }, [SKUsServicioTecnico])

    useEffect(() => {
        setStockProduct(stock);
        setCantProduct(1);
        setSizeColorSKU(null);
        setUpdate(true);
        if (id) {
            setUpdateID(id);
        }
    }, [stock, id])

    // al cambiar color resetea tallaColor para mostrar fecha
    useEffect(() => {
        // console.log("color text--- ", colorText, "  talla text:", tallaText)
        if (colorText && longitudTallas) {
            setSizeColorSKU(null);
            setSizeColorSKUprincipal(null);
        }
        else{
            setSizeColorSKUprincipal(null);
        }
    }, [colorText])

    useEffect(() => {
        if (update) {
            if (id) {
                //console.log('id',id)
            }
            if (stock) {
                //console.log('stock',stock)
            }
            setUpdate(false);
        }
    }, [update])

    useEffect(() => {
        // console.log("ESTOY MODULE SHOP BUY  SIZE_COLOR: ",size_color ," ",sizeColorSKU, " longitud tallas: ", longitudTallas)
        if (size_color) {
            if (sizeColorSKU) {

                if (sizeColorSKU?.stock) {
                    setStockProduct(sizeColorSKU?.stock);
                    stockSizeColorSKU(sizeColorSKU?.stock);
                }
                if (sizeColorSKU?.fulFliment) {
                    stockSizeColorSKUFF(sizeColorSKU?.fulFliment);
                }
                if (sizeColorSKU?.sku) {
                    stockSizeColorVariant(sizeColorSKU?.sku);
                }

            }
        }
    }, [cantProduct, sizeColorSKU, stock])

    const changeSizeColor = (val) => {
        // console.log("change size color: ", val)
        setSizeColorSKU(val);

    };

    useEffect(() => {
        if (sizeColorSKU) {
            setSizeColorSKUprincipal(sizeColorSKU);
        }
    }, [sizeColorSKU])

    const changeQuantity = (val) => {
        setCantProduct(val);
    };

    const [colorSel, setColorSel] = useState();
    const [preseleccionarMobile, setPreseleccionarMobile] = useState(false);
    const [preseleccionarNivel2, setPreseleccionarNivel2] = useState(false);

    useEffect(() => {
        if (preseleccionar) {
            setPreseleccionarNivel2(preseleccionar);
        }
    }, [preseleccionar])

    useEffect(() => {
        if (preseleccionarMobile) {
            setPreseleccionarNivel2(preseleccionarMobile);
        }
    }, [preseleccionarMobile])

    const [showMenuComprar, setShowMenuComprar] = useState(true)
    const [scrollActual, setScrollActual] = useState(window.scrollY)
    //const [positionShopCardProduct, setPositionShopCardProduct] = useState(0);

    const mostrarOcultar = () => {
        //console.log("positionShopCardProduct",positionShopCardProduct)
        if( document.querySelector('.menuPrincipal.botones') ){
            scrollActual >= 1270 
                ? document.querySelector('.menuPrincipal.botones').classList.remove('close') 
                : document.querySelector('.menuPrincipal.botones').classList.add('close') ;
                setScrollActual(window.scrollY)
        }
        // console.log("scrollActual",scrollActual)
    }
    useEffect(() => {
        if(isMobile){
            //setPositionShopCardProduct(document.querySelector('.moduleShopCardProduct').getBoundingClientRect())
            if(showMenuComprar && isMobile !== 'desktop'){
                window.addEventListener("scroll", mostrarOcultar);
            }
            return () => window.removeEventListener("scroll", mostrarOcultar);
        }
    },[scrollActual,showMenuComprar,isMobile])

    return (
        <>
            {stock && status
                ?
                <div className="moduleShopBuy">
                    {isMobile !== 'desktop'
                        ?
                            <div className="headerMobile">
                                <div className="menuPrincipal botones close">
                                    <HeaderBarMovile
                                        cyr={cyr}
                                        id={id}
                                        title={title}
                                        dataImage={dataImage}
                                        precioLista={sale_price}
                                        discount={discount}
                                        skuChildren={skuChildren}
                                        skuChildrenFF={skuChildrenFF}
                                        store={store}
                                        stock={stock}
                                        categories={category}
                                        status={status}
                                        size_color={size_color}
                                        colorCyR={colorText}
                                        tallaCyR={tallaText}
                                        precio={price}
                                        is_store_only={is_store_only}
                                        cex={cex}
                                        isMobile={isMobile}
                                        
                                        cantProduct={cantProduct}
                                        sizeColorSKU={sizeColorSKU}
                                        size_colorExist={size_color?.length}
                                        // tallasExiste = {size_color}
                                        sale_price={sale_price}
                                        brand={brand}
                                        category={category}
                                        variant={colorSel}
                                        tallaText={tallaText}
                                    />
                                </div>
                            </div>
                        : null
                    }
                    {/* <ButtonAddToBagCircle
                        id={id}
                        cantProduct={cantProduct}
                        sizeColorSKU={sizeColorSKU}
                        size_colorExist={size_color.length}

                        title={title}
                        price={price}
                        sale_price={sale_price}
                        dataImage={dataImage}
                        is_digital={is_digital}
                        category={category}
                        brand={brand}
                        variant={colorSel}
                    /> */}



                    {size_color?.length !== 0
                        ?
                        <SizeColor
                            id={id}
                            size_color={size_color}
                            returnSizeColor={changeSizeColor}
                            colorSelData={setColorSel}

                            cantBase="1"
                            stock={stockProduct}
                            returnNumber={changeQuantity}

                            status={status}
                            updateStockTC={updateStockTC}
                            updateFF={updateFF}

                            category={category}

                            setTallaText={setTallaText}
                            setColorText={setColorText}
                            colorText={colorText}
                            tallaText={tallaText}
                            preseleccionar={preseleccionarNivel2}
                            stockGeneral={stock}
                            catPadre={catPadre}
                            catHija_Hombre={catHija_Hombre}
                            catHija_Mujer={catHija_Mujer}
                            catHija_Nino={catHija_Nino}
                            catHija_Nina={catHija_Nina}
                            tallasNino={tallasNino} tallasNina={tallasNina} tallasHombre={tallasHombre} tallasMujer={tallasMujer}

                            setLongitudTallas={setLongitudTallas}
                        />
                        : <ModuleQuantity
                            id={id}
                            cantBase="1"
                            stock={stockProduct}
                            status={status}
                            returnNumber={changeQuantity}
                            clase="sinTallaColor"
                        />
                    }
                    {(payment_methods && longitudDeliveryDate !== 0) &&
                            !is_store_only 
                                ?<ModuleDeliveryDate
                                    is_digital={is_digital}
                                    id_Product={id}
                                    skuChildren={skuChildren}
                                    stock={stock}
                                    status={status}
                                    changeDirectionBar={changeDirectionBar}
                                    fullfilment={fullfilment?.fulfillment}
                                    updateFF={updateFF}
                                    sizeColorLongitud={size_color?.length}
                                    skuChildrenFF={skuChildrenFF}
                                    sizeColorSKU={sizeColorSKU}
                                    tallaColor={tallaColor}
                                    setPreseleccionar={setPreseleccionarMobile}
                                    isfree={isfree}
                                    sale_price={sale_price}
                                    colorText={colorText}
                                    super_express={super_express}
                                    
                                />
                                : null
                    }

                    {isMobile !== 'desktop' &&
                        <ErrorBoundary>
                            {cyr || is_store_only
                                ? <ModuleClickRecoge
                                    cyr={cyr}
                                    id={id}
                                    title={title}
                                    dataImage={dataImage}
                                    precioLista={sale_price}
                                    discount={discount}
                                    skuChildren={skuChildren}
                                    skuChildrenFF={skuChildrenFF}
                                    store={store}
                                    stock={stock}
                                    categories={category}
                                    status={status}
                                    size_color={size_color}
                                    colorCyR={colorText}
                                    tallaCyR={tallaText}
                                    is_store_only={is_store_only}
                                    cex={cex}
                                />
                                : null
                            }
                            {cex &&
                                <ModuleCatalogoExtendido />    
                            }
                        </ErrorBoundary>
                    }

                    {/* {isMobile !== 'desktop' &&
                        (jsonInfo && (jsonInfo.includes(Number(skuProducto)) || jsonInfo.includes(Number(skuChildrenFF)))) &&
                        <ModuleServicioInstalacion skuProducto={skuProducto} />
                    } */}

{/* {console.log("....talla color.....", size_color?.sizes)} */}
                   
                    {!is_store_only &&
                        <>
                            <ButtonBuyNow
                                id={id}
                                cantProduct={cantProduct}
                                sizeColorSKU={sizeColorSKU}
                                size_colorExist={size_color?.length}
                                // tallasExiste = {size_color}
                                title={title}
                                sale_price={sale_price}
                                brand={brand}
                                category={category}
                                variant={colorSel}
                                tallaText={tallaText}
                            />

                            <ButtonAddToCart
                                id={id}
                                cantProduct={cantProduct}
                                sizeColorSKU={sizeColorSKU}
                                size_colorExist={size_color?.length}
                                tallaText={tallaText}
                                title={title}
                                price={price}
                                sale_price={sale_price}
                                dataImage={dataImage}
                                is_digital={is_digital}
                                category={category}
                                brand={brand}
                                variant={colorSel}
                                libro={libro}
                            />
                        </>
                    }

                    {isMobile !== 'desktop' &&
                        <ErrorBoundary>
                            {cyr || is_store_only
                                ? <ButtonCyR
                                    cyr={cyr}
                                    id={id}
                                    title={title}
                                    dataImage={dataImage}
                                    precioLista={sale_price}
                                    discount={discount}
                                    skuChildren={skuChildren}
                                    skuChildrenFF={skuChildrenFF}
                                    store={store}
                                    stock={stock}
                                    categories={category}
                                    status={status}
                                    size_color={size_color}
                                    colorCyR={colorText}
                                    tallaCyR={tallaText}
                                    precio={price}
                                    is_store_only={is_store_only}
                                    cex={cex}
                                />
                                : null
                            }
                        </ErrorBoundary>
                    }
                    {/* {isMobile !== 'desktop' &&
                        <ButtonSellinClaroshop id={id} title={title} />
                    } */}


                </div>
                : null
                // <div className="productoAgotado">
                //     <p>¡Producto Agotado!</p>
                //     <span></span>
                // </div>
            }
            
            {!libro && isMobile === "movil" ? (promoDepart && promoDepart?.length > 0 )
                || payment_methods?.find(item => item?.id === 4468)?.promotion?.length > 0 ?

                    <div className="contentCreditCard">
                        <ErrorBoundary>
                            {
                                // payment_methods && payment_methods.find(item => item.id === 4384)
                                //     ? payment_methods.find(item => item.id === 4384).promotion.length > 0
                                promoDepart && promoDepart?.length > 0
                                    ? <ModuleCreditCardSanbornsPromotion credit_card_months={promoDepart} tieneFormasPago={payment_methods} />
                                    : null
                                // : null
                            }
                        </ErrorBoundary>
        
                        <ErrorBoundary>
                            {payment_methods && payment_methods?.find(item => item?.id === 4468)
                                ? payment_methods?.find(item => item?.id === 4468)?.promotion?.length > 0
                                    ? <ModuleCreditCardPromotion credit_card_months={payment_methods?.find(item => item?.id === 4468)?.promotion[0]?.monthly_payments} />
                                    : null
                                : null
                            }
                        </ErrorBoundary>
                    </div>
                :null
            :null
            }
        </>
    )
}