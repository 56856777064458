import { useEffect, useState } from 'react'
import { postPromoValidator, getPromoValidator } from 'services/Promotions';

export function usePromoSears(){

    const [productData, setProductData] = useState();
    const [promoDep, setPromoDep] = useState();

    useEffect(()=>{
        let promotion = {};
        if(productData){
            if(productData?.producto){
                if(productData?.producto?.categories){

                    let cat1 = productData?.producto?.categories[0]?.id
                    let cat2 = productData?.producto?.categories[1]?.id
                    let cat3 = productData?.producto?.categories[2]?.id
                    let contCat = []

                    // -Post-
                    // if(cat1 === undefined && cat2 === undefined && cat3 === undefined){
                    //     contCat= []
                    // }else{
                    //     contCat = [
                    //         cat1,
                    //         cat2,
                    //         cat3
                    //     ]
                    // }
                    // promotion = {
                    //     elements:[
                    //         {
                    //             price: productData?.producto?.sale_price,
                    //             list_price: productData?.producto?.price,
                    //             sku: productData?.producto?.id,
                    //             store_id: productData?.producto?.store?.id,
                    //             categories: contCat
                    //         }
                    //     ]
                    // }
                    // if(productData?.producto?.store?.id === 2430 ){
                    //     postPromoValidator(promotion)
                    //     .then(setPromoDep)
                    // }

                    // -Get-
                    if(cat1 === undefined && cat2 === undefined && cat3 === undefined){
                        contCat = ''
                    }else{
                        contCat = `&categories=${cat1}${cat2 ? ','+cat2 : '' }${cat3 ? ','+cat3 : '' }`
                    }
                    promotion = `price=${productData?.producto?.sale_price}&list_price=${productData?.producto?.price}&sku=${productData?.producto?.id}&store_id=${productData?.producto?.store?.id}${contCat}`


                    if(productData?.producto?.store?.id === 3496 ){
                        //console.log('1')
                        getPromoValidator(promotion)
                        .then(setPromoDep)
                    }
                }
            }
        }
    },[productData])

    const [promoDepart, setPromoDepart] = useState();
    useEffect(()=>{
        // let idProd = params.id
        if(promoDep?.statusCode === 200){
            //console.log(promoDep)
            let result = promoDep?.data?.data

            //console.log(result)
            if(result != undefined){
                if(Object.values(result)?.length >=1 ){
                    setPromoDepart( Object.values( Object.values(result)[0] ) );
                }
                else{
                    setPromoDepart(null);
                }

            }
        }else{
            setPromoDepart(promoDep?.error);
        }
    },[promoDep])

    const setPromoSears = (val) => {
        setProductData(val)
    }

    return {
        promoDepart,
        setPromoSears
    }
}