import React, { useState, useEffect } from 'react';
import './style.sass';
import { Link } from 'react-router-dom';
import { HIDDEN_DISCOUNT, NO_IMAGE, VARIANTS_PER_PORTAL } from 'constants/general';
// import StarsFive from 'components/StarsFive';
import CurrencyFormat from "react-currency-format";
import { decodeStorage, encodeStorage } from 'functions/storageBase64';
import ModalPortal from 'components/Modal';
// import ButtonAddToCart from 'components/ProductDetail/ShopProduct/ButtonsPayment/ButtonAddToCart';
import { repareTitleSEO, repareTextHtml } from 'functions/repareDataService';
import BtnWishlist from 'components/Wishlist';


function MessageRemove(props) {
    const {
        remvoeAction,
        awaitAction
    } = props;

    const elimiar = () => remvoeAction(true);

    const recapacitar = () => awaitAction(true);

    return (
        <div className="modalRemoveAddress">
            <p>¿Estas seguro que deseas eliminar este producto?</p>
            <div className="contBtns">
                <div className="btn blanco" onClick={recapacitar}>No</div>
                <div className="btn blanco" onClick={elimiar}>Si, eliminar</div>
            </div>
        </div>
    )
}

export function CardVistos({ ...props }) {
    const byCart = decodeStorage('idCarrito');
    var arrayProducts = decodeStorage('productsVistoslist');
    const {
        id,        
        images,
        title,        
        size_color,
        price,
        sale_price,
        discount,        
        status,
        stock,     
        shipping_price,           
        upAddCart,
        upDelete,
        features,
        brand,
        index
    } = props;

    // const {
    //     total_reviews,
    //     score
    // } = reviews;

    const {
        cex,
        cyr,
        fulfillment,
        is_digital,
        super_express
    } = features;

    let newTitle = repareTitleSEO(title);

    const [responseFavorite, setResponseFavorite] = useState(false);
    const [removeWL, setRemoveWL] = useState(false);
    const [idArray, setIdArray] = useState('');
    const [addCartProduct, setAddCartProduct] = useState(false);
    const [showModal, SetShowModal] = useState(false);
    const sendProductToCart = { "id_product": id, "children_sku": 0, "quantity": 1 }
    //const sendProductToCart = {"id": byCart,"products":[{"id_cs": id, "children_sku": 0,"quantity": 1}]}

    // funciones para mostrar el modal
    const showModalRemove = () => SetShowModal(true);
    const handleClose = () => SetShowModal(false);

    // detona modal para eliminar producto
    const removeFavorite = () => showModalRemove();
    const sendWhsilist = () => upDelete(id);
    const noRemove = () => SetShowModal(false);

    // se ejecuta si el suario le da continuar
    const siRemove = () => {
        SetShowModal(false);
        setResponseFavorite(id);
    }

    // se ejecuta cuando el usario confirma el eliminar
    useEffect(() => {
        if (responseFavorite) {
            // console.log("send wss ", responseFavorite);
            sendWhsilist(responseFavorite);
        }
    }, [responseFavorite])

    //manda borrar el producto del storage
    useEffect(() => {
        if (arrayProducts.includes(String(id))) {
            setIdArray(arrayProducts.indexOf(String(id)));
        }
        if (removeWL) {
            setResponseFavorite(id);
            if (idArray !== -1) {
                arrayProducts.splice(idArray, 1);
                encodeStorage('productsVistoslist', arrayProducts);
            }
            setRemoveWL(false);
        }
    }, [removeWL, id])

    // ejecuta la funcion cuando el usuario le da comprar
    useEffect(() => {
        if (addCartProduct) {
            setAddCartProduct(false);
        }
    }, [addCartProduct, sendProductToCart])

    const sendToCart = () => {
        upAddCart(sendProductToCart);
        setAddCartProduct(true);
    }

    return (
        <>
            <article className="cardWishlist">

                {/* boton wishlist */}
                <BtnWishlist
                    id={id}
                    nombre={title}
                    precioLista={sale_price}
                    brand={brand}                       
                    index={index}
                    cyr={features}
                    digital={features}
                    super_express={features}
                />                 
                              
                <div className="contentCardWLhistorial">
                    <div className="wlL">
                        <div className="contImageCard">
                            <Link to={`/producto/${id}/${repareTitleSEO(newTitle)}/`} title={newTitle}>
                                <img src={images ? images : NO_IMAGE} alt={newTitle} />
                            </Link>                             
                        </div>     

                        <div className="dataInternal">
                            
                            {/* titulo del producto */}
                            <p className="titleProductWL">{repareTextHtml(title)}</p>
                           
                           {/* muestra el precio anterior y el porcentaje de descuento */}
                            {price !== sale_price &&
                                <div className="priceDiscountContent">
                                    {discount !== 0 && sale_price !== price
                                        ? <CurrencyFormat
                                            value={price}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            renderText={value => <p className="textUnderline">{value} <span className="mxn"> MXN</span></p>}
                                        />
                                        : null
                                    }
                                    {discount <= HIDDEN_DISCOUNT
                                        ? null
                                        : <span className="discoutnCard">-{discount}%</span>
                                    }
                                </div>
                            }

                            

                            {/* precio actual */}
                            <CurrencyFormat
                                value={sale_price}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                renderText={value => <p className="priceProductWL">{value} <span className="mxn"> MXN</span></p>}
                            />



                            <div className="moduleContFullFree">    
                                {/* copy cyr */}
                                {cyr === true &&
                                    <div className="cyr"><img src="/img/recogerTienda.png" alt="Recoger en tienda" /></div>
                                }

                                {/* muestra envio gratis */}                    
                                {shipping_price &&
                                    <div className="envioGratis"> Envío gratis </div>
                                }
                                {/* muestra envio express */}                 
                                 { fulfillment && VARIANTS_PER_PORTAL.express === true &&
                                    <div className="fullFilment"> Express </div>
                                } 

                                 {/* copy express */}
                                 {(features?.super_express === true) 
                                     ? <div className='fullFilment'></div>
                                     : null
                                 }
                                 {/* copy producto digital */}
                                {is_digital &&
                                <div className="stickerDigital"> Producto Digital </div>
                                }
                                     
                            </div>
                         



                            {/* muestra copy ultimas piezas */}  
                            {stock && status
                                ? <>
                                    {Number(stock) === 1
                                        ? <div className="lastProducts">Queda 1 pieza</div>
                                        : null
                                    }
                                    {Number(stock) >= 2 && Number(stock) <= 14
                                        ? <div className="lastProducts">Últimas {stock} piezas</div>
                                        : null
                                    }
                                    {Number(stock) >= 15 && Number(stock) <= 30
                                        ? <div className="lastProducts">Últimas piezas</div>
                                        : null
                                    }
                                </>
                                : null
                            }
                        </div>    

                            {/* muestra alert producto agotado */}
                            {stock && status
                                ? null                              
                                : <div className='bxAgotado'><div className="messageOut">¡Producto agotado!</div> </div>
                            }                                              
                    </div>

                    <div className="wlLbut">
                        {/* boton agregar a la bolsa */}
                        {stock && status
                            ?  <div className='wlRb2'>
                                    <div className="addCart">
                                        {size_color.length > 0
                                            ? null //<Link to={`/producto/${id}/${repareTitleSEO(title)}`} title={title} className="showProduct">  Ver artículo </Link>
                                            : <div className="btn gris cart" onClick={sendToCart}>  Agregar a la bolsa </div>
                                        }
                                    </div>
                                </div>
                            : null
                        }      
                        
                        {/* boton eliminar */}
                        <span className="deleteItem" onClick={removeFavorite} >Eliminar</span> 
                            
                    </div>                                      
                    
                </div>
            </article>

            {showModal &&
                <ModalPortal type="modalSB" onClose={handleClose} closeBody="closeBody" >
                    <MessageRemove
                        remvoeAction={siRemove}
                        awaitAction={noRemove}
                    />
                </ModalPortal>
            }
         
        </>
    )
}

export function CardVistosMobile({ ...props }) {
    const byCart = decodeStorage('idCarrito');
    var arrayProducts = decodeStorage('productsVistoslist');
    const {
        id,
        images,
        title,
        size_color,
        price,
        sale_price,
        discount,
        status,
        stock,
        shipping_price,
        upAddCart,
        upDelete,
        features,
        brand,
        index,
        //is_digital,                
        // dateAdd,
        // reviews,
    } = props;

    // const {
    //     total_reviews,
    //     score
    // } = reviews;

    const {
        cex,
        cyr,
        fulfillment,
        is_digital
    } = features;
    
    let newTitle = repareTitleSEO(title);

    const [responseFavorite, setResponseFavorite] = useState(false);
    const [removeWL, setRemoveWL] = useState(false);
    const [idArray, setIdArray] = useState('');
    const [addCartProduct, setAddCartProduct] = useState(false);
    const [showModal, SetShowModal] = useState(false);
    const sendProductToCart = { "id_product": id, "children_sku": 0, "quantity": 1 };
    //const sendProductToCart = {"id": byCart,"products":[{"id_cs": id, "children_sku": 0,"quantity": 1}]}

    // funciones para mostrar el modal
    const showModalRemove = () => SetShowModal(true);
    const handleClose = () => SetShowModal(false);

    // detona modal para eliminar producto
    const removeFavorite = () => showModalRemove();
    const sendWhsilist = () => upDelete(id);
    const noRemove = () => SetShowModal(false);

    // se ejecuta si el suario le da continuar
    const siRemove = () => {
        SetShowModal(false);
        setResponseFavorite(id);
    }

    // se ejecuta cuando el usario confirma el eliminar
    useEffect(() => {
        if (responseFavorite) {
            sendWhsilist(responseFavorite);
        }
    }, [responseFavorite])

    // manda borrar el producto del storage
    useEffect(() => {
        if (arrayProducts.includes(String(id))) {
            setIdArray(arrayProducts.indexOf(String(id)));
        }
        if (removeWL) {
            setResponseFavorite(id);
            if (idArray !== -1) {
                arrayProducts.splice(idArray, 1);
                encodeStorage('productsWishlist', arrayProducts);
            }
            setRemoveWL(false);
        }
    }, [removeWL, id])

    // ejecuta la funcion cuando el usuario le da comprar
    useEffect(() => {
        if (addCartProduct) {
            setAddCartProduct(false);
        }
    }, [addCartProduct, sendProductToCart])

    const sendToCart = () => {
        upAddCart(sendProductToCart);
        setAddCartProduct(true);
    }

    return (
    <>
        <article className="cardWishlistMobileHistorial">

            {/* boton agregar a wishlist */}
            <BtnWishlist
                id={id}
                nombre={title}
                precioLista={sale_price}
                brand={brand}                       
                index={index}
            />    

            <div className="contentCardWLMobileHistorial">
                <div className="wlLMobileHistorial">
                    <div className='divImgData'>
                        {/* seccion imagen del producto */}
                        <div className="contImageCardMobileHistorial">
                            <Link to={`/producto/${id}/${repareTitleSEO(newTitle)}/`} title={newTitle}>
                                <img src={images ? images : NO_IMAGE} alt={newTitle} />
                            </Link>
                        </div>

                        {/* seccion informacion del producto */}
                        <div className="dataInternal">

                            {/* titulo del producto */}
                            <p className="titleProductWL">{title}</p>

                            {/* seccion reviews */}
                            {/* {total_reviews
                                ? <StarsFive score={score} comments={String(total_reviews)} />
                                : null
                            } */}

                            {/* precio anterior  y descuento*/}
                                {price !== sale_price &&
                                    <div className="priceDiscountContent">
                                        {discount !== 0 && sale_price !== price
                                            ? <CurrencyFormat
                                                value={price}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'$'}
                                                renderText={value => <p className="textUnderline">{value} MXN</p>}
                                            />
                                            : null
                                        }
                                        {discount <= HIDDEN_DISCOUNT
                                            ? null
                                            : <span className="discoutnCard">-{discount}%</span>
                                        }
                                    </div>
                                }

                            {/* precio actual */}
                            <CurrencyFormat
                                value={sale_price}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                renderText={value => <p className="priceProductWL">{value} <span className="mxn"> MXN</span></p>}
                            />

                            <div className="moduleContFullFreeMobile">
                                {/* copy cyr */}
                                {cyr === true &&
                                    <div className="cyr"><img src="/img/recogerTienda.png" alt="Recoger en tienda" /></div>
                                }
                                {/* copy envio gratis */}
                                {shipping_price &&
                                    <div className="envioGratis"> Envío gratis </div>
                                }
                                {/* copy express */}
                                 {(features?.super_express === true) 
                                     ? <div className='fullFilment'></div>
                                     : null
                                 }
                            </div>

                            {/* copy producto digital */}
                            {is_digital &&
                                <div className="stickerDigital"> Producto Digital </div>
                            }

                            {/* copy ultimas piezas */}
                            {stock && status
                                ? <>
                                    {Number(stock) === 1
                                        ? <div className="lastProducts">Queda 1 pieza</div>
                                        : null
                                    }
                                    {Number(stock) >= 2 && Number(stock) <= 14
                                        ? <div className="lastProducts">Últimas {stock} piezas</div>
                                        : null
                                    }
                                    {Number(stock) >= 15 && Number(stock) <= 30
                                        ? <div className="lastProducts">Últimas piezas</div>
                                        : null
                                    }
                                </>
                                : null
                            }

                            {stock && status
                                ? null
                                // ? <>
                                //     {size_color.length > 0
                                //         ?
                                //         // <Link
                                //         //     to={`/producto/${id}/${title.replace(/ /ig, '-')}`}
                                //         //     title={title}
                                //         //     className="link byProduct">
                                //         //     Ver artículo
                                //         // </Link>
                                //         null
                                //         : <div className="link addCart" onClick={sendToCart}>
                                //             Agregar a carrito
                                //         </div>
                                //     }
                                // </>
                                : <div className="messageOut">¡Producto agotado!</div>
                            }

                        </div>
                    </div>

                    <div className='divBtnDelete'>
                        {/* eliminar producto */}
                        <div className="removeItem" onClick={removeFavorite}>Eliminar</div>

                        {/* btn agregar producto a la bolsa */}
                        {stock && status
                            ? <div className="addCart">
                                {size_color.length > 0
                                    ? null //<Link to={`/producto/${id}/${repareTitleSEO(title)}`} title={title} className="showProductMobile">  Ver artículo </Link>
                                    : <div className="addCartBtnMobile" onClick={sendToCart}> Agregar a la bolsa </div>
                                }
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        </article>

        {showModal &&
            <ModalPortal type="modalSB" onClose={handleClose} closeBody="closeBody" >
                <MessageRemove
                    remvoeAction={siRemove}
                    awaitAction={noRemove}
                />
            </ModalPortal>
        }
    </>
    )
}