import {BASE_PATH_API} from 'constants/general'
import axios from 'axios'

export default async function getShipping({codigoPostal}){
    const apiURL = `${BASE_PATH_API}/shipping/v1/zoning/${codigoPostal}`

    const config = {
        method: 'get',
        url: apiURL,
        withCredentials: false,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8'
        }
    };

    return await axios(config)
    .then(res => {
        const {
            // config,
            // headers, 
            status, 
            data
        } = res
        //console.log(res)
        // console.log(config)
        //console.log(data)
        // console.log(headers)
        //console.log(status)
        if(status === 200){
            const {
                status,
                error,
                code,
                ciudad,
                estado,
                colonias,
                municipio,
                c_estado
            } = data
            return {
                status,
                error,
                code,
                ciudad,
                estado,
                colonias,
                municipio,
                c_estado
            }
        }
        return []
    })
    .catch(error =>{
        // console.log(error)
        // console.log(error.request)
        // console.log(error.request.status === 0)
        // console.log(error.response)
        // console.log(error.response.data)
        if(error.message === 'Network Error'){
            console.log('Error de conexión')
        }
        else if(error.request){
            console.log(error.request.responseText)
        }
        if(error.request.status === 0) {
            // console.log('request 0')
            console.log(error.request.status)
            return {'error': '0' , 'response': error.message}
        }else{
            if(error.response.status === 401) {
                console.log(error.response.data.msg)
                return {'error': error.response.status, 'response': error.message}
            }
            else if(error.response.status === 404) {
                console.error(error.response);
                return {'status': error.response.data.status ,'code': error.response.status, 'error': error.response.data.error}
            }
            else if(error.response.status === 440) {
                return {'error': error.response.status, 'response': error.message}
            }
            else if(error.response.status >= 500) {
                console.error(error.response);
                return {'error': error.response.status, 'response': error.message}
            }
            else{
                console.error(error.response.status);
                console.error(error.response);
            }
        }
    })




    // return fetch(apiURL, property)
    // .then(res => res.json())
    // .then(response =>{
    //     return response
    // })
    // .catch(error=>{
    //     console.log('error', error)
    // })
}
