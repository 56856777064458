import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import "./style.sass";
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory } from 'react-router-dom';
import 'react-light-accordion/demo/css/index.css';
import Loading from 'components/Loading';
import { useServices } from 'hooks/useServices';

export function RestauranteMenuDesktop({ data, loading }) {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    const toggleClass = (e) => {
        let images = document.querySelectorAll('.imgMenu');
        images.forEach(img => img.classList.remove("active"));
        document.getElementById(e.target.id + "img").classList.add("active");
    };

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Restaurante Menu desktop');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])


    return (
        <>
            {loading && <Loading />}

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="container">

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>{String(data.title)}</p>
                            </div>
                        </section>

                        {String(data.name) === 'Historia_vajilla'
                            ? <section className="mod__Interna">
                                <div className="container">
                                    <div className="info__Completa">
                                        <div className="contVideoYT">
                                            <iframe width="565" height="318" src={data.video} frameborder="0"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            : data.is_menu
                                ? <>
                                    <div className="contImages max">
                                        {data.images &&
                                            data.images.map(({ id, img }) =>
                                                id === 0
                                                    ? <img id={`menuRest${id}img`} key={id} src={img} alt={"logo" + id} className='imgMenu active' />
                                                    : <img id={`menuRest${id}img`} key={id} src={img} alt={"logo" + id} className='imgMenu' />
                                            )
                                        }
                                    </div>

                                    <div className="menuRestaurante">
                                        {data.images &&
                                            data.images.map(({ id, index_menu }) =>
                                                <div onClick={toggleClass} id={`menuRest${id}`} key={id} className="btn-rojo">{index_menu}</div>
                                            )
                                        }
                                    </div>
                                </>
                                : <div className="contImages max">
                                    {data && data.images && data.images.map(({ id, img }) =>
                                        <img id={`menuRest${id}img`} key={id} src={img} alt={"logo" + id} className='active' />
                                    )}
                                </div>
                        }

                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}



export function RestauranteMenuMobile({ data }) {
    const history = useHistory();

    const toggleClass = (e) => {
        let images = document.querySelectorAll('.imgMenu');
        images.forEach(img => img.classList.remove("active"));
        document.getElementById(e.target.id + "img").classList.add("active");
    };

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Restaurante Menu mobile');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>

            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN">
                        <div className="btn closeBack" onClick={onClose} ></div>
                        <p className="title">{String(data.title)}</p>
                    </div>

                    <div className="dataModal">

                        <div className="boxAN">

                            <div className="bxSlider">

                                {String(data.name) === 'Historia_vajilla'

                                    ? <section className="mod__Interna">
                                        <div className="container">
                                            <div className="info__Completa">
                                                <div className="contVideoYT">
                                                    <iframe width="565" height="318" src={data.video} frameborder="0"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    : data.is_menu
                                        ? <>
                                            <div className="contImages max">

                                                {data.images &&
                                                    data.images.map(({ id, img }) =>
                                                        Number(id) === 0
                                                            ? <img id={`menuRest${id}img`} key={id} src={img} alt={"logo" + id} className='imgMenu active' />
                                                            : <img id={`menuRest${id}img`} key={id} src={img} alt={"logo" + id} className='imgMenu' />
                                                    )
                                                }
                                            </div>

                                            <div className="menuRestaurante">
                                                {data.images &&
                                                    data.images.map(({ id, index_menu }) =>
                                                        <div onClick={toggleClass} id={`menuRest${id}`} key={id} className="btn-rojo">{index_menu}</div>
                                                    )
                                                }
                                            </div>
                                        </>
                                        : <div className="contImages max">
                                            {data.images &&
                                                data.images.map(({ id, img }) =>
                                                    <img id={`menuRest${id}img`} key={id} src={img} alt={"logo" + id} className='active' />
                                                )
                                            }
                                        </div>
                                }

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export function RestauranteMenu(props) {
    const [menu, setMenu] = useState({});
    const { isMobile } = useIsMobile();

    const {
        headers,
        statusCode,
        data,
        error,
        loading
    } = useServices({
        method: 'get',
        url: `/staticJson/restautanteMenu.json`,
        timeout: 25000,
        withCredentials: false,
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        }
    })

    useEffect(() => {
        const { name } = props.match.params;

        data &&
            setMenu(data.find(item => item.name === name))
    }, [data])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {isMobile &&
                isMobile === 'desktop'
                ? <RestauranteMenuDesktop data={menu} loading={loading} />

                : isMobile === 'movile'
                    ? null
                    : <RestauranteMenuMobile data={menu} loading={loading} />
            }
        </>
    )
}