import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Loading from 'components/Loading'
import Header from 'pages/Header/HeaderNavegacion'
import Footer from 'pages/Footer/FooterDesktop'
import FormAddress from 'components/Address/FormAddress'
import ErrorType from 'pages/Error/ErrorType'
import { getAddress, putAddress, putDefaultAddress } from 'services/Address'

import { decodeStorage } from 'functions/storageBase64';
import { useValidateSession } from 'hooks/useValidateSession'
import { useToken } from 'hooks/useToken';

export default function CajaDireccionEdit(props) {
    const params = props.match.params
    const history = useHistory()

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'My Account | Edit Address Page');
        window.scrollTo(0, 0)
    }, [])


    const [loading, setLoading] = useState(true)
    const [rawAddress, setRawAddress] = useState({})
    const [address, setAddress] = useState({})
    const [returnDadaForm, setReturnDadaForm] = useState()
    const [succesUpdateOK, setSuccessUpdateOk] = useState(false)
    const [errorText, setErrorText] = useState('')
    // para actualizar direccion predeterminada
    const [defaultAddress, sendDefaultAddress] = useState('');
    const [updatePredet, sendUpdatePredet] = useState(false);
    const [longitudDirecciones, setLongitudDirecciones] = useState(false)
    const [longitud, setLongitud] = useState(false);

    /*  --------------------------- Inicio --------------------------------------  */
        //  Hooks para validar tiempo y refresh token mas state de boton
        const {checkValidateSession } = useValidateSession()
        const refreshCyR = useToken({firstActive: false, name: "EditerDreccion", timeRef: 1000})
        const [actionOK, setActionOk] = useState(false)
        const [actionOK_2, setActionOk_2] = useState(false)
    /*  --------------------------- Fin --------------------------------------  */

     // Va al servicio para traer la direccion seleccionada
    useEffect(() => {
        if (params?.id) {
            checkValidateSession(true)
            setActionOk('EditAddress')
        }else{
            checkValidateSession(true)
            setActionOk('ready')
        }
    }, [])
    // Va al servicio para traer actualizar los datos de la direccion seleccionada
    useEffect(() => {
        if (returnDadaForm) {
            checkValidateSession(true)
            setActionOk('updateAddress')
        }
    }, [returnDadaForm])
    // Selecciona la direccion por default
    useEffect(() => {
        if (defaultAddress) {
            checkValidateSession(true)
            setActionOk_2('defaultAddress')
        }
    }, [defaultAddress])


    /*  --------------------------- Inicio --------------------------------------  */
        //  Ajusta Los tiempos y refresca token
        const [serviceRT, setServiceRT] = useState(false)
        const [uD, setUD] = useState(false)
        useEffect(() => {
            if(actionOK){
                let  actTimeLS = Date.now() 
                let  atExpLS = decodeStorage('access_token_exp') * 1000
                setUD(false)
                
                // console.log('------------------------------')
                // console.log('actTimeLS : ', new Date() )
                // console.log('atExp   : ', new Date (atExpLS) )
                // console.log('time ok : ', actTimeLS <= atExpLS)
                // console.log('actionOK: ', actionOK)
                
                if(actTimeLS <= atExpLS || Date.now() <= decodeStorage('access_token_exp') * 1000 ){
                    setServiceRT(true)
                }
                else{
                    setTimeout(function(){
                        refreshCyR.forceToken(true)
                    },300)
                    setTimeout(function(){
                        setUD(true)
                    },900)
                }
            }
        }, [uD, actionOK])
    /*-------------------------------------------------*/
        // hace la peticion a servicio
        useEffect(() => {

            if(actionOK === 'ready'){
                setServiceRT(false)
                setActionOk(false)
                getAddress()
                .then(setLongitudDirecciones)
            }
            else if(actionOK === 'EditAddress'){
                setServiceRT(false)
                setActionOk(false)

                getAddress({ id: params?.id })
                .then(setRawAddress)
                getAddress()
                .then(setLongitudDirecciones)
            }
            else if(actionOK === 'updateAddress'){
                setServiceRT(false)
                setActionOk(false)
                putAddress(returnDadaForm)
                .then(setSuccessUpdateOk)
            }
            if(actionOK_2 === 'defaultAddress'){
                setServiceRT(false)
                setActionOk_2(false)
                setTimeout(function(){
                    putDefaultAddress({ id: defaultAddress })
                    .then(sendUpdatePredet)
                }, 700)
            }
        }, [serviceRT])
    /*  --------------------------- Fin --------------------------------------  */


    useEffect(()=>{
        if (longitudDirecciones) {
            if (longitudDirecciones?.data) {
                // if (longitudDirecciones.statusCode === 200) {
                    // if (typeof (longitudDirecciones.data) === 'object') {
                        //console.log("primero---- ",longitudDirecciones?.data?.data?.address)
                        setLongitud(longitudDirecciones?.data?.data?.address);
                    // }
                // }
            }
        }
    },[longitudDirecciones])


    // Formatea la respuesta para ser generica
    useEffect(() => {
        // console.log(rawAddress)
        if (rawAddress) {
            if (rawAddress?.data) {
                if (rawAddress?.statusCode === 200) {
                    if (typeof (rawAddress?.data) === 'object') {
                        // console.log(rawAddress.data.data)
                        setAddress(rawAddress?.data?.data)
                    }
                    else {
                        setAddress({})
                        setErrorText(rawAddress?.data)
                        setLoading(false)
                    }
                }
                else if (rawAddress?.statusCode !== undefined) {
                    let response = JSON.parse(rawAddress?.data)
                    setAddress({})
                    setErrorText(response?.data?.msg)
                    setLoading(false)
                }
            }
        }
    }, [rawAddress])

    // Remueve el loading cuando encuentra regresa los datos de la direccion
    useEffect(() => {
        if (address) {
            if (Object.keys(address).length) {
                setLoading(false)
            }
        }
    }, [address])

    //- si todo sale ok Regrea a las direcciones
    useEffect(() => {
        if (succesUpdateOK) {
            history.replace('/mi-cuenta/direcciones-envio/')
        }
    }, [succesUpdateOK])

    // recibe los datos del formulario
    function successForm(val) {
        setReturnDadaForm({ ...val, id: params.id })
        setLoading(true)
    }
    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState()
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val)
    }
    const [upCP, setUpCP] = useState()
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    // recibe el id de la direccion predeterminada
    function changeDefaultA(val) {
        sendDefaultAddress(val);
    }


    return (
        <>
            {loading
                ? <Loading />
                : null
            }
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} headerEnCategoria={"headerListaDeseos"} tituloPagina="Mis direcciones de envío" />
            <main>
                <div className="container formDirections">
                    {address &&
                        Object.keys(address).length && longitud
                        ?
                        <FormAddress
                            nombre={address.address.nombre}
                            telefono={address.address.telefono}
                            calle={address.address.calle}
                            numeroExterior={address.address.numeroExterior}
                            numeroInterior={address.address.numeroInterior}
                            codigoPostal={address.address.codigoPostal}
                            estado={address.address.estado}
                            municipio={address.address.municipio}
                            colonia={address.address.colonia}
                            entreCalles={address.address.entreCalles}
                            referencias={address.address.referencias}
                            accesibilidad={address.address.accesibilidad}
                            success={successForm}
                            changeDefaultA={changeDefaultA}
                            longitudDirecciones={longitud}
                        />
                        :
                        null
                    }
                    <ErrorType
                        codeStatus={rawAddress.statusCode}
                        errorText={errorText}
                    />
                </div>
            </main>
            <Footer />
        </>
    )
} 