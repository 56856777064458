import React from "react";

import FooterNeswletter from "components/Footer/FooterNewsletter";
import DisclaimerNotice from "components/Footer/DisclaimerNotice";
import UpperFooter from "components/Footer/Upperfooter";
import LowerFooter from "components/Footer/Lowerfooter";
import { useIsMobile } from "hooks/useIsMobile";

export default function FooterPortal(props) {
  const { visible = null } = props;

  const { isMobile } = useIsMobile();

  return (
    <>
      {isMobile ? (
        isMobile === "desktop" ? (
          <footer className="nhfooter">
            <FooterNeswletter />
            <UpperFooter />
            {/* <DisclaimerNotice /> */}
            {/* <LowerFooter /> */}
          </footer>
        ) : isMobile === "movile" ? null : (
          visible && (
            <footer className="nhfooter">
              <FooterNeswletter />
              <UpperFooter />
              {/* <DisclaimerNotice /> */}
              {/* <LowerFooter /> */}
            </footer>
          )
        )
      ) : null}
    </>
  );
}
