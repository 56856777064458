import { BASE_PATH_API, TIMEOUT_TIME, STAGE_DIRNAME, BASE_PATH_API_DEV } from 'constants/general'
import axios from 'axios'
import { decodeStorage } from 'functions/storageBase64'

export async function getClicyRecoge (props){
    const longitud = decodeStorage('longitud')
    const latitud = decodeStorage('latitud')
    
    const {
        sku,
        seller_id
    } = props
    
    let queryParam
    if(longitud && latitud){
        queryParam = `sku=${sku}&seller_id=${seller_id}&latitude=${latitud}&longitude=${longitud}`
    }else{
        queryParam = `sku=${sku}&seller_id=${seller_id}`
    }

    let apiURL = null
    if(STAGE_DIRNAME === "dev"){
        apiURL = `${BASE_PATH_API_DEV.subsidiaries}/sellers/v1/subsidiaries?${queryParam}`
    }else{
        apiURL = `${BASE_PATH_API}/sellers/v1/subsidiaries?${queryParam}`
    }

    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        mode: 'no-cors',
		crossdomain: true,
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        }
    };

    return await axios(config)
    .then(res => {
        const {
            // config,
            headers, 
            status, 
            data
        } = res
        let newData
        if(data){
            if(data.data){
                newData = {
                    token : data.data.session_token,
                    idCarrito : data.data.cart_hash

                }
            }
        }
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.response.data,
            'error': error.response.data.msg
        }
    })
}