import React, { useState, useEffect, Fragment } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './tabs.sass';
import CurrencyFormat from 'react-currency-format';

export function ModuleTabsMobile({ ...props }) {
    const {
        payment_methods,
        promoDepart,
    } = props;

    const [promoDepartamental, setPromoDepartamentla] = useState(false);

    useEffect(() => {
        if (promoDepart && promoDepart?.length > 0) {
            setPromoDepartamentla(promoDepart)
        }
    }, [promoDepart])

    const [mostrarPromociones, setMostrarPromociones] = useState(true);
    const HandleMostrarPromociones = () => {
        setMostrarPromociones(!mostrarPromociones);
    }

    return (
        <div className="tabMobile">
            {promoDepartamental || (payment_methods.find(item => item.id === 4468) && payment_methods.find(item => item.id === 4468).promotion.length > 0)
                ? <div className="secPromociones">
                    <p>PROMOCIONES</p>
                </div>
                : null
            }

            {mostrarPromociones === true &&
                <div>
                    {(promoDepartamental)
                        ? <div className=''>
                            <div className='tSanborns'>Tarjetas Sanborns</div>

                            {(promoDepartamental && promoDepartamental?.length > 0)
                                && <div className="seccionTarjetaSears">
                                    <ul className="ulTarjetasSearsMobile">
                                        {promoDepartamental && promoDepartamental?.length > 0 &&
                                            promoDepartamental.map(((promo, i) =>
                                                <Fragment key={i}>
                                                    {promo.promotion &&
                                                        <li key={i} style={{ paddingBottom: "10px" }}>
                                                            {promo.promotion.name}
                                                            {promo?.promotion && promo?.promotion?.id === 0
                                                                ? <ul className="intoPromo">
                                                                    {promo?.promotion?.best_plan?.map((plan, index) =>
                                                                        <li key={index} style={{ paddingBottom: "10px" }}>
                                                                            {plan?.months} mensualidades fijas de 
                                                                            {/* //plan?.monthly_payments */}
                                                                            <CurrencyFormat
                                                                                decimalScale={2}
                                                                                FixedDecimalScale={true}
                                                                                value={plan?.monthly_payments % 1 === 0 ? plan?.monthly_payments + '.001' : plan?.monthly_payments}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                prefix={'$'}
                                                                                renderText={value => <span>{value}</span>}>
                                                                            </CurrencyFormat>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                                : null
                                                            }
                                                        </li>
                                                    }
                                                </Fragment>
                                            ))
                                        }
                                    </ul>
                                </div>
                            }
                        </div>
                        : null
                    }

                    {(payment_methods.find(item => item.id === 4468) && payment_methods.find(item => item.id === 4468).promotion?.length > 0)
                        ? <div className=''>
                            <div className='tBancarias'>Tarjetas Bancarias</div>

                            {(payment_methods.find(item => item.id === 4468) && payment_methods.find(item => item.id === 4468).promotion?.length > 0)
                                ? <div className="seccionTarjetaBancaria">
                                    <ul className="ulpagosMobile">
                                        {payment_methods.find(item => item.id === 4468).promotion[0].monthly_payments &&
                                            payment_methods.find(item => item.id === 4468).promotion[0].monthly_payments.map((datos, ind) =>
                                                <li key={ind}>
                                                    {datos.months} {datos.months === 1 ? <>Mes de </> : <>Meses de </>}
                                                    <CurrencyFormat
                                                        decimalScale={2}
                                                        FixedDecimalScale={true}
                                                        value={datos.price % 1 === 0 ? datos.price + '.001' : datos.price}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'}
                                                        renderText={value => <span>{value}*</span>}>
                                                    </CurrencyFormat>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                                : null
                            }
                        </div>
                        : null
                    }
                </div>
            }

            {mostrarPromociones === true
                ?
                <div className='btnVerDetalle'>
                    <button onClick={HandleMostrarPromociones} className='menos'>Ver menos</button>
                </div>
                : <div className='btnVerDetalle'>
                    <button onClick={HandleMostrarPromociones} className='mas'>Ver más</button>
                </div>
            }
        </div>
    )
}

export default function ModuleTabsDesktop({ ...props }) {
    const {
        id,
        description,
        attributes,
        payment_methods,
        promoDepart,
        formasPagoArray,
        isbn
        // , warrantyDetails 
    } = props;

    const [tamanoLista, setTamanoLista] = useState(false);
    const [promoDepartamental, setPromoDepartamentla] = useState(false);

    let atributesArray = attributes != null && Object.entries(attributes)?.map(([key, value]) => {
        return (
            <li key={key}>
                <span className="atributesKeyM">{key.replace(/_/g, " ")}: </span>
                <span className="atributesValueM">{value}</span>
            </li>
        )
    })


    useEffect(() => {
        var elementos = document.getElementsByClassName("react-tabs__tab-list");
        var ele2 = elementos[0]?.childElementCount;

        if (ele2)
            setTamanoLista(Number(ele2));

    }, [])

    useEffect(() => {
        if (promoDepart && promoDepart?.length > 0) {
            setPromoDepartamentla(promoDepart)
        } else {
            setPromoDepartamentla(false)
        }
    }, [promoDepart])

    let CRev = []
    let MPCSears = []
    let MSISears = []
    const [CRevUS, setCRevUS] = useState()
    const [MPCSearsUS, setMPCSearsUS] = useState()
    const [MSISearsUS, setMSISearsUS] = useState()
    useEffect(() => {
        if (promoDepartamental) {
            promoDepartamental.forEach(cardMonts => {
                if (cardMonts.promotion.payment_method === 4379) {
                    CRev.push(cardMonts)
                } else if (cardMonts.promotion.payment_method === 4368) {
                    MPCSears.push(cardMonts)
                } else if (cardMonts.promotion.payment_method === 4369) {
                    MSISears.push(cardMonts)
                }
            })
            setCRevUS(CRev)
            setMPCSearsUS(MPCSears)
            setMSISearsUS(MSISears)
        }
    }, [promoDepartamental])


    const [formasPago, setFormasPago] = useState();
    useEffect(() => {
        if (formasPagoArray) {
            setFormasPago(formasPagoArray);
        }
    }, [formasPagoArray])

    return (
        <Tabs forceRenderTabPanel={true}>
            <TabList id={tamanoLista > 2 ? "alineacion" + tamanoLista : "alineacion2"}>
                {description?.length >= 1
                    ? <Tab>{isbn ? 'Sinopsis' : 'Descripción'}</Tab>
                    : null
                }
                {!isbn 
                    ? attributes && Object.keys(attributes)?.length >= 1 
                        ? <Tab>Especificaciones</Tab> 
                        : null
                    : null
                }
                {((payment_methods?.find(item => item.id === 4468) && payment_methods?.find(item => item.id === 4468)?.promotion?.length > 0) ||
                    (promoDepartamental))
                    ? <Tab>Promociones</Tab>
                    : null
                }
                {/* <Tab>Existencias en tienda</Tab> */}

            </TabList>

            {description?.length >= 1
                ?
                    <TabPanel>
                        <div className="moduleDescriptionSears">
                            <div dangerouslySetInnerHTML={{ __html: description }} className="textDescriptionSears"></div>
                            <div id="flix-inpage"></div>
                        </div>
                    </TabPanel> 
                : null
            }
            {!isbn ? attributes && Object.keys(attributes)?.length >= 1
                ?
                    <TabPanel>
                        <div className="moduleEspecificacionSears">
                                {/* <div className="modulePartEspecificacionSears">
                                    <p className="h3">Especificaciones</p>
                                </div> */}
                                <div className="textEspecificacionSears">
                                    <ul className="listAttributesSears">
                                        {attributes &&
                                            Object.entries(attributes).map(([key, value], ind) =>
                                                <li key={ind}>
                                                    <span className="atributesKeySears">{key.replace(/_/g, " ")}</span>
                                                    <span className="atributesValueSears">{value}</span>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                    </TabPanel>
                : null
            :null
            }


            {((payment_methods?.find(item => item.id === 4468) && payment_methods?.find(item => item.id === 4468)?.promotion?.length > 0) ||
                (promoDepartamental))
                ? <TabPanel>
                    <div className="pagosprincipal">
                        {(promoDepartamental && promoDepartamental?.length > 0)
                            ? <div className="unoPagos">
                                <p className="h2">Tarjetas Sanborns</p>

                                <ul className="ulTarjetasSears">
                                    {CRevUS?.length >= 1 &&
                                        <li>Crédito Revolvente Sanborns
                                            <ul className="intoPromo">
                                                {CRevUS.map(((promo, i) =>
                                                    <li key={i}> {promo?.promotion?.name} </li>
                                                ))
                                                }
                                            </ul>
                                        </li>
                                    }
                                    {MSISearsUS?.length >= 1 &&
                                        <li>Meses sin intereses Sanborns
                                            <ul className="intoPromo">
                                                {MSISearsUS.map(((promo, i) =>
                                                    <li key={i}> {promo?.promotion?.name} </li>
                                                ))
                                                }
                                            </ul>
                                        </li>
                                    }
                                    {MPCSearsUS?.length >= 1 &&
                                        <li>Mejor plan de crédito Sanborns
                                            <ul className="intoPromo">
                                                {MPCSearsUS[0].promotion?.best_plan?.map((plan, ind) =>
                                                    <li key={ind}>
                                                        {plan?.months} mensualidades fijas de {plan?.monthly_payments?.toFixed(2)}
                                                    </li>)
                                                }
                                            </ul>
                                        </li>
                                    }


                                </ul>
                            </div>
                            : null
                        }

                        {(payment_methods?.find(item => item.id === 4468) && payment_methods?.find(item => item.id === 4468)?.promotion?.length > 0)
                            ? <div className="dosPagos">
                                <p className="h2">Otras tarjetas</p>
                                <ul className="uldospagos">
                                    {payment_methods?.find(item => item.id === 4468)?.promotion[0]?.monthly_payments &&
                                        payment_methods?.find(item => item.id === 4468)?.promotion[0]?.monthly_payments?.map( (datos, ind) =>
                                            <li key={ind}>
                                                {datos.months} {datos.months === 1 ? <>Mes de </> : <>Meses de </>}
                                                <CurrencyFormat
                                                    decimalScale={2}
                                                    FixedDecimalScale={true}
                                                    value={datos.price % 1 === 0 ? datos.price + '.001' : datos.price}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    renderText={value => <span>{value}*</span>}>
                                                </CurrencyFormat>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                            : <div className="dosPagos"></div>
                        }
                    </div>
                </TabPanel>
                : null
            }

            {/* <TabPanel>
                <div className="moduleFormaPagoSanborns">

                </div>
            </TabPanel> */}

            {/* {warrantyDetails === null || !warrantyDetails ? null
                :
                <TabPanel>
                    <div className="moduleWarrantySears">
                        {warrantyDetails && warrantyDetails.length >= 1
                            ? <div className="warrantyRSears">
                                <p className="titulo"></p>
                                <ul className="opciones">
                                    {warrantyDetails[0].manufacturer &&
                                        <li>Garantía con Fabricante: <span>{warrantyDetails[0].manufacturer.time}</span></li>
                                    }
                                    {warrantyDetails[0].seller &&
                                        <li>Garantía con Vendedor: <span>{warrantyDetails[0].seller.time}</span></li>
                                    }
                                </ul>
                            </div>
                            : null
                        }

                        <div className="condicionesSears">
                            <p className="titulo">Condiciones:</p>

                            <ul className="ulCondiciones">
                                <li>El producto no debe presentar golpes o alteraciones de ningún tipo.</li>
                                <li>El producto debe ser devuelto en el mismo estado en que lo recibiste.</li>
                                <li>Empaca tu devolución de forma segura.</li>
                            </ul>

                            <div className="pEnlaceDevoluciones">*Conoce más acerca de las <Link to="/politica-devolucion" className="enlaceDevoluciones">Políticas de Garantía</Link></div>
                        </div>

                    </div>
                </TabPanel>
            } */}
        </Tabs >
    )
}