import React, { useState, useEffect } from 'react';
import './style.sass';
import { Link, useHistory } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';
import ReactDOM from 'react-dom';

export default function ModuleCreditCardSanbornsPromotion(props) {

    const {
        credit_card_months,
        tieneFormasPago
    } = props;

    const history = useHistory();
    const prevUrl = document.referrer;
    const urlLink = window.location.href;
    const hashLink = window.location.hash;
    const { isMobile } = useIsMobile();

    const [showModal, SetShowModal] = useState(false);
    const showCreditCardData = () => {
        SetShowModal(true);
    }

    const handleClose = () => {
        history.goBack();
    }

    useEffect(() => {
        if (prevUrl === "") {
            if (hashLink === "#modalCreditCardSanborns") {
                showCreditCardData();
            } else {
                SetShowModal(false);
            }
        } else {
            if (hashLink === "#modalCreditCardSanborns") {
                showCreditCardData();
            } else {
                SetShowModal(false);
            }
        }
    }, [hashLink, urlLink])

    let CRev = []
    let MPCSears = []
    let MSISears = []
    const [CRevUS, setCRevUS] = useState()
    const [MPCSearsUS, setMPCSearsUS] = useState()
    const [MSISearsUS, setMSISearsUS] = useState()
    useEffect(()=>{
        if(credit_card_months){
            credit_card_months.forEach(cardMonts => {
                if(cardMonts.promotion.payment_method === 4379){
                    CRev.push(cardMonts)
                }else if(cardMonts.promotion.payment_method === 4368 ){
                    MPCSears.push(cardMonts)
                }else if(cardMonts.promotion.payment_method === 4369 ){
                    MSISears.push(cardMonts )
                }
            })
            setCRevUS(CRev)
            setMPCSearsUS(MPCSears)
            setMSISearsUS(MSISears)
        }
    },[credit_card_months])

    return (
        <div className={tieneFormasPago && tieneFormasPago.find(item => item.id === 4468) && tieneFormasPago.find(item => item.id === 4468).promotion.length > 0 && tieneFormasPago.find(item => item.id === 4468).promotion[0].monthly_payments ? "moduleCreditCardPromo creditSoloTop" : "moduleCreditCardPromo creditSears"}>
            <Link to={"#modalCreditCardSanborns"}
                className="lastMonthCreditCard"
                onClick={showCreditCardData}
            >
                <img src="/img/card-sanborns.svg" alt="Logo Tarjeta Sanborns" loading="lazy" width="35" height="26" />
                <p> Ver promociones de Tarjetas Sanborns </p>
            </Link>

            {showModal &&
                <ModalPortalSanbornsPromotion onClose={handleClose} isMobile={isMobile} type={isMobile === "desktop" ? "" : "full"} title={isMobile === "desktop" ? "Promociones" : "Promociones Sanborns"}>
                    <div className="textEspecificacion dataModal11">
                        <div className={isMobile === "desktop" ? "formasPago" : "formasPago fpMobile"}>
                            <p>Tarjetas Sanborns</p>
                        </div>

                        <ul className="ulModalPromoSears">
                            {/* {credit_card_months && credit_card_months.length > 0 &&
                                credit_card_months.map(((promo, i) =>
                                    <li key={i}> {promo.promotion.name} </li>
                                ))
                            } */}


                            {/* {credit_card_months && credit_card_months.length > 0 &&
                                credit_card_months.map(((promo, i) =>
                                    // <li key={i}> {promo.name} </li>
                                    <>
                                        {promo.promotion &&
                                            <li key={i}>
                                                {promo.promotion.name}

                                                {promo?.promotion && promo?.promotion?.id === 0
                                                    ? 
                                                        <ul className="intoPromo">
                                                            {   promo?.promotion?.best_plan?.map((plan) =>
                                                                <li>
                                                                    {plan?.months} mensualidades fijas de {plan?.monthly_payments}
                                                                </li>)
                                                            }
                                                        </ul>
                                                    : null
                                                }
                                            </li>
                                        }



                                    </>
                                ))
                            } */}

                            {CRevUS?.length >= 1 &&
                                <li>Crédito Revolvente Sanborns
                                    <ul className="intoPromo">
                                        {CRevUS.map(((promo, i) =>
                                                <li key={i}> {promo.promotion.name} </li>
                                            ))
                                        }
                                    </ul>
                                </li>
                            }
                            {MSISearsUS?.length >= 1 &&
                                <li>Meses sin intereses Sanborns
                                    <ul className="intoPromo">
                                        {MSISearsUS.map(((promo, i) =>
                                                <li key={i}> {promo.promotion.name} </li>
                                            ))
                                        }
                                    </ul>
                                </li>
                            }
                            {MPCSearsUS?.length >= 1 &&
                                <li>Mejor plan de crédito Sanborns
                                    <ul className="intoPromo">
                                        {MPCSearsUS[0].promotion?.best_plan?.map((plan, ind) =>
                                            <li key={ind}>
                                                {plan?.months} mensualidades fijas de {plan?.monthly_payments?.toFixed(2)}
                                            </li>)
                                        }
                                    </ul>
                                </li>
                            }

                        </ul>

                    </div>
                </ModalPortalSanbornsPromotion>
            }
        </div>
    )
}

function Modal({ ...props }) {
    const {
        children,
        onClose,
        type,
        title,
        isMobile
    } = props;

    return (
        <div className={`modal ${type}`}>
            <div className={isMobile === "desktop" ? "contentModal modalTallas" : "contentModal modalTallasM"}>
                <div className={type === "full" ? "contentCloseTallas" : "contentCloseTallas"}>
                    <div className={type === "full" ? "btn closeX" : "btn closeX"} onClick={onClose}></div>
                    {title
                        ? <p className="title">{title}</p>
                        : null
                    }
                </div>

                <div className="dataModalTallas dataModal11">
                    {children}
                </div>
            </div>
        </div>
    )
}


export function ModalPortalSanbornsPromotion({ ...props }) {
    const {
        children,
        onClose,
        type,
        closeBody,
        title,
        isMobile
    } = props;

    return ReactDOM.createPortal(
        <Modal onClose={onClose} type={type} title={title} closeBody={closeBody} isMobile={isMobile}>
            {children}
        </Modal>,
        document.getElementById('modal-root')
    )
}