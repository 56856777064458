import React, { Fragment } from "react";

//import {OldVersionSlider} from 'components/Home/PrincipalSlider/TypeVersionPrincipalBanner'
import {
  AppVersionSliderMobile,
  AppVersionSliderMobileNew,
} from "components/Home/PrincipalSlider/TypeVersionPrincipalBanner";
import {
  QuickAccessMobile,
  QuickAccessMobileNew,
} from "components/Home/QuickAccess";
import {
  PromotionProductMobile,
  PromotionProductMobileNew,
} from "components/Home/PromotionProduct";
import {
  RecomendationProductsHome,
  RecomendationProductsHomeNew,
} from "components/Home/RecomendationProductsHome";
import {
  TiendasSliderMovil,
  TiendasSliderMovilNew,
} from "components/Home/Tiendas";
import PromotionAnnouncement from "components/Home/PromotionAnnouncement";
import SlidersEmarsys from "components/Emarsys/SlidersEmarsys";
import Footer from "pages/Footer/FooterDesktop";
import ErrorType from "pages/Error/ErrorType";

import { HomeSkeletonSliderP } from "pages/Home/HomeSkeleton/Sliders";
import { HomeSkeletonQuickA } from "pages/Home/HomeSkeleton/QuickAccess";
import { HomeSkeletonPromotion } from "pages/Home/HomeSkeleton/Promotion";
import { HomeSkeletonCarrusel } from "pages/Home/HomeSkeleton/Carruseles";

import { decodeStorage } from "functions/storageBase64";
import { ModalMiTelmexHome } from "components/MiTelmex/modaMiTelmex/modalMiTelmex";
import { ErrorBoundary } from "components/Error/ErrorBoundary";
import { VARIANTS_PER_PORTAL } from "constants/general";
// import { SectionOpcionesSearsMobile } from "components/Home/SectionOpcionesSears.js";

import { GridBanners } from "components/Home/GridBanners";
import { WhatsAppAyuda } from "components/ProductDetail/SirenaWhatsApp";
// import { WhatsAppAyuda } from 'components/ProductDetail/SirenaWhatsApp'
import HistorialNav from "components/HistorialNav";
import HistorialNavSliderMovil from "components/HistorialNav/carruselMovil";

// Home V3
export function HomeTemplateMobileNew(props) {
  const sesionTelmex = decodeStorage("telefono_usuario");

  const { tmxCredit, respHomeV3, errors } = props;

  // console.log('sliders',sliders)
  //console.log('quickAccess',quickAccess)
  //console.log('promotionProduct',promotionProduct)
  //console.log('carruseles',carruseles)
  //console.log('tiendas',tiendas)
  //console.log('promotionAnnouncement',promotionAnnouncement)
  //console.log('emarsys',emarsys)
  //console.log('tmxCredit',tmxCredit)
  //console.log('respHomeV3: ',respHomeV3)

  return (
    <>
      <main className="sectionHome">
        {!errors ? (
          <>
            {respHomeV3 ? (
              respHomeV3.map(
                ({ globalOrder, items, type, visible, style }, index) => (
                  <Fragment key={index}>
                    {
                      //console.log(globalOrder, items, type, visible)
                    }
                    {type === "sliders" ? (
                      (items || items?.length >= 1) && visible === true ? (
                        <ErrorBoundary>
                          <section className={`homePosition${globalOrder} new`}>
                            <AppVersionSliderMobileNew
                              sliders={items}
                              styleView={style}
                            />
                          </section>
                        </ErrorBoundary>
                      ) : null
                    ) : type === "quickAccess" ? (
                      (items || items?.length >= 1) && visible === true ? (
                        <ErrorBoundary>
                          <section className={`homePosition${globalOrder} new`}>
                            <QuickAccessMobileNew
                              quickAccess={items}
                              styleView={style}
                            />
                          </section>
                        </ErrorBoundary>
                      ) : null
                    ) : type === "promotionAnnouncement" ? (
                      (items || items?.length >= 1) && visible === true ? (
                        <ErrorBoundary>
                          <section className={`homePosition${globalOrder} new`}>
                            <PromotionAnnouncement
                              promotionAnnouncement={items}
                              styleView={style}
                              respHomeV3={respHomeV3}
                            />
                          </section>
                        </ErrorBoundary>
                      ) : null
                    ) : type === "carousels" ? (
                      (items || items?.length >= 1) && visible === true ? (
                        <ErrorBoundary>
                          <section className={`homePosition${globalOrder} new`}>
                            <RecomendationProductsHomeNew
                              carruseles={items}
                              type={"slider"}
                              styleView={style}
                            />
                          </section>
                        </ErrorBoundary>
                      ) : null
                    ) : type === "shops2" ? (
                      (items || items?.length >= 1) && visible === true ? (
                        <ErrorBoundary>
                          <section className={`homePosition${globalOrder} new`}>
                            <TiendasSliderMovilNew
                              tiendas={items}
                              styleView={style}
                            />
                          </section>
                        </ErrorBoundary>
                      ) : null
                    ) : type === "bombshellProduct" ? (
                      (items || items?.length >= 1) && visible === true ? (
                        <ErrorBoundary>
                          <section className={`homePosition${globalOrder} new`}>
                            <PromotionProductMobileNew
                              promotionProduct={items[0]?.product}
                              styleView={style}
                            />
                          </section>
                        </ErrorBoundary>
                      ) : null
                    ) : type === "emarsys" ? (
                      visible === true ? (
                        VARIANTS_PER_PORTAL?.emarsys?.discovery ? (
                          <section
                            id={`personalSlider_${globalOrder}`}
                            className={`homePosition${globalOrder}`}
                          >
                            <SlidersEmarsys
                              logica={"PERSONAL"}
                              type={"recomendation"}
                              typeView={"blockView"}
                              title={"Te recomendamos"}
                              idContent={`personalSlider_${globalOrder}`}
                              btnType={"add"}
                              cant={50}
                              gadget="desktop"
                            />
                            <SlidersEmarsys
                              logica={"GO"}
                              idContent={`personalSlider_${globalOrder}`}
                            />
                          </section>
                        ) : null
                      ) : null
                    ) : type === "tmx_credit" && VARIANTS_PER_PORTAL.telmex ? (
                      visible === true ? (
                        sesionTelmex ? (
                          <section className="homePosition0">
                            <div className="container">
                              {" "}
                              <ModalMiTelmexHome />
                            </div>
                          </section>
                        ) : (
                          <>
                            {!window.localStorage.getItem(
                              "closebtnMT-home"
                            ) && (
                              <section className="homePosition2">
                                <div className="container">
                                  {" "}
                                  <ModalMiTelmexHome />
                                </div>
                              </section>
                            )}
                            <section
                              className={
                                tmxCredit
                                  ? `homePosition${tmxCredit.order}`
                                  : null
                              }
                            >
                              {window.localStorage.getItem(
                                "closebtnMT-home"
                              ) === "true" && (
                                <div className="container">
                                  {" "}
                                  <ModalMiTelmexHome />
                                </div>
                              )}
                            </section>
                          </>
                        )
                      ) : null
                    ) : null}
                  </Fragment>
                )
              )
            ) : (
              <>
                <HomeSkeletonSliderP position="0" />
                <HomeSkeletonQuickA position="1" />
                <HomeSkeletonPromotion position="2" />
                <HomeSkeletonCarrusel position="3" />
                <HomeSkeletonPromotion position="4" />
                <HomeSkeletonCarrusel position="5" />
              </>
            )}
          </>
        ) : (
          <ErrorType codeStatus={errors.error} errorText={errors.response} />
        )}

        {/* <ErrorBoundary>
          <section className={`homePosition10 seccionTiendasHome`}>
            <h2 className="tituloTiendas">OTROS SERVICIOS</h2>
            <SectionOpcionesSearsMobile />
          </section>
        </ErrorBoundary> */}

        <ErrorBoundary>
					<WhatsAppAyuda 
						section="home"
					/>
				</ErrorBoundary>
      </main>
    </>
  );
}

// Home V2
export function HomeTemplateMobileN(props) {
  const sesionTelmex = decodeStorage("telefono_usuario");

  const {
    sliders,
    quickAccess,
    promotionProduct,
    carruseles,
    tiendas,
    promotionAnnouncement,
    emarsys,
    tmxCredit,
    errors,
  } = props;

  // console.log('sliders',sliders)
  //console.log('quickAccess',quickAccess)
  //console.log('promotionProduct',promotionProduct)
  //console.log('carruseles',carruseles)
  //console.log('tiendas',tiendas)
  //console.log('promotionAnnouncement',promotionAnnouncement)
  //console.log('emarsys',emarsys)
  //console.log('tmxCredit',tmxCredit)

  return (
    <>
      <main className="sectionHome">
        {!errors ? (
          <>
            <ErrorBoundary>
              {sliders && sliders.visible ? (
                //? <section className={sliders ? `homePosition${sliders.order}` : null}><AppVersionSliderMobileNew sliders={sliders.content} /></section>
                <section className={sliders ? `homePosition$0` : null}>
                  <AppVersionSliderMobileNew sliders={sliders.content} />
                </section>
              ) : (
                <HomeSkeletonSliderP position="0" />
              )}
            </ErrorBoundary>

            <ErrorBoundary>
              {quickAccess && quickAccess.visible ? (
                //? <section className={quickAccess ? `homePosition${quickAccess.order}` : null}><QuickAccessMobileNew quickAccess={quickAccess.content} /></section>
                <section className={quickAccess ? `homePosition1` : null}>
                  <QuickAccessMobileNew quickAccess={quickAccess.content} />
                </section>
              ) : (
                <HomeSkeletonQuickA position="1" />
              )}
            </ErrorBoundary>

            <ErrorBoundary>
              {promotionProduct && promotionProduct.visible ? (
                //<section className={promotionProduct ? `homePosition${promotionProduct.order}` : null}>
                <section className={promotionProduct ? `homePosition4` : null}>
                  {promotionProduct.content &&
                    promotionProduct.content.product && (
                      <PromotionProductMobileNew
                        promotionProduct={promotionProduct.content.product}
                      />
                    )}
                </section>
              ) : null}
            </ErrorBoundary>

            {/* <ErrorBoundary>
							{VARIANTS_PER_PORTAL.telmex &&
								tmxCredit &&
								tmxCredit.visible
								?
								sesionTelmex
									?
									<section className="homePosition0">
										<div className="container">	<ModalMiTelmexHome /></div>
									</section>


									:
									<>
										{!window.localStorage.getItem("closebtnMT-home") &&
											<section className="homePosition2">
												<div className="container">	<ModalMiTelmexHome /></div>
											</section>
										}
										<section className={tmxCredit ? `homePosition${tmxCredit.order}` : null}>
											{window.localStorage.getItem("closebtnMT-home") === 'true' &&
												<div className="container">	<ModalMiTelmexHome /></div>
											}
										</section>
									</>
								: null
							}
						</ErrorBoundary> */}

            <ErrorBoundary>
              {carruseles && carruseles.visible ? (
                //? <section className={carruseles ? `homePosition${carruseles.order}` : null}><RecomendationProductsHomeNew carruseles={carruseles.content} type={'slider'} /></section>
                <section className={carruseles ? `homePosition5` : null}>
                  <RecomendationProductsHomeNew
                    carruseles={carruseles.content}
                    type={"slider"}
                  />
                </section>
              ) : (
                <HomeSkeletonCarrusel position="3" />
              )}
            </ErrorBoundary>
          </>
        ) : (
          <ErrorType codeStatus={errors.error} errorText={errors.response} />
        )}

        <ErrorBoundary>
          {emarsys && emarsys.visible ? (
            VARIANTS_PER_PORTAL?.emarsys?.discovery ? (
              <section
                id="personalSlider"
                className={emarsys ? `homePosition7` : null}
              >
                <SlidersEmarsys
                  logica={"PERSONAL"}
                  type={"recomendation"}
                  typeView={"blockView"}
                  title={"Te Recomendamos"}
                  idContent={"personalSlider"}
                  btnType={"add"}
                  cant={30}
                />
                <SlidersEmarsys logica={"GO"} idContent={"personalSlider"} />
              </section>
            ) : null
          ) : null}
        </ErrorBoundary>

        {/* <ErrorBoundary>
          <section
            className={
              tiendas
                ? `homePosition10 seccionTiendasHome`
                : "seccionTiendasHome"
            }
          >
            <h2 className="tituloTiendas">DÉJANOS AYUDARTE</h2>
            <div className="bxDejanos"></div>
            <SectionOpcionesSearsMobile />
          </section>
        </ErrorBoundary> */}

        {/* <ErrorBoundary>
					{promotionAnnouncement &&
						promotionAnnouncement.visible
						? <section className={promotionAnnouncement ? `homePosition${promotionAnnouncement.order}` : null}><PromotionAnnouncement promotionAnnouncement={promotionAnnouncement.content} /></section>
						: null
					}
				</ErrorBoundary> */}

        <ErrorBoundary>
          <GridBanners />
        </ErrorBoundary>

        <ErrorBoundary>
					<WhatsAppAyuda
						section="home"
					/>
				</ErrorBoundary>
      </main>
      {/* <Footer visible="true" /> */}
    </>
  );
}

// Home V1
export function HomeTemplateMobile(props) {
  const {
    sliders,
    quickAccess,
    promotionProduct,
    carruseles,
    tiendas,
    promotionAnnouncement,
    errors,
  } = props;

  return (
    <>
      <main>
        {!errors ? (
          <>
            <ErrorBoundary>
              {sliders &&
              sliders.length !== 0 &&
              Array.isArray(sliders) !== false ? (
                <section>
                  <AppVersionSliderMobile sliders={sliders} />
                </section>
              ) : (
                <HomeSkeletonSliderP position="0" />
              )}
            </ErrorBoundary>

            <ErrorBoundary>
              {quickAccess &&
              quickAccess.length !== 0 &&
              Array.isArray(quickAccess) !== false ? (
                <section>
                  <QuickAccessMobile quickAccess={quickAccess} />
                </section>
              ) : (
                <HomeSkeletonQuickA position="1" />
              )}
            </ErrorBoundary>

            <ErrorBoundary>
              {promotionProduct &&
              promotionProduct.length !== 0 &&
              Object.keys(promotionProduct).length > 0 ? (
                <section>
                  {" "}
                  <PromotionProductMobile promotionProduct={promotionProduct} />
                </section>
              ) : null}
            </ErrorBoundary>

            {/* <ErrorBoundary>
							{!window.localStorage.getItem("closebtnMT-home") &&
								<div className="container">	<ModalMiTelmexHome /></div>
							}
						</ErrorBoundary> */}

            <ErrorBoundary>
              {carruseles && carruseles.length !== 0 ? (
                <section>
                  <RecomendationProductsHome
                    carruseles={carruseles}
                    type={"slider"}
                  />
                </section>
              ) : (
                <HomeSkeletonCarrusel position="3" />
              )}
            </ErrorBoundary>
          </>
        ) : (
          <ErrorType codeStatus={errors.error} errorText={errors.response} />
        )}

        <ErrorBoundary>
          {VARIANTS_PER_PORTAL?.emarsys?.discovery ? (
            <section id="personalSlider">
              <SlidersEmarsys
                logica={"PERSONAL"}
                type={"recomendation"}
                typeView={"blockView"}
                title={"Te Recomendamos"}
                idContent={"personalSlider"}
                btnType={"add"}
                cant={30}
              />
              <SlidersEmarsys logica={"GO"} idContent={"personalSlider"} />
            </section>
          ) : null}
        </ErrorBoundary>

        {/* <ErrorBoundary>
          {tiendas && tiendas.length !== 0 ? (
            <section className="seccionTiendasHome">
              <h2 className="tituloTiendas">DÉJANOS AYUDARTE</h2>
              <div className="bxDejanos"></div>
              <SectionOpcionesSearsMobile></SectionOpcionesSearsMobile>
            </section>
          ) : null}
        </ErrorBoundary> */}

        {/* <ErrorBoundary>
					{promotionAnnouncement && promotionAnnouncement.length !== 0
						? <section><PromotionAnnouncement promotionAnnouncement={promotionAnnouncement} /></section>
						: null
					}
				</ErrorBoundary> */}

        <ErrorBoundary>
          {window.localStorage.getItem("closebtnMT-home") === "true" && (
            <div className="container">
              {" "}
              <ModalMiTelmexHome />
            </div>
          )}
        </ErrorBoundary>
      </main>
      {/* <Footer visible="true" /> */}
    </>
  );
}
