import React from 'react'
import { MenuMiCuenta } from 'components/MenuMiCuenta'
import { CardVistos } from 'components/CardProduct/CardVistos'
import ErrorType from 'pages/Error/ErrorType'
import { NO_IMAGE } from 'constants/general';

export function VistoslistTemplateDesktop(props) {
    const {
        rawPixelProducts,
        pixelProducts,
        errorText,        
        updateDelete,
        addToCart
    } = props

    // console.log("desktop historial: ",props)
    return (
        <>
            <main>
                <section className="miCuentaContainer">

                    <div className="container myAccountSection">

                        {/* <MenuMiCuenta /> */}

                        <div className="navigationContentMyAccountHistorial">
                            <h1 className="h1MiLista">Historial de Navegación</h1>
                            { (rawPixelProducts.statusCode >= 200 && rawPixelProducts.statusCode <= 299 )
                                ? <div className="">
                                    {pixelProducts?.products &&
                                        pixelProducts?.products.map(({ month, products }, ind) =>
                                            products &&
                                                <>
                                                    <h2 className='text-left'>{month}</h2>
                                                    <div className="contWishlisthistorial">
                                                        {products.map(({ id, images, title, price, sale_price, discount, size_color, status, stock, shipping, features, brand }, ind) =>
                                                            <CardVistos
                                                                key={ind}
                                                                id={id}                                                
                                                                images={images?.length >= 1 ? images[0]?.thumbnail : NO_IMAGE}
                                                                title={title}
                                                                size_color={size_color}
                                                                price={price}
                                                                sale_price={sale_price}
                                                                discount={discount}
                                                                status={status}
                                                                stock={stock}
                                                                shipping_price={shipping?.is_free}                                                
                                                                features={features}                                                
                                                                upAddCart={addToCart}
                                                                upDelete={updateDelete}
                                                                brand={brand}
                                                                index={ind}
                                                                cyr={features?.cyr}
                                                
                                                            />
                                                            )
                                                        }
                                                    </div>
                                                </>
                                        )
                                    }
                                    {pixelProducts?.total_items === 0
                                        ? <ErrorType
                                            section="pixelProducts"
                                            codeStatus={404}
                                            errorText={errorText}
                                        />
                                        : null
                                    }
                                </div>
                                : null
                            }
                            <ErrorType
                                section="pixelProducts"
                                codeStatus={rawPixelProducts?.statusCode}
                                errorText={errorText}
                            />
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}