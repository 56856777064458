import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.sass';

export default function BoxServiciosCliente() {

    const [menuView, setMenuView] = useState(false);

    const toggleClass = () => {
        setMenuView(!menuView);
    };

    return (
        <div className="boxClaroShop">
            <p onClick={toggleClass} className={menuView ? 'active' : null}>Servicios al cliente</p>
            <ul>
                <li><Link to={"/localizador-tiendas/"}  >Directorio de tiendas</Link></li>
                <li><a href='https://facturaelectronica.sanborns.com.mx/' target="_blank" rel="noopener noreferrer" >Facturación electrónica</a></li>
            </ul>
        </div>
    )
}
