import React, { useState, useEffect } from 'react';
import { getPedido, LoginAyudaDinamica } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import '../detallePedido/detallePedido.sass';
import './ayudaDinamica.sass';
import Loading from 'components/Loading';
import { removeDuplicates } from 'functions/removeDuplicates';
import { useIsMobile } from 'hooks/useIsMobile';
import { NoFuncionaDesktop } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/AD-Desktop/vista7-noFunciona-desktop';
import { USERNAME, PASSWORD, PUBLICKEY } from 'constants/general';

export function NoFuncionaMovil() {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [ima, setima] = useState([]);
    const [loading, setLoading] = useState();

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '')
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // obtiene servicio pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido)
    }, [])

    useEffect(() => {
        let imagenProducto = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido) {
                // recorre por producto
                if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {
                    if (resultadoPedido.pedido[0]) {
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem.images[0].url, "id": elem.related_product_id })
                        )

                        let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                        setima(limpioImagen);
                    }
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
                LoginAyudaDinamica({ username: USERNAME, password: PASSWORD, publicKey: PUBLICKEY })
                setTimeout(() => {
                    getPedido()
                        .then(setResultadoPedido)
                }, 1000);
            }
        }
    }, [resultadoPedido])

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido && resultadoPedido.pedido) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido])

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    // redirecciona a cual es tu motivo
    const handleEntendido = () => {
        history.push("/mi-cuenta/mis-pedidos/dias=30/pagina=1")
    }

    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">No funciona o no enciende</p>
                    </div>
                    <div className="dataModal">
                        <div className="contenedorImagenes">
                            <ul>
                                {ima.length > 0 && ima.map((elemento, i) => i <= 2 && <li key={i}><img src={elemento.url} alt={"imagen" + i} width={70} /></li>)}
                            </ul>
                        </div>

                        <div className="boxSolicitarDevolucion">
                            <p className="devolucionp1">Si el artículo fue vendido por <span style={{ fontWeight: "600" }}>SEARS</span> o <span style={{ fontWeight: "600" }}>Sanborns</span>, por favor contacta directamente el fabricante del producto.</p>
                            <p className="devolucionp2">Para apoyo con este proceso puedes contactarnos en el ícono de Whatsapp o en el 55-1203-0502 de 8 a 21 horas.</p>


                            <div className="boxBtnContinuarDevolucion">
                                <button className="btnContinuarDevolucion" onClick={handleEntendido}>Entendido</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export function NoFunciona(props) {
    const { isMobile } = useIsMobile();

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <NoFuncionaDesktop />
                    : isMobile === 'movile'
                        ? null
                        : <NoFuncionaMovil />
                : null
            }
        </>
    )
}