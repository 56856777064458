import React, { useState, Fragment, useEffect, useRef } from 'react';
import './style.sass';
import { useHistory, useLocation } from 'react-router-dom'
import Slider from 'react-slick';
import ProgressiveImage from 'react-progressive-graceful-image'
import UseModal from './useModal';
import { useImageArray } from 'hooks/useImageArray'
import { repareImage } from 'functions/repareDataService'
import { NO_IMAGE } from 'constants/general'

import { ShareComponentDetail } from 'components/Share';
import { VideoProductComponent } from 'components/ProductDetail/VideoProduct'
import BtnWishlist from 'components/Wishlist';

export default function SliderImageProdut({ ...props }) {
    const {
        id,
        dataImage,
        dataVideo,
        title,
        category,
        brand,
        sale_price
    } = props

    const history = useHistory()
    const location = useLocation()
    const urlLink = window.location.href
    const hashLink = window.location.hash

    const imageSliderProduct = useImageArray(dataImage)

    const [position, setPosition] = useState({ value: 0 });
    const [isShowing, setIsShowing] = useState(false);
    const [indexModal, setIndexModal] = useState(0);
    const [indexAbrir, setIndexAbrir] = useState(null);
    const slider1 = useRef(null);


    const [showModal, SetShowModal] = useState(false)
    const showModalSliderProduct = () => {
        SetShowModal(true)
        document.querySelector('body').classList.add('open')
        document.querySelector('body').classList.add('openMobile')
    }

    const handleClose = () => {
        history.push(location.pathname)
    }
    useEffect(() => {
        if (hashLink === "#modalSliderProduct") {
            showModalSliderProduct()
        } else {
            SetShowModal(false)
            document.querySelector('body').classList.remove('open')
            document.querySelector('body').classList.remove('openMobile')
        }
    }, [hashLink, urlLink])


    const settings = {
        beforeChange: (current, next) => {
            setPosition({ value: next })
            // if(dataVideo){
            //     document.querySelector('.ytVideo').contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
            // }
        },
        afterChange: current => {
            setPosition({ value: current })
            // if(dataVideo){
            //     document.querySelector('.ytVideo').contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
            // }
        }
    };

    // if (isShowing) {
    //     $('body').addClass('open');
    //     $('body').addClass('openMobile');
    // }
    // if (!isShowing) {
    //     $('body').removeClass('open');
    // }

    // abre modal
    function toggle(e) {
        let id = e.target.id;
        setIndexAbrir(Number(id));
        history.push('#modalSliderProduct')
    }

    const fijarIndex = (index) => {
        setIndexModal(Number(index))
        slider1.current.slickGoTo(Number(index))
        // if(dataVideo){
        //     document.querySelector('.ytVideo').contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
        // }
    }
    useEffect(() => {
        slider1.current.slickGoTo(Number(indexModal))
    }, [indexModal])

    useEffect(() => {
        if (id) {
            slider1.current.slickGoTo(Number(0))
        }
    }, [id])

    return (
        <Fragment>
            <div className="contImagesProductMobile">
                <ShareComponentDetail title={title} />
                {/* <BtnWishlist
                    id={id}
                    nombre={title}
                    precioLista={sale_price}
                    brand={brand}
                    categories={category}
                    index='1'
                /> */}
                <div className="contSlider">
                    {imageSliderProduct?.dataImage &&
                            <Fragment>
                                <Slider {...settings}
                                    slidesToShow={1}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                    arrows={false}
                                    ref={slider1}
                                    slickGoTo={indexModal}
                                    infinite={false}
                                    dots={true}
                                >
                                    {/* {dataVideo &&
                                        dataVideo.map(({ url, order }, i) =>
                                            <div className="videoYtProduct">
                                                <iframe width="460" height="266" src={`${url}?enablejsapi=1&version=3&playerapiid=ytplayer`} frameborder="0" allowfullscreen="allowfullscreen" key={order} d={i} data-slide-index={i} className="ytVideo"></iframe>
                                            </div>
                                        )
                                    } */}
                                    {imageSliderProduct?.dataImage?.map(({ url, order }, ind) =>
                                        ind === 0
                                            ?
                                                <ProgressiveImage
                                                    src={url != null ? url?.indexOf('?') >= 0 ? `${repareImage(url).replace('publicidad', 'mkt')}&scale=550` : `${repareImage(url).replace('publicidad', 'mkt')}?scale=550` : NO_IMAGE}
                                                    placeholder={url != null ? url?.indexOf('?') >= 0 ? `${repareImage(url).replace('publicidad', 'mkt')}&scale=350` : `${repareImage(url).replace('publicidad', 'mkt')}?scale=350` : NO_IMAGE}
                                                    key={`big_${order}`}
                                                >
                                                    {(src) => <img
                                                            className="imagenActual"
                                                            fetchpriority={ind === 0 ? 'High' :'low'}
                                                            loading={ind === 0 ? 'eager' :'lazy'}
                                                            src={src}
                                                            alt={title}
                                                            key={`big_${order}`}
                                                            width="500"
                                                            height="500"
                                                            decoding="async"
                                                            onClick={toggle}
                                                            id={ind}
                                                            data-slide-index={ind}
                                                        />
                                                    }
                                                </ProgressiveImage>
                                            :
                                                <img
                                                    className="imagenActual"
                                                    fetchpriority={ind === 0 ? 'High' :'low'}
                                                    loading={ind === 0 ? 'eager' :'lazy'}
                                                    src={url != null ? url?.indexOf('?') >= 0 ? `${repareImage(url).replace('publicidad', 'mkt')}&scale=550` : `${repareImage(url).replace('publicidad', 'mkt')}?scale=550` : NO_IMAGE}
                                                    alt={title}
                                                    key={`big_${order}`}
                                                    width="500"
                                                    height="500"
                                                    decoding="async"
                                                    onClick={toggle}
                                                    id={ind}
                                                    data-slide-index={ind}
                                                />
                                    )}
                                </Slider>

                                {showModal &&
                                    <UseModal
                                        indexAbrir={indexAbrir}
                                        setIndexAbrir={setIndexAbrir}
                                        dataImage={imageSliderProduct.dataImage}
                                        setIsShowing={handleClose}
                                        fijarIndex={fijarIndex}
                                    />
                                }

                            </Fragment>
                    }
                </div>
                <div className="contDataSlider">
                    {/* <p className="countImage">{(position.value + 1)} / {dataVideo ? dataImage.length + dataVideo.length : dataImage.length }</p> */}
                    <p className="countImage">{(position.value + 1)} / {imageSliderProduct.dataImage.length}</p>
                    <VideoProductComponent video={dataVideo} />
                    {/* <ShareComponentDetail title={title} /> */}
                    <BtnWishlist
                        id={id}
                        nombre={title}
                        precioLista={sale_price}
                        brand={brand}
                        categories={category}
                        index='1'
                    />
                </div>
            </div>
        </Fragment>
    )
}