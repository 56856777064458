import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuPoliticas } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { Link, useHistory } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';

export function AvisoPrivacidadStoreBTSDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Store BTS');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuPoliticas />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Aviso de Privacidad EventoPop Up Store BTS</p>
                            </div>

                            <div className="contentPreguntas">
                                <div className="question1">
                                    <h2>Eventos</h2>

                                    <p>Los datos personales de identificación, incluyendo su imagen personal a través de fotografía y/o video así como los datos de contacto que nos ha proporcionado serán tratados por Sanborn Hermanos, S. A. de C.V. (en adelante, “Sanborns”) con domicilio en calle Lago Zúrich No. 245, Edificio Presa Falcón piso 7, Colonia Ampliación Granada, Alcaldía Miguel Hidalgo, C.P. 11529, México, Ciudad de México, para identificarle como cliente, entregarle los artículos que adquiera y enviarle publicidad relativa a los artículos de la marca BTS o BT21 que SANBORNS comercializa.</p>
                                    <p>Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos a la dirección electrónica arco@sanborns.com.mx</p>
                                    <p>Por otro lado, le informamos que en la tienda Pop Up Store BTS (“Tienda”) operada por SANBORNS se tomarán fotografías y/o video en los que puede aparecer su imagen personal. Por ello, le informamos que en caso de que ingrese a la Tienda se entenderá que usted autoriza de forma expresa a SANBORNS para utilizar su imagen personal en el material informativo y/o en campañas promocionales que SANBORNS considere pertinentes. SANBORNS no es responsable del uso que medios de comunicación externos presentes en la Tienda Pop Up Stores BTS puedan dar a su imagen personal.</p>

                                    <p>Aunado a lo anterior, le informamos que, en caso de no oponerse, SANBORNS podrá utilizar sus datos personales para posibles contactos posteriores e informarle de lanzamiento o cambios de nuevos productos, bienes, servicios, promociones y/u ofertas de acuerdo a sus intereses, incluyendo el envío de comunicaciones a través de correos electrónicos, llamadas telefónicas, mensajes cortos y demás medios de comunicación físicos y/o electrónicos; así como para invitarle a eventos que pudieren ser de su interés.</p>
                                    <p>En caso de que usted no desee que sus datos personales sean tratados para las finalidades respecto de las cuales procede manifestar su negativa, desde este momento usted nos puede comunicar lo anterior al correo <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>
                                    <p>Sanborns podrá transferir, sin requerir de su consentimiento, los datos personales necesarios a autoridades competentes en los casos legalmente previstos así como a empresas del grupo para que éstas le puedan contactar, directa o indirectamente, para llevar a cabo actividades de promoción y/u ofrecimiento de productos y/o servicios.</p>

                                    <p>Para conocer cuáles son las Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común a quienes sus datos son transferidos puede consultarlo en <a href='http://www.gsanborns.com.mx/perfil-corporativo.html' target='_blank' rel="noopener noreferrer">http://www.gsanborns.com.mx/perfil-corporativo.html</a>.</p>
                                    <p>Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en lo sucesivo “derechos Arco”), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Protección de Datos Personales de SANBORNS a la dirección electrónica arco@sanborns.com.mx. Su petición deberá ser realizada a través del Formato de Solicitud de Derechos ARCO, el cual debe ser solicitado a la dirección de correo electrónico antes señalada. Para que el Departamento de Protección de Datos Personales de SANBORNS pueda darle seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo que es necesario que complete todos los campos indicados en el Formato y lo acompañe con copia de alguna de las identificaciones oficiales vigentes que se señalan en el mismo. Para obtener mayor información sobre los procedimientos, requisitos
                                        y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento contacte al Departamento de Protección de Datos Personales de SANBORNS en la dirección <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a>.</p>

                                    <p>Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la dirección arco@sanborns.com.mx. En caso de que su Solicitud sea procedente se le registrará en el listado de exclusión propio de SANBORNS. Para mayor información favor de contactar al Departamento de Protección de Datos Personales de SANBORNS.</p>
                                    <p>SANBORNS le notificará de cualquier cambio a su aviso de privacidad a través de una comunicación electrónica en el sitio web <Link to="/">www.sanborns.com.mx</Link>, y la posterior publicación de las modificaciones en punto de venta.</p>

                                    <p className='bold'>Aceptación de Aviso de Privacidad</p>
                                    <p>En caso de utilizar nuestros servicios se entenderá que autoriza el uso de sus datos personales de conformidad con lo establecido en el presente Aviso de Privacidad.</p>
                                    <p>Vigente desde Julio del 2020.</p>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
            </main>

            <Footer/>
        </>
    )
}


export function AvisoPrivacidadStoreBTSMobile() {
    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Store BTS');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN">
                        <div className="btn closeBack" onClick={onClose} ></div>
                        <p className="title">Aviso de Privacidad EventoPop Up Store BTS</p>
                    </div>

                    <div className="dataModal">

                        <div className="boxAN">
                            <div className='bxSlider'>
                                <p className="pSliderTitleL">Eventos</p>

                                <p className="pSliderL">Los datos personales de identificación, incluyendo su imagen personal a través de fotografía y/o video así como los datos de contacto que nos ha proporcionado serán tratados por Sanborn Hermanos, S. A. de C.V. (en adelante, “Sanborns”) con domicilio en calle Lago Zúrich No. 245, Edificio Presa Falcón piso 7, Colonia Ampliación Granada, Alcaldía Miguel Hidalgo, C.P. 11529, México, Ciudad de México, para identificarle como cliente, entregarle los artículos que adquiera y enviarle publicidad relativa a los artículos de la marca BTS o BT21 que SANBORNS comercializa.</p>
                                <p className="pSliderL">Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos a la dirección electrónica arco@sanborns.com.mx</p>
                                <p className="pSliderL">Por otro lado, le informamos que en la tienda Pop Up Store BTS (“Tienda”) operada por SANBORNS se tomarán fotografías y/o video en los que puede aparecer su imagen personal. Por ello, le informamos que en caso de que ingrese a la Tienda se entenderá que usted autoriza de forma expresa a SANBORNS para utilizar su imagen personal en el material informativo y/o en campañas promocionales que SANBORNS considere pertinentes. SANBORNS no es responsable del uso que medios de comunicación externos presentes en la Tienda Pop Up Stores BTS puedan dar a su imagen personal.</p>

                                <p className="pSliderL">Aunado a lo anterior, le informamos que, en caso de no oponerse, SANBORNS podrá utilizar sus datos personales para posibles contactos posteriores e informarle de lanzamiento o cambios de nuevos productos, bienes, servicios, promociones y/u ofertas de acuerdo a sus intereses, incluyendo el envío de comunicaciones a través de correos electrónicos, llamadas telefónicas, mensajes cortos y demás medios de comunicación físicos y/o electrónicos; así como para invitarle a eventos que pudieren ser de su interés.</p>
                                <p className="pSliderL">En caso de que usted no desee que sus datos personales sean tratados para las finalidades respecto de las cuales procede manifestar su negativa, desde este momento usted nos puede comunicar lo anterior al correo <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>
                                <p className="pSliderL">Sanborns podrá transferir, sin requerir de su consentimiento, los datos personales necesarios a autoridades competentes en los casos legalmente previstos así como a empresas del grupo para que éstas le puedan contactar, directa o indirectamente, para llevar a cabo actividades de promoción y/u ofrecimiento de productos y/o servicios.</p>

                                <p className="pSliderL">Para conocer cuáles son las Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común a quienes sus datos son transferidos puede consultarlo en <a href='http://www.gsanborns.com.mx/perfil-corporativo.html' target='_blank' rel="noopener noreferrer">http://www.gsanborns.com.mx/perfil-corporativo.html</a>.</p>
                                <p className="pSliderL">Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en lo sucesivo “derechos Arco”), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Protección de Datos Personales de SANBORNS a la dirección electrónica arco@sanborns.com.mx. Su petición deberá ser realizada a través del Formato de Solicitud de Derechos ARCO, el cual debe ser solicitado a la dirección de correo electrónico antes señalada. Para que el Departamento de Protección de Datos Personales de SANBORNS pueda darle seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo que es necesario que complete todos los campos indicados en el Formato y lo acompañe con copia de alguna de las identificaciones oficiales vigentes que se señalan en el mismo. Para obtener mayor información sobre los procedimientos, requisitos
                                    y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento contacte al Departamento de Protección de Datos Personales de SANBORNS en la dirección <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a>.</p>

                                <p className="pSliderL">Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la dirección arco@sanborns.com.mx. En caso de que su Solicitud sea procedente se le registrará en el listado de exclusión propio de SANBORNS. Para mayor información favor de contactar al Departamento de Protección de Datos Personales de SANBORNS.</p>
                                <p className="pSliderL">SANBORNS le notificará de cualquier cambio a su aviso de privacidad a través de una comunicación electrónica en el sitio web <Link to="/">www.sanborns.com.mx</Link>, y la posterior publicación de las modificaciones en punto de venta.</p>

                                <p className='pSliderL bold'>Aceptación de Aviso de Privacidad</p>
                                <p className="pSliderL">En caso de utilizar nuestros servicios se entenderá que autoriza el uso de sus datos personales de conformidad con lo establecido en el presente Aviso de Privacidad.</p>
                                <p className="pSliderL">Vigente desde Julio del 2020.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function AvisoPrivacidadStoreBTS() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Store BTS');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {isMobile &&
                isMobile === 'desktop'
                ? <AvisoPrivacidadStoreBTSDesktop />

                : isMobile === 'movile'
                    ? null
                    : <AvisoPrivacidadStoreBTSMobile />
            }
        </>
    )
}