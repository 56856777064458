import React from "react";
import Tecolotes from "../../../../assets/img/Boton_Tecos.webp";
import Fast from "../../../../assets/img/Boton_FastMag.webp";
import Cliki from "../../../../assets/img/Boton_Instituto_CS.webp";
import Fundacion from "../../../../assets/img/Boton_FundacionCS.webp";
import Capacitate from "../../../../assets/img/Boton_capacitate.webp";
import Heroes from "../../../../assets/img/Boton_HPLVIDA.webp";

import Responsabilidad from "../../../../assets/img/Responsabilidad_social.webp";
import SoloUnete from "../../../../assets/img/soloUnete.webp";
import Sello from "../../../../assets/img/Sello_Datos.webp";

import "./style.sass";
import { Link } from "react-router-dom";

export default function BoxLogos() {
  return (
    <>
      <div className="BoxLogos__Container">
        <a href="http://lostecolotes.com.mx/" target="_blank">
          <img src={Tecolotes} className="LogoBottom" alt="Los Tecolotes" height="45" width="100" loading="lazy" />
        </a>
        <a href="https://fast-mag.com/" target="_blank">
          <img src={Fast} className="LogoBottom" alt="Fast mag" height="45" width="100" loading="lazy" />
        </a>
        <a href="https://saluddigital.com/" target="_blank">
          <img src={Cliki} className="LogoBottom" alt="Salud digital" height="45" width="100" loading="lazy" />
        </a>
        <a href="https://fundacioncarlosslim.org/" target="_blank">
          <img
            src={Fundacion}
            className="LogoBottom"
            alt="Fundación Carlos Slim"
          height="45" width="100" loading="lazy" />
        </a>
        <a href="https://capacitateparaelempleo.org/" target="_blank">
          <img src={Capacitate} className="LogoBottom" alt="Capacítate" height="45" width="100" loading="lazy" />
        </a>
        <a href="https://heroesporlavida.org/" target="_blank">
          <img src={Heroes} className="LogoBottom" alt="Héroes por la vida" height="45" width="100" loading="lazy" />
        </a>
        <Link to="/comunicados/">
          <img
            src={Responsabilidad}
            className="LogoBottom"
            alt="logotipo Resposabilidad Social"
          height="45" width="100" loading="lazy" />
        </Link>
        <a href="http://www.solounete.mx/" target="_blank">
          <img src={SoloUnete} className="LogoBottom" alt="logotipo Solo Unete" height="45" width="100" loading="lazy" />
        </a>
        <a
          href="https://sellosdeconfianza.org.mx/MuestraCertificado.php?NUMERO_SERIE=SC_b92"
          target="_blank"
        >
          <img src={Sello} className="LogoBottom" alt="logotipo Sello de Confianza" height="45" width="100" loading="lazy" />
        </a>
      </div>
      {/* <div className="MB-10">
        <a
          href="https://distintivodigital.profeco.gob.mx/consulta.php?serie=DA21K4N3071511"
          target="_blank"
        >
          <img src={Profeco} className="LogoBottom" alt="logotipo profeco" height="45" width="100" loading="lazy" />
        </a>
      </div> */}
    </>
  );
}
