import React from 'react';
import './style.sass';

import BoxAyuda from './BoxAyuda';
import BoxLegal from './BoxLegal';
import BoxServiciosCliente from './BoxServiciosCliente';
import BoxContactanos from './BoxContactanos';
import BoxLogos from './BoxLogos';

export default function UpperFooter() {
    return (<>
        <div className="upperfooter">
            <BoxAyuda />
            <BoxLegal />
            <BoxServiciosCliente />
            <BoxContactanos />
        </div>
       <BoxLogos />
   </> )
}