import React, { useState, useEffect } from 'react'
import './style.sass'
import { useHistory } from 'react-router-dom'
import { useIsMobile } from 'hooks/useIsMobile'
import { encodeStorage, decodeStorage, removeStorage, encodeStorageLatin, decodeStorageLatin } from 'functions/storageBase64'
import { dataLayerCart, dataLayerCartRemove, dataLayerCheckout } from 'functions/dataLayerFunction'
import { funtionCartEmarsys } from 'functions/cartEmarsys'
import { BASE_PATH_CAJA } from 'constants/general'
import getInitialPayment from 'services/inicioCaja'
import { getCart } from 'services/Cart'
import putProductCart from 'services/putProductCart'
import deleteProductCart from 'services/deleteProductCart'


import Header from 'pages/Header/HeaderNavegacion'
import BarraCP from 'components/BarCP/BarHeaderCP'
import Loading from 'components/Loading'
import { CartDesktop } from 'pages/Carrito/CarritoDesktop'
import { CartMobile } from 'pages/Carrito/CarritoMobile'
import Toast from 'components/Toast'

import { useCart } from 'hooks/useCart'
import { useToken } from 'hooks/useToken';

export default function Cart() {
    const history = useHistory()
    const { isMobile } = useIsMobile()
    const [cart, setCart] = useState({})
    const byCart = decodeStorage('idCarrito')
    const isLoged = decodeStorage('session_token')
    const [rawCart, setRawCart] = useState({})
    const [loading, setLoading] = useState(true)
    const [evalPriceCart, setEvalPriceCart] = useState(false)
    const [showModalRemove, setShowModalRemove] = useState(false)
    const [updateProd, setUpdateProd] = useState(false)
    const [errorText, setErrorText] = useState('')
    const domain = window.location.host

    const [toast, setToast] = useState(false)
    const [messageToast, setMessageToast] = useState({ type: '', time: '', textMessage: '' })
    const refreshCyR = useToken({firstActive: false, name: "Cart", timeRef: 1000})

    const { 
        cartCount, 
        carrito,
        Product_Add,
        Product_Update,
        Product_Delete
      } = useCart({firstActive: true, name: "CartPage", timeRef: 1000})


    useEffect(()=>{
        if(carrito){
            setRawCart(carrito)
            setLoading(false)
        }
    },[carrito])



    const handleToast = () => {
        setToast(false)
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Cart Page');
    }, [])

    useEffect(() => {
        // getCart()
        // .then(setRawCart)
        window.scrollTo(0, 0)
        removeStorage('typeButton')
        removeStorage('wishlistAfter')
        removeStorage('productAfter')
        removeStorage('okCartProductAfter')
        removeStorage('okBuyProductAfter')
    }, [])


    // estructura la respuesta
    useEffect(() => {
        if (rawCart) {
            if (rawCart?.statusCode === 200 || rawCart?.statusCode === 201) {
                if (typeof (rawCart.data) === 'object') {
                    setCart({
                        'products': rawCart?.data?.data?.products,
                        'total_items': rawCart?.data?.data?.total_items,
                        'shipping': rawCart?.data?.data?.shipping,
                        'total_price': rawCart?.data?.data?.total_price
                    })
                }
                else {
                    setCart({})
                    setErrorText(rawCart?.data)
                }
            }
            else if (rawCart?.statusCode !== undefined) {
                setCart({})
                setLoading(false)
            }
        }
    }, [rawCart])


    // guarda los valores del carito en el storage
    useEffect(() => {
        if (cart) {
            try {
                encodeStorage('cartData', cart)
            }
            catch {
                encodeStorageLatin('cartData', cart)
            }
            encodeStorage('total_items', cart?.total_items)
        }
    }, [cart])


    useEffect(() => {
        if (cart) {
            if (Object.keys(cart)?.length > 0) {
                setLoading(false)
            }
            else if (cart === 404) {
                history.push('/')
            }
        } else if (cart === 404) {
            history.push('/')
        }
    }, [cart, history])


    //- Elimina los productos
    const [deleteUpdate, setDeleteUpdate] = useState(false)
    useEffect(() => {
        if (showModalRemove) {
            // console.log('showModalRemove: ', showModalRemove)
            Product_Delete([showModalRemove])
            // deleteProductCart(showModalRemove)
            // .then(setRawCart)
            let totalUpdateItems = decodeStorage('total_items') - 1
            encodeStorage('total_items', totalUpdateItems)
            setDeleteUpdate(totalUpdateItems)
        }
    }, [showModalRemove])

    //- dataleyer remueve item manda a remover al servicio
    const findRemoveItem = (val) => {
        if (cart) {
            if (cart?.products) {

                let catPadre, catHija, catNieta, variante

                let nombre = cart?.products.find(product => product?.id === val?.id_product)?.title
                let id = cart?.products.find(product => product?.id === val?.id_product)?.id
                let precio = cart?.products.find(product => product?.id === val?.id_product)?.sale_price
                let marca = cart?.products.find(product => product?.id === val?.id_product)?.brand
                let cantidad = cart?.products.find(product => product?.id === val?.id_product)?.cart_quantity

                if (cart?.products.find(product => product?.id === val?.id_product)?.categories) {
                    catPadre = cart.products.find(product => product?.id === val?.id_product)?.categories[2]?.name
                    catHija = cart.products.find(product => product?.id === val?.id_product)?.categories[1]?.name
                    catNieta = cart.products.find(product => product?.id === val?.id_product)?.categories[0]?.name
                } else {
                    catPadre = ""
                    catHija = ""
                    catNieta = ""
                }
                if (cart?.products.find(product => product?.id === val?.id_product)?.selected_size_color?.color) {
                    variante = cart?.products.find(product => product?.id === val?.id_product)?.selected_size_color?.color
                } else {
                    variante = ""
                }
                if (cart?.products.find(product => product?.id === val?.id_product).stock >= 1 && cart?.products.find(product => product?.id === val?.id_product).status === true) {
                    dataLayerCartRemove({
                        item: [
                            {
                                item_name: String(nombre),
                                item_id: String(id),
                                price: String(precio),
                                item_brand: String(marca),
                                item_category: String(catPadre),
                                item_category2: String(catHija),
                                item_category3: String(catNieta),
                                item_variant: String(variante),
                                quantity: String(cantidad)
                            }
                        ]
                    })
                }
                setShowModalRemove(val)
            }
        }
    }

    // manda a eliminar despues de seleccion del modal
    const modalRemove = (val) => {
        setLoading(true)
        findRemoveItem(val)
        //setShowModalRemove(val)
    }

    //- Actualiza la cantidad de productos
    useEffect(() => {
        if (updateProd) {
            // console.log('updateProd: ', updateProd)
            Product_Update([updateProd])
            
            // putProductCart(updateProd)
            // .then(setRawCart)
        }
    }, [updateProd])

    const updateProduct = (val) => {
        setUpdateProd(val)
        setLoading(true)
    }


    //detona el evento del click al boton
    const submitCart = () => setEvalPriceCart(true)

    //recibe la direccion del CP
    const [ifUpdateCP, setIfUpdateCP] = useState(false)
    const reciveDirection = (val) => {
        //console.log('Y29kaWdvUG9zdGFs', val)
        setIfUpdateCP(val)
    }
    const [upCP, setUpCP] = useState()
    useEffect(() => {
        if (upCP) {
            //console.log('upCP, ',upCP)
        }
    }, [upCP, ifUpdateCP, deleteUpdate])

    //se ejecuta cuadno tiene datos el producto para llenar el datalayer dataLayerCart
    //window.productsCarrito = cart.products
    const [dataLayerData, setDataLayerData] = useState()
    const [totalCart, setTotalCart] = useState()
    const indexCount = []
    const listArrayCart = []
    useEffect(() => {
        //console.log('productDAta-> ', productData)
        if (cart) {
            if (cart?.products) {
                // console.log('val', cart?.products)
                funtionCartEmarsys(cart?.products)
                try {
                    encodeStorage('cartFormEm', cart?.products)
                }
                catch {
                    encodeStorageLatin('cartFormEm', cart?.products)
                }

                cart.products.map(({ id, title, cart_quantity, brand, stock, status, selected_size_color, sale_price, categories }, ind) => {
                    if (stock >= 1 && status === true) {
                        indexCount.push(1)
                        if (categories) {
                            if (selected_size_color?.color) {
                                listArrayCart.push({
                                    item_name: String(title),
                                    item_id: String(id),
                                    price: String(sale_price),
                                    item_brand: String(brand),
                                    item_category: String(categories[2]?.name),
                                    item_category2: String(categories[1]?.name),
                                    item_category3: String(categories[0]?.name),
                                    item_variant: String(selected_size_color?.color),
                                    index: indexCount?.length,
                                    quantity: String(cart_quantity)
                                })
                            } else {
                                listArrayCart.push({
                                    item_name: String(title),
                                    item_id: String(id),
                                    price: String(sale_price),
                                    item_brand: String(brand),
                                    item_category: String(categories[2]?.name),
                                    item_category2: String(categories[1]?.name),
                                    item_category3: String(categories[0]?.name),
                                    index: indexCount?.length,
                                    quantity: String(cart_quantity)
                                })
                            }
                        } else {
                            if (selected_size_color.color) {
                                listArrayCart.push({
                                    item_name: String(title),
                                    item_id: String(id),
                                    price: String(sale_price),
                                    item_brand: String(brand),
                                    item_variant: String(selected_size_color?.color),
                                    item_category: "",
                                    item_category2: "",
                                    item_category3: "",
                                    index: indexCount?.length,
                                    quantity: String(cart_quantity)
                                })
                            } else {
                                listArrayCart.push({
                                    item_name: String(title),
                                    item_id: String(id),
                                    price: String(sale_price),
                                    item_brand: String(brand),
                                    item_category: "",
                                    item_category2: "",
                                    item_category3: "",
                                    index: indexCount?.length,
                                    quantity: String(cart_quantity)
                                })
                            }
                        }
                    }
                })
                setDataLayerData(listArrayCart)
                setTotalCart(cart?.total_price)
            }
        }
    }, [cart])

    //con el datalyer lleno manda los datos a analytics dataLayerCart
    useEffect(() => {
        if (dataLayerData) {
            //console.log('dataLayerCart: ',dataLayerData)
            dataLayerCart({ valueCart: totalCart, items: dataLayerData })
        }
    }, [dataLayerData, totalCart])



    // evalua que todo este Ok y manda a Caja
    const [initCaja, setInitCaja] = useState()
    useEffect(() => {
        if (evalPriceCart) {
            if (dataLayerData) {
                if (totalCart >= 1) {
                    setLoading(true)
                    // inicioCaja
                    setTimeout(function(){
                        refreshCyR.forceToken(true)
                    },300)
                    //console.log('dataLayerCheckout',{valueCart: totalCart, items: dataLayerData})
                    //document.cookie = `token_cart=${byCart};Max-Age=${60*60*24*365};Path=/;Domain=${domain};`
                    //dataLayerCheckout({valueCart: totalCart, items: dataLayerData})
                    //window.location.href = `${BASE_PATH_CAJA}`
                    document.cookie = `token_cart=${byCart};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                }
            }
        }
    }, [evalPriceCart, byCart, dataLayerData, totalCart])

    const [ref, setRef] = useState(false)
    useEffect(()=>{
        if(refreshCyR.newToken){
            if (evalPriceCart) {
                if (dataLayerData) {
                    if (totalCart >= 1) {
                        setRef(true)
                    }
                }
            }
        }
    },[refreshCyR.newToken])


    useEffect(()=>{
        if(ref){
            let time = decodeStorage("access_token")
            let timeToken = JSON.parse( window.atob( time?.split('.')[1] ) )
            setRef(false)
            if(  Date.now() >= (timeToken?.iat * 1000) ){
                getInitialPayment()
                .then(setInitCaja)
            }else{
                let tiempo = Math.floor( (timeToken?.iat * 1000) - Date.now() ) + 1000
                console.log('preTime: ', tiempo)
                setTimeout(function(){
                    getInitialPayment()
                    .then(setInitCaja)
                },tiempo)
            }
        }    
    },[ref])


    useEffect(() => {
        if (initCaja) {
            console.log('initCaja: ',initCaja)
            if (initCaja?.newStatus === 200) {
                //console.log('dataLayerCheckout',{valueCart: totalCart, items: dataLayerData})
                document.cookie = `token_cart=${byCart};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                dataLayerCheckout({ valueCart: totalCart, items: dataLayerData })
                window.location.href = `${BASE_PATH_CAJA}`
                setLoading(false)
            }
            else if (initCaja?.newStatus === 409) {
                // console.log('byCart', byCart)
                setLoading(false)
                setToast(true)
                setMessageToast({
                    type: 'warning',
                    time: 7,
                    textMessage: initCaja?.response
                })
            }
            else if (initCaja?.newStatus === 405) {
                setLoading(false)
                setToast(true)
            }
            else if (initCaja?.newStatus === 404) {
                window.location.reload()
            }
        }
    }, [initCaja, byCart])



    return (
        <>
            {loading
                ? <Loading />
                : null
            }

            <Header setUpCP={setUpCP} upHeader={deleteUpdate} changeDirectionBar={reciveDirection} headerEnCategoria={"headerCarrito"} />
            
            <section className="barCpResponsive">
                <BarraCP changeCP={reciveDirection} clase="barHeaderCP" />
            </section>

            {isMobile && isMobile === 'desktop'
                ? <CartDesktop
                    superCart={cart}
                    superRawCart={rawCart}
                    superErrorText={errorText}
                    modalRemove={modalRemove}
                    updateProduct={updateProduct}
                    reciveDirection={reciveDirection}
                    submitCart={submitCart}
                    isMobile={isMobile}
                />
                : isMobile === 'movile'
                    ? null
                    : <CartMobile
                        superCart={cart}
                        superRawCart={rawCart}
                        superErrorText={errorText}
                        modalRemove={modalRemove}
                        updateProduct={updateProduct}
                        reciveDirection={reciveDirection}
                        submitCart={submitCart}
                        isMobile={isMobile}
                    />
            }
            {toast &&
                <Toast
                    type={messageToast.type}
                    time={messageToast.time}
                    onClose={handleToast}
                >
                    {messageToast?.textMessage}
                </Toast>
            }
        </>
    )
}