import React from 'react'
import './style.sass'
import { NavLink, useHistory } from "react-router-dom"

import { HeaderMiCuenta } from 'components/Header/HeaderMiCuenta'
import { ErrorBoundary } from 'components/Error/ErrorBoundary'
import { VARIANTS_PER_PORTAL } from 'constants/general'
import { BurbleCredit } from 'components/Burble/Credit'
import ButtonBuyNow from 'components/ProductDetail/ShopProduct/ButtonsPayment/ButtonBuyNow'
import ButtonCyR from 'components/ProductDetail/ShopProduct/ButtonCyR'


export function HeaderBarMovile(props){

    const {
        isLoged,
        profile,
        cyr,
        id,
        title,
        dataImage,
        precioLista,
        discount,
        skuChildren,
        skuChildrenFF,
        store,
        stock,
        categories,
        status,
        size_color,
        colorCyR,
        tallaCyR,
        precio,
        is_store_only,
        cex,
        cantProduct,
        sizeColorSKU,
        size_colorExist,
        // tallasExiste = {size_color}
        sale_price,
        brand,
        category,
        variant,
        tallaText,
        isMobile
    } = props

    const hostName = window.location.origin;
    const history = useHistory();
    const pathName = window.location.pathname

    return(
        <>
            {pathName.includes("/producto") && isMobile === 'movil' ? 
                <div className="ocultarMenu2 menuMovilBotones">
                    {cyr || is_store_only ?
                        <ButtonCyR
                            cyr={cyr}
                            id={id}
                            title={title}
                            dataImage={dataImage}
                            precioLista={precioLista}
                            discount={discount}
                            skuChildren={skuChildren}
                            skuChildrenFF={skuChildrenFF}
                            store={store}
                            stock={stock}
                            categories={categories}
                            status={status}
                            size_color={size_color}
                            colorCyR={colorCyR}
                            tallaCyR={tallaCyR}
                            precio={precio}
                            is_store_only={is_store_only}
                            cex={cex}
                            is_menu={true}
                        />
                        : null
                    }
                    {!is_store_only &&
                        <ButtonBuyNow
                            id={id}
                            cantProduct={cantProduct}
                            sizeColorSKU={sizeColorSKU}
                            size_colorExist={size_color?.length}
                            // tallasExiste = {size_color}
                            title={title}
                            sale_price={sale_price}
                            brand={brand}
                            category={category}
                            variant={variant}
                            tallaText={tallaText}
                        />
                    } 
                </div>
                : 
                <div className="ocultarMenu2">
                    <BurbleCredit />
                    {VARIANTS_PER_PORTAL?.menuMobile.map( ({title, iconClass, url, privateUrl, exactUrl, anchor, showOnlyLogin}, index) => 
                        {
                            return(
                                <ErrorBoundary key={index}>
                                    {
                                        !showOnlyLogin || isLoged
                                            ?
                                                title !== "Registrate"
                                                    ? Boolean(anchor) !== true
                                                        ?
                                                            <NavLink
                                                                exact={exactUrl}
                                                                to={privateUrl ? isLoged ? privateUrl : `/login?redirect=${hostName}${privateUrl}` : url}
                                                                className={iconClass}
                                                                activeClassName="active"
                                                                aria-label={`Ir a menu ${title}`}
                                                                //data-url={privateUrl ? isLoged ? privateUrl : `/login?redirect=${hostName}/${privateUrl}` : url}
                                                            >
                                                                <span className="textReferenceMo">
                                                                    <span className="smallTextMo">{title}</span>
                                                                </span>
                                                            </NavLink>
                                                        :
                                                            <a
                                                                href={privateUrl ? privateUrl : url}
                                                                className={pathName === privateUrl || pathName === url ? `${iconClass} active` : iconClass}
                                                                aria-label={`Ir a menu ${title}`}
                                                            >
                                                                <span className="textReferenceMo">
                                                                    <span className="smallTextMo">{title}</span>
                                                                </span>
                                                            </a>
                                                    : <HeaderMiCuenta
                                                        exactUrl={exactUrl}
                                                        ruta={privateUrl ? isLoged ? privateUrl : `/login?redirect=${hostName}${privateUrl}` : url}
                                                        className={iconClass}
                                                        anchor={anchor}
                                                        showOnlyLogin={showOnlyLogin}
                                                        title={title}
                                                        profile={profile}
                                                    />
                                            : null
                                    }
                                </ErrorBoundary>
                            )}
                        )
                    }
                </div>
            }
        </>
    )
}