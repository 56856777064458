import React, {useEffect} from 'react';
import './style.sass';
import {Helmet} from "react-helmet";
import { HeaderHidden } from 'pages/Header/HeaderSimple';
import {SearchHeaderMobile} from 'components/SearchHeader/searchHeaderMobile';

export default function SearchAutocomplete (props) {

    //- Codigo de instana para track de pagina 
    useEffect(()=>{
        window.ineum('page', 'Search Page');
        window.scrollTo(0, 0);
    },[])

    return(
        <>
            <Helmet>
                <title>Solo Sanborns</title>
                <link rel="canonical" href="https://www.sanborns.com.mx/buscador" />
                <meta name="Description" content="SANBORNS.COM.MX | Envíos a todo México · Más de 500 mil productos con todas las Formas de Pago  Compras 100% Seguras · Lo mejor en Moda, Línea blanca, Pantallas, Computo, Celulares, Juguetes, Muebles, Ferretería y más · Click & Recoge en Tienda" />
            </Helmet>
            <HeaderHidden />
            <main>
                <section>
                    <SearchHeaderMobile/>
                </section>
            </main>
        </>
    )
}