import React, { useRef, useState } from "react";
import {
    FileUploadContainer,
    FormField,
    DragDropText,
    UploadFileBtn,
    FilePreviewContainer,
    ImagePreview,
    PreviewContainer,
    PreviewList,
    RemoveFileIcon,
    InputLabel
} from "./file-upload.styles";

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 10485760; //5242880//

const convertNestedObjectToArray = (nestedObj) =>
    Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

export const DrapAndDrop = ({
    desactivar,
    label,
    updateFilesCb,
    maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    ...otherProps
}) => {
    const fileInputField = useRef(null);
    const [files, setFiles] = useState({});

    const handleUploadBtnClick = () => {
        fileInputField.current.click();
    };

    const [error, setError] = useState(false);
    const [tamanio, setTamanio] = useState(false);
    const addNewFiles = (newFiles) => {
        for (let file of newFiles) {
            if (file.type.includes("jpg") || file.type.includes("jpeg") || file.type.includes("png")) {
                if (file.size <= maxFileSizeInBytes) {
                    if (!otherProps.multiple) {
                        return { file };
                    }
                    files[file.name] = file;
                }
                else{
                    setTamanio(true);
                }
            }
            else {
                setError(true);
            }
        }
        return { ...files };
    };

    const callUpdateFilesCb = (files) => {
        const filesAsArray = convertNestedObjectToArray(files);
        updateFilesCb(filesAsArray);
    };

    const handleNewFileUpload = (e) => {
        const { files: newFiles } = e.target;

        if (newFiles.length) {
            setError(false);
            setTamanio(false);
            let updatedFiles = addNewFiles(newFiles);
            setFiles(updatedFiles);
            callUpdateFilesCb(updatedFiles);
        }
    };

    const removeFile = (fileName) => {
        delete files[fileName];
        setFiles({ ...files });
        callUpdateFilesCb({ ...files });
    };

    return (
        <>
            {error && <div className="mensajeError"><p>La extensión del archivo no es válida.</p></div>}
            {tamanio && <div className="mensajeError"><p>El tamaño del archivo supera el límite permitido.</p></div>}

            <FilePreviewContainer>
                <PreviewList>
                    {Object.keys(files).map((fileName, index) => {
                        let file = files[fileName];
                        let isImageFile = file.type.split("/")[0] === "image";
                        return (
                            <PreviewContainer key={fileName}>
                                <div>
                                    {isImageFile && (
                                        <>
                                            <RemoveFileIcon onClick={() => removeFile(fileName)} />
                                            <ImagePreview src={URL.createObjectURL(file)} alt={`preview ${index}`} />
                                        </>
                                    )}
                                </div>
                            </PreviewContainer>
                        );
                    })}
                </PreviewList>
            </FilePreviewContainer>

            <FileUploadContainer>
                <InputLabel>{label}</InputLabel>
                <DragDropText>Arrastra aquí tus fotos o</DragDropText>
                <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
                    <i className="fas fa-file-upload" />
                    <span> Adjuntar</span>
                </UploadFileBtn>
                <FormField
                    type="file"
                    ref={fileInputField}
                    onChange={handleNewFileUpload}
                    title=""
                    value=""
                    disabled={desactivar}
                    {...otherProps}
                />
            </FileUploadContainer>

        </>
    );
};
