import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import MenuPrincipal from 'components/Menu';
import CartHeaderCount from 'components/Cart/CartHeaderCount';
import HeadeWishlist from 'components/CardProduct/CardWishlist/headerWishlist';
import { useStorage64 } from 'hooks/useStorageBase64';
import { ErrorBoundary } from 'components/Error/ErrorBoundary';
import { HeaderGenerico, HeaderMiCuentaSears } from 'components/Header/HeaderGenerico/headerGenerico';
import { HeaderWishlist } from 'components/Header/HeaderWishlist';
import { useHistory } from 'react-router-dom';
import { VARIANTS_PER_PORTAL } from 'constants/general'

import { HeaderBarMovile } from 'pages/Header/HeaderNavegacion/HeaderBarMovile'

export function HeaderMobile(props) {
    const {
        isLoged,
        profile,
        totalItems,
        page = null,
        headerEnCategoria,
        categoriaRecibida,
        categoriaPadre,
        tituloPagina,
        searchWork,
        claseMas
    } = props

    const history = useHistory();
    const [showModal, SetShowModal] = useState(false);
    const [dataFace] = useStorage64('dataFacebook', '');
    const [nombre0, setnombre0] = useState("");
    const hostName = window.location.origin;
    const urlLink = window.location.href;

    //- Funciones para activar o desactivar el modal del menu lateral
    const showMenu = () => {
        // SetShowModal(true);
        history.push("/menu-mas");
    }
    const handleClose = () => { SetShowModal(false); }

    let nombreArray = [];
    useEffect(() => {
        if (profile.nombre) {
            nombreArray = profile.nombre.split(' ');
            setnombre0(nombreArray[0]);
        }
    }, [profile])

    useEffect(() => {
        if (isLoged && dataFace.name) {
            nombreArray = dataFace.name.split(' ');
            setnombre0(nombreArray[0]);
        }
    }, [dataFace])

    const handleBack = () => {
        history.goBack();
    };

    const RegresarCategoria = () => {
        history.goBack();
    };

    const [namec, setName] = useState(false);
    useEffect(() => {
        if (categoriaRecibida) {
            setName(categoriaRecibida);
        }
    }, [categoriaRecibida])

    const [namep, setNamep] = useState(false);
    useEffect(() => {
        if (categoriaPadre) {
            setNamep(categoriaPadre);
        }
    }, [categoriaPadre])

    return (
        <>
            <>
                {(headerEnCategoria && (headerEnCategoria === "headerEnCategoriaDepartamentos" || headerEnCategoria === "headerEnCategoria" || headerEnCategoria === "headerEnCategoriaHija" || headerEnCategoria === "headerEnCategoriaNieta" || headerEnCategoria === "headerCarrito"))
                    ? <header className={page + " paddingM"}>
                        <div className={headerEnCategoria === "headerEnCategoriaNieta" || headerEnCategoria === "headerCarrito" ? "headerMobile hcateNieta" : "headerMobile hcate"}>
                            <div className="regresarPadre" onClick={RegresarCategoria}></div>
                            {headerEnCategoria === "headerEnCategoria"
                                ? <div className="headerTituloCategoria">
                                    <p>Categorías</p>
                                </div>
                                : headerEnCategoria === "headerEnCategoriaHija"
                                    ? <div className="headerTituloCategoriaHija">
                                        {namep
                                            ? <p>{namep}</p>
                                            : <p>Categorías</p>
                                        }
                                    </div>
                                    : headerEnCategoria === "headerEnCategoriaNieta"
                                        ? <div className="headerTituloCategoriaHija">
                                            {namec
                                                ? <p>{namec}</p>
                                                : <p>Categorías</p>
                                            }
                                        </div>
                                        : headerEnCategoria === "headerEnCategoriaDepartamentos"
                                            ? <div className="headerTituloCategoria">
                                                <p>Categorías</p>
                                            </div>
                                            : headerEnCategoria === "headerCarrito"
                                            && <>
                                                <div className="headerTituloCarrito">
                                                    <p>Mi bolsa</p>
                                                </div>
                                                <div style={{ width: "14%" }}></div>
                                            </>

                            }

                            {/* <ErrorBoundary>
                                <CartHeaderCount
                                    ruta={isLoged ? '/carrito/detalle' : `/login?redirect=${hostName}/carrito/detalle`} cantidad={isLoged ? totalItems : 0}
                                />
                            </ErrorBoundary> */}
                            <div></div>

                            <div className="menuPrincipal">
                                <HeaderBarMovile isLoged={isLoged} profile={profile} />
                            </div>

                        </div>
                    </header>
                    : (headerEnCategoria && headerEnCategoria === "headerLocalizador")
                        ? <header className={page}>
                            <div className="headerMobile">

                                <div className="regresarPadre" onClick={RegresarCategoria}></div>
                                <div className="headerTituloCarrito">
                                    <p>Localizador de Tiendas</p>
                                </div>

                                <div className="contBolsaLista">
                                    <ErrorBoundary>
                                        <HeaderWishlist
                                            ruta={isLoged ? '/mi-cuenta/listadeseos/' : `/login?redirect=${hostName}/mi-cuenta/listadeseos/`}
                                        />
                                    </ErrorBoundary>
                                    <ErrorBoundary>
                                        <CartHeaderCount
                                            ruta={isLoged ? '/carrito/detalle' : `/login?redirect=${hostName}/carrito/detalle`}
                                            cantidad={isLoged ? totalItems : 0}
                                        />
                                    </ErrorBoundary>
                                </div>

                                <div className="menuPrincipal menuMlocalizador">
                                    <HeaderBarMovile isLoged={isLoged} profile={profile} />
                                </div>
                            </div>
                        </header>
                        : (headerEnCategoria && headerEnCategoria === "headerListaDeseos")
                            ? <header className={page}>
                                <div className="headerMobile hmicuenta">

                                    <div className="regresarPadre" onClick={RegresarCategoria}></div>
                                    <div className="headerTituloCarrito">
                                        <p>{tituloPagina}</p>
                                    </div>

                                    <div className="contBolsaLista">
                                        {!history.location.pathname.includes("/direcciones-envio") &&
                                            !history.location.pathname.includes("/mis-formas-pago") &&
                                            !history.location.pathname.includes("/mi-perfil") &&
                                            String(history.location.pathname) !== "/mi-cuenta" &&
                                            !history.location.pathname.includes("/mis-pedidos")
                                            ? <>
                                                <ErrorBoundary>
                                                    {page !== "pageHome" &&
                                                        <>
                                                            <Link to="/buscador" className="searchIcon" aria-label="Icono Buscador" title="Icono Buscador">.</Link>
                                                        </>
                                                    }
                                                </ErrorBoundary>
                                                <ErrorBoundary>
                                                    <CartHeaderCount
                                                        ruta={isLoged ? '/carrito/detalle' : `/login?redirect=${hostName}/carrito/detalle`}
                                                        cantidad={isLoged ? totalItems : 0}
                                                    />
                                                </ErrorBoundary>
                                            </>
                                            : <div style={{ width: "30px" }}></div>
                                        }
                                    </div>

                                    <div className="menuPrincipal">
                                        <HeaderBarMovile isLoged={isLoged} profile={profile} />
                                    </div>
                                </div>
                            </header>
                            : (headerEnCategoria && headerEnCategoria === "headerBuscador")
                                ? <header className={page}>
                                    <div className="headerMobile">

                                        <div className="buscadorMo">
                                            <Link className="fakeSearch fakeBuscador"  aria-label="Buscador" to="/buscador">
                                                {searchWork
                                                    ? <span><p className="textoS">{decodeURI(searchWork)}</p></span>
                                                    : <span>Buscar</span>
                                                }
                                            </Link>
                                        </div>
                                        <div className="contBolsaLista">
                                            <ErrorBoundary>
                                                <HeaderWishlist
                                                    ruta={isLoged ? '/mi-cuenta/listadeseos/' : `/login?redirect=${hostName}/mi-cuenta/listadeseos/`}
                                                />
                                            </ErrorBoundary>
                                            <ErrorBoundary>
                                                <CartHeaderCount
                                                    ruta={isLoged ? '/carrito/detalle' : `/login?redirect=${hostName}/carrito/detalle`} cantidad={isLoged ? totalItems : 0}
                                                />
                                            </ErrorBoundary>
                                        </div>

                                        <div className="menuPrincipal">
                                            <HeaderBarMovile isLoged={isLoged} profile={profile} />
                                        </div>

                                    </div>
                                </header>
                                : (headerEnCategoria && headerEnCategoria === "modalAddCart")
                                    ? <>
                                        <header className={page}>
                                            <div className="headerMobile haddcart">
                                                <div className="hl">
                                                    <div className="regresarPadre" onClick={RegresarCategoria}></div>
                                                </div>

                                                <div className="hr">
                                                    <Link to="/buscador" className="searchIcon" aria-label="Icono Buscador" title="Icono Buscador">.</Link>

                                                    <ErrorBoundary>
                                                        <HeaderWishlist
                                                            ruta={isLoged ? '/mi-cuenta/listadeseos/' : `/login?redirect=${hostName}/mi-cuenta/listadeseos/`}
                                                        />
                                                    </ErrorBoundary>
                                                </div>
                                            </div>
                                        </header>
                                    </>
                                    : <header className={page}>
                                        <div className="headerMobile">

                                            <ErrorBoundary>
                                                {page === "pageHome"
                                                    ? <>
                                                        <div className="bxVacio">
                                                            <a href="/" className="logoClaroshop" aria-label="logoSanborns">.</a>
                                                        </div>
                                                    </>
                                                    : <>
                                                        <div className="bxarrowLeft" >
                                                            {/* <p className="arrowLeft"></p> */}
                                                            {claseMas
                                                                ? <a href="/" className="logoClaroshop logoClaroshopMas" aria-label="logoSanborns">.</a>
                                                                : <a href="/" className="logoClaroshop" aria-label="logoSanborns">.</a>
                                                            }
                                                        </div>
                                                        {/* onClick={handleBack} */}
                                                    </>
                                                }

                                                {(page !== "pageHome" && !claseMas) &&
                                                    <>
                                                        <Link to="/buscador" className="searchIcon" aria-label="Icono Buscador" title="Icono Buscador">.</Link>
                                                    </>
                                                }
                                            </ErrorBoundary>

                                            {/* <ErrorBoundary>
                                                        {page === "pageHome" &&
                                                            <HeadeWishlist ruta={isLoged ? '/mi-cuenta/listadeseos/' : `/login?redirect=${hostName}/mi-cuenta/listadeseos/`} />
                                                        }
                                                    </ErrorBoundary> */}
                                            {!claseMas &&
                                                <ErrorBoundary>
                                                    <HeaderWishlist
                                                        ruta={isLoged ? '/mi-cuenta/listadeseos/' : `/login?redirect=${hostName}/mi-cuenta/listadeseos/`}
                                                    />
                                                </ErrorBoundary>
                                            }
                                            <ErrorBoundary>
                                                <CartHeaderCount
                                                    ruta={isLoged ? '/carrito/detalle' : `/login?redirect=${hostName}/carrito/detalle`}
                                                    cantidad={isLoged ? totalItems : 0}
                                                />
                                            </ErrorBoundary>

                                            <div className="menuPrincipal">
                                                <HeaderBarMovile isLoged={isLoged} profile={profile} />
                                            </div>

                                        </div>
                                    </header>
                }
            </>

            {((headerEnCategoria && headerEnCategoria === "headerEnCategoriaNieta")) &&
                <Link className="fakeSearch searchCate" to="/buscador"  aria-label="Buscador">
                    <span>Buscar</span>
                </Link>
            }
            {(claseMas) &&
                <Link className="fakeSearch" to="/buscador"  aria-label="Buscador">
                    <span>¿Qué es lo que buscas?<div className="contenedorLogo"><span className="logoBuscador"></span></div></span>
                </Link>
            }

            {showModal && <MenuPrincipal onClose={handleClose} />}
        </>
    )
}