import React, { useEffect,useState } from 'react'
import { SSO } from 'constants/general'
import { useIsMobile } from 'hooks/useIsMobile'
import { useRegistro } from 'hooks/useLogin'
import { useHistory } from 'react-router-dom';
import './style.sass'

import Header from 'pages/Header/HeaderSimple'
import ErrorType from 'pages/Error/ErrorType'

import RegistroForm from 'components/LoginRegistro/Registro'
import Loading from 'components/Loading'
import { ErrorBoundary } from 'components/Error/ErrorBoundary'

import { decodeStorage, encodeStorage, removeStorage } from 'functions/storageBase64';
import {SHA1} from 'functions/generateSHA1'

export default function Registro() {

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Register Page');
        window.scrollTo(0, 0);
    }, [])

    const { isMobile } = useIsMobile();

    useEffect(() => {
        if (isMobile) {
            if (isMobile === 'desktop') {
                document.querySelector('#root').classList.add('desktopView')
            }
            else{
                document.querySelector('#root').classList.add('loginHeaderMobile')
            }
        }
    }, [isMobile])


    return(
        <>
            <ErrorBoundary>
                <Header pageClass={isMobile !== 'desktop' ? "hidden" : "registroHeader"}/>
            </ErrorBoundary>

            {SSO
                ? SSO.active
                    ? <RegistroSSO />
                    : <RegistroOld isMobile={isMobile} />
                : <RegistroOld isMobile={isMobile} />
            }
        </>
    )
}


export function RegistroSSO() {

    const isLoged = decodeStorage('session_token');
    const dateActual = new Date()
    const rand = Math.random()
    const [loading, setLoading] = useState(true)

    const redirURL =  encodeURIComponent(`${window.location.origin}/authenticated`)
    const redirectURL = encodeURIComponent( window.location.href.split('?redirect=')[1] )

    useEffect(() => {
        
        if(redirectURL != "undefined" && redirectURL != null){
            encodeStorage('redirectURL', redirectURL)
        }else{
            removeStorage('redirectURL')
        }

        if(!isLoged){
            let state = SHA1(rand+'|'+dateActual)
            let nonce = SHA1((rand * 2) +'|'+dateActual)
            encodeStorage('stateSSO', state)
            encodeStorage('nonceSSO', nonce )
            // window.location.href = `${SSO.ssoURL}/auth/realms/${SSO.realmSSO}/protocol/openid-connect/registrations?client_id=${SSO.clientSSO}&redirect_uri=${redirURL}&state=${state}&response_mode=fragment&response_type=code&scope=openid&nonce=${nonce}`
            window.location.replace(`${SSO.ssoURL}/auth/realms/${SSO.realmSSO}/protocol/openid-connect/registrations?client_id=${SSO.clientSSO}&redirect_uri=${redirURL}&state=${state}&response_mode=fragment&response_type=code&scope=openid&nonce=${nonce}`)
        }else{
            let redirectURL = decodeStorage('redirectURL')
            if(redirectURL){
                window.location.href = decodeURIComponent( redirectURL )
            }else{
                window.location.href = window.location.origin
            }
        }

    },[])

    return(
        <>
            {loading && <Loading />}
            <main>
                <p></p>
            </main>
        </>
        
    )

}




export function RegistroOld(props) {
    const {
        isMobile
    } = props


    const history = useHistory()
    const backHistory = () => {
        history.go(-1)
    }

    const {
        dataRegistro,
        reciveDataRegistro,
        reciveDataRegistroFacebook
    } = useRegistro()

    return (
        <>
            { dataRegistro.loading && <Loading />}
            <main>
                {dataRegistro.statusCode !== 500
                    ?
                        <>
                            {!dataRegistro.isLoged &&
                                <section className="loginRegistroPage">
                                    <div className="container">
                                        {isMobile === 'desktop'
                                            ?
                                                <h1 className="titleRegistro">Crear cuenta</h1>
                                            :
                                                <div className="sectionTitleServicioClienteLogo">
                                                    {/* <button onClick={backHistory}>Crear cuenta</button> */}
                                                    <img src="/img/logo-sears.svg" alt="logo-sears"></img>
                                                </div>
                                        }
                                        <ErrorBoundary>
                                            <RegistroForm
                                                dataForm={reciveDataRegistro}
                                                warningMessage={dataRegistro.errorMessage}
                                                urlRedirect={dataRegistro.redir}
                                                responseFacebook={reciveDataRegistroFacebook}
                                                isMobile={isMobile}
                                            />
                                        </ErrorBoundary>
                                    </div>
                                </section>
                            }
                        </>
                    :
                        <>
                            <ErrorBoundary>
                                <ErrorType
                                    codeStatus={500}
                                    errorText={""}
                                />
                            </ErrorBoundary>
                        </>
                }
            </main>
        </>
    )
}