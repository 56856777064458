import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuAutocenter } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory } from 'react-router-dom';
import Loading from 'components/Loading';
import { useServices } from 'hooks/useServices';

export function SitioDeInteresDesktop({ data, loading }) {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Sitios de Interes');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            {loading && <Loading />}

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuAutocenter />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Sitios de interés</p>
                            </div>
                        </section>

                        <div className="contentFormasP">
                            <div className="contSitiosInteres">
                                {data &&
                                    data.map(({ link, img, id }) =>
                                        <a key={id} href={link} target="_blank" rel="noopener noreferrer" >
                                            <img src={img} alt={"logo" + id} />
                                        </a>
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}

export function SitioDeInteresMobile({ data, loading }) {
    const history = useHistory();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Sitios de Interes');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const onClose = () => {
        history.go(-1);
    };

    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN">
                        <div className="btn closeBack" onClick={onClose} ></div>
                        <p className="title">Sitios de interés</p>
                    </div>
                    <div className="dataModal">

                        <div className="boxAN">

                            <div className="bxSlider">
                                <div className="contSitiosInteres">
                                    {data &&
                                        data.map(({ link, img, id }) =>
                                            <a key={id} href={link} target="_blank" rel="noopener noreferrer">
                                                <img src={img} alt={"logo" + id}/>
                                            </a>
                                        )
                                    }
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export function SitioDeInteres() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Sitios de Interes');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const {
        headers,
        statusCode,
        data,
        error,
        loading
    } = useServices({
        method: 'get',
        url: `/staticJson/sitiosDeInteres.json`,
        timeout: 25000,
        withCredentials: false,
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        }
    })

    const [dataJson, setDataJson] = useState(false);

    useEffect(() => {
        if (statusCode === 200) {
            if (data) {
                setDataJson(data);
            }
        }
    }, [statusCode, data])

    return (
        <>
            {isMobile &&
                isMobile === 'desktop'
                ? <SitioDeInteresDesktop data={data} loading={loading} />

                : isMobile === 'movile'
                    ? null
                    : <SitioDeInteresMobile data={data} loading={loading} />
            }
        </>
    )
}