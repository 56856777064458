import React, { useState, useEffect } from 'react';
import './desktop.sass';
import { Helmet } from "react-helmet";
import { useHistory, Link, Redirect, useParams } from 'react-router-dom';
import { useStorage64 } from 'hooks/useStorageBase64';
import { useFilterVerification } from 'hooks/useFilterVerification';
import { useIsMobile } from 'hooks/useIsMobile';
import { urlGetFilterFunction } from 'functions/urlGetFilterFunction';
import { removeStorage, decodeStorage, encodeStorage } from 'functions/storageBase64';
import getSearch from 'services/getSearch';
import { ENVIO_GRATIS, BASE_URL_PORTAL } from 'constants/general';

import Header from 'pages/Header/HeaderNavegacion';
import Footer from 'pages/Footer/FooterDesktop';
import Loading from 'components/Loading';
import ErrorSearchServices from 'components/Error/ErrorSearch';
import CardProduct from 'components/CardProduct/ClasicCard';
import Filtros from '../../../components/filtros/filtros';
import Paginador from '../../../components/Paginator';
import BarraCP from 'components/BarCP/BarHeaderCP';
import BarraResultados from 'components/filtrosDesktop/barraResultados';
import FiltrosDeskLogica from 'components/filtrosDesktop/filtrosDesk';
import { WhatsBuscador } from 'components/ProductDetail/SirenaWhatsApp'
import { anteaterProductAdapter } from 'adapters/products_Adapter';

export default function ResultsSearch(props) {

    const [scrollY, setScrollY] = useState()
    const [activeScroll, setActiveScroll] = useState(false)
    useEffect(() => {
        let searchBusqueda = decodeStorage('searchBusqueda')
        if(searchBusqueda >= 1){
            setScrollY(searchBusqueda)
            setActiveScroll(true)
        }else{
            setScrollY(0)
            setActiveScroll(true)
        }
    }, [])

    const paramSearch = useParams()

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Search Result Page');
        // window.scrollTo(0, 0);

        removeStorage('wishlistAfter');
        removeStorage('productAfter');
        removeStorage('typeButton');
    }, [])

    useEffect(() => {
        if(activeScroll){
            if(!loading){
                window.scrollTo(0, scrollY);
                setActiveScroll(false)
            }
        }
    }, [activeScroll])

    const [vista, setVista] = useState(false);
    const [ordenS, setOrdenS] = useState(1);

    const obtenerOrdenSeleccionado = (orden) => {
        setOrdenS(orden);
    }

    const seEnviaVista = (vistaS) => {
        setVista(vistaS);
    }

    const seEnviaVistaDesktop = (vistaL, vistaM) => {

        if (vistaL === true) {
            setVista(true);
        }
        if (vistaM === true) {
            setVista(false);
        }
    }

    const history = useHistory();
    const { isMobile } = useIsMobile();
    const params = props.location.pathname;
    const [searchResults, setSearchResults] = useState([]);
    const [searchParams, setSearchParams] = useState({
        busqueda: null,
        idtienda: null,
        subCategory: null,
        marca: null,
        precioMenor: null,
        precioMayor: null,
        categoria: null,
        start: null,
        shipping: null,
        fulfillment: null,
        discount: null,
        page: null,
        order: null
    });

    const filters = urlGetFilterFunction();
    const [paginacion, setPaginacion] = useState(0);
    const [loading, setLoading] = useState(true);
    // var regex = /(\d+)/g;
    const arrayProductos = [];

    // actualizar parametros
    useEffect(() => { updateParams(); }, [params])

    // actualizar parametros de la url y recordar campos al actualizar la pagina
    const updateParams = () => {
        setSearchParams({
            busqueda: filters.busqueda,
            subCategory: filters.subCategory,
            idtienda: filters.idtienda,
            marca: filters.marca,
            precioMenor: filters.precioMenor,
            precioMayor: filters.precioMayor,
            categoria: filters.categoria,
            page: filters.page,
            order: filters.order,
            start: filters.start,
            shipping: filters.shipping,
            fulfillment: filters.fulfillment,
            discount: filters.discount
        });
    }

    // getSearch
    useEffect(() => {
        if (searchParams?.busqueda !== null) {
            getSearch({
                searchText: encodeURIComponent(searchParams?.busqueda), brand: searchParams.marca,
                lowerPrice: searchParams.precioMenor, higherPrice: searchParams.precioMayor, id_Category: searchParams.categoria,
                order: searchParams.order, pagination: searchParams.page, start: searchParams.start, shipping: searchParams.shipping,
                fulfillment: searchParams.fulfillment, discount: searchParams.discount
            })
                .then(setSearchResults)
        }
        else {
            return
        }
        //MENOR A MAYOR: A:Y --- MAYOR A MENOR: D:Y
    }, [searchParams])

    // ---------ONEBOX
    const [tieneOneBox, setTieneOneBox] = useState('');
    const [oneBoxReplace, setOneBoxReplace] = useState('');

    useEffect(() => {
        if (searchResults) {
            if (searchResults.searchResult) {
                if (searchResults.searchResult.GSP.ENTOBRESULTS) {
                    if (searchResults.searchResult.GSP.ENTOBRESULTS.OBRES) {
                        if (searchResults.searchResult.GSP.ENTOBRESULTS.OBRES[0]) {
                            if (searchResults.searchResult.GSP.ENTOBRESULTS.OBRES[0].MODULE_REDIRECT) {
                                setTieneOneBox(searchResults.searchResult.GSP.ENTOBRESULTS.OBRES[0].MODULE_REDIRECT.U);
                            }
                        }
                        else if (searchResults.searchResult.GSP.ENTOBRESULTS.OBRES.MODULE_REDIRECT) {
                            setTieneOneBox(searchResults.searchResult.GSP.ENTOBRESULTS.OBRES.MODULE_REDIRECT.U);
                        }
                    }
                }
            }
        }
    }, [searchResults])

    useEffect(() => {
        if (tieneOneBox.length > 0) {
            // console.log("----------------->", tieneOneBox.includes(BASE_URL_PORTAL),BASE_URL_PORTAL)
            if (tieneOneBox.includes(BASE_URL_PORTAL)) {
                let textoconReplace = tieneOneBox.replace(BASE_URL_PORTAL, '');

                if (window.location.pathname.split('/')[1] === "resultados") {
                    if (window.location.pathname.split('/')[2] !== textoconReplace.split('/')[2]) {
                        setOneBoxReplace(textoconReplace);
                        if (tieneOneBox.replace(BASE_URL_PORTAL, '').split('/')[1] === "tienda") {
                            if (tieneOneBox.replace(BASE_URL_PORTAL, '').split('/')[3]) {
                                setOneBoxReplace(textoconReplace);
                            } else {
                                setOneBoxReplace(textoconReplace + "/");
                            }
                        }
                    }
                    else{
                        if(textoconReplace.split('/')[2] !== decodeStorage('lastWorkSearch')  ){
                            encodeStorage('lastWorkSearch', textoconReplace.split('/')[2] )
                            setOneBoxReplace(textoconReplace);
                        }else{
                        }
                    }
                }
                else {
                    setOneBoxReplace(textoconReplace);
                }
            }
            else {
                setOneBoxReplace(tieneOneBox);
            }
        }
    }, [tieneOneBox])

    function redireccionInterna(urlRedirigir) {
        history.replace(urlRedirigir);
        window.location.reload();
        // console.log("redireccion interna--> ", urlRedirigir)
    }
    function redireccionExterna(urlRedirigir) {
        window.location.replace(urlRedirigir);
        // console.log("redireccion extenrna--> ", urlRedirigir)
    }

    // mostrar loading
    useEffect(() => {
        if (searchResults) {
            if (Object.keys(searchResults).length > 0) {
                setTimeout(function () {
                    setLoading(false);
                    // window.scrollTo(0, 0);
                }, 500)
            }
        }
    }, [searchResults])

    const url_Per_Pagination = params.replace('/pagina=' + searchParams.page, '');
    const cadena = url_Per_Pagination.split("pagina=").pop();

    // funciones para la paginacion
    useEffect(() => {
        if ((Number(cadena) < 1)) {//if ((Number(params.match(regex)) < 1)) {
            setSearchParams({
                ...searchParams,
                page: 1
            });
        }

        if (Number(cadena) > 100) {
            setSearchParams({
                ...searchParams,
                page: 100
            });
        }
    }, [params])

    // hook para saber si los resultados contienen el filtro: fulfillment, review, envioGratis
    const variablesFiltros = useFilterVerification(searchResults)

    if ((Number(cadena) < 1)) {
        return <Redirect to={`pagina=1`} />
    }

    if (Number(cadena) > 100) {
        return <Redirect to="pagina=1" />
    }

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const reciveDirection = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    const [buscaste, setBuscaste] = useStorage64('storageBuscaste', '');
    const [sugerencia, setSugerencia] = useStorage64('storageSugerencia', '');
    const [search, setSearch] = useState(false);
    const [suggestions, setSuggestions] = useState(false);
    const [mostrarSinResultados, setMostrarSinResultados] = useState(false);
    const [BanderaResultado, setBanderaResultado] = useState(false);

    // se verifica si se tuvo busqueda con resultados o sin resultados
    useEffect(() => {
        if (searchResults) {
            if (searchResults.searchResult) {
                // sin resultados
                if (Number(searchResults.searchResult.GSP.RES.M) === 0) {
                    // tiene sugerencias
                    if (searchResults.searchResult.GSP.Spelling && searchResults.searchResult.GSP.Spelling.Suggestion.V) {
                        setBuscaste(searchResults.searchResult.GSP.Q);
                        setSugerencia(searchResults.searchResult.GSP.Spelling.Suggestion.V);
                        setMostrarSinResultados(false);
                        quisisteDecir(searchResults.searchResult.GSP.Spelling.Suggestion.V);
                        setBanderaResultado(true);
                    }
                    // no tiene sugerencias
                    else {
                        removeStorage('storageBuscaste');
                        removeStorage('storageSugerencia');
                        setMostrarSinResultados(true);
                        setSearch(false);
                        setSuggestions(false);
                    }
                }
                // tiene resultados
                else if (Number(searchResults.searchResult.GSP.RES.M) !== 0) {
                    if (buscaste.length > 0 && sugerencia.length > 0) {
                        if ((filters.busqueda !== sugerencia && filters.busqueda !== buscaste)) {
                            setSearch(false);
                            setSuggestions(false);
                            removeStorage('storageBuscaste');
                            removeStorage('storageSugerencia');
                        }
                        else {
                            setSearch(buscaste);
                            setSuggestions(sugerencia);
                            removeStorage('storageBuscaste');
                            removeStorage('storageSugerencia');
                        }
                    }
                    else {
                        setSearch(false);
                        setSuggestions(false);
                    }
                }
            }
        }
    }, [searchResults, filters.busqueda, searchParams?.busqueda])
    
    // const [palabraBus, setPalabraBus] = useState();
    // useEffect(()=>{
    //     if(searchParams?.busqueda){
    //         console.log('1....', searchParams)
    //         console.log('2....', searchParams?.busqueda)
    //         console.log('3....', decodeURIComponent(searchParams?.busqueda) )
    //         setPalabraBus(searchParams?.busqueda)
    //     }
    // },[searchParams])

    // useEffect(()=>{
    //     if(/iPhone|iPad|iPod/i.test(navigator.userAgent)){
    //         console.log('iphone :S', window.location.pathname.split('/')[2].replace('q=','') )
    //         console.log('a: ', window.location.pathname)
    //         console.log('a: ', decodeURIComponent(window.location.pathname) )
    //         console.log('a: ', decodeURI(window.location.pathname) )
    //     }
    //     if(palabraBus){
    //         console.log('4....', palabraBus)
    //         console.log('5....', decodeURIComponent(palabraBus) )
    //         console.log('6....', decodeURI(palabraBus) )
    //         console.log('7....', encodeURI(palabraBus) )
    //     }
    // },[palabraBus])

    // se verifica si se tiene sugerencia y palabra buscada
    useEffect(() => {
        if (sugerencia.length > 0 && buscaste.length > 0) {
            setSearch(buscaste);
            setSuggestions(sugerencia);
        }
        else {
            setSearch(false);
            setSuggestions(false);
        }
    }, [buscaste, sugerencia])

    // se realiza redireccion con la sugerencia
    function quisisteDecir(sugerencia) {
        let urlSugerencia = `/resultados/q=${sugerencia}/pagina=1`;
        history.replace(urlSugerencia);
        // window.location.reload();
    }

    // console.log(" one box replace -------> ", oneBoxReplace)

    let structData = `{
        "@context":"https://schema.org",
        "@graph":[
            {
                "@type":"SearchResultsPage",
                "id":"${searchParams?.busqueda}",
                "name":"${searchParams?.busqueda}",
                "about":"Si lo que buscas es ${searchParams?.busqueda} compra con tu recibo Telmex, encuentra productos 100% originales y realiza tu compra segura. Envío gratis a partir de $${ENVIO_GRATIS}.00 "
            }
        ]
    }`

    return (
        tieneOneBox && oneBoxReplace.length > 0
            ? oneBoxReplace.includes("https://www")
                ? redireccionExterna(oneBoxReplace)
                : redireccionInterna(oneBoxReplace)
            :
            <>
                {loading ? <Loading /> : null}
                <Helmet>
                    <title>{`Encuentra ${searchParams?.busqueda} a los mejores precios | Sanborns.com.mx`}</title>
                    <link rel="canonical" href={`https://www.sanborns.com.mx/resultados/q=${searchParams?.busqueda}`} />
                    <meta name="Description" content={`Si lo que buscas es ${searchParams?.busqueda} compra con tu recibo Telmex, encuentra productos 100% originales y realiza tu compra segura. Envío gratis a partir de $${ENVIO_GRATIS}.00 `} />
                
                    <script type="application/ld+json">
                        { structData }
                    </script>
                
                </Helmet >

                <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection} searchWork={decodeURIComponent(paramSearch?.busqueda)} headerEnCategoria="headerBuscador" />
                <section className="barCpResponsive resultMo">
                    <BarraCP changeCP={reciveDirection} clase="barHeaderCP" />
                </section>

                <main className="templateSearchResult">
                    <section>
                        <div className="container">
                            {searchResults &&
                                searchResults.searchResult &&
                                <>
                                    {isMobile
                                        ? isMobile === 'movil'
                                            ? <>
                                                <div className="contProductCategory">
                                                    {Number(searchResults.searchResult.GSP.RES.M) !== 0 &&
                                                        < Filtros results={searchResults.searchResult.GSP.RES.M} params={params} searchParams={searchParams}
                                                            searchResults={searchResults} setSearchParams={setSearchParams}
                                                            history={props.history} getSearch={getSearch} setSearchResults={setSearchResults}
                                                            primeraParte={`/resultados/q=${searchParams?.busqueda}`}
                                                            primeraParteConPagina={`/resultados/q=${searchParams?.busqueda}/pagina=` + searchParams.page}

                                                            categoriaP={searchParams.categoria} marcaP={searchParams.marca}
                                                            precioMayorP={searchParams.precioMayor} precioMenorP={searchParams.precioMenor}
                                                            startP={searchParams.start} shippingP={searchParams.shipping} fulfillmentP={searchParams.fulfillment}
                                                            discountP={searchParams.discount}
                                                            pageP={searchParams.page} orderP={searchParams.order} busquedaP={searchParams?.busqueda}
                                                            idtiendaP={searchParams.idtienda} subCategoryP={searchParams.subCategory}
                                                            valorDescuento={variablesFiltros.valorDescuento}
                                                            tieneEnvioGratis={variablesFiltros.tieneEnvioGratis} tieneFulfillment={variablesFiltros.tieneFulfillment}
                                                            tieneReview={variablesFiltros.tieneReview}
                                                            minimo={variablesFiltros.minimo} maximo={variablesFiltros.maximo}

                                                            seEnviaVista={seEnviaVista}
                                                        />
                                                    }
                                                    {(suggestions !== false && search !== false) &&
                                                        <div className="boxSuggestionMovil">
                                                            <p className="suggestion">Tal vez quisiste decir <Link className="link" to={`/resultados/q=${suggestions}/pagina=1`} title={suggestions}>{suggestions}</Link> en lugar de <span className="opcion2">{decodeURIComponent(search)}</span>
                                                            </p>
                                                        </div>
                                                    }

                                                    {(Number(searchResults.searchResult.GSP.RES.M) === 0 && (searchParams.discount == null && searchParams.fulfillment == null && searchParams.categoria == null && searchParams.marca == null && searchParams.precioMayor == null && searchParams.precioMenor == null && searchParams.shipping == null && searchParams.start == null))
                                                        ? null
                                                        : <>
                                                            <h1 className="titleSearchWorkMovil">Resultados para <span>{decodeURI(searchParams?.busqueda)}</span>:</h1>
                                                        </>
                                                    }

                                                    {Number(searchResults.searchResult.GSP.RES.M) === 0
                                                        && // si no se encuentran resultados con filtros aplicados
                                                        ((searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //estrellas, envio, precios
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //estrellas, precios
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //envio, precios
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // marca
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //estrellas
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //envio
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria, marca
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria, estrellas
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria, envio
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios, marca
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //marca,estrellas
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //envio,marca
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //estrellas,envio
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //categoria,marca,precios-
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,marca,estrellas
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,marca,envio
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //estrellas,marcas,precios,
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //marca,precios,envio
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //marca,envio,estrellas
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,estrellas,envio
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,envio,categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,estrella,categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,estrella,marca,categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,envio,marca,categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,estrella,envio,categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,marca,envio,estrellas
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios, categoria, marca,envios,estrella
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios, marca,envios,estrella

                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, estrellas
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, envio
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment,marca, precios
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, estrellas, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, envio
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, categoria, precios 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria, estrellas 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria, envio 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, estrella, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, envio, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, estrellas, envio, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, categoria, precios, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria, estrellas, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria, envio, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, precios, estrella, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, precios, envio
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, estrellas, envio, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, categoria, precios, estrella, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, categoria, precios, envio, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria, estrellas, envio, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, estrellas, envio
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, categoria, precios, estrellas
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, categoria, precios, envio, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria, estrellas, envio, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, precios, estrellas, envio
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, estrella, envio, categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, estrellas, envio, marca, categoria, precios

                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount estrellas precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount shipping precios
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount fulfillment precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca categoria precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca start precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca shipping precios
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca fulfillment precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria start precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria shipping precios
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria fulfillment precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount start shipping precios
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount start fulfillment precios
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount shipping fulfillment precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // disocunt marca categoria shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria start 
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca start shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca start fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria start shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria start fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios start shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria start shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria start fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca start shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria start shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria marca start shipping fulfillment precios
                                                        )
                                                        ? <>
                                                            {/* {console.log("antes del 1: ", url_Per_Pagination, " --> ", props.location.pathname)} */}
                                                            <Redirect to={`pagina=1`} />
                                                            <div className="sinResultados">
                                                                <div className="imagen">
                                                                    <img src="/img/searchError.svg" alt="No se encontraron resultados" width="120" height="120" loading="lazy" />
                                                                </div>

                                                                <div className="texto">
                                                                    <h2> Se encontraron 0 resultados</h2>
                                                                    <p> Por favor intenta con un filtro diferente</p>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : (mostrarSinResultados === true && Number(searchResults.searchResult.GSP.RES.M) === 0 && (searchParams.discount == null && searchParams.fulfillment == null && searchParams.categoria == null && searchParams.marca == null && searchParams.precioMayor == null && searchParams.precioMenor == null && searchParams.shipping == null && searchParams.start == null))
                                                            ? <ErrorSearchServices searchQ={searchResults.searchResult.GSP.Q} suggestion={searchResults.searchResult.GSP.Spelling ? searchResults.searchResult.GSP.Spelling.Suggestion.V : null} />
                                                            : null
                                                    }



                                                    {Number(searchResults.searchResult.GSP.RES.M) !== 0 &&
                                                        <>
                                                            {(searchResults.searchResult.GSP.RES.R) != undefined
                                                                ?
                                                                ((searchResults.searchResult.GSP.RES.R).length > 0  //>1
                                                                    ? searchResults.searchResult.GSP.RES.R.map(({ MT }, index) => {
                                                                        arrayProductos.push({
                                                                            nombre: MT[MT.findIndex(item => item.N === 'title')] ? MT[MT.findIndex(item => item.N === 'title')].V : null,
                                                                            nombreSEO: MT[MT.findIndex(item => item.N === 'title_seo')] ? MT[MT.findIndex(item => item.N === 'title_seo')].V : null,
                                                                            precio: MT[MT.findIndex(item => item.N === 'price')] ? MT[MT.findIndex(item => item.N === 'price')].V : null,
                                                                            id: MT[MT.findIndex(item => item.N === 'id')] ? MT[MT.findIndex(item => item.N === 'id')].V : null,
                                                                            precioLista: MT[MT.findIndex(item => item.N === 'sale_price')] ? MT[MT.findIndex(item => item.N === 'sale_price')].V : null,
                                                                            foto: MT[MT.findIndex(item => item.N === 'link_thumbnail')] ? MT[MT.findIndex(item => item.N === 'link_thumbnail')].V : null,
                                                                            descuento: MT[MT.findIndex(item => item.N === 'discount')] ? MT[MT.findIndex(item => item.N === 'discount')].V : null,
                                                                            store: MT[MT.findIndex(item => item.N === 'store')] ? MT[MT.findIndex(item => item.N === 'store')].V : null,
                                                                            review: MT[MT.findIndex(item => item.N === 'review')] ? MT[MT.findIndex(item => item.N === 'review')].V : null,
                                                                            total_review: MT[MT.findIndex(item => item.N === 'total_review')] ? MT[MT.findIndex(item => item.N === 'total_review')].V : null,
                                                                            shipping_price: MT[MT.findIndex(item => item.N === 'shipping_price')] ? MT[MT.findIndex(item => item.N === 'shipping_price')].V : null,
                                                                            fulfillment: MT[MT.findIndex(item => item.N === 'fulfillment')] ? MT[MT.findIndex(item => item.N === 'fulfillment')].V : null,
                                                                            breadcrumbs: MT[MT.findIndex(item => item.N === 'breadcrumbs')] ? MT[MT.findIndex(item => item.N === 'breadcrumbs')].V : null,
                                                                            attribute_marca: MT[MT.findIndex(item => item.N === 'attribute_marca')] ? MT[MT.findIndex(item => item.N === 'attribute_marca')].V : null,
                                                                            telmex_price: MT[MT.findIndex(item => item.N === 'telmex_price')] ? MT[MT.findIndex(item => item.N === 'telmex_price')].V : null,
                                                                            telmex_months: MT[MT.findIndex(item => item.N === 'telmex_months')] ? MT[MT.findIndex(item => item.N === 'telmex_months')].V : null,
                                                                            credit_card_months: MT[MT.findIndex(item => item.N === 'credit_card_months')] ? MT[MT.findIndex(item => item.N === 'credit_card_months')].V : null,
                                                                            credit_card_price: MT[MT.findIndex(item => item.N === 'credit_card_price')] ? MT[MT.findIndex(item => item.N === 'credit_card_price')].V : null,
                                                                            index: index,
                                                                            cyr: MT[MT.findIndex(item=> item.N === "cyr")] ? MT[MT.findIndex(item=> item.N=== "cyr")].V : null,
                                                                            promotions: MT[MT.findIndex(item=> item.N === "promotions")] ? MT[MT.findIndex(item=> item.N=== "promotions")].V : null,
                                                                            // ,discount: MT[MT.findIndex(item => item.N === 'discount')] ? MT[MT.findIndex(item => item.N === 'discount')].V : null
                                                                        })
                                                                    }
                                                                    )

                                                                    : [searchResults.searchResult.GSP.RES.R].map(({ MT }, index) => {
                                                                        arrayProductos.push({
                                                                            nombre: MT[MT.findIndex(item => item.N === 'title')] ? MT[MT.findIndex(item => item.N === 'title')].V : null,
                                                                            nombreSEO: MT[MT.findIndex(item => item.N === 'title_seo')] ? MT[MT.findIndex(item => item.N === 'title_seo')].V : null,
                                                                            precio: MT[MT.findIndex(item => item.N === 'price')] ? MT[MT.findIndex(item => item.N === 'price')].V : null,
                                                                            id: MT[MT.findIndex(item => item.N === 'id')] ? MT[MT.findIndex(item => item.N === 'id')].V : null,
                                                                            precioLista: MT[MT.findIndex(item => item.N === 'sale_price')] ? MT[MT.findIndex(item => item.N === 'sale_price')].V : null,
                                                                            foto: MT[MT.findIndex(item => item.N === 'link_thumbnail')] ? MT[MT.findIndex(item => item.N === 'link_thumbnail')].V : null,
                                                                            descuento: MT[MT.findIndex(item => item.N === 'discount')] ? MT[MT.findIndex(item => item.N === 'discount')].V : null,
                                                                            store: MT[MT.findIndex(item => item.N === 'store')] ? MT[MT.findIndex(item => item.N === 'store')].V : null,
                                                                            review: MT[MT.findIndex(item => item.N === 'review')] ? MT[MT.findIndex(item => item.N === 'review')].V : null,
                                                                            total_review: MT[MT.findIndex(item => item.N === 'total_review')] ? MT[MT.findIndex(item => item.N === 'total_review')].V : null,
                                                                            shipping_price: MT[MT.findIndex(item => item.N === 'shipping_price')] ? MT[MT.findIndex(item => item.N === 'shipping_price')].V : null,
                                                                            fulfillment: MT[MT.findIndex(item => item.N === 'fulfillment')] ? MT[MT.findIndex(item => item.N === 'fulfillment')].V : null,
                                                                            breadcrumbs: MT[MT.findIndex(item => item.N === 'breadcrumbs')] ? MT[MT.findIndex(item => item.N === 'breadcrumbs')].V : null,
                                                                            attribute_marca: MT[MT.findIndex(item => item.N === 'attribute_marca')] ? MT[MT.findIndex(item => item.N === 'attribute_marca')].V : null,
                                                                            telmex_price: MT[MT.findIndex(item => item.N === 'telmex_price')] ? MT[MT.findIndex(item => item.N === 'telmex_price')].V : null,
                                                                            telmex_months: MT[MT.findIndex(item => item.N === 'telmex_months')] ? MT[MT.findIndex(item => item.N === 'telmex_months')].V : null,
                                                                            credit_card_months: MT[MT.findIndex(item => item.N === 'credit_card_months')] ? MT[MT.findIndex(item => item.N === 'credit_card_months')].V : null,
                                                                            credit_card_price: MT[MT.findIndex(item => item.N === 'credit_card_price')] ? MT[MT.findIndex(item => item.N === 'credit_card_price')].V : null,
                                                                            index: index,
                                                                            cyr: MT[MT.findIndex(item=> item.N === "cyr")] ? MT[MT.findIndex(item=> item.N=== "cyr")].V : null,
                                                                            promotions: MT[MT.findIndex(item=> item.N === "promotions")] ? MT[MT.findIndex(item=> item.N=== "promotions")].V : null,
                                                                            // ,discount: MT[MT.findIndex(item => item.N === 'discount')] ? MT[MT.findIndex(item => item.N === 'discount')].V : null
                                                                        })
                                                                    })

                                                                )
                                                                : null
                                                            }

                                                            {arrayProductos?.map( (producto, index)  =>
                                                                    index !== 3
                                                                        ?
                                                                            <CardProduct
                                                                                key={index} id={producto?.id}
                                                                                nombre={producto?.nombre} nombreSEO={producto?.nombreSEO}
                                                                                precio={producto?.precio} precioLista={producto?.precioLista}
                                                                                foto={producto?.foto} descuento={producto?.descuento}
                                                                                store={producto?.store} review={producto?.review}
                                                                                shipping_price={producto?.shipping_price} fulfillment={producto?.fulfillment}
                                                                                categories={producto?.breadcrumbs} brand={producto?.attribute_marca}
                                                                                vista={vista}
                                                                                creditCardMonths={producto?.credit_card_months}
                                                                                creditCardPrice={producto?.credit_card_price}
                                                                                telmexMonths={producto?.telmex_months}
                                                                                telmexPrice={producto?.telmex_price}
                                                                                comments={producto?.total_review}
                                                                                index={producto?.index}
                                                                                clicRecoge = {producto?.cyr}
                                                                                pagosSears = {producto?.promotions}
                                                                                priority={producto?.index <= 12 ? "High" : null}
                                                                                productTotal={anteaterProductAdapter(arrayProductos[producto?.index])}
                                                                            />
                                                                    :
                                                                        <>
                                                                            <CardProduct
                                                                                key={index} id={producto?.id}
                                                                                nombre={producto?.nombre} nombreSEO={producto?.nombreSEO}
                                                                                precio={producto?.precio} precioLista={producto?.precioLista}
                                                                                foto={producto?.foto} descuento={producto?.descuento}
                                                                                store={producto?.store} review={producto?.review}
                                                                                shipping_price={producto?.shipping_price} fulfillment={producto?.fulfillment}
                                                                                categories={producto?.breadcrumbs} brand={producto?.attribute_marca}
                                                                                vista={vista}
                                                                                creditCardMonths={producto?.credit_card_months}
                                                                                creditCardPrice={producto?.credit_card_price}
                                                                                telmexMonths={producto?.telmex_months}
                                                                                telmexPrice={producto?.telmex_price}
                                                                                comments={producto?.total_review}
                                                                                index={producto?.index}
                                                                                clicRecoge = {producto?.cyr}
                                                                                pagosSears = {producto?.promotions}
                                                                                priority={producto?.index <= 12 ? "High" : null}
                                                                                productTotal={anteaterProductAdapter(arrayProductos[producto?.index])}
                                                                            />
                                                                            {/* <WhatsBuscador busqueda={searchParams?.busqueda} /> */}
                                                                        </>
                                                                    
                                                            )}
                                                        </>
                                                    }
                                                </div>
                                            </>
                                            : isMobile === 'desktop'
                                                ? <>
                                                    {/* {console.log(" suggestions != false  && search != false ------- > ", suggestions != false && search != false, " sugerencia: ", suggestions)} */}
                                                    {(suggestions !== false && search !== false) &&
                                                        <div className="boxSuggestion">
                                                            <p className="suggestion">Tal vez quisiste decir <Link className="link" to={`/resultados/q=${suggestions}/pagina=1`} title={suggestions}>{suggestions}</Link> en lugar de <span className="opcion2">{decodeURIComponent(search)}</span>
                                                            </p>
                                                        </div>
                                                    }

                                                    {(Number(searchResults.searchResult.GSP.RES.M) === 0 && (searchParams.discount == null && searchParams.fulfillment == null && searchParams.categoria == null && searchParams.marca == null && searchParams.precioMayor == null && searchParams.precioMenor == null && searchParams.shipping == null && searchParams.start == null))
                                                        ? null
                                                        : (Number(searchResults.searchResult.GSP.RES.M) !== 0) &&
                                                        <div className="secTitleSearch">
                                                            <div className="search1"></div>
                                                            <div className="search2">
                                                                <h1 className="titleSearchWork">Encuentra y compra lo mejor de <span>{decodeURIComponent(searchParams?.busqueda)}</span> en Sanborns</h1>
                                                            </div>
                                                        </div>

                                                    }

                                                    <div className="contProductCategoryDesk">
                                                        {Number(searchResults.searchResult.GSP.RES.M) !== 0 && <div className="boxFiltrosDesk">
                                                            <FiltrosDeskLogica searchParams={searchParams} searchResults={searchResults}
                                                                setSearchParams={setSearchParams} history={props.history} params={params}
                                                                primeraParte={`/resultados/q=${searchParams?.busqueda}`}
                                                                marcaP={searchParams.marca}
                                                                tieneEnvioGratis={variablesFiltros.tieneEnvioGratis}
                                                                tieneFulfillment={variablesFiltros.tieneFulfillment}
                                                                tieneReview={variablesFiltros.tieneReview}
                                                                ordenS={ordenS} setOrdenS={setOrdenS}
                                                                minimo={variablesFiltros.minimo} maximo={variablesFiltros.maximo}

                                                                valorDescuento={variablesFiltros.valorDescuento}
                                                            />
                                                        </div>
                                                        }
                                                        <div className={Number(searchResults.searchResult.GSP.RES.M) !== 0 ? "boxGeneralProductosResultados" : "boxGeneralSinResultados"} >

                                                            {Number(searchResults.searchResult.GSP.RES.M) !== 0 && <BarraResultados
                                                                primeraParte={`/resultados/q=${searchParams?.busqueda}`}
                                                                history={props.history} params={params}
                                                                searchParams={searchParams} setSearchParams={setSearchParams}
                                                                results={searchResults.searchResult.GSP.RES.M} seEnviaVista={seEnviaVistaDesktop} vista={vista}
                                                                obtenerOrdenSeleccionado={obtenerOrdenSeleccionado} />
                                                            }

                                                            {Number(searchResults.searchResult.GSP.RES.M) !== 0 && <div className="bxTituloProductos">
                                                                <p>PRODUCTOS</p>
                                                                <div className="bxinteriorproductos"> </div>
                                                            </div>
                                                            }
                                                            
                                                            <div className="boxProductosCategory">
                                                                {Number(searchResults.searchResult.GSP.RES.M) === 0
                                                                    && // si no se encuentran resultados con filtros aplicados
                                                                    ((searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //estrellas, envio, precios
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //estrellas, precios
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //envio, precios
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // marca
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //estrellas
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria, marca
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria, estrellas
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria, envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios, marca
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //marca,estrellas
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //envio,marca
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //estrellas,envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //categoria,marca,precios-
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,marca,estrellas
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,marca,envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //estrellas,marcas,precios,
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //marca,precios,envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //marca,envio,estrellas
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,estrellas,envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,envio,categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,estrella,categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,estrella,marca,categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,envio,marca,categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,estrella,envio,categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,marca,envio,estrellas
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios, categoria, marca,envios,estrella
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios, marca,envios,estrella

                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, estrellas
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment,marca, precios
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, estrellas, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, categoria, precios 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria, estrellas 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria, envio 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, estrella, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, envio, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, estrellas, envio, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, categoria, precios, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria, estrellas, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria, envio, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, precios, estrella, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, precios, envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, estrellas, envio, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, categoria, precios, estrella, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, categoria, precios, envio, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria, estrellas, envio, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, estrellas, envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, categoria, precios, estrellas
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, categoria, precios, envio, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria, estrellas, envio, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, precios, estrellas, envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, estrella, envio, categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, estrellas, envio, marca, categoria, precios

                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount estrellas precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount shipping precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount fulfillment precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca categoria precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca start precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca shipping precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca fulfillment precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria start precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria shipping precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria fulfillment precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount start shipping precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount start fulfillment precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount shipping fulfillment precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // disocunt marca categoria shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria start 
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca start shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca start fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria start shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria start fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios start shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria start shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria start fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca start shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria start shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria marca start shipping fulfillment precios
                                                                    )
                                                                    ? <>
                                                                        <Redirect to={`pagina=1`} />
                                                                        <div className="sinResultados">
                                                                            <div className="imagen">
                                                                                <img src="/img/searchError.svg" alt="No se encontraron resultados" width="120" height="120" loading="lazy" />
                                                                            </div>

                                                                            <div className="texto">
                                                                                <h2> Se encontraron 0 resultados</h2>
                                                                                <p> Por favor intenta con un filtro diferente</p>
                                                                            </div>

                                                                        </div>
                                                                    </>
                                                                    : (mostrarSinResultados === true && Number(searchResults.searchResult.GSP.RES.M) === 0 && (searchParams.discount == null && searchParams.fulfillment == null && searchParams.categoria == null && searchParams.marca == null && searchParams.precioMayor == null && searchParams.precioMenor == null && searchParams.shipping == null && searchParams.start == null))
                                                                        ? <ErrorSearchServices searchQ={searchResults.searchResult.GSP.Q} suggestion={searchResults.searchResult.GSP.Spelling ? searchResults.searchResult.GSP.Spelling.Suggestion.V : null} />
                                                                        : null
                                                                }


                                                                {Number(searchResults.searchResult.GSP.RES.M) !== 0 &&
                                                                    <>
                                                                        {(searchResults.searchResult.GSP.RES.R) != undefined
                                                                            ?
                                                                            ((searchResults.searchResult.GSP.RES.R).length > 0  //>1
                                                                                ? searchResults.searchResult.GSP.RES.R.map(({ MT }, index) => {
                                                                                    arrayProductos.push({
                                                                                        nombre: MT[MT.findIndex(item => item.N === 'title')] ? MT[MT.findIndex(item => item.N === 'title')].V : null,
                                                                                        nombreSEO: MT[MT.findIndex(item => item.N === 'title_seo')] ? MT[MT.findIndex(item => item.N === 'title_seo')].V : null,
                                                                                        precio: MT[MT.findIndex(item => item.N === 'price')] ? MT[MT.findIndex(item => item.N === 'price')].V : null,
                                                                                        id: MT[MT.findIndex(item => item.N === 'id')] ? MT[MT.findIndex(item => item.N === 'id')].V : null,
                                                                                        precioLista: MT[MT.findIndex(item => item.N === 'sale_price')] ? MT[MT.findIndex(item => item.N === 'sale_price')].V : null,
                                                                                        foto: MT[MT.findIndex(item => item.N === 'link_thumbnail')] ? MT[MT.findIndex(item => item.N === 'link_thumbnail')].V : null,
                                                                                        descuento: MT[MT.findIndex(item => item.N === 'discount')] ? MT[MT.findIndex(item => item.N === 'discount')].V : null,
                                                                                        store: MT[MT.findIndex(item => item.N === 'store')] ? MT[MT.findIndex(item => item.N === 'store')].V : null,
                                                                                        review: MT[MT.findIndex(item => item.N === 'review')] ? MT[MT.findIndex(item => item.N === 'review')].V : null,
                                                                                        total_review: MT[MT.findIndex(item => item.N === 'total_review')] ? MT[MT.findIndex(item => item.N === 'total_review')].V : null,
                                                                                        shipping_price: MT[MT.findIndex(item => item.N === 'shipping_price')] ? MT[MT.findIndex(item => item.N === 'shipping_price')].V : null,
                                                                                        fulfillment: MT[MT.findIndex(item => item.N === 'fulfillment')] ? MT[MT.findIndex(item => item.N === 'fulfillment')].V : null,
                                                                                        breadcrumbs: MT[MT.findIndex(item => item.N === 'breadcrumbs')] ? MT[MT.findIndex(item => item.N === 'breadcrumbs')].V : null,
                                                                                        attribute_marca: MT[MT.findIndex(item => item.N === 'attribute_marca')] ? MT[MT.findIndex(item => item.N === 'attribute_marca')].V : null,
                                                                                        telmex_price: MT[MT.findIndex(item => item.N === 'telmex_price')] ? MT[MT.findIndex(item => item.N === 'telmex_price')].V : null,
                                                                                        telmex_months: MT[MT.findIndex(item => item.N === 'telmex_months')] ? MT[MT.findIndex(item => item.N === 'telmex_months')].V : null,
                                                                                        credit_card_months: MT[MT.findIndex(item => item.N === 'credit_card_months')] ? MT[MT.findIndex(item => item.N === 'credit_card_months')].V : null,
                                                                                        credit_card_price: MT[MT.findIndex(item => item.N === 'credit_card_price')] ? MT[MT.findIndex(item => item.N === 'credit_card_price')].V : null,
                                                                                        index: index,
                                                                                        cyr: MT[MT.findIndex(item=> item.N === "cyr")] ? MT[MT.findIndex(item=> item.N=== "cyr")].V : null,
                                                                                        promotions: MT[MT.findIndex(item=> item.N === "promotions")] ? MT[MT.findIndex(item=> item.N=== "promotions")].V : null,
                                                                                        // ,discount: MT[MT.findIndex(item => item.N === 'discount')] ? MT[MT.findIndex(item => item.N === 'discount')].V : null
                                                                                    })
                                                                                }
                                                                                )

                                                                                : [searchResults.searchResult.GSP.RES.R].map(({ MT }, index) => {
                                                                                    arrayProductos.push({
                                                                                        nombre: MT[MT.findIndex(item => item.N === 'title')] ? MT[MT.findIndex(item => item.N === 'title')].V : null,
                                                                                        nombreSEO: MT[MT.findIndex(item => item.N === 'title_seo')] ? MT[MT.findIndex(item => item.N === 'title_seo')].V : null,
                                                                                        precio: MT[MT.findIndex(item => item.N === 'price')] ? MT[MT.findIndex(item => item.N === 'price')].V : null,
                                                                                        id: MT[MT.findIndex(item => item.N === 'id')] ? MT[MT.findIndex(item => item.N === 'id')].V : null,
                                                                                        precioLista: MT[MT.findIndex(item => item.N === 'sale_price')] ? MT[MT.findIndex(item => item.N === 'sale_price')].V : null,
                                                                                        foto: MT[MT.findIndex(item => item.N === 'link_thumbnail')] ? MT[MT.findIndex(item => item.N === 'link_thumbnail')].V : null,
                                                                                        descuento: MT[MT.findIndex(item => item.N === 'discount')] ? MT[MT.findIndex(item => item.N === 'discount')].V : null,
                                                                                        store: MT[MT.findIndex(item => item.N === 'store')] ? MT[MT.findIndex(item => item.N === 'store')].V : null,
                                                                                        review: MT[MT.findIndex(item => item.N === 'review')] ? MT[MT.findIndex(item => item.N === 'review')].V : null,
                                                                                        total_review: MT[MT.findIndex(item => item.N === 'total_review')] ? MT[MT.findIndex(item => item.N === 'total_review')].V : null,
                                                                                        shipping_price: MT[MT.findIndex(item => item.N === 'shipping_price')] ? MT[MT.findIndex(item => item.N === 'shipping_price')].V : null,
                                                                                        fulfillment: MT[MT.findIndex(item => item.N === 'fulfillment')] ? MT[MT.findIndex(item => item.N === 'fulfillment')].V : null,
                                                                                        breadcrumbs: MT[MT.findIndex(item => item.N === 'breadcrumbs')] ? MT[MT.findIndex(item => item.N === 'breadcrumbs')].V : null,
                                                                                        attribute_marca: MT[MT.findIndex(item => item.N === 'attribute_marca')] ? MT[MT.findIndex(item => item.N === 'attribute_marca')].V : null,
                                                                                        telmex_price: MT[MT.findIndex(item => item.N === 'telmex_price')] ? MT[MT.findIndex(item => item.N === 'telmex_price')].V : null,
                                                                                        telmex_months: MT[MT.findIndex(item => item.N === 'telmex_months')] ? MT[MT.findIndex(item => item.N === 'telmex_months')].V : null,
                                                                                        credit_card_months: MT[MT.findIndex(item => item.N === 'credit_card_months')] ? MT[MT.findIndex(item => item.N === 'credit_card_months')].V : null,
                                                                                        credit_card_price: MT[MT.findIndex(item => item.N === 'credit_card_price')] ? MT[MT.findIndex(item => item.N === 'credit_card_price')].V : null,
                                                                                        index: index,
                                                                                        cyr: MT[MT.findIndex(item=> item.N === "cyr")] ? MT[MT.findIndex(item=> item.N=== "cyr")].V : null,
                                                                                        promotions: MT[MT.findIndex(item=> item.N === "promotions")] ? MT[MT.findIndex(item=> item.N=== "promotions")].V : null,
                                                                                        // ,discount: MT[MT.findIndex(item => item.N === 'discount')] ? MT[MT.findIndex(item => item.N === 'discount')].V : null
                                                                                    })
                                                                                })

                                                                            )
                                                                            : null
                                                                        }

                                                                        {arrayProductos && arrayProductos.map((producto,id)=>
                                                                            id !== 3
                                                                                ?
                                                                                    <CardProduct
                                                                                        key={id} id={producto?.id}
                                                                                        nombre={producto?.nombre} nombreSEO={producto?.nombreSEO}
                                                                                        precio={producto?.precio} precioLista={producto?.precioLista}
                                                                                        foto={producto?.foto} descuento={producto?.descuento}
                                                                                        store={producto?.store} review={producto?.review}
                                                                                        shipping_price={producto?.shipping_price} fulfillment={producto?.fulfillment}
                                                                                        categories={producto?.breadcrumbs} brand={producto?.attribute_marca}
                                                                                        vista={vista}
                                                                                        creditCardMonths={producto?.credit_card_months}
                                                                                        creditCardPrice={producto?.credit_card_price}
                                                                                        telmexMonths={producto?.telmex_months}
                                                                                        telmexPrice={producto?.telmex_price}
                                                                                        comments={producto?.total_review}
                                                                                        index={producto?.index}
                                                                                        clicRecoge = {producto?.cyr}
                                                                                        pagosSears = {producto?.promotions}
                                                                                        priority={producto?.id <= 12 ? "High" : null}
                                                                                        productTotal={anteaterProductAdapter(arrayProductos[producto?.index])}
                                                                                    />
                                                                                :
                                                                                    <>
                                                                                        <CardProduct
                                                                                            key={id} id={producto?.id}
                                                                                            nombre={producto?.nombre} nombreSEO={producto?.nombreSEO}
                                                                                            precio={producto?.precio} precioLista={producto?.precioLista}
                                                                                            foto={producto?.foto} descuento={producto?.descuento}
                                                                                            store={producto?.store} review={producto?.review}
                                                                                            shipping_price={producto?.shipping_price} fulfillment={producto?.fulfillment}
                                                                                            categories={producto?.breadcrumbs} brand={producto?.attribute_marca}
                                                                                            vista={vista}
                                                                                            creditCardMonths={producto?.credit_card_months}
                                                                                            creditCardPrice={producto?.credit_card_price}
                                                                                            telmexMonths={producto?.telmex_months}
                                                                                            telmexPrice={producto?.telmex_price}
                                                                                            comments={producto?.total_review}
                                                                                            index={producto?.index}
                                                                                            clicRecoge = {producto?.cyr}
                                                                                            pagosSears = {producto?.promotions}
                                                                                            priority={producto?.index <= 12 ? "High" : null}
                                                                                            productTotal={anteaterProductAdapter(arrayProductos[producto?.index])}
                                                                                        />
                                                                                        {/* <WhatsBuscador busqueda={searchParams?.busqueda} /> */}
                                                                                    </>
                                                                        )}
                                                                    </>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                        : null
                                    }
                                    < Paginador params={params} paginacion={paginacion} setPaginacion={setPaginacion} searchParams1={searchParams} url_Per_Pagination={url_Per_Pagination} searchResults={searchResults} setSearchParams={setSearchParams} />
                                </>
                            }
                        </div>

                        {Number(searchResults?.searchResult?.GSP?.RES?.M) !== 0
                            ?<WhatsBuscador busqueda={searchParams?.busqueda} seccion={'buscador'} />
                            :null
                        }
                    </section>

                </main>
                <Footer />
            </>

    )
}