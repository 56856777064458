export function urlPedidosFunction() {

    let urlPath = window.location.pathname
    const urlParams = urlPath.split('/')
    urlParams.shift()

    let pagina = null
    let dias = null

    for (let i = 0; i <= urlParams.length - 1; i++) {

        if (urlParams[i].indexOf('pagina=') >= 0) {
            pagina = urlParams[i].replace('pagina=', '')
        }
        if (urlParams[i].indexOf('dias=') >= 0) {
            dias = urlParams[i].replace('dias=', '')
        }

    }

    // console.log("url en funcion: ", urlParams)
    // console.log("pagina----> " + pagina)
    // console.log("dias ----> " + dias)

    let pedidos = {
        days: dias,
        pages: pagina
    }

    return pedidos
}