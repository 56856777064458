import React from 'react';
import Slider from 'react-slick';
import './style.sass';
import { useIsMobile } from 'hooks/useIsMobile'

export function SectionAttributesBoock(props) {
    const { attributes, brand} = props;
    const { isMobile } = useIsMobile()

    const settings = {
        infinite: false,
        arrows: true,
        dots: false,
        speed: 500,
        //swipe: true,
        touchMove: true,
        centerPadding: "60px",
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        //accessibility: false,
        responsive: [     
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            }
        ]
    };

    return (
        <>
            {isMobile === 'desktop'
                    ?
                    <div className="banner-attributes-boock">
                        <div className="attributes-boock">
                            <div className="contProductoRecomend">
                                <div className={`productContent`}>

                                    <Slider {...settings}>
                                        {attributes?.no_de_páginas &&
                                            <div className="attribute">
                                                <div className="texto-attribute">
                                                    <p>Páginas</p>
                                                </div>
                                                <div className="imagen-attributeHome">
                                                    <img src="/img/libro/paginas.png" alt="paginas_logo" loading="lazy" width="100" height="100" />
                                                </div>
                                                <div className="texto">
                                                    <p>{attributes?.no_de_páginas}</p>
                                                </div>
                                            </div>
                                        }
                                        {brand &&
                                            <div className="attribute">
                                                <div className="texto-attribute">
                                                    <p>Editorial</p>
                                                </div>
                                                <div className="imagen-attributeHome">
                                                    <img src="/img/libro/editorial.png" alt="editorial_logo" loading="lazy" width="100" height="100" />
                                                </div>
                                                <div className="texto">
                                                    <p>{brand}</p>
                                                </div>
                                            </div>
                                        }
                                        {attributes?.año &&
                                            <div className="attribute">
                                                <div className="texto-attribute">
                                                    <p>Fecha de publicación</p>
                                                </div>
                                                <div className="imagen-attributeHome">
                                                    <img src="/img/libro/fecha.png" alt="fecha_logo" loading="lazy" width="100" height="100" />
                                                </div>
                                                <div className="texto">
                                                    <p>{attributes?.año}</p>
                                                </div>
                                            </div>
                                        }
                                        {attributes?.idioma &&
                                            <div className="attribute">
                                                <div className="texto-attribute">
                                                    <p>Idioma</p>
                                                </div>
                                                <div className="imagen-attributeHome">
                                                    <img src="/img/libro/idioma.png" alt="idioma_logo" loading="lazy" width="100" height="100" />
                                                </div>
                                                <div className="texto">
                                                    <p>{attributes?.idioma}</p>
                                                </div>
                                            </div>
                                        }
                                        {attributes?.ancho_del_empaque && attributes?.alto_del_empaque && attributes?.profundo_del_empaque &&
                                            <div className="attribute">
                                                <div className="texto-attribute">
                                                    <p>Dimensiones</p>
                                                </div>
                                                <div className="imagen-attributeHome">
                                                    <img src="/img/libro/dimensiones.png" alt="dimensiones_logo" loading="lazy" width="100" height="100" />
                                                </div>
                                                <div className="texto">
                                                    <p>
                                                        {attributes?.ancho_del_empaque} x &nbsp;
                                                        {attributes?.alto_del_empaque} x &nbsp;
                                                        {attributes?.profundo_del_empaque} cm
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                        
                                    </Slider>
                                </div>
                            </div>    
                        </div>
                    </div>
                :
                    <div className="banner-attributes-boock">
                        <div className="attributes-boock">
                            {attributes?.no_de_páginas &&
                                <div className="attribute">
                                    <div className="texto-attribute">
                                        <p>Páginas</p>
                                    </div>
                                    <div className="imagen-attributeHome">
                                        <img src="/img/libro/paginas.png" alt="paginas_logo" loading="lazy" width="100" height="100" />
                                    </div>
                                    <div className="texto">
                                        <p>{attributes?.no_de_páginas}</p>
                                    </div>
                                </div>
                            }
                            {brand &&
                                <div className="attribute">
                                    <div className="texto-attribute">
                                        <p>Editorial</p>
                                    </div>
                                    <div className="imagen-attributeHome">
                                        <img src="/img/libro/editorial.png" alt="editorial_logo" loading="lazy" width="100" height="100" />
                                    </div>
                                    <div className="texto">
                                        <p>{brand}</p>
                                    </div>
                                </div>
                            }
                            {attributes?.año &&
                                <div className="attribute">
                                    <div className="texto-attribute">
                                        <p>Fecha de publicación</p>
                                    </div>
                                    <div className="imagen-attributeHome">
                                        <img src="/img/libro/fecha.png" alt="fecha_logo" loading="lazy" width="100" height="100" />
                                    </div>
                                    <div className="texto">
                                        <p>{attributes?.año}</p>
                                    </div>
                                </div>
                            }
                            {attributes?.idioma &&
                                <div className="attribute">
                                    <div className="texto-attribute">
                                        <p>Idioma</p>
                                    </div>
                                    <div className="imagen-attributeHome">
                                        <img src="/img/libro/idioma.png" alt="idioma_logo" loading="lazy" width="100" height="100" />
                                    </div>
                                    <div className="texto">
                                        <p>{attributes?.idioma}</p>
                                    </div>
                                </div>
                            }
                            {attributes?.ancho_del_empaque && attributes?.alto_del_empaque && attributes?.profundo_del_empaque &&
                                <div className="attribute">
                                    <div className="texto-attribute">
                                        <p>Dimensiones</p>
                                    </div>
                                    <div className="imagen-attributeHome">
                                        <img src="/img/libro/dimensiones.png" alt="dimensiones_logo" loading="lazy" width="100" height="100" />
                                    </div>
                                    <div className="texto">
                                        <p>
                                            {attributes?.ancho_del_empaque} x &nbsp;
                                            {attributes?.alto_del_empaque} x &nbsp;
                                            {attributes?.profundo_del_empaque} cm
                                        </p>
                                    </div>
                                </div>
                            }
                    </div>
                </div>
            }
                
        </>
    )
}
