import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from 'pages/Header/HeaderSimple';
import './styles.sass';

export default function AcercaSanborns() {
    const history = useHistory();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Acerca Sanborns Section');
        window.scrollTo(0, 0);
    }, [])

    const backHistory = () => {
        history.go(-1);
    }

    return (
        <>
            <Header pageClass="hidden" />
            <div className="sectionTitleServicioCliente">
                <button onClick={backHistory}>Acerca de Sanborns</button>
            </div>

            <div className="opcionesCliente">
                <ul className="listaExterna">
                    <li className="balance"><span className="nombre">Aviso de Privacidad</span> </li>
                    <ul className="listaInterna">
                        <Link to={"/aviso-privacidad"} className="enlaces">
                            <li><span className="nombreI">Aviso de Privacidad</span><span className="icono"></span></li>
                        </Link>
                        <Link to={"/aviso-privacidad-store-BTS"} className="enlaces">
                            <li><span className="nombreI">Aviso de Privacidad Evento Pop Up Store BTS</span><span className="icono"></span></li>
                        </Link>
                        <Link to={"/aviso-privacidad-prospectos"} className="enlaces">
                            <li><span className="nombreI">Aviso de Privacidad Prospectos</span><span className="icono"></span></li>
                        </Link>

                        <Link to={"/aviso-privacidad-redes-sociales"} className="enlaces">
                            <li><span className="nombreI">Aviso de Privacidad Redes Sociales</span><span className="icono"></span></li>
                        </Link>
                        <Link to={"/aviso-privacidad-consultorio-sanborns"} className="enlaces">
                            <li><span className="nombreI">Aviso de Privacidad para Consultorio Sanborns</span><span className="icono"></span></li>
                        </Link>
                        <Link to={"/aviso-privacidad-tecolotes-sanborns"} className="enlaces">
                            <li><span className="nombreI">Aviso de Privacidad para APP Los Tecolotes de Sanborns</span><span className="icono"></span></li>
                        </Link>
                        <Link to={"/aviso-privacidad-circulo-de-bienestar"} className="enlaces">
                            <li><span className="nombreI">Aviso de Privacidad Círculo de Bienestar</span><span className="icono"></span></li>
                        </Link>
                        <Link to={"/aviso-privacidad-prospecto-credito"} className="enlaces">
                            <li><span className="nombreI">Aviso de Privacidad Prospecto de Crédito</span><span className="icono"></span></li>
                        </Link>
                    </ul>

                    <li className="balance"><span className="nombre">Legal</span> </li>
                    <ul className="listaInterna">
                        <Link to={"/terminos-condiciones"} className="enlaces">
                            <li><span className="nombreI">Términos y Condiciones</span><span className="icono"></span></li>
                        </Link>
                        <Link to={"/legal"} className="enlaces">
                            <li><span className="nombreI">Información Legal</span><span className="icono"></span></li>
                        </Link>
                    </ul>

                    <li className="balance">
                        <span className="nombre">Políticas</span>
                    </li>
                    <ul className="listaInterna">
                        <Link to={"/politica-devolucion/"} className="enlaces">
                            <li><span className="nombreI">Devolución</span><span className="icono"></span></li>
                        </Link>
                        <Link to={"/politica-seguridad"} className="enlaces">
                            <li><span className="nombreI">Seguridad</span><span className="icono"></span></li>
                        </Link>
                    </ul>

                </ul>
            </div>
        </>
    )
}