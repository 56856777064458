import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuAutocenter } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory} from 'react-router-dom';

export function ComoComprarDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Como Comprar');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuAutocenter />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>¿Cómo comprar?</p>
                            </div>
                        </section>

                        <div className="contentFormasP">
                            <div className="bxFp1">
                                <p className="tituloFp">¿Cómo puedo comprar en la tienda de Sanborns?</p>
                                <p className="p1fp">Para poder realizar una compra en nuestra tienda, deberás buscar y seleccionar el o los productos deseados de los distintos departamentos, agregarlos a tu “Carrito de Compras” y realizar el sencillo proceso de compra.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Cómo puedo buscar un producto?</p>
                                <p className="p1fp">“Sanborns.com.mx” cuenta con poderosas funciones de navegación y búsqueda de productos que te permiten encontrar rápida y fácilmente los artículos que estás buscando.</p>
                                <p className="p1fp"><span className="bold">Búsqueda por palabras:</span> Deberás escribir en la barra de búsqueda de esta página el nombre de intérprete, autor, título o descripción del producto que estés buscando y hacer "Click" en el botón "Buscar" (Imagen de la lupa).</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Cómo puedo seleccionar un producto?</p>
                                <p className="p1fp">Una vez encontrado el o los productos a comprar, deberás agregarlos a tu "Carrito de Compras", para lo cual deberás seguir los siguientes pasos:</p>
                                <ul className="ulBxFp1">
                                    <li>Hacer "Click" en la Imagen o Título del producto que desees comprar.</li>
                                    <li>Opcionalmente, puedes guardar el producto momentáneamente con el botón “Agregar a Carrito” al dar un “Click”. Con el fin de seleccionar más productos, antes de iniciar el proceso de compra.</li>
                                </ul>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Cómo puedo modificar las cantidades de los productos seleccionados?</p>
                                <p className="p1fp">Puedes ver el contenido de tu "Carrito de Compras" cada vez que agregues un nuevo producto a esta, dando “Click” en el vínculo: “Ingresar Carrito” junto al ícono de la Bolsa que aparece en la parte superior derecha de la página. 
                                Desde aquí podrás modificar las cantidades de los productos seleccionados o eliminar los que no desees comprar.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Cómo puedo comprar un producto?</p>
                                <p className="p1fp">Existen varios métodos para comprar dentro de la página, podrás iniciar tu proceso de compra por medio de las siguientes opciones:</p>
                                <ul className="ulBxFp1">
                                    <li>Al hacer "Click" en el botón <span className="bold">"Comprar Ahora”</span> (en el detalle del producto). En este momento se comienza el proceso de compra.</li>
                                    <li>Al hacer "Click" en el botón <span className="bold">"Compra en 1 Click”</span> te llevará de inmediato a pagar con <span className="bold">“PayPal”</span>.</li>
                                    <li>Al hacer “Click” en el botón “Recoge en Tienda”, te mostrará una pestaña en donde podrás seleccionar la sucursal con la que cuenta este producto. (En esta opción solo participan artículos y tiendas seleccionadas. Para más información consulta la página “Preguntas frecuentes”).</li>
                                </ul>
                                <p className="p1fp"><span className="bold">OJO</span>, los métodos “Compra Ahora”, “Compra en 1 Click” y “Recoge en Tienda” son uniartículo (Al momento de dar “Click” en cualquiera de esas opciones, inicia el proceso de compra, por lo tanto solo se podrá comprar un solo producto).</p>
                                <p className="p1fp">Para comprar más de un producto, puedes agregarlos por medio del botón “Agregar a Carrito”. Una vez que hayas adicionado todos los productos que desees comprar a tu "Carrito de Compras", deberás confirmar tu compra realizando los siguientes pasos:</p>
                                <p className="p1fp">1. Hacer "Click" en el botón "Comenzar compra".</p>
                                <ul className="ulBxFp1">
                                    <li>Si ya estás registrado, únicamente introduce tu E-mail y tu password.</li>
                                    <li>Si no te has registrado, deberás entrar a la opción de registro y llenar la forma con tus datos.</li>
                                </ul>
                                <p className="p1fp">2. De forma predeterminada se encuentra tu dirección favorita. En caso contrario, selecciona la dirección de entrega, llenando sus campos correspondientes.</p>
                                <p className="p1fp">3. Selecciona un método de pago en la opción “Forma de pago”, te mostrará todas las modalidades que maneja la plataforma. (Tarjeta de Crédito, Tarjeta Sanborns, PayPal, Certificado de Regalo, Depósito Bancario, Pago en Efectivo o Pago en Tienda Sanborns)*.
                                <br></br>*Llena sus campos en el caso de ser necesario.</p>
                                <p className="p1fp">4. En la parte izquierda te mostrará el “Resumen de compra”, indicando el Subtotal, el Costo de entrega, Descuento y Total a pagar.</p>
                                <p className="p1fp">5. Una vez finalizada la opción “Forma de pago” te mostrará la confirmación de tu compra, presentando el “Detalle del pedido” (Número de pedido, Fecha límite de pago*, Resumen de pago y Dirección de envío).</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Cuáles son las Formas de pago?</p>
                                <p className="p1fp">Contamos con las siguientes formas de pago:</p>
                                <ul className="ulBxFp1">
                                    <li>Tarjetas de Crédito: Sanborns, Visa, MasterCard y American Express.</li>
                                    <li>Depósito Bancario*: Citibanamex, Santander, BBVA e Inbursa.</li>
                                </ul>
                                <p className="p1fp">*Eligiendo esta opción encontrarás un ejemplo gráfico de cómo llenar la forma correctamente.</p>
                                <ul className="ulBxFp1">
                                    {/* <li>Certificado de Regalos Sanborns.</li> */}
                                    <li>PayPal.</li>
                                    <li>Oxxo Pay: Si lo que prefieres es pagar con efectivo en el horario y día que tu prefieras, sanborns.com.mx pone a tu servicio nuestra opción de pagos en las tiendas de conveniencia Oxxo, así que ya no hay excusas para comprar lo que deseas, fácil, rápido, sencillo y seguro siempre en sanborns.com.mx.</li>
                                    <li>Pago en Tienda Sanborns.</li>
                                </ul>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Cuáles son los Costos de envío?</p>
                                <p className="p1fp">El Costo de envío ó Gasto de Envío, se mostrará en la parte izquierda en el “Resumen de compra” durante el proceso de pago.</p>
                                {/* <p className="p1fp">Recuerda:</p>
                                <ul className="ulBxFp1">
                                    <li>Envío gratis en el departamento de Libros.</li>
                                </ul>
                                <p className="p1fp">Para más información referente a los “Costos de envío” puedes consultarlo en el inciso 11 en la página “Términos y Condiciones”.</p>
                                <p className="p1fp">Las políticas y condiciones señaladas, solo aplica para la tienda en línea.</p> */}
                            </div>

                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}

export function ComoComprarMobile() {

    const history = useHistory();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Como Comprar');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const onClose = () => {
        history.go(-1);
    };

    return (
        <>
        <div className="modalDetallePedido full">
            <div className="contentModal">

                <div className="contentCloseAN">
                    <div className="btn closeBack" onClick={onClose} ></div>
                    <p className="title">¿Cómo comprar?</p>
                </div>
                <div className="dataModal">

                    <div className="boxAN">

                        <div className="bxSlider">
                            
                            <p className="pSliderTitleL">¿Cómo puedo comprar en la tienda de Sanborns?</p>
                            <p className="pSliderL">Para poder realizar una compra en nuestra tienda, deberás buscar y seleccionar el o los productos deseados de los distintos departamentos, agregarlos a tu “Carrito de Compras” y realizar el sencillo proceso de compra.</p>

                            <p className="pSliderTitleL">¿Cómo puedo buscar un producto?</p>
                            <p className="pSliderL">“Sanborns.com.mx” cuenta con poderosas funciones de navegación y búsqueda de productos que te permiten encontrar rápida y fácilmente los artículos que estás buscando.<br></br>
                            <span className='bold'>Búsqueda por palabras:</span> Deberás escribir en la barra de búsqueda de esta página el nombre de intérprete, autor, título o descripción del producto que estés buscando y hacer "Click" en el botón "Buscar" (Imagen de la lupa).</p>

                            <p className="pSliderTitleL">¿Cómo puedo seleccionar un producto?</p>
                            <p className="pSliderL">Una vez encontrado el o los productos a comprar, deberás agregarlos a tu "Carrito de Compras", para lo cual deberás seguir los siguientes pasos:</p>
                            <ul className='ulDeclaracionesDisc'>
                                <li>Hacer "Click" en la Imagen o Título del producto que desees comprar.</li>
                                <li>Opcionalmente, puedes guardar el producto momentáneamente con el botón “Agregar a Carrito” al dar un “Click”. Con el fin de seleccionar más productos, antes de iniciar el proceso de compra.</li>
                            </ul>

                            <p className="pSliderTitleL">¿Cómo puedo modificar las cantidades de los productos seleccionados?</p>
                            <p className="pSliderL">Puedes ver el contenido de tu "Carrito de Compras" cada vez que agregues un nuevo producto a esta, dando “Click” en el vínculo: “Ingresar Carrito” junto al ícono de la Bolsa que aparece en la parte superior derecha de la página. Desde aquí podrás modificar las cantidades de los productos seleccionados o eliminar los que no desees comprar.</p>



                            <p className="pSliderTitleL">¿Cómo puedo comprar un producto?</p>
                            <p className="pSliderL">Existen varios métodos para comprar dentro de la página, podrás iniciar tu proceso de compra por medio de las siguientes opciones:</p>
                            <ul className='ulDeclaracionesDisc'>
                                <li>Al hacer "Click" en el botón <span className='bold'>"Comprar Ahora”</span> (en el detalle del producto). En este momento se comienza el proceso de compra.</li>
                                <li>Al hacer "Click" en el botón <span className='bold'>"Compra en 1 Click”</span> te llevará de inmediato a pagar con “PayPal”.</li>
                                <li>Al hacer “Click” en el botón <span className='bold'>“Recoge en Tienda”</span>, te mostrará una pestaña en donde podrás seleccionar la sucursal con la que cuenta este producto. (En esta opción solo participan artículos y tiendas seleccionadas. Para más información consulta la página “Preguntas frecuentes”).</li>
                            </ul>
                            <p className="pSliderL"><span className='bold'>OJO</span>, los métodos “Compra Ahora”, “Compra en 1 Click” y “Recoge en Tienda” son uniartículo (Al momento de dar “Click” en cualquiera de esas opciones, inicia el proceso de compra, por lo tanto solo se podrá comprar un solo producto).</p>
                            <p className="pSliderL">Para comprar más de un producto, puedes agregarlos por medio del botón “Agregar a Carrito”. Una vez que hayas adicionado todos los productos que desees comprar a tu "Carrito de Compras", deberás confirmar tu compra realizando los siguientes pasos:</p>
                            <p className="pSliderL">1. Hacer "Click" en el botón "Comenzar compra".</p>
                            <ul className='ulDeclaracionesDisc'>
                                <li>Si ya estás registrado, únicamente introduce tu E-mail y tu password.</li>
                                <li>Si no te has registrado, deberás entrar a la opción de registro y llenar la forma con tus datos.</li>
                            </ul>
                            <p className="pSliderL">2. De forma predeterminada se encuentra tu dirección favorita. En caso contrario, selecciona la dirección de entrega, llenando sus campos correspondientes.</p>
                            <p className="pSliderL">3. Selecciona un método de pago en la opción “Forma de pago”, te mostrará todas las modalidades que maneja la plataforma. (Tarjeta de Crédito, Tarjeta Sanborns, PayPal, Certificado de Regalo, Depósito Bancario, Pago en Efectivo o Pago en Tienda Sanborns)*.<br></br>*Llena sus campos en el caso de ser necesario.</p>
                            <p className="pSliderL">4. En la parte izquierda te mostrará el “Resumen de compra”, indicando el Subtotal, el Costo de entrega, Descuento y Total a pagar.</p>
                            <p className="pSliderL">5. Una vez finalizada la opción “Forma de pago” te mostrará la confirmación de tu compra, presentando el “Detalle del pedido” (Número de pedido, Fecha límite de pago*, Resumen de pago y Dirección de envío).</p>


                            <p className="pSliderTitleL">¿Cuáles son las Formas de pago?</p>
                            <p className="pSliderL">Contamos con las siguientes formas de pago:</p>
                            <ul className='ulDeclaracionesDisc'>
                                <li>Tarjetas de Crédito: Sanborns, Visa, MasterCard y American Express.</li>
                                <li>Depósito Bancario*: Citibanamex, Santander, BBVA e Inbursa.</li>
                            </ul>
                            <p className="pSliderL">*Eligiendo esta opción encontrarás un ejemplo gráfico de cómo llenar la forma correctamente.</p>
                            <ul className='ulDeclaracionesDisc'>
                                {/* <li>Certificado de Regalos Sanborns.</li> */}
                                <li>PayPal.</li>
                                <li>Oxxo Pay: Si lo que prefieres es pagar con efectivo en el horario y día que tu prefieras, sanborns.com.mx pone a tu servicio nuestra opción de pagos en las tiendas de conveniencia Oxxo, así que ya no hay excusas para comprar lo que deseas, fácil, rápido, sencillo y seguro siempre en sanborns.com.mx.</li>
                                <li>Pago en Tienda Sanborns.</li>
                            </ul>


                            <p className="pSliderTitleL">¿Cuáles son los Costos de envío?</p>
                            <p className="pSliderL">El Costo de envío ó Gasto de Envío, se mostrará en la parte izquierda en el “Resumen de compra” durante el proceso de pago.</p>
                            {/* <p className="pSliderL">Recuerda:</p>
                            <ul className='ulDeclaracionesDisc'>
                                <li>Envío gratis en el departamento de Libros.</li>
                            </ul>
                            <p className="pSliderL">Para más información referente a los “Costos de envío” puedes consultarlo en el inciso 11 en la página “Términos y Condiciones”.</p>
                            <p className="pSliderL">Las políticas y condiciones señaladas, solo aplica para la tienda en línea.</p> */}
                            
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </>
    )
}

export function ComoComprar() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        //window.ineum('page', 'Como Comprar');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {isMobile &&
                isMobile === 'desktop'
                ? <ComoComprarDesktop />

                : isMobile === 'movile'
                    ? null
                    : <ComoComprarMobile />
            }
        </>
    )
}