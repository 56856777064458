import React, { useState, useEffect } from 'react';
import ErrorType from 'pages/Error/ErrorType';
import Header from 'pages/Header/HeaderNavegacion';
import Footer from 'pages/Footer/FooterDesktop';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { Link } from 'react-router-dom';
import { MenuAutocenter } from 'components/MenuAutocenter/menuAutocenter';

export function MapaDesktop(props) {
    const {
        response,
        setLoading
    } = props;

    const [categorias, setCategorias] = useState([]);
    const [errors, setErrors] = useState('');

    //- Guarda en un estado la respuesta y evalua que sea 200 si no manda el error
    useEffect(() => {
        if (response) {
            if (response.statusCode === 200) {
                if (response.data) {
                    if (typeof (response.data) === 'object') {
                        setCategorias({
                            'categoriasMenu': response.data
                        });
                    }
                    else {
                        setCategorias({});
                        setErrors({ 'error': response.statusCode, 'response': response.data.response });
                    }
                }
            }
            else if (response.statusCode) {
                setErrors({ 'error': response.statusCode, 'response': response.data });
                setLoading(false);
            }
        }
        else if (response.statusCode !== undefined) {
            setCategorias({});
            setErrors({ 'error': response.statusCode, 'response': response.data });
            setLoading(false);
        }

    }, [response])

    useEffect(() => {
        if (categorias) {
            if (Object.keys(categorias).length > 0) {
                setLoading(false);
            }
        }
    }, [categorias])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const reciveDirection = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState()
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection} />
            <section className="barCpResponsive">
                <BarraCP changeCP={reciveDirection} clase="barHeaderCP" />
            </section>
            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuAutocenter />
                    </div>
                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Categorías</p>
                            </div>
                        </section>


                        {response && response.statusCode === 200
                            ? categorias.categoriasMenu &&
                            <div className='mapaSitio'>
                                {categorias.categoriasMenu.map((nivel1, { name, icon, seo, external_id, children, estatus, visible }) =>
                                    nivel1.estatus !== 0 && nivel1.visible !== 0 && String(nivel1.name).toLowerCase() !== "marketplace"
                                        ? <div className="cardCategoryMapa" >
                                            <div className="containerMapa">
                                                {String(nivel1.seo).toLowerCase() !== "marketplace" &&
                                                    <div className="firstLevelDesktopMapa">
                                                        <Link to={`/categoria/${nivel1.external_id}/${nivel1.seo}/pagina=1`} key={nivel1.external_id} className="catPadrem">
                                                            <p>{nivel1.name}</p>
                                                        </Link>
                                                    </div>
                                                }

                                                <div className="secondLevelDesktopMapa">
                                                    {(typeof (nivel1.children) === 'object')
                                                        ? nivel1.children.map((nivel2) =>
                                                            nivel2.estatus !== 0 && nivel2.visible !== 0 && String(nivel1.name).toLowerCase() !== "marketplace"
                                                                ? <div className="thirdLevelDesktop" key={nivel2.external_id}>
                                                                    <Link to={`/categoria/${nivel2.external_id}/${nivel2.seo}/pagina=1`} className="catPadrem"><p>{nivel2.name}</p></Link>
                                                                    <ul>
                                                                        {nivel2.children.map((nivel3, { name, external_id, seo, estatus, visible }, idCount) =>
                                                                            nivel3.estatus !== 0 && nivel3.visible !== 0
                                                                                ? <li key={external_id}>
                                                                                    <Link to={`/categoria/${nivel3.external_id}/${nivel3.seo}/pagina=1`}><p>{nivel3.name}</p></Link>
                                                                                </li>
                                                                                : null
                                                                        )
                                                                        }
                                                                    </ul>
                                                                </div>

                                                                : null
                                                        )
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                )}
                            </div>
                            : null
                        }

                        <ErrorType
                            codeStatus={errors.error}
                            errorText={errors.response}
                        />
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}