import React from 'react'
import '../style.sass'
import { Link } from "react-router-dom"
import CardProduct from 'components/CardProduct/ClasicCard'
import Slider from 'react-slick'
import { NO_IMAGE } from 'constants/general';

import {ErrorBoundary} from 'components/Error/ErrorBoundary'

//- Contenedor de productos tipo App V1
export function RecomendationAppHome ({carruseles}){
    return(
        <>
            {
                carruseles?.map( ({nombre, link, productos}, index) => 
                    <div className="moduleCarruselHome" key={index}>
                        <div className="container">
                            <Link to={link.split("/")[3] != undefined ? `${link.replace('/l/', '/landing/').replace('/p/', '/landing/')}/` : `${link.replace('/l/', '/landing/').replace('/p/', '/landing/')}/${nombre.trim().replace(/ /g,'-')}/`} className="h3 verMas">{nombre}</Link>
                            {nombre && <div className="borderRed"></div>}
                           
                            <div className={index%2 ? 'cardGrid' : 'cardLine'} >
                                {
                                    productos.map(({id, nombre, precio, precioLista, foto, descuento}, ind) => 
                                        <CardProduct 
                                            key={id}
                                            id={id}
                                            nombre={nombre}
                                            precio={precio}
                                            precioLista={precioLista}
                                            foto={foto}
                                            descuento={descuento}
                                            index={ind}
                                            productTotal={productos[ind]}
                                        />
                                    )
                                }
                            </div>
                            <Link to={link.split("/")[3] != undefined ? `${link.replace('/l/', '/landing/').replace('/p/', '/landing/')}/` : `${link.replace('/l/', '/landing/').replace('/p/', '/landing/')}/${nombre.trim().replace(/ /g,'-')}/`} className="verMas btnVer">Ver más</Link>
                        </div>
                    </div>
                )
            }
        </>
    )
}

//- Contenedor de productos tipo App V2
export function RecomendationAppHomeNew ({carruseles, styleType}){
    return(
        <>
            {
                carruseles.map( ({name, link, products}, index) => 
                    <div className="moduleCarruselHome" key={index}>
                        <div className="container">
                            {typeof(link) !== 'function'
                                ?
                                    <Link to={link.split("/")[3] != undefined ? `${link.replace('/l/', '/landing/').replace('/p/', '/landing/')}/` : `${link.replace('/l/', '/landing/').replace('/p/', '/landing/')}/${name.trim().replace(/ /g,'-')}/`} className="h3 verMas">{name}</Link>
                                : null
                            }
                            {/*name && <div className="borderRed"></div>*/}
                            <div className={ styleType === "cardGrid" ? "cardGrid" : styleType === "cardLine" ? "cardLine" :  index%2 ? 'cardGrid' : 'cardLine'} >
                                {
                                    products?.map(({id, title, title_seo, price, sale_price, images, discount, reviews, shipping, features, categories, brand, telmex_months, payment_methods}, index) => 
                                        telmex_months.length
                                            ?
                                                payment_methods
                                                    ?
                                                        payment_methods.find(idProd => idProd.id === 4468).promotion.length
                                                            ?
                                                                <CardProduct 
                                                                    key={id} id={id}
                                                                    nombre={title} nombreSEO={title_seo}
                                                                    precio={price} precioLista={sale_price}
                                                                    foto={images.length >= 1 ? images[0].url : NO_IMAGE} descuento={discount}
                                                                    review={reviews.score} comments={reviews.total_reviews}
                                                                    shipping_price={shipping.is_free} fulfillment={features.fulfillment} super_express={features.super_express}
                                                                    categories={categories} brand={brand} 
                                                                    creditCardMonths={payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments[payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments.length -1].months}
                                                                    creditCardPrice={payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments[payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments.length -1].price}
                                                                    telmexMonths={telmex_months[telmex_months.length -1].month} telmexPrice={telmex_months[telmex_months.length -1].price}
                                                                    index={index}
                                                                    productTotal={products[index]}
                                                                />
                                                            :
                                                                <CardProduct 
                                                                    key={id} id={id}
                                                                    nombre={title} nombreSEO={title_seo}
                                                                    precio={price} precioLista={sale_price}
                                                                    foto={images.length >= 1 ? images[0].url : NO_IMAGE} descuento={discount}
                                                                    review={reviews.score} comments={reviews.total_reviews}
                                                                    shipping_price={shipping.is_free} fulfillment={features.fulfillment} super_express={features.super_express}
                                                                    categories={categories} brand={brand}
                                                                    telmexMonths={telmex_months[telmex_months.length -1].month} telmexPrice={telmex_months[telmex_months.length -1].price}
                                                                    index={index}
                                                                    productTotal={products[index]}
                                                                />
                                                                
                                                        :
                                                            <CardProduct 
                                                                key={id} id={id}
                                                                nombre={title} nombreSEO={title_seo}
                                                                precio={price} precioLista={sale_price}
                                                                foto={images.length >= 1 ? images[0].url : NO_IMAGE} descuento={discount}
                                                                review={reviews.score} comments={reviews.total_reviews}
                                                                shipping_price={shipping.is_free} fulfillment={features.fulfillment} super_express={features.super_express}
                                                                categories={categories} brand={brand}
                                                                telmexMonths={telmex_months[telmex_months.length -1].month} telmexPrice={telmex_months[telmex_months.length -1].price}
                                                                index={index}
                                                                productTotal={products[index]}
                                                            />
                                            :
                                                <CardProduct 
                                                    key={id} id={id}
                                                    nombre={title} nombreSEO={title_seo}
                                                    precio={price} precioLista={sale_price}
                                                    foto={images.length >= 1 ? images[0].url : NO_IMAGE} descuento={discount}
                                                    review={reviews.score} comments={reviews.total_reviews}
                                                    shipping_price={shipping.is_free} fulfillment={features.fulfillment} super_express={features.super_express}
                                                    categories={categories} brand={brand}
                                                    index={index}
                                                    productTotal={products[index]}
                                                />
                                    )
                                }
                            </div>
                            {typeof(link) !== 'function'
                                ? <Link to={link.split("/")[3] != undefined ? `${link.replace('/l/', '/landing/').replace('/p/', '/landing/')}/` : `${link.replace('/l/', '/landing/').replace('/p/', '/landing/')}/${name.trim().replace(/ /g,'-')}/`} className="verMas btnVer">Ver más</Link>
                                : null
                            }
                        </div>
                    </div>
                )
            }
        </>
    )
}


//- Contenedor de productos tipo scroll V1
export function RecomendationScrollHome({carruseles}){    
    return(
        <>
            {
                carruseles.map( ({nombre, link, productos}, index) => 
                    <div className="moduleCarruselHome" key={index}>
                        <div className="container contScroll">
                            <Link to={link.split("/")[3] != undefined ? `${link.replace('/l/', '/landing/').replace('/p/', '/landing/')}/` : `${link.replace('/l/', '/landing/').replace('/p/', '/landing/')}/${nombre.trim().replace(/ /g,'-')}/`} className="h3 verMas">{nombre}</Link>
                            {nombre && <div className="borderRed"></div>}
                            <div className="cardGrid " >
                                <div className="scrollNav">
                                    {
                                        productos?.map(({id, nombre, precio, precioLista, foto, descuento}, ind) => 
                                            <CardProduct 
                                                key={id}
                                                id={id}
                                                nombre={nombre}
                                                precio={precio}
                                                precioLista={precioLista}
                                                foto={foto}
                                                descuento={descuento}
                                                index={ind}
                                                productTotal={productos[ind]}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            <Link to={link.split("/")[3] != undefined ? `${link.replace('/l/', '/landing/').replace('/p/', '/landing/')}/` : `${link.replace('/l/', '/landing/').replace('/p/', '/landing/')}/${nombre.trim().replace(/ /g,'-')}/`} className="verMas btnVer">Ver más</Link>
                        </div>
                    </div>
                )
            }
        </>
    )
}


//- Contenedor de productos tipo scroll V2
export function RecomendationScrollHomeNew({carruseles}){    
    return(
        <>
            {
                carruseles.map( ({name, link, products}, index) => 
                    <div className="moduleCarruselHome" key={index}>
                        <div className="container contScroll">
                            <Link to={link.split("/")[3] != undefined ? `${link.replace('/l/', '/landing/').replace('/p/', '/landing/')}/` : `${link.replace('/l/', '/landing/').replace('/p/', '/landing/')}/${name.trim().replace(/ /g,'-')}/`} className="h3 verMas">{name}</Link>
                            {name && <div className="borderRed"></div>}
                            <div className="cardGrid " >
                                <div className="scrollNav">
                                    {
                                        products?.map(({id, title, title_seo, price, sale_price, images, discount, reviews, shipping, features, categories, brand, telmex_months, payment_methods}, index) => 
                                            telmex_months.length
                                                ?
                                                    payment_methods
                                                        ?
                                                            payment_methods.find(idProd => idProd.id === 4468).promotion.length
                                                                ?
                                                                    <CardProduct 
                                                                        key={id} id={id}
                                                                        nombre={title} nombreSEO={title_seo}
                                                                        precio={price} precioLista={sale_price}
                                                                        foto={images.length >= 1 ? images[0].url : NO_IMAGE} descuento={discount}
                                                                        review={reviews.score} comments={reviews.total_reviews}
                                                                        shipping_price={shipping.is_free} fulfillment={features.fulfillment} super_express={features.super_express}
                                                                        categories={categories} brand={brand} 
                                                                        creditCardMonths={payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments[payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments.length -1].months}
                                                                        creditCardPrice={payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments[payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments.length -1].price}
                                                                        telmexMonths={telmex_months[telmex_months.length -1].month} telmexPrice={telmex_months[telmex_months.length -1].price}
                                                                        index={index}
                                                                        productTotal={products[index]}
                                                                    />
                                                                :
                                                                    <CardProduct 
                                                                        key={id} id={id}
                                                                        nombre={title} nombreSEO={title_seo}
                                                                        precio={price} precioLista={sale_price}
                                                                        foto={images.length >= 1 ? images[0].url : NO_IMAGE} descuento={discount}
                                                                        review={reviews.score} comments={reviews.total_reviews}
                                                                        shipping_price={shipping.is_free} fulfillment={features.fulfillment} super_express={features.super_express}
                                                                        categories={categories} brand={brand}
                                                                        telmexMonths={telmex_months[telmex_months.length -1].month} telmexPrice={telmex_months[telmex_months.length -1].price}
                                                                        index={index}
                                                                        productTotal={products[index]}
                                                                    />
                                                                    
                                                            :
                                                                <CardProduct 
                                                                    key={id} id={id}
                                                                    nombre={title} nombreSEO={title_seo}
                                                                    precio={price} precioLista={sale_price}
                                                                    foto={images.length >= 1 ? images[0].url : NO_IMAGE} descuento={discount}
                                                                    review={reviews.score} comments={reviews.total_reviews}
                                                                    shipping_price={shipping.is_free} fulfillment={features.fulfillment} super_express={features.super_express}
                                                                    categories={categories} brand={brand}
                                                                    telmexMonths={telmex_months[telmex_months.length -1].month} telmexPrice={telmex_months[telmex_months.length -1].price}
                                                                    index={index}
                                                                    productTotal={products[index]}
                                                                />
                                                :
                                                    <CardProduct 
                                                        key={id} id={id}
                                                        nombre={title} nombreSEO={title_seo}
                                                        precio={price} precioLista={sale_price}
                                                        foto={images.length >= 1 ? images[0].url : NO_IMAGE} descuento={discount}
                                                        review={reviews.score} comments={reviews.total_reviews}
                                                        shipping_price={shipping.is_free} fulfillment={features.fulfillment} super_express={features.super_express}
                                                        categories={categories} brand={brand}
                                                        index={index}
                                                        productTotal={products[index]}
                                                    />
                                        )
                                    }
                                </div>
                            </div>
                            <Link to={link.split("/")[3] != undefined ? `${link.replace('/l/', '/landing/').replace('/p/', '/landing/')}/` : `${link.replace('/l/', '/landing/').replace('/p/', '/landing/')}/${name.trim().replace(/ /g,'-')}/`} className="verMas btnVer">Ver más</Link>
                        </div>
                    </div>
                )
            }
        </>
    )
}


//- Contenedor de productos tipo slider actual claroshop V1
export function RecomendationSliderHome({carruseles}){
    const settings = {
        arrows: true,
        dots: false,
        infinite: false,
        speed: 500,
        swipe: true,
        touchMove: true,
        centerPadding: "60px",
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            }
        ]
    };
    return(
        <>
            {
                carruseles.map( ({nombre, link, productos}, index) => 
                    <div className="moduleCarruselHome sinBG" key={index}>
                        <div className="container contRecomendation">
                            <h2 className="h3">{nombre} <Link to={link.split("/")[3] != undefined ? `${link.replace('/l/', '/landing/').replace('/p/', '/landing/')}/` : `${link.replace('/l/', '/landing/').replace('/p/', '/landing/')}/${nombre.trim().replace(/ /g,'-')}/`} className="verMas">Ver más</Link></h2>
                            {nombre && <div className="borderT"></div> }
                            <div className="contProductoRecomend" >
                                <div className="productContent sliderView">
                                    <Slider {...settings}>
                                        {
                                            productos.map(({id, nombre, precio, precioLista, foto, descuento}, ind) => 
                                                <CardProduct 
                                                    key={id}
                                                    id={id}
                                                    nombre={nombre}
                                                    precio={precio}
                                                    precioLista={precioLista}
                                                    foto={foto ? foto : NO_IMAGE}
                                                    descuento={descuento}
                                                    index={ind}
                                                    productTotal={productos[ind]}
                                                />
                                            )
                                        }
                                    </Slider>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                )
            }
        </>
    )
}

//- Contenedor de productos tipo slider actual claroshop V2
export function RecomendationSliderHomeNew({carruseles}){
    const settings = {
        arrows: true,
        dots: false,
        infinite: false,
        speed: 500,
        swipe: true,
        touchMove: true,
        centerPadding: "60px",
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            }
        ]
    };
    return(
        <ErrorBoundary>
            {
                carruseles?.map( ({name, link, products}, index) => 
                    <div className="moduleCarruselHome sinBG" key={index}>
                        <div className="container contRecomendation">
                            { typeof(link) !== 'function'
                                ?
                                    <h2 className="h3">{name} {/*<Link to={link?.split("/")[3] != undefined ? `${link?.replace('/l/', '/landing/')?.replace('/p/', '/landing/')}/`  : `${link?.replace('/l/', '/landing/')?.replace('/p/', '/landing/')}/${name?.trim()?.replace(/ /g,'-')}/`} className="verMas">Ver más</Link>*/}</h2>
                                : null
                            }
                            {/*name && <div className="borderT"></div> */}
                            <div className="contProductoRecomend" >
                                <div className="productContent sliderView">
                                    <Slider {...settings}>
                                        {
                                            products?.map(({id, title, title_seo, price, sale_price, images, discount, reviews, shipping, features, categories, brand, telmex_months, payment_methods}, index) => 
                                                telmex_months.length 
                                                    ?
                                                        payment_methods
                                                            ?
                                                                payment_methods.find(idProd => idProd.id === 4468).promotion.length
                                                                    ?
                                                                        <CardProduct 
                                                                            key={id} id={id}
                                                                            nombre={title} nombreSEO={title_seo}
                                                                            precio={price} precioLista={sale_price}
                                                                            foto={images.length >=1 ? images[0].url : NO_IMAGE} descuento={discount}
                                                                            review={reviews.score} comments={reviews.total_reviews}
                                                                            shipping_price={shipping.is_free} fulfillment={features.fulfillment} super_express={features.super_express}
                                                                            categories={categories} brand={brand} 
                                                                            creditCardMonths={payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments[payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments.length -1].months}
                                                                            creditCardPrice={payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments[payment_methods.find(idProd => idProd.id === 4468).promotion[0].monthly_payments.length -1].price}
                                                                            telmexMonths={telmex_months[telmex_months.length -1].month} telmexPrice={telmex_months[telmex_months.length -1].price}
                                                                            index={index}
                                                                            productTotal={products[index]}
                                                                        />
                                                                    :
                                                                        <CardProduct 
                                                                            key={id} id={id}
                                                                            nombre={title} nombreSEO={title_seo}
                                                                            precio={price} precioLista={sale_price}
                                                                            foto={images.length >= 1 ? images[0].url : NO_IMAGE} descuento={discount}
                                                                            review={reviews.score} comments={reviews.total_reviews}
                                                                            shipping_price={shipping.is_free} fulfillment={features.fulfillment} super_express={features.super_express}
                                                                            categories={categories} brand={brand}
                                                                            telmexMonths={telmex_months[telmex_months.length -1].month} telmexPrice={telmex_months[telmex_months.length -1].price}
                                                                            index={index}
                                                                            productTotal={products[index]}
                                                                        />
                                                                        
                                                                :
                                                                    <CardProduct 
                                                                        key={id} id={id}
                                                                        nombre={title} nombreSEO={title_seo}
                                                                        precio={price} precioLista={sale_price}
                                                                        foto={images.length >= 1 ? images[0].url : NO_IMAGE} descuento={discount}
                                                                        review={reviews.score} comments={reviews.total_reviews}
                                                                        shipping_price={shipping.is_free} fulfillment={features.fulfillment} super_express={features.super_express}
                                                                        categories={categories} brand={brand}
                                                                        telmexMonths={telmex_months[telmex_months.length -1].month} telmexPrice={telmex_months[telmex_months.length -1].price}
                                                                        index={index}
                                                                        productTotal={products[index]}
                                                                    />
                                                    :
                                                        <CardProduct 
                                                            key={id} id={id}
                                                            nombre={title} nombreSEO={title_seo}
                                                            precio={price} precioLista={sale_price}
                                                            foto={images.length >= 1 ? images[0].url : NO_IMAGE} descuento={discount}
                                                            review={reviews.score} comments={reviews.total_reviews}
                                                            shipping_price={shipping.is_free} fulfillment={features.fulfillment} super_express={features.super_express}
                                                            categories={categories} brand={brand}
                                                            index={index}
                                                            productTotal={products[index]}
                                                        />
                                            )
                                        }
                                    </Slider>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                )
            }
        </ErrorBoundary>
    )
}