import React from 'react';
import { BASE_PATH_PORTAL, BASE_PORTAL } from 'constants/general';
import './style.sass';
import { NavLink, useHistory } from 'react-router-dom';
import { useLogout } from 'hooks/useLogin'

export function MenuMiCuenta() {

    const history = useHistory();
    const { logOut } = useLogout()

    return (
        <nav className="navigationMenuMyAccount">
            <ul className="ulMic">
                <li>Mi Cuenta</li>
            </ul>

            <ul>
                {/* <li><a href={`/mi-cuenta/mis-datos/`  activeClassName="active" className="aPer"} >Mi perfil</a> </li> */}
                <li><NavLink to="/mi-cuenta/direcciones-envio/" activeClassName="active" className="aDir">Mis direcciones</NavLink></li>
                {/* <li><a href={`/mi-cuenta/formas-pago/`} activeClassName="active" className="aFp">Mis formas de pago</a></li> */}
                <li><a href={"https://credito.sanborns.com.mx/"} activeClassName="active" className="aCre">Mi crédito SANBORNS</a></li>
                <li><NavLink to="/mi-cuenta/mis-pedidos/dias=30/pagina=1" activeClassName="active" className={history.location.pathname.includes("/mi-cuenta/mis-pedidos/")? "active aPe": "aPe"} >Mis pedidos</NavLink></li>
                <li><NavLink to="/mi-cuenta/listadeseos/" activeClassName="active" className="aWl" aria-label="Mi lista de deseos" alt="Mi lista de deseos">Mi lista de deseos</NavLink></li>
                <li><NavLink to="/mi-cuenta/historial-navegacion/" activeClassName="active" className="aHistorial" aria-label="Historial de navegación" alt="Historial de Navegación">Historial de navegación</NavLink></li>
            </ul>

            <ul>
                <li><div className="anchorMenuMyAccount" onClick={logOut} >Cerrar sesión</div></li>
            </ul>
        </nav>
    )
}