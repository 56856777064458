import React, {useState, useEffect} from 'react'
import CardProductCart from 'components/Cart/CardProductCart'
import ResumeCart from 'components/Cart/ResumeCart'
import ErrorType from 'pages/Error/ErrorType'

export function CartMobile (props) {

    const {
        superCart,
        superRawCart,
        superErrorText,
        modalRemove,
        updateProduct,
        reciveDirection,
        submitCart,
        isMobile
    } = props
    
    
    const [cart, setCart] = useState({})
    useEffect(()=>{
        //console.log('superCart ',superCart)
        if(superCart){
            setCart(superCart)
        }
    },[superCart])
    
    const [rawCart, setRawCart] = useState({})
    useEffect(()=>{
        //console.log('superRawCart ',superRawCart)
        if(superRawCart){
            setRawCart(superRawCart)
            if(superRawCart.data){
                if(superRawCart.data.data){
                    if(!superRawCart.data.data.products){
                        //console.log(' :(')
                        setRawCart({statusCode: 404})
                    }
                }
            }
        }
    },[superRawCart])


    const [errorText, setErrorText] = useState('')
    useEffect(()=>{
        //console.log('superErrorText ',superErrorText)
        if(superErrorText){
            setErrorText(superErrorText)
        }
    },[superErrorText])


    return(
        <>
            <main>
                <section className="cartShop">
                    {cart
                        ?    
                            <div className="container">
                                { rawCart &&
                                    rawCart.statusCode === 200 || rawCart.statusCode === 201
                                        ?
                                            <>
                                                <div className="contentCardProductCart">
                                                    {cart.products
                                                        ?
                                                            cart.products.map(({id, title, images, cart_quantity, delivery_date, stock, status, selected_size_color, features, price, sale_price, discount},ind) =>
                                                                <CardProductCart
                                                                    key={ind}
                                                                    id_Product={id}
                                                                    images={images}
                                                                    cart_quantity={cart_quantity}
                                                                    delivery_date={delivery_date}
                                                                    title={title}
                                                                    parentStock={stock}
                                                                    status={status}
                                                                    selected_size_color={selected_size_color}
                                                                    features={features}
                                                                    price={price}
                                                                    sale_price={sale_price}
                                                                    discount={discount}
                                                                    updateCant={updateProduct}
                                                                    removeButton={modalRemove}
                                                                    reciveDirection={reciveDirection}
                                                                />
                                                            )
                                                        :  null       
                                                    }
                                                </div>
                                                
                                                <ResumeCart
                                                    reciveDirection={reciveDirection}
                                                    total_items={cart.total_items}
                                                    shipping={cart.shipping}
                                                    total_price={cart.total_price}
                                                    submitCart={submitCart} 
                                                    isMobile={isMobile}
                                                />
                                            </> 
                                        : null
                                }

                                <ErrorType
                                    section="cart"
                                    codeStatus={rawCart.statusCode}
                                    errorText={errorText}
                                />
                            </div>
                        : null
                    }
                </section>
            </main>
        </>
    )
}