import React, { useState, useEffect } from 'react';
import { getPedido, postCreateTiket, LoginAyudaDinamica } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import '../detallePedido/detallePedido.sass';
import './ayudaDinamica.sass';
import { ModalError, ModalEstasSeguro, ModalEstasSeguroProducto } from './vista4-modal';
import ModalPortal from 'components/Modal';
import { FORMA_PAGO_REEMBOLSABLE, PEDIDOS_PENDIENTES_AUTORIZAR, PEDIDOS_PENDIENTES_PAGO, LABEL_PREPARANDO_PRODUCTO, LABEL_AUTORIZADO, PEDIDOS_PRODUCTO_ENTREGADO } from 'constants/general';
import Loading from 'components/Loading';
import { useStorage64 } from 'hooks/useStorageBase64';
import { removeDuplicates } from 'functions/removeDuplicates';
import { useIsMobile } from 'hooks/useIsMobile';
import { MotivoCancelacionDesktop } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/AD-Desktop/vista3-motivo-desktop.js';
import { USERNAME, PASSWORD, PUBLICKEY } from 'constants/general';

export function MotivoCancelacionMovil() {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);

    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [crearTicket, setCrearTicket] = useState(false);
    const [orderNumber, setOrderNumber] = useState(0);
    const [productos, setProductos] = useState(false);
    const [estatusPedido, setEstatusPedido] = useState(0);

    const [valorInput, setValorInput] = useState("");
    const [contador, setContador] = useState(0);
    const [row, setRow] = useState(1);
    const [showModal, SetShowModal] = useState(false);
    const [showModalError, SetShowModalError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [remover, setRemover] = useState(false);
    const [idClaro, setIdClaro] = useState(false);
    const [statusProducto, setStatusProducto] = useState(0);
    const [guia, setGuia] = useState(0);
    const [saveIdStatusProducto, setSaveIdStatusProducto] = useState(false);
    const [productoUnico, setProductoUnico] = useState(false);
    const [formaPago, setFormaPago] = useState(false);
    const profileData = useStorage64('profileData');
    const [, setSaveStatus] = useStorage64('saveStatus', '');
    const [saveStatusProducto,] = useStorage64('saveStatusProducto', '');
    const [, setStorageMotivoEntregado] = useStorage64('storageMotivoEntregado', '');
    const [, setInformacionStorage] = useStorage64("infoTicket", "");

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido && resultadoPedido.pedido) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido])

    // obtengo el id del usuario del storage
    useEffect(() => {
        if (profileData != "undefined") {
            setIdClaro(profileData[0].idClaro);
        }
    }, [profileData])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // get pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido)
    }, [])

    useEffect(() => {
        let productosInterno = [];
        let producto = [];
        let productoGuia = [];
        let productoIdStatus = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido) {
                if (resultadoPedido.pedido && Number(idProductoEstate) === 0) {

                    resultadoPedido.pedido[0].products.map((elem, i) =>
                        productosInterno.push({ "id": elem.related_product_id })
                    )
                    setProductos(productosInterno);
                    setOrderNumber(resultadoPedido.pedido[0].order_number);
                    setEstatusPedido(resultadoPedido.pedido[0].axxi.status_axxi_id);
                    setSaveStatus(resultadoPedido.pedido[0].axxi.status_axxi_id);
                    setFormaPago(resultadoPedido.pedido[0].payment_method.id);

                }
                // recorre por producto
                else if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {

                    if (resultadoPedido.pedido[0]) {

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && producto.push({ "id": elem.related_product_id })
                        )

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoGuia.push({ "code": elem.tracking.code })
                        )

                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && productoIdStatus.push({ "status_id": elem.status_id })
                        )

                        let limpiaProducto = removeDuplicates(producto, idProductoEstate);
                        setProductoUnico(limpiaProducto);

                        setStatusProducto(saveStatusProducto);
                        let limpiaGuia = removeDuplicates(productoGuia, idProductoEstate);
                        setGuia(limpiaGuia[0].code);

                        setEstatusPedido(resultadoPedido.pedido[0].axxi.status_axxi_id);
                        setSaveStatus(resultadoPedido.pedido[0].axxi.status_axxi_id);
                        setOrderNumber(resultadoPedido.pedido[0].order_number);
                        setFormaPago(resultadoPedido.pedido[0].payment_method.id);
                        setSaveIdStatusProducto(productoIdStatus[0].status_id);
                    }
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
                LoginAyudaDinamica({ username: USERNAME, password: PASSWORD, publicKey: PUBLICKEY })
                setTimeout(() => {
                    getPedido()
                        .then(setResultadoPedido)
                }, 1000);
            }
        }
    }, [resultadoPedido])

    // regresar pagina anterior
    const onClose = () => {
        history.goBack(-1);
    }

    // continuar eliminando pedido, llama a modal estas seguro
    const handleContinuar = () => {
        // producto entregado
        if (PEDIDOS_PRODUCTO_ENTREGADO.includes(saveIdStatusProducto)) {
            setStorageMotivoEntregado(valorInput);
            history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/adjuntar-fotografias");
        }
        else {
            // pedido autorizado - producto preparando tu producto -forma de pago NO REEMBOLSABLE - sin guia
            if ((LABEL_PREPARANDO_PRODUCTO === String(statusProducto).toLowerCase() && !FORMA_PAGO_REEMBOLSABLE.includes(formaPago) && guia === null) ||
                (LABEL_AUTORIZADO === String(statusProducto).toLowerCase() && !FORMA_PAGO_REEMBOLSABLE.includes(formaPago) && guia === null) ||
                (idProductoEstate !== 0 && !PEDIDOS_PRODUCTO_ENTREGADO.includes(saveIdStatusProducto) && !FORMA_PAGO_REEMBOLSABLE.includes(formaPago))) {
                setInformacionStorage({
                    "reason_id": 7, "order_id": orderNumber, "order_number": orderNumber, "client_id": idClaro, "description": valorInput, "products_to_cancel": productoUnico,
                    "ticket_type_id": 1, "additional_details": [], "action_id": 0
                })
                history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/datos-bancarios");
            }
            else {
                showModalRemove();
            }
        }
    }

    const showModalRemove = () => SetShowModal(true);

    // cerrar modal de error
    const handleCloseError = () => SetShowModalError(false);

    // actualizar valor del texto en input
    const cambiarValorInput = (valor) => {
        let texto = valor.target.value;
        let cont = String(texto).length;

        setValorInput(texto);
        setContador(cont);

        var maxrows = 8;
        var txt = texto;
        var cols = valor.target.cols;
        let arraytxt = txt.split('\n');
        let rows = arraytxt.length;

        for (let i = 0; i < arraytxt.length; i++) {
            rows += Number(arraytxt[i].length / cols);
        }
        if (rows > maxrows) { setRow(maxrows); }
        else { setRow(rows); }
    }

    // si desea cancelar pedido, post crear ticket
    const siRemove = () => {
        SetShowModal(false);
        setRemover(true);

        let reason_id = 7;
        let order_id = orderNumber;
        let order_number = orderNumber;
        let client_id = idClaro;
        let description = valorInput;
        let ticket_type_id = 1; // siempre es 1

        // si es pendiente de pago o pendiente de autorizar
        let refound_details = []; // 
        let additional_details = []; // 
        let action_id = 0;

        // pedido pendiente de pago o pendiente de autorizar
        if (estatusPedido === PEDIDOS_PENDIENTES_PAGO || PEDIDOS_PENDIENTES_AUTORIZAR.includes(estatusPedido)) {
            let products_to_cancel = productos;
            postCreateTiket({
                reason_id,
                order_id,
                order_number,
                client_id,
                description,
                products_to_cancel,
                ticket_type_id,
                refound_details,
                additional_details,
                action_id
            })
                .then(setCrearTicket)
        }
        // pedido autorizado - producto preparando tu producto - forma de pago diferente de oxxo-seven-deposito-tienda - sin guia
        else if ((LABEL_PREPARANDO_PRODUCTO === String(statusProducto).toLowerCase() && FORMA_PAGO_REEMBOLSABLE.includes(formaPago) && guia === null) ||
            (LABEL_AUTORIZADO === String(statusProducto).toLowerCase() && FORMA_PAGO_REEMBOLSABLE.includes(formaPago) && guia === null) ||
            (idProductoEstate !== 0 && !PEDIDOS_PRODUCTO_ENTREGADO.includes(saveIdStatusProducto) && FORMA_PAGO_REEMBOLSABLE.includes(formaPago))) {
            let products_to_cancel = productoUnico;
            postCreateTiket({
                reason_id,
                order_id,
                order_number,
                client_id,
                description,
                products_to_cancel,
                ticket_type_id,
                refound_details,
                additional_details,
                action_id
            })
                .then(setCrearTicket)
        }
        else {
            if (idProductoEstate != 0) {
                let products_to_cancel = productoUnico;
                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id
                })
                    .then(setCrearTicket)
            }
            else {
                let products_to_cancel = productos;
                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id
                })
                    .then(setCrearTicket)
            }

        }
    }

    // cerrar modal y regresar a mis pedidos
    const noRemove = () => {
        SetShowModal(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    // regresar a pedido en modal error
    const regresarPedidos = () => {
        SetShowModalError(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    // cerrar modal
    const handleClose = () => SetShowModal(false);

    // si la respuesta es ticket creado === 201 redirecciona a cancelacion exitosa
    useEffect(() => {
        if (crearTicket) {
            if (crearTicket.metadata) {
                if (Number(crearTicket.metadata.http_status) === 201 || Number(crearTicket.metadata.http_status) === 200) {
                    if (idProductoEstate != 0) {
                        history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/cancelacion-exitosa");
                    }
                    else if (idProductoEstate === 0) {
                        history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/cancelacion-exitosa");
                    }
                }
            }
            else {
                SetShowModalError(true);
            }
        }
    }, [crearTicket])

    // mostrar el loading mientras se crea el ticket
    useEffect(() => {
        if (remover) {
            if (crearTicket) {
                setLoading(false);
                setRemover(false);
            }
            else {
                setLoading(true);
            }
        }
    }, [remover, crearTicket])

    return (
        <>
            { loading && <Loading />}

            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">¿Cuál es tu motivo?</p>
                    </div>
                    <div className="dataModal">
                        <div className="boxMotivoCancelacion">
                            <textarea className={"inputMotivo"} placeholder="Escribe tu motivo de cancelación" cols={40} rows={row} value={valorInput} onChange={cambiarValorInput} type="text" maxLength={300} minLength={10}></textarea>
                            <div className="contadorCaracteres"> {contador}/300</div>

                            <div className="boxBtn">
                                <button className="btnContinuar" onClick={handleContinuar}>Continuar</button>
                            </div>
                        </div>
                    </div>

                    {/* mostrar modal estas seguro que deseas cancelar*/}
                    {(showModal && idProductoEstate === 0) &&
                        <ModalPortal type="modalSB" onClose={handleClose} >
                            <ModalEstasSeguro remvoeAction={siRemove} awaitAction={noRemove} />
                        </ModalPortal>
                    }

                    {/* mostrar modal estas seguro que deseas cancelar por producto*/}
                    {(showModal && idProductoEstate !== 0) &&
                        <ModalPortal type="modalSB" onClose={handleClose} >
                            <ModalEstasSeguroProducto remvoeAction={siRemove} awaitAction={noRemove} />
                        </ModalPortal>
                    }

                    {showModalError &&
                        <ModalPortal type="modalSB" onClose={handleCloseError} >
                            <ModalError okRegresar={regresarPedidos} />
                        </ModalPortal>
                    }
                </div>
            </div>

        </>
    )
}

export function MotivoCancelacion(props) {
    const { isMobile } = useIsMobile();

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <MotivoCancelacionDesktop />
                    : isMobile === 'movile'
                        ? null
                        : <MotivoCancelacionMovil />
                : null
            }
        </>
    )
}