import {POWER_REVIEWS} from 'constants/general'

// PowerReview Producto
export function powerReviewsProduct(props){
    //console.log('Producto-DL: ', props)
    const {
        id,
        title,
        description,
        sale_price,
        brand,
        image_url,
        categories,
        sku,
        ean,
        size_color,
        stock,
        catPadre,
        catHija,
        catNieta
    } = props

    let stockBoolean = stock >= 1 ? true : false
    let arrayVariants = []
    let productPR

    // if(size_color?.length >= 1 ){
    //     size_color.forEach(sizeC => {
    //         //console.log('::::  ' ,sizeC.color)
    //         sizeC.sizes.forEach(variantSC =>{
    //             arrayVariants.push({
    //                 name: sizeC.color+' - '+variantSC.size,
    //                 image_url: image_url,
    //                 upc: variantSC.variant,
    //                 page_id_variant: id
    //             })

    //         })
    //     })
    // }

    if(size_color?.length >= 1 ){
        productPR = {
            api_key: POWER_REVIEWS?.apikey,
            locale: POWER_REVIEWS?.locale,
            merchant_group_id: POWER_REVIEWS?.mGroupID,
            merchant_id: POWER_REVIEWS?.mID,
            page_id: id,
            product: {
                name: title,
                url: window.location.href,
                image_url: image_url,
                description: description,
                category_name: categories,
                manufacturer_id: sku,
                upc: ean,
                brand_name: brand,
                price: sale_price,
                in_stock: stockBoolean
            },
            variants: arrayVariants
        } 
    }else{
        productPR = {
            api_key: POWER_REVIEWS?.apikey,
            locale: POWER_REVIEWS?.locale,
            merchant_group_id: POWER_REVIEWS?.mGroupID,
            merchant_id: POWER_REVIEWS?.mID,
            page_id: id,
            product: {
                name: title,
                url: window.location.href,
                image_url: image_url,
                description: description,
                category_name: categories,
                manufacturer_id: sku,
                upc: ean,
                brand_name: brand,
                price: sale_price,
                in_stock: stockBoolean
            }
        }
    }


    window.pwr = window.pwr || function () {
        (window.pwr.q = window.pwr.q || []).push(arguments); 
    };
    
    
    setTimeout(function(){
        window.pwr("render", productPR)
        // console.log("render: ", productPR)
    },100)
}

// PowerReview Producto
export function powerReviewsWrite(){

    let writePR = {
        api_key: POWER_REVIEWS?.apikey,
        locale: POWER_REVIEWS?.locale,
        merchant_group_id: POWER_REVIEWS?.mGroupID,
        merchant_id: POWER_REVIEWS?.mID,
        on_render: function () {
            //console.log("test");
        },
        components: {
            Write: 'pr-write'
        }
    }

    window.pwr = window.pwr || function () {
        (window.pwr.q = window.pwr.q || []).push(arguments); 
    };

    setTimeout(function(){
        window.pwr("render", writePR)
        //console.log("render: ", writePR)
    },100)
}