import React, { useState, useEffect } from 'react';
import './style.sass';
import { Helmet } from "react-helmet";
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';
import { useStorage64 } from 'hooks/useStorageBase64';
import { usePromoSears } from 'hooks/usePromoSears'
import { useServices } from 'hooks/useServices';
import { dataLayerProduct } from 'functions/dataLayerFunction';
import { powerReviewsProduct } from 'functions/powerReviewsFunction';
import { decodeStorage } from 'functions/storageBase64';
import { repareImage, repareTextHtml } from 'functions/repareDataService'
// import { decodeStorage, encodeStorage } from 'functions/storageBase64';
import getProduct, { getProductNew } from 'services/getProduct';
import getStarsOpinion from 'services/getStarsOpinion';
import { NO_IMAGE, VARIANTS_PER_PORTAL, TIME_PIXEL } from 'constants/general'
import { ErrorBoundary } from 'components/Error/ErrorBoundary'
import { useStore } from 'context/storeProvider';
import { getReviewAdapter } from 'adapters/reviews_Adapter';

import Header from 'pages/Header/HeaderNavegacion';
import Footer from 'pages/Footer/FooterDesktop';
import BarraCP from 'components/BarCP/BarHeaderCP';
import Loading from 'components/Loading';
import { DetalleProductoDesktop } from 'pages/Producto/ProductoDesktop/indexSuspense';
import { DetalleProductoMobile } from 'pages/Producto/ProductoMobile';
import { postPixelProduct } from 'services/pixel';

// import BarraSelectorTienda from 'components/SelectorTiendas/selectorTiendas';
//import { postPromoValidator } from 'services/Promotions';
// import { DownloadApp } from 'components/DownloadApp';


function DetalleProducto() {
    const params = useParams();
    const codigoP = decodeStorage('codigoPostal');

    const { isMobile } = useIsMobile();
    const [mostrarResumen, setMostrarResumen] = useState(true);
    const [mostrarSimilares, setMostrarSimilares] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const [yaVisitoProductos, setYaVisitoProductos] = useStorage64('yaVisitoProductos', 0);
    const [yaPresionoSimilares, setYaPresionoSimilares] = useStorage64('yaPresionoSimilares', 0);
    // const handleVisitoP = decodeStorage('yaVisitoProductos');
    // const handlePresionoNuevo = decodeStorage('yaPresionoSimilares');
    const [flagNuevo, setFlagNuevo] = useState(false);

    const contextProduct = useStore()

    const [productData, setProductData] = useState([]);
    const [opinionData, setOpinionData] = useState([]);
    const [codigoPostal, setCodigoPostal] = useState(codigoP);
    const [loading, setLoading] = useState(true);
    const [dataLayerData, setDataLayerData] = useState();
    const [powerRevData, setPowerRevData] = useState();
    const [prodFisrt, setProdFisrt] = useState(false)
    const [callDatalayer, setCallDatalayer] = useState(false)

    const returnScrollLeft = () => {
        let scrollsL = document.querySelectorAll('.contProductoRecomend')?.length;
        if (scrollsL >= 1) {
            let cant = 0;
            for (cant; cant <= (scrollsL - 1); cant++) {
                document.querySelectorAll('.contProductoRecomend')[cant].scrollLeft = 0;
            }
        }
    }
 
    const upPage = () => {
        setTimeout(function(){
            window.scrollTo(0, 0)
        }, 450)
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Product Page');
        window.scrollTo(0, 0);

        setYaVisitoProductos(yaVisitoProductos + 1);

    }, []);

    useEffect(() => {
        if (flagNuevo === true) {
            if (yaPresionoSimilares === 0 && document.querySelector('.spanNuevo')) {
                setYaPresionoSimilares(yaPresionoSimilares + 1);
                document.querySelector('.spanNuevo').remove();
            }
        }
    }, [flagNuevo]);


    useEffect(() => {
        returnScrollLeft();
        handleResumen();
        upPage()

        if(params?.id === String(contextProduct?.product?.click_product?.producto?.id) ){
            // console.log('product0: ', contextProduct?.product?.click_product)
            setLoading(false);
            setProductData(contextProduct?.product?.click_product)
            setOpinionData(contextProduct?.product?.click_product)
            setProdFisrt(true)
            setCallDatalayer(false)
        }
        else if(params?.id === String(JSON.parse( window.sessionStorage.getItem("productTotal") )?.id) ){
            // console.log('product1: ', JSON.parse( window.sessionStorage.getItem("productTotal") ))
            setProductData({ producto: JSON.parse( window.sessionStorage.getItem("productTotal") ) })
            setOpinionData(getReviewAdapter({ producto: JSON.parse( window.sessionStorage.getItem("productTotal") ) }))
            setProdFisrt(true)
            setCallDatalayer(false)
        }
        else{
            setLoading(true);
            setProdFisrt(true)
            setCallDatalayer(false)
            
            // getProductNew({ idProduct: params.id })
            // .then(product => {
            //     // console.log('product1: ', product)
            //     setProductData(product)
            // })
        }
    }, [params.id]);

    const [clickCP, setClickCp] = useState(false)
    useEffect(() => {
        if(clickCP){
            setProdFisrt(true)
            setClickCp(false)
        }
    }, [clickCP])

    const [getReviews, setGetReviews] = useState(false)
    useEffect(()=>{
        if(prodFisrt){
            getProduct({ idProduct: params.id })
            .then(product => {
                // console.log('product2: ', product)
                setProductData(product);
                setOpinionData(getReviewAdapter(product))
                setGetReviews(true)
                setCallDatalayer(true)
                setProdFisrt(false)
            })
        }
    
    },[prodFisrt])

    useEffect(()=>{
        if(getReviews){
            if(productData?.producto?.reviews){
                if(!productData?.producto?.reviews?.stars){
                    getStarsOpinion({ idProduct: params.id })
                    .then(opinion => {
                        setOpinionData(opinion);
                    })
                }
            }
        }
    },[getReviews])

    const { promoDepart, setPromoSears } = usePromoSears()

    const {
        headers,
        statusCode,
        data,
        error,
        // loading
    } = useServices({
        method: 'get',
        url: `/staticJson/producto.json`,
        timeout: 25000,
        withCredentials: false,
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        }
    })

    const [formasPagoArray, setFormasPagoArray] = useState();
    const [formasPagoOrdenadoArray, setFormasPagoOrdenadoArray] = useState();
    const [catPadre, setCatPadre] = useState();
    const [catHija_Hombre, setCatHija_Hombre] = useState();
    const [catHija_Mujer, setCatHija_Mujer] = useState();
    const [catHija_Nino, setCatHija_Nino] = useState();
    const [catHija_Nina, setCatHija_Nina] = useState();
    const [tallasNino, setTallasNino]= useState(); 
    const [tallasNina,setTallasNina]= useState();
    const [tallasHombre,setTallasHombre]= useState();
    const [tallasMujer,setTallasMujer]= useState();
    const [SKUsServicioTecnico,setSKUsServicioTecnico] = useState();

    useEffect(() => {
        if (statusCode === 200) {
            if (data) {
                setSKUsServicioTecnico(data[0]?.SKUservicioTecnico);

                setFormasPagoArray(data[0].formasPago);
                setFormasPagoOrdenadoArray(data[0].formasPagoOrdenado);
                setCatPadre(data[0].guiaTallasCategoriaPadre);
                setCatHija_Hombre(data[0].guiaTallasCategoriaHijoCaballero);
                setCatHija_Mujer(data[0].guiaTallasCategoriaHijoDama);
                setCatHija_Nino(data[0].guiaTallasCategoriaHijoNino);
                setCatHija_Nina(data[0].guiaTallasCategoriaHijoNina);
                setTallasNino(data[0].tallasNino);
                setTallasNina(data[0].tallasNina);
                setTallasHombre(data[0].tallasHombre);
                setTallasMujer(data[0].tallasMujer);

            }
        }
    }, [statusCode, data])


    useEffect(() => {
        if (productData) {
            if (productData?.producto) {
                if (`${location.pathname}` !== `/producto/${productData?.producto?.id}/${productData?.producto?.title_seo}/`) {

                    if (history?.location?.hash) {
                        history.replace(`/producto/${productData?.producto?.id}/${productData?.producto?.title_seo}/${history?.location?.hash}`, null);
                    } else {
                        history.replace(`/producto/${productData?.producto?.id}/${productData?.producto?.title_seo}/`, null);
                    }
                } else {
                    //console.log('igual')
                }
            }
            setPromoSears(productData)
        }
    }, [productData]);


    // promociones inicio


    // const [promoDep, setPromoDep] = useState();
    // useEffect(()=>{
    //     let promotion = {};
    //     if(productData?.producto){
    //         let cat1 = productData?.producto?.categories[0]?.id
    //         let cat2 = productData?.producto?.categories[1]?.id
    //         let cat3 = productData?.producto?.categories[2]?.id
    //         let contCat = []

    //         if(cat1 === undefined && cat2 === undefined && cat3 === undefined){
    //             contCat= []
    //         }else{
    //             contCat = [
    //                 cat1,
    //                 cat2,
    //                 cat3
    //             ]
    //         }

    //         promotion = {
    //             elements:[
    //                 {//sale_price
    //                     price: productData?.producto?.sale_price,
    //                     list_price: productData?.producto?.price,
    //                     sku: productData?.producto?.id,
    //                     store_id: productData?.producto?.store?.id,
    //                     categories: contCat
    //                 }
    //             ]
    //         }
    //         if(productData?.producto?.store?.id === 2430 ){
    //             postPromoValidator(promotion)
    //             .then(setPromoDep)
    //         }
    //     }
    // },[productData])

    // const [promoDepart, setPromoDepart] = useState();
    // useEffect(()=>{
    //     // let idProd = params.id
    //     if(promoDep?.statusCode === 200){
    //         let result = promoDep?.data?.data
    //         if(Object.values(result).length >=1 ){
    //             setPromoDepart( Object.values( Object.values(result)[0] ) );
    //         }
    //         else{
    //             setPromoDepart(null);
    //         }
    //     }else{
    //         setPromoDepart(promoDep?.error);
    //     }
    // },[promoDep])
    // promociones fin

    const [flixMedia, setFlixMedia] = useState()
    const [dataFlixMedia, setDataFlixMedia] = useState()
    //se ejecuta cuadno tiene datos el producto para llenar el datalayer
    useEffect(() => {
        // console.log('productDAta-> ', productData)
        // console.log('callDatalayer-> ', callDatalayer)
        if (productData && callDatalayer) {
            if (productData?.producto) {
                let breadC, ffilment, catPadre, catHija, catNieta;

                if (productData?.producto?.categories) {
                    if (productData?.producto?.categories?.length > 1) {
                        if (productData?.producto?.categories?.length >= 3) {
                            breadC = `${productData?.producto?.categories[2]?.name}/${productData?.producto?.categories[1]?.name}/${productData?.producto?.categories[0]?.name}`;
                            catPadre = productData?.producto?.categories[2]?.name;
                            catHija = productData?.producto?.categories[1]?.name;
                            catNieta = productData?.producto?.categories[0]?.name;
                        } else if (productData?.producto?.categories?.length === 2) {
                            breadC = `${productData?.producto?.categories[1]?.name}/${productData?.producto?.categories[0]?.name}/`;
                            catPadre = ""
                            catHija = productData?.producto?.categories[1]?.name;
                            catNieta = productData?.producto?.categories[0]?.name;
                        } else if (productData?.producto?.categories?.length === 1) {
                            breadC = `${productData?.producto?.categories[0]?.name}/`;
                            catPadre = ""
                            catHija = ""
                            catNieta = productData?.producto?.categories[0]?.name;
                        }
                    }
                    else {
                        breadC = "";
                        catPadre = "";
                        catHija = "";
                        catNieta = "";
                    }
                }
                if (productData?.producto?.features?.fulfillment) {
                    if (productData?.producto?.features.fulfillment?.quantity) {
                        ffilment = true;
                    }
                }
                else {
                    ffilment = false;
                }

                setDataLayerData({
                    id: String(productData?.producto?.id),
                    title: String(productData?.producto?.title),
                    sale_price: String(productData?.producto?.sale_price),
                    price: String(productData?.producto?.price),
                    store: String(productData?.producto?.store.name),
                    brand: String(productData?.producto?.brand),
                    categories: String(breadC),
                    producto_estrellas: String(productData?.producto?.reviews?.score),
                    producto_fulfillment: String(ffilment),
                    producto_digital: String(productData?.producto?.features?.is_digital),
                    sku: String(productData?.producto?.sku),
                    catPadre: String(catPadre),
                    catHija: String(catHija),
                    catNieta: String(catNieta),
                    variante: '',
                    index: 1,
                    quantity: '1',
                    stage: 'release'
                })
                setPowerRevData({
                    id: String(productData?.producto?.id),
                    title: String(productData?.producto?.title),
                    description:  String(productData?.producto?.description),
                    sale_price: String(productData?.producto?.sale_price),
                    brand: String(productData?.producto?.brand),
                    image_url: String(repareImage(productData?.producto?.images ?  productData?.producto?.images[0]?.url :  NO_IMAGE)),
                    categories: String(breadC?.replace(/\//ig, " > ")),
                    sku: String(productData?.producto?.sku),
                    ean: String(productData?.producto?.ean),
                    size_color: productData?.producto?.size_color,
                    stock: productData?.producto?.stock,
                    catPadre: String(catPadre),
                    catHija: String(catHija),
                    catNieta: String(catNieta)
                })
                setLoading(false);
            }
            
            setFlixMedia()
            if (productData) {
                if (productData?.producto) {
                    if (document.getElementById('flix-inpage')) {
                        document.getElementById('flix-inpage').innerHTML = ""
                        if (document.getElementById('flix_hotspots')) {
                            document.getElementById('flix_hotspots').remove()
                        }
                    }
                    setFlixMedia(productData?.producto?.ean)
                    setDataFlixMedia({
                        'brand': productData?.producto?.brand,
                        'ean': productData?.producto?.ean,
                        'sku': productData?.producto?.sku
                    })
                }
            }
        }
    }, [productData, callDatalayer]);

    const [libro, setLibro] = useState(false);
    useEffect(() => {
        setFlixMedia()
        //categorias libro
        const CATEGORIAS_LIBRO = [501,504,505]
        if (productData) {
            if (productData?.producto) {
                if(productData?.producto?.categories){
                    CATEGORIAS_LIBRO?.includes(productData?.producto?.categories[1]?.id) ? setLibro(true) : setLibro(false)
                }
                setFlixMedia(productData?.producto?.ean)
            }
        }
    },[productData]);

    //con el datalyer lleno manda los datos a analytics
    useEffect(() => {
        if (dataLayerData) {
            dataLayerProduct(dataLayerData);
            powerReviewsProduct(powerRevData)
        }
    }, [dataLayerData]);

    const handleResumen = () => {
        setMostrarSimilares(false);
        setMostrarResumen(true);
    };
    const handleSimilares = () => {
        setMostrarResumen(false);
        setMostrarSimilares(true);
        setFlagNuevo(true);
    };

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPostal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar ]);

    const [structData, setStructData] = useState(false)
    useEffect(()=>{
        if(productData?.producto){
            let atributesArray

            if(productData?.producto?.attributes != null){
                atributesArray = `"height": "${productData?.producto?.attributes?.alto_del_empaque}",
                    "width": "${productData?.producto?.attributes?.ancho_del_empaque}",
                    "model": "${productData?.producto?.attributes?.modelo}",
                `
            }else{
                atributesArray = ""
            }

            //imagenes de producto
            let reviewsArr = []
            let reviews
            if(opinionData?.response?.bottomline?.total_review >= 1){
                reviews = opinionData?.response?.reviews?.map((review) => {

                    let contentReview =`{
                        "@type":"Review",
                        "datePublished": "${review?.created_at.split('T')[0]}",
                        "reviewBody": "${repareTextHtml(review?.content?.replace(/\n/gi, " ").replace(/"/gi, ""))}",
                        "name": "${review?.title?.replace(/\n/gi, " ").replace(/"/gi, "")}",
                        "author":{
                            "@type":"Person",
                            "name":"${review?.user?.display_name?.replace(/\n/gi, " ").replace(/"/gi, "")}"
                        },
                        "reviewRating": {
                            "@type": "Rating",
                            "bestRating": "5",
                            "ratingValue": "${review?.score}",
                            "worstRating": "0"
                        }
                    }`
                    return reviewsArr.push(contentReview?.replace(/\s+/g, ' '))
                })
            }

            //imagenes de producto
            let img = []
            let imagenes = productData?.producto?.images?.map((image) => {
                return img.push(`"${image.url}"`)
            })
            
            if(opinionData?.response?.bottomline?.total_review >= 1){
                // console.log('reviewsArr: ',reviewsArr)
                setStructData(
                    `{
                        "@context": "https://schema.org/",
                        "@type": "Product",
                        "name": "${productData?.producto?.title?.replace(/\n/gi, " ").replace(/"/gi, "")}",
                        "image": [${img}],
                        "description": "${productData?.producto?.description?.replace(/\n/gi, " ").replace(/"/gi, "")}",
                        "sku": "${productData?.producto?.sku}",
                        "mpn":"${productData?.producto?.ean}",
                        "productID":"${productData?.producto?.id}",
                        "category":"${productData?.producto?.categories ? productData?.producto?.categories[0]?.name : null}",
                        ${atributesArray}
                        "brand": {
                            "@type": "Brand",
                            "name": "${productData?.producto?.brand}"
                        },
                        "review": [${reviewsArr}],
                        "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue":"${opinionData?.response?.bottomline?.average_score}",
                            "reviewCount": "${opinionData?.response?.bottomline?.total_review}"
                        },
                        "offers": {
                            "@type": "Offer",
                            "url": "https://www.sanborns.com.mx/producto/${productData?.producto?.id}/${productData?.producto?.title_seo?.replace(/\n/gi, " ").replace(/"/gi, "")}/",
                            "priceCurrency": "MXN",
                            "price": "${productData?.producto?.sale_price}",
                            "priceValidUntil": "${productData?.producto?.last_modified?.split(" ")[0]}",
                            "itemCondition": "https://schema.org/UsedCondition",
                            "availability": "https://schema.org/InStock"
                        }
                    }`
                )
            }
            else{
                setStructData(
                    `{
                        "@context": "https://schema.org/",
                        "@type": "Product",
                        "name": "${productData?.producto?.title?.replace(/\n/gi, " ").replace(/"/gi, "")}",
                        "image": [${img}],
                        "description": "${productData?.producto?.description?.replace(/\n/gi, " ").replace(/"/gi, "")}",
                        "sku": "${productData?.producto?.sku}",
                        "mpn":"${productData?.producto?.ean}",
                        "productID":"${productData?.producto?.id}",
                        "category":"${productData?.producto?.categories ? productData?.producto?.categories[0]?.name : null}",
                        ${atributesArray}
                        "brand": {
                            "@type": "Brand",
                            "name": "${productData?.producto?.brand?.replace(/\n/gi, " ").replace(/"/gi, "")}"
                        },
                        "review": {
                            "@type":"Review",
                            "datePublished": "",
                            "reviewBody": "",
                            "name": "",
                            "author":{
                                "@type":"Person",
                                "name":"Usuario de Sanborns"
                            },
                            "reviewRating": {
                                "@type": "Rating",
                                "bestRating": "5",
                                "ratingValue": "4",
                                "worstRating": "0"
                            }
                        },
                        "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue":"4",
                            "reviewCount": "1"
                        },
                        "offers": {
                            "@type": "Offer",
                            "url": "https://www.sanborns.com.mx/producto/${productData?.producto?.id}/${productData?.producto?.title_seo?.replace(/\n/gi, " ").replace(/"/gi, "")}/",
                            "priceCurrency": "MXN",
                            "price": "${productData?.producto?.sale_price}",
                            "priceValidUntil": "${productData?.producto?.last_modified?.split(" ")[0]}",
                            "itemCondition": "https://schema.org/UsedCondition",
                            "availability": "https://schema.org/InStock"
                        }
                    }`
                )
            }
        }
    },[productData, opinionData])


    
    const [preID, setPreId] = useState()
    const [actualID, setActualId] = useState()
    useEffect(()=>{
        setPreId(params?.id)
    },[])
    useEffect(() => {  
        if (params?.id){
            setActualId(params?.id)
            window[`pixelTrack_${params?.id}`] = setTimeout(function(){
                console.log(`historial timeout ${TIME_PIXEL} id `, params?.id)
                postPixelProduct({ idProduct: params.id }) 
            },TIME_PIXEL)
        }
    }, [params?.id])
    useEffect(() => {  
        if(preID != actualID){
            clearTimeout(window[`pixelTrack_${preID}`]); 
            setPreId(actualID)
        }
    }, [actualID])
    

    // useEffect(() => {               
    //     if (productData?.producto){
    //         postPixelProduct({ idProduct: params.id })            
    //     }        
    // }, [params.id, productData])

    return (
        <>
            {loading && <Loading />}

            {productData?.producto &&
                <Helmet>
                    <title>{productData?.producto?.title}</title>
                    <link rel="canonical" href={`https://www.sanborns.com.mx/producto/${productData?.producto?.id}/${productData?.producto?.title_seo}/`} />
                    {/* <link rel="amphtml" href={`https://www.sanborns.com.mx/amp/producto/${productData?.producto?.id}/${productData?.producto?.title_seo}/`} /> */}
                    <meta name="Description" content={`${productData?.producto?.title} Precio Normal: $${productData?.producto?.price}.00 M.N ${productData?.producto?.discount !== 0 ? 'Precio oferta $' + productData?.producto?.sale_price + '.00 M.N' : null} !Compra ahora! ¡Envío Gratis!`} />
                    
                    <meta property="og:title" content={productData?.producto?.title}/>
                    <meta property="og:description" content={`${productData?.producto?.title} Precio Normal: $${productData?.producto?.price}.00 M.N ${productData?.producto?.discount !== 0 ? 'Precio oferta $' + productData?.producto?.sale_price + '.00 M.N' : null} !Compra ahora! ¡Envío Gratis!`}/>
                    <meta property="og:image" content={productData?.producto?.images[0]?.url}/>
                    <meta property="og:url" content={`https://www.sanborns.com.mx/producto/${productData?.producto?.id}/${productData?.producto?.title_seo}`} />
                    <meta property="og:type" content="website" />

                    <meta name="twitter:card" content="product" />
                    <meta name="twitter:site" content="Sanborns" />
                    <meta name="twitter:url" content={`https://www.sanborns.com.mx/producto/${productData?.producto?.id}/${productData?.producto?.title_seo}`} />
                    <meta name="twitter:title" content={productData?.producto?.title}/>
                    <meta name="twitter:description" content={`${productData?.producto?.title} Precio Normal: $${productData?.producto?.price}.00 M.N ${productData?.producto?.discount !== 0 ? 'Precio oferta $' + productData?.producto?.sale_price + '.00 M.N' : null} !Compra ahora! ¡Envío Gratis!`}/>
                    <meta name="twitter:image" content={productData?.producto?.images[0]?.url}/>
                    <meta name="twitter:domain" content="www.sanborns.com.mx" />
                   
                    <link rel="preconnect dns-prefetch" crossorigin href="https://api.yotpo.com" />
                                        
                    <meta name="robots" content="index, follow" />

                    {flixMedia &&
                        productData?.producto?.store?.id === 3496 &&
                            <script type="text/javascript"
                                defer
                                src="https://media.flixfacts.com/js/loader.js"
                                data-flix-distributor="5584"
                                data-flix-language="mx"
                                data-flix-brand={`${dataFlixMedia?.brand}`}
                                data-flix-mpn=""
                                data-flix-ean={`${dataFlixMedia?.ean}`}
                                data-flix-sku={`${dataFlixMedia?.sku}`}
                                data-flix-inpage="flix-inpage"
                                data-flix-button-image=""
                                data-flix-fallback-language=""
                                data-flix-price="">
                            </script>
                    }

                    {structData
                        ?
                            <script type="application/ld+json">
                                { structData }
                            </script>
                        : null
                    }
                </Helmet>
            }

            <ErrorBoundary>
                <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            </ErrorBoundary>

            <ErrorBoundary>
                <div className="barraCpTienda">
                    <section className="barCpResponsive">
                        <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
                    </section>
                </div>
            </ErrorBoundary>

            {isMobile === 'desktop'
                ?
                    <ErrorBoundary>
                        <DetalleProductoDesktop
                            changeDirectionBar={changeDirectionBar}
                            loading={loading}
                            productDataSup={productData}
                            opinionData={opinionData}
                            handleResumen={handleResumen}
                            handleSimilares={handleSimilares}
                            mostrarResumen={mostrarResumen}
                            mostrarSimilares={mostrarSimilares}
                            setLoading={setLoading}
                            promoDepart={promoDepart}
                            formasPagoArray={formasPagoArray}
                            formasPagoOrdenadoArray={formasPagoOrdenadoArray}
                            catPadre={catPadre}
                            catHija_Hombre={catHija_Hombre}
                            catHija_Mujer={catHija_Mujer}
                            catHija_Nino={catHija_Nino}
                            catHija_Nina={catHija_Nina}
                            tallasNino={tallasNino} 
                            tallasNina={tallasNina} 
                            tallasHombre={tallasHombre} 
                            tallasMujer={tallasMujer}
                            SKUsServicioTecnico={SKUsServicioTecnico}
                            status={productData?.producto?.status}
                            stock={productData?.producto?.stock}
                            id={productData?.producto?.id}
                            libro={libro}
                        />
                    </ErrorBoundary>
                :
                    <ErrorBoundary>
                        <DetalleProductoMobile
                            changeDirectionBar={changeDirectionBar}
                            loading={loading}
                            productDataSup={productData}
                            opinionData={opinionData}
                            handleResumen={handleResumen}
                            handleSimilares={handleSimilares}
                            mostrarResumen={mostrarResumen}
                            mostrarSimilares={mostrarSimilares}
                            setLoading={setLoading}
                            promoDepart={promoDepart}
                            formasPagoArray={formasPagoArray}
                            formasPagoOrdenadoArray={formasPagoOrdenadoArray}
                            catPadre={catPadre}
                            catHija_Hombre={catHija_Hombre}
                            catHija_Mujer={catHija_Mujer}
                            catHija_Nino={catHija_Nino}
                            catHija_Nina={catHija_Nina}
                            tallasNino={tallasNino} 
                            tallasNina={tallasNina} 
                            tallasHombre={tallasHombre} 
                            tallasMujer={tallasMujer}
                            SKUsServicioTecnico={SKUsServicioTecnico}
                            libro={libro}
                        />
                    </ErrorBoundary>

            }
            <ErrorBoundary>
                <Footer />
            </ErrorBoundary>
        </>
    )
}

export default React.memo(DetalleProducto)