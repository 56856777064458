import React, { useState } from 'react'
// logicas usadas ['HOME', 'RELATED','CART','CATEGORY','SEARCH','DEPARTMENT','ALSO_BOUGHT','PERSONAL','POPULAR']
import Slider from "react-slick";

export default function MenuRecomendationEmarsys(props) {
    const {
        changeMenu,
        gadget
    } = props

    const [clickSelect, setClickSelect] = useState(1)

    //const contRecomendation = document.querySelector('.contRecomendation').offsetTop

    const changeMenuSelected = (val) => {
        setClickSelect(val.pos)
        changeMenu(val)
        const categoryDiscovery = document.querySelector('.categoryDiscovery').parentElement.offsetTop
        window.scrollTo(0, categoryDiscovery - 15)
    }

    const settings = {
        infinite: true,
        arrows: true,
        dots: false,
        speed: 100,
        // swipe: true,
        // touchMove: true,
        // centerPadding: "60px",
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        // accessibility: false,
        centerMode: false,
        variableWidth: true,
        responsive: [

            //     {
            //         breakpoint: 1400,
            //         settings: {
            //             slidesToShow: 7,
            //             slidesToScroll: 7,
            //         }
            //     },
            //     {
            //         breakpoint: 1200,
            //         settings: {
            //             slidesToShow: 4,
            //             slidesToScroll: 4,
            //         }
            //     },
            {
                breakpoint: 900,
                settings: {
                    arrows: false,
                    //slidesToShow: 4,
                    //slidesToScroll: 4,
                }
            },
            //     {
            //         breakpoint: 650,
            //         settings: {
            //             slidesToShow: 2,
            //             slidesToScroll: 1,
            //         }
            //     }
        ]
    };


    return (
        <>
            <ul className="categoryDiscovery">
                {gadget === "desktop"
                    ?
                    <Slider {...settings} id="sliderDiscovery">
                        <li className={clickSelect === 1 ? "btn rojo icon_all" : "btn blanco icon_all"} onClick={() => changeMenuSelected({ 'logical': 'PERSONAL', 'bread': '', 'pos': 1 })}>Para ti</li>
                        <li className={clickSelect === 2 ? "btn rojo icon_muebles" : "btn blanco icon_muebles"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'tecnología y electrónica', 'pos': 2 })}>Tecnología y Electrónica</li>
                        <li className={clickSelect === 3 ? "btn rojo icon_hogar" : "btn blanco icon_hogar"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'videojuegos', 'pos': 3 })}>Videojuegos</li>
                        <li className={clickSelect === 4 ? "btn rojo icon_lineaBlanca" : "btn blanco icon_lineaBlanca"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'entretenimiento', 'pos': 4 })}>Entretenimiento</li>
                        <li className={clickSelect === 5 ? "btn rojo icon_electronica" : "btn blanco icon_electronica"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'libros y revistas', 'pos': 5 })}>Libros y Revistas</li>
                        <li className={clickSelect === 6 ? "btn rojo icon_belleza" : "btn blanco icon_belleza"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'perfumes y maquillaje', 'pos': 6 })}>Perfumes y Maquillaje</li>
                        <li className={clickSelect === 7 ? "btn rojo icon_telefonia" : "btn blanco icon_telefonia"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'juguetes y dulces', 'pos': 7 })}>Juguetes y Dulces</li>
                        <li className={clickSelect === 8 ? "btn rojo icon_videojuegos" : "btn blanco icon_videojuegos"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'farmacia', 'pos': 8 })}>Farmacia</li>
                        <li className={clickSelect === 9 ? "btn rojo icon_deportes" : "btn blanco icon_deportes"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'moda y belleza', 'pos': 9 })}>Moda y Belleza</li>
                        <li className={clickSelect === 10 ? "btn rojo icon_ferreteria" : "btn blanco icon_ferreteria"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'hogar y oficina', 'pos': 10 })}>Hogar y Oficina</li>
                        <li className={clickSelect === 11 ? "btn rojo icon_ella" : "btn blanco icon_ella"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'regalos', 'pos': 11 })}>Regalos</li>
                        <li className={clickSelect === 12 ? "btn rojo icon_el" : "btn blanco icon_el"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'otros', 'pos': 12 })}>Otros</li>
                        {/* <li className={clickSelect === 13 ? "btn rojo icon_zapateria" : "btn blanco icon_zapateria"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'zapatería', 'pos': 13 })}>Zapatería</li> */}
                        {/* <li className={clickSelect === 9 ? "btn rojo icon_pasatiempo" : "btn blanco icon_pasatiempo"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'pasatiempo y diversión', 'pos': 9 })}>Pasatiempo y Diversión</li> */}
                        {/* <li className={clickSelect === 14 ? "btn rojo icon_ninios" : "btn blanco icon_ninios"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'niños y bebés', 'pos': 14 })}>Niños y Bebés</li> */}
                        {/* <li className={clickSelect === 16 ? "btn rojo icon_gourmet" : "btn blanco icon_gourmet"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'gourmet y dulcería', 'pos': 16 })}>Gourmet y Dulcería</li> */}
                    </Slider>
                    :
                    <>
                        <li className={clickSelect === 1 ? "btn rojo icon_all" : "btn blanco icon_all"} onClick={() => changeMenuSelected({ 'logical': 'PERSONAL', 'bread': '', 'pos': 1 })}>Para ti</li>
                        <li className={clickSelect === 2 ? "btn rojo icon_muebles" : "btn blanco icon_muebles"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'tecnología y electrónica', 'pos': 2 })}>Tecnología y Electrónica</li>
                        <li className={clickSelect === 3 ? "btn rojo icon_hogar" : "btn blanco icon_hogar"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'videojuegos', 'pos': 3 })}>Videojuegos</li>
                        <li className={clickSelect === 4 ? "btn rojo icon_lineaBlanca" : "btn blanco icon_lineaBlanca"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'entretenimiento', 'pos': 4 })}>Entretenimiento</li>
                        <li className={clickSelect === 5 ? "btn rojo icon_electronica" : "btn blanco icon_electronica"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'libros y revistas', 'pos': 5 })}>Libros y Revistas</li>
                        <li className={clickSelect === 6 ? "btn rojo icon_belleza" : "btn blanco icon_belleza"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'perfumes y maquillaje', 'pos': 6 })}>Perfumes y Maquillaje</li>
                        <li className={clickSelect === 7 ? "btn rojo icon_telefonia" : "btn blanco icon_telefonia"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'juguetes y dulces', 'pos': 7 })}>Juguetes y Dulces</li>
                        <li className={clickSelect === 8 ? "btn rojo icon_videojuegos" : "btn blanco icon_videojuegos"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'farmacia', 'pos': 8 })}>Farmacia</li>
                        <li className={clickSelect === 9 ? "btn rojo icon_deportes" : "btn blanco icon_deportes"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'moda y belleza', 'pos': 9 })}>Moda y Belleza</li>
                        <li className={clickSelect === 10 ? "btn rojo icon_ferreteria" : "btn blanco icon_ferreteria"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'hogar y oficina', 'pos': 10 })}>Hogar y Oficina</li>
                        <li className={clickSelect === 11 ? "btn rojo icon_ella" : "btn blanco icon_ella"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'regalos', 'pos': 11 })}>Regalos</li>
                        <li className={clickSelect === 12 ? "btn rojo icon_el" : "btn blanco icon_el"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'otros', 'pos': 12 })}>Otros</li>
                        {/* <li className={clickSelect === 13 ? "btn rojo icon_zapateria" : "btn blanco icon_zapateria"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'zapatería', 'pos': 13 })}>Zapatería</li> */}
                        {/* <li className={clickSelect === 9 ? "btn rojo icon_pasatiempo" : "btn blanco icon_pasatiempo"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'pasatiempo y diversión', 'pos': 9 })}>Pasatiempo y Diversión</li> */}
                        {/* <li className={clickSelect === 14 ? "btn rojo icon_ninios" : "btn blanco icon_ninios"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'niños y bebés', 'pos': 14 })}>Niños y Bebés</li> */}
                        {/* <li className={clickSelect === 16 ? "btn rojo icon_gourmet" : "btn blanco icon_gourmet"} onClick={() => changeMenuSelected({ 'logical': 'POPULAR', 'bread': 'gourmet y dulcería', 'pos': 16 })}>Gourmet y Dulcería</li> */}
                    </>
                }
            </ul>
        </>
    )
}