import React, { useEffect } from 'react';
import './style.sass';
import PageFilters from './PageFilter';
import { ComponentViewTypeMobileBarraResults } from 'components/filtros/BoxVistas';
import { Link } from "react-router-dom";
import { VARIANTS_PER_PORTAL } from 'constants/general';

export default function BarFilter({ results, disableFilters, isShowing, history, setIsShowing, activarOrdenar, valor, filtrar, limpiar, listaCategorias, listaMarcas,
    precioMenor, PrecioMax, precioMin, precioMax,
    categoriaVisible, setCategoriaVisible, idCategoria, categoria, seleccionarCategoria, limpiarCategoria, setCategoria,
    limpiarMarca, filtrarMarcas, setMarcasVisible, checkedItem, setCheckedItem, marcasVisible, seleccionarMarca, marcaSeleccionada,
    HandleActivarEstrellas, activarEstrellas,
    handleEnvioGratis, activarEnvioGratis, params, activarFullfilment, handleActivarFullfilment,
    tieneEnvioGratis, tieneFulfillment, tieneReview,
    setDiscountVisible, discountVisible, limpiarDiscount, seleccionarDiscount, discountSeleccionado, checkedDiscount, setCheckedDiscount,
    minimo, maximo,
    setVistaLista, setVistaMosaico, vistaLista, vistaMosaico, handleLista, handleMoscaico, handleChangeExpress,
    valorDescuento,

    actualizarMaxDesdeBarra,actualizarMinDesdeBarra
}) {
    const hashLink = window.location.hash;
    const urlLink = window.location.href;

    if (params !== undefined) {
        const urlParams = params.split('/');
        var contador = 0;

        for (let i = 0; i <= (urlParams.length - 1); i++) {
            if (urlParams[i].indexOf('marca=') >= 0) {
                contador = contador + 1;
            }
            if (urlParams[i].indexOf('preciomenor=') >= 0 && urlParams[i + 1].indexOf('preciomayor=') >= 0) {
                contador = contador + 1;
            }
            if (urlParams[i].indexOf('categoria=') >= 0) {
                contador = contador + 1;
            }
            if (urlParams[i].indexOf('estrellas=') >= 0) {
                contador = contador + 1;
            }
            if (urlParams[i].indexOf('enviogratis=') >= 0) {
                contador = contador + 1;
            }
            if (urlParams[i].indexOf('fulfillment=') >= 0) {
                contador = contador + 1;
            }
            if (urlParams[i].indexOf('descuento') >= 0) {
                contador = contador + 1;
            }
            if (urlParams[i].indexOf('orden') >= 0) {
                contador = contador + 1;
            }
        }
    }
    // abrir o cerrar filtros
    function toggle() {
        setIsShowing(!isShowing);
        if (isShowing === true) {
            history.goBack();
        }
    }

    // revisa si la url contiene la cadena para mostrar el modal
    useEffect(() => {
        if (hashLink === "#modalFiltros") {
            setIsShowing(true);
        } else {
            setIsShowing(false);
        }
    }, [hashLink, urlLink])

    return (
        <>
            <div className="barFilter">
                {results
                    ? <div className={tieneFulfillment !== true ? "countResuts50" : "countResuts"}>
                        {results >= 1000 && results <= 9999 //para +2000 a 9999
                            ? '+' + results[0] + ',000'
                            : (Number(results) >= 10000 && Number(results) <= 99999) // para +1000
                                ? '+' + results[0] + '0,000'
                                : (Number(results) >= 100000) // para +1000
                                    ? '+' + results[0] + '00,000'
                                    : results
                        } resultados</div>
                    : <div className={tieneFulfillment !== true ? "countResuts50" : "countResuts"}>0 resultados</div>
                }

                {!disableFilters && tieneFulfillment === true && VARIANTS_PER_PORTAL.express === true
                    ? <div className="boxExpress">
                        <p className="fullFilment" aria-label="Express">Express</p>
                        <div className="switchExpress">
                            <label>
                                <input type="checkbox" className="inputExpress" checked={activarFullfilment} onChange={handleChangeExpress}></input>
                                <div className="toggle">
                                    <span className="selector"></span>
                                </div>
                            </label>
                        </div>
                    </div>
                    : <ComponentViewTypeMobileBarraResults />
                }

                {!disableFilters
                    ? <Link to={"#modalFiltros"} className="accesoModalFiltros"><div className={tieneFulfillment !== true && VARIANTS_PER_PORTAL.express === true ? "filters50" : "filters"} onClick={toggle} >
                        <p>
                            <span className="fil">Filtros</span>
                            <span className="contador" style={{ marginLeft: '10px' }}>
                                {(contador > 0 && contador <= 9)
                                    ? <>({contador})</>
                                    : (contador === 0)
                                        ? null
                                        : <>(+9)</>
                                }
                            </span>
                        </p>

                    </div>
                    </Link>
                    : null
                }

            </div>

            <PageFilters isShowing={isShowing} setIsShowing={setIsShowing} toggle={toggle} activarOrdenar={activarOrdenar} valor={valor} filtrar={filtrar}
                limpiar={limpiar} 
                precioMin={precioMin} precioMenor={precioMenor} PrecioMax={PrecioMax} precioMax={precioMax} minimo={minimo} maximo={maximo}
                
                listaCategorias={listaCategorias} listaMarcas={listaMarcas}
                categoriaVisible={categoriaVisible} setCategoriaVisible={setCategoriaVisible} idCategoria={idCategoria} categoria={categoria}
                seleccionarCategoria={seleccionarCategoria} limpiarCategoria={limpiarCategoria}
                limpiarMarca={limpiarMarca} filtrarMarcas={filtrarMarcas} setMarcasVisible={setMarcasVisible}
                checkedItem={checkedItem} setCheckedItem={setCheckedItem} marcasVisible={marcasVisible} seleccionarMarca={seleccionarMarca}
                marcaSeleccionada={marcaSeleccionada}
                HandleActivarEstrellas={HandleActivarEstrellas} activarEstrellas={activarEstrellas}
                handleEnvioGratis={handleEnvioGratis} activarEnvioGratis={activarEnvioGratis}
                setCategoria={setCategoria} activarFullfilment={activarFullfilment} handleActivarFullfilment={handleActivarFullfilment}

                tieneEnvioGratis={tieneEnvioGratis} tieneFulfillment={tieneFulfillment} tieneReview={tieneReview}

                params={params}
                setDiscountVisible={setDiscountVisible} discountVisible={discountVisible} limpiarDiscount={limpiarDiscount} seleccionarDiscount={seleccionarDiscount}
                discountSeleccionado={discountSeleccionado} checkedDiscount={checkedDiscount} setCheckedDiscount={setCheckedDiscount}

                setVistaLista={setVistaLista} setVistaMosaico={setVistaMosaico} vistaMosaico={vistaMosaico} vistaLista={vistaLista}
                handleMoscaico={handleMoscaico} handleLista={handleLista}
                valorDescuento={valorDescuento}

                actualizarMaxDesdeBarra={actualizarMaxDesdeBarra}
                actualizarMinDesdeBarra={actualizarMinDesdeBarra}
            
            />

        </>
    )
}