import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.sass';

export default function BoxAyuda() {

    const [menuView, setMenuView] = useState(false);

    const toggleClass = () => {
        setMenuView(!menuView);
    };

    return (
        <div className="boxClaroShop">
            <p onClick={toggleClass} className={menuView ? 'active' : null}>Ayuda</p>
            <ul>
                <li><Link to={"/como-comprar/"}>¿Cómo comprar?</Link></li>
                <li><Link to={"/preguntas-frecuentes/"}>Preguntas frecuentes</Link></li>
                <li><Link to={"/sitio-de-interes/"}>Sitio de interés</Link></li>
                <li><Link to={"/departamentos/"}>Mapa del sitio</Link></li>
            </ul>
        </div>
    )
}
