import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuPoliticas } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { Link, useHistory } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';

export function AvisoPrivacidadTecolotesSanbornsDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Tecolotes Sanborns');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuPoliticas />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Aviso de Privacidad para APP Los Tecolotes de Sanborns</p>
                            </div>

                            <div className="contentPreguntas">
                                <div className="question1">
                                    <h2>¿Quién dará tratamiento a tus datos personales?</h2>

                                    <p><span className='bold'>Sanborn Hermanos, S. A. de C. V. </span>(en adelante, “Sanborns”) con domicilio en Lago Zúrich 245, edificio Presa Falcón piso 7, colonia Ampliación Granada, Alcaldía Miguel Hidalgo, C.P. 11529, México, Ciudad de México, será el responsable del tratamiento de sus datos personales.</p>
                                    <p>Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos a la dirección electrónica <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>
                                </div>

                                <div className="question1">
                                    <h2>¿Qué información vamos a recabar?</h2>

                                    <p>Sanborns para cumplir con las finalidades señaladas en el presente Aviso recabará y tratará datos personales de identificación y de contacto para permitirle el envió desde la aplicación de Los Tecolotes de Sanborns, de cartas personalizadas, denominadas en adelante “Tecocards”.</p>
                                    <p>Asimismo, le informamos que para efectos de lo señalado en el presente Aviso de Privacidad Sanborns dará tratamiento a datos personales de contacto de terceros para poder realizar el envío del contenido generado en la aplicación, en ese sentido usted reconoce que les ha informado a estos terceros las finalidades para las que se tratarán sus datos personales, así como recabar su consentimiento para dichas finalidades.</p>
                                    <p>Sanborns no recabará ni tratará datos personales sensibles.</p>
                                </div>

                                <div className="question1">
                                    <h2>No tratamiento de datos personales de menores</h2>

                                    <p>Sanborns no recaba datos personales o información directamente de menores de edad. En el supuesto de que se proporcionen datos relacionados con personas menores de edad presumiéremos que la persona que los proporciona es el padre, madre y/o tutor legal, quien reconoce haber leído y aceptado el presente aviso de privacidad.</p>
                                    <p>En caso de que Sanborns detecte que nos ha proporcionado este tipo de información, tomaremos medidas para garantizar que éstos sean tratados bajo las condiciones previstas en la normatividad aplicable, de lo contrario la información será eliminada inmediatamente de nuestros registros.</p>
                                </div>

                                <div className="question1">
                                    <h2>¿Para qué fines usamos su información?</h2>

                                    <p>Sanborns tratará sus datos personales para las siguientes finalidades primarias y necesarias:</p>
                                    <ul className='ulQuestionDisc'>
                                        <li>Para permitirle generar Tecocards;</li>
                                        <li>Para permitirle enviar la Tecocards por medio de correo electrónico al tercero que usted quiera hacerle llegar la carta;</li>
                                        <li>Atender y resolver sus dudas relacionadas con la aplicación de Los Tecolotes de Sanborns;</li>
                                        <li>Dar cumplimiento a las obligaciones derivadas de la relación jurídica con usted;</li>
                                        <li>Dar cumplimiento a   las políticas y procedimientos   de carácter interno;</li>
                                        <li>Dar atención a sus solicitudes de información, quejas o reclamaciones;</li>
                                        <li>Dar cumplimiento a los requerimientos de autoridades competentes en los casos legalmente previstos.</li>
                                    </ul>
                                </div>

                                <div className="question1">
                                    <h2>Transferencias</h2>

                                    <p>Sanborns podrá transferir, sin requerir de su consentimiento, los datos personales necesarios a autoridades competentes en los casos legalmente previstos y a cualquier empresa Subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común, las cuales operan bajo políticas de privacidad que cumplen con las disposiciones aplicables correspondientes, para dar cumplimiento a las finalidades señaladas en el presente aviso.</p>
                                    <p>Para conocer cuáles son las Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común a quienes sus datos son transferidos puede consultarlo en http://www.gsanborns.com.mx/perfil-corporativo.html</p>
                                </div>

                                <div className="question1">
                                    <h2>¿Cómo puede ejercer sus derechos ARCO y/o revocar su consentimiento?</h2>

                                    <p>Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en lo sucesivo “derechos Arco”), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Protección de Datos de Sanborns a la dirección electrónica arco@sanborns.com.mx </p>
                                    <p>Su petición deberá ser realizada a través del Formato de Solicitud de Derechos ARCO, el cual debe ser solicitado a la dirección de correo electrónico antes señalada. Para que el Departamento de Protección de Datos de Sanborns pueda darle seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo que es necesario que complete todos los campos indicados en el Formato y lo acompañe con copia de alguna de las identificaciones oficiales vigentes que se señalan en el mismo. En caso de que la información proporcionada sea errónea o insuficiente, o bien, no se acompañen los documentos de acreditación correspondientes, nuestro Departamento de Protección de Datos, dentro de los cinco (5) días hábiles siguientes a la recepción de la solicitud, podrá requerirle que aporte los elementos o documentos necesarios para dar trámite a la misma. Usted contará con diez (10) días hábiles para atender el requerimiento, contados a partir del día siguiente en que lo haya recibido. De no dar respuesta en dicho plazo, se tendrá por no presentada la solicitud correspondiente. Nuestro Departamento de Protección de Datos le comunicará la determinación adoptada, en un plazo máximo de veinte (20) días hábiles contados desde la fecha en que se recibió la solicitud, a efecto de que, si resulta procedente, haga efectiva la misma dentro de los quince (15) días hábiles siguientes a que se comunique la respuesta. Para obtener mayor información sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento contacte al Departamento de Protección de Datos de Sanborns.</p>
                                    <p>Le recordamos que, si usted desea eliminar sus datos, deberá promover su derecho de cancelación, en los términos antes descritos.</p>
                                    <p>En caso de que su solicitud de cancelación, oposición o revocación sea procedente, según corresponda se dejarán de usar sus datos, para la finalidad que nos hubiere requerido o se eliminarán de nuestros sistemas en términos de lo que establece la legislación de la materia.</p>
                                </div>

                                <div className="question1">
                                    <h2>¿Cómo puede limitar el uso y/o divulgación de sus datos?</h2>
                                    <p>Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la dirección <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a>.En caso de que su Solicitud sea procedente se le registrará en el listado de exclusión propio de Sanborns. Para mayor información favor de contactar al Departamento de Protección de Datos de Sanborns.</p>
                                   
                                </div>

         
                                <div className="question1">
                                    <h2>Uso de cookies y web beacons</h2>
                                    <p>Sanborns utiliza varias tecnologías para mejorar la eficiencia de sus aplicaciones. Entre estas tecnologías se incluye el uso de cookies de terceros (como socios comerciales o Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común). Las cookies son pequeñas cantidades de información que se almacenan en el dispositivo utilizado por cada usuario para que el servidor recuerde cierta información que posteriormente pueda utilizar. Esta información permite identificarle y guardar sus preferencias personales para brindarle una mejor experiencia de navegación. Le recordamos que usted puede deshabilitar o ajustar el uso de cookies siguiendo los procedimientos del dispositivo que usted utiliza para hacer uso de nuestra aplicación.</p>
                                </div>

                                <div className="question1">
                                    <h2>¿Cómo le notificaremos de los cambios que efectuemos al presente Aviso de Privacidad?</h2>

                                    <p>Sanborns le notificará de cualquier cambio a su aviso de privacidad a través de nuestra página de internet <Link to="/">https://www.sanborns.com.mx</Link> por lo cual le pedimos revise la misma de manera periódica.</p>
                                </div>

                                <div className="question1">
                                    <h2>¿Cómo puede hacernos llegar sus dudas?</h2>
                                    <p>Nuestro Departamento de Protección de Datos queda a sus órdenes para proporcionarle cualquier información adicional que requiera o, en su caso, para resolver cualquier duda que pudiera surgirle en materia de privacidad y protección de datos personales, para lo que podrá contactarnos a través del correo electrónico <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>
                                </div>

                                <div className="question1">
                                    <h2>INAI</h2>
                                    <p>En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) para hacer efectivos sus derechos.</p>
                                    
                                </div>

                                <div className='question1'>
                                    <h2>Aceptación de Aviso de Privacidad
</h2>
                                    <p>Al hacer uso de nuestros servicios y proporcionar sus datos personales, significa que usted ha leído, entendido, y acordado los términos expuestos en el presente Aviso de Privacidad y consiente el tratamiento de sus datos personales según lo previsto en este último</p>
                                    <p className='bold'>Fecha de última actualización: Junio de 2023.</p>
                                </div>

                            </div>
                        </section>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}


export function AvisoPrivacidadTecolotesSanbornsMobile() {
    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Tecolotes Sanborns');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN">
                        <div className="btn closeBack" onClick={onClose} ></div>
                        <p className="title">Aviso de Privacidad para APP Los Tecolotes de Sanborns</p>
                    </div>

                    <div className="dataModal">

                        <div className="boxAN">
                            <div className='bxSlider'>

                                <p className="pSliderTitleL">¿Quién dará tratamiento a tus datos personales?</p>
                                <p className="pSliderL"><strong>Sanborn Hermanos, S. A. de C. V.</strong>(en adelante, “Sanborns”) con domicilio en Lago Zúrich 245, edificio Presa Falcón piso 7, colonia Ampliación Granada, Alcaldía Miguel Hidalgo, C.P. 11529, México, Ciudad de México, será el responsable del tratamiento de sus datos personales.</p>
                                <p className="pSliderL">Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos a la dirección electrónica <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>

                                <p className="pSliderTitleL">¿Qué información vamos a recabar?</p>
                                <p className="pSliderL">Sanborns para cumplir con las finalidades señaladas en el presente Aviso recabará y tratará datos personales de identificación y de contacto para permitirle el envió desde la aplicación de Los Tecolotes de Sanborns, de cartas personalizadas, denominadas en adelante “Tecocards”.</p>
                                <p className="pSliderL">Asimismo, le informamos que para efectos de lo señalado en el presente Aviso de Privacidad Sanborns dará tratamiento a datos personales de contacto de terceros para poder realizar el envío del contenido generado en la aplicación, en ese sentido usted reconoce que les ha informado a estos terceros las finalidades para las que se tratarán sus datos personales, así como recabar su consentimiento para dichas finalidades.</p>
                                <p className="pSliderL">Sanborns no recabará ni tratará datos personales sensibles.</p>

                                <p className="pSliderTitleL">No tratamiento de datos personales de menores</p>
                                <p className="pSliderL">Sanborns no recaba datos personales o información directamente de menores de edad. En el supuesto de que se proporcionen datos relacionados con personas menores de edad presumiéremos que la persona que los proporciona es el padre, madre y/o tutor legal, quien reconoce haber leído y aceptado el presente aviso de privacidad.</p>
                                <p className="pSliderL">En caso de que Sanborns detecte que nos ha proporcionado este tipo de información, tomaremos medidas para garantizar que éstos sean tratados bajo las condiciones previstas en la normatividad aplicable, de lo contrario la información será eliminada inmediatamente de nuestros registros.</p>

                                <p className="pSliderTitleL">¿Para qué fines usamos su información?</p>
                                <p className="pSliderL">Sanborns tratará sus datos personales para las siguientes finalidades primarias y necesarias:</p>
                                <ul className='ulDeclaracionesDisc'>
                                    <li>Para permitirle generar Tecocards;</li>
                                    <li>Para permitirle enviar la Tecocards por medio de correo electrónico al tercero que usted quiera hacerle llegar la carta;</li>
                                    <li>Dar cumplimiento a las obligaciones derivadas de la relación jurídica con usted;</li>
                                    <li>Dar cumplimiento a   las políticas y procedimientos   de carácter interno;</li>
                                    <li>Dar atención a sus solicitudes de información, quejas o reclamaciones;</li>
                                    <li>Dar cumplimiento a los requerimientos de autoridades competentes en los casos legalmente previstos.</li>
                                </ul>

                                <p className="pSliderTitleL">Transferencias</p>
                                <p className="pSliderL">Sanborns podrá transferir, sin requerir de su consentimiento, los datos personales necesarios a autoridades competentes en los casos legalmente previstos y a cualquier empresa Subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común, las cuales operan bajo políticas de privacidad que cumplen con las disposiciones aplicables correspondientes, para dar cumplimiento a las finalidades señaladas en el presente aviso.</p>
                                <p className="pSliderL">Para conocer cuáles son las Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común a quienes sus datos son transferidos puede consultarlo en http://www.gsanborns.com.mx/perfil-corporativo.html</p>

                                <p className="pSliderTitleL">¿Cómo puede ejercer sus derechos ARCO y/o revocar su consentimiento?</p>
                                <p className="pSliderL">Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en lo sucesivo “derechos Arco”), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Protección de Datos de Sanborns a la dirección electrónica <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>
                                <p className='pSliderL'>Su petición deberá ser realizada a través del Formato de Solicitud de Derechos ARCO, el cual debe ser solicitado a la dirección de correo electrónico antes señalada. Para que el Departamento de Protección de Datos de Sanborns pueda darle seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo que es necesario que complete todos los campos indicados en el Formato y lo acompañe con copia de alguna de las identificaciones oficiales vigentes que se señalan en el mismo. En caso de que la información proporcionada sea errónea o insuficiente, o bien, no se acompañen los documentos de acreditación correspondientes, nuestro Departamento de Protección de Datos, dentro de los cinco (5) días hábiles siguientes a la recepción de la solicitud, podrá requerirle que aporte los elementos o documentos necesarios para dar trámite a la misma. Usted contará con diez (10) días hábiles para atender el requerimiento, contados a partir del día siguiente en que lo haya recibido. De no dar respuesta en dicho plazo, se tendrá por no presentada la solicitud correspondiente. Nuestro Departamento de Protección de Datos le comunicará la determinación adoptada, en un plazo máximo de veinte (20) días hábiles contados desde la fecha en que se recibió la solicitud, a efecto de que, si resulta procedente, haga efectiva la misma dentro de los quince (15) días hábiles siguientes a que se comunique la respuesta. Para obtener mayor información sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento contacte al Departamento de Protección de Datos de Sanborns.</p>
                                <p className='pSliderL'>Le recordamos que, si usted desea eliminar sus datos, deberá promover su derecho de cancelación, en los términos antes descritos.</p>
                                <p className='pSliderL'>En caso de que su solicitud de cancelación, oposición o revocación sea procedente, según corresponda se dejarán de usar sus datos, para la finalidad que nos hubiere requerido o se eliminarán de nuestros sistemas en términos de lo que establece la legislación de la materia.</p>

                                <p className="pSliderTitleL">¿Cómo puede limitar el uso y/o divulgación de sus datos?</p>
                                <p className="pSliderL">Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la dirección arco@sanborns.com.mx. En caso de que su Solicitud sea procedente se le registrará en el listado de exclusión propio de Sanborns. Para mayor información favor de contactar al Departamento de Protección de Datos de Sanborns.</p>
                                
                                <p className="pSliderTitleL">Uso de cookies y web beacons</p>
                                <p className="pSliderL">Sanborns utiliza varias tecnologías para mejorar la eficiencia de sus aplicaciones. Entre estas tecnologías se incluye el uso de cookies de terceros (como socios comerciales o Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común). Las cookies son pequeñas cantidades de información que se almacenan en el dispositivo utilizado por cada usuario para que el servidor recuerde cierta información que posteriormente pueda utilizar. Esta información permite identificarle y guardar sus preferencias personales para brindarle una mejor experiencia de navegación. Le recordamos que usted puede deshabilitar o ajustar el uso de cookies siguiendo los procedimientos del dispositivo que usted utiliza para hacer uso de nuestra aplicación.</p>

                                <p className="pSliderTitleL">¿Cómo le notificaremos de los cambios que efectuemos al presente Aviso de Privacidad?</p>
                                <p className="pSliderL">Sanborns le notificará de cualquier cambio a su aviso de privacidad a través de nuestra página de internet <Link to="/">https://www.sanborns.com.mx</Link> por lo cual le pedimos revise la misma de manera periódica.</p>

                                <p className="pSliderTitleL">¿Cómo puede hacernos llegar sus dudas?</p>
                                <p className="pSliderL">Nuestro Departamento de Protección de Datos queda a sus órdenes para proporcionarle cualquier información adicional que requiera o, en su caso, para resolver cualquier duda que pudiera surgirle en materia de privacidad y protección de datos personales, para lo que podrá contactarnos a través del correo electrónico <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>

                                <p className="pSliderTitleL">INAI</p>
                                <p className="pSliderL">En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) para hacer efectivos sus derechos.</p>
                                

                                <p className='pSliderTitleL'>Aceptación de Aviso de Privacidad</p>
                                <p className='pSliderL'>Al hacer uso de nuestros servicios y proporcionar sus datos personales, significa que usted ha leído, entendido, y acordado los términos expuestos en el presente Aviso de Privacidad y consiente el tratamiento de sus datos personales según lo previsto en este último</p>
                                <p className='bold pSliderL'>Fecha de última actualización: Junio de 2023.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function AvisoPrivacidadTecolotesSanborns() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Tecolotes Sanborns');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {isMobile &&
                isMobile === 'desktop'
                ? <AvisoPrivacidadTecolotesSanbornsDesktop />

                : isMobile === 'movile'
                    ? null
                    : <AvisoPrivacidadTecolotesSanbornsMobile />
            }
        </>
    )
}