import React from 'react';
import loadable from '@loadable/component'


import { useClearCacheCtx } from 'react-clear-cache';
import { Router, Route, Redirect, Switch } from "react-router-dom";
import PrivateRoute from 'routers/privateRouter';
import BrowserHistory from 'routers/history';
import Provider from 'context/storeProvider';

// import Login from 'pages/LoginRegistro/Login/indexSuspence'
// import Registro from 'pages/LoginRegistro/Registro/indexSuspence'
// import Resultados from 'pages/Buscador/Results/indexSuspence'
// import MenuCategoria from 'pages/Categorias/MenuCategoria/indexSuspence'

import Home from 'pages/Home/indexSuspense';
import Authenticated from 'pages/LoginRegistro/Authenticated';
import Login from 'pages/LoginRegistro/Login';
import PreLogin from 'pages/LoginRegistro/PreLogin';
import Registro from 'pages/LoginRegistro/Registro';
import Resultados from 'pages/Buscador/Results/indexSuspense';
import MenuCategoria from 'pages/Categorias/MenuCategoria';
import Buscador from 'pages/Buscador/Search';
import CategoriaPage from 'pages/Categorias/SeccionCategoria/indexSuspense';
import TiendaPage from 'pages/Tienda/ResultsTiendas/indexSuspense';
import { TiendasOficiales } from 'pages/Tienda/SeccionT';
import { CornerPage } from 'pages/Corner';
import DetalleProducto from 'pages/Producto/indexSuspense';
import Landings from 'pages/Landings';
import RecuperarContrasenia from 'pages/LoginRegistro/Password';
import Carrito from 'pages/Carrito/indexNew';
import MisPedidos from 'pages/MiCuenta/MisPedidos';
import MisDirecciones from 'pages/MiCuenta/MisDirecciones/SeleccionarDireccion';
import MisDireccionesAdd from 'pages/MiCuenta/MisDirecciones/CrearDireccion';
import MisDireccionesEdit from 'pages/MiCuenta/MisDirecciones/EditarDireccion';
import MiWishlist from 'pages/MiCuenta/MiWishlist/indexNew';
import MiCuenta from 'pages/MiCuenta/MiCuenta';
import { DeleteAccount } from 'pages/MiCuenta/DeleteAccount';
import LoginMiTelmex from 'pages/MiCuenta/MiTelmex/loginTelmexPage';
import MiTelmexComponent from 'components/MiTelmex/MiTelmex/MiTelmexComponent';
import QuestionsMiTelmexPage from 'pages/MiCuenta/MiTelmex/questionsMiTelmex';
import Contacto from 'pages/Contacto';
import AcercaSanborns from 'pages/AcercaSanborns';
import Ajustes from 'pages/Ajustes';
import { MailsCorreoCompleto } from 'pages/Mails/VerCorreoCompleto';
import Pruebas from 'pages/PruebaModules';
import Error404 from 'pages/Error/Error404';

import { MesaRegalos } from 'pages/MesaRegalos';

import { DetallePedido } from 'pages/MiCuenta/MisPedidos/detallePedido';
import { SectionEnqueNecesitasAyuda } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista1-vista2';
import { MotivoCancelacion } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista3-motivo';
import { CancelarPedido } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista5-pedidoCancelado';
import { FormularioDatosReembolso } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista35-datoReembolso';
import { SolicitarCambioDevolucion } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista23-solicitarCambioDevolucion';
import { AdjuntarFotografias } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista36-adjuntarFotografias';
import { SectionProcesoRecoleccionEnCamino } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista6-nopuedeCancelar';
import { CuentanosTuProblema } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista21-cuentanosTuProblema';
import { NoFunciona } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista7-noFunciona';
import { CambioDevolucion } from 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista22-CambioDevolucion';


import { PreguntasFrecuentes } from 'pages/Ayuda/preguntasFrecuentes';
import { AvisoPrivacidad } from 'pages/Politicas/avisoPrivacidad';
import { PoliticaDevolucion } from 'pages/Politicas/politicaDevolucion';
import { AvisoProspectos } from 'pages/Politicas/avisoProspectos';
import { TerminosCondiciones } from 'pages/Politicas/terminosCondiciones';
import { AcercaNosotros } from 'pages/AcercaNosotros/acercaNosotros';
import ServicioCliente from 'pages/ServicioCliente';
import { CategoriasHijas } from 'components/CardCategory';
import { LocalizadorTiendas } from 'pages/LocalizadorTiendas';
import MenuPrincipal from 'components/Menu';
import SinDirecciones from 'pages/MiCuenta/MisDirecciones/SinDirecciones';
import { AvisoPrivacidadStoreBTS } from 'pages/Politicas/avisoPrivacidadStoreBTS';
import { AvisoPrivacidadRedesSociales } from 'pages/Politicas/avisoPrivacidadRedesSociales';
import { AvisoPrivacidadTecolotesSanborns } from 'pages/Politicas/avisoPrivacidadTecolotesSanborns';
import { AvisoPrivacidadConsultorioSanborns } from 'pages/Politicas/avisoPrivacidadConsultorioSanborns';
import { AvisoPrivacidadCirculoDeBienestar } from 'pages/Politicas/avisoPrivacidadCirculoDeBienestar';
import { AvisoPrivacidadCirculoPlus } from 'pages/Politicas/avisoPrivacidadCirculoPlus';
import { Legal } from 'pages/Politicas/legal';
import { PoliticaSeguridad } from 'pages/Politicas/politica-seguridad';
import { ComoComprar } from 'pages/Ayuda/comoComprar';
import { SitioDeInteres } from 'pages/Ayuda/sitioDeInteres';
import MapaDelSitio from 'pages/Categorias/mapaDelSitio';
import { Restaurante } from 'pages/Ayuda/restaurante';
import { Comunicados } from 'pages/Ayuda/comunicados';
import { RestauranteMenu } from 'pages/Ayuda/restauranteMenu';

import { ReviewsWrite } from 'pages/Reviews';
import { AvisoPrivacidadProspectoCredito } from 'pages/Politicas/avisoPrivacidadProspectoCredito';
import { ContratoCredito } from 'pages/Politicas/contratoCredito';
import Misproductosvistos from 'pages/MiCuenta/Misproductosvistos';

import TemplateTest from 'pages/Test';

BrowserHistory.listen(location => {
  //console.log('Set page to', location.pathname);
  // eslint-disable-next-line no-undef
  //ineum('page', location.pathname);
  // Note that the above can result in many useless pages when you are making use of path parameters.
  // In these cases you will have to define the page via different means, e.g. by creating a custom
  // Route component which accepts a 'pageName' property.
});

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

  return (
    <>
      <Provider>

        <Router history={BrowserHistory}>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/home" exact component={Home} />
            <Route path={[
              "/resultados/q=:busqueda?",
              "/preciomenor=:mp?",
              "/preciomayor=:pm?",
              "/marca=:idMarca?",
              "/categoria=:idCategoria?",
              "/estrellas=:estrella?",
              "/enviogratis=:ship?",
              "/fulfillment=:ful?",
              "/descuento=:dis?",
              "/orden=:ord?"
            ]} component={Resultados} />

            <Route path="/buscador" component={Buscador} />
            <Route path="/departamentos" exact component={MapaDelSitio} />
            <Route path="/categoria" exact component={MenuCategoria} />
            <Route path="/categoria/:id_cat" exact component={CategoriasHijas} />
            <Route path="/categoria/:id_cat?/:seo?/:page" component={CategoriaPage} />

            <Route path="/producto/:id?/:seo?" component={DetalleProducto} />

            <Route path="/tiendas" component={TiendasOficiales} />
            <Route path="/tienda/:id_Tienda?/:seo?" component={TiendaPage} />
            <Route path="/corner/:id_Corner?/:seo?" component={CornerPage} />

            <Route path="/landing/:landingName" component={Landings} />

            <Route path="/authenticated" component={Authenticated} />
            <Route path="/login" component={Login} />
            <Route path="/pre_login" component={PreLogin} />
            <Route path="/registro" exact component={Registro} />
            <Route path="/recuperar-contrasena/" component={RecuperarContrasenia} />
            <Route path="/contacto" component={Contacto} />
            <Route path="/servicios-cliente" component={ServicioCliente} />

            <Route path="/Mesa_Regalos" component={MesaRegalos} />

            <Route path="/menu-mas" component={MenuPrincipal} />
            <Route path="/test" component={TemplateTest} />

            <Route path="/restaurante/" component={Restaurante} />
            <Route path="/restaurante-menu/:name" component={RestauranteMenu} />
            <Route path="/comunicados/" component={Comunicados} />

            {/* menu necesitas ayuda sanborns */}
            <Route path="/como-comprar/" component={ComoComprar} />
            <Route path="/preguntas-frecuentes/" component={PreguntasFrecuentes} />
            <Route path="/sitio-de-interes/" component={SitioDeInteres} />

            {/* informacion legal sanborns */}
            <Route path="/terminos-condiciones" component={TerminosCondiciones} />
            <Route path="/acerca-sanborns" component={AcercaSanborns} />
            <Route path="/aviso-privacidad" component={AvisoPrivacidad} />
            <Route path="/contrato-credito" component={ContratoCredito} />
            <Route path="/aviso-privacidad-store-BTS" component={AvisoPrivacidadStoreBTS} />
            <Route path="/aviso-privacidad-redes-sociales" component={AvisoPrivacidadRedesSociales} />
            <Route path="/aviso-privacidad-prospectos" component={AvisoProspectos} />
            <Route path="/aviso-privacidad-tecolotes-sanborns" component={AvisoPrivacidadTecolotesSanborns} />
            <Route path="/aviso-privacidad-consultorio-sanborns" component={AvisoPrivacidadConsultorioSanborns} />
            <Route path="/aviso-privacidad-circulo-de-bienestar" component={AvisoPrivacidadCirculoDeBienestar} />
            <Route path="/aviso-privacidad-circulo-plus" component={AvisoPrivacidadCirculoPlus} />
            <Route path="/aviso-privacidad-prospecto-credito" component={AvisoPrivacidadProspectoCredito} />
            <Route path="/legal" component={Legal} />
            <Route path="/politica-devolucion" component={PoliticaDevolucion} />
            <Route path="/politica-seguridad" component={PoliticaSeguridad} />

            <Route path="/acerca-nosotros" component={AcercaNosotros} />
            <Route path="/localizador-tiendas" component={LocalizadorTiendas} />

            <Route path="/testModules" component={Pruebas} />
            <Route path="/verCorreoCompleto" component={MailsCorreoCompleto} />
            <Route path="/correo" component={MailsCorreoCompleto} />

            <Route path="/loginMiTelmex/" component={LoginMiTelmex} />
            <Route path="/mi-cuenta/mitelmex/" component={MiTelmexComponent} />

            <PrivateRoute path="/mi-cuenta/questions/" component={QuestionsMiTelmexPage} />
            <PrivateRoute path="/mi-cuenta/direcciones-envio/" exact component={MisDirecciones} />
            <PrivateRoute path="/mi-cuenta/direcciones-envio/editar/:id" component={MisDireccionesEdit} />
            <PrivateRoute path="/mi-cuenta/direcciones-envio/agregar" component={MisDireccionesAdd} />
            <PrivateRoute path="/mi-cuenta/direcciones-envio/sin-direcciones" component={SinDirecciones} />

            <PrivateRoute path="/mi-cuenta/mis-pedidos/dias=:days/pagina=:pages" component={MisPedidos} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido" component={DetallePedido} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto?" component={DetallePedido} />

            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/necesitas-ayuda" component={SectionEnqueNecesitasAyuda} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/necesitas-ayuda" component={SectionEnqueNecesitasAyuda} />

            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/motivo-cancelacion" component={MotivoCancelacion} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/motivo-cancelacion" component={MotivoCancelacion} />

            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/cancelacion-exitosa" component={CancelarPedido} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/cancelacion-exitosa" component={CancelarPedido} />

            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/datos-bancarios" component={FormularioDatosReembolso} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/datos-bancarios" component={FormularioDatosReembolso} />

            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/no-puede-cancelar" component={SectionProcesoRecoleccionEnCamino} />

            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/que-quieres-hacer" component={CambioDevolucion} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/solicitar-cambio-devolucion" component={SolicitarCambioDevolucion} />

            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/cuentanos-tu-problema" component={CuentanosTuProblema} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/no-funciona" component={NoFunciona} />
            <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/adjuntar-fotografias" component={AdjuntarFotografias} />

            <PrivateRoute path="/mi-cuenta/borrar-cuenta" exact component={DeleteAccount} />

            <PrivateRoute path="/mi-cuenta/listadeseos/" component={MiWishlist} />
            <PrivateRoute path="/mi-cuenta/historial-navegacion/" component={Misproductosvistos} />
            <PrivateRoute path="/mi-cuenta/" component={MiCuenta} />

            <PrivateRoute path="/carrito/" exact ><Redirect to="/carrito/detalle" /></PrivateRoute>
            <PrivateRoute path="/carrito/detalle" component={Carrito} />

            <PrivateRoute path="/ajustes" component={Ajustes} />
            <Route path="/escribir-opinion" component={ReviewsWrite} />
            <Route path="/write-a-review" component={ReviewsWrite} />

            <Route path="/:anything*" component={Error404} />
          </Switch>
        </Router>
      </Provider>
    </>
  );
}

export default App;
