import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import InputType from 'components/Forms/InputType'
import InputPassword from 'components/Forms/InputPassword'
import './styles.sass'

export default function LoginMiTelmexForm(props) {
    const { dataForm } = props

    const { register, handleSubmit, errors } = useForm()
    const [loginmiTelmex, setloginMiTelmex] = useState()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    // recibe los valores del formulario 
    const sendFormMiTelmex = data => {
        setloginMiTelmex({
            "phone": data.phoneNew,
            "password": data.password
        })
    }

    // validaciones para el formulario
    const valPhone = {
        required: 'El teléfono es requerido',
        minLength: {
            value: 10,
            message: 'El teléfono debe de tener 10 dígitos'
        },
        maxLength: {
            value: 10,
            message: 'El teléfono debe de tener 10 dígitos'
        },
        pattern: {
            value: /[0-9]{10}/,
            message: 'El formato de teléfono es inválido'
        }
    }

    const valPassword = {
        required: 'La contraseña es requerida',
        minLength: {
            value: 6,
            message: 'La contraseña debe contener al menos 6 caracteres'
        }
    }

    //valida si los datos son correctos y manda resultado al dataForm
    useEffect(() => {
        if (loginmiTelmex) {
            dataForm(loginmiTelmex)
        }
    }, [loginmiTelmex])


    return (
        <>
            <main>
                {/* <div className="sectionTitle">
                    <Link>Ingresa tus datos Mi Telmex</Link>
                </div> */}

                <div className="boxContenedorLoginTelmex">

                    <div className="boxImportante">
                        <div className="logosImportante">
                            <img className="img1" src="/img/TelmexLogo.png" alt="telmex" />
                            <img className="img2" src="/img/claroLogo.png" alt="claroshop" />
                        </div>

                        <p className="p1importante"><span className="">¡Conoce tu crédito disponible para comprar en Claroshop!</span></p>
                        <p className="p2importante">Ingresa con tu cuenta <span className="bold">Mi telmex</span></p>
                        <p className="p3importante">¿Aún no la tienes? <a className="bold" href="https://mitelmex.telmex.com/web/mitelmex-hogar/registro" target="_blank" rel="noopener noreferrer">Créala aquí</a>
                        </p>
                    </div>

                    <form className="boxFormulario" onSubmit={handleSubmit(sendFormMiTelmex)} >

                        <InputType
                            type="tel"
                            name="phoneNew"
                            id="phoneNew"
                            placeholder="Teléfono Telmex a 10 dígitos"
                            refs={register(valPhone)}
                            autocomplete="tel"
                            errorMessage={errors.phoneNew && errors.phoneNew.message}
                            maxLength="10"
                        />

                        <InputPassword
                            name="password"
                            id="password"
                            placeholder="Contraseña"
                            autocomplete="new-password"
                            refs={register(valPassword)}
                            errorMessage={errors.password && errors.password.message}
                        />

                        <a className="boxOlvidasteContrasena" href="https://mitelmex.telmex.com/web/mitelmex-hogar/recupera" target="_blank" rel="noopener noreferrer">¿Olvidaste tu contraseña?</a>

                        <button className="btn rojo">Validar</button>
                    </form>

                </div>
            </main>
        </>
    )
}