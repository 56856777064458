import React, { useState, useEffect } from 'react';
// import { decodeStorage } from 'functions/storageBase64';
// import { useParams, useHistory } from 'react-router-dom';
import ErrorProductServices from 'components/Error/ErrorProduct';
import ErrorConnectionServices from 'components/Error/ErrorConection';

import ModuleSliderImageProdut from 'components/ProductDetail/SlideImageProduct/SlideImageProductOld';
import ModuleShopProductCart from 'components/ProductDetail/ShopProduct/ContentCardProduct';
// import ModuleFeatures from 'components/ProductDetail/Features';
// import ModuleDeliveryDate from 'components/ProductDetail/DeliveryDate';
// import ModuleTelmexPromotion from 'components/ProductDetail/TelmexPromotion';
// import ModuleCreditCardPromotion from 'components/ProductDetail/CreditCardPromotion';
import ModuleSpecificationProduct from 'components/ProductDetail/Specification';
import ModuleDescriptionProduct from 'components/ProductDetail/Description';
import ModuleReviews from 'components/ProductDetail/Reviews/ProductReviews';
import ModulePaymentMethods from 'components/ProductDetail/PaymentMethods';
// import ModuleStoreCardProduct from 'components/ProductDetail/Store';
import SlidersEmarsys from 'components/Emarsys/SlidersEmarsys/index_product';
// import BannerImagenExpress from 'components/ProductDetail/BannerImagenExpress';
import { useStorage64 } from 'hooks/useStorageBase64';
// import { useHistory } from 'react-router-dom';
import ErrorType from 'pages/Error/ErrorType';
import ModalMiTelmexDetalle from 'components/MiTelmex/modaMiTelmex/modalMiTelmex';
import { ErrorBoundary } from 'components/Error/ErrorBoundary';
// import { ShareComponentDetailTexto } from 'components/Share';
// import { BtnWishlistDetail } from 'components/Wishlist';
import ModuleWarrantyProduct from 'components/ProductDetail/Warranty';
import ButtonVenderSanborns from 'components/ProductDetail/ShopProduct/ButtonVenderSanborns';
import { SirenaWhatsApp } from 'components/ProductDetail/SirenaWhatsApp'
import { ModuleTabsMobile } from 'components/ProductDetail/ModuleTabsDesktop';
import { VARIANTS_PER_PORTAL } from 'constants/general'
import { SectionAttributesBoock } from 'components/ProductDetail/SectionAttributesBoock';
import ModuleCreditCardSanbornsPromotion from 'components/ProductDetail/CreditCardSanbornsPromotion';
import ModuleCreditCardPromotion from 'components/ProductDetail/CreditCardPromotion';

function SliderSimilares(props) {
    const {
        id,
        category,
        vista,
        titulo,
        cantidad,
        setSinResultados
    } = props;

    // const catPadre = category.filter(valor => valor.path_length === 2)[0].name;
    // const categoryString = catPadre.toLowerCase();
    const idString = String(id);


    let catString, catStringLow, catStringTitle;
    if (category?.length > 1) {
        catStringTitle = category.filter(valor => valor?.path_length === 0)[0]?.name;
        //console.log("longitud--> ", category.length)
        if (category?.length >= 3) {
            catString = category.filter(valor => valor?.path_length === 2)[0]?.name + ' > ' + category.filter(valor => valor?.path_length === 1)[0]?.name + ' > ' + category.filter(valor => valor?.path_length === 0)[0]?.name
            catStringLow = catString.toLowerCase();
        } else if (category?.length === 2) {
            catString = category.filter(valor => valor?.path_length === 1)[0]?.name + ' > ' + category.filter(valor => valor?.path_length === 0)[0]?.name
            catStringLow = catString.toLowerCase();
        }
        else if (category?.length === 1) {
            catString = category.filter(valor => valor?.path_length === 0)[0]?.name
            catStringLow = catString.toLowerCase();
        }
    } else {
        catString = null;
        catStringTitle = null;
    }

    const categoryString1 = catStringLow;
    const [similaresMobile,] = useState(true); // flag para pestana similares
    const [categoria, setCategoria] = useState(false); // saber la logica usada
    const [ca, setC] = useState(false);

    useEffect(() => {
        if (categoria) {
            setC(categoria);
        }
    }, [categoria])

    return (
        <>
            <ErrorBoundary>
                {idString &&

                    <section id="similarProduct" className="related">
                        {ca === false
                            ?
                            VARIANTS_PER_PORTAL?.emarsys?.product_related
                                ?
                                <SlidersEmarsys
                                    logica={'RELATED'}
                                    idContent={'similarProduct'}
                                    idProd={idString}
                                    typeView={vista}
                                    cant={cantidad}
                                    title={titulo != null ? titulo : null}
                                    similaresMobile={similaresMobile}
                                    setCategoria={setCategoria}
                                    setSinResultados={setSinResultados}
                                />
                                : null
                            : String(ca) === "CATEGORY"
                                ?
                                VARIANTS_PER_PORTAL?.emarsys?.product_cat
                                    ?
                                    <SlidersEmarsys
                                        logica={'CATEGORY'}
                                        idContent={'similarProduct'}
                                        typeView={vista}
                                        cant={cantidad}
                                        breadcrumb={categoryString1}
                                        title={titulo != null ? titulo : null}
                                        similaresMobile={similaresMobile}
                                        setCategoria={setCategoria}
                                        setSinResultados={setSinResultados}
                                    />
                                    : null
                                : String(ca) === "ALSO_BOUGHT"
                                    ?
                                    VARIANTS_PER_PORTAL?.emarsys?.product_also
                                        ?
                                        <SlidersEmarsys
                                            logica={'ALSO_BOUGHT'}
                                            idContent={'personalSlider2'}
                                            typeView={vista}
                                            cant={100}
                                            idProd={idString}
                                            title={titulo != null ? titulo : null}
                                            similaresMobile={similaresMobile}
                                            setCategoria={setCategoria}
                                            setSinResultados={setSinResultados}
                                        />
                                        : null
                                    : null
                        }

                    </section>
                }
            </ErrorBoundary>
        </>
    )
}

function SlidersBlocks(props) {
    const {
        id,
        category
    } = props;

    let catString, catStringLow, catStringTitle;
    if (category?.length > 1) {
        catStringTitle = category.filter(valor => valor?.path_length === 0)[0]?.name;
        if (category?.length >= 3) {
            catString = category.filter(valor => valor?.path_length === 2)[0]?.name + ' > ' + category.filter(valor => valor?.path_length === 1)[0]?.name + ' > ' + category.filter(valor => valor?.path_length === 0)[0]?.name
            catStringLow = catString.toLowerCase();
        } else if (category?.length === 2) {
            catString = category.filter(valor => valor?.path_length === 1)[0]?.name + ' > ' + category.filter(valor => valor?.path_length === 0)[0]?.name
            catStringLow = catString.toLowerCase();
        }
        else if (category?.length === 1) {
            catString = category.filter(valor => valor?.path_length === 0)[0]?.name
            catStringLow = catString.toLowerCase();
        }
    } else {
        catString = null;
        catStringTitle = null;
    }

    // const catPadre = catString;
    const idString = String(id);

    const categoryString = catStringLow;


    return (
        <>
            <ErrorBoundary>
                {VARIANTS_PER_PORTAL?.emarsys?.product_personal
                    ?
                    <section id="personalSlider">
                        <SlidersEmarsys
                            logica={'PERSONAL'}
                            idContent={'personalSlider'}
                            //title={"Productos que te pueden interesar"}
                            title={"Productos basados en tu navegación"}
                            //typeView={'sliderView'}
                            typeView={'scrollView'}
                            cant={50}
                        />
                    </section>
                    : null
                }
            </ErrorBoundary>

            <ErrorBoundary>
                {idString &&
                    VARIANTS_PER_PORTAL?.emarsys?.product_also
                    ?
                    <section id="tambienCompraronSlider">
                        <SlidersEmarsys
                            logica={'ALSO_BOUGHT'}
                            idContent={'tambienCompraronSlider'}
                            idProd={idString}
                            title={"También compraron"}
                            //typeView={'sliderView'}
                            typeView={'scrollView'}
                            cant={50}
                        />
                    </section>
                    : null
                }
            </ErrorBoundary>

            <ErrorBoundary>
                {categoryString &&
                    VARIANTS_PER_PORTAL?.emarsys?.product_cat
                    ?
                    <section id="categorySlider">
                        <SlidersEmarsys
                            logica={'CATEGORY'}
                            idContent={'categorySlider'}
                            breadcrumb={categoryString}
                            title={`Otros de ${catStringTitle}`}
                            //typeView={'sliderView'}
                            typeView={'scrollView'}
                            cant={16}
                        />
                    </section>
                    : null
                }
            </ErrorBoundary>
        </>
    )
}

export function DetalleProductoMobile(props) {
    const {
        changeDirectionBar,
        // loading,
        productDataSup,
        opinionData,
        handleResumen,
        handleSimilares,
        mostrarResumen,
        mostrarSimilares,
        setLoading,
        promoDepart,
        formasPagoArray,
        formasPagoOrdenadoArray,
        catPadre,
        catHija_Hombre,
        catHija_Mujer,
        catHija_Nino,
        catHija_Nina,
        tallasNino,
        tallasNina,
        tallasMujer,
        tallasHombre,
        SKUsServicioTecnico,
        libro
    } = props;

    // const params = useParams();
    // const codigoP = decodeStorage('codigoPostal');  

    // const history = useHistory();
    const [yaVisitoProductos, setYaVisitoProductos] = useStorage64('yaVisitoProductos', 0);
    const [yaPresionoSimilares, setYaPresionoSimilares] = useStorage64('yaPresionoSimilares', 0);
    // const [flagNuevo, setFlagNuevo] = useState(false);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Product Page');
    }, []);

    const [productData, SetProductData] = useState([]);
    useEffect(() => {
        if (productDataSup) {
            SetProductData(productDataSup);
        }
    }, [productDataSup]);

    //- remueve loading con errores
    useEffect(() => {
        if (productData) {
            if (productData?.error) {
                setLoading(false);
            }
        }
    }, [productData]);

    //- Evalua talla color stock para mostrar reglas de mensaje
    const [updateStockTC, setUpdateStockTC] = useState();
    const [updateFF, setUpdateFF] = useState();
    const stockSizeColorSKU = (val) => {
        setUpdateStockTC(val);
    };
    const stockSizeColorSKUFF = (val) => {
        setUpdateFF(val);
    };

    const [updateFFVariant, setUpdateFFVariant] = useState();
    const stockSizeColorVariant = (val) => {
        setUpdateFFVariant(val);
    };

    const [colorText, setColorText] = useState(null);
    const [tallaText, setTallaText] = useState(null);
    useEffect(() => {
        // console.log('updateFFVariant: ', updateFFVariant)
        // console.log('updateFF: ', updateFF)
        // console.log('updateStockTC: ', updateStockTC)
    }, [updateFFVariant]);

    const [sinResultados, setSinResultados] = useState(true);

    //valor para promociones en tarjetas departamentales
    // if(promoDepart){
    //     console.log('promoDepart: ',promoDepart)
    // }

    return (
        <>

            {productData &&
                productData?.producto
                ?
                VARIANTS_PER_PORTAL?.emarsys?.product_related || (VARIANTS_PER_PORTAL?.emarsys?.product_related && VARIANTS_PER_PORTAL?.emarsys?.product_cat) || (VARIANTS_PER_PORTAL?.emarsys?.product_related && VARIANTS_PER_PORTAL?.emarsys?.product_also)
                    ?
                    sinResultados
                        ?
                        <div className="barraInferior">
                            {/* <div className="barraResumenSimilares">
                                <p className={
                                    mostrarResumen === true && sinResultados === true
                                        ? 'activaSeccion p1'
                                        : mostrarResumen === true && sinResultados === true
                                            ? 'activaSeccion p1 anchoCompleto'
                                            : mostrarResumen === true && sinResultados === false
                                                ? 'activaSeccion p1 anchoCompleto'
                                                : 'noActiva p1'
                                }
                                    onClick={handleResumen}>Resumen</p>
                                {sinResultados === true &&
                                    <p className={mostrarSimilares === true ? 'activaSeccion p2' : 'noActiva p2'} onClick={handleSimilares} id="pestanaSimilares">Similares
                                        {yaPresionoSimilares < 1 && <span className={yaVisitoProductos <= 1 ? "spanNuevo" : "ocultarTooltip"}>NUEVO
                                            <span className="tooltip1">Encuentra más productos como este</span>
                                        </span>
                                        }
                                    </p>
                                }
                            </div> */}
                        </div>
                        : null
                    : null
                : null
            }

            <main>

                {/* resumen */}
                {mostrarResumen === true &&
                    <>
                        <section>
                            <div className="container">
                                {productData &&
                                    productData?.error >= 500 &&
                                    <ErrorType
                                        codeStatus={productData?.error}
                                        errorText={'Código de error: ' + productData?.error}
                                    />
                                }
                                {productData &&
                                    productData?.error === 404 &&
                                    <ErrorProductServices />
                                }
                                {productData &&
                                    productData?.error === '0' &&
                                    <ErrorConnectionServices errorCode={productData?.response} />
                                }
                                {productData &&
                                    productData?.producto
                                    ?
                                    <>
                                        <ErrorBoundary>
                                            <div className="containShopProd">
                                                <ErrorBoundary>
                                                    <ModuleSliderImageProdut
                                                        id={productData?.producto?.id}
                                                        dataImage={productData?.producto?.images}
                                                        dataVideo={productData?.producto?.videos}
                                                        title={productData?.producto?.title}
                                                        category={productData?.producto?.categories}
                                                        brand={productData?.producto?.brand}
                                                        sale_price={productData?.producto?.sale_price}
                                                    />
                                                </ErrorBoundary>

                                                {opinionData
                                                    ?
                                                        <ModuleShopProductCart
                                                            id={productData?.producto?.id}
                                                            title={productData?.producto?.title}
                                                            brand={productData?.producto?.brand}
                                                            store={productData?.producto?.store}
                                                            discount={productData?.producto?.discount}
                                                            price={productData?.producto?.price}
                                                            sale_price={productData?.producto?.sale_price}
                                                            size_color={productData?.producto?.size_color}
                                                            tallaColor={productData?.producto?.size_color}
                                                            is_digital={productData?.producto?.is_digital}
                                                            stock={productData?.producto?.stock}
                                                            status={productData?.producto?.status}
                                                            changeQuantity={productData?.producto?.changeQuantity}
                                                            cyr={productData?.producto?.cyr}
                                                            reviews={opinionData.response}
                                                            dataImage={productData?.producto?.images}
                                                            isfree={productData?.producto?.shipping?.is_free}
                                                            updateStockTC={updateStockTC}
                                                            fullfilment={productData?.producto?.features}
                                                            category={productData?.producto?.categories}
                                                            stockSizeColorSKU={stockSizeColorSKU}
                                                            stockSizeColorVariant={stockSizeColorVariant}
                                                            ifShopBuy={true}
                                                            changeDirectionBar={changeDirectionBar}
                                                            skuChildrenFF={updateFFVariant}
                                                            skuChildren={productData?.producto?.sku}
                                                            longitudDeliveryDate={productData?.producto?.delivery_date?.length}
                                                            payment_methods={productData?.producto?.payment_methods}
                                                            setColorText={setColorText}
                                                            setTallaText={setTallaText}
                                                            colorText={colorText}
                                                            tallaText={tallaText}
                                                            promoDepart={promoDepart}
                                                            catPadre={catPadre}
                                                            catHija_Hombre={catHija_Hombre}
                                                            catHija_Mujer={catHija_Mujer}
                                                            catHija_Nino={catHija_Nino}
                                                            catHija_Nina={catHija_Nina}
                                                            tallasNino={tallasNino}
                                                            tallasNina={tallasNina}
                                                            tallasMujer={tallasMujer}
                                                            tallasHombre={tallasHombre}
                                                            SKUsServicioTecnico={SKUsServicioTecnico}
                                                            is_store_only={productData?.producto?.features?.is_store_only}
                                                            cex={productData?.producto?.features?.cex}
                                                            super_express={productData?.producto?.features?.super_express}
                                                            ean={productData?.producto?.ean}
                                                            attributes={productData?.producto?.attributes}
                                                            libro={libro}
                                                            last_modified={productData?.producto?.last_modified}
                                                        />
                                                    :
                                                        <ModuleShopProductCart
                                                            id={productData?.producto?.id}
                                                            title={productData?.producto?.title}
                                                            store={productData?.producto?.store}
                                                            discount={productData?.producto?.discount}
                                                            price={productData?.producto?.price}
                                                            sale_price={productData?.producto?.sale_price}
                                                            size_color={productData?.producto?.size_color}
                                                            tallaColor={productData?.producto?.size_color}
                                                            is_digital={productData?.producto?.is_digital}
                                                            stock={productData?.producto?.stock}
                                                            status={productData?.producto?.status}
                                                            changeQuantity={productData?.producto?.changeQuantity}
                                                            cyr={productData?.producto?.cyr}
                                                            reviews={null}
                                                            dataImage={productData?.producto?.images}
                                                            isfree={productData?.producto?.shipping?.is_free}
                                                            updateStockTC={updateStockTC}
                                                            fullfilment={productData?.producto?.features}
                                                            category={productData?.producto?.categories}
                                                            brand={productData?.producto?.brand}
                                                            stockSizeColorSKU={stockSizeColorSKU}
                                                            stockSizeColorVariant={stockSizeColorVariant}
                                                            ifShopBuy={true}
                                                            changeDirectionBar={changeDirectionBar}
                                                            skuChildrenFF={updateFFVariant}
                                                            skuChildren={productData?.producto?.sku}
                                                            longitudDeliveryDate={productData?.producto?.delivery_date?.length}
                                                            payment_methods={productData?.producto?.payment_methods}
                                                            setColorText={setColorText}
                                                            setTallaText={setTallaText}
                                                            colorText={colorText}
                                                            tallaText={tallaText}
                                                            promoDepart={promoDepart}
                                                            catPadre={catPadre}
                                                            catHija_Hombre={catHija_Hombre}
                                                            catHija_Mujer={catHija_Mujer}
                                                            catHija_Nino={catHija_Nino}
                                                            catHija_Nina={catHija_Nina}
                                                            tallasNino={tallasNino}
                                                            tallasNina={tallasNina}
                                                            tallasMujer={tallasMujer}
                                                            tallasHombre={tallasHombre}
                                                            SKUsServicioTecnico={SKUsServicioTecnico}
                                                            is_store_only={productData?.producto?.features?.is_store_only}
                                                            cex={productData?.producto?.features?.cex}
                                                            super_express={productData?.producto?.features?.super_express}
                                                            attributes={productData?.producto?.attributes}
                                                            libro={libro}
                                                            last_modified={productData?.producto?.last_modified}
                                                        />

                                                }

                                            </div>
                                        </ErrorBoundary>

                                        {/* <ErrorBoundary>
                                            <ModuleFeatures />
                                        </ErrorBoundary> */}

                                        {/* <ErrorBoundary>
                                            {productData?.producto?.delivery_date.length !== 0
                                                ?
                                                <ModuleDeliveryDate
                                                    is_digital={productData?.producto?.is_digital}
                                                    id_Product={productData?.producto?.id}
                                                    skuChildren={productData?.producto?.sku}
                                                    stock={productData?.producto?.stock}
                                                    status={productData?.producto?.status}
                                                    changeDirectionBar={changeDirectionBar}
                                                    skuChildrenFF={updateFFVariant}
                                                />
                                                : null
                                            }
                                        </ErrorBoundary> */}

                                        {/* <ErrorBoundary>
                                            {productData?.producto?.payment_methods?.find(item => item.id === 4468)
                                                ? productData?.producto?.payment_methods?.find(item => item.id === 4468).promotion.length > 0
                                                    ? <ModuleCreditCardSanbornsPromotion credit_card_months={productData?.producto?.payment_methods?.find(item => item.id === 4468).promotion[0].monthly_payments} />
                                                    : null
                                                : null
                                            }
                                        </ErrorBoundary> */}

                                        {/* <ErrorBoundary>
                                            {productData?.producto?.payment_methods?.find(item => item.id === 4468)
                                                ? productData?.producto?.payment_methods?.find(item => item.id === 4468).promotion.length > 0
                                                    ? <ModuleCreditCardPromotion credit_card_months={productData?.producto?.payment_methods?.find(item => item.id === 4468).promotion[0].monthly_payments} />
                                                    : null
                                                : null
                                            }
                                        </ErrorBoundary> */}

                                        {/* <ErrorBoundary>
                                            {productData?.producto?.telmex_months.length !== 0
                                                ? <ModuleTelmexPromotion telmex_months={productData?.producto?.telmex_months} />
                                                : null
                                            }
                                        </ErrorBoundary> */}

                                        {/* compartir / lista deseos */}
                                        {/* <div className="boxWishlistShareMovil">
                                            <div className="listaDeseos">
                                                <BtnWishlistDetail
                                                    id={productData?.producto?.id}
                                                    nombre={productData?.producto?.title}
                                                    precioLista={productData?.producto?.sale_price}
                                                    brand={productData?.producto?.brand}
                                                    categories={productData?.producto?.categories}
                                                    index='1'
                                                />

                                            </div>
                                            <div className="compartir">
                                                <ShareComponentDetailTexto title={productData?.producto?.title} />
                                            </div>
                                        </div> */}

                                        {/* modulo garantia */}
                                        {/* {productData?.producto?.warrantyDetails && productData?.producto?.warrantyDetails.length >= 1
                                            ? <ModuleWarrantyProduct warrantyDetails={productData?.producto?.warrantyDetails} />
                                            : null
                                        } */}

                                        {/* caracteristicas libros*/}
                                                                                        
                                        {libro && productData?.producto?.attributes ?
                                                <div className="contenedorMovilCaracteristicasLibros">
                                                    <p className="titulo-banner">Detalles del libro</p>
                                                    <SectionAttributesBoock 
                                                        attributes={productData?.producto?.attributes}
                                                        brand={productData?.producto?.brand}
                                                    />  
                                                </div>                          
                                            : null
                                        }

                                        <ErrorBoundary>
                                            {productData?.producto?.description && productData?.producto?.description?.length >= 1
                                                ? <ModuleDescriptionProduct 
                                                    description={productData?.producto?.description}
                                                    isbn={productData?.producto?.attributes?.isbn}
                                                    />
                                                : null
                                            }
                                        </ErrorBoundary>

                                        <ErrorBoundary>
                                            {productData?.producto?.attributes && Object.keys(productData?.producto?.attributes)?.length >= 1
                                                ? <ModuleSpecificationProduct attributes={productData?.producto?.attributes} />
                                                : null
                                            }
                                        </ErrorBoundary>

                                        {/* <ErrorBoundary>
                                            {!window.localStorage.getItem("closebtnMT-detalle") &&
                                                <ModalMiTelmexDetalle />
                                            }
                                        </ErrorBoundary> */}

                                        <ErrorBoundary>
                                            {libro ? (promoDepart && promoDepart?.length > 0 )
                                                || productData?.producto?.payment_methods?.find(item => item?.id === 4468).promotion?.length > 0 ?
                                                    <div className="moduleShopCardProduct">
                                                        <div className="contentCreditCard">
                                                            <ErrorBoundary>
                                                                {
                                                                    // payment_methods && payment_methods?.find(item => item.id === 4384)
                                                                    //     ? payment_methods?.find(item => item.id === 4384).promotion.length > 0
                                                                    promoDepart && promoDepart?.length > 0
                                                                        ? <ModuleCreditCardSanbornsPromotion credit_card_months={promoDepart} tieneFormasPago={productData?.producto?.payment_methods} />
                                                                        : null
                                                                    // : null
                                                                }
                                                            </ErrorBoundary>
                                            
                                                            <ErrorBoundary>
                                                                {productData?.producto?.payment_methods && productData?.producto?.payment_methods?.find(item => item?.id === 4468)
                                                                    ? productData?.producto?.payment_methods?.find(item => item?.id === 4468).promotion?.length > 0
                                                                        ? <ModuleCreditCardPromotion credit_card_months={productData?.producto?.payment_methods?.find(item => item?.id === 4468).promotion[0]?.monthly_payments} />
                                                                        : null
                                                                    : null
                                                                }
                                                            </ErrorBoundary>
                                                        </div>
                                                    </div>
                                                :null
                                            :null
                                            }

                                        </ErrorBoundary>



                                        <ErrorBoundary>
                                            {opinionData && opinionData.response && opinionData.response?.reviews?.length >= 1
                                                ? <ModuleReviews response={opinionData.response} />
                                                : null
                                            }
                                        </ErrorBoundary>

                                        {promoDepart &&
                                            <ErrorBoundary>

                                                <ModuleTabsMobile payment_methods={productData?.producto?.payment_methods} promoDepart={promoDepart} />

                                            </ErrorBoundary>
                                        }


                                        {/* <ButtonVenderSanborns /> */}

                                        <ErrorBoundary>
                                            {productData?.producto &&
                                                productData?.producto?.id &&
                                                VARIANTS_PER_PORTAL?.emarsys?.product_related
                                                ?
                                                <section id="relatedSlider">
                                                    <SlidersEmarsys
                                                        logica={'RELATED'}
                                                        idContent={'relatedSlider'}
                                                        idProd={productData?.producto?.id}
                                                        title={"Productos similares"}
                                                        //typeView={'sliderView'}
                                                        typeView={'scrollView'}
                                                        cant={16}
                                                    />
                                                </section>
                                                : null
                                            }
                                        </ErrorBoundary>

                                        <ModulePaymentMethods formasPagoArray={formasPagoArray} formasPagoOrdenadoArray={formasPagoOrdenadoArray} />

                                        {/* <ErrorBoundary>
                                            <ModuleStoreCardProduct
                                                id={productData?.producto?.store.id}
                                                title={productData?.producto?.store.name}
                                                logo={productData?.producto?.store.logo}
                                            />
                                        </ErrorBoundary> */}



                                        {/* <ErrorBoundary>
                                            <ModulePaymentMethods
                                                payment_methods={productData?.producto?.payment_methods}
                                            />
                                        </ErrorBoundary> */}

                                        {/* <ErrorBoundary>
                                            <BannerImagenExpress express={productData?.producto?.features} />
                                        </ErrorBoundary> */}
                                    </>
                                    : null
                                }
                            </div>
                        </section>

                        <ErrorBoundary>
                            {productData &&
                                productData?.producto
                                ? <SlidersBlocks
                                    id={productData?.producto?.id}
                                    category={productData?.producto?.categories}
                                />
                                : null
                            }

                            <div style={{ display: "none" }}>
                                {productData &&
                                    productData?.producto
                                    ?
                                    <SliderSimilares
                                        id={productData?.producto?.id}
                                        category={productData?.producto?.categories}
                                        vista="gridView"
                                        titulo={null}
                                        cantidad={200}
                                        setSinResultados={setSinResultados}
                                    />
                                    : null
                                }
                            </div>
                        </ErrorBoundary>
                    </>
                }

                {/* similares */}
                <ErrorBoundary>
                    {mostrarSimilares === true &&
                        <>
                            {productData &&
                                productData?.producto
                                ?
                                <SliderSimilares
                                    id={productData?.producto?.id}
                                    category={productData?.producto?.categories}
                                    vista="gridView"
                                    titulo={null}
                                    cantidad={200}
                                    setSinResultados={setSinResultados}
                                />
                                : null
                            }
                        </>
                    }
                </ErrorBoundary>

                <ErrorBoundary>
                    {window.localStorage.getItem("closebtnMT-detalle") === "true" &&
                        <section>
                            <div className="container">
                                <ModalMiTelmexDetalle />
                            </div>
                        </section>
                    }
                </ErrorBoundary>

                <ErrorBoundary>
                    {productData && productData?.producto
                        ? <SirenaWhatsApp
                            store={productData?.producto?.store}
                            category={productData?.producto?.categories}
                            price={productData?.producto?.price}
                            sale_price={productData?.producto?.sale_price}
                            stock={productData?.producto?.stock}
                            status={productData?.producto?.status}
                        />
                        : null
                    }
                </ErrorBoundary>
            </main>
        </>
    )
}