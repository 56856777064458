import React, { useState, useRef, useEffect } from 'react';
import StarsFive from 'components/StarsFive';
import './style.sass';
import ModalFiltros from './modalFiltros';
import StarsPrincipal from 'components/StarsFive/starsPrincipal';
import ModalOrdenamiento from './modalOrdenamiento';


export default function ModalReviews({ reviews, star_distribution, total_review, average_score, nombre }) {

    const [mostrarFiltros, setMostrarFiltros] = useState(false);
    const [valorRadioButton, setValorRadioButton] = useState(0);

    const cerrar = () => {
        setMostrarFiltros(false);
    }
    const handleMostrarFiltros = () => {
        setMostrarFiltros(!mostrarFiltros);
    }
    const envioRadioButton = (valor) => {
        setValorRadioButton(Number(valor));
    }

    const filtrado = () => {
        if (Number(valorRadioButton) !== 0) {
            setOrdenado(reviews.filter(estrellas => String(estrellas.score).indexOf(String(valorRadioButton)) === 0));
        }
    }

    useEffect(() => {
        filtrado();
    }, [valorRadioButton])


    // funcionamiento para ordenamiento
    const [ordenamiento, setOrdenamiento] = useState(3);
    const [ordenado, setOrdenado] = useState([]);
    const [mostrarOrdenamiento, setMostrarOrdenamiento] = useState(false)
    const [valorRadioButtonOrdenamiento, setValorRadioButtonOrdenamiento] = useState(0)

    // cierra modal de orden
    const cerrarOrdenamiento = () => {
        setMostrarOrdenamiento(false);
    }
    // activa modal orden
    const handleMostrarOrdenamiento = () => {
        setMostrarOrdenamiento(!mostrarOrdenamiento);
    }

    const handleOrdenamiento = (valor) => {
        setOrdenamiento(Number(valor.target.value));
        ascendente(valor.target.value);
    }

    // recibe seleccion de radio modal
    const envioRadioButtonOrdenamiento = (valor) => {
        setValorRadioButtonOrdenamiento(Number(valor));
        setOrdenamiento(Number(valor));
        ascendente(valor);
    }

    function ascendente(ordena) {
        if (Number(ordena) === 5) {//mas recientes
            setOrdenado(reviews.sort(((a, b) => new Date(b.created_at.slice(0, -14)) - new Date(a.created_at.slice(0, -14)))));
        }
        if (Number(ordena) === 4) { // mas antiguos
            setOrdenado(reviews.sort(((a, b) => new Date(a.created_at.slice(0, -14)) - new Date(b.created_at.slice(0, -14)))));
        }
        if (Number(ordena) === 3) { //mayor a menor
            setOrdenado(reviews.sort(((a, b) => b.score - a.score)));
        }
        if (Number(ordena) === 2) { //menor a mayor
            setOrdenado(reviews.sort(((a, b) => a.score - b.score)));
        }
    }

    useEffect(() => {
        ascendente(3);
    }, [])

    function RankingStart({ cantStar, total, position }) {

        const promedyPerStar = (totalStar, cantStar) => {
            const total = Math.round((cantStar / totalStar) * 100)
            return total
        }

        const progressBar = promedyPerStar(total, cantStar)

        return (
            <div className="promedyRankStars">
                <div className="rankS">{position}</div>
                <div className="barraProgres">
                    <span style={{ width: progressBar + '%' }}></span>
                </div>
                <div className="totalPerStar">{cantStar}</div>
            </div>
        )
    }

    function VoteReview({ idUser, voteType, voteCant }) {
        const [votes, setVotes] = useState(voteCant)

        const voteReviewClick = () => {
            var reviewsLocalS = window.localStorage.getItem(`review-${idUser}`)
            // console.log('click')
            // console.log(idUser)
            // console.log(voteType)
            // console.log(reviewsLocalS)

            if (reviewsLocalS) {
                //console.log('exist storage')
                if (reviewsLocalS === 'up') {
                    //console.log('up - storage')
                    if (votes !== 0)
                        setVotes(votes - 1)
                    window.localStorage.removeItem(`review-${idUser}`)
                }
                else if (reviewsLocalS === 'down') {
                    //console.log('down - storage')
                    if (votes !== 0)
                        setVotes(votes - 1)
                    window.localStorage.removeItem(`review-${idUser}`)
                }
            } else {
                window.localStorage.setItem(`review-${idUser}`, voteType)
                setVotes(votes + 1)
                //console.log('create storage')
            }
        }

        return (
            <span onClick={voteReviewClick} className={voteType === 'up' ? 'votesUp' : 'votesDown'}>{votes}</span>
        )
    }

    return (
        <>
            <div className="promedyRank">
                <div className="tituloProductoStrellas">
                    {nombre}
                </div>
                <div className="prom">
                    <div className="prom1">
                        <div className="promedioTotal">{average_score ? Number.parseFloat(average_score).toFixed(1) : null}</div>
                        <div className="boxOpiniones">
                            <StarsPrincipal score={average_score} />
                            <div className="promedy"> {total_review} {total_review === 1 ? <>opinión</> : <>opiniones</>}</div>
                        </div>
                    </div>
                    <div className="prom2">
                        <div className="filtros">
                            <p onClick={handleMostrarFiltros}>Filtros {Number(valorRadioButton) !== 0 ? <>(1)</> : <>(0)</>}</p>
                            {mostrarFiltros === true && <ModalFiltros onClose={mostrarFiltros} type="" title="" cerrar={cerrar} envioRadioButton={envioRadioButton} />}
                        </div>
                    </div>
                </div>

                {star_distribution &&
                    Object.entries(star_distribution).reverse().map(([key, value]) => {
                        return (
                            <RankingStart key={key} cantStar={value} total={total_review} position={key} />
                        )
                    })
                }

                <div className="boxOrdenarFiltros">
                    {/* <p>{total_review} {total_review === 1 ? <>opinión</> : <>opiniones</>}</p> */}
                    <div className="boxof">
                        <div className="ordenar">
                            <label>Ordenar por:</label>
                            <p onClick={handleMostrarOrdenamiento}>
                                {ordenamiento === 5
                                    ? "Más recientes"
                                    : ordenamiento === 4
                                        ? "Más antiguas"
                                        : ordenamiento === 3
                                            ? "Mayor valoración"
                                            : ordenamiento === 2
                                                ? "Menor valoración"
                                                : null
                                }
                            </p>
                            {/* <select name="ordenamiento" defaultValue="3" onChange={handleOrdenamiento}>
                                <option value="1" name="1">Más recientes</option>
                                <option value="2" name="2">Más antiguas</option>
                                <option value="3" name="3">Mayor valoración</option>
                                <option value="4" name="4">Menor valoración</option>
                            </select> */}
                        </div>
                        {mostrarOrdenamiento === true && <ModalOrdenamiento onClose={mostrarOrdenamiento} type="" title="" ordenamiento={ordenamiento} cerrar={cerrarOrdenamiento} envioRadioButton={envioRadioButtonOrdenamiento} />}
                    </div>
                </div>

            </div>

            <div className="contentCardOpinion">
                <h3 className="commenth3">Comentarios</h3>
                {ordenado &&
                    ordenado.map(({ id, user, score, title, verified_buyer, content, created_at, votes_up, votes_down, images_data, videos_data }, ind) =>
                        <div key={ind} className="cardReviewsDetailsStars">
                            <div className="textPrincipalCard">
                                <div className="firstLetter">{user.display_name.toUpperCase().split('')[0]}</div>
                                <div className="nameBuyer">
                                    <p className="h3">{user.display_name}
                                        {verified_buyer
                                            ? <span className="verifiedBuyer">Compra Verificada</span>
                                            : null
                                        }
                                    </p>
                                    <StarsFive score={score} />
                                </div>
                            </div>
                            <div className="titleDescription">
                                <p className="h3">{title}</p>
                                <p>{content}</p>
                                <div className="contImageReviews">
                                    {videos_data &&
                                        videos_data.map(image =>
                                            <img src={image.thumbnail_url} alt={image.id} />
                                        )
                                    }
                                    {images_data &&
                                        images_data.map(image =>
                                            <img src={image.thumb_url} alt={image.id} />
                                        )
                                    }
                                </div>
                            </div>
                            <div className="generalDateVotes">
                                <p>{created_at.split('T')[0]}</p>

                                <div className="dateVotes">
                                    <VoteReview idUser={id} voteType='up' voteCant={votes_up} />
                                    <VoteReview idUser={id} voteType='down' voteCant={votes_down} />
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        </>
    )
}