import { BASE_PATH_API, TIMEOUT_TIME } from 'constants/general';
import axios from 'axios';
import { decodeStorage, encodeStorage } from 'functions/storageBase64';

// login ayuda-dinamica
export async function LoginAyudaDinamica({ username, password, publicKey }) {
    const idSesionToken = decodeStorage('session_token');
    const apiURL = BASE_PATH_API + '/app/auth/login';
    const raw = JSON.stringify({ "username": username, "password": password, "publicKey": publicKey })

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        mode: 'no-cors',
        crossdomain: true,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8'
        },
        data: raw
    };

    return await axios(config)
        .then(res => {
            const {
                data
            } = res
            // console.log("servicio login token ------>", data.data.access_token)
            encodeStorage('accessToken', data.data.access_token);
            return data
        })
        .catch(error => {
            // console.log(error.request.status)
            if (error.message === 'Network Error') {
                // console.log('Error de conexión')
            }
            else if (error.request) {
                // console.log(error.request.responseText)
            }
            if (error.request.status === 0) {
                // console.log('request 0')
            } else {
                if (error.response.status === 401) {
                    // console.log(error.response.data.msg)
                    return { error: error.response.status, message: error.response.data.msg }
                }
                else if (error.response.status === 404) {
                    // console.error(error.response);
                    // console.log('request 404')
                }
                else if (error.response.status === 440) {
                    // console.log('request 440')
                }
                else if (error.response.status >= 500) {
                    // console.error(error.response);
                    // console.log('request 500')
                    return (error.response)
                }
                else {
                    // console.error(error.response.status);
                    // console.error(error.response);
                }
            }
        })
}

// obtener bancos
export async function getBanks() {
    const idSesionToken = decodeStorage('session_token');
    const accessToken = "Bearer " + decodeStorage("accessToken");
    const apiURL = `${BASE_PATH_API}/app/v1/dynamic-help/banks?token=${idSesionToken}`;

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
    };

    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res

            const { data: bancos } = data
            if (status === 200) {
                if (bancos) {
                    return { bancos }
                }
                else {
                    return { 'error': '404', 'response': data }
                }
            }
            return []
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })
}

// obtener razones de cancelacion
export async function getReasons() {
    // console.log(" decode--> ", decodeStorage("accessToken"))
    const accessToken = "Bearer " + decodeStorage("accessToken");
    const apiURL = `${BASE_PATH_API}/app/v1/dynamic-help/reason`;

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
    };

    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res
            const { data: razones } = data
            if (status === 200) {
                if (razones) {
                    return { razones }
                }
                else {
                    return { 'error': '404', 'response': data }
                }
            }
            return []
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })
}

// obtener pedido por id
export async function getPedido(id) {
    const idSesionToken = decodeStorage('session_token');
    //const accessToken = "Bearer " + decodeStorage("accessToken");
    //const apiURL = `${BASE_PATH_API}/app/v1/orders/${id}?token=${idSesionToken}`;
    const accessToken = "Bearer " + decodeStorage("access_token");
    const apiURL = `${BASE_PATH_API}/app/v1/orders/${id}`;
    
    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": accessToken
        }
    };
    if(id){
        return await axios(config)
            .then(res => {
                const {
                    status,
                    data
                } = res
    
                const { data: pedido } = data
                if (status === 200) {
                    if (pedido) {
                        return { pedido }
                    }
                    else {
                        return { 'error': '404', 'response': data }
                    }
                }
                return []
            })
            .catch(error => {
                return {
                    'headers': '',
                    'statusCode': error.request.status,
                    'data': error.request.response,
                    'error': error.response.data.msg
                }
            })
    }
}

// post crear ticket
export async function postCreateTiket({ reason_id, order_id, order_number, client_id, description, products_to_cancel, ticket_type_id, refound_details, additional_details, action_id }) {
    const accessToken = decodeStorage('accessToken');
    const apiURL = BASE_PATH_API + '/app/v1/dynamic-help/tickets';
    const raw = JSON.stringify({
        "reason_id": reason_id, "order_id": order_id, "order_number": order_number, "client_id": client_id,
        "description": description, "products_to_cancel": products_to_cancel, "ticket_type_id": ticket_type_id,
        "refound_details": refound_details, "additional_details": additional_details, "action_id": action_id
    })

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        mode: 'no-cors',
        crossdomain: true,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'Bearer ' + accessToken
        },
        data: raw
    };

    return await axios(config)
        .then(res => {
            const {
                data
            } = res

            return data
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })
}

// get obtener accion 
export async function getAction(idReason) {
    const accessToken = "Bearer " + decodeStorage("accessToken");
    const apiURL = `${BASE_PATH_API}/app/v1/dynamic-help/action?reason_id=${idReason}`;

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
    };

    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res

            const { data: action } = data
            if (status === 200) {
                if (action) {
                    return { action }
                }
                else {
                    return { 'error': '404', 'response': data }
                }
            }
            return []
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })
}

// get refound info / informacion de reembolso
export async function getRefoundInfo(id) {
    const accessToken = "Bearer " + decodeStorage("accessToken");
    const apiURL = `${BASE_PATH_API}/app/v1/dynamic-help/refound-info/${id}`;

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
    };
    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res

            const { data: refound } = data
            if (status === 200) {
                if (refound) {
                    return { refound }
                }
                else {
                    return { 'error': '404', 'response': data }
                }
            }
            return []
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })
}

// get parcel / paqueterias
export async function getParcel() {
    const accessToken = "Bearer " + decodeStorage("accessToken");
    const apiURL = `${BASE_PATH_API}/app/v1/dynamic-help/parcel`;

    const config = {
        method: 'get',
        url: apiURL,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
    };
    return await axios(config)
        .then(res => {
            const {
                status,
                data
            } = res

            const { data: parcel } = data
            if (status === 200) {
                if (parcel) {
                    return { parcel }
                }
                else {
                    return { 'error': '404', 'response': data }
                }
            }
            return []
        })
        .catch(error => {
            return {
                'headers': '',
                'statusCode': error.request.status,
                'data': error.request.response,
                'error': error.response.data.msg
            }
        })
}