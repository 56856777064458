import React, { useEffect } from 'react'
import './styles.sass'
import { Link } from "react-router-dom"
import { decodeStorage, encodeStorage } from 'functions/storageBase64'

import { HeaderBarMovile } from 'pages/Header/HeaderNavegacion/HeaderBarMovile';

export default function HeaderSimple(props) {
    const {
        pageClass = "",
        isMobile,
        pagina
    } = props

    const darkModeUser = decodeStorage('darkMode')

    //- Datos que se mandan a instana
    useEffect(() => {
        //window.ineum('wrapEventHandlers', true);
        //window.ineum('wrapTimers', true);
        //window.ineum('ignoreErrorMessages', [/^script error/i])
    }, [])


    //- Funcion que evalua el dark mode
    function changeMedia(mq) {
        if (sessionStorage.getItem('SelectUser') === "true") {
            if (darkModeUser) {
                document.body.classList.add('is-dark-mode')
                encodeStorage('darkMode', true)
            }
            else {
                document.body.classList.remove('is-dark-mode')
                encodeStorage('darkMode', false)
            }
        } else {
            if (mq.matches) {
                document.body.classList.add('is-dark-mode')
                //encodeStorage('darkMode', true)
                encodeStorage('darkMode', false)
            }
            else {
                document.body.classList.remove('is-dark-mode')
                encodeStorage('darkMode', false)
            }
        }
    }
    //- Efecto que evalua si se encuentra con dark o light mode
    useEffect(() => {
        const mq = window.matchMedia('(prefers-color-scheme: dark)')
        try {
            mq.addEventListener('change', changeMedia(mq))
        }
        catch (e) {
            //console.log('error:',e)
            mq.addListener(changeMedia(mq))
        }
        if (mq.matches) { document.body.classList.add('is-dark-mode') }
        else { document.body.classList.remove('is-dark-mode') }
    }, [])

    // - seleccion dark mode en switch
    useEffect(() => {
        //console.log(decodeStorage('darkMode'))
        if (window.matchMedia('(prefers-color-scheme: dark)')) {
            if (decodeStorage('darkMode')) { document.body.classList.add('is-dark-mode') }
            else { document.body.classList.remove('is-dark-mode') }
        }
    }, [decodeStorage('darkMode'), window.matchMedia('(prefers-color-scheme: dark)').matches])


    return (
        <>
            <header className={`simple ${pageClass}`}>
                <a href="/" className="logoClaroshop" aria-label="logoClaroshop"></a>


                {isMobile !== "desktop" && pagina === "login" &&
                    <div className="menuPrincipal">
                        <HeaderBarMovile isLoged={false} profile={false} />
                    </div>}
            </header>
        </>
    )
}


export function HeaderHidden() {

    const darkModeUser = decodeStorage('darkMode')
    //- Funcion que evalua el dark mode
    function changeMedia(mq) {
        if (sessionStorage.getItem('SelectUser') === "true") {
            if (darkModeUser) {
                document.body.classList.add('is-dark-mode')
                encodeStorage('darkMode', true)
            }
            else {
                document.body.classList.remove('is-dark-mode')
                encodeStorage('darkMode', false)
            }
        } else {
            if (mq.matches) {
                document.body.classList.add('is-dark-mode')
                //encodeStorage('darkMode', true)
                encodeStorage('darkMode', false)
            }
            else {
                document.body.classList.remove('is-dark-mode')
                encodeStorage('darkMode', false)
            }
        }
    }
    //- Efecto que evalua si se encuentra con dark o light mode
    useEffect(() => {
        const mq = window.matchMedia('(prefers-color-scheme: dark)')
        try {
            mq.addEventListener('change', changeMedia(mq))
        }
        catch (e) {
            //console.log('error:',e)
            mq.addListener(changeMedia(mq))
        }
        if (mq.matches) { document.body.classList.add('is-dark-mode') }
        else { document.body.classList.remove('is-dark-mode') }
    }, [])

    // - seleccion dark mode en switch
    useEffect(() => {
        //console.log(decodeStorage('darkMode'))
        if (window.matchMedia('(prefers-color-scheme: dark)')) {
            if (decodeStorage('darkMode')) { document.body.classList.add('is-dark-mode') }
            else { document.body.classList.remove('is-dark-mode') }
        }
    }, [decodeStorage('darkMode'), window.matchMedia('(prefers-color-scheme: dark)').matches])


    return (
        <header className="hidden"></header>
    )
}