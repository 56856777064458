import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuPoliticas } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { Link, useHistory } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';

export function PoliticaSeguridadDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Politica Seguridad');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuPoliticas />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Política de Seguridad</p>
                            </div>

                            <div className="contentPreguntas">
                                <div className="question1">
                                    <h2>Estimado Cliente:</h2>

                                    <p>Para Sanborns es importante mantenerte informado sobre los riesgos mas comunes que se encuentran en Internet, por eso hemos creado este sitio para ofrecerte consejos de prevención de riesgos dentro de este medio.</p>
                                    <p>Estamos seguros que siguiendo estas sencillas medidas de seguridad, podrás aprovechar los beneficios que se ofrecen por Internet de una manera más segura.</p>
                                </div>

                                <div className="question1">
                                    <h2>Seguridad para Tarjetas de Crédito</h2>

                                    <p>Nuestro Software para Servidor Seguro encripta los datos, asegurando que las transacciones vía Internet sean privadas y protegidas. Tus datos no pueden ser interceptados por ninguna otra persona mientras estos viajan de tu computadora a la nuestra. Una vez que nosotros recibimos los datos se almacenan en una computadora inaccesible desde Internet.</p>
                                    <p className='bold'>¡La seguridad de tu Pedido es nuestra principal preocupación!</p>
                                    <p>Todos los datos que teclees, incluyendo el número de tu tarjeta de crédito, nombre, domicilio, teléfono, etc. se encriptan bajo un método sofisticado utilizando tecnología de punta y se envían a nuestro servidor seguro. Estos datos no pueden ser leídos de ninguna manera durante su transmisión. Al recibir nosotros esos datos, los almacenamos encriptados en una base de datos alojada en una computadora inaccesible desde Internet, preservando la seguridad de los mismos después de su transmisión.</p>
                                    <p>Estadísticamente es mucho más seguro utilizar una tarjeta de crédito en el Web que en un restaurante o una tienda. Nosotros pensamos que esto es una buena noticia para cualquiera, sin embargo, de todas formas tomamos todas las precauciones posibles para proteger la integridad de los datos de las tarjetas de crédito.</p>
                                    <p>Al comprar en nuestra tienda, puedes olvidarte totalmente de los mitos que han surgido acerca de los fraudes por la inseguridad de transmisión de datos personales a través de Internet.</p>
                                    <p>Por tu seguridad; por favor no envíes tu número de tarjeta crédito por correo electrónico, ya que este no viaja de manera encriptado.</p>
                                </div>

                                <div className="question1">
                                    <h2>¿Qué es Spyware?</h2>

                                    <p>Spyware es un tipo de software que manda tu información personal a terceros sin que des tu consentimiento. Este tipo de información puede ser tan delicada como por ejemplo tu nombre de usuario y contraseña de banca electrónica. Por lo general personas con pocos escrúpulos utilizan esta información para cometer fraudes electrónicos.</p>
                                </div>

                                <div className="question1">
                                    <h2>¿Su equipo ha sido infectado por Spyware?</h2>

                                    <p>El Spyware se instala sin permiso en tu equipo siguiendo uno de estos métodos:</p>
                                    <p>Engañándote para que hagas clic en el enlace que lo instala. Los enlaces a Spyware no lo parecen, disimulan haciéndote pensar que estás en otro sitio. A veces, los que promocionan Spyware pondrán un título falso en la barra en una ventana vacía y luego instalarán Spyware cuando intentas cerrar la ventana.</p>
                                    <p>Al instalar software gratuito en el que está incluido. Por ejemplo, puedes instalar un programa de archivo compartido que te instala Spyware sin que tú lo sepas.</p>
                                </div>

                                <div className="question1">
                                    <h2>Mantenerse actualizado</h2>

                                    <p>Una buena forma de proteger tu computadora es actualizar el sistema operativo y programas como navegadores, antivirus y firewalls. Esto, porque la mayoría de los virus y spyware aprovechan los "agujeros de seguridad" para infectar el sistema. Por eso, constantemente los propios desarrolladores están trabajando para resolver problemas mediante los respectivos parches. Sea cual sea el sistema operativo que uses debes mantenerlo actualizado. Por lo general, algunos despliegan un aviso cuando hay "updates" disponibles. De lo contrario, es recomendable visitar de vez en cuando la página oficial y descargar las novedades.</p>
                                </div>

                                <div className="question1">
                                    <h2>Phishing</h2>

                                    <p>Es el envío de correo electrónico perfectamente disfrazados como correos oficiales de bancos o tiendas, pidiendo al usuario seguir un enlace para 'actualizar sus datos'. La página en cuestión también simula ser oficial, siendo la encargada de captar la información.</p>
                                </div>

                                <div className="question1">
                                    <h2>Medidas de prevención</h2>

                                    <p>Verificar la dirección (URL): Regularmente, los usuarios se dejan llevar por la similitud entre el sitio fraudulento y el original, sin verificar siquiera que la dirección o URL de ambos coincida. Aunque esto también puede ser manejado hasta cierto punto, constituye un buen identificador.</p>
                                    <p>Ante la duda, abstenerse: Tal cual. Rara vez " si no nunca “ los bancos u otras instituciones envían correos a sus usuarios pidiendo información personal. Menos aún números de cuenta o contraseñas. Si duda de la veracidad de un mensaje, haga las consultas pertinentes antes de seguirlo.</p>
                                </div>

                                <div className="question1">
                                    <h2>Contraseñas Seguras</h2>

                                    <ul className='ulQuestionDisc'>
                                        <li>Utiliza una contraseña de más de 8 caracteres.</li>
                                        <li>En esta contraseña debe incluir mayúsculas, símbolos y números.</li>
                                        <li>Evita contraseñas fáciles de adivinar como refranes, tu nombre, tus apellidos, fecha de nacimiento o de tus familiares. No uses secuencias de números ni de letras. Tampoco utilices palabras que aparezcan en el diccionario.</li>
                                        <li>Nunca escribas esta contraseña en papel y cuando la escribas en el teclado, asegúrate de teclearla rápido.</li>
                                        <li>Utiliza diversas contraseñas para los distintos sistemas.</li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}


export function PoliticaSeguridadMobile() {
    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Politica de Seguridad');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN">
                        <div className="btn closeBack" onClick={onClose} ></div>
                        <p className="title">Política de Seguridad</p>
                    </div>

                    <div className="dataModal">

                        <div className="boxAN">
                            <div className='bxSlider'>

                                <p className="pSliderTitleL">Estimado Cliente:</p>
                                <p className="pSliderL">Para Sanborns es importante mantenerte informado sobre los riesgos mas comunes que se encuentran en Internet, por eso hemos creado este sitio para ofrecerte consejos de prevención de riesgos dentro de este medio.</p>
                                <p className="pSliderL">Estamos seguros que siguiendo estas sencillas medidas de seguridad, podrás aprovechar los beneficios que se ofrecen por Internet de una manera más segura.</p>

                                <p className="pSliderTitleL">Seguridad para Tarjetas de Crédito</p>
                                <p className="pSliderL">Nuestro Software para Servidor Seguro encripta los datos, asegurando que las transacciones vía Internet sean privadas y protegidas. Tus datos no pueden ser interceptados por ninguna otra persona mientras estos viajan de tu computadora a la nuestra. Una vez que nosotros recibimos los datos se almacenan en una computadora inaccesible desde Internet.</p>
                                <p className='bold pSliderL'>¡La seguridad de tu Pedido es nuestra principal preocupación!</p>
                                <p className="pSliderL">Todos los datos que teclees, incluyendo el número de tu tarjeta de crédito, nombre, domicilio, teléfono, etc. se encriptan bajo un método sofisticado utilizando tecnología de punta y se envían a nuestro servidor seguro. Estos datos no pueden ser leídos de ninguna manera durante su transmisión. Al recibir nosotros esos datos, los almacenamos encriptados en una base de datos alojada en una computadora inaccesible desde Internet, preservando la seguridad de los mismos después de su transmisión.</p>
                                <p className="pSliderL">Estadísticamente es mucho más seguro utilizar una tarjeta de crédito en el Web que en un restaurante o una tienda. Nosotros pensamos que esto es una buena noticia para cualquiera, sin embargo, de todas formas tomamos todas las precauciones posibles para proteger la integridad de los datos de las tarjetas de crédito.</p>
                                <p className="pSliderL">Al comprar en nuestra tienda, puedes olvidarte totalmente de los mitos que han surgido acerca de los fraudes por la inseguridad de transmisión de datos personales a través de Internet.</p>
                                <p className="pSliderL">Por tu seguridad; por favor no envíes tu número de tarjeta crédito por correo electrónico, ya que este no viaja de manera encriptado.</p>

                                <p className="pSliderTitleL">¿Qué es Spyware?</p>
                                <p className="pSliderL">Spyware es un tipo de software que manda tu información personal a terceros sin que des tu consentimiento. Este tipo de información puede ser tan delicada como por ejemplo tu nombre de usuario y contraseña de banca electrónica. Por lo general personas con pocos escrúpulos utilizan esta información para cometer fraudes electrónicos.</p>

                                <p className="pSliderTitleL">¿Su equipo ha sido infectado por Spyware?</p>
                                <p className="pSliderL">El Spyware se instala sin permiso en tu equipo siguiendo uno de estos métodos:</p>
                                <p className="pSliderL">Engañándote para que hagas clic en el enlace que lo instala. Los enlaces a Spyware no lo parecen, disimulan haciéndote pensar que estás en otro sitio. A veces, los que promocionan Spyware pondrán un título falso en la barra en una ventana vacía y luego instalarán Spyware cuando intentas cerrar la ventana.</p>
                                <p className="pSliderL">Al instalar software gratuito en el que está incluido. Por ejemplo, puedes instalar un programa de archivo compartido que te instala Spyware sin que tú lo sepas.</p>

                                <p className="pSliderTitleL">Mantenerse actualizado</p>
                                <p className="pSliderL">Una buena forma de proteger tu computadora es actualizar el sistema operativo y programas como navegadores, antivirus y firewalls. Esto, porque la mayoría de los virus y spyware aprovechan los "agujeros de seguridad" para infectar el sistema. Por eso, constantemente los propios desarrolladores están trabajando para resolver problemas mediante los respectivos parches. Sea cual sea el sistema operativo que uses debes mantenerlo actualizado. Por lo general, algunos despliegan un aviso cuando hay "updates" disponibles. De lo contrario, es recomendable visitar de vez en cuando la página oficial y descargar las novedades.</p>

                                <p className="pSliderTitleL">Phishing</p>
                                <p className="pSliderL">Es el envío de correo electrónico perfectamente disfrazados como correos oficiales de bancos o tiendas, pidiendo al usuario seguir un enlace para 'actualizar sus datos'. La página en cuestión también simula ser oficial, siendo la encargada de captar la información.</p>

                                <p className="pSliderTitleL">Medidas de prevención</p>
                                <p className="pSliderL">Verificar la dirección (URL): Regularmente, los usuarios se dejan llevar por la similitud entre el sitio fraudulento y el original, sin verificar siquiera que la dirección o URL de ambos coincida. Aunque esto también puede ser manejado hasta cierto punto, constituye un buen identificador.</p>
                                <p className="pSliderL">Ante la duda, abstenerse: Tal cual. Rara vez " si no nunca “ los bancos u otras instituciones envían correos a sus usuarios pidiendo información personal. Menos aún números de cuenta o contraseñas. Si duda de la veracidad de un mensaje, haga las consultas pertinentes antes de seguirlo.</p>

                                <p className="pSliderTitleL">Contraseñas Seguras</p>
                                <ul className='ulDeclaracionesDisc'>
                                    <li>Utiliza una contraseña de más de 8 caracteres.</li>
                                    <li>En esta contraseña debe incluir mayúsculas, símbolos y números.</li>
                                    <li>Evita contraseñas fáciles de adivinar como refranes, tu nombre, tus apellidos, fecha de nacimiento o de tus familiares. No uses secuencias de números ni de letras. Tampoco utilices palabras que aparezcan en el diccionario.</li>
                                    <li>Nunca escribas esta contraseña en papel y cuando la escribas en el teclado, asegúrate de teclearla rápido.</li>
                                    <li>Utiliza diversas contraseñas para los distintos sistemas.</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function PoliticaSeguridad() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Politica de Seguridad');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {isMobile &&
                isMobile === 'desktop'
                ? <PoliticaSeguridadDesktop />

                : isMobile === 'movile'
                    ? null
                    : <PoliticaSeguridadMobile />
            }
        </>
    )
}