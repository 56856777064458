import React, {useState, useEffect} from "react"
import './style.sass';
import Header from 'pages/Header/HeaderNavegacion';
import Footer from 'pages/Footer/FooterDesktop';

import { useIsMobile } from 'hooks/useIsMobile';
import BarraCP from 'components/BarCP/BarHeaderCP';
import Loading from 'components/Loading';
import { decodeStorage } from 'functions/storageBase64';
import ModalPortal from 'components/Modal';
import {getDeleteAccount} from 'services/UserProfile'
import { useLogout } from 'hooks/useLogin'

import { useValidateSession } from 'hooks/useValidateSession'
import { useToken } from 'hooks/useToken';

export function DeleteAccount(){

    const { isMobile } = useIsMobile();
    const [loading, setLoading] = useState(false);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'My Account Delete Page');
    }, [])
    

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const reciveDirection = (val) => {
        setUpdateCPBar(val);
    }

    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    //recibe la direccion del CP   
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPostal] = useState(codigoP);
    const changeDirectionBar = (val) => {
        setCodigoPostal(val);
        setUpdateCPBar(val);
    };

    return(
        <>
             {loading
                ? <Loading />
                : null
            }
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} headerEnCategoria={"headerListaDeseos"} tituloPagina="Eliminar cuenta" />
            {isMobile && (isMobile === 'desktop' || isMobile === 'movile')
                ? <section className="barCpResponsive">
                    <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
                </section>
                : null
            }
            <main>
                <BorrarCuenta setLoading={setLoading} />
            </main>
            <Footer />
        </>
    )
}

//- funcion de modal de eliminar direccion
function MessageRemove(props) {
    const { remvoeAction, awaitAction } = props;

    const elimiar = () => remvoeAction(true);
    const recapacitar = () => awaitAction(true);

    return (
        <div className="modalRemoveAddress">
            <p>¿Estas seguro que deseas eliminar tu cuenta?</p>
            <div className="contBtns">
                <div className="btn blanco" onClick={recapacitar}>Cancelar</div>
                <div className="btn blanco" onClick={elimiar}>Confirmar</div>
            </div>
        </div>
    )
}


export function BorrarCuenta (props){
    const { logOut } = useLogout()

    const {
        setLoading
    } = props

    const email = decodeStorage('profileData')
    const [showModal, SetShowModal] = useState(false);
    const [aceptDelete, setceptDelete] = useState(false)
    const [deleteAc, setDeleteAc] = useState(false)
    const [response, setResponse] = useState(false)
    const [responseMessage, setResponseMessage] = useState(false)

    // funciones para mostrar el modal
    const showModalRemove = () => SetShowModal(true);
    const handleClose = () => SetShowModal(false);

    // detona modal para eliminar direccion
    const removeAccount = () => showModalRemove();
    const noRemove = () => SetShowModal(false);

    const siRemove = () => {
        setceptDelete(true)
    }

    /*  --------------------------- Inicio --------------------------------------  */
        //  Hooks para validar tiempo y refresh token mas state de boton
        const {checkValidateSession } = useValidateSession()
        const refreshCyR = useToken({firstActive: false, name: "EditerDreccion", timeRef: 1000})
        const [actionOK, setActionOk] = useState(false)
    /*  --------------------------- Fin --------------------------------------  */

        // Va al servicio para traer la direccion seleccionada
        useEffect(() => {
            if (aceptDelete) {
                checkValidateSession(true)
                setActionOk('deleteAccount')
            }
        }, [aceptDelete])

    /*  --------------------------- Inicio --------------------------------------  */
        //  Ajusta Los tiempos y refresca token
        const [serviceRT, setServiceRT] = useState(false)
        const [uD, setUD] = useState(false)
        useEffect(() => {
            if(actionOK){
                let  actTimeLS = Date.now() 
                let  atExpLS = decodeStorage('access_token_exp') * 1000
                setUD(false)
                
                // console.log('------------------------------')
                // console.log('actTimeLS : ', new Date() )
                // console.log('atExp   : ', new Date (atExpLS) )
                // console.log('time ok : ', actTimeLS <= atExpLS)
                // console.log('actionOK: ', actionOK)
                
                if(actTimeLS <= atExpLS || Date.now() <= decodeStorage('access_token_exp') * 1000 ){
                    setServiceRT(true)
                }
                else{
                    setTimeout(function(){
                        refreshCyR.forceToken(true)
                    },300)
                    setTimeout(function(){
                        setUD(true)
                    },900)
                }
            }
        }, [uD, actionOK])
    /*-------------------------------------------------*/
        // hace la peticion a servicio
        useEffect(() => {

            if(actionOK === 'deleteAccount'){
                setServiceRT(false)
                
                SetShowModal(false);
                setLoading(true);
                if(email?.correo){
                    let request = {
                        email:  email?.correo,
                        platform: "pwa"
                    }
                    getDeleteAccount(request)
                    .then(setResponse)
                }
                setActionOk(false)
            }
        }, [serviceRT])
    /*  --------------------------- Fin --------------------------------------  */

    
    useEffect(()=>{
        if(response){
            //console.log(response)
            if(response?.statusCode === 200 || response?.statusCode === 201 ){
                setLoading(false);
                setDeleteAc(true);
                if(response?.data?.data?.message){
                    setResponseMessage(response?.data?.data?.message)
                }
            }
        }
    },[response])

    return(
        <>
            {!deleteAc
                ?
                    <div className="contDeleteAccount">
                        <div className="imgLogo"></div>
                        <div className="contDeleteText">
                            <h1>Tu cuenta sanborns te permite:</h1>
                            <p>Acceder a miles de  productos, contar con envíos seguros y gratuitos a partir de $499MXN, ofertas exclusivas y realizar compras con múltiples métodos de pago</p>
                            <p className="smallColor">Al decidir eliminar tu cuenta perderas tus favoritos, tu historial de pedidos, tu listado de direcciones y tus metodos de pago guardados; no podras recuperarlos, si en un futuro quieres volver a  comprar con nosotros necesitariás volver a crear una nueva cuenta.</p>
                        </div>

                        <div className="btn blanco" onClick={removeAccount} >Eliminar cuenta</div>
                    </div>
                :
                    <div className="contDeleteAccount">
                        <div className="contDeleteTextResp">
                            <p>
                                {responseMessage}
                            </p>
                        </div>
                        <div className="btn rojo" onClick={logOut} >Aceptar</div>
                    </div>
            }


            {showModal &&
                <ModalPortal type="modalSB" onClose={handleClose} >
                    <MessageRemove
                        remvoeAction={siRemove}
                        awaitAction={noRemove}
                    />
                </ModalPortal>
            }
        </>
    )
}

