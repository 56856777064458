import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import "./style.sass";
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory } from 'react-router-dom';
import 'react-light-accordion/demo/css/index.css';

export function ComunicadosDesktop({data}) {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        //window.ineum('page', 'Comunicados');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])


    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="container">

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>{data.title}</p>
                            </div>
                        </section>

                        <div className="contImages max">
      
                            <img className="active" src={data.img} alt="logo"/>
                           
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}



export function ComunicadosMobile({data}) {
    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        //window.ineum('page', 'Comunicados');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
    return (
        <>

            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN">
                        <div className="btn closeBack" onClick={onClose} ></div>
                        <p className="title">{data.title}</p>
                    </div>

                    <div className="dataModal">

                        <div className="boxAN">

                            <div className="bxSlider">
                                <div className="contImages max">
        
                                    <img src={data.img} alt="logo"/>
                                    
                                </div>
                                                            
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export function Comunicados() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        //window.ineum('page', 'Comunicados');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const comunicado = {"title": "Bienestar Animal", "img": "https://staasobj-api.telmex.com/swift/v1/Comunicados/Bienestar_Animal.png"};

    return (
        <>
            { isMobile &&
                isMobile === 'desktop'
                ? <ComunicadosDesktop data={comunicado} />

                : isMobile === 'movile'
                    ? null
                    : <ComunicadosMobile data={comunicado} />
            }
        </>
    )
}