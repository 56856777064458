import React, { useState, useEffect } from 'react';
import './filtrosDesktop.sass';
import { ComponentViewType } from 'components/filtros/BoxVistas';
import { useHistory } from 'react-router-dom';

export default function BarraResultados(props) {

    const { results,
        obtenerOrdenSeleccionado } = props;

    const history = useHistory();
    const [valorSelect, setValorSelect] = useState("");

    // cambia valor del select
    const handleSeleccionarOrden = (e) => {
        setValorSelect(e.target.value);

        if (Number(e.target.value) !== 1) {
            obtenerOrdenSeleccionado(Number(e.target.value));
        } else {
            obtenerOrdenSeleccionado(Number(1));
        }
    };

    useEffect(() => {
        const urlParams = history.location.pathname.split('/');
        var orden = null;

        for (let i = 0; i <= (urlParams.length - 1); i++) {
            if (urlParams[i].indexOf('orden=') >= 0) {
                orden = urlParams[i].replace('orden=', '');
            }
        }

        if (orden != null && orden !== "null" && String(orden) !== "") {
            if (orden === 'A')//menor
            {
                setValorSelect(2);
            }
            if (orden === 'D')//mayor
            {
                setValorSelect(3);
            }
        } else {
            setValorSelect("");
        }
    }, [history.location.pathname]);

    return (
        <div className="barraResultados">
            {results
                ? <div className={"countResults"}>{
                    results >= 1000 && results <= 9999 //para +2000 a 9999
                        ? '+' + results[0] + ',000'
                        : (Number(results) >= 10000 && Number(results) <= 99999) // para +1000
                            ? '+' + results[0] + '0,000'
                            : (Number(results) >= 100000) // para +1000
                                ? '+' + results[0] + '00,000'
                                : results
                } resultados</div>
                : <div className={"countResults"}>0 resultados</div>
            }
            <ComponentViewType />

            <div className="contenedorOrdenar">
                <div className="seccionOrdenarDesktop">
                    <div className="boxSelectOrden">
                        <span>
                            <select className="selectOrden" value={valorSelect} onChange={handleSeleccionarOrden}>
                                <option disabled value="">Ordenar por</option>
                                <option value={1}>Relevancia</option>
                                <option value={2}>Menor precio</option>
                                <option value={3}>Mayor precio</option>
                            </select>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}