import React from "react";
import { ReactComponent as ReactLogo } from "../../../../assets/img/icon-pinterest.svg";
import Pinterest from "../../../../assets/img/icon-pinterest.svg";
import Instagram from "../../../../assets/img/icon-instagram.svg";
import Facebook from "../../../../assets/img/icon-face.svg";
import Youtube from "../../../../assets/img/icon-youtube.svg";
import Twitter from "../../../../assets/img/icon-twitter.svg";

export default function Redes() {
  return (
    <div className="iconSection">
      <div className="iconContainer">
        <a href="https://www.pinterest.com.mx/solosanborns/" target="_blank">
          <img
            className="logoRedes"
            src={Pinterest}
            title="Síguenos en Pinterest"
            width="15"
            height="15"
            loading="lazy"
            alt="pinterest"
          />
        </a>
      </div>
      <div className="iconContainer">
        <a href="https://www.instagram.com/solosanborns/" target="_blank">
          <img
            className="logoRedes"
            src={Instagram}
            title="Síguenos en Instagram"
            width="15"
            height="15"
            loading="lazy"
            alt="Instagram"
          />
        </a>
      </div>
      <div className="iconContainer">
        <a href="https://www.facebook.com/solosanborns" target="_blank">
          <img
            className="logoRedes"
            src={Facebook}
            title="Síguenos en Facebook"
            width="15"
            height="15"
            loading="lazy"
            alt="Facebook"
          />
        </a>
      </div>
      <div className="iconContainer">
        <a href="https://www.youtube.com/user/SoloSanborns" target="_blank">
          <img
            className="logoRedes"
            src={Youtube}
            title="Síguenos en Youtube"
            width="15"
            height="15"
            loading="lazy"
            alt="Youtube"
          />
        </a>
      </div>
      <div className="iconContainer">
        <a href="https://twitter.com/solosanborns" target="_blank">
          <img
            className="logoRedes"
            src={Twitter}
            title="Síguenos en Twitter"
            width="15"
            height="15"
            loading="lazy"
            alt="Twitter"
          />
        </a>
      </div>
    </div>
  );
}
