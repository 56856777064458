import React from 'react';
import './style.sass';
import ReactDOM from 'react-dom';
import '../Modal/style.sass';

export default function ModalMarca({ verMarcas, limpiarMarca, seleccionarMarca, listaMarcas, filtrarMarcas, checkedItem }) {
    return (
        ReactDOM.createPortal(
            <div className="modal full mCategorias">
                <div className="contentModal largo contenidoCategorias">
                    <div className="seccionSuperiorCategoria">
                        <div className="contentClose">
                            <span onClick={verMarcas} className="btn close"> </span>
                            <h2 onClick={verMarcas}>Por marcas</h2>
                            <span onClick={limpiarMarca} className="limpiarText"></span>
                        </div>
                    </div>

                    <div className="dataModal iteracionMarca">
                        <ul onChange={seleccionarMarca}>
                            {listaMarcas.map((mar) =>
                                <li key={mar.marca}>
                                    <label name={mar.marca} htmlFor={mar.marca}> {mar.marca} </label>
                                    <input className="radio" type="radio" id={mar.marca} value={mar.marca} name="marca"
                                        checked={checkedItem === mar.marca ? true : false} />
                                </li>
                            )}
                        </ul>
                    </div>

                    <div className="botonFiltrar">
                        <button className="bFiltrar" onClick={filtrarMarcas}>Aplicar Filtros</button>
                    </div>
                </div>
            </div>
            , document.getElementById('modal-root')
        )
    )
}