import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuPoliticas } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory } from 'react-router-dom';

export function PoliticaDevolucionDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Contact Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuPoliticas />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Política de Devolución</p>
                            </div>
                        </section>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <p>Nuestra política de devolución es muy sencilla. Podrás devolver cualquier artículo comprado en S@nborns Internet por las siguientes causas:</p>

                                <ul className="ulQuestionDisc">
                                    <li>Si el artículo presenta defectos de fabricación.</li>
                                    <li>Si existe equivocación en el artículo enviado, conservando el empaque original, el cual debe encontrarse en perfectas condiciones y sin presentar muestras de maltrato, deterioro y/o daños que lo hagan inservible.</li>
                                </ul>

                                <p>En la recepción de mercancía errónea o dañada se aplicará el cambio físico de la misma solo si ésta fue reportada durante las primeras 24 horas posteriores a su entrega, a los siguientes teléfonos:</p>

                                <ul className="ulQuestionDisc">
                                    <li>Teléfonos de Atención a Clientes: <a href="tel:26529733"> 26 52 97 33</a> o <a href='tel:800 849 33 10'>800 849 33 10</a></li>
                                </ul>
                                <p>La garantía a productos eléctricos y electrónicos será válida únicamente durante los 60 días naturales a la recepción de los mismos.</p>

                                <p className="h3">No hay Cambios ni Devoluciones en los siguientes artículos o departamentos:</p>

                                <ul className="ulQuestionDisc">
                                    <li>Relojes, joyería fina y de fantasía, lencería y corsetería, ropa interior para caballero, niño, niña y bebés, perfumería, cosméticos, tratamientos dermatológicos, telefonía celular, relojería, accesorios y juegos, consolas de videojuegos y software.</li>
                                    <li>En Computación, Tablets, iPods, iPads y Telefonía celular. - La garantía es directamente con el proveedor.</li>
                                    <li>Libros Electrónicos y Revistas Digitales.</li>
                                </ul>
                                <p className="h3">Telefonía Celular</p>
                                <p>La garantía y servicio en aparatos celulares adquiridos en el Sitio se aplicará de forma directa con el proveedor Telcel®, conforme a sus políticas, procedimientos y restricciones.</p>
                                <p>Para esto deberás acudir al Centro de Servicio Telcel® más cercano para la evaluación del equipo del Usuario.</p>
                            </div>



                            <div className="question1">
                                <h2>Procedimiento para la devolución en una tienda Sanborns</h2>

                                <ul className="ulQuestionDisc">
                                    <li>Si realizas la devolución de la mercancía, deberás dirigirte con el Gerente de la tienda Sanborns más cercana. Presentando la mercancía, conservando el empaque original, el cual debe encontrarse en perfectas condiciones y sin presentar muestras de maltrato, deterioro y/o daños que lo hagan inservible, la factura original y la tarjeta de crédito/débito en la cual se generó el cargo.</li>
                                    <li>En caso de que la mercancía presente algún defecto de fabricación. Se podrá dirigir a la sucursal Sanborns a realizar el cambio físico de mercancía. Siempre y cuando dicha tienda cuente con la existencia del producto.</li>
                                    <li>Si la tienda Sanborns no cuenta con la existencia, el Usuario podrá escoger otro artículo equivalente de su preferencia, o en caso contrario, se realizará el reembolso a la tarjeta de crédito/débito correspondiente.</li>
                                    <li>Si se envió otra mercancía por equivocación se realizará el reembolso total de la mercancía, más los gastos de envío.</li>
                                    <li>Si únicamente el Usuario desea cancelar su compra, se realizará el reembolso por el importe de la mercancía sin incluir los gastos de envío.</li>
                                    <li>En caso de una devolución de pastel vendido a través del Sitio, el Usuario podrá realizar su devolución en cualquiera de nuestras unidades y se le reintegrará su dinero o se le abonará a su tarjeta de crédito, según sea el caso.</li>
                                    <li>Para pagos en tienda con depósito, no se realizan devoluciones en efectivo.</li>
                                </ul>
                            </div>

                            <div className="question1">
                                <h2>Procedimiento para devoluciones por servicio de mensajería</h2>

                                <ul className="ulQuestionDisc">
                                    <li>Si en la localidad del Usuario no se cuenta con ninguna tienda Sanborns®, el Usuario podrá enviar los artículos conservando el empaque original, el cual debe encontrarse en perfectas condiciones y sin presentar muestras de maltrato deterioro y/o daños que lo hagan inservible, utilizando de preferencia, la misma compañía de mensajería que le entregó los artículos. El Usuario debe asegurar perfectamente el paquete. Este deberá contener la factura original, una nota aclaratoria que indique el motivo de la devolución, y si deseas la reposición del artículo o el reembolso del dinero del Usuario.</li>
                                    <li>Si se envían los artículos por mensajería, el Usuario deberá asegurarse de anotar correctamente el siguiente destinatario:</li>
                                </ul>

                                <p className="h3black pbottom0">SANBORNS HERMANOS, S.A. DE C.V.</p>
                                <p className="h3black pbottom0">Norte 45 Nº 1014 Colonia Industrial Vallejo</p>
                                <p className="h3black">C.P. 02300, Ciudad de México.</p>

                                <ul className="ulQuestionDisc">
                                    <li>Al momento en que SANBORNS reciba la devolución, contactará al Usuario para confirmar la reposición de la mercancía o el reembolso.</li>
                                    <li>Si el Usuario no ha recibido los artículos que adquirió, podrá enviar un e-mail a: <a href="mailto:sanborns@sanborns.com.mx">sanborns@sanborns.com.mx</a> o comunicarse al teléfono: <a href="tel:8008493310"> 800 849 33 10 </a> de lunes a domingo de 8:00 a 21:00 hrs. Horario de atención vía chat lunes a viernes de 10:00 a 18:00 hrs. (Horario de la Ciudad de México).</li>
                                </ul>

                                <p>Preguntas, sugerencias y/o comentarios adicionales, puedes escribirnos a: <a href="mailto:sanborns@sanborns.com.mx">sanborns@sanborns.com.mx</a></p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />

        </>
    )
}

export function PoliticaDevolucionMobile() {

    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Politica de Devolucion Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Política de devolución</p>
                    </div>
                    <div className="dataModal">

                        <div className="boxAN">

                            <div className="bxSlider">

                                <p className="pSliderL">Nuestra política de devolución es muy sencilla. Podrás devolver cualquier artículo comprado en S@nborns Internet por las siguientes causas:</p>

                                <ul className="ulDeclaracionesDisc">
                                    <li>Si el artículo presenta defectos de fabricación.</li>
                                    <li>Si existe equivocación en el artículo enviado, conservando el empaque original, el cual debe encontrarse en perfectas condiciones y sin presentar muestras de maltrato, deterioro y/o daños que lo hagan inservible.</li>
                                </ul>

                                <p className="pSliderL">En la recepción de mercancía errónea o dañada se aplicará el cambio físico de la misma solo si ésta fue reportada durante las primeras 24 horas posteriores a su entrega, a los siguientes teléfonos:</p>

                                <ul className="ulDeclaracionesDisc">
                                    <li>Teléfonos de Atención a Clientes: <a href="tel:26529733"> 26 52 97 33</a> o <a href='tel:800 849 33 10'>800 849 33 10</a></li>
                                </ul>
                                <p className="pSliderL">La garantía a productos eléctricos y electrónicos será válida únicamente durante los 60 días naturales a la recepción de los mismos.</p>

                                <p className="pSliderTitleL">No hay Cambios ni Devoluciones en los siguientes artículos o departamentos:</p>

                                <ul className="ulDeclaracionesDisc">
                                    <li>Relojes, joyería fina y de fantasía, lencería y corsetería, ropa interior para caballero, niño, niña y bebés, perfumería, cosméticos, tratamientos dermatológicos, telefonía celular, relojería, accesorios y juegos, consolas de videojuegos y software.</li>
                                    <li>En Computación, Tablets, iPods, iPads y Telefonía celular. - La garantía es directamente con el proveedor.</li>
                                    <li>Libros Electrónicos y Revistas Digitales.</li>
                                </ul>
                                <p className="pSliderTitleL">Telefonía Celular</p>
                                <p className="pSliderL">La garantía y servicio en aparatos celulares adquiridos en el Sitio se aplicará de forma directa con el proveedor Telcel®, conforme a sus políticas, procedimientos y restricciones.</p>
                                <p className="pSliderL">Para esto deberás acudir al Centro de Servicio Telcel® más cercano para la evaluación del equipo del Usuario.</p>



                                <h2 className="pSliderTitleL">Procedimiento para la devolución en una tienda Sanborns</h2>

                                <ul className="ulDeclaracionesDisc">
                                    <li>Si realizas la devolución de la mercancía, deberás dirigirte con el Gerente de la tienda Sanborns más cercana. Presentando la mercancía, conservando el empaque original, el cual debe encontrarse en perfectas condiciones y sin presentar muestras de maltrato, deterioro y/o daños que lo hagan inservible, la factura original y la tarjeta de crédito/débito en la cual se generó el cargo.</li>
                                    <li>En caso de que la mercancía presente algún defecto de fabricación. Se podrá dirigir a la sucursal Sanborns a realizar el cambio físico de mercancía. Siempre y cuando dicha tienda cuente con la existencia del producto.</li>
                                    <li>Si la tienda Sanborns no cuenta con la existencia, el Usuario podrá escoger otro artículo equivalente de su preferencia, o en caso contrario, se realizará el reembolso a la tarjeta de crédito/débito correspondiente.</li>
                                    <li>Si se envió otra mercancía por equivocación se realizará el reembolso total de la mercancía, más los gastos de envío.</li>
                                    <li>Si únicamente el Usuario desea cancelar su compra, se realizará el reembolso por el importe de la mercancía sin incluir los gastos de envío.</li>
                                    <li>En caso de una devolución de pastel vendido a través del Sitio, el Usuario podrá realizar su devolución en cualquiera de nuestras unidades y se le reintegrará su dinero o se le abonará a su tarjeta de crédito, según sea el caso.</li>
                                    <li>Para pagos en tienda con depósito, no se realizan devoluciones en efectivo.</li>
                                </ul>



                                <h2 className="pSliderTitleL">Procedimiento para devoluciones por servicio de mensajería</h2>

                                <ul className="ulDeclaracionesDisc">
                                    <li>Si en la localidad del Usuario no se cuenta con ninguna tienda Sanborns®, el Usuario podrá enviar los artículos conservando el empaque original, el cual debe encontrarse en perfectas condiciones y sin presentar muestras de maltrato deterioro y/o daños que lo hagan inservible, utilizando de preferencia, la misma compañía de mensajería que le entregó los artículos. El Usuario debe asegurar perfectamente el paquete. Este deberá contener la factura original, una nota aclaratoria que indique el motivo de la devolución, y si deseas la reposición del artículo o el reembolso del dinero del Usuario.</li>
                                    <li>Si se envían los artículos por mensajería, el Usuario deberá asegurarse de anotar correctamente el siguiente destinatario:</li>
                                </ul>

                                <p className="colorNegro bold paddingleft12">SANBORNS HERMANOS, S.A. DE C.V.</p>
                                <p className="colorNegro bold paddingleft12">Norte 45 Nº 1014 Colonia Industrial Vallejo</p>
                                <p className="colorNegro bold pbottom paddingleft12">C.P. 02300, Ciudad de México.</p>

                                <ul className="ulDeclaracionesDisc">
                                    <li>Al momento en que SANBORNS reciba la devolución, contactará al Usuario para confirmar la reposición de la mercancía o el reembolso.</li>
                                    <li>Si el Usuario no ha recibido los artículos que adquirió, podrá enviar un e-mail a: <a href="mailto:sanborns@sanborns.com.mx">sanborns@sanborns.com.mx</a> o comunicarse al teléfono: <a href='tel:8008493310'>800 849 33 10</a> de lunes a domingo de 8:00 a 21:00 hrs. Horario de atención vía chat lunes a viernes de 10:00 a 18:00 hrs. (Horario de la Ciudad de México).</li>
                                </ul>

                                <p>Preguntas, sugerencias y/o comentarios adicionales, puedes escribirnos a: <a href="mailto:sanborns@sanborns.com.mx">sanborns@sanborns.com.mx</a></p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export function PoliticaDevolucion() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Politica de Devolución');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {isMobile &&
                isMobile === 'desktop'
                ? <PoliticaDevolucionDesktop />

                : isMobile === 'movile'
                    ? null
                    : <PoliticaDevolucionMobile />
            }
        </>
    )
}