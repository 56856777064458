import React, { useState, useEffect } from 'react';
import './style.sass';
import { useIsMobile } from 'hooks/useIsMobile';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ModalPaymentMethods from './modalPaymentMethods';

export default function ModulePaymentMethods(props) {

    const { formasPagoArray, formasPagoOrdenadoArray } = props;
    const history = useHistory();
    const location = useLocation();
    const urlLink = window.location.href;
    const hashLink = window.location.hash;
    const { isMobile } = useIsMobile();

    const [showModal, SetShowModal] = useState(false);
    const showTelmexData = () => {
        SetShowModal(true);
    }

    const handleClose = () => {
        history.push(location.pathname);
    }

    useEffect(() => {
        if (hashLink === "#modalPaymentsMethods") {
            showTelmexData();
        } else {
            SetShowModal(false);
        }
    }, [hashLink, urlLink])

    const [formasPago, setFormasPago] = useState();
    const [formasPagoOrdenado, setFormasPagoOrdenado] = useState();
    useEffect(() => {
        if (formasPagoArray && formasPagoOrdenadoArray) {
            setFormasPago(formasPagoArray);
            setFormasPagoOrdenado(formasPagoOrdenadoArray);
        }
    }, [formasPagoArray, formasPagoOrdenadoArray])

    return (
        <div className="moduleTelmexPromo moduloFPdesk">
            <p className="tituloFP ">Formas de Pago</p>

            {isMobile === "desktop"
                ? <>
                    <ul className="ulPrincipalPagosDesktop">
                        {formasPago && formasPago.map(data =>
                            data.id < 4 &&
                            <li key={data.id}>  <img src={data.url_logo} alt={"Logo"} loading="lazy" width="35" height="35" />
                                {data.name}
                            </li>
                        )}
                        <li className='verMasFP'>
                            <Link to={"#modalPaymentsMethods"} onClick={showTelmexData}> Ver más </Link>
                        </li>
                    </ul>
                </>
                : <>
                    <ul className="ulPrincipalPagos">
                        {formasPago && formasPago.map(data =>
                            data.id < 4 &&
                            <li key={data.id}>  <img src={data.url_logo} alt={"Logo"} loading="lazy" width="35" height="35" />
                                {data.name}
                            </li>
                        )}
                    </ul>

                    <Link to={"#modalPaymentsMethods"} className="conocerMas" onClick={showTelmexData} >
                        <p className="tituloConoce">Conoce todas las formas de pago</p>
                    </Link>
                </>
            }

            {showModal && formasPagoOrdenado &&
                <ModalPaymentMethods onClose={handleClose} type={isMobile === "desktop" ? "" : "full"} title={isMobile === "desktop" ? "" : "Formas de Pago"} formasPago={formasPagoOrdenado}>
                    <div className="textEspecificacion dataModal11">
                        <ul>
                            {formasPagoOrdenado && formasPagoOrdenado.map(data =>
                                <li key={data.id}> {data.name}  </li>
                            )}
                        </ul>

                    </div>
                </ModalPaymentMethods>
            }
        </div>
    )
}