import React from 'react';

export function SectionAutor(props) {
    const { attributes, brand } = props;

    return (
        <div className="contenedorVendidoPorLibro">
            <p className="vendidoPor">Autor: <span>{attributes?.autor}</span></p>
            <p className="vendidoPor">Editorial: <span>{brand}</span></p>
            <p className="vendidoPor">ISBN: <span>{attributes?.isbn}</span></p>
        </div>
    )
}
