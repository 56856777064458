import React, { useRef, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Link, useHistory } from 'react-router-dom';
import { useStorage64 } from 'hooks/useStorageBase64';
import { PORTAL_URL, VARIANTS_PER_PORTAL } from 'constants/general'

import InputType from 'components/Forms/InputType';
import InputPassword from 'components/Forms/InputPassword';
import { FacebookLogin } from 'react-facebook-login-component';

import '../Login/style.sass';
import '../Login/facebook.sass';

export default function RegistroForm(props) {
    const {
        dataForm,
        warningMessage,
        urlRedirect,
        responseFacebook,
        isMobile
    } = props;

    const { register, handleSubmit, errors, watch } = useForm();
    const [registro, setRegistro] = useState();

    // inicio de sesion con facebook
    const [flaglogin, setFlagLogin] = useState(false);
    const [data, setData] = useStorage64('dataFacebook', '');
    const [carrito, setCarrito] = useStorage64('idCarrito', '')
    const [tokenFacebook, setTokenFacebook] = useStorage64('session_token', '')
    const [success, getSuccess] = useState(false)
    const history = useHistory()
    const [errorFacebook, setErrorFacebook] = useState(false)

    const sendFormRegister = data => {
        setRegistro({
            "nombre": data.nombre,
            "paterno": data.paterno,
            "materno": data.materno,
            "correo": data.correo,
            "password": data.password,
            "genero": "",
            "boletin": data.boletin,
            "cellphone": data.cellphone
        });
    };

    useEffect(() => {
        if (registro) {
            dataForm(registro);
            //setErrorFacebook(false);
        }
    }, [registro]);

    //-- Validaciones del formulario
    const valName = {
        required: 'Campo obligatorio',
        minLength: {
            value: 3,
            message: 'El nombre al menos debe de contener 3 caracteres'
        }
    }
    const valLastName = {
        required: 'Campo obligatorio',
        minLength: {
            value: 3,
            message: 'El apellido paterno al menos debe de contener 3 caracteres'
        }
    }
    const valLastNameM = {
        minLength: {
            value: 3,
            message: 'El apellido materno al menos debe de contener 3 caracteres'
        }
    }
    const valEmail = {
        required: 'Campo obligatorio',
        pattern: {
            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'Correo inválido.'
        }
    }
    const valCelular = {
        minLength: {
            value: 10,
            message: 'El número celular debe de contener 10 caracteres'
        },
        maxLength: {
            value: 10,
            message: 'El número celular debe de contener 10 caracteres'
        },
        pattern: {
            value: /[0-9]{10}/,
            message: 'El formato de teléfono es inválido'
        }
    }
    const valPassword = {
        required: 'Campo obligatorio',
        minLength: {
            value: 6,
            message: 'Faltan caracteres. Debe de contener mínimo 6.'
        }
    }
    const password = useRef()
    password.current = watch("password", "")
    const valRePassword = {
        required: 'Campo obligatorio',
        validate: value => value === password.current || "Los campos no son iguales"
    }


    return (
        <>
            {isMobile !== "desktop" && <h3 className="titleGenerar">Crear cuenta</h3>}

            {warningMessage
                ? <p className="errorMessageWarning">{warningMessage}</p>
                : null
            }

            <form onSubmit={handleSubmit(sendFormRegister)} className="loginPage">
                <InputType
                    type="text"
                    name="nombre"
                    id="nombre"
                    placeholder="Nombre"
                    refs={register(valName)}
                    errorMessage={errors.nombre && errors.nombre.message}
                />

                <InputType
                    type="text"
                    name="paterno"
                    id="paterno"
                    placeholder="Apellido Paterno"
                    refs={register(valLastName)}
                    errorMessage={errors.paterno && errors.paterno.message}
                />

                <InputType
                    type="text"
                    name="materno"
                    id="materno"
                    placeholder="Apellido Materno"
                    refs={register(valLastNameM)}
                    errorMessage={errors.materno && errors.materno.message}
                />

                <InputType
                    type="tel"
                    name="cellphone"
                    id="cellphone"
                    placeholder="Teléfono celular"
                    refs={register(valCelular)}
                    autocomplete="tel"
                    errorMessage={errors.cellphone && errors.cellphone.message}
                    maxLength="10"
                />

                <InputType
                    type="email"
                    name="correo"
                    id="correo"
                    placeholder="Correo electrónico"
                    refs={register(valEmail)}
                    autocomplete="email"
                    errorMessage={errors.correo && errors.correo.message}
                />


                <InputPassword
                    name="password"
                    id="password"
                    placeholder="Contraseña"
                    refs={register(valPassword)}
                    errorMessage={errors.password && errors.password.message}

                />

                <InputPassword
                    name="passwordCopy"
                    id="passwordCopy"
                    placeholder="Confirmar contraseña"
                    refs={register(valRePassword)}
                    errorMessage={errors.passwordCopy && errors.passwordCopy.message}
                    autocomplete="passwordCopy"
                />

                <div className="copyContrasena">
                    <p>Debe contener mínimo 6 caracteres, al menos una letra mayúscula y un número, no podrás utilizar una contraseña anterior, así como contraseñas fáciles de adivinar (ejemplo: 123456).</p>
                </div>

                <label className="checkboxContent fileset">
                    <input type="checkbox" name="boletin" id="boletin" ref={register} />
                    <p>Deseo recibir promociones exclusivas por email.</p>
                </label>

                <p className="textTerminos supTerm">
                    Al registrarte aceptas los <Link to={"/terminos-condiciones/"} className="link">Términos y Condiciones</Link> y <Link to={"/aviso-privacidad/"} className="link">Aviso de Privacidad</Link> de SANBORNS.
                </p>

                <button className="btn rojo">Crear Cuenta</button>
                <p className="textTerminos">¿Ya tienes cuenta?  <Link to={`/login${urlRedirect}`} className="link">Inicia sesión</Link></p>
            </form>

            {VARIANTS_PER_PORTAL.loginFB &&
                <div className="botonFacebook">
                    < FacebookLogin
                        socialId={VARIANTS_PER_PORTAL.loginFB}
                        responseHandler={responseFacebook}
                        xfbml={true}
                        fields="id, email, name"
                        version="v2.5"
                        buttonText="Iniciar sesión con Facebook"
                        className="facebook-button"
                        language="es_ES"
                        scope="public_profile, email" />
                </div>
            }
        </>
    )
}