import React, { useState } from "react";
import { Link } from "react-router-dom";
import Redes from "./Redes";
import "./style.sass";

export default function BoxContactanos() {
  const [menuView, setMenuView] = useState(false);

  const toggleClass = () => {
    setMenuView(!menuView);
  };

  return (
    <div className="boxClaroShop">
      <p onClick={toggleClass} className={menuView ? "active" : null}>
        CONTÁCTANOS
      </p>
      <ul>
        <li>
          <div className="textContent">
            <div className="MB-10 Aclaracion">
              Aclaración de dudas y Compras en Internet
            </div>
            <div className="MB-5 Aclaracion__Desde">
              Desde cualquier ciudad del país
            </div>
            <div className="MB-15">
              <a href="tel:55-1203-0514">55-1203-0514</a>
            </div>
            <div className="MB-15 Aclaracion__Email">
              Email:{" "}
              <a href="mailto:sanborns@sanborns.com.mx">sanborns@sanborns.com.mx</a>
            </div>
            <div className="MB-5 Horario B">Horario de atención</div>
            <div className="MB-15 Horario__Lunes">
              Lunes a Domingo de 8:00 a 21:00 hrs.
            </div>
            <div className="MB-10 Consulta B">Consulta de Saldos y Crédito Sanborns</div>
            <div className="MB-5 Consulta__Desde">
              Desde cualquier ciudad del país
            </div>
            <div className="MB-15 Consulta__Telefono">
              <a href="tel:55-5130-3960">55-5130-3960</a>
            </div>
            <div className="MB-10 Siguenos B">Síguenos:</div>
            <Redes />
          </div>
        </li>
      </ul>
    </div>
  );
}
