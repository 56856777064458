import React, { useState, useEffect } from 'react';
import { getPedido, LoginAyudaDinamica } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import './ayudaDinamicaDesktop.sass';
import { BASE_PATH_PORTAL } from 'constants/general';
import { removeDuplicates } from 'functions/removeDuplicates';
import Loading from 'components/Loading';
import { useStorage64 } from 'hooks/useStorageBase64';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { USERNAME, PASSWORD, PUBLICKEY } from 'constants/general';

export function SolicitarCambioDevolucionDesktop(props) {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [ima, setima] = useState([]);
    const [loading, setLoading] = useState(false);
    const [storageCD,] = useStorage64('storageCD', ''); //obtiene seleccion entre cambio o devolucion
    const [storageIdProblem,] = useStorage64("storageIdProblem", "");

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido && resultadoPedido.pedido) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido])

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    // obtiene servicio pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido)
    }, [])

    useEffect(() => {
        let imagenProducto = [];

        if (resultadoPedido) {
            if (resultadoPedido.pedido) {
                // recorre por producto
                if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {
                    if (resultadoPedido.pedido[0]) {
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem.images[0].url, "id": elem.related_product_id })
                        )
                        let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                        setima(limpioImagen);
                    }
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
                LoginAyudaDinamica({ username: USERNAME, password: PASSWORD, publicKey: PUBLICKEY })
                setTimeout(() => {
                    getPedido()
                        .then(setResultadoPedido)
                }, 1000);
            }
        }
    }, [resultadoPedido])

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    // redirecciona a cual es tu motivo
    const handleContinuar = () => {
        history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/motivo-cancelacion");
    }

    // redirecciona a agregar imagenes
    const handleContinuarCD = () => {
        history.push("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/adjuntar-fotografias");
    }

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            {loading && <Loading />}

            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="miCuentaContainer">
                    <div className="containerAD">
                        <div className="boxBtnRegresarDktop">
                            <div className="icoBack" onClick={onClose}></div>
                            <button className="btnBackAD" onClick={onClose}>Atrás</button>
                        </div>

                        <div className="boxGeneralAD">

                            <div className="boxLeftAD">
                                <div className="contSectionRed">
                                    <div className={ima.length > 1 ? "imaDesktop more" : "imaDesktop"}>
                                        {ima.length > 0 && ima.map((elemento, i) => i <= 3 && <img key={i} src={elemento.url} alt={"imagen" + i} width={70} />)}
                                    </div>

                                    {/* Es lo que compré, pero tengo un problema || Mi producto llegó maltratado || No es lo que compré -   DEVOLUCION */}
                                    {(Number(storageIdProblem) === 8 || (Number(storageIdProblem) === 10) || (Number(storageIdProblem) === 9 && Number(storageCD) === 10)) &&
                                        <div className="textDektop"> <p>Solicitar Devolución</p> </div>
                                    }
                                    {/*  No es lo que compré - CAMBIO  */}
                                    {(Number(storageIdProblem) === 9 && Number(storageCD) === 9) && <div className="textDektop"> <p>Solicitar Cambio</p> </div>}

                                </div>
                            </div>

                            <div className="boxRightAD">
                                {/* que paso con tu producto --  storage id reason 8 */}
                                {Number(storageIdProblem) === 8 &&
                                    <div className="boxSolicitarDevolucion">
                                        <h3>Antes de realizar tu devolución, ten en cuenta lo siguiente</h3>

                                        <ul className="normasL1">
                                            <li>Puedes solicitar la devolución de tu producto hasta 20 días naturales posteriores a la fecha de entrega.</li>
                                            <li>El producto debe estar sin usar, con todos sus accesorios y en el paquete original.</li>
                                            <li>Las devoluciones serán revisadas para garantizar la conformidad de ambas partes.</li>
                                            <li>No hay cambios ni devoluciones en los siguientes artículos o departamentos:
                                                <ol className="normasL2">
                                                    <li>Joyería fina y de fantasía, relojes, perfumería, farmacia, cosméticos, tratamientos dermatológicos.</li>
                                                    <li>Ropa deportiva, trajes de baño, lencería, ropa interior, ropa para eventos (vestidos de noche, cocktail, bautizo, primera comunión, etc.)</li>
                                                    <li>Cómputo, celulares, TV y video, cámaras, fotografía, software y videojuegos.</li>
                                                    <li>Muebles, electrodomésticos, línea blanca, blancos.</li>
                                                    <li>Motocicletas.</li>
                                                    <li>Licores, vinos, tabacos.</li>
                                                </ol>
                                            </li>
                                        </ul>

                                        <p className="nota">La devolución podrá ser rechazada si contiene alguno de los productos mencionados.</p>
                                        <div className="boxPoliticas">
                                            <a href={BASE_PATH_PORTAL + "/politica-devoluciones/"} className="linkPoliticas" target="_blank" rel="noopener noreferrer">Políticas de devolución</a>
                                        </div>

                                        <div className="boxBtnContinuarDevolucion">
                                            <button className="btnContinuarDevolucion" onClick={handleContinuar}>Continuar</button>
                                        </div>
                                    </div>
                                }

                                {/* que paso con tu producto -- storage id reason 9 */}
                                {Number(storageIdProblem) === 9
                                    && <div className="boxSolicitarDevolucion">
                                        {/* solicitar cambio, selecciona 9 */}
                                        {Number(storageCD) === 9 && <h3>Antes de realizar tu cambio, ten en cuenta lo siguiente:</h3>}
                                        {/* solicitar devolucion, selecciona 10 */}
                                        {Number(storageCD) === 10 && <h3>Antes de realizar tu devolución, ten en cuenta lo siguiente:</h3>}

                                        <ul className="normasL1">
                                            <li>Puedes solicitar la devolución de tu producto hasta 20 días naturales posteriores a la fecha de entrega.</li>
                                            <li>El producto debe estar sin usar, con todos sus accesorios y en el paquete original.</li>
                                            <li>Las devoluciones serán revisadas para garantizar la conformidad de ambas partes.</li>
                                        </ul>

                                        <div className="boxPoliticas">
                                            <a href={BASE_PATH_PORTAL + "/politica-devoluciones/"} className="linkPoliticas" target="_blank" rel="noopener noreferrer">Políticas de devolución</a>
                                        </div>

                                        <div className="boxBtnContinuarDevolucion">
                                            <button className="btnContinuarDevolucion" onClick={handleContinuarCD}>Continuar</button>
                                        </div>
                                    </div>
                                }

                                {/* que paso con tu producto -- storage id reason 10 */}
                                {Number(storageIdProblem) === 10
                                    && <div className="boxSolicitarDevolucion">
                                        {/* solicitar devolucion, selecciona 10 */}
                                        <h3>Antes de realizar tu devolución, ten en cuenta lo siguiente:</h3>

                                        <ul className="normasL1">
                                            <li>Puedes solicitar la devolución de tu producto hasta 20 días naturales posteriores a la fecha de entrega.</li>
                                            <li>El producto debe estar sin usar, con todos sus accesorios y en el paquete original.</li>
                                            <li>Las devoluciones serán revisadas para garantizar la conformidad de ambas partes.</li>
                                        </ul>

                                        <div className="boxPoliticas">
                                            <a href={BASE_PATH_PORTAL + "/politica-devoluciones/"} className="linkPoliticas" target="_blank" rel="noopener noreferrer">Políticas de devolución</a>
                                        </div>

                                        <div className="boxBtnContinuarDevolucion">
                                            <button className="btnContinuarDevolucion" onClick={handleContinuarCD}>Continuar</button>
                                        </div>
                                    </div>
                                }


                            </div>

                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}