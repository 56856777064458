import React from 'react'

import './style.sass'
import CardProduct from 'components/CardProduct/ClasicCard'
import CardRecomendation from 'components/CardProduct/CardRecomendation'
import { VARIANTS_PER_PORTAL } from 'constants/general'

export default function PlantillaSimpleEmarsys(props){
	const {
		item,
		c_descuento,
		image,
		price,
        msrp,
        brand,
		title,
        link,
        review,
        comments,
        shipping_price,
        fulfillment,
        categories,
        categoriesEmar,
        typeCard: typeCard = 'clasic',
        clasee,
        creditCardMonths,
        creditCardPrice,
        telmexMonths,
        telmexPrice,
        index,
        productTotal
    } = props
    
    
	const urlProd = link.replace(`${VARIANTS_PER_PORTAL.domain}`, '').replace(`https://www.sanborns.com.mx`, '')
    const nombreSEO = urlProd.split('/')[3]

	return(
        <>
            {typeCard === "clasic"
                ?
                    <CardProduct
                        id={item}
                        nombre={title}
                        nombreSEO={nombreSEO}
                        precio={msrp}
                        precioLista={price}
                        foto={image}
                        descuento={c_descuento}
                        store={brand}
                        review={review}
                        comments={comments}
                        shipping_price={shipping_price}
                        fulfillment={fulfillment}
                        categories={categories}
                        categoriesEmar={categoriesEmar}
                        brand={brand}
                        scarabitem={item}
                        clasee={clasee}
                        creditCardMonths={creditCardMonths}
                        creditCardPrice={creditCardPrice}
                        telmexMonths={telmexMonths}
                        telmexPrice={telmexPrice}
                        index={index}
                        productTotal={productTotal}
                    />
                : 
                    <CardRecomendation
                        id={item}
                        nombre={title}
                        precio={msrp}
                        precioLista={price}
                        foto={image}
                        descuento={c_descuento}
                        scarabitem={item}
                        categoriesEmar={categoriesEmar}
                        link={urlProd}
                        index={index}
                        productTotal={productTotal}
                    />
            }
        </>
	)
}