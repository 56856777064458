import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Link, useHistory } from 'react-router-dom';
import { VARIANTS_PER_PORTAL } from 'constants/general';

import InputType from 'components/Forms/InputType';
import InputPassword from 'components/Forms/InputPassword';
import { FacebookLogin } from 'react-facebook-login-component';
import './style.sass';
import './facebook.sass';

export default function LoginForm(props) {
    const {
        dataForm,
        warningMessage,
        urlRedirect,
        responseFacebook,
        isMobile
    } = props;

    const { register, handleSubmit, errors } = useForm();
    const [login, setLogin] = useState();
    const history = useHistory();

    //recibe parametros del formulario
    const sendFormLogin = data => {
        setLogin({
            "usuario": data.correo,
            "contrasena": data.password
        });
    }

    //valida si los datos son correctos y manda resultado al dataForm
    useEffect(() => {
        if (login) {
            dataForm(login);
        }
    }, [login])

    //-- Validaciones del formulario
    const valEmail = {
        required: 'Campo obligatorio',
        pattern: {
            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'Correo inválido'
        }
    }
    const valPassword = {
        required: 'Campo obligatorio',
        minLength: {
            value: 6,
            message: 'Faltan caracteres, debe contener mínimo 6.'
        }
    }

    return (
        <>
            {warningMessage
                ? <p className="errorMessageWarning">{warningMessage}</p>
                : null
            }

            <form onSubmit={handleSubmit(sendFormLogin)} className="loginPage">
                <InputType
                    type="email"
                    name="correo"
                    id="correo"
                    placeholder="Correo electrónico"
                    refs={register(valEmail)}
                    autocomplete="email"
                    errorMessage={errors.correo && errors.correo.message}
                    clase="loginPage"
                />
                <InputPassword
                    name="password"
                    id="password"
                    placeholder="Contraseña"
                    refs={register(valPassword)}
                    errorMessage={errors.password && errors.password.message}

                />

                <div className='mtop55'>
                    <button className="btn rojo">Iniciar Sesión</button>
                </div>

                <Link to="/recuperar-contrasena/" className="link notPass">¿Olvidaste tu contraseña?</Link>

                <Link to={`/registro${urlRedirect}`} className="btn blancoGris">Crear Cuenta</Link>

            </form>

            {VARIANTS_PER_PORTAL.loginFB &&
                <div className="botonFacebook">
                    < FacebookLogin
                        socialId={VARIANTS_PER_PORTAL.loginFB}
                        responseHandler={responseFacebook}
                        xfbml={true}
                        fields="id, email, name"
                        version="v2.5"
                        buttonText="Iniciar sesión con Facebook"
                        className="facebook-button"
                        language="es_ES"
                        scope="public_profile, email" />
                </div>
            }
        </>
    )
}