import {STAGE_DIRNAME, PORTAL_URL, TIMEOUT_TIME, BASE_PATH_API, BASE_PATH_API_DEV} from 'constants/general'
import axios from 'axios'


// Funcion Post Recovery Password
export  async function postRecoveryPass({email} = {}){
   
    let apiURL = null
    

    // const data = `email=${encodeURIComponent(email)}`
    // if(STAGE_DIRNAME === "dev"){
    //     apiURL = `${BASE_PATH_API_DEV.recoveryPass}/app/v1/recoveryaccount`
    // }else{
    //     apiURL = `${BASE_PATH_API}/app/v1/recoveryaccount`
    // }

    const data = {email: email}
    if(STAGE_DIRNAME === "dev"){
        apiURL = `${BASE_PATH_API_DEV.recoveryPass}/app/v1/recover-password`
    }else{
        apiURL = `${BASE_PATH_API}/app/v1/recover-password`
    }

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        data: data,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}


// Funcion Post Recovery Password
export  async function postUpdatePass({password, token} = {}){
   
    let apiURL = null
    const data = `password=${encodeURIComponent(password)}`

    if(STAGE_DIRNAME === "dev"){
        apiURL = `${BASE_PATH_API_DEV.recoveryPass}/app/v1/recoveryaccount/${token}`
    }else{
        apiURL = `${BASE_PATH_API}/app/v1/recoveryaccount/${token}`
    }

    const config = {
        method: 'put',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}


export  async function postOldRecoveryPass({email} = {}){
   
    //const apiURL = `${BASE_PATH_PORTAL}/recuperar-contrasena/`
    const apiURL = `${PORTAL_URL}/recuperar-contrasena/`
    const data = `accion=iniciar_recuperar&email=${encodeURIComponent(email)}`

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}