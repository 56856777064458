import React, { useState } from 'react'
import './style.sass'

export default function InputPassword ({...params }) {
    const {
        name,
        id,
        placeholder,
        change,
        focus,
        autocomplete,
        errorMessage,
        refs,
        children
    } = params
    
    const [viewP, sendViewP] = useState(false)

    const viewPass = () => {
        sendViewP(!viewP)
    }
    return(
        <label className="inputPassword fileset" aria-label={placeholder}>
            <input
                type={!viewP ? 'password' : 'text'}
                name={name}
                id={id}
                placeholder={placeholder}
                onChange={change}
                onFocus={focus}
                autoComplete={autocomplete}
                ref={refs}
                className={errorMessage ? "borderRed": ""}
            />
            
            <div onClick={viewPass} className={!viewP ? 'viewPass' : 'viewPass show'}></div>
            
            {children}

            {errorMessage
                ? <div className="error">{errorMessage}</div>
                : null
            }
        </label>
    )
}