import {BASE_PATH_API, TIMEOUT_TIME} from 'constants/general'
import axios from 'axios'


// Funcion Get Shops
export  async function getShop(){
   
    const apiURL = `${BASE_PATH_API}/app/v1/shop`

    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8'
        }
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}
