import React, { useState, useEffect } from 'react';
import './style.sass';
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';
import { useFilterVerification } from 'hooks/useFilterVerification';
import { urlGetFilterFunction } from 'functions/urlGetFilterFunction';
import { dataLayerCategory } from 'functions/dataLayerFunction';
import { decodeStorage, removeStorage } from 'functions/storageBase64'
import getCategory from 'services/getCategory';
import { VARIANTS_PER_PORTAL } from 'constants/general'
import { ErrorBoundary } from 'components/Error/ErrorBoundary';


import Header from 'pages/Header/HeaderNavegacion';
import Footer from 'pages/Footer/FooterDesktop';
import { BreadcrumbCategory } from 'components/BreadcrumbCategory';
import CardProduct from 'components/CardProduct/ClasicCard';
import Loading from 'components/Loading';
import Filtros from 'components/filtros/filtros';
import ErrorSearchServices from 'components/Error/ErrorSearch';
import Paginador from '../../../components/Paginator';
import SlidersEmarsys from 'components/Emarsys/SlidersEmarsys';
import BarraCP from 'components/BarCP/BarHeaderCP';
import FiltrosDeskLogica from 'components/filtrosDesktop/filtrosDesk';
import BarraResultados from 'components/filtrosDesktop/barraResultados';
import { WhatsBuscador } from 'components/ProductDetail/SirenaWhatsApp'
import { anteaterProductAdapter } from 'adapters/products_Adapter';

export default function CategoriaPage(props) {

    const [scrollY, setScrollY] = useState()
    const [activeScroll, setActiveScroll] = useState(false)
    useEffect(() => {
        let searchBusqueda = decodeStorage('searchBusqueda')
        if(searchBusqueda >= 1){
            setScrollY(searchBusqueda)
            setActiveScroll(true)
        }else{
            setScrollY(0)
            setActiveScroll(true)
        }
    }, [])

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Category Results Page');
        // window.scrollTo(0, 0);

        removeStorage('wishlistAfter');
        removeStorage('productAfter');
        removeStorage('typeButton');

    }, []);

    useEffect(() => {
        if(activeScroll){
            if(!loading){
                window.scrollTo(0, scrollY);
                setActiveScroll(false)
            }
        }
    }, [activeScroll])

    const [ordenS, setOrdenS] = useState(1);

    const obtenerOrdenSeleccionado = (orden) => {
        setOrdenS(orden);
    };

    const [vista, setVista] = useState(false);

    const seEnviaVista = (vistaS) => {
        setVista(vistaS);
    };

    const { isMobile } = useIsMobile();
    const params = props.location.pathname;
    const [productCategory, setProductCategory] = useState([]);
    const [searchParams, setSearchParams] = useState({
        busqueda: null,
        idtienda: null,
        subCategory: null,
        marca: null,
        precioMenor: null,
        precioMayor: null,
        categoria: null,
        start: null,
        shipping: null,
        fulfillment: null,
        page: null,
        order: null,
        discount: null
    });

    const filters = urlGetFilterFunction();
    const [paginacion, setPaginacion] = useState(0);
    const [loading, setLoading] = useState(true);
    var arrayProductos = [];
    const [cantidadPaginas, setCantidadPaginas] = useState();

    // actualiza los parametros de la url
    useEffect(() => { updateParamsCategoria() }, [params]);

    // actualiza los pamaetros enviados por la url
    const updateParamsCategoria = () => {
        setSearchParams({
            busqueda: filters.busqueda,
            idtienda: filters.idtienda,
            subCategory: filters.subCategory,
            marca: filters.marca,
            precioMenor: filters.precioMenor,
            precioMayor: filters.precioMayor,
            categoria: filters.categoria,
            page: filters.page,
            order: filters.order,
            start: filters.start,
            shipping: filters.shipping,
            fulfillment: filters.fulfillment,
            discount: filters.discount
        });
    };

    // asigna los valores al servicio getCategory
    useEffect(() => {
        if (searchParams) {
            if (!searchParams.idtienda && !searchParams.subCategory) {
                return
            } else {
                getCategory({
                    searchText: searchParams.busqueda, idtienda: searchParams.idtienda, subCategory: searchParams.subCategory, brand: searchParams.marca,
                    lowerPrice: searchParams.precioMenor, higherPrice: searchParams.precioMayor, id_Category: searchParams.categoria,
                    order: searchParams.order, pagination: searchParams.page, start: searchParams.start, shipping: searchParams.shipping, fulfillment: searchParams.fulfillment,
                    discount: searchParams.discount
                })
                    .then(setProductCategory)
            }
        }

    }, [searchParams]);

    useEffect(() => {
        if (searchParams) {
            if (searchParams.idtienda) {
                setLoading(true);
            }
        }
    }, [searchParams.idtienda]);

    // muestra el loading
    useEffect(() => {
        if (productCategory) {
            if (Object.keys(productCategory).length > 0) {
                setLoading(false);
                setTimeout(function () {
                    // window.scrollTo(0, 0);
                }, 500)
            }
        }
    }, [productCategory]);

    const url_Per_Pagination = params.replace('/pagina=' + searchParams.page, '');
    var logitud = params.split('pagina=').pop();

    // funciones para la paginacion
    useEffect(() => {
        if (Number(logitud) < 1) {
            setSearchParams({
                ...searchParams,
                page: 1
            });
        }

        if (Number(logitud) > 100) {
            setSearchParams({
                ...searchParams,
                page: 100
            });
        }
    }, [productCategory]);


    // hook para saber si el resultado contiene los filtros: review, fulfillment, envioGratis
    const variblesFiltrosCategoria = useFilterVerification(productCategory);

    // componentes para DataLayer
    const [categoryBread, setCategoryBread] = useState();
    const [dataLayerData, setDataLayerData] = useState();
    const listIdProducts = [];
    const catBreadcrumb = [];
    const idBreadcrumb = [];
    const listProducts = [];
    //se ejecuta cuadno tiene datos de la categoria para llenar el datalayer
    useEffect(() => {
        if (productCategory) {
            if (productCategory.searchResult) {
                if (productCategory.searchResult.GSP) {
                    if (productCategory.searchResult.GSP.RES) {
                        if (typeof (productCategory.searchResult.GSP.RES) === "object") {
                            if (productCategory.searchResult.GSP.RES.R != undefined && productCategory.searchResult.GSP.RES.R.length >= 1) {
                                productCategory.searchResult.GSP.RES.R.map(({ MT }, indexPos) => {
                                    listIdProducts.push(
                                        MT[MT.findIndex(item => item.N === 'id')] ? MT[MT.findIndex(item => item.N === 'id')].V : null
                                    )
                                    catBreadcrumb.push(
                                        MT[MT.findIndex(item => item.N === 'breadcrumbs')] ? MT[MT.findIndex(item => item.N === 'breadcrumbs')].V : null
                                    )
                                    idBreadcrumb.push(
                                        MT[MT.findIndex(item => item.N === 'breadcrumbs_id')] ? MT[MT.findIndex(item => item.N === 'breadcrumbs_id')].V : null
                                    )
                                    listProducts.push({
                                        name: MT[MT.findIndex(item => item.N === 'title')] ? MT[MT.findIndex(item => item.N === 'title')].V : null,
                                        id: MT[MT.findIndex(item => item.N === 'id')] ? MT[MT.findIndex(item => item.N === 'id')].V : null,
                                        price: Number(MT[MT.findIndex(item => item.N === 'price')] ? MT[MT.findIndex(item => item.N === 'price')].V : null),
                                        offer: Number(MT[MT.findIndex(item => item.N === 'sale_price')] ? MT[MT.findIndex(item => item.N === 'sale_price')].V : null),
                                        brand: MT[MT.findIndex(item => item.N === 'attribute_marca')] ? MT[MT.findIndex(item => item.N === 'attribute_marca')].V : null,
                                        seller: MT[MT.findIndex(item => item.N === 'store')] ? MT[MT.findIndex(item => item.N === 'store')].V : null,
                                        list: 'BusquedaCategoria',
                                        category: MT[MT.findIndex(item => item.N === 'categorias_leaf')] ? MT[MT.findIndex(item => item.N === 'categorias_leaf')].V : null,
                                        position: indexPos + 1
                                    })
                                })
                                let breadcrumbCat
                                if (idBreadcrumb[1].indexOf(params.split('/')[2]) === 0) {
                                    breadcrumbCat = catBreadcrumb[1][2] + '/' + catBreadcrumb[1][1] + '/' + catBreadcrumb[1][0];
                                }
                                else if (idBreadcrumb[1].indexOf(params.split('/')[2]) === 1) {
                                    breadcrumbCat = catBreadcrumb[1][2] + '/' + catBreadcrumb[1][1];
                                }
                                else if (idBreadcrumb[1].indexOf(params.split('/')[2]) === 2) {
                                    breadcrumbCat = catBreadcrumb[1][2];
                                }
                                else if (idBreadcrumb[1].indexOf(params.split('/')[2]) >= 3) {
                                    breadcrumbCat = catBreadcrumb[1][1] + '/' + catBreadcrumb[1][idBreadcrumb[1].indexOf(params.split('/')[2])];
                                }

                                if (breadcrumbCat) {
                                    setCategoryBread(breadcrumbCat.replace(/\//ig, '>').toLowerCase());

                                    setDataLayerData({
                                        idCat: params.split('/')[2],
                                        categories: breadcrumbCat,
                                        listIdProducts: listIdProducts,
                                        results: productCategory.searchResult.GSP.RES.M,
                                        listProducts: listProducts,
                                        pathName: window.location.pathname
                                    });
                                }
                            } else {
                                if ((productCategory.searchResult.GSP.RES.R) != undefined) {

                                    listIdProducts.push(
                                        productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'id').V ? productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'id').V : null
                                    )
                                    catBreadcrumb.push(
                                        productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'breadcrumbs').V ? productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'breadcrumbs').V : null
                                    )
                                    idBreadcrumb.push(
                                        productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'breadcrumbs_id').V ? productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'breadcrumbs_id').V : null
                                    )
                                    listProducts.push({
                                        name: productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'title').V ? productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'title').V : null,
                                        id: productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'id').V ? productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'id').V : null,
                                        price: Number(productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'price').V ? productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'price').V : null),
                                        offer: Number(productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'sale_price').V ? productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'sale_price').V : null),
                                        brand: productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'attribute_marca').V ? productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'attribute_marca').V : null,
                                        seller: productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'store').V ? productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'store').V : null,
                                        list: 'BusquedaCategoria',
                                        category: productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'categorias_leaf').V ? productCategory.searchResult.GSP.RES.R.MT.find(data => data.N === 'categorias_leaf').V : null,
                                        position: 1
                                    })

                                    let breadcrumbCat
                                    if (idBreadcrumb[0].indexOf(params.split('/')[2]) === 0) {
                                        breadcrumbCat = catBreadcrumb[0][2] + '/' + catBreadcrumb[0][1] + '/' + catBreadcrumb[0][0];
                                    }
                                    else if (idBreadcrumb[0].indexOf(params.split('/')[2]) === 1) {
                                        breadcrumbCat = catBreadcrumb[0][2] + '/' + catBreadcrumb[0][1];
                                    }
                                    else if (idBreadcrumb[0].indexOf(params.split('/')[2]) === 2) {
                                        breadcrumbCat = catBreadcrumb[0][2];
                                    }
                                    else if (idBreadcrumb[0].indexOf(params.split('/')[2]) >= 3) {
                                        breadcrumbCat = catBreadcrumb[0][1] + '/' + catBreadcrumb[0][idBreadcrumb[1].indexOf(params.split('/')[2])];
                                    }

                                    setCategoryBread(breadcrumbCat.replace(/\//ig, '>').toLowerCase());

                                    setDataLayerData({
                                        idCat: params.split('/')[2],
                                        categories: breadcrumbCat,
                                        listIdProducts: listIdProducts,
                                        results: productCategory.searchResult.GSP.RES.M,
                                        listProducts: listProducts,
                                        pathName: window.location.pathname
                                    });
                                }

                            }
                        }
                    }
                }
            }
        }
    }, [productCategory]);

    //con el datalyer lleno manda los datos a analytics
    const [chargeDatalayer, setChargeDatalayer] = useState(1);
    useEffect(() => {
        if (dataLayerData) {
            if (chargeDatalayer === 2) {
                dataLayerCategory(dataLayerData);
                setChargeDatalayer(1);
            }
            else {
                setChargeDatalayer(chargeDatalayer + 1);
            }
        }
    }, [dataLayerData]);

    if (Number(logitud) < 1) {
        return <Redirect to="pagina=1" />
    }
    if (Number(logitud) > 100) {
        return <Redirect to="pagina=1" />
    }

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const reciveDirection = (val) => {
        setUpdateCPBar(val);
    };

    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar]);

    const [nombreCategoria, setNombreCategoria] = useState(false);
    const guardarCategoria = (e) => {
        setNombreCategoria(e);
    }

    const [structData, setStructData] = useState(false)
    const [categoriaTitle, setCategoriaTitle] = useState(false)
    const [catLevel, setCatLevel] = useState(0)
    const [catArray, setCatArray] = useState([])
    const [idsArray, setIdsArray] = useState([])
    useEffect(()=> {
    
        if(productCategory?.searchResult?.GSP?.RES?.R?.length > 1){
            let dataPerProduct = productCategory?.searchResult?.GSP?.RES?.R.map( (item, index) => {
                let product = `{
                    "@type": "ListItem",
                    "position": "${index + 1}",
                    "item": {   
                        "@type": "Product",
                        "image": "${item.MT[item.MT.findIndex(dato => dato.N === "link" )].V}",
                        "url": "https://www.sanborns.com.mx/producto/${item.MT[item.MT.findIndex(dato => dato.N === "id" )].V}/${item.MT[item.MT.findIndex(dato => dato.N === "title_seo" )].V.replace(/\n/gi, " ").replace(/"/gi, "")}",
                        "name": "${item.MT[item.MT.findIndex(dato => dato.N === "title" )].V.replace(/\n/gi, " ").replace(/"/gi, "")}",
                        "description": "${item.MT[item.MT.findIndex(dato => dato.N === "description" )].V.replace(/\n/gi, " ").replace(/"/gi, "")}",
                        "brand": {
                            "@type": "Brand",
                            "name": "${item.MT[item.MT.findIndex(dato => dato.N === "attribute_marca" )].V.replace(/\n/gi, " ").replace(/"/gi, "")}",
                        },
                        "sku": "${item.MT[item.MT.findIndex(dato => dato.N === "sku" )].V}",
                        "review": {
                            "@type":"Review",
                        "author":{
                            "@type":"Person",
                            "name": "Usuario de Sanborns"
                            },
                            "reviewRating": {
                                "@type": "Rating",
                                "bestRating": "5",
                                "ratingValue":  "${item.MT[item.MT.findIndex(dato => dato.N === "review" )].V}",
                                "worstRating": "0"
                            }
                        },
                        "offers": {
                            "@type": "Offer",
                            "price": "${item.MT[item.MT.findIndex(dato => dato.N === "sale_price" )].V}"
                        }
                    }
                }`
                return product.replace(/\s+/g, ' ')
            })

            setStructData(`{
                "@context": "https://schema.org",
                "@type": "ItemList",
                "url": "https://www.sanborns.com.mx${params}",
                "numberOfItems": "${productCategory?.searchResult?.GSP?.RES?.R?.length}",
                "itemListElement": [${dataPerProduct}]
            }`)
            try{
                let idArray = productCategory?.searchResult?.GSP?.RES?.R[0].MT[productCategory?.searchResult?.GSP?.RES?.R[0].MT.findIndex(dato => dato.N === "categories_id" )]?.V.reverse()
                let catsArray = productCategory?.searchResult?.GSP?.RES?.R[0].MT[productCategory?.searchResult?.GSP?.RES?.R[0].MT.findIndex(dato => dato.N === "breadcrumbs" )]?.V

                setCatArray(catsArray)
                setIdsArray(idArray)
                setCatLevel(idArray.indexOf(params.split('/')[2]))
                
                setCategoriaTitle(productCategory?.searchResult?.GSP?.RES?.R[0].MT[productCategory?.searchResult?.GSP?.RES?.R[0].MT.findIndex(dato => dato.N === "breadcrumbs" )]?.V.reverse()[0])
            }catch(e){

                let catsArray = productCategory?.searchResult?.GSP?.RES?.R[0].MT[productCategory?.searchResult?.GSP?.RES?.R[0].MT.findIndex(dato => dato.N === "breadcrumbs" )]?.V

                setCatArray(catsArray)
                setIdsArray([params.split('/')[2]])
                setCatLevel(0)
                setCategoriaTitle(productCategory?.searchResult?.GSP?.RES?.R[0].MT[productCategory?.searchResult?.GSP?.RES?.R[0].MT.findIndex(dato => dato.N === "breadcrumbs" )]?.V)
            }    
        }

    },[productCategory])

    // useEffect(()=>{
    //     console.log('catLevel: ', catLevel)
    //     console.log('idsArray: ', idsArray)
    //     console.log('catArray: ', catArray)
    //     console.log('params: ', params.split('/')[2])
    // },[catLevel, catArray, idsArray])

    return (
        <>
            {loading ? <Loading /> : null}

            <Helmet>
                {categoriaTitle
                    ? 
                        catLevel === 0
                            ? <title>{`Compra ${catArray[0]} fácil y rápido en línea | Sanborns`}</title>
                            : catLevel === 1
                                ? <title>{`Compra ${catArray[1]} fácil y rápido en línea | Sanborns`}</title> 
                                : catLevel === 2
                                    ? <title>{`Compra ${catArray[2]} ${catArray[1]} fácil y rápido en línea | Sanborns`}</title> 
                                    : <title>{`Categoría | Sanborns.com.mx`}</title>
                    : <title>{`Categoría | Sanborns.com.mx`}</title>
                }
                <link rel="canonical" href={`https://www.sanborns.com.mx/categoria/${params.split('/')[2]}/${params.split('/')[3]}/`} />

                <meta name="robots" content="index, follow" />

                <meta name="Description" content="SANBORNS.COM.MX | Envíos a todo México · Más de 500 mil productos con todas las Formas de Pago  Compras 100% Seguras · Lo mejor en Moda, Línea blanca, Pantallas, Computo, Celulares, Juguetes, Muebles, Ferretería y más · Click & Recoge en Tienda" />
                <script type="application/ld+json">
                    { structData }
                </script>
            </Helmet>

            <ErrorBoundary>
                <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection} headerEnCategoria={"headerEnCategoriaNieta"} categoriaRecibida={nombreCategoria}/>
            </ErrorBoundary>
            <section className="barCpResponsive">
                <ErrorBoundary>
                    <BarraCP changeCP={reciveDirection} clase="barHeaderCP" />
                </ErrorBoundary>
            </section>
            <main>
                <ErrorBoundary>
                    {searchParams && <BreadcrumbCategory idCategory={searchParams.idtienda} guardarCategoria={guardarCategoria} />}
                </ErrorBoundary>

                <section className="sectionCategoryProducts">
                    <div className="container">
                        {productCategory &&
                            <>
                                {productCategory.searchResult
                                    ?
                                    <>
                                        {isMobile
                                            ? isMobile === 'movil'
                                                ? <div className="contProductCategory">
                                                    {/* componente para aplicar filtros */}
                                                    {Number(productCategory.searchResult.GSP.RES.M) !== 0 &&
                                                        <ErrorBoundary>
                                                            < Filtros results={productCategory.searchResult.GSP.RES.M} 
                                                                //searchResults={productCategory.searchResult.GSP.RES.M}
                                                                params={params}
                                                                searchParams={searchParams} 
                                                                searchResults={productCategory}
                                                                setSearchParams={setSearchParams}
                                                                getSearch={getCategory} setSearchResults={setProductCategory} history={props.history}
                                                                primeraParte={`/categoria/${searchParams.idtienda}/${searchParams.subCategory}`}
                                                                primeraParteConPagina={`/categoria/${searchParams.idtienda}/${searchParams.subCategory}/pagina=` + searchParams.page}

                                                                categoriaP={searchParams.categoria}
                                                                precioMayorP={searchParams.precioMayor}
                                                                precioMenorP={searchParams.precioMenor}
                                                                startP={searchParams.start}
                                                                shippingP={searchParams.shipping}
                                                                marcaP={searchParams.marca}
                                                                pageP={searchParams.page}
                                                                orderP={searchParams.order}
                                                                busquedaP={searchParams.busqueda}
                                                                idtiendaP={searchParams.idtienda}
                                                                subCategoryP={searchParams.subCategory}
                                                                fulfillmentP={searchParams.fulfillment}
                                                                discountP={searchParams.discount}
                                                                minimo={variblesFiltrosCategoria.minimo} maximo={variblesFiltrosCategoria.maximo}
                                                                seEnviaVista={seEnviaVista}
                                                                valorDescuento={variblesFiltrosCategoria.valorDescuento}
                                                                tieneEnvioGratis={variblesFiltrosCategoria.tieneEnvioGratis} 
                                                                tieneFulfillment={variblesFiltrosCategoria.tieneFulfillment} 
                                                                tieneReview={variblesFiltrosCategoria.tieneReview}
                                                            />
                                                        </ErrorBoundary>
                                                    }

                                                    {/* si no se encuentran resultados con filtros aplicados */}
                                                    {Number(productCategory.searchResult.GSP.RES.M) === 0
                                                        && ((searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //estrellas, envio, precios
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //estrellas, precios
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //envio, precios
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // marca
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //estrellas
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //envio
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria, marca
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria, estrellas
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria, envio
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios, marca
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //marca,estrellas
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //envio,marca
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //estrellas,envio
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //categoria,marca,precios-
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,marca,estrellas
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,marca,envio
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //estrellas,marcas,precios,
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //marca,precios,envio
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //marca,envio,estrellas
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,estrellas,envio
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,envio,categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,estrella,categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,estrella,marca,categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,envio,marca,categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,estrella,envio,categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,marca,envio,estrellas
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios, categoria,marca,envios,estrella
                                                            || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios, marca,envios,estrella

                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, estrellas
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, envio
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment,marca, precios
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, estrellas, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, envio
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, categoria, precios 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria, estrellas 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria, envio 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, estrella, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, envio, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, estrellas, envio, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, categoria, precios, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria, estrellas, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria, envio, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, precios, estrella, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, precios, envio
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, estrellas, envio, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, categoria, precios, estrella, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, categoria, precios, envio, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria, estrellas, envio, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, estrellas, envio
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, categoria, precios, estrellas
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, categoria, precios, envio, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria, estrellas, envio, 
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, precios, estrellas, envio
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, estrella, envio, categoria
                                                            || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, estrellas, envio, marca, categoria, precios


                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount estrellas precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount shipping precios
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount fulfillment precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca categoria precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca start precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca shipping precios
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca fulfillment precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria start precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria shipping precios
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria fulfillment precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount start shipping precios
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount start fulfillment precios
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount shipping fulfillment precios
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // disocunt marca categoria shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria start 
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca start shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca start fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria start shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria start fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios start shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria start shipping
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria start fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca start shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria start shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start shipping fulfillment
                                                            || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria marca start shipping fulfillment precios
                                                        )
                                                        ?
                                                        <>
                                                            <div className="sinResultados">
                                                                <div className="imagen">
                                                                    <img src="/img/searchError.svg" alt="No se encontraron resultados" width="120" height="120" loading="lazy" />
                                                                </div>

                                                                <div className="texto">
                                                                    <h2> Se encontraron 0 resultados</h2>
                                                                    <p> Por favor intenta con un filtro diferente</p>
                                                                </div>

                                                            </div>
                                                        </>
                                                        : (Number(productCategory.searchResult.GSP.RES.M) === 0 && (searchParams.discount == null && searchParams.fulfillment == null && searchParams.categoria == null && searchParams.marca == null && searchParams.precioMayor == null && searchParams.precioMenor == null && searchParams.shipping == null && searchParams.start == null))
                                                            ? <ErrorSearchServices searchQ={productCategory.searchResult.GSP.Q} suggestion={productCategory.searchResult.GSP.Spelling ? productCategory.searchResult.GSP.Spelling.Suggestion.V : null} />
                                                            : null
                                                    }

                                                    {Number(productCategory.searchResult.GSP.RES.M) !== 0 &&
                                                        <>
                                                            {(productCategory.searchResult.GSP.RES.R) != undefined
                                                                ?
                                                                ((productCategory.searchResult.GSP.RES.R).length > 1
                                                                    ? productCategory.searchResult.GSP.RES.R.map(({ MT }, index) => {
                                                                        arrayProductos.push({
                                                                            nombre: MT[MT.findIndex(item => item.N === 'title')] ? MT[MT.findIndex(item => item.N === 'title')].V : null,
                                                                            nombreSEO: MT[MT.findIndex(item => item.N === 'title_seo')] ? MT[MT.findIndex(item => item.N === 'title_seo')].V : null,
                                                                            precio: MT[MT.findIndex(item => item.N === 'price')] ? MT[MT.findIndex(item => item.N === 'price')].V : null,
                                                                            id: MT[MT.findIndex(item => item.N === 'id')] ? MT[MT.findIndex(item => item.N === 'id')].V : null,
                                                                            precioLista: MT[MT.findIndex(item => item.N === 'sale_price')] ? MT[MT.findIndex(item => item.N === 'sale_price')].V : null,
                                                                            foto: MT[MT.findIndex(item => item.N === 'link_thumbnail')] ? MT[MT.findIndex(item => item.N === 'link_thumbnail')].V : null,
                                                                            descuento: MT[MT.findIndex(item => item.N === 'discount')] ? MT[MT.findIndex(item => item.N === 'discount')].V : null,
                                                                            store: MT[MT.findIndex(item => item.N === 'store')] ? MT[MT.findIndex(item => item.N === 'store')].V : null,
                                                                            review: MT[MT.findIndex(item => item.N === 'review')] ? MT[MT.findIndex(item => item.N === 'review')].V : null,
                                                                            total_review: MT[MT.findIndex(item => item.N === 'total_review')] ? MT[MT.findIndex(item => item.N === 'total_review')].V : null,
                                                                            shipping_price: MT[MT.findIndex(item => item.N === 'shipping_price')] ? MT[MT.findIndex(item => item.N === 'shipping_price')].V : null,
                                                                            fulfillment: MT[MT.findIndex(item => item.N === 'fulfillment')] ? MT[MT.findIndex(item => item.N === 'fulfillment')].V : null,
                                                                            breadcrumbs: MT[MT.findIndex(item => item.N === 'breadcrumbs')] ? MT[MT.findIndex(item => item.N === 'breadcrumbs')].V : null,
                                                                            attribute_marca: MT[MT.findIndex(item => item.N === 'attribute_marca')] ? MT[MT.findIndex(item => item.N === 'attribute_marca')].V : null,
                                                                            telmex_price: MT[MT.findIndex(item => item.N === 'telmex_price')] ? MT[MT.findIndex(item => item.N === 'telmex_price')].V : null,
                                                                            telmex_months: MT[MT.findIndex(item => item.N === 'telmex_months')] ? MT[MT.findIndex(item => item.N === 'telmex_months')].V : null,
                                                                            credit_card_months: MT[MT.findIndex(item => item.N === 'credit_card_months')] ? MT[MT.findIndex(item => item.N === 'credit_card_months')].V : null,
                                                                            credit_card_price: MT[MT.findIndex(item => item.N === 'credit_card_price')] ? MT[MT.findIndex(item => item.N === 'credit_card_price')].V : null,
                                                                            index: index,
                                                                            cyr: MT[MT.findIndex(item=> item.N === "cyr")] ? MT[MT.findIndex(item=> item.N=== "cyr")].V : null,
                                                                            promotions: MT[MT.findIndex(item=> item.N === "promotions")] ? MT[MT.findIndex(item=> item.N=== "promotions")].V : null,
                                                                        })
                                                                    }
                                                                    )

                                                                    : [productCategory.searchResult.GSP.RES.R].map(({ MT }, index) => {
                                                                        arrayProductos.push({
                                                                            nombre: MT[MT.findIndex(item => item.N === 'title')] ? MT[MT.findIndex(item => item.N === 'title')].V : null,
                                                                            nombreSEO: MT[MT.findIndex(item => item.N === 'title_seo')] ? MT[MT.findIndex(item => item.N === 'title_seo')].V : null,
                                                                            precio: MT[MT.findIndex(item => item.N === 'price')] ? MT[MT.findIndex(item => item.N === 'price')].V : null,
                                                                            id: MT[MT.findIndex(item => item.N === 'id')] ? MT[MT.findIndex(item => item.N === 'id')].V : null,
                                                                            precioLista: MT[MT.findIndex(item => item.N === 'sale_price')] ? MT[MT.findIndex(item => item.N === 'sale_price')].V : null,
                                                                            foto: MT[MT.findIndex(item => item.N === 'link_thumbnail')] ? MT[MT.findIndex(item => item.N === 'link_thumbnail')].V : null,
                                                                            descuento: MT[MT.findIndex(item => item.N === 'discount')] ? MT[MT.findIndex(item => item.N === 'discount')].V : null,
                                                                            store: MT[MT.findIndex(item => item.N === 'store')] ? MT[MT.findIndex(item => item.N === 'store')].V : null,
                                                                            review: MT[MT.findIndex(item => item.N === 'review')] ? MT[MT.findIndex(item => item.N === 'review')].V : null,
                                                                            total_review: MT[MT.findIndex(item => item.N === 'total_review')] ? MT[MT.findIndex(item => item.N === 'total_review')].V : null,
                                                                            shipping_price: MT[MT.findIndex(item => item.N === 'shipping_price')] ? MT[MT.findIndex(item => item.N === 'shipping_price')].V : null,
                                                                            fulfillment: MT[MT.findIndex(item => item.N === 'fulfillment')] ? MT[MT.findIndex(item => item.N === 'fulfillment')].V : null,
                                                                            breadcrumbs: MT[MT.findIndex(item => item.N === 'breadcrumbs')] ? MT[MT.findIndex(item => item.N === 'breadcrumbs')].V : null,
                                                                            attribute_marca: MT[MT.findIndex(item => item.N === 'attribute_marca')] ? MT[MT.findIndex(item => item.N === 'attribute_marca')].V : null,
                                                                            telmex_price: MT[MT.findIndex(item => item.N === 'telmex_price')] ? MT[MT.findIndex(item => item.N === 'telmex_price')].V : null,
                                                                            telmex_months: MT[MT.findIndex(item => item.N === 'telmex_months')] ? MT[MT.findIndex(item => item.N === 'telmex_months')].V : null,
                                                                            credit_card_months: MT[MT.findIndex(item => item.N === 'credit_card_months')] ? MT[MT.findIndex(item => item.N === 'credit_card_months')].V : null,
                                                                            credit_card_price: MT[MT.findIndex(item => item.N === 'credit_card_price')] ? MT[MT.findIndex(item => item.N === 'credit_card_price')].V : null,
                                                                            index: index,
                                                                            cyr: MT[MT.findIndex(item=> item.N === "cyr")] ? MT[MT.findIndex(item=> item.N=== "cyr")].V : null,
                                                                            promotions: MT[MT.findIndex(item=> item.N === "promotions")] ? MT[MT.findIndex(item=> item.N=== "promotions")].V : null,
                                                                        })
                                                                    })
                                                                )
                                                                : null
                                                            }

                                                            {arrayProductos?.map(producto =>
                                                                <ErrorBoundary key={producto?.id}>
                                                                    <CardProduct
                                                                        key={producto?.id} id={producto?.id}
                                                                        nombre={producto?.nombre} nombreSEO={producto?.nombreSEO}
                                                                        precio={producto?.precio} precioLista={producto?.precioLista}
                                                                        foto={producto?.foto} descuento={producto?.descuento}
                                                                        store={producto?.store} review={producto?.review}
                                                                        shipping_price={producto?.shipping_price} fulfillment={producto?.fulfillment}
                                                                        categories={producto?.breadcrumbs} brand={producto?.attribute_marca}
                                                                        vista={vista}
                                                                        creditCardMonths={producto?.credit_card_months}
                                                                        creditCardPrice={producto?.credit_card_price}
                                                                        telmexMonths={producto?.telmex_months}
                                                                        telmexPrice={producto?.telmex_price}
                                                                        comments={producto?.total_review}
                                                                        index={producto?.index}
                                                                        clicRecoge = {producto?.cyr}
                                                                        pagosSears = {producto?.promotions}
                                                                        priority={producto?.index <= 12 ? "High" : null}
                                                                        productTotal={anteaterProductAdapter(arrayProductos[producto?.index])}
                                                                    />
                                                                </ErrorBoundary>
                                                            )}
                                                        </>
                                                    }


                                                </div>
                                                : isMobile === 'desktop'
                                                    ?
                                                    <div className="contProductCategoryDesk" >
                                                        {Number(productCategory.searchResult.GSP.RES.M) !== 0 &&
                                                            <div className="boxFiltrosDesk">
                                                                <ErrorBoundary>
                                                                    <FiltrosDeskLogica
                                                                        searchParams={searchParams}
                                                                        results={productCategory.searchResult.GSP.RES.M}
                                                                        //searchResults={productCategory.searchResult.GSP.RES.M}
                                                                        searchResults={productCategory}
                                                                        setSearchResults={setProductCategory}
                                                                        setSearchParams={setSearchParams}
                                                                        history={props.history} params={params}
                                                                        primeraParte={`/categoria/${searchParams.idtienda}/${searchParams.subCategory}`}
                                                                        marcaP={searchParams.marca}
                                                                        tieneEnvioGratis={variblesFiltrosCategoria.tieneEnvioGratis} 
                                                                        tieneFulfillment={variblesFiltrosCategoria.tieneFulfillment} 
                                                                        tieneReview={variblesFiltrosCategoria.tieneReview}
                                                                        minimo={variblesFiltrosCategoria.minimo} maximo={variblesFiltrosCategoria.maximo}
                                                                        ordenS={ordenS} setOrdenS={setOrdenS}
                                                                        valorDescuento={variblesFiltrosCategoria.valorDescuento}
                                                                    />
                                                                </ErrorBoundary>
                                                            </div>
                                                        }
                                                        <div className={Number(productCategory.searchResult.GSP.RES.M) !== 0 ? "boxGeneralProductosResultados" : "boxSinResultados"}>
                                                            {Number(productCategory.searchResult.GSP.RES.M) !== 0 &&
                                                                <ErrorBoundary>
                                                                    <BarraResultados
                                                                        results={productCategory.searchResult.GSP.RES.M} seEnviaVista={seEnviaVista} vista={vista}

                                                                        primeraParte={`/categoria/${searchParams.idtienda}/${searchParams.subCategory}`}
                                                                        history={props.history} params={params}
                                                                        searchParams={searchParams} setSearchParams={setSearchParams}
                                                                        obtenerOrdenSeleccionado={obtenerOrdenSeleccionado} />
                                                                </ErrorBoundary>
                                                            }
                                                            <div className="boxProductosCategory">
                                                                {/* si no se encuentran resultados con filtros aplicados */}
                                                                {Number(productCategory.searchResult.GSP.RES.M) === 0
                                                                    && ((searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //estrellas, envio, precios
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //estrellas, precios
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //envio, precios
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // marca
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //estrellas
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria, marca
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria, estrellas
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria, envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios, marca
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //marca,estrellas
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //envio,marca
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //estrellas,envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //categoria,marca,precios-
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,marca,estrellas
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,marca,envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //estrellas,marcas,precios,
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //marca,precios,envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) //marca,envio,estrellas
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,estrellas,envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,envio,categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,estrella,categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,estrella,marca,categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,envio,marca,categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios,estrella,envio,categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) //categoria,marca,envio,estrellas
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios, categoria,marca,envios,estrella
                                                                        || (searchParams.discount == null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) //precios, marca,envios,estrella

                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, estrellas
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment,marca, precios
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, estrellas, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, categoria, precios 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria, estrellas 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria, envio 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, estrella, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, envio, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, estrellas, envio, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, categoria, precios, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria, estrellas, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria, envio, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, precios, estrella, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, precios, envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, estrellas, envio, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, categoria, precios, estrella, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, categoria, precios, envio, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, categoria, estrellas, envio, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, estrellas, envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, categoria, precios, estrellas
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, categoria, precios, envio, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // fulfillment, marca, categoria, estrellas, envio, 
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, marca, precios, estrellas, envio
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, precios, estrella, envio, categoria
                                                                        || (searchParams.discount == null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // fulfillment, estrellas, envio, marca, categoria, precios


                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount estrellas precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount shipping precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount fulfillment precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca categoria precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca start precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca shipping precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount marca fulfillment precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria start precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria shipping precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria fulfillment precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount start shipping precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount start fulfillment precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount shipping fulfillment precios
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // disocunt marca categoria shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount start shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria start 
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca start shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca start fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria start shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria start fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios start shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca start shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount categoria start shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment == null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria start shipping
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping == null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria start fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start == null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca categoria shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria == null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios marca start shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca == null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount precios categoria start shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor == null && searchParams.precioMenor == null) // discount marca categoria start shipping fulfillment
                                                                        || (searchParams.discount != null && searchParams.fulfillment != null && searchParams.start != null && searchParams.shipping != null && searchParams.marca != null && searchParams.categoria != null && searchParams.precioMayor != null && searchParams.precioMenor != null) // discount categoria marca start shipping fulfillment precios
                                                                    )
                                                                    ?
                                                                    <>
                                                                        <div className="sinResultados">
                                                                            <div className="imagen">
                                                                                <img src="/img/searchError.svg" alt="No se encontraron resultados" width="120" height="120" loading="lazy" />
                                                                            </div>

                                                                            <div className="texto">
                                                                                <h2> Se encontraron 0 resultados</h2>
                                                                                <p> Por favor intenta con un filtro diferente</p>
                                                                            </div>

                                                                        </div>
                                                                    </>
                                                                    : (Number(productCategory.searchResult.GSP.RES.M) === 0 && (searchParams.discount == null && searchParams.fulfillment == null && searchParams.categoria == null && searchParams.marca == null && searchParams.precioMayor == null && searchParams.precioMenor == null && searchParams.shipping == null && searchParams.start == null))
                                                                        ? <ErrorSearchServices searchQ={productCategory.searchResult.GSP.Q} suggestion={productCategory.searchResult.GSP.Spelling ? productCategory.searchResult.GSP.Spelling.Suggestion.V : null} />
                                                                        : null
                                                                }

                                                                {Number(productCategory.searchResult.GSP.RES.M) !== 0 &&
                                                                    <>
                                                                        {(productCategory.searchResult.GSP.RES.R) != undefined
                                                                            ?
                                                                            ((productCategory.searchResult.GSP.RES.R).length > 1
                                                                                ? productCategory.searchResult.GSP.RES.R.map(({ MT }, index) => {
                                                                                    arrayProductos.push({
                                                                                        nombre: MT[MT.findIndex(item => item.N === 'title')] ? MT[MT.findIndex(item => item.N === 'title')].V : null,
                                                                                        nombreSEO: MT[MT.findIndex(item => item.N === 'title_seo')] ? MT[MT.findIndex(item => item.N === 'title_seo')].V : null,
                                                                                        precio: MT[MT.findIndex(item => item.N === 'price')] ? MT[MT.findIndex(item => item.N === 'price')].V : null,
                                                                                        id: MT[MT.findIndex(item => item.N === 'id')] ? MT[MT.findIndex(item => item.N === 'id')].V : null,
                                                                                        precioLista: MT[MT.findIndex(item => item.N === 'sale_price')] ? MT[MT.findIndex(item => item.N === 'sale_price')].V : null,
                                                                                        foto: MT[MT.findIndex(item => item.N === 'link_thumbnail')] ? MT[MT.findIndex(item => item.N === 'link_thumbnail')].V : null,
                                                                                        descuento: MT[MT.findIndex(item => item.N === 'discount')] ? MT[MT.findIndex(item => item.N === 'discount')].V : null,
                                                                                        store: MT[MT.findIndex(item => item.N === 'store')] ? MT[MT.findIndex(item => item.N === 'store')].V : null,
                                                                                        review: MT[MT.findIndex(item => item.N === 'review')] ? MT[MT.findIndex(item => item.N === 'review')].V : null,
                                                                                        total_review: MT[MT.findIndex(item => item.N === 'total_review')] ? MT[MT.findIndex(item => item.N === 'total_review')].V : null,
                                                                                        shipping_price: MT[MT.findIndex(item => item.N === 'shipping_price')] ? MT[MT.findIndex(item => item.N === 'shipping_price')].V : null,
                                                                                        fulfillment: MT[MT.findIndex(item => item.N === 'fulfillment')] ? MT[MT.findIndex(item => item.N === 'fulfillment')].V : null,
                                                                                        breadcrumbs: MT[MT.findIndex(item => item.N === 'breadcrumbs')] ? MT[MT.findIndex(item => item.N === 'breadcrumbs')].V : null,
                                                                                        attribute_marca: MT[MT.findIndex(item => item.N === 'attribute_marca')] ? MT[MT.findIndex(item => item.N === 'attribute_marca')].V : null,
                                                                                        telmex_price: MT[MT.findIndex(item => item.N === 'telmex_price')] ? MT[MT.findIndex(item => item.N === 'telmex_price')].V : null,
                                                                                        telmex_months: MT[MT.findIndex(item => item.N === 'telmex_months')] ? MT[MT.findIndex(item => item.N === 'telmex_months')].V : null,
                                                                                        credit_card_months: MT[MT.findIndex(item => item.N === 'credit_card_months')] ? MT[MT.findIndex(item => item.N === 'credit_card_months')].V : null,
                                                                                        credit_card_price: MT[MT.findIndex(item => item.N === 'credit_card_price')] ? MT[MT.findIndex(item => item.N === 'credit_card_price')].V : null,
                                                                                        index: index,
                                                                                        cyr: MT[MT.findIndex(item=> item.N === "cyr")] ? MT[MT.findIndex(item=> item.N=== "cyr")].V : null,
                                                                                        promotions: MT[MT.findIndex(item=> item.N === "promotions")] ? MT[MT.findIndex(item=> item.N=== "promotions")].V : null,
                                                                                    })
                                                                                }
                                                                                )

                                                                                : [productCategory.searchResult.GSP.RES.R].map(({ MT }, index) => {
                                                                                    arrayProductos.push({
                                                                                        nombre: MT[MT.findIndex(item => item.N === 'title')] ? MT[MT.findIndex(item => item.N === 'title')].V : null,
                                                                                        nombreSEO: MT[MT.findIndex(item => item.N === 'title_seo')] ? MT[MT.findIndex(item => item.N === 'title_seo')].V : null,
                                                                                        precio: MT[MT.findIndex(item => item.N === 'price')] ? MT[MT.findIndex(item => item.N === 'price')].V : null,
                                                                                        id: MT[MT.findIndex(item => item.N === 'id')] ? MT[MT.findIndex(item => item.N === 'id')].V : null,
                                                                                        precioLista: MT[MT.findIndex(item => item.N === 'sale_price')] ? MT[MT.findIndex(item => item.N === 'sale_price')].V : null,
                                                                                        foto: MT[MT.findIndex(item => item.N === 'link_thumbnail')] ? MT[MT.findIndex(item => item.N === 'link_thumbnail')].V : null,
                                                                                        descuento: MT[MT.findIndex(item => item.N === 'discount')] ? MT[MT.findIndex(item => item.N === 'discount')].V : null,
                                                                                        store: MT[MT.findIndex(item => item.N === 'store')] ? MT[MT.findIndex(item => item.N === 'store')].V : null,
                                                                                        review: MT[MT.findIndex(item => item.N === 'review')] ? MT[MT.findIndex(item => item.N === 'review')].V : null,
                                                                                        total_review: MT[MT.findIndex(item => item.N === 'total_review')] ? MT[MT.findIndex(item => item.N === 'total_review')].V : null,
                                                                                        shipping_price: MT[MT.findIndex(item => item.N === 'shipping_price')] ? MT[MT.findIndex(item => item.N === 'shipping_price')].V : null,
                                                                                        fulfillment: MT[MT.findIndex(item => item.N === 'fulfillment')] ? MT[MT.findIndex(item => item.N === 'fulfillment')].V : null,
                                                                                        breadcrumbs: MT[MT.findIndex(item => item.N === 'breadcrumbs')] ? MT[MT.findIndex(item => item.N === 'breadcrumbs')].V : null,
                                                                                        attribute_marca: MT[MT.findIndex(item => item.N === 'attribute_marca')] ? MT[MT.findIndex(item => item.N === 'attribute_marca')].V : null,
                                                                                        telmex_price: MT[MT.findIndex(item => item.N === 'telmex_price')] ? MT[MT.findIndex(item => item.N === 'telmex_price')].V : null,
                                                                                        telmex_months: MT[MT.findIndex(item => item.N === 'telmex_months')] ? MT[MT.findIndex(item => item.N === 'telmex_months')].V : null,
                                                                                        credit_card_months: MT[MT.findIndex(item => item.N === 'credit_card_months')] ? MT[MT.findIndex(item => item.N === 'credit_card_months')].V : null,
                                                                                        credit_card_price: MT[MT.findIndex(item => item.N === 'credit_card_price')] ? MT[MT.findIndex(item => item.N === 'credit_card_price')].V : null,
                                                                                        index: index,
                                                                                        cyr: MT[MT.findIndex(item=> item.N === "cyr")] ? MT[MT.findIndex(item=> item.N=== "cyr")].V : null,
                                                                                        promotions: MT[MT.findIndex(item=> item.N === "promotions")] ? MT[MT.findIndex(item=> item.N=== "promotions")].V : null,
                                                                                    })
                                                                                })
                                                                            )
                                                                            : null
                                                                        }

                                                                        {arrayProductos?.map((producto,id) =>
                                                                            <CardProduct
                                                                                key={id} id={producto?.id}
                                                                                nombre={producto?.nombre} nombreSEO={producto?.nombreSEO}
                                                                                precio={producto?.precio} precioLista={producto?.precioLista}
                                                                                foto={producto?.foto} descuento={producto?.descuento}
                                                                                store={producto?.store} review={producto?.review}
                                                                                shipping_price={producto?.shipping_price} fulfillment={producto?.fulfillment}
                                                                                categories={producto?.breadcrumbs} brand={producto?.attribute_marca}
                                                                                vista={vista}
                                                                                creditCardMonths={producto?.credit_card_months}
                                                                                creditCardPrice={producto?.credit_card_price}
                                                                                telmexMonths={producto?.telmex_months}
                                                                                telmexPrice={producto?.telmex_price}
                                                                                comments={producto?.total_review}
                                                                                index={producto?.index}
                                                                                clicRecoge = {producto?.cyr}
                                                                                pagosSears = {producto?.promotions}
                                                                                priority={producto?.index <= 12 ? "High" : null}
                                                                                productTotal={anteaterProductAdapter(arrayProductos[producto?.index])}
                                                                            />
                                                                        )}
                                                                    </>
                                                                }


                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            : null
                                        }
                                        <ErrorBoundary>
                                            <Paginador setPaginacion={setPaginacion} searchParams1={searchParams} searchParams={searchParams} url_Per_Pagination={url_Per_Pagination} searchResults={productCategory} setSearchParams={setSearchParams} />
                                        </ErrorBoundary>
                                    </>
                                    : null
                                }
                            </>
                        }
                    </div>
                </section>
                {categoryBread &&
                    VARIANTS_PER_PORTAL?.emarsys?.category
                        ?
                            <section id="categorySlider">
                                <SlidersEmarsys
                                    logica={'CATEGORY'}
                                    idContent={'categorySlider'}
                                    breadcrumb={categoryBread}
                                    title={`Recomendaciones para ti`}
                                    typeView={'sliderView'}
                                    cant={30}
                                />
                            </section>
                        : null
                }
                <WhatsBuscador busqueda={searchParams.subCategory} seccion={'categoria'} />
            </main>
            <Footer />
        </>
    )
}