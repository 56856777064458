import React, { useState, useEffect } from 'react';
import { getBanks, getPedido, postCreateTiket, LoginAyudaDinamica } from 'services/AyudaDinamicaServices';
import { useHistory } from 'react-router-dom';
import './ayudaDinamicaDesktop.sass';
import { useForm } from "react-hook-form";
import { InputTypeDesktop } from 'components/Forms/InputType';
import { removeDuplicates } from 'functions/removeDuplicates';
import { SelectTypeADDesktop } from 'components/Forms/Select';
import ModalPortal from 'components/Modal';
import { ModalEstasSeguro, ModalError, ModalEstasSeguroProducto } from '../vista4-modal';
import { useStorage64 } from 'hooks/useStorageBase64';
import { removeStorage } from 'functions/storageBase64';
import Loading from 'components/Loading';
import { FORMA_PAGO_REEMBOLSABLE, LABEL_AUTORIZADO, LABEL_PREPARANDO_PRODUCTO, PEDIDOS_PRODUCTO_ENTREGADO } from 'constants/general';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { USERNAME, PASSWORD, PUBLICKEY } from 'constants/general';

export function FormularioDatosReembolsoDesktop(props) {
    const history = useHistory();
    const params = history.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];
    const [idProductoEstate, setIdProductoEstate] = useState(0)

    const [datosBancarios, setDatosBancarios] = useState(false);
    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            clabe: datosBancarios.clabe,
            banco: datosBancarios.banco,
            titular: datosBancarios.titular,
            curp: datosBancarios.curp
        }
    })
    const [datos, setDatos] = useState();
    const [returnForm, SetReturnForm] = useState(false);

    // listado de bancos
    const [bancos, setBancos] = useState(false);
    const [lisBanks, setListBanks] = useState(false);

    // informacion de pedido
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [ima, setima] = useState([]);
    const [, setEstatusPedido] = useState(0);
    const [formaPago, setFormaPago] = useState(0);

    // banderas para mostrar ayuda inputs
    const [flagClabe, setFlagClabe] = useState(false);
    const [, setFlagBancos] = useState(false);
    const [, setFlagTitular] = useState(false);
    const [flagCurp, setFlagCurp] = useState(false);

    // crear ticket
    const [showModal, SetShowModal] = useState(false); //mostrar modal estas seguro
    const [showModalError, SetShowModalError] = useState(false); //mostrar modal error
    const [crearTicket, setCrearTicket] = useState(false);
    const [loading, setLoading] = useState(false); //loading mientras se crea el ticket
    const [remover, setRemover] = useState(false); // activa loading cuando se comienza a crear el ticket
    const [infoStorage] = useStorage64("infoTicket"); // obtiene la informacion del storage para crear el ticket
    const [infoStorageEntregado] = useStorage64("infoTicketEntregado"); // obtiene la informacion del storage para crear el ticket entregado
    const [storageImgProducto,] = useStorage64('storageImgProducto'); // obtiene las imagenes del storage
    const [saveStatusProducto,] = useStorage64('saveStatusProducto', '');
    const [storageActionId,] = useStorage64('storageActionId', '');
    const [storageMotivoEntregado,] = useStorage64('storageMotivoEntregado', '');
    const [saveIdStatusProducto,] = useStorage64('saveIdStatusProducto', '');

    // revisa si tiene un id de producto
    useEffect(() => {
        let idProducto = 0;
        const urlParams = params.split('/');
        urlParams.shift();

        if (params.includes("/product=")) {
            for (let i = 0; i <= (urlParams.length - 1); i++) {
                if (urlParams[i].indexOf('product=') >= 0) {
                    idProducto = urlParams[i].replace('product=', '');
                }
            }
            setIdProductoEstate(idProducto);
        }
    }, [])

    //MOSTRAR LOADING HASTA QUE SE CARGUE LA INFORMACION
    useEffect(() => {
        if ((bancos && bancos.bancos) && (resultadoPedido && resultadoPedido.pedido)) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [resultadoPedido, bancos])

    // obtiene servicio pedido
    useEffect(() => {
        getPedido(idPedido)
            .then(setResultadoPedido)
    }, [])

    useEffect(() => {
        let imagenProducto = [];

        if (resultadoPedido) {
            // recorre por producto
            if (resultadoPedido.pedido) {
                if (resultadoPedido.pedido && Number(idProductoEstate) !== 0) {
                    if (resultadoPedido.pedido[0]) {
                        resultadoPedido.pedido[0].products.map((elem, i) =>
                            Number(elem.related_product_id) === Number(idProductoEstate) && imagenProducto.push({ "url": elem.images[0].url, "id": elem.related_product_id })
                        )
                        let limpioImagen = removeDuplicates(imagenProducto, idProductoEstate);
                        setima(limpioImagen);

                        setEstatusPedido(resultadoPedido.pedido[0].axxi.status_axxi_id);
                        setFormaPago(resultadoPedido.pedido[0].payment_method.id);
                    }
                }
            }
            else if (resultadoPedido.statusCode && resultadoPedido.statusCode === 401) {
                LoginAyudaDinamica({ username: USERNAME, password: PASSWORD, publicKey: PUBLICKEY })
                setTimeout(() => {
                    getPedido()
                        .then(setResultadoPedido)
                }, 1000);
            }
        }
    }, [resultadoPedido])

    // obtiene servicio bancos
    useEffect(() => {
        getBanks()
            .then(setBancos)
    }, [])

    useEffect(() => {
        if (bancos) {
            if (bancos.bancos) {
                setListBanks(bancos.bancos);
            }
            else if (bancos.statusCode && bancos.statusCode === 401) {
                LoginAyudaDinamica({ username: USERNAME, password: PASSWORD, publicKey: PUBLICKEY })
                setTimeout(() => {
                    getBanks()
                        .then(setBancos)
                }, 1000);
            }
        }
    }, [bancos])

    // guarda la informacion enviada en el formulario
    const sendFormInformacionBancaria = data => {
        setDatos({
            "voucher_url": "n/a",
            "interbank_code": data.clabe,
            "bank_id": Number(data.banco),
            "identification_id": 1,
            "identification": data.curp,
            "account_holder": data.titular
        })
        SetReturnForm(true);
    }

    // al recibir data del formulario se guarda en variable datos bancarios
    useEffect(() => {
        if (returnForm) {
            setDatosBancarios(datos);
            SetReturnForm(false);
        }
    }, [returnForm])

    // muesta el modal seguro de que desea cancelar el pedido
    useEffect(() => {
        if (datosBancarios) {
            SetShowModal(true);
        }
    }, [datosBancarios])

    // regresa a la pagina anterior al formulario
    const onClose = () => {
        history.goBack();
    }

    // cerrar modal de eliminar pedidos
    const handleClose = () => SetShowModal(false);

    // accion para si cancelar pedido
    const siRemove = () => {

        SetShowModal(false); // ocultar modal
        setRemover(true); // activa loading

        // preparando tu producto / forma pago igual a oxxo, seven, deposito o tienda departamental
        if (idProductoEstate != 0 &&
            (LABEL_PREPARANDO_PRODUCTO === String(saveStatusProducto).toLowerCase() || LABEL_AUTORIZADO === String(saveStatusProducto).toLowerCase() ||
                !PEDIDOS_PRODUCTO_ENTREGADO.includes(saveIdStatusProducto)) && !FORMA_PAGO_REEMBOLSABLE.includes(formaPago)) {
            let reason_id = infoStorage.reason_id;
            let order_id = infoStorage.order_id;
            let order_number = infoStorage.order_number;
            let client_id = infoStorage.client_id;
            let description = infoStorage.description;
            let products_to_cancel = infoStorage.products_to_cancel;
            let ticket_type_id = infoStorage.ticket_type_id;
            let refound_details = datosBancarios;
            let additional_details = infoStorage.additional_details;
            let action_id = infoStorage.action_id;

            postCreateTiket({
                reason_id,
                order_id,
                order_number,
                client_id,
                description,
                products_to_cancel,
                ticket_type_id,
                refound_details,
                additional_details,
                action_id
            })
                .then(setCrearTicket)
        }
        // producto entregado / forma pago igual a oxxo, seven, deposito o tienda departamental
        else if (idProductoEstate != 0 && PEDIDOS_PRODUCTO_ENTREGADO.includes(saveIdStatusProducto) && !FORMA_PAGO_REEMBOLSABLE.includes(formaPago)) {
            let reason_id = infoStorageEntregado.reason_id;
            let order_id = infoStorageEntregado.order_id;
            let order_number = infoStorageEntregado.order_number;
            let client_id = infoStorageEntregado.client_id;
            let description = storageMotivoEntregado;
            let products_to_cancel = infoStorageEntregado.products_to_cancel;
            let ticket_type_id = infoStorageEntregado.ticket_type_id;
            let refound_details = datosBancarios;
            let additional_details = storageImgProducto;
            let action_id = storageActionId;

            postCreateTiket({
                reason_id,
                order_id,
                order_number,
                client_id,
                description,
                products_to_cancel,
                ticket_type_id,
                refound_details,
                additional_details,
                action_id
            })
                .then(setCrearTicket)
        }
        else {
            if (idProductoEstate === 0) {
                let reason_id = infoStorage.reason_id;
                let order_id = infoStorage.order_id;
                let order_number = infoStorage.order_number;
                let client_id = infoStorage.client_id;
                let description = infoStorage.description;
                let products_to_cancel = infoStorage.products_to_cancel;
                let ticket_type_id = infoStorage.ticket_type_id;
                let refound_details = datosBancarios;
                let additional_details = infoStorage.additional_details;
                let action_id = infoStorage.action_id;

                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id
                })
                    .then(setCrearTicket)
            }
            else {
                let reason_id = infoStorage.reason_id;
                let order_id = infoStorage.order_id;
                let order_number = infoStorage.order_number;
                let client_id = infoStorage.client_id;
                let description = infoStorage.description;
                let products_to_cancel = infoStorage.products_to_cancel;
                let ticket_type_id = infoStorage.ticket_type_id;
                let refound_details = datosBancarios;
                let additional_details = infoStorage.additional_details;
                let action_id = infoStorage.action_id;

                postCreateTiket({
                    reason_id,
                    order_id,
                    order_number,
                    client_id,
                    description,
                    products_to_cancel,
                    ticket_type_id,
                    refound_details,
                    additional_details,
                    action_id
                })
                    .then(setCrearTicket)
            }
        }
    }

    //no cancelar -> cerrar modal y regresar a mis pedidos
    const noRemove = () => {
        SetShowModal(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }

    // si la respuesta es ticket creado === 201 redirecciona a cancelacion exitosa
    useEffect(() => {
        if (crearTicket) {
            if (crearTicket.metadata) {
                if (Number(crearTicket.metadata.http_status) === 201) {
                    removeStorage("infoTicket");
                    removeStorage("infoTicketEntregado");
                    if (idProductoEstate != 0) {
                        history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/product=" + idProductoEstate + "/cancelacion-exitosa");
                    }
                    else {
                        history.replace("/mi-cuenta/mis-pedidos/" + idPedido + "/cancelacion-exitosa");
                    }
                }
            }
            else {
                SetShowModalError(true);
            }
        }
    }, [crearTicket])

    // mostrar el loading mientras se crea el ticket
    useEffect(() => {
        if (remover) {
            if (crearTicket) {
                setLoading(false);
                setRemover(false);
            }
            else {
                setLoading(true);
            }
        }
    }, [remover, crearTicket])

    // activar on focus
    const onFocusInputs = (e) => {
        let idFocus = e.target.id;

        if (idFocus === "clabe") {
            setFlagClabe(true);
        }
        else if (idFocus === "banco") {
            setFlagBancos(true);
        }
        else if (idFocus === "titular") {
            setFlagTitular(true);
        }
        else if (idFocus === "curp") {
            setFlagCurp(true);
        }
    }

    // desactivar on focus
    const onBlurInputs = (e) => {
        let idFocus = e.target.id;

        if (idFocus === "clabe") {
            setFlagClabe(false);
        }
        else if (idFocus === "banco") {
            setFlagBancos(false);
        }
        else if (idFocus === "titular") {
            setFlagTitular(false);
        }
        else if (idFocus === "curp") {
            setFlagCurp(false);
        }
    }

    // regresar a pedido en modal error
    const regresarPedidos = () => {
        SetShowModalError(false);
        history.replace("/mi-cuenta/mis-pedidos/dias=30/pagina=1");
    }
    // cerrar modal de error
    const handleCloseError = () => SetShowModalError(false);

    //------ Validaciones ------
    const valClabe = {
        required: 'Campo obligatorio',
        minLength: {
            value: 18,
            message: 'Faltan dígitos. Deben ser 18 dígitos'
        },
        maxLength: {
            value: 18,
            message: 'No debe contener más de 18 dígitos'
        }
    }
    const valBanco = {
        required: 'Campo obligatorio',
        minLength: {
            value: 1,
            message: 'El banco es requerido'
        }
    }
    const valTitular = {
        required: 'Campo obligatorio',
        minLength: {
            value: 6,
            message: 'El nombre del titular al menos debe de contener 6 carácteres'
        }
    }
    const valCurp = {
        required: 'Campo obligatorio',
        minLength: {
            value: 12,
            message: 'Faltan carácteres, Debe ser de al menos 12 carácteres'
        },
        maxLength: {
            value: 18,
            message: 'Debe contener máximo 18 carácteres'
        }
    }

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            { loading && <Loading />}


            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="miCuentaContainer">
                    <div className="containerAD">
                        <div className="boxBtnRegresarDktop">
                            <div className="icoBack" onClick={onClose}></div>
                            <button className="btnBackAD" onClick={onClose}>Atrás</button>
                        </div>


                        <div className="boxGeneralAD">

                            <div className="boxLeftAD">
                                <div className="contSectionRed">
                                    <div className={ima.length > 1 ? "imaDesktop more" : "imaDesktop"}>
                                        {ima.length > 0 && ima.map((elemento, i) => i <= 3 && <img key={i} src={elemento.url} alt={"imagen" + i} width={70} />)}
                                    </div>

                                    <div className="textDektop"> <p>Ingresa tus datos para el reembolso</p> </div>

                                </div>
                            </div>

                            <div className="boxRightAD">
                                <div className="copyFormAD">
                                    <p>Para poder generar tu reembolso ingresa la siguiente información:</p>
                                </div>

                                <form onSubmit={handleSubmit(sendFormInformacionBancaria)} className="formInformacionBancaria">
                                    <InputTypeDesktop
                                        type="text"
                                        name="clabe"
                                        id="clabe"
                                        placeholder="CLABE interbancaria"
                                        refs={register(valClabe)}
                                        autocomplete='clabe'
                                        errorMessage={errors.clabe && errors.clabe.message}
                                        onFocus={(e) => onFocusInputs(e)}
                                        onBlur={(e) => onBlurInputs(e)}
                                        flag={flagClabe}
                                        texto={"Tu clabe esta compuesta por 18 dígitos."}
                                        max="999999999999999999"
                                        maxLength={18}
                                        onKeyDown={function (e) {
                                            if ((e.keyCode < '48' || e.keyCode > '57') && e.keyCode != "8") {
                                                e.preventDefault()
                                            }
                                        }}
                                    />

                                    <SelectTypeADDesktop
                                        type="text"
                                        name="banco"
                                        id="banco"
                                        placeholder="Selecciona"
                                        refs={register(valBanco)}
                                        options={lisBanks}
                                        errorMessage={errors.banco && errors.banco.message}
                                        label="Banco"
                                    />

                                    <InputTypeDesktop
                                        type="text"
                                        name="titular"
                                        id="titular"
                                        placeholder="Titular de la cuenta"
                                        refs={register(valTitular)}
                                        autocomplete='titular'
                                        errorMessage={errors.titular && errors.titular.message}
                                    />

                                    <InputTypeDesktop
                                        type="text"
                                        name="curp"
                                        id="curp"
                                        placeholder="RFC ó CURP"
                                        refs={register(valCurp)}
                                        autocomplete='curp'
                                        errorMessage={errors.curp && errors.curp.message}
                                        onFocus={(e) => onFocusInputs(e)}
                                        onBlur={(e) => onBlurInputs(e)}
                                        flag={flagCurp}
                                        texto={"Tu curp esta compuesto por 18 carácteres / Tu RFC esta compuesto por 12 ó 13 carácteres."}
                                        maxLength={18}
                                    />

                                    <div className="boxBtnFinalizar">
                                        <button className="btnFinalizarForm">Finalizar</button>
                                    </div>
                                </form>

                            </div>

                            {/* mostrar modal estas seguro que deseas cancelar*/}
                            {(showModal && idProductoEstate === 0) &&
                                <ModalPortal type="modalSB" onClose={handleClose} >
                                    <ModalEstasSeguro remvoeAction={siRemove} awaitAction={noRemove} />
                                </ModalPortal>
                            }

                            {/* mostrar modal estas seguro que deseas cancelar por producto*/}
                            {(showModal && idProductoEstate !== 0) &&
                                <ModalPortal type="modalSB" onClose={handleClose} >
                                    <ModalEstasSeguroProducto remvoeAction={siRemove} awaitAction={noRemove} />
                                </ModalPortal>
                            }

                            {showModalError &&
                                <ModalPortal type="modalSB" onClose={handleCloseError} >
                                    <ModalError okRegresar={regresarPedidos} />
                                </ModalPortal>
                            }
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}