import React, { useEffect, useState, useRef, Fragment } from 'react';
import './style.sass';
import { Helmet } from "react-helmet";
import Header from 'pages/Header/HeaderNavegacion';
import Footer from 'pages/Footer/FooterDesktop';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage, encodeStorage, removeStorage } from 'functions/storageBase64';
import getLocalizadorTiendaS from 'services/getLocalizadorTiendas';
import Loading from 'components/Loading';
// import ErrorType from 'pages/Error/ErrorType';
import { repareTextSimple } from 'functions/repareDataService';
import { useIsMobile } from 'hooks/useIsMobile';
import { API_KEY } from 'constants/general'

// import { ShareComponentDetail } from 'components/Share';
const { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } = require("react-google-maps");


export function LocalizadorTiendasDesktop({ tiendas, response, loading }) {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    // variables para el mapa
    const urlGoogle = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${API_KEY}`;
    const storageLatitud = decodeStorage('latitud');
    const storageLongitud = decodeStorage('longitud');
    const [latitud, setLatitud] = useState(false);
    const [longitud, setLongitud] = useState(false);
    const [latitudMexico,] = useState(19.4418228);   // LATITUD INICIAL DE SANBORNS POLANCO (TIENDA PRINCIPAL)
    const [longitudMexico,] = useState(-99.2077745); // LONGITUD INICIAL DE SANBORNS POLANCO (TIENDA PRINCIPAL)
    const [isOpen, setIsOpen] = useState(false);
    const [ventanaAbierta, setVentanaAbierta] = useState(false);
    const [storeActive, setStoreActive] = useState(false);
    const [primeraPosicion, setPrimeraPosicion] = useState([]);

    // variables para el buscador
    const [valorSearch, setValorSearch] = useState('');
    const search = useRef(null);
    const [, setBandera] = useState(false);
    let respuesta = [];
    const focusTextInput = () => search.current.focus();
    const [valorSearchClick, setValorSearchClick] = useState("");
    const [verTodas, setVerTodas] = useState(false);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'LocalizadorTiendas');
    }, [])

    // posiciona en x/y 0,0
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    // revisa si se tiene valor en el campo de busqueda
    useEffect(() => {
        if (valorSearch.length > 0) {
            setBandera(true);
        } else {
            setBandera(false);
        }
    }, [valorSearch])

    // borrar el contenido buscado
    const borrar = (e) => {
        if (Number(e.keyCode) === 8) {
            if (valorSearch.length === 1) {
                setValorSearch("");
                if (tiendas.length > 0) {
                    if (tiendas[0].status === true && tiendas[0].id != 1244) {
                        setPrimeraPosicion(tiendas[0]);
                        setStoreActive(1);
                        setLatitud(tiendas[0].latitude);
                        setLongitud(tiendas[0].longitude);
                    }
                    else {
                        for (let i = 0; i <= tiendas.length; i++) {
                            if (tiendas[i].status === true && tiendas[i].id != 1244) {
                                setPrimeraPosicion(tiendas[i]);
                                setStoreActive(i + 1);
                                setLatitud(tiendas[i].latitude);
                                setLongitud(tiendas[i].longitude);
                                return
                            }
                        }
                    }
                }
            }
            if (valorSearch.length === 0) {
                setValorSearch("");

                if (tiendas.length > 0) {
                    if (tiendas[0].status === true && tiendas[0].id != 1244) {
                        setPrimeraPosicion(tiendas[0]);
                        setStoreActive(1);
                        setLatitud(tiendas[0].latitude);
                        setLongitud(tiendas[0].longitude);
                    }
                    else {
                        for (let i = 0; i <= tiendas.length; i++) {
                            if (tiendas[i].status === true && tiendas[i].id != 1244) {
                                setPrimeraPosicion(tiendas[i]);
                                setStoreActive(i + 1);
                                setLatitud(tiendas[i].latitude);
                                setLongitud(tiendas[i].longitude);
                                return
                            }
                        }
                    }
                }
            }
            if (valorSearchClick.length === 1) {
                setValorSearchClick("");

                if (tiendas.length > 0) {
                    if (tiendas[0].status === true && tiendas[0].id != 1244) {
                        setPrimeraPosicion(tiendas[0]);
                        setStoreActive(1);
                        setLatitud(tiendas[0].latitude);
                        setLongitud(tiendas[0].longitude);
                    }
                    else {
                        for (let i = 0; i <= tiendas.length; i++) {
                            if (tiendas[i].status === true && tiendas[i].id != 1244) {
                                setPrimeraPosicion(tiendas[i]);
                                setStoreActive(i + 1);
                                setLatitud(tiendas[i].latitude);
                                setLongitud(tiendas[i].longitude);
                                return
                            }
                        }
                    }
                }
            }
            if (valorSearchClick.length === 0) {
                setValorSearchClick("");
                if (tiendas.length > 0) {
                    if (tiendas[0].status === true && tiendas[0].id != 1244) {
                        setPrimeraPosicion(tiendas[0]);
                        setStoreActive(1);
                        setLatitud(tiendas[0].latitude);
                        setLongitud(tiendas[0].longitude);
                    }
                    else {
                        for (let i = 0; i <= tiendas.length; i++) {
                            if (tiendas[i].status === true && tiendas[i].id != 1244) {
                                setPrimeraPosicion(tiendas[i]);
                                setStoreActive(i + 1);
                                setLatitud(tiendas[i].latitude);
                                setLongitud(tiendas[i].longitude);
                                return
                            }
                        }
                    }
                }
            }
        }
    }

    // resetea valorSearchClick y el valor del input se resetea
    useEffect(() => {
        if (valorSearch.length === 0) {
            setValorSearchClick("");
            if (tiendas.length > 0) {
                if (tiendas[0].status === true && tiendas[0].id != 1244) {
                    setPrimeraPosicion(tiendas[0]);
                    setStoreActive(1);
                    setLatitud(tiendas[0].latitude);
                    setLongitud(tiendas[0].longitude);
                }
                else {
                    for (let i = 0; i <= tiendas.length; i++) {
                        if (tiendas[i].status === true && tiendas[i].id != 1244) {
                            setPrimeraPosicion(tiendas[i]);
                            setStoreActive(i + 1);
                            setLatitud(tiendas[i].latitude);
                            setLongitud(tiendas[i].longitude);
                            return
                        }
                    }
                }
            }
        }
    }, [valorSearch])

    useEffect(() => {
        if (valorSearchClick.length === 0) {
            setValorSearchClick("");
            if (tiendas.length > 0) {
                if (tiendas[0].status === true && tiendas[0].id != 1244) {
                    setPrimeraPosicion(tiendas[0]);
                    setStoreActive(1);
                    setLatitud(tiendas[0].latitude);
                    setLongitud(tiendas[0].longitude);
                }
                else {
                    for (let i = 0; i <= tiendas.length; i++) {
                        if (tiendas[i].status === true && tiendas[i].id != 1244) {
                            setPrimeraPosicion(tiendas[i]);
                            setStoreActive(i + 1);
                            setLatitud(tiendas[i].latitude);
                            setLongitud(tiendas[i].longitude);
                            return
                        }
                    }
                }
            }
        }
    }, [valorSearchClick])

    useEffect(() => {
        if (tiendas.length > 0) {
            if (tiendas[0].status === true && tiendas[0].id != 1244) {
                setStoreActive(0);
            }
            else {
                for (let i = 0; i <= tiendas.length; i++) {
                    if (tiendas[i].status === true && tiendas[i].id != 1244) {
                        setStoreActive(i + 1);
                        return
                    }
                }
            }
        }
    }, [tiendas])

    // si el el valor de busqueda tiene valor, busca por ese campo al hacer click
    const clickBuscarTienda = () => {
        if (valorSearch.length > 0)
            setValorSearchClick(valorSearch);
    }

    // funcion llamada en el boton buscar del input
    const buscar = (e) => {
        if (String(e.target.value) === "") {
            setValorSearch('');
        }
        if (String(e.target.value) !== "") {
            setValorSearch(e.target.value);
        }
    }

    // abre ventana de direccion del marcador seleccionado
    const handleToggle = (e, id) => {
        setVentanaAbierta(id);
        setIsOpen(!isOpen);
    }

    // detecta la longitud y latitud inicial (primera tienda de la lista)
    useEffect(() => {
        if (tiendas.length > 0) {
            setLatitud(tiendas[0].latitude);
            setLongitud(tiendas[0].longitude);
            if (tiendas.length > 0) {
                if (tiendas[0].status === true && tiendas[0].id != 1244) {
                    setLatitud(tiendas[0].latitude);
                    setLongitud(tiendas[0].longitude);
                }
                else {
                    for (let i = 0; i <= tiendas.length; i++) {
                        if (tiendas[i].status === true && tiendas[i].id != 1244) {
                            setLatitud(tiendas[i].latitude);
                            setLongitud(tiendas[i].longitude);
                            return
                        }
                    }
                }
            }
        }
    }, [tiendas])

    // mapa general con todos los marcadores
    const GoogleMapLocalizador = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={14}
            defaultCenter={{ lat: Number(latitud), lng: Number(longitud) }}
            center={{ lat: Number(latitud), lng: Number(longitud) }}
        >
            {props.isMarkerShown &&
                tiendas && tiendas.map((marker, id) => (
                    marker.status === true && marker.id != 1244 && String(marker.name) !== "Prueba allow empty" && <Fragment key={id}>
                        <Marker
                            position={{ lat: Number(marker.latitude), lng: Number(marker.longitude) }}
                            key={marker.id}
                            labelStyle={{ textAlign: "center", width: '220px', fontSize: "14px", paddingTop: "8px", marginTop: "30px" }}
                            labelAnchor={{ x: (220 / 2) + 8, y: 80 }}
                            label={{ text: `${marker.name}`, color: "#ff0000", top: "20px", position: "relative", fontWeight: "400" }}
                            onClick={(e) => handleToggle(e, marker.id)}
                            icon={{
                                url: '/img/pinSanbornsPeque.png',
                                labelOrigin: new window.google.maps.Point(85, 45),
                                // size: new window.google.maps.Size(32,32),
                                anchor: new window.google.maps.Point(16, 32)
                            }}
                            labelClass='map-price-marker'
                            markerWithLabel={window.MarkerWithLabel}
                            labelContent="<div class='marker'></div>"
                        >

                            {isOpen && ventanaAbierta === marker.id &&

                                <InfoWindow onCloseClick={(e) => handleToggle(e, marker.id)}  >
                                    <div className="ventanaInfo">
                                        <div className="nameTienda">
                                            <p>{marker.name}</p>
                                        </div>
                                        <div className="direccionTienda">
                                            <span></span>
                                            <p>{marker.street} {marker.number} {marker.suburb}, C.P. {marker.zip_code}, {marker.state}</p>
                                        </div>
                                        <div className="horarioTienda">
                                            <span></span>
                                            <p>Horario de {marker.schedule}</p>
                                        </div>
                                        <div className="telefonoTienda">
                                            <span></span>
                                            <p>Tel. {marker.phone.lada ? <>({marker.phone.lada})</> : ""} {marker.phone.number}</p>
                                        </div>

                                        <div className="botones">
                                            <div className="btnT1">
                                                {/* <a className="llegarBtn" href={`https://www.google.com/maps/dir/${Number(latitudMexico)},${Number(longitudMexico)}/${marker.street}`} target="_blank" rel="noopener noreferrer">Cómo llegar</a> */}
                                                <a className="llegarBtn" href={"https://maps.google.com?q=" + Number(marker.latitude) + "," + Number(marker.longitude)} target="_blank" rel="noopener noreferrer">Cómo llegar</a>
                                                <a className="llamarBtn" href={"tel:" + marker.phone.lada + marker.phone.number}>Llamar</a>
                                            </div>
                                            <div className="btnT2">
                                                {/* <ShareComponentDetail title={marker.nameTienda} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </InfoWindow>
                            }
                        </Marker>
                    </Fragment>
                ))
            }
        </GoogleMap>
    ))

    // mapa con marcador filtrado por tienda
    const GoogleMapLocalizadorConFiltro = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={12}
            defaultCenter={{ lat: Number(primeraPosicion.latitude), lng: Number(primeraPosicion.longitude) }}
            center={{ lat: Number(primeraPosicion.latitude), lng: Number(primeraPosicion.longitude) }}
        >
            <Marker
                position={{ lat: Number(primeraPosicion.latitude), lng: Number(primeraPosicion.longitude) }}
                key={primeraPosicion.id}
                labelStyle={{ textAlign: "center", width: '220px', fontSize: "14px", paddingTop: "8px", fontWeight: "400" }}
                labelAnchor={{ x: (220 / 2) + 8, y: 80 }}
                onClick={(e) => handleToggle(e, primeraPosicion.id)}
                label={{ text: `${primeraPosicion.name}`, color: "#ff0000" }}
                icon={{
                    url: '/img/pinSanbornsPeque.png',
                    labelOrigin: new window.google.maps.Point(85, 45),
                    anchor: new window.google.maps.Point(16, 32)
                }}
            >
                {isOpen && ventanaAbierta === primeraPosicion.id &&

                    <InfoWindow onCloseClick={(e) => handleToggle(e, primeraPosicion.id)}  >
                        <div className="ventanaInfo">
                            <div className="nameTienda">
                                <p>{primeraPosicion.name}</p>
                            </div>
                            <div className="direccionTienda">
                                <span></span>
                                <p>{primeraPosicion.street} {primeraPosicion.number} {primeraPosicion.suburb}, C.P. {primeraPosicion.zip_code}, {primeraPosicion.state}</p>
                            </div>
                            <div className="horarioTienda">
                                <span></span>
                                <p>Horario de {primeraPosicion.schedule}</p>
                            </div>
                            <div className="telefonoTienda">
                                <span></span>
                                <p>Tel. {primeraPosicion.phone.lada ? <>({primeraPosicion.phone.lada})</> : ""} {primeraPosicion.phone.number}</p>
                            </div>

                            <div className="botones">
                                <div className="btnT1">
                                    {/* <a className="llegarBtn" href={`https://www.google.com/maps/dir/${Number(latitudMexico)},${Number(longitudMexico)}/${primeraPosicion.street}`} target="_blank" rel="noopener noreferrer">Cómo llegar</a> */}
                                    <a className="llegarBtn" href={"https://maps.google.com?q=" + Number(primeraPosicion.latitude) + "," + Number(primeraPosicion.longitude)} target="_blank" rel="noopener noreferrer">Cómo llegar</a>
                                    <a className="llamarBtn" href={"tel:" + primeraPosicion.phone.lada + primeraPosicion.phone.number}>Llamar</a>
                                </div>
                                <div className="btnT2">
                                </div>
                            </div>
                        </div>
                    </InfoWindow>
                }
            </Marker>

        </GoogleMap>
    ))

    // obtiene la primera posicion del resultado de busqueda
    useEffect(() => {
        if (respuesta.length > 0) {
            console.log("respuesta: ", respuesta[0].id)
            if (respuesta[0].status === true) { //&& respuesta[0].id != 1244
                if (respuesta[0].id != 1244) {
                    setPrimeraPosicion(respuesta[0]);
                    setStoreActive(respuesta[0].id);
                }
            }
            else {
                for (let i = 0; i <= respuesta.length; i++) {
                    if (respuesta[i].status === true && respuesta[i].id != 1244) { //&& respuesta[i].id != 1244
                        console.log("respuesta: ", respuesta[i].id)
                        setPrimeraPosicion(respuesta[i]);
                        setStoreActive(respuesta[i].id);
                        return
                    }
                }
            }
            setVerTodas(false);
        }
    }, [respuesta])

    const [, setFiltroG] = useState([]);
    // guarda informacion de tienda activa en radio button
    const seleccionarTienda = (e) => {
        let numero = Number(e.target.value);
        let filtro = [];

        tiendas.forEach(function (task) {
            if (Number(task.id) === numero) {
                filtro.push(task);
            }
        });

        setFiltroG(filtro[0]);
        setStoreActive(numero);
        setLatitud(filtro[0].latitude);
        setLongitud(filtro[0].longitude);
        setVerTodas(false);
    }

    // funcion que activa ver tiendas cercanas
    const showAllStores = () => {
        setVerTodas(true);
        setValorSearchClick("");
        setValorSearch("");
    }

    const actualizarLocalizacion = () => {
        setVerTodas(true);
    }

    // enviar coordenadas a mapa para mostrar tiendas cercanas
    useEffect(() => {
        // presiono boton ver tiendas cercanas
        if (verTodas === true) {
            // ya tiene una ubicacion permitida
            if (storageLatitud && storageLongitud) {
                setLatitud(Number(storageLatitud));
                setLongitud(Number(storageLongitud));
            }
            // no tiene ubicacion permitida
            else {
                if ("geolocation" in navigator) {
                    console.log("Geolocalization Available");
                    // solicita coordenadas 
                    navigator.geolocation.getCurrentPosition(position => {
                        encodeStorage('latitud', position.coords.latitude);
                        encodeStorage('longitud', position.coords.longitude);
                        setLatitud(position.coords.latitude);
                        setLongitud(position.coords.longitude);
                    }, e => {
                        console.log("usuario rechaza permisos. ", e);
                        if (e.code === e.PERMISSION_DENIED) {
                            // pop up dialog asking for location
                            removeStorage("latitud");
                            removeStorage("longitud");
                        }
                        setLatitud(19.4381193);
                        setLongitud(-99.2069279);
                    });
                }
                // explorador no tiene disponible geolocalizacion
                else {
                    console.log("Geolocalization not Available.");
                    setLatitud(19.4381193);
                    setLongitud(-99.2069279);
                }
            }
        }
    }, [verTodas])

    // console.log("TIENDA DEFECTO--> ", primeraPosicion.name, "----------- primera posicion-> ", primeraPosicion, "pp latitud->", primeraPosicion.latitud, "leng:", primeraPosicion.length, "    store active: ", storeActive, " latitud: ", latitud)

    return (
        <>
            <Helmet>
                <title>Localizador de Tiendas</title>
                <link rel="canonical" href={`https://www.sanborns.com.mx/localizador-tiendas/`} />
                <meta name="Description" content="Localizador de Tiendas" />
            </Helmet>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                {loading && <Loading />}

                {response &&
                    response.statusCode === 200
                    ?
                    tiendas &&
                    <section className="sectionAuto">

                        <div className="sectionContentLocalizador">
                            <section className="sectionTitleAutoLocalizador">
                                <div className="divTitle">
                                    <p>Localizador de Tiendas</p>
                                </div>
                            </section>

                            <div className="contentLocalizador">
                                <div className="localizadorL">

                                    <div className="buscadorLocalizadorDesk">
                                        <div className="divLabel">
                                            <label>
                                                <input aria-label="Buscador Tiendas" autoComplete="off" type="search" placeholder="Buscar por nombre o C.P."
                                                    id="buscadorStore" className="buscadorStore"
                                                    value={valorSearch}
                                                    ref={search}
                                                    onChange={(text1) => buscar(text1)}
                                                    onKeyDown={(e) => borrar(e)}>
                                                </input>
                                            </label>
                                        </div>
                                        <div className="bBuscar">
                                            <button onClick={clickBuscarTienda}> Buscar</button>
                                        </div>
                                    </div>

                                    <div className="contenedorCards">
                                        {tiendas && tiendas.filter((dataInterna, id) => {
                                            if ((valorSearchClick == null || valorSearchClick === '')) {
                                                return dataInterna
                                            }
                                            else if (
                                                (repareTextSimple(String(dataInterna.name).toLowerCase()).includes(repareTextSimple(String(valorSearchClick).toLowerCase())) ||
                                                    repareTextSimple(String(dataInterna.state).toLowerCase()).includes(repareTextSimple(String(valorSearchClick).toLowerCase())) ||
                                                    repareTextSimple(String(dataInterna.suburb).toLowerCase()).includes(repareTextSimple(String(valorSearchClick).toLowerCase())) ||
                                                    repareTextSimple(String(dataInterna.zip_code).toLowerCase()).includes(repareTextSimple(String(valorSearchClick).toLowerCase())))
                                            ) {
                                                respuesta.push(dataInterna)
                                                return dataInterna
                                            }
                                        }).map(({ id, street, latitude, longitude, name, phone, number, suburb, zip_code, state, schedule, status }, i) =>
                                            (status === true && id != 1244) &&
                                            <div className="direccionTienda" key={i}>
                                                <div className="bxCardTienda">

                                                    <h3 className="tituloTienda" onChange={(e) => seleccionarTienda(e)}>
                                                        <input className="radio" type="radio" value={id} id={id} name="store"
                                                            checked={Number(id) === Number(storeActive) ? true : false}>
                                                        </input>
                                                        <label name={id} htmlFor={id}>{String(name).toUpperCase()} </label>
                                                    </h3>

                                                    <p className="d1bx"><span className="direcBx"></span> {street} {number} {suburb}, C.P. {zip_code} {state}</p>
                                                    <p className="d3bx"><span className="horarioBx"></span> Horario de {schedule}</p>
                                                    <p className="d2bx"><span className="telBx"></span> Tel. {phone.lada ? <>({phone.lada})</> : ""}  {phone.number}</p>

                                                    <div className="botones">
                                                        <div className="btnT1">
                                                            <a href={`https://maps.google.com?q=${latitude},${longitude}`} target="_blank" rel="noopener noreferrer" className="llegarBtn">Cómo llegar</a>
                                                            {/* <a className="llegarBtn" href={`https://www.google.com/maps/dir/${Number(latitud)},${Number(longitud)}/${tienda.street}`} target="_blank" rel="noopener noreferrer">Cómo llegar</a> */}
                                                            <a className="llamarBtn" href={"tel:" + phone.lada + phone.number}>Llamar</a>
                                                        </div>
                                                        <div className="btnT2">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        )}

                                        {((valorSearchClick.length > 0 && respuesta.length === 0) || (respuesta.length > 0 && respuesta[0].id === 1244 && valorSearchClick.length > 0)) && (search.current && search.current.defaultValue)
                                            && <div className="bxSinResultados">
                                                <p>No se encontraron tiendas con el nombre o c.p. indicado.</p>
                                            </div>
                                        }
                                        {console.log(" respuesta--- ", respuesta, " valorSearch --- ", valorSearchClick)}
                                    </div>

                                </div>

                                <div className="localizadorR">

                                    <div className="mapBx">
                                        <div className="mapT">
                                            <div className="cajaButtons">
                                                <div className="btnStore">
                                                    <button onClick={showAllStores} className={verTodas === true ? "active" : ""}>Tiendas cercanas</button>
                                                </div>
                                                <div className="btnAct">
                                                    <button onClick={actualizarLocalizacion} className={""}>
                                                        <p>Actualizar ubicación</p>
                                                        <span></span>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="copyStore">
                                                <p>Para mostrar tus tiendas cercanas pediremos acceso a tu localización</p>
                                            </div>
                                        </div>

                                        <div className="mapB">
                                            {respuesta.length > 0
                                                ? <GoogleMapLocalizadorConFiltro
                                                    isMarkerShown={true}
                                                    googleMapURL={urlGoogle}
                                                    containerElement={<div style={{ height: '550px' }} />}
                                                    mapElement={<div style={{ height: "100%" }} />}
                                                    loadingElement={<p>Cargando...</p>}
                                                    center={{ lat: Number(primeraPosicion.latitude), lng: Number(primeraPosicion.longitude) }}
                                                />
                                                : <GoogleMapLocalizador
                                                    isMarkerShown={true}
                                                    googleMapURL={urlGoogle}
                                                    containerElement={<div style={{ height: '550px', width: '100%', maxWidth: '1200px', minHeight: '320px' }} />}
                                                    mapElement={<div style={{ height: "100%" }} />}
                                                    loadingElement={<div style={{ textAlign: "center", fontWeight: "600" }}> <p>Cargando...</p></div>}
                                                    center={{ lat: latitudMexico, lng: longitudMexico }}
                                                />
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    : null
                }
            </main>

            <Footer />
        </>
    )
}

export function LocalizadorTiendasMobile({ tiendas, response, loading }) {
    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Localizador de tiendas');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const [mostrarUno, setMostrarUno] = useState(true);
    const [mostrarDos, setMostrarDos] = useState(false);

    // flag para mostrar pestana de mapa
    const handleMostrarUno = () => {
        setMostrarDos(false);
        setMostrarUno(true);
    }

    // flag para mostrar pestana de listado
    const handleMostrarDos = () => {
        setMostrarUno(false);
        setMostrarDos(true);
    }

    //recibe la direccion del CP    
    const codigoP = decodeStorage('codigoPostal');
    const [, setCodigoPostal] = useState(codigoP);
    const [updateCPBar, setUpdateCPBar] = useState();

    const changeDirectionBar = (val) => {
        setCodigoPostal(val);
        setUpdateCPBar(val);
    };

    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar]);

    const [valorSearch, setValorSearch] = useState('');
    const search = useRef(null);
    const [, setBandera] = useState(false);
    let respuesta = [];
    const focusTextInput = () => search.current.focus();
    const [storeActive, setStoreActive] = useState(false);

    const [valorSearchClick, setValorSearchClick] = useState("");

    // revisa si se tiene valor en el campo de busqueda
    useEffect(() => {
        if (valorSearch.length > 0) {
            setBandera(true);
        } else {
            setBandera(false);
        }
    }, [valorSearch])

    // borrar el contenido buscado
    // const borrar = (e) => {
    //     if (Number(e.keyCode) === 8) {
    //         if (valorSearch.length === 1)
    //             setValorSearch("");
    //         if (valorSearch.length === 0)
    //             setValorSearch("");
    //     }
    // }
    const borrar = (e) => {
        if (Number(e.keyCode) === 8) {
            if (valorSearch.length === 1) {
                setValorSearch("");
                if (tiendas.length > 0) {
                    if (tiendas[0].status === true && tiendas[0].id != 1244) {
                        setPrimeraPosicion(tiendas[0]);
                        setStoreActive(1);
                        setLatitud(tiendas[0].latitude);
                        setLongitud(tiendas[0].longitude);
                    }
                    else {
                        for (let i = 0; i <= tiendas.length; i++) {
                            if (tiendas[i].status === true && tiendas[i].id != 1244) {
                                setPrimeraPosicion(tiendas[i]);
                                setStoreActive(i + 1);
                                setLatitud(tiendas[i].latitude);
                                setLongitud(tiendas[i].longitude);
                                return
                            }
                        }
                    }
                }
            }
            if (valorSearch.length === 0) {
                setValorSearch("");

                if (tiendas.length > 0) {
                    if (tiendas[0].status === true && tiendas[0].id != 1244) {
                        setPrimeraPosicion(tiendas[0]);
                        setStoreActive(1);
                        setLatitud(tiendas[0].latitude);
                        setLongitud(tiendas[0].longitude);
                    }
                    else {
                        for (let i = 0; i <= tiendas.length; i++) {
                            if (tiendas[i].status === true && tiendas[i].id != 1244) {
                                setPrimeraPosicion(tiendas[i]);
                                setStoreActive(i + 1);
                                setLatitud(tiendas[i].latitude);
                                setLongitud(tiendas[i].longitude);
                                return
                            }
                        }
                    }
                }
            }
            if (valorSearchClick.length === 1) {
                setValorSearchClick("");

                if (tiendas.length > 0) {
                    if (tiendas[0].status === true && tiendas[0].id != 1244) {
                        setPrimeraPosicion(tiendas[0]);
                        setStoreActive(1);
                        setLatitud(tiendas[0].latitude);
                        setLongitud(tiendas[0].longitude);
                    }
                    else {
                        for (let i = 0; i <= tiendas.length; i++) {
                            if (tiendas[i].status === true && tiendas[i].id != 1244) {
                                setPrimeraPosicion(tiendas[i]);
                                setStoreActive(i + 1);
                                setLatitud(tiendas[i].latitude);
                                setLongitud(tiendas[i].longitude);
                                return
                            }
                        }
                    }
                }
            }
            if (valorSearchClick.length === 0) {
                setValorSearchClick("");
                if (tiendas.length > 0) {
                    if (tiendas[0].status === true && tiendas[0].id != 1244) {
                        setPrimeraPosicion(tiendas[0]);
                        setStoreActive(1);
                        setLatitud(tiendas[0].latitude);
                        setLongitud(tiendas[0].longitude);
                    }
                    else {
                        for (let i = 0; i <= tiendas.length; i++) {
                            if (tiendas[i].status === true && tiendas[i].id != 1244) {
                                setPrimeraPosicion(tiendas[i]);
                                setStoreActive(i + 1);
                                setLatitud(tiendas[i].latitude);
                                setLongitud(tiendas[i].longitude);
                                return
                            }
                        }
                    }
                }
            }
        }
    }
    // resetea valorSearchClick y el valor del input se resetea
    useEffect(() => {
        if (valorSearch.length === 0) {
            setValorSearchClick("");
            if (tiendas.length > 0) {
                if (tiendas[0].status === true && tiendas[0].id != 1244) {
                    setPrimeraPosicion(tiendas[0]);
                    setStoreActive(1);
                    setLatitud(tiendas[0].latitude);
                    setLongitud(tiendas[0].longitude);
                }
                else {
                    for (let i = 0; i <= tiendas.length; i++) {
                        if (tiendas[i].status === true && tiendas[i].id != 1244) {
                            setPrimeraPosicion(tiendas[i]);
                            setStoreActive(i + 1);
                            setLatitud(tiendas[i].latitude);
                            setLongitud(tiendas[i].longitude);
                            return
                        }
                    }
                }
            }
        }
    }, [valorSearch])

    useEffect(() => {
        if (valorSearchClick.length === 0) {
            setValorSearchClick("");
            if (tiendas.length > 0) {
                if (tiendas[0].status === true && tiendas[0].id != 1244) {
                    setPrimeraPosicion(tiendas[0]);
                    setStoreActive(1);
                    setLatitud(tiendas[0].latitude);
                    setLongitud(tiendas[0].longitude);
                }
                else {
                    for (let i = 0; i <= tiendas.length; i++) {
                        if (tiendas[i].status === true && tiendas[i].id != 1244) {
                            setPrimeraPosicion(tiendas[i]);
                            setStoreActive(i + 1);
                            setLatitud(tiendas[i].latitude);
                            setLongitud(tiendas[i].longitude);
                            return
                        }
                    }
                }
            }
        }
    }, [valorSearchClick])
    useEffect(() => {
        if (tiendas.length > 0) {
            if (tiendas[0].status === true) {
                setStoreActive(0);
            }
            else {
                for (let i = 0; i <= tiendas.length; i++) {
                    if (tiendas[i].status === true) {
                        setStoreActive(i + 1);
                        return
                    }
                }
            }
        }
    }, [tiendas])

    // si el el valor de busqueda tiene valor, busca por ese campo al hacer click
    const clickBuscarTienda = () => {
        if (valorSearch.length > 0)
            setValorSearchClick(valorSearch);
    }
    // funcion llamada en el boton buscar del input
    const buscar = (e) => {
        if (String(e.target.value) === "") {
            setValorSearch('');
        }
        if (String(e.target.value) !== "") {
            setValorSearch(e.target.value);
        }
    }

    const { isMobile } = useIsMobile();
    const urlGoogle = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${API_KEY}`;
    const latitudMexico = 19.4418228;     // LATITUD INICIAL DE SANBORNS POLANCO (TIENDA PRINCIPAL)
    const longitudMexico = -99.2077745;   // LONGITUD INICIAL DE SANBORNS POLANCO (TIENDA PRINCIPAL)
    const [latitud, setLatitud] = useState(latitudMexico);
    const [longitud, setLongitud] = useState(longitudMexico);
    // LATITUD Y LONGITUD ALMACENADA CUANDO EL USUARIO YA DIO PERMISOS
    const storageLatitud = decodeStorage('latitud');
    const storageLongitud = decodeStorage('longitud');
    const [isOpen, setIsOpen] = useState(false);
    const [ventanaAbierta, setVentanaAbierta] = useState(false);
    const [primeraPosicion, setPrimeraPosicion] = useState([]);
    const [verTodas, setVerTodas] = useState(false);
    // let respuestaUno = [];

    // detecta la longitud y latitud inicial
    useEffect(() => {
        // ya tiene una ubicacion permitida
        if (storageLatitud && storageLongitud) {
            setLatitud(Number(storageLatitud));
            setLongitud(Number(storageLongitud));
        }
        // no tiene ubicacion permitida
        else {
            // revisa si explorardor tiene geolocalizacion
            if (isMobile && isMobile !== 'desktop') {
                if ("geolocation" in navigator) {
                    console.log("Geolocalization Available");
                    encodeStorage('permisoNegado', "permitido");

                    // solicita coordenadas 
                    navigator.geolocation.getCurrentPosition(position => {
                        encodeStorage('latitud', position.coords.latitude);
                        encodeStorage('longitud', position.coords.longitude);
                        setLatitud(position.coords.latitude);
                        setLongitud(position.coords.longitude);
                        encodeStorage('permisoNegado', "permitido");
                    }, e => {
                        console.log("usuario rechaza permisos. ", e);
                        if (e.code === e.PERMISSION_DENIED) {
                            // pop up dialog asking for location
                            encodeStorage('permisoNegado', "negado");
                            removeStorage("latitud")
                            removeStorage("longitud")
                            setLatitud(latitudMexico);
                            setLongitud(longitudMexico);
                        }
                        setMostrarUno(false);
                        setMostrarDos(true);
                    });
                }
                // explorador no tiene disponible geolocalizacion
                else {
                    console.log("Geolocalization not Available.");
                    setLatitud(latitudMexico);
                    setLongitud(longitudMexico);
                }
            }
        }
    }, [])

    // console.log("- latitud:  ", latitudMexico, "longitud:  ", longitudMexico)

    // abre ventana de direccion del marcador seleccionado
    const handleToggle = (e, id) => {
        setVentanaAbierta(id);
        setIsOpen(!isOpen);
    }

    // mapa general con todos los marcadores
    const GoogleMapLocalizador = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={12}
            defaultCenter={{ lat: Number(latitud), lng: Number(longitud) }}
            center={{ lat: Number(latitud), lng: Number(longitud) }}
        >
            {props.isMarkerShown &&
                tiendas.map((marker, id) => (
                    marker.status === true && marker.id != 1244 && <Fragment key={id}>
                        <Marker
                            position={{ lat: Number(marker.latitude), lng: Number(marker.longitude) }}
                            key={marker.id}
                            labelStyle={{ textAlign: "center", width: '220px', fontSize: "13px", paddingTop: "8px", marginTop: "30px" }}
                            labelAnchor={{ x: (220 / 2) + 8, y: 80 }}
                            label={{ text: `${marker.name}`, color: "#ff0000", top: "20px", position: "relative", fontWeight: "400" }}
                            onClick={(e) => handleToggle(e, marker.id)}
                            icon={{
                                url: '/img/pinSanbornsPeque.png',
                                labelOrigin: new window.google.maps.Point(85, 45),
                                // size: new window.google.maps.Size(32,32),
                                anchor: new window.google.maps.Point(16, 32)
                            }}
                            labelClass='map-price-marker'
                            markerWithLabel={window.MarkerWithLabel}
                            labelContent="<div class='marker'></div>"
                        >

                            {isOpen && ventanaAbierta === marker.id &&

                                <InfoWindow onCloseClick={(e) => handleToggle(e, marker.id)}  >
                                    <div className="ventanaInfo">
                                        <div className="nameTienda">
                                            <p>{marker.name}</p>
                                        </div>
                                        <div className="direccionTienda">
                                            <span></span>
                                            <p>{marker.street} {marker.number} {marker.suburb}, {marker.zin_code}, {marker.state}</p>
                                        </div>
                                        <div className="horarioTienda">
                                            <span></span>
                                            <p>Horario de {marker.schedule}</p>
                                        </div>
                                        <div className="telefonoTienda">
                                            <span></span>
                                            <p>Tel. {marker.phone.lada ? <>({marker.phone.lada})</> : ""} {marker.phone.number}</p>
                                        </div>

                                        <div className="botones">
                                            <div className="btnT1">

                                                {/* <a className="llegarBtn" href={`https://www.google.com/maps/dir/${Number(marker.latitude)},${Number(market.longitude)}`} target="_blank" rel="noopener noreferrer">Cómo llegar</a> */}
                                                <a className="llegarBtn" href={"https://maps.google.com?q=" + Number(marker.latitude) + "," + Number(marker.longitude)} target="_blank" rel="noopener noreferrer">Cómo llegar</a>
                                                {/* href={"https://maps.google.com/maps/dir//" + marker.comoLlegar}  href={"https://www.google.com/maps/dir/?api=1&amp;destination=" + Number(marker.latitud) + "," + Number(marker.longitud)}*/}
                                                <a className="llamarBtn" href={"tel:" + marker.phone.lada + marker.phone.number}>Llamar</a>
                                            </div>
                                            <div className="btnT2">
                                                {/* <ShareComponentDetail title={marker.nameTienda} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </InfoWindow>
                            }
                        </Marker>
                    </Fragment>
                ))
            }
        </GoogleMap>
    ))

    // mapa con marcador filtrado
    const GoogleMapLocalizadorConFiltro = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={12}
            defaultCenter={{ lat: Number(primeraPosicion.latitude), lng: Number(primeraPosicion.longitude) }}
            center={{ lat: Number(primeraPosicion.latitude), lng: Number(primeraPosicion.longitude) }}
        >
            <Marker
                position={{ lat: Number(primeraPosicion.latitude), lng: Number(primeraPosicion.longitude) }}
                key={primeraPosicion.id}
                labelStyle={{ textAlign: "center", width: '220px', fontSize: "13px", paddingTop: "8px", fontWeight: "400" }}
                labelAnchor={{ x: (220 / 2) + 8, y: 80 }}
                onClick={(e) => handleToggle(e, primeraPosicion.id)}
                label={{ text: `${primeraPosicion.name}`, color: "#e4002b" }}
                icon={{
                    url: '/img/pinSanbornsPeque.png',
                    labelOrigin: new window.google.maps.Point(85, 45),
                    anchor: new window.google.maps.Point(16, 32)
                }}
            >
                {isOpen && ventanaAbierta === primeraPosicion.id &&

                    <InfoWindow onCloseClick={(e) => handleToggle(e, primeraPosicion.id)}  >
                        <div className="ventanaInfo">
                            <div className="nameTienda">
                                <p>{primeraPosicion.name}</p>
                            </div>
                            <div className="direccionTienda">
                                <span></span>
                                <p> {primeraPosicion.street} {primeraPosicion.number} {primeraPosicion.suburb}, {primeraPosicion.zin_code}, {primeraPosicion.state}</p>
                            </div>
                            <div className="horarioTienda">
                                <span></span>
                                <p>Horario de {primeraPosicion.schedule}</p>
                            </div>
                            <div className="telefonoTienda">
                                <span></span>
                                <p>Tel. {primeraPosicion.phone.lada ? <>({primeraPosicion.phone.lada})</> : ""} {primeraPosicion.phone.number}</p>
                            </div>

                            <div className="botones">
                                <div className="btnT1">
                                    {/* <a className="llegarBtn" href={`https://www.google.com/maps/dir/${Number(latitud)},${Number(longitud)}`} target="_blank" rel="noopener noreferrer">Cómo llegar</a> */}
                                    <a className="llegarBtn" href={"https://maps.google.com?q=" + Number(primeraPosicion.latitude) + "," + Number(primeraPosicion.longitude)} target="_blank" rel="noopener noreferrer">Cómo llegar</a>
                                    {/* <a className="llegarBtn" href={"https://maps.google.com/maps/dir//" + primeraPosicion.comoLlegar}>Cómo llegar</a> */}
                                    {/* <a className="llegarBtn" href={"https://www.google.com/maps/dir/?api=1&amp;destination=" + Number(primeraPosicion.latitud) + "," + Number(primeraPosicion.longitud)} target="_blank" rel="noopener noreferrer" >Cómo llegar</a> */}
                                    <a className="llamarBtn" href={"tel:" + primeraPosicion.phone.lada + primeraPosicion.phone.number}>Llamar</a>
                                </div>
                                <div className="btnT2">
                                    {/* <ShareComponentDetail title={primeraPosicion.nameTienda} /> */}
                                </div>
                            </div>
                        </div>
                    </InfoWindow>
                }
            </Marker>

        </GoogleMap>
    ))

    // obtiene la primera posicion del resultado de busqueda
    useEffect(() => {
        if (respuesta.length > 0) {
            setPrimeraPosicion(respuesta[0]);
            setLatitud(respuesta[0].latitude);
            setLongitud(respuesta[0].longitude);
        }
    }, [respuesta])

    // actualiza la ubicacion guardada del usuario
    const ClickPedirUbicacion = () => {
        setVerTodas(true);
    }

    useEffect(() => {
        if (verTodas === true) {
            setVerTodas(false);
            if (storageLatitud && storageLongitud) {
                setLatitud(Number(storageLatitud));
                setLongitud(Number(storageLongitud));
            }
            else {
                // revisa si explorardor tiene geolocalizacion
                if ("geolocation" in navigator) {
                    console.log("Geolocalization Available");

                    // solicita coordenadas 
                    navigator.geolocation.getCurrentPosition(position => {
                        encodeStorage('latitud', position.coords.latitude);
                        encodeStorage('longitud', position.coords.longitude);
                        setLatitud(position.coords.latitude);
                        setLongitud(position.coords.longitude);
                        encodeStorage('permisoNegado', "permitido");
                    }, e => {
                        console.log("usuario rechaza permisos. ", e);
                        if (e.code === e.PERMISSION_DENIED) {
                            // pop up dialog asking for location
                            removeStorage("latitud");
                            removeStorage("longitud");
                        }
                        encodeStorage('permisoNegado', "negado");
                        setMostrarUno(false);
                        setMostrarDos(true);
                        removeStorage("latitud");
                        removeStorage("longitud");
                    });
                }
                // explorador no tiene disponible geolocalizacion
                else {
                    console.log("Geolocalization not Available.");
                    setLatitud(latitudMexico);
                    setLongitud(longitudMexico);
                }
            }
        }
    }, [verTodas])

    // console.log("is mobile--> ", isMobile, " permiso negado--> ", decodeStorage('permisoNegado'), " latitud--> ", decodeStorage('latitud'))

    return (
        <>
            <div className="modalDetallePedido full sinblur">
                <div className="contentModal modalPadding0">

                    <div className="contentCloseHeader">
                        <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} headerEnCategoria={"headerLocalizador"} />
                    </div>

                    {loading && <Loading />}

                    {response &&
                        response.statusCode === 200
                        ? tiendas &&
                        <div className="dataModal dataModalLocalizador">

                            <div className="barraInferiorDudas barraTiendas">
                                <div className="barraDudas">
                                    <p className={mostrarUno === true ? 'activaSeccionLocalizador p1Dudas leftOpcionActiva' : 'noActivaDudas p1Dudas leftOpcionNoActiva'} onClick={handleMostrarUno}>Tiendas cercanas</p>
                                    <p className={mostrarDos === true ? 'activaSeccionLocalizador p2Dudas rightOpcionActiva' : 'noActivaDudas p2Dudas rightOpcionNoActiva'} onClick={handleMostrarDos}>Listado</p>
                                </div>
                            </div>

                            <div className="boxTiendasMobile">
                                {mostrarUno &&
                                    <>
                                        <>
                                            {/* <div className="listadoTiendasOculto">
                                        {data.filter((dataInterna, id) => {
                                            if (valorSearch == null || valorSearch === '') {
                                                return dataInterna
                                            }
                                            if ((String(dataInterna.campoBusqueda).toLowerCase()).includes(String(valorSearch).toLowerCase())) {
                                                respuestaUno.push(dataInterna)
                                                return dataInterna
                                            }
                                        }).map((tienda) =>
                                            <div className="cardTienda">

                                                <div className="titulo">{String(tienda.nameTienda).replace("-", "").replace("SANBORNS", "Sanborns")}</div>

                                                <div className="direccion">
                                                    <span></span>
                                                    <p>{tienda.titulo}, {tienda.dataText}, {tienda.cp}, {tienda.estado}</p>
                                                </div>

                                                <div className="horario">
                                                    <span></span>
                                                    <p>Horario de {tienda.horario}</p>
                                                </div>

                                                <div className="telefono">Tel. {tienda.telefono}</div>

                                                <div className="botones">
                                                    <div className="btnT1">
                                                        <a className="llegarBtn" href={"https://maps.google.com/maps/dir//" + tienda.comoLlegar}>Cómo llegar</a>
                                                        <a className="llamarBtn" href={"tel:" + tienda.telefono}>Llamar</a>
                                                    </div>
                                                    <div className="btnT2">
                                                        <ShareComponentDetail title={tienda.nameTienda} />
                                                    </div>
                                                </div>

                                            </div>
                                        )}
                                    </div> */}
                                        </>
                                        {/* {decodeStorage('permisoNegado') !== "negado"
                                        ?  */}
                                        <div className="bxUpdateGeo">
                                            <p onClick={ClickPedirUbicacion}>Actualizar ubicación</p>
                                        </div>
                                        {/*    : <div className="bxUpdateGeoNegada">
                                             <p>*Por favor, permite la geolocalización para visualizar tus tiendas cercanas.</p>
                                             <p>En caso de no permitirnos usarla, la ubicación por defecto será Sanborns Plaza Carso.</p>
                                         </div>
                                     } */}

                                        <div className="mapaTiendas">
                                            {/* {valorSearch.length === 0 || respuestaUno.length === 0 ?  */}
                                            <GoogleMapLocalizador
                                                isMarkerShown={true}
                                                googleMapURL={urlGoogle}
                                                containerElement={<div style={{ height: '550px', marginBottom: '30px' }} />}
                                                mapElement={<div style={{ height: "100%" }} />}
                                                loadingElement={<p>Cargando...</p>}
                                                center={{ lat: Number(latitud), lng: Number(longitud) }}
                                            />
                                            {/* : <GoogleMapLocalizadorConFiltro
                                                isMarkerShown={true}
                                                googleMapURL={urlGoogle}
                                                containerElement={<div style={{ height: '550px' }} />}
                                                mapElement={<div style={{ height: "100%" }} />}
                                                loadingElement={<p>Cargando...</p>}
                                                center={{ lat: Number(primeraPosicion.latitud), lng: Number(primeraPosicion.longitud) }}
                                            />
                                        } */}
                                        </div>
                                    </>
                                }

                                {mostrarDos &&
                                    <>
                                        <div className="buscadorTienda">
                                            <div className="divLabel">
                                                <label>
                                                    <input aria-label="Buscador Tiendas" autoComplete="off" type="search" placeholder="Buscar por nombre de tienda o C.P."
                                                        id="buscadorStore" className="buscadorStore"
                                                        value={valorSearch}
                                                        ref={search}
                                                        onChange={(text1) => buscar(text1)}
                                                        onKeyDown={(e) => borrar(e)}>
                                                    </input>
                                                </label>
                                            </div>
                                            <div className="bBuscar">
                                                <button onClick={clickBuscarTienda}> Buscar</button>
                                            </div>
                                        </div>

                                        <div className="listadoTiendas">
                                            {tiendas && tiendas.filter((dataInterna) => {
                                                if (valorSearchClick == null || valorSearchClick === '') {
                                                    return dataInterna
                                                }
                                                else if (
                                                    repareTextSimple(String(dataInterna.name).toLowerCase()).includes(repareTextSimple(String(valorSearchClick).toLowerCase())) ||
                                                    repareTextSimple(String(dataInterna.state).toLowerCase()).includes(repareTextSimple(String(valorSearchClick).toLowerCase())) ||
                                                    repareTextSimple(String(dataInterna.suburb).toLowerCase()).includes(repareTextSimple(String(valorSearchClick).toLowerCase())) ||
                                                    repareTextSimple(String(dataInterna.zip_code).toLowerCase()).includes(repareTextSimple(String(valorSearchClick).toLowerCase()))
                                                ) {
                                                    respuesta.push(dataInterna)
                                                    return dataInterna
                                                }
                                            }).map((tienda, indice) =>
                                                tienda.status === true && tienda.id != 1244 && <>
                                                    <div className="cardTienda">

                                                        <div className="titulo">{String(tienda.name).toUpperCase()}</div>

                                                        <div className="direccion">
                                                            <span></span>
                                                            <p>{tienda.street} {tienda.number} {tienda.suburb}, C.P. {tienda.zip_code}, {tienda.state}</p>
                                                        </div>

                                                        <div className="horario">
                                                            <span></span>
                                                            <p>Horario de {tienda.schedule}</p>
                                                        </div>

                                                        <div className="telefono">Tel. {tienda.phone.lada ? <>({tienda.phone.lada})</> : ""} {tienda.phone.number}</div>

                                                        <div className="botones">
                                                            <div className="btnT1">
                                                                {/* <a className="llegarBtn" href={`https://www.google.com/maps/dir/${Number(tienda.latitude)},${Number(tienda.longitude)}/${tienda.street}`} target="_blank" rel="noopener noreferrer">Cómo llegar</a> */}
                                                                <a className="llegarBtn" href={`https://maps.google.com?q=${tienda.latitude},${tienda.longitude}`} target="_blank" rel="noopener noreferrer">Cómo llegar</a>
                                                                <a className="llamarBtn" href={"tel:" + tienda.phone.lada + tienda.phone.number}>Llamar</a>
                                                            </div>
                                                            <div className="btnT2">
                                                                {/* <ShareComponentDetail title={tienda.nameTienda} /> */}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </>
                                            )}

                                            {((valorSearchClick.length > 0 && respuesta.length === 0) || (respuesta.length > 0 && respuesta[0].id === 1244 && valorSearchClick.length > 0)) && (search.current && search.current.defaultValue)
                                                && <div className="bxSinResultados">
                                                    <p>No se encontraron tiendas con el nombre o c.p. indicado.</p>
                                                </div>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        : null
                    }
                </div>
            </div>
        </>
    )
}

export function LocalizadorTiendas() {
    const { isMobile } = useIsMobile();
    const [response, setResponse] = useState(false);
    const [loading, setLoading] = useState(true);
    const [tiendas, setTiendas] = useState([]);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Localizador de tiendas');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    //- Funcion que va al servicio de localizador de tiendas
    useEffect(() => {
        getLocalizadorTiendaS()
            .then(setResponse)
    }, [])

    //- Guarda en un estado la respuesta y evalua que sea 200 si no manda el error
    useEffect(() => {
        if (response) {
            if (response.statusCode === 200) {
                if (response?.data) {
                    if (response?.data?.data) {
                        setTiendas(response?.data?.data);
                    }
                }
            }
        }
    }, [response])

    useEffect(() => {
        if (tiendas) {
            if (Object.keys(tiendas).length > 0) {
                setLoading(false);
            }
        }
    }, [tiendas])

    return (
        <>
            {isMobile &&
                isMobile === 'desktop'
                ? <LocalizadorTiendasDesktop tiendas={tiendas} response={response} loading={loading} />

                : isMobile === 'movile'
                    ? null
                    : <LocalizadorTiendasMobile tiendas={tiendas} response={response} loading={loading} />
            }
        </>
    )
}

