import React, { useState, useEffect } from 'react';
import { PRODUCTS_PER_PAGINATION } from '../../constants/general';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import './style.sass';

export default function Paginador({ searchParams1, searchResults, setPaginacion, params }) {

    const [flagInicial, setFlagInicial] = useState(false); // si presiono ir al inicio
    const [flagFinal, setFlagFinal] = useState(false); // si presiono ir al final
    const [upperPageBound, setUpperPageBound] = useState(10);  // cantidad de numero de paginas a mostrar en el paginador
    const [lowerPageBound, setLowerPageBound] = useState(0); //  pagina inferior
    const [pageBound, setPageBound] = useState(0); // pagina enlazada 10

    const [searchParams, setSearchParams] = useState({
        busqueda: null,
        idtienda: null,
        subCategory: null,
        marca: null,
        precioMenor: null,
        precioMayor: null,
        categoria: null,
        start: null,
        shipping: null,
        fulfillment: null,
        page: null
    });

    // aumentar numero de pagina
    const incrementPage = () => {
        window.scrollTo(0, 0);
        if ((searchParams1.page + 1) > upperPageBound) {
            setUpperPageBound(upperPageBound + pageBound);
            setLowerPageBound(lowerPageBound + pageBound);
        }

        let listid = searchParams1.page + 1;
        if (listid < Math.ceil(searchResults.searchResult.GSP.RES.M / PRODUCTS_PER_PAGINATION)) {
            setSearchParams({
                ...searchParams,
                page: listid
            });
        }
    };

    // disminuir numero de pagina
    const decrementPage = () => {
        window.scrollTo(0, 0);
        if ((searchParams1.page - 1) % pageBound === 0) {
            setUpperPageBound(upperPageBound - pageBound);
            setLowerPageBound(lowerPageBound - pageBound);
        }
        let listid = searchParams1.page - 1;
        setSearchParams({
            ...searchParams,
            page: listid
        });
    };

    // ir a la ultima pagina
    const ultimatePage = (ultima) => {
        window.scrollTo(0, 0);
        if (ultima < Math.ceil(searchResults.searchResult.GSP.RES.M / PRODUCTS_PER_PAGINATION)) {
            setSearchParams({
                ...searchParams,
                page: ultima
            });
        }
        setFlagInicial(false);
        setFlagFinal(true);
    };

    // ir a la primera pagina
    const initialPage = () => {
        window.scrollTo(0, 0);
        setSearchParams({
            ...searchParams,
            page: 1
        });

        setFlagInicial(true);
        setFlagFinal(false);
    };

    // actualiza la pagina seleccionada
    const handleClick = (event) => {
        let listid = Number(event.target.id);
        setSearchParams({
            ...searchParams,
            page: listid
        });

        $("ul li a.activarPaginacion").removeClass('activarPaginacion');
        $('ul li#' + listid + ' a ').addClass('activarPaginacion');
        window.scrollTo(0, 0);
    };

    // activa la clase css de la pagina seleccionada
    useEffect(() => {
        $("ul li a.activarPaginacion").removeClass('activarPaginacion');
        $('ul li#' + Number(searchParams1.page) + ' a ').addClass('activarPaginacion');
        setPaginacion(searchParams1.page);
    });

    // verifica el numero de pagina para mostrar el boton ...
    useEffect(() => {
        if (searchParams1.page >= 9) {
            setPageBound(8);
        }
    }, [searchParams1.page]);

    // establece las condiciones de suma o resta de paginas para los botones ...
    useEffect(() => {

        setPageBound(Number(searchParams1.page));

        if (Number(searchParams1.page) === 1) {
            setUpperPageBound(pageBound + 9);
            setLowerPageBound(pageBound - 0);
        }
        else if (Number(searchParams1.page) > 1 && Number(searchParams1.page) < 10) {
            setUpperPageBound(pageBound + 7);
            setLowerPageBound(pageBound - 3);
        }
        else {
            setLowerPageBound(pageBound - 5);
            setUpperPageBound(pageBound + 5);
        }
    });

    // boton incrementar pagina
    const btnIncrementClick = () => {
        setUpperPageBound(upperPageBound + pageBound);
        setLowerPageBound(lowerPageBound + pageBound);
        let listid = upperPageBound;

        if ((listid < Math.ceil(searchResults.searchResult.GSP.RES.M / PRODUCTS_PER_PAGINATION)) && listid < 100) {

            setSearchParams({
                ...searchParams,
                page: listid

            });
        }
        if ((listid < Math.ceil(searchResults.searchResult.GSP.RES.M / PRODUCTS_PER_PAGINATION)) && listid > 100) {

            setSearchParams({
                ...searchParams,
                page: 100

            });
        }
    };

    // boton decrementar pagina
    const btnDecrementClick = () => {
        setUpperPageBound(upperPageBound - pageBound);
        setLowerPageBound(lowerPageBound - pageBound);
        let listid = lowerPageBound;

        if ((listid < Math.ceil(searchResults.searchResult.GSP.RES.M / PRODUCTS_PER_PAGINATION)) && listid > 0) {
            setSearchParams({
                ...searchParams,
                page: listid
            });
        }
    };

    // obtener el numero de paginas encontradas
    const pageNumbers = [];
    if (searchResults.searchResult) {
        for (let i = 1; i <= Math.ceil(searchResults.searchResult.GSP.RES.M / PRODUCTS_PER_PAGINATION); i++) {
            pageNumbers.push(i);
        }
    }

    const pageNumbersSlice = pageNumbers.slice(0, 100);
    const renderPageNumbers = pageNumbersSlice.map(number => {
        if (number === 1 && Number(searchParams1.page) === 1) {
            return (
                <li key={number} id={number}>
                    <Link className='activarPaginacion' to={`pagina=${number}`} id={number} onClick={handleClick} >{number}</Link>
                </li>
            )
        }
        else if ((number < upperPageBound + 1) && number > lowerPageBound) {
            if ((number <= pageNumbersSlice.length)) {
                return (
                    <li key={number} id={number}>
                        <Link to={`pagina=${number}`} onClick={handleClick}> {number} </Link>
                    </li>
                )
            }
        }
        return null //agrega return a la funcion de flecha
    });

    // botones ... incrementar
    let pageIncrementBtn = null;
    if ((pageNumbersSlice.length > upperPageBound) &&
        (upperPageBound <= pageNumbersSlice.length - 1) &&
        (searchParams.page > 8) &&
        (searchParams.page < 92)) {

        if ((Number(searchParams1.page) + 8) < pageNumbersSlice.length) {
            pageIncrementBtn = <li className=''><Link
                to={`pagina=${Number(searchParams1.page) + 8}`}
                onClick={btnIncrementClick}
            > &hellip; </Link></li>
        }
    }

    // botones ... decrementar
    let pageDecrementBtn = null;
    if (lowerPageBound >= 1 && searchParams1.page > 10) {
        if ((Number(searchParams1.page) - 8) < 0) {
            pageDecrementBtn = <li className=''><Link
                to={`pagina=1`}
                onClick={btnDecrementClick}
            > &hellip; </Link></li>
        }
        else {
            pageDecrementBtn = <li className=''><Link
                to={`pagina=${Number(searchParams1.page) - 8}`}
                onClick={btnDecrementClick}
            > &hellip; </Link></li>
        }
    }

    return (
        <>
            {searchResults.searchResult.GSP.RES.M > 0
                ? <div className="paginador">
                    <ul className="ulPaginador">
                        {/* visualizar back  e ir a la primera pagina */}
                        {Number(searchParams1.page) >= 3
                            ? <>
                                <li>
                                    <Link to={`pagina=${1}`} onClick={initialPage} className={flagInicial === true ? "backback activarPaginacion" : "backback"} />
                                </li>
                                <li>
                                    <Link to={`pagina=${Number(searchParams1.page) - 1}`} onClick={decrementPage} className="back"><span>Anterior</span></Link>
                                </li>
                            </>
                            : Number(searchParams1.page) === 2
                                ? <>
                                    <li>
                                        <Link to={`pagina=${Number(searchParams1.page) - 1}`} onClick={decrementPage} className="back paddingL25" />
                                    </li>
                                </>
                                : null
                        }

                        {pageDecrementBtn}
                        {renderPageNumbers}
                        {pageIncrementBtn}

                        {/* visualizar next e ir ultima pagina */}
                        {/* la pagina actual es diferente a la ultima pagina && el numero de la pagina es menor que 99 && el numero de pagina es mayor a 1 */}
                        {((Number(searchParams1.page) !== Math.ceil(searchResults.searchResult.GSP.RES.M / PRODUCTS_PER_PAGINATION))
                            && (Number(searchParams1.page) <= 99)
                            && Math.ceil(searchResults.searchResult.GSP.RES.M / PRODUCTS_PER_PAGINATION) > 1)
                            ? <>
                                <li>
                                    <Link to={`pagina=${Number(searchParams1.page) + 1}`}
                                        onClick={incrementPage}
                                        className={flagFinal ? "next activarPaginacion" : ' next'}
                                        data-tooltip="siguiente">
                                        <span>Siguiente</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`pagina=${pageNumbersSlice.length}`}
                                        onClick={() => ultimatePage(pageNumbersSlice.length)}
                                        className={flagFinal ? "nextnext activarPaginacion" : "nextnext"} >
                                    </Link>
                                </li>

                            </>
                            // la pagina ===  total de paginas || el numero de pagina es > = 100 || el numero de paginas es igual a 0 o igual a 1
                            : ((Number(searchParams1.page) === Number(Math.ceil(searchResults.searchResult.GSP.RES.M / PRODUCTS_PER_PAGINATION))
                                || (Number(searchParams1.page) >= 100)
                                || Number(Math.ceil(searchResults.searchResult.GSP.RES.M / PRODUCTS_PER_PAGINATION)) === 0
                                || Number(Math.ceil(searchResults.searchResult.GSP.RES.M / PRODUCTS_PER_PAGINATION)) === 1)
                                ? <>
                                    {/* <li> <span className={"nextnext activarPaginacion"} /> </li>
                                    <li> <span className={"next activarPaginacion"} /> </li> */}
                                </>
                                : null
                            )
                        }
                    </ul>

                </div>
                : null
            }
        </>
    )
}



