import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuPoliticas } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { Link, useHistory } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';

export function AvisoPrivacidadRedesSocialesDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Redes Sociales');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuPoliticas />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Aviso de Privacidad Redes Sociales</p>
                            </div>

                            <div className="contentPreguntas">
                                <div className="question1">
                                    <h2>¿Quién dará tratamiento a tus datos personales?</h2>

                                    <p>Los datos personales proporcionados por usted serán utilizados por Sanborn Hermanos, S. A. de C. V. (en adelante, “Sanborns”) con domicilio en Lago Zurich 245, edificio Presa Falcón piso 7, colonia Ampliación Granada, Alcaldía Miguel Hidalgo, C.P. 11529, México, Ciudad de México.</p>
                                    <p>Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos a la dirección electrónica <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>
                                </div>

                                <div className="question1">
                                    <h2>¿Qué información vamos a recabar?</h2>

                                    <p>SANBORNS para cumplir con las finalidades señaladas en el presente aviso de privacidad podrá obtener y dar tratamiento a la información contenida en su perfil público de la red social de que se trate, así como al contenido de sus publicaciones.</p>
                                    <p>No tratamiento de datos personales de menores de edad.</p>
                                    <p>SANBORNS no recaba datos personales o información directamente de menores de edad. En el supuesto de que se proporcionen datos relacionados con personas menores de edad asumiremos que la persona que los proporciona es el padre, madre y/o tutor legal, quien reconoce haber leído y aceptado el presente aviso de privacidad.</p>
                                    <p>Tratamiento de datos sin decisión humana valorativa y actividades de perfilamiento.</p>
                                    <p>Le informamos que para dar cumplimiento a las finalidades previstas en este Aviso de Privacidad SANBORNS realizará tratamientos de datos personales como parte de un proceso de toma de decisiones sin intervención humana valorativa y técnicas de análisis masivo de datos para realizar actividades de perfilamiento a partir de sus hábitos de consumo, gustos e intereses, monitorear su comportamiento y ofrecerle una experiencia personalizada en relación con el uso de los servicios, así como para dar cumplimiento a algunas(s) de las finalidades(es ) informadas en el presente Aviso de Privacidad.</p>
                                </div>

                                <div className="question1">
                                    <h2>¿Para qué fines usamos tu información?</h2>

                                    <p>SANBORNS tratará la información antes señalada para identificarle, contactarle, divulgar a través de distintos medios de información tanto físicos como electrónicos los contenidos publicados por usted dentro de las redes sociales en las que interactúe con SANBORNS, realizar estudios y reportes estadísticos sobre su comportamiento en redes sociales, atender y dar seguimiento a sus peticiones, comentarios y sugerencias sobre cuestiones que sean competencia de SANBORNS.</p>
                                </div>

                                <div className="question1">
                                    <h2>¿Qué otros usos podemos darle a tu información?</h2>

                                    <p>De manera adicional, si usted no se opone, SANBORNS utilizará su información personal para el envío, tanto físico como electrónico, de publicidad y comunicación con fines de mercadotecnia y tele-marketing, así como para invitarle a participar en encuestas, eventos, rifas y concursos.</p>
                                    <p>En caso de que no desee que sus datos personales sean tratados para alguna o todas las finalidades adicionales, usted nos puede comunicar lo anterior al correo <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>
                                </div>

                                <div className="question1">
                                    <h2>¿A qué terceros podemos transferir tu información?</h2>

                                    <p>SANBORNS podrá transferir, sin requerir de su consentimiento, los datos personales necesarios a autoridades competentes en los casos legalmente previstos, así como a cualquier empresa Subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común, las cuales operan bajo políticas de privacidad que cumplen con las disposiciones aplicables correspondientes, para dar cumplimiento a las finalidades señaladas en el presente aviso.</p>
                                    <p>Para conocer cuáles son las Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común a quienes sus datos son transferidos puede consultarlo en http://www.gsanborns.com.mx/perfil-corporativo.html SANBORNS podrá transferir sin requerir de su consentimiento los datos personales necesarios a socios comerciales y/o terceros con los que tengamos celebrados acuerdos comerciales o de colaboración para fines relacionados con la validación y autenticación de su identidad, conforme a las finalidades descritas en el presente Aviso de Privacidad.</p>
                                    <p>Si usted no se opone, SANBORNS podrá transferir sus datos personales a socios comerciales para que estos puedan contactarle y ofrecerle bienes y/o servicios que resulten de su interés, o bien, para que puedan determinar si usted es sujeto a adquirir los bienes y/o servicios que dichos terceros ofrecen.</p>
                                    <p>En caso de que no desee que sus datos personales sean transferidos para alguna o todas las finalidades señaladas, desde este momento usted nos puede comunicar lo anterior al correo <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>
                                </div>

                                <div className="question1">
                                    <h2>¿Cómo puedes ejercer tus derechos ARCO y/o revocar tu consentimiento?</h2>

                                    <p>Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en lo sucesivo "Derechos ARCO"), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Datos Personales de SANBORNS a la dirección electrónica <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a>. Su petición deberá ser realizada a través del Formato de Solicitud de Derechos ARCO, el cual debe ser solicitado a la dirección de correo electrónico antes señalada. Para que el Departamento de Datos Personales de SANBORNS.</p>
                                    <p>SANBORNS pueda darle seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo que es necesario que complete todos los campos indicados en el Formato y lo acompañe con copia de alguna de las identificaciones oficiales vigentes que se señalan en el mismo. Para obtener mayor información sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento contacte al Departamento de Datos Personales de SANBORNS.</p>
                                </div>

                                <div className="question1">
                                    <h2>¿Cómo puedes limitar el uso y/o divulgación de tus datos?</h2>

                                    <p>Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la dirección arco@sanborns.com.mx. En caso de que su Solicitud sea procedente se le registrará en el listado de exclusión propio de SANBORNS. Para mayor información favor de contactar al Departamento de Protección de Datos de SANBORNS.</p>
                                </div>

                                <div className="question1">
                                    <h2>¿Cómo te notificaremos de los cambios que efectuemos al presente Aviso dePrivacidad?</h2>

                                    <p>SANBORNS le notificará de cualquier cambio a su aviso de privacidad a través de nuestra página de internet <Link to="/">https://www.sanborns.com.mx</Link> por lo cual le pedimos se revise la misma de manera periódica.</p>
                                </div>

                                <div className="question1">
                                    <h2>¿Cómo puede hacernos llegar sus dudas?</h2>

                                    <p>Nuestro Departamento de Datos Personales queda a sus órdenes para proporcionarle cualquier información adicional que requiera o, en su caso, para resolver cualquier duda que pudiera surgirle en materia de privacidad y protección de datos personales, para lo que podrá contactarnos a través del correo electrónico <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>
                                </div>

                                <div className="question1">
                                    <h2>INAI</h2>

                                    <p>En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) para hacer efectivos sus derechos.</p>
                                </div>

                                <div className="question1">
                                    <h2>Aceptación de Aviso de Privacidad</h2>

                                    <p>En caso de utilizar nuestros servicios se entenderá que autoriza el uso de sus datos personales de conformidad con lo establecido en el presente Aviso de Privacidad.</p>

                                    <p className='bold'>Fecha de última actualización: 01 de Julio de 2020</p>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}


export function AvisoPrivacidadRedesSocialesMobile() {
    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Redes Sociales');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN">
                        <div className="btn closeBack" onClick={onClose} ></div>
                        <p className="title">Aviso de Privacidad Redes Sociales</p>
                    </div>

                    <div className="dataModal">

                        <div className="boxAN">
                            <div className='bxSlider'>
                                <p className="pSliderTitleL">¿Quién dará tratamiento a tus datos personales?</p>
                                <p className="pSliderL">Los datos personales proporcionados por usted serán utilizados por Sanborn Hermanos, S. A. de C. V. (en adelante, “Sanborns”) con domicilio en Lago Zurich 245, edificio Presa Falcón piso 7, colonia Ampliación Granada, Alcaldía Miguel Hidalgo, C.P. 11529, México, Ciudad de México.</p>
                                <p className="pSliderL">Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos a la dirección electrónica <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>

                                <p className="pSliderTitleL">¿Qué información vamos a recabar?</p>
                                <p className="pSliderL">SANBORNS para cumplir con las finalidades señaladas en el presente aviso de privacidad podrá obtener y dar tratamiento a la información contenida en su perfil público de la red social de que se trate, así como al contenido de sus publicaciones.</p>
                                <p className="pSliderL">No tratamiento de datos personales de menores de edad.</p>
                                <p className="pSliderL">SANBORNS no recaba datos personales o información directamente de menores de edad. En el supuesto de que se proporcionen datos relacionados con personas menores de edad asumiremos que la persona que los proporciona es el padre, madre y/o tutor legal, quien reconoce haber leído y aceptado el presente aviso de privacidad.</p>
                                <p className="pSliderL">Tratamiento de datos sin decisión humana valorativa y actividades de perfilamiento.</p>
                                <p className="pSliderL">Le informamos que para dar cumplimiento a las finalidades previstas en este Aviso de Privacidad SANBORNS realizará tratamientos de datos personales como parte de un proceso de toma de decisiones sin intervención humana valorativa y técnicas de análisis masivo de datos para realizar actividades de perfilamiento a partir de sus hábitos de consumo, gustos e intereses, monitorear su comportamiento y ofrecerle una experiencia personalizada en relación con el uso de los servicios, así como para dar cumplimiento a algunas(s) de las finalidades(es ) informadas en el presente Aviso de Privacidad.</p>

                                <p className="pSliderTitleL">¿Para qué fines usamos tu información?</p>
                                <p className="pSliderL">SANBORNS tratará la información antes señalada para identificarle, contactarle, divulgar a través de distintos medios de información tanto físicos como electrónicos los contenidos publicados por usted dentro de las redes sociales en las que interactúe con SANBORNS, realizar estudios y reportes estadísticos sobre su comportamiento en redes sociales, atender y dar seguimiento a sus peticiones, comentarios y sugerencias sobre cuestiones que sean competencia de SANBORNS.</p>

                                <p className="pSliderTitleL">¿Qué otros usos podemos darle a tu información?</p>
                                <p className="pSliderL">De manera adicional, si usted no se opone, SANBORNS utilizará su información personal para el envío, tanto físico como electrónico, de publicidad y comunicación con fines de mercadotecnia y tele-marketing, así como para invitarle a participar en encuestas, eventos, rifas y concursos.</p>
                                <p className="pSliderL">En caso de que no desee que sus datos personales sean tratados para alguna o todas las finalidades adicionales, usted nos puede comunicar lo anterior al correo <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>

                                <p className="pSliderTitleL">¿A qué terceros podemos transferir tu información?</p>
                                <p className="pSliderL">SANBORNS podrá transferir, sin requerir de su consentimiento, los datos personales necesarios a autoridades competentes en los casos legalmente previstos, así como a cualquier empresa Subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común, las cuales operan bajo políticas de privacidad que cumplen con las disposiciones aplicables correspondientes, para dar cumplimiento a las finalidades señaladas en el presente aviso.</p>
                                <p className="pSliderL">Para conocer cuáles son las Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común a quienes sus datos son transferidos puede consultarlo en http://www.gsanborns.com.mx/perfil-corporativo.html SANBORNS podrá transferir sin requerir de su consentimiento los datos personales necesarios a socios comerciales y/o terceros con los que tengamos celebrados acuerdos comerciales o de colaboración para fines relacionados con la validación y autenticación de su identidad, conforme a las finalidades descritas en el presente Aviso de Privacidad.</p>
                                <p className="pSliderL">Si usted no se opone, SANBORNS podrá transferir sus datos personales a socios comerciales para que estos puedan contactarle y ofrecerle bienes y/o servicios que resulten de su interés, o bien, para que puedan determinar si usted es sujeto a adquirir los bienes y/o servicios que dichos terceros ofrecen.</p>
                                <p className="pSliderL">En caso de que no desee que sus datos personales sean transferidos para alguna o todas las finalidades señaladas, desde este momento usted nos puede comunicar lo anterior al correo <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>

                                <p className="pSliderTitleL">¿Cómo puedes ejercer tus derechos ARCO y/o revocar tu consentimiento?</p>
                                <p className="pSliderL">Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en lo sucesivo "Derechos ARCO"), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Datos Personales de SANBORNS a la dirección electrónica <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a>. Su petición deberá ser realizada a través del Formato de Solicitud de Derechos ARCO, el cual debe ser solicitado a la dirección de correo electrónico antes señalada. Para que el Departamento de Datos Personales de SANBORNS.</p>
                                <p className="pSliderL">SANBORNS pueda darle seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo que es necesario que complete todos los campos indicados en el Formato y lo acompañe con copia de alguna de las identificaciones oficiales vigentes que se señalan en el mismo. Para obtener mayor información sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento contacte al Departamento de Datos Personales de SANBORNS.</p>

                                <p className="pSliderTitleL">¿Cómo puedes limitar el uso y/o divulgación de tus datos?</p>
                                <p className="pSliderL">Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la dirección arco@sanborns.com.mx. En caso de que su Solicitud sea procedente se le registrará en el listado de exclusión propio de SANBORNS. Para mayor información favor de contactar al Departamento de Protección de Datos de SANBORNS.</p>

                                <p className="pSliderTitleL">¿Cómo te notificaremos de los cambios que efectuemos al presente Aviso dePrivacidad?</p>
                                <p className="pSliderL">SANBORNS le notificará de cualquier cambio a su aviso de privacidad a través de nuestra página de internet <Link to="/">https://www.sanborns.com.mx</Link> por lo cual le pedimos se revise la misma de manera periódica.</p>

                                <p className="pSliderTitleL">¿Cómo puede hacernos llegar sus dudas?</p>
                                <p className="pSliderL">Nuestro Departamento de Datos Personales queda a sus órdenes para proporcionarle cualquier información adicional que requiera o, en su caso, para resolver cualquier duda que pudiera surgirle en materia de privacidad y protección de datos personales, para lo que podrá contactarnos a través del correo electrónico <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>

                                <p className="pSliderTitleL">INAI</p>
                                <p className="pSliderL">En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) para hacer efectivos sus derechos.</p>

                                <p className="pSliderTitleL">Aceptación de Aviso de Privacidad</p>
                                <p className="pSliderL">En caso de utilizar nuestros servicios se entenderá que autoriza el uso de sus datos personales de conformidad con lo establecido en el presente Aviso de Privacidad.</p>

                                <p className='bold pSliderL'>Fecha de última actualización: 01 de Julio de 2020</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function AvisoPrivacidadRedesSociales() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Redes Sociales');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {isMobile &&
                isMobile === 'desktop'
                ? <AvisoPrivacidadRedesSocialesDesktop />

                : isMobile === 'movile'
                    ? null
                    : <AvisoPrivacidadRedesSocialesMobile />
            }
        </>
    )
}