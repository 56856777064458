import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuPoliticas } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { Link, useHistory } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';

export function LegalDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Legal');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuPoliticas />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Información Legal</p>
                            </div>

                            <div className="contentPreguntas">
                                <div className="question1">
                                    <h2>Derecho de Propiedad Industrial</h2>

                                    <p>Todo lo contenido en esta página, tanto el texto como las gráficas, logos, íconos, imágenes, audio clips y software, son propiedad de SANBORN HERMANOS S.A. o licenciados a esta por SANBORN HERMANOS S.A y se encuentran protegidos sus derechos por la ley de fomento y protección de la propiedad industrial, y por los tratados internacionales. La compilación de todo lo contenido en esta página es propiedad exclusiva de SANBORN HERMANOS S.A., y se encuentra protegido por las leyes mexicanas y por el derecho internacional. El contenido y el software solo pueden ser usados como un recurso de compra. Cualquier otro uso, incluyendo la reproducción, modificación, distribución, transmisión, re publicación, exhibición o ejecución del contenido de esta página se encuentra estrictamente prohibido.</p>
                                </div>

                                <div className="question1">
                                    <h2>Marcas</h2>

                                    <p>La marca Sanborns y sus logotipos se encuentran legalmente registrados en México y otros países. Estos no pueden ser usados en conexión con otros productos o servicios en virtud de que los derechos y su uso son exclusivos de SANBORN HERMANOS S.A.</p>
                                </div>

                                <div className="question1">
                                    <h2>Liberación de Responsabilidad</h2>

                                    <p>Esta página es creada por SANBORN HERMANOS S.A., con la debida autorización de SANBORN HERMANOS S.A, para el uso de su imagen, lo cual no la hace responsable, ni garantiza, expresa o implícitamente la operación de dicha página, así como la información, contenido, materiales o productos incluidos en la misma. Siempre respetando las garantías que de acuerdo a la ley federal de protección al consumidor deben acompañar a todos los productos que se comercializan, así como los derechos y prerrogativas que confiere la ley de la materia. SANBORN HERMANOS S.A. no será responsable por daños de ninguna naturaleza derivados del uso de esta página, incluyendo pero no limitando los daños directos, indirectos, incidentales, culposos y consecuenciales.</p>
                                    <p>Los Precios se encuentran sujetos a Cambio y sin Previo Aviso.</p>
                                    <p>Los productos mostrados en esta página se encuentran sujetos a disponibilidad.</p>
                                    <p>No nos hacemos responsables por los cargos aduanales generados por envíos al extranjero, ya que dichos cargos pueden variar de país en país y son responsabilidad de quien recibe el producto.</p>
                                </div>

                                <div className="question1">
                                    <h2>Competencia</h2>

                                    <p>Las operaciones comerciales que se den entre SANBORN HERMANOS S.A. y sus consumidores estarán regidas por la ley federal de protección al consumidor, de los Estados Unidos Mexicanos y cualquier controversia que se derive de la aplicación de la misma se ventilará ante las autoridades y los tribunales de la Ciudad de México D.F.,renunciando expresamente a cualquiera otra jurisdicción que les pudiera corresponder por razón de su domicilio presente o futuro. Nos reservamos el derecho de hacer cambios a nuestra página y/o exclusiones, términos y condiciones en cualquier tiempo. Con relación a la información comercial y disposiciones generales para los productos que ofrece SANBORN HERMANOS S.A., se observa lo que disponen las normas oficiales mexicanas aplicables a los mismos.</p>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}


export function LegalMobile() {
    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Legal');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN">
                        <div className="btn closeBack" onClick={onClose} ></div>
                        <p className="title">Información Legal</p>
                    </div>

                    <div className="dataModal">

                        <div className="boxAN">
                            <div className='bxSlider'>
                                <p className="pSliderTitleL">Derecho de Propiedad Industrial</p>
                                <p className="pSliderL">Todo lo contenido en esta página, tanto el texto como las gráficas, logos, íconos, imágenes, audio clips y software, son propiedad de SANBORN HERMANOS S.A. o licenciados a esta por SANBORN HERMANOS S.A y se encuentran protegidos sus derechos por la ley de fomento y protección de la propiedad industrial, y por los tratados internacionales. La compilación de todo lo contenido en esta página es propiedad exclusiva de SANBORN HERMANOS S.A., y se encuentra protegido por las leyes mexicanas y por el derecho internacional. El contenido y el software solo pueden ser usados como un recurso de compra. Cualquier otro uso, incluyendo la reproducción, modificación, distribución, transmisión, re publicación, exhibición o ejecución del contenido de esta página se encuentra estrictamente prohibido.</p>

                                <p className="pSliderTitleL">Marcas</p>
                                <p className="pSliderL">La marca Sanborns y sus logotipos se encuentran legalmente registrados en México y otros países. Estos no pueden ser usados en conexión con otros productos o servicios en virtud de que los derechos y su uso son exclusivos de SANBORN HERMANOS S.A.</p>

                                <p className="pSliderTitleL">Liberación de Responsabilidad</p>
                                <p className="pSliderL">Esta página es creada por SANBORN HERMANOS S.A., con la debida autorización de SANBORN HERMANOS S.A, para el uso de su imagen, lo cual no la hace responsable, ni garantiza, expresa o implícitamente la operación de dicha página, así como la información, contenido, materiales o productos incluidos en la misma. Siempre respetando las garantías que de acuerdo a la ley federal de protección al consumidor deben acompañar a todos los productos que se comercializan, así como los derechos y prerrogativas que confiere la ley de la materia. SANBORN HERMANOS S.A. no será responsable por daños de ninguna naturaleza derivados del uso de esta página, incluyendo pero no limitando los daños directos, indirectos, incidentales, culposos y consecuenciales.</p>
                                <p className="pSliderL">Los Precios se encuentran sujetos a Cambio y sin Previo Aviso.</p>
                                <p className="pSliderL">Los productos mostrados en esta página se encuentran sujetos a disponibilidad.</p>
                                <p className="pSliderL">No nos hacemos responsables por los cargos aduanales generados por envíos al extranjero, ya que dichos cargos pueden variar de país en país y son responsabilidad de quien recibe el producto.</p>

                                <p className="pSliderTitleL">Competencia</p>
                                <p className="pSliderL">Las operaciones comerciales que se den entre SANBORN HERMANOS S.A. y sus consumidores estarán regidas por la ley federal de protección al consumidor, de los Estados Unidos Mexicanos y cualquier controversia que se derive de la aplicación de la misma se ventilará ante las autoridades y los tribunales de la Ciudad de México D.F.,renunciando expresamente a cualquiera otra jurisdicción que les pudiera corresponder por razón de su domicilio presente o futuro. Nos reservamos el derecho de hacer cambios a nuestra página y/o exclusiones, términos y condiciones en cualquier tiempo. Con relación a la información comercial y disposiciones generales para los productos que ofrece SANBORN HERMANOS S.A., se observa lo que disponen las normas oficiales mexicanas aplicables a los mismos.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function Legal() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Legal');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {isMobile &&
                isMobile === 'desktop'
                ? <LegalDesktop />

                : isMobile === 'movile'
                    ? null
                    : <LegalMobile />
            }
        </>
    )
}