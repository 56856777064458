import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuAutocenter } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory } from 'react-router-dom';
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';

export function PreguntasFrecuentesDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Contact Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuAutocenter />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Preguntas Frecuentes</p>
                            </div>
                        </section>

                        <div className="contentFormasP">

                            <div className="bxFp1">
                                <p className="tituloFp">¿En qué tiempo recibo mis pedidos?</p>
                                <p className="p1fp">El tiempo de entrega aproximado es de 5 a 10 días hábiles para Ciudad de México y Área Metropolitana, de 6 a 10 días hábiles para Interior de la República, posteriores a la validación del pago y puede variar según:</p>
                                <ul className="ulBxFp1">
                                    <li>Tipo de artículo (alto o bajo volúmen).</li>
                                    <li>Origen y destino del artículo</li>
                                </ul>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Qué costo de envío tienen los pedidos realizados en Sanborns Internet?</p>
                                <p className="p1fp">El costo de envió depende de la zona a entregar, por lo que le recomendamos verificar el apartado "Gastos de Envío" en la parte inferior de la página.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Qué compañía de mensajería utilizan?</p>
                                <p className="p1fp">Los pedidos se entregan por medio de DHL y mensajería interna.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Puedo recoger mí pedido en la tienda Sanborns más cercana?</p>
                                <p className="p1fp">Con la modalidad “Recoge en tienda*” usted podrá realizar su compra en línea y recogerlo en la sucursal de su preferencia*.</p>
                                <p className="p1fp">1. Elige tu producto.</p>
                                <p className="p1fp">2. Selecciona tu tienda.</p>
                                <p className="p1fp">3. Recoge tu producto.</p>
                                <p className="p1fp"><span className="bold">Horario de servicio para modalidad Recoge en Tienda: 8:00 a.m. hasta las 8:00 p.m.</span><br></br>
                                Para recoger el pedido en tu sucursal seleccionada, es importante presentar alguna de las siguientes identificaciones oficiales:</p>
                                <ul className="ulBxFp1">
                                    <li>Credencial de Elector con fotografía (INE/IFE)</li>
                                    <li>Pasaporte vigente</li>
                                    <li>Licencia de conducir</li>
                                </ul>
                                <p className="p1fp">*Las sucursales mostradas pueden variar, ya que se muestran considerando la disponibilidad del producto.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Cómo puedo pagar mis pedidos?</p>
                                <p className="p1fp">Puede pagar con una de las siguientes opciones:</p>

                                <p className="tituloFp">Importante: Las cuentas aquí mencionadas son para uso exclusivo de Compras por Internet</p>
                                <p className="p1fp">Si deseas pagar tu Crédito Sanborns por favor haz click aquí <a href="https://credito.sanborns.com.mx">Credito.sanborns.com.mx.</a></p>

                                <ul className="ulBxFp1">
                                    <li><span className="bold">Tarjetas de Crédito:</span> Sanborns y Visa, MasterCard y American Express.</li>
                                    <li><span className="bold">Depósito Bancario:</span> Citibanamex, Santander, BBVA e Inbursa. Eligiendo esta opción encontrarás un ejemplo gráfico de cómo llenar la forma correctamente.</li>
                                    {/* <li><span className="bold">Certificado de Regalos Sanborns.</span></li> */}
                                    <li><span className="bold">PayPal.</span></li>
                                    <li><span className="bold">Oxxo Pay:</span> Si lo que prefieres es pagar con efectivo en el horario y día que tu prefieras, sanborns.com.mx pone a tu servicio nuestra opción de pagos en las tiendas de conveniencia Oxxo, así que ya no hay excusas para comprar lo que deseas, fácil, rápido, sencillo y seguro siempre en sanborns.com.mx.</li>
                                    <li><span className="bold">Pago en Tienda Sanborns </span></li>
                                </ul>    
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Puedo pagar con mi tarjeta de débito?</p>
                                <p className="p1fp">Puedes ocupar tu tarjeta de débito en nuestra página previo registro de la misma a través de PayPal</p>
                                
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Puedo pagar mi pedido al recibir mi compra?</p>
                                <p className="p1fp">Contamos con el servicio de pagar y recoger en tienda.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Puedo pagar mis pedidos en tiendas Sanborns?</p>
                                <p className="p1fp">Si, puedes pagar tu pedido en cualquiera de nuestras sucursales. Sigue los pasos que se te indicarán por correo electrónico al finalizar tu pedido.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Cuántas formas de pago puedo utilizar para liquidar mi compra?</p>
                                <p className="p1fp">Solo UNA.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Si elegí pagar con Tarjeta de Crédito y deseo cambiar mi forma de pago?</p>
                                <p className="p1fp">Le recomendamos enviar un correo electrónico a la dirección <a href="mailto: sanborns@sanborns.com.mx">sanborns@sanborns.com.mx </a> solicitando la cancelación de su pedido, y debe realizar un nuevo pedido con la forma de pago deseada.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Si elegí pagar con Certificado de Regalo Sanborns y deseo cambiar mi forma de pago?</p>
                                <p className="p1fp">Le recomendamos enviar un correo electrónico a la dirección <a href="mailto: sanborns@sanborns.com.mx">sanborns@sanborns.com.mx </a>  solicitando la cancelación de su pedido, y debe realizar una nueva orden con la forma de pago deseada.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Si elegí pagar con Depósito Bancario y deseo cambiar mi forma de pago?</p>
                                <p className="p1fp">Le recomendamos enviar un correo electrónico a la dirección <a href="mailto: sanborns@sanborns.com.mx">sanborns@sanborns.com.mx </a>  solicitando la cancelación de su pedido, y debe realizar una nueva orden con la forma de pago deseada.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Si elegí pagar con Depósito Bancario en un banco determinado y el pago se realizó en otro de los bancos que se tienen como opción de depósito?</p>
                                <p className="p1fp">En este caso le solicitamos de aviso al personal de Atención a clientes, al correo <a href="mailto:mesadecontrol@sanborns.com.mx">mesadecontrol@sanborns.com.mx</a>  con los datos del pedido, fecha del depósito y banco en el que se realizó.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">Ya realicé mi depósito, ¿Cómo estoy seguro de que lo recibieron?</p>
                                <p className="p1fp">Puede verificar en la sección "Historial de Pedidos" el cambio de status en AUTORIZADO, también puede consultar el estado en los teléfonos de Atención a clientes, indicando su número de pedido.</p>
                                <p className="p1fp"><span className="bold">Aclaración de dudas y compras en Internet</span></p>
                                <ul className="ulBxFp1">
                                    <li><a href="tel:800 4726267">800 GSANBOR </a></li>
                                    <li><a href="tel:800 7262676">800 SANBORN </a></li>
                                    <li><a href="tel:800 4726267">800 472-6267 </a></li>
                                    <li><a href="tel:800 7262676">800 726-2676 </a></li>
                                    <li><a href="tel:800 8493310">800 849-3310 </a></li>
                                    <li><a href="tel:55 26 52 97 33 ">(55) 26 52 97 33 </a></li>
                                    <li>Número Internacional <a href="tel:">1 8774773781</a></li>
                                </ul>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">Realicé mi depósito, pero no aparece mi número de pedido en la ficha</p>
                                <p className="p1fp">En este caso le solicitamos nos haga llegar una copia de su ficha de depósito por vía fax al <a href="tel:55 5523 7523">(55) 5523-7523</a> o bien escaneada por correo electrónico a la dirección <a href="mailto: sanborns@sanborns.com.mx">sanborns@sanborns.com.mx</a> indicando su número de pedido.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">Realicé mi depósito, pero aparece mi número de pedido erróneo en la ficha</p>
                                <p className="p1fp">En este caso le solicitamos nos haga llegar una copia de su ficha de depósito por vía fax al <a href="tel:55 5523 7523">(55) 5523-7523</a> o bien escaneada por correo electrónico a la dirección <a href="mailto: sanborns@sanborns.com.mx">sanborns@sanborns.com.mx</a> indicando su número de pedido correcto.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Puedo comprar y que se envié la mercancía a otro domicilio diferente al de mi registro?</p>
                                <p className="p1fp">SI, solo debe crear un nuevo domicilio en el paso # 2 (Información de Envió).</p>
                                <p className="p1fp">Para mayor información le recomendamos llamar a los teléfonos de Atención a Clientes, donde con gusto le ayudaran a finalizar su orden paso a paso.</p>
                                <p className="p1fp"><span className="bold">Aclaración de Dudas y Compras en Internet</span></p>
                                <ul className="ulBxFp1">
                                    <li><a href="tel:800 4726267">800 GSANBOR </a></li>
                                    <li><a href="tel:800 7262676">800 SANBORN </a></li>
                                    <li><a href="tel:800 4726267 ">800 4726267 </a></li>
                                    <li><a href="tel:800 7262676">800 7262676 </a></li>
                                    <li><a href="tel:800 8493310">800 8493310 </a></li>
                                    <li><a href="tel:55 2652 9733 ">(55) 2652 9733 </a></li>
                                    <li>Número Internacional <a href="tel:1 8774773781">1 8774773781</a></li>
                                </ul>
                                <p className="p1fp">Para aclaraciones y dudas del pedido, favor de comunicarse por mail, al siguiente correo electrónico: <a href="mailto:incidencias@sanborns.com.mx">incidencias@sanborns.com.mx</a> indicando su número de pedido correcto.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">Cambio mi dirección de correo electrónico, ¿Cómo les aviso?</p>
                                <p className="p1fp">El cambio de correo electrónico se puede realizar en la opción Modificar Cuenta de Correo que se encuentra en el menú izquierdo de nuestra página.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">Cambié de lugar de residencia, ¿Cómo cambio mi registro?</p>
                                <p className="p1fp">Usted puede modificar los datos de su registro en la opción "Modificar Registro" que se encuentra en el menú izquierdo de nuestra página.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">No puedo ver mi Historial de Pedidos</p>
                                <p className="p1fp">Esto se debe a que realizó un cambio de correo electrónico en su registro y al ingresar con el nuevo correo solo vera los pedidos que se realicen con el mismo.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Cómo puedo Cancelar un pedido?</p>
                                <p className="p1fp">Solo debe solicitar la cancelación enviando un correo a la dirección <a href="mailto:sanborns@sanborns.com.mx">sanborns@sanborns.com.mx </a></p>
                                <p className="p1fp">Nota: Solo se podrán cancelar los pedidos, siempre y cuando no hayan sido enviados.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Puedo comprar en las Tiendas Sanborns con el Precio Internet?</p>
                                <p className="p1fp">NO, los precios mostrados en Internet y sus promociones son exclusivos de la página.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Puedo pagar con mi tarjeta de crédito bancaria a meses sin intereses?</p>
                                <p className="p1fp">Por supuesto que si con tu Tarjeta de Crédito Sanborns y tarjetas de crédito participantes.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Puedo solicitar mis compras por vía telefónica?</p>
                                <p className="p1fp">Si es posible, solo tiene que llamar al siguiente número: <a href="tel:800-849-92-87 ">800-849-92-87</a></p>
                                <p className="p1fp">Nota: aplica solo para compras y entregas dentro de la República Mexicana, con el precio de lista, sin promociones y con el costo de envío correspondiente.</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">Si no estoy en mi domicilio, ¿Puede recibir otra persona mi pedido?</p>
                                <p className="p1fp">La mercancía se entregará en el domicilio indicado a la persona que se encuentre en ese momento, mostrando una identificación oficial (IFE, Pasaporte vigente o Cédula profesional).</p>
                            </div>

                            <div className="bxFp1">
                                <p className="tituloFp">¿Puedo comprar y reservar boletos para Volaris?</p>
                                <p className="p1fp">NO, por el momento Sanborns solo es receptor de pagos por reservaciones en Volaris. Para reservaciones debe contactar al personal de Volaris en www.volaris.com.mx ó a los siguientes teléfonos:</p>
                                <ul className="ulBxFp1">
                                    <li>D.F. <a href="tel: 11028000">11028000</a></li>
                                    <li>INTERIOR DE LA REPUBLICA <a href="tel: 80078652747 ">800-7VOLARIS</a></li>
                                    <li>INTERNACIONAL <a href="tel:1-866-988-35-27 ">1-866-988-35-27 </a></li>
                                    <li>DESDE TU TELCEL <a href="tel:*8000">*8000</a></li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}



export function PreguntasFrecuentesMobile() {
    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Preguntas frecuentes');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN">
                        <div className="btn closeBack" onClick={onClose} ></div>
                        <p className="title">Preguntas Frecuentes</p>
                    </div>

                    <div className="dataModal">

                        <div className="boxAN">

                            <div className="bxSlider">
                                <p className="pSliderTitleL">¿En qué tiempo recibo mis pedidos?</p>
                                <p className="pSliderL">El tiempo de entrega aproximado es de 5 a 10 días hábiles para Ciudad de México y Área Metropolitana, de 6 a 10 días hábiles para Interior de la República, posteriores a la validación del pago y puede variar según:</p>
                                <ul className="ulDeclaracionesDisc">
                                    <li>Tipo de artículo (alto o bajo volúmen).</li>
                                    <li>Origen y destino del artículo</li>
                                </ul>
                                
                                <p className="pSliderTitleLr">¿Qué costo de envío tienen los pedidos realizados en Sanborns Internet?</p>
                                <p className="pSliderL">El costo de envió depende de la zona a entregar, por lo que le recomendamos verificar el apartado "Gastos de Envío" en la parte inferior de la página.</p>

                                <p className="pSliderTitleL">¿Qué compañía de mensajería utilizan?</p>
                                <p className="pSliderL">Los pedidos se entregan por medio de DHL y mensajería interna.</p>
                            
                                <p className="pSliderTitleL">¿Puedo recoger mí pedido en la tienda Sanborns más cercana?</p>
                                <p className="pSliderL">Con la modalidad “Recoge en tienda*” usted podrá realizar su compra en línea y recogerlo en la sucursal de su preferencia*.</p>
                                <ul className="ulDeclaracionesNumber">
                                    <li>Elige tu producto.</li>
                                    <li>Selecciona tu tienda.</li>
                                    <li>Recoge tu producto.</li>
                                </ul>
                                <p className="pSliderL"><span className="bold">Horario de servicio para modalidad Recoge en Tienda: 8:00 a.m. hasta las 8:00 p.m.</span></p>
                                <p className="pSliderL">Para recoger el pedido en tu sucursal seleccionada, es importante presentar alguna de las siguientes identificaciones oficiales:</p>
                                <ul className="ulDeclaracionesDisc">
                                    <li>Credencial de Elector con fotografía (INE/IFE)</li>
                                    <li>Pasaporte vigente</li>
                                    <li>Licencia de conducir</li>
                                </ul>
                                <p className="pSliderL">*Las sucursales mostradas pueden variar, ya que se muestran considerando la disponibilidad del producto.</p>
                                
                                <p className="pSliderTitleL">¿Cómo puedo pagar mis pedidos?</p>
                                <p className="pSliderL">Puede pagar con una de las siguientes opciones:</p>
                                <p className="pSliderTitleL">Importante: Las cuentas aquí mencionadas son para uso exclusivo de Compras por Internet</p>
                                <p className="pSliderL">Si deseas pagar tu Crédito Sanborns por favor haz click aquí <a href="https://credito.sanborns.com.mx">Credito.sanborns.com.mx.</a></p>
                                <ul className="ulDeclaracionesDisc">
                                    <li><span className="bold">Tarjetas de Crédito:</span> Sanborns, Visa, MasterCard y American Express.</li>
                                    <li><span className="bold">Depósito Bancario:</span> Citibanamex, Santander, BBVA e Inbursa. Eligiendo esta opción encontrarás un ejemplo gráfico de cómo llenar la forma correctamente.</li>
                                    {/* <li><span className="bold">Certificado de Regalos Sanborns.</span></li> */}
                                    <li><span className="bold">PayPal.</span></li>
                                    <li><span className="bold">Oxxo Pay:</span>Si lo que prefieres es pagar con efectivo en el horario y día que tu prefieras, sanborns.com.mx pone a tu servicio nuestra opción de pagos en las tiendas de conveniencia Oxxo, así que ya no hay excusas para comprar lo que deseas, fácil, rápido, sencillo y seguro siempre en sanborns.com.mx.</li>
                                    <li><span className="bold">Pago en Tienda Sanborns</span></li>
                                </ul>
                                <p className="pSliderL">Cuentas para Depósito Bancario:</p>
                                <p className="pSliderL">
                                    <span className="bold">Banco Santander</span><br></br>
                                    Cuenta 65501173989<br></br>
                                    A nombre de Sanborn Hermanos. S.A. C.V.<br></br>
                                    CLABE Interbancaria 014 180 655 011 739 896<br></br>
                                    Referencia Número de Pedido<br></br>
                                    Si tu pago es con cheque, debe expedirlo a nombre de Sanborns Hermanos, S.A. C.V., el pedido será liberado a las 48 hrs hábiles posteriores al pago
                                </p>

                                <p className="pSliderL">
                                    <span className="bold">Banco Citibanamex</span><br></br>
                                    Cuenta 555888<br></br>
                                    A nombre de Sanborn Hermanos. S.A. C.V.<br></br>
                                    Sucursal 870<br></br>
                                    CLABE Interbancaria 002 180 087 005 558 883<br></br>
                                    Referencia Número de Pedido<br></br>
                                    Si tu pago es con cheque, debe expedirlo a nombre de Sanborns Hermanos, S.A. C.V., el pedido será liberado a las 48 hrs hábiles posteriores al pago
                                </p>

                                <p className="pSliderL">
                                    <span className="bold">Banco BBVA</span><br></br>
                                    Cuenta 0133636623<br></br>
                                    A nombre de Sanborn Hermanos. S.A. C.V.<br></br>
                                    CLABE Interbancaria 012 180 001 336 366 231<br></br>
                                    Referencia Número de Pedido<br></br>
                                    Si tu pago es con cheque, debe expedirlo a nombre de Sanborns Hermanos, S.A. C.V., el pedido será liberado a las 48 hrs hábiles posteriores al pago
                                </p>

                                <p className="pSliderL">
                                    <span className="bold">Banco Inbursa</span><br></br>
                                    Cuenta 12299470014<br></br>
                                    A nombre de Sanborn Hermanos. S.A. C.V.<br></br>
                                    RFC SGC990610NA0<br></br>
                                    Sucursal 012<br></br>
                                    CLABE Interbancaria 036 1801229 94700149<br></br>
                                    Referencia Número de Pedido<br></br>
                                    Si tu pago es con cheque, debe expedirlo a nombre de Sanborns Hermanos, S.A. C.V., el pedido será liberado a las 48 hrs hábiles posteriores al pago
                                </p>
                                
                                <p className="pSliderTitleL">¿Puedo pagar con mi tarjeta de débito?</p>
                                <p className="pSliderL">Puedes ocupar tu tarjeta de débito en nuestra página previo registro de la misma a través de PayPal</p>

                                <p className="pSliderTitleL">¿Puedo pagar mi pedido al recibir mi compra?</p>
                                <p className="pSliderL">Contamos con el servicio de pagar y recoger en tienda.</p>

                                <p className="pSliderTitleL">¿Puedo pagar mis pedidos en tiendas Sanborns?</p>
                                <p className="pSliderL">Si, puedes pagar tu pedido en cualquiera de nuestras sucursales. Sigue los pasos que se te indicarán por correo electrónico al finalizar tu pedido.</p>
                                
                                <p className="pSliderTitleL">¿Cuántas formas de pago puedo utilizar para liquidar mi compra?</p>
                                <p className="pSliderL">Solo UNA.</p>

                                <p className="pSliderTitleL">¿Si elegí pagar con Tarjeta de Crédito y deseo cambiar mi forma de pago?</p>
                                <p className="pSliderL">Le recomendamos enviar un correo electrónico a la dirección <a href="mailto: sanborns@sanborns.com.mx">sanborns@sanborns.com.mx </a> solicitando la cancelación de su pedido, y debe realizar un nuevo pedido con la forma de pago deseada.</p>
                                
                                <p className="pSliderTitleL">¿Si elegí pagar con Certificado de Regalo Sanborns y deseo cambiar mi forma de pago?</p>
                                <p className="pSliderL">Le recomendamos enviar un correo electrónico a la dirección <a href="mailto: sanborns@sanborns.com.mx">sanborns@sanborns.com.mx </a> solicitando la cancelación de su pedido, y debe realizar un nuevo pedido con la forma de pago deseada.</p>
                                
                                <p className="pSliderTitleL">¿Si elegí pagar con Depósito Bancario y deseo cambiar mi forma de pago?</p>
                                <p className="pSliderL">Le recomendamos enviar un correo electrónico a la dirección <a href="mailto: sanborns@sanborns.com.mx">sanborns@sanborns.com.mx </a> solicitando la cancelación de su pedido, y debe realizar un nuevo pedido con la forma de pago deseada.</p>

                                <p className="pSliderTitleL">¿Si elegí pagar con Depósito Bancario en un banco determinado y el pago se realizó en otro de los bancos que se tienen como opción de depósito?</p>
                                <p className="pSliderL">En este caso le solicitamos de aviso al personal de Atención a clientes, al correo <a href="mailto:mesadecontrol@sanborns.com.mx">mesadecontrol@sanborns.com.mx</a>  con los datos del pedido, fecha del depósito y banco en el que se realizó.</p>

                                <p className="pSliderTitleL">Ya realicé mi depósito, ¿Cómo estoy seguro de que lo recibieron?</p>
                                <p className="pSliderL">Puede verificar en la sección "Historial de Pedidos" el cambio de status en AUTORIZADO, también puede consultar el estado en los teléfonos de Atención a clientes, indicando su número de pedido.</p>
                                <p className="pSliderL"><span className="bold">Aclaración de dudas y compras en Internet</span></p>
                                <ul className="ulDeclaracionesDisc">
                                    <li><a href="tel:800 4726267">800 GSANBOR </a></li>
                                    <li><a href="tel:800 7262676">800 SANBORN </a></li>
                                    <li><a href="tel:800 4726267">800 472-6267 </a></li>
                                    <li><a href="tel:800 7262676">800 726-2676 </a></li>
                                    <li><a href="tel:800 8493310">800 849-3310 </a></li>
                                    <li><a href="tel:55 26 52 97 33 ">(55) 26 52 97 33 </a></li>
                                    <li>Número Internacional <a href="tel:">1 8774773781</a></li>
                                </ul>

                                <p className="pSliderTitleL">Realicé mi depósito, pero no aparece mi número de pedido en la ficha</p>
                                <p className="pSliderL">En este caso le solicitamos nos haga llegar una copia de su ficha de depósito por vía fax al <a href="tel:55 5523 7523">(55) 5523-7523</a> o bien escaneada por correo electrónico a la dirección <a href="mailto: sanborns@sanborns.com.mx">sanborns@sanborns.com.mx</a> indicando su número de pedido.</p>
                            
                                <p className="pSliderTitleL">Realicé mi depósito, pero aparece mi número de pedido erróneo en la ficha</p>
                                <p className="pSliderL">En este caso le solicitamos nos haga llegar una copia de su ficha de depósito por vía fax al <a href="tel:55 5523 7523">(55) 5523-7523</a> o bien escaneada por correo electrónico a la dirección <a href="mailto: sanborns@sanborns.com.mx">sanborns@sanborns.com.mx</a> indicando su número de pedido correcto.</p>

                                <p className="pSliderTitleL">¿Puedo comprar y que se envié la mercancía a otro domicilio diferente al de mi registro?</p>
                                <p className="pSliderL">SI, solo debe crear un nuevo domicilio en el paso # 2 (Información de Envió).</p>
                                <p className="pSliderL">Para mayor información le recomendamos llamar a los teléfonos de Atención a Clientes, donde con gusto le ayudaran a finalizar su orden paso a paso.</p>
                                <p className="pSliderL"><span className="bold">Aclaración de Dudas y Compras en Internet</span></p>
                                <ul className="ulDeclaracionesDisc">
                                    <li><a href="tel:800 4726267">800 GSANBOR </a></li>
                                    <li><a href="tel:800 7262676">800 SANBORN </a></li>
                                    <li><a href="tel:800 4726267 ">800 4726267 </a></li>
                                    <li><a href="tel:800 7262676">800 7262676 </a></li>
                                    <li><a href="tel:800 8493310">800 8493310 </a></li>
                                    <li><a href="tel:55 2652 9733 ">(55) 2652 9733 </a></li>
                                    <li>Número Internacional <a href="tel:1 8774773781">1 8774773781</a></li>
                                </ul>
                                <p className="pSliderL">Para aclaraciones y dudas del pedido, favor de comunicarse por mail, al siguiente correo electrónico: <a href="mailto:incidencias@sanborns.com.mx">incidencias@sanborns.com.mx</a> indicando su número de pedido correcto.</p>
                                
                                <p className="pSliderTitleL">Cambio mi dirección de correo electrónico, ¿Cómo les aviso?</p> 
                                <p className="pSliderL">El cambio de correo electrónico se puede realizar en la opción Modificar Cuenta de Correo que se encuentra en el menú izquierdo de nuestra página.</p>

                                <p className="pSliderTitleL">Cambié de lugar de residencia, ¿Cómo cambio mi registro?</p>
                                <p className="pSliderL">Usted puede modificar los datos de su registro en la opción "Modificar Registro" que se encuentra en el menú izquierdo de nuestra página.</p>
                            
                                <p className="pSliderTitleL">No puedo ver mi Historial de Pedidos</p>
                                <p className="pSliderL">Esto se debe a que realizó un cambio de correo electrónico en su registro y al ingresar con el nuevo correo solo vera los pedidos que se realicen con el mismo.</p>

                                <p className="pSliderTitleL">¿Cómo puedo Cancelar un pedido?</p>
                                <p className="pSliderL">Solo debe solicitar la cancelación enviando un correo a la dirección <a href="mailto:sanborns@sanborns.com.mx">sanborns@sanborns.com.mx </a></p>
                                <p className="pSliderL">Nota: Solo se podrán cancelar los pedidos, siempre y cuando no hayan sido enviados.</p>
                                
                                <p className="pSliderTitleL">¿Puedo comprar en las Tiendas Sanborns con el Precio Internet?</p>
                                <p className="pSliderL">NO, los precios mostrados en Internet y sus promociones son exclusivos de la página.</p>
                                
                                <p className="pSliderTitleL">¿Puedo pagar con mi tarjeta de crédito bancaria a meses sin intereses?</p>
                                <p className="pSliderL">Por supuesto que si con tu Tarjeta de Crédito Sanborns y tarjetas de crédito participantes.</p>

                                <p className="pSliderTitleL">¿Puedo solicitar mis compras por vía telefónica?</p>
                                <p className="pSliderL">Si es posible, solo tiene que llamar al siguiente número: <a href="tel:800-849-92-87 ">800-849-92-87</a></p>
                                <p className="pSliderL">Nota: aplica solo para compras y entregas dentro de la República Mexicana, con el precio de lista, sin promociones y con el costo de envío correspondiente.</p>

                                <p className="pSliderTitleL">Si no estoy en mi domicilio, ¿Puede recibir otra persona mi pedido?</p>
                                <p className="pSliderL">La mercancía se entregará en el domicilio indicado a la persona que se encuentre en ese momento, mostrando una identificación oficial (IFE, Pasaporte vigente o Cédula profesional).</p>

                                <p className="pSliderTitleL">¿Puedo comprar y reservar boletos para Volaris?</p>
                                
                                <p className="pSliderL">NO, por el momento Sanborns solo es receptor de pagos por reservaciones en Volaris. Para reservaciones debe contactar al personal de Volaris en www.volaris.com.mx ó a los siguientes teléfonos:</p>
                                <ul className="ulDeclaracionesDisc">
                                    <li>D.F. <a href="tel: 11028000">11028000</a></li>
                                    <li>INTERIOR DE LA REPUBLICA <a href="tel: 80078652747 ">800-7VOLARIS</a></li>
                                    <li>INTERNACIONAL <a href="tel:1-866-988-35-27 ">1-866-988-35-27 </a></li>
                                    <li>DESDE TU TELCEL <a href="tel:*8000">*8000</a></li>
                                </ul>
                                                            
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export function PreguntasFrecuentes() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Preguntas frecuentes');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            { isMobile &&
                isMobile === 'desktop'
                ? <PreguntasFrecuentesDesktop />

                : isMobile === 'movile'
                    ? null
                    : <PreguntasFrecuentesMobile />
            }
        </>
    )
}