import React from 'react'
import styles from './styleErrorInternalServer.module.sass'

export default function ErrorInternalServer() {

    return (
        <section>
            <div className={styles.errorMessageServices}>
                <p>
                    <span className={styles.bold}>
                        ¡Hola! Parece que nuestro sitio está recibiendo muchas visitas en
                        este momento&nbsp;
                    </span>
                    y ha causado un pequeño tropiezo. No te preocupes, ¡estamos en ello!
                </p>
                <img className={styles.errorImage} src="/img/error-sanborns.svg" alt="Error en el sistema" width="450" height="450"
                    loading="lazy" />
                <p><span className={styles.bold}>Aquí hay algunos consejos mientras esperas:</span></p>
                <p><span className={styles.bold}>1.- Refresca la página:</span></p>
                <p>
                    A veces, todo lo que se necesita es un simple refresh para volver a la acción
                </p>
                <p><span className={styles.bold}>2.- Espera 10 minutos y reintenta:</span></p>
                <p>
                    Dale al servidor un poco de espacio para respirar y luego inténtalo de nuevo.
                </p>
                <p><span className={styles.bold}>3.- Ponte en contacto con nosotros:</span></p>
                <p>
                    Si el problema persiste o necesitas ayuda adicional, estamos aquí para ti.
                    ¡No dudes en contactarnos! <a href="mailto:sanborns@sanborns.com.mx">sanborns@sanborns.com.mx</a>
                </p>
                <p>Gracias por tu paciencia y apoyo. ¡Volveremos pronto!</p>
            </div>
        </section>
    )
}