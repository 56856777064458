import React, { useState, useEffect, useRef } from 'react';
import './style.sass';
import getSearch from 'services/getSearchAutocomplete';
import $ from 'jquery';
import HistorySearch from 'components/HistorySearch';
import { decodeStorage } from 'functions/storageBase64';
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory } from 'react-router-dom';

export function AutocompleteComponent(props) {
    const {
        totalArray,
        focus,
        handleHoverSearcherSuggestions,
        hiddenAutocomplete,
        ClearTextLink,
        ClearTextLink2,
    } = props;

    return (
        <ul className="contAutocomplete" onFocus={focus} name="sugerencias">
            {totalArray.map(({ clase, nombre }, ind) =>
                <li key={ind} className="searcher-suggs-word" onMouseOver={handleHoverSearcherSuggestions} >
                    {String(clase) === "word_item"
                        ? <>
                            <button
                                onClick={(e) => hiddenAutocomplete(e)}
                                id={`/resultados?query=${ClearTextLink(nombre)}`}
                                className={clase}
                                value={ClearTextLink(nombre)}>
                                {ClearTextLink(nombre)}
                            </button>
                            {/* <button
                                className="ir"
                                onClick={(e) => ReplicarEnBuscador(e)}
                                value={ClearTextLink(nombre)}
                                aria-label="ir a">
                            </button> */}
                        </>
                        // : String(clase) === "category_item"
                        //     ? <>
                        //         <button
                        //             className={clase}
                        //             onClick={(e) => hiddenAutocomplete(e)}
                        //             value={ClearTextLink2(nombre)}
                        //             id={`/categoria/${nombre.split('/')[2]}/${nombre.split('/')[3]}`}
                        //         >
                        //             Ir a la Categoría {ClearTextLink2(nombre)}
                        //         </button>
                        //         {/* <button
                        //         className="ir"
                        //         onClick={(e) => ReplicarEnBuscador(e)} 
                        //         value={ClearTextLink2(nombre)} 
                        //         aria-label="ir a">
                        //     </button> */}
                        //     </>
                        // : String(clase) === "store_item"
                        //     ? <>
                        //         <button
                        //             className={clase}
                        //             onClick={(e) => hiddenAutocomplete(e)}
                        //             value={ClearTextLink2(nombre)}
                        //             id={`/tienda/${nombre.split('/')[2]}/${nombre.split('/')[3]}`}>
                        //             Ir a la Tienda   {ClearTextLink2(nombre)}
                        //         </button>
                        //         {/* <button
                        //             className="ir"
                        //             onClick={(e) => ReplicarEnBuscador(e)} 
                        //             value={ClearTextLink2(nombre)} 
                        //             aria-label="ir a">
                        //         </button> */}
                        //     </>
                        : String(clase) === "disclaimerNotice" && ClearTextLink(nombre) !== "Tiendas Oficiales" && ClearTextLink(nombre) !== "Por Coincidencia" && ClearTextLink(nombre) !== "Por categoría"
                            ? <span className={clase}>{ClearTextLink(nombre)}</span>
                            : null
                    }
                </li>
            )}
        </ul>
    )
}

export function AutocompleteComponentResp(props) {
    const {
        totalArray,
        focus,
        handleHoverSearcherSuggestions,
        hiddenAutocomplete,
        ClearTextLink,
        ClearTextLink2,
        ReplicarEnBuscador
    } = props;

    return (
        <ul className="contAutocomplete" onFocus={focus} name="sugerencias">
            {totalArray.map(({ cadena, clase, nombre }, ind) => (
                <li key={ind}
                    className="searcher-suggs-word"
                    onMouseOver={handleHoverSearcherSuggestions} >
                    {String(clase) === "word_item"
                        ? (<>
                            <button onClick={(e) => hiddenAutocomplete(e)}
                                id={`/resultados?query=${ClearTextLink(nombre)}`}
                                className={clase}
                                value={ClearTextLink(nombre)}>
                                {ClearTextLink(nombre)}
                            </button>
                            <button className="ir"
                                onClick={(e) => ReplicarEnBuscador(e)}
                                value={ClearTextLink(nombre)}></button>
                        </>

                        )
                        // : String(clase) === "category_item"
                        //     ? (<>
                        //         <button className={clase}
                        //             onClick={(e) => hiddenAutocomplete(e)}
                        //             value={ClearTextLink2(nombre)}
                        //             id={`/categoria/${nombre.split('/')[2]}/${nombre.split('/')[3]}`}
                        //         // name={ClearTextLink2(nombre)} // id={ClearTextLink2(nombre)}
                        //         >
                        //             {ClearTextLink2(nombre)}
                        //         </button>
                        //         <button className="ir"
                        //             onClick={(e) => ReplicarEnBuscador(e)}
                        //             value={ClearTextLink2(nombre)}></button>
                        //     </>
                        //     ) 
                        // : String(clase) === "store_item"
                        //     ? (<>
                        //         {/* <Link className={clase} onClick={(e) => hiddenAutocomplete(e)} value={ClearTextLink2(nombre)}
                        //                 // to={`/tienda/${nombre.split('/')[2]}/${nombre.split('/')[3]}`}
                        //                 name={ClearTextLink2(nombre)}id={ClearTextLink2(nombre)}>
                        //                 {ClearTextLink(nombre)}  </Link> */}
                        //         <button className={clase}
                        //             onClick={(e) => hiddenAutocomplete(e)}
                        //             value={ClearTextLink2(nombre)}
                        //             id={`/tienda/${nombre.split('/')[2]}/${nombre.split('/')[3]}`}>
                        //             {ClearTextLink2(nombre)}
                        //         </button>
                        //         <button className="ir"
                        //             onClick={(e) => ReplicarEnBuscador(e)}
                        //             value={ClearTextLink2(nombre)}></button>
                        //     </>
                        //     )
                        : String(clase) === "disclaimerNotice"
                            ? (<span className={clase}>{ClearTextLink(nombre)}</span>)
                            : null
                    }
                </li>
            ))}
        </ul>
    )
}

export default function SearchHeader(props) {
    const {
        searchWork = ""
    } = props;
    const history = useHistory();

    const url = history.location.pathname;
    const { isMobile } = useIsMobile();
    const [typeSearch, setTypeSearch] = useState('');
    const [autocomplete, setAutocomplete] = useState([]);
    const [show, setShow] = useState(false);
    const [totalArray, setTotalArray] = useState([]);
    const [palabraBuscada, setPalabraBuscada] = useState('');
    const [mostrarHistorial, setMostrarHistorial] = useState(false);
    const [inputVacio, setInputVacio] = useState(false);
    const [enFocus, setEnFocus] = useState(false);
    var arrayBusquedas = decodeStorage('busquedas');
    const nameInputDesk = useRef();
    const [movimientoSubirBajar, setMovimientoSubirBajar] = useState(0);

    // pre carga el valor de busqueda
    useEffect(() => {
        setTypeSearch(searchWork);
    }, [])

    useEffect(() => {
        if (!typeSearch) {
            setAutocomplete([]);
        }
        else if (typeSearch) {
            if (typeSearch?.length === 0) {
                setAutocomplete([]);
            }
            else if (typeSearch?.length >= 1) {
                // console.log(typeSearch)
                getSearch({ typeSearch })
                .then(autocomplete =>
                    setAutocomplete(autocomplete.suggestions)
                )
            }
            else {
                // getSearch({ typeSearch: '' })
                //     .then(autocomplete =>
                //         setAutocomplete(autocomplete.suggestions)
                //     )
                // console.log('aaaa')
            }
        }
    }, [typeSearch])

    // buscar texto con con boton buscar
    const SearchText = (evt) => {
        if ((typeSearch?.length > 2 && String(url) != '/') || (typeSearch?.length > 2 && String(url) === '/')) {
            //history.push(`/resultados?query=${encodeURIComponent(String(typeSearch.replace("%","")))}`);
            console.log(`/resultados?query=${encodeURIComponent(String(typeSearch.replace("%","").replace(".","")))}`)
            setShow(false);
            //console.log(`1`)
        }
    }

    // asignar valor al input
    const SearchChange = (evt) => {
        let limpiarTexto = String(evt.target.value).trim();
        
        if (limpiarTexto.length > 0) {
            setTypeSearch(evt.target.value);
        }
        else{
            setTypeSearch("");
        }
    }

    // establece la cantidad de tiendas y resultados a visualizar
    useEffect(() => {
        const arrayTotalCoincidencia = [];
        const total = [];

        if (autocomplete?.length > 0) {
            autocomplete.map((auto, i) => {
                return (auto.clase === "word_item" && arrayTotalCoincidencia?.length < 8
                    ? arrayTotalCoincidencia.push({ clase: auto.clase, nombre: auto.nombre })
                    : null
                )
            })
        }

        if (autocomplete?.length > 0) {
            var contadorCate = []
            autocomplete.map((auto, i) => {
                return (auto.clase === "category_item" ? contadorCate.push(auto.clase) : null)
            })

            if (contadorCate?.length > 0)
                arrayTotalCoincidencia.push({ 'clase': "disclaimerNotice", 'nombre': "Por categoría" })

            autocomplete.map((auto, i) => {
                return (auto.clase === "category_item" && arrayTotalCoincidencia?.length < 8 ?
                    arrayTotalCoincidencia.push({ 'clase': auto.clase, 'nombre': auto.nombre })
                    : null
                )
            })
        }

        // if (autocomplete.length > 0) {
        //     var contadorStore = [];
        //     autocomplete.map((auto, i) => {
        //         return (auto.clase === "store_item" ? contadorStore.push(auto.clase) : null)
        //     })

        //     if (contadorStore.length > 0)
        //         arrayTotalCoincidencia.push({ clase: "disclaimerNotice", nombre: "Tiendas Oficiales" })

        //     autocomplete.map((auto, i) => {
        //         return (String(auto.clase) === "store_item" && arrayTotalCoincidencia.length <= 11 ?
        //             arrayTotalCoincidencia.push({ clase: auto.clase, nombre: auto.nombre })
        //             : null
        //         )
        //     })
        // }

        total.push(arrayTotalCoincidencia);
        setTotalArray(total[0]);
    }, [autocomplete])

    // limpia los textos ir a a tienda , ir a la categoria
    const ClearTextLink = (text) => {
        const texto = text;
        const limpio = texto.replace(/(<([^>]+)>)/ig, '').trim();
        const longitud = limpio?.length;

        // if (limpio.substring(0, 14) === "Ir a la Tienda") {
        //     return <p>{limpio.substring(15, longitud)}</p>
        //     //     return <p> {limpio.substring(0, 14)} <span style={{ color: "red" }}>{limpio.substring(15, longitud)}</span></p>
        // }

        // if (limpio.substring(0, 17) === "Ir a la Categoría") {
        //     return <p>{limpio.substring(18, longitud)}</p>
        //     //     return <p> {limpio.substring(0, 17)} <span style={{ color: "red" }}>{limpio.substring(18, longitud)}</span></p>
        // }

        // if (longitud > 0) {
        //     const negrita = limpio.replace(typeSearch, '$&'.bold())

        //     const inicio = negrita.indexOf("<b>")
        //     const fin = negrita.indexOf("</b>")

        //     return <p> {limpio.substring(0, inicio)} <b style={{ fontWeight: "600", color: "black" }}>
        //         {limpio.substring((inicio), (fin - 3))}</b>{limpio.substring(fin - 3, longitud)}
        //     </p>
        // }

        return texto.replace(/(<([^>]+)>)/ig, '').trim();
    }

    function ClearTextLink2(text) {
        const texto = text;
        const limpio = texto.replace(/(<([^>]+)>)/ig, '').trim();
        const longitud = limpio?.length;

        // if (limpio.substring(0, 14) == "Ir a la Tienda") {
        //     return limpio.substring(15, longitud).trim();
        // }

        // else if (limpio.trim().substring(0, 15) == "Ir a la Categor") {
        //     return limpio.substring(18, longitud).trim();
        // }

        return texto.replace(/(<([^>]+)>)/ig, '').trim();
    }

    //- Funcion de la respuesta del autocompletado para mandar a la busqueda
    const hiddenAutocomplete = (e, clase) => {
        enviarPalabraBuscada(e);
        setAutocomplete([]);

        if (String(clase) === "word_item") {
            var buscarTexto = String(e.target.value);
            let result = buscarTexto//.slice(5, -6);
            //var urlRedirigir = `/resultados?query=${encodeURIComponent(String(typeSearch.replace("%","")))}`//e.target.id;
            var urlRedirigir = `/resultados?query=${encodeURIComponent(String(buscarTexto.replace('.','')))}`//e.target.id;

            // console.log(String(e.target.value))
            // console.log(`1.1`)
            
            setTypeSearch(result);
            setTimeout(() => {
                history.replace(url, null);
                window.location.href = urlRedirigir
                // history.push(`${urlRedirigir}`);
                //console.log(`${urlRedirigir}`);
                //console.log(`2`)
            }, 100);
        }
        else {
            var buscarTexto = String(e.target.value);
            var limpiar = buscarTexto//.replace(/-/g, " ")
            var urlRedirigir = e.target.id;
            
            setTypeSearch(limpiar);
            setTimeout(() => {
                //history.replace(url, null);
                //history.push(`${urlRedirigir}`);
                console.log(`${urlRedirigir}`);
                console.log(`3`)
            }, 100);
        }
        setShow(false);
    }

    // envia palabra buscada al historial
    const enviarPalabraBuscada = evt => {
        evt.preventDefault();

        if (String(evt.target.value) !== "") {
            setPalabraBuscada(String(evt.target.value).replace("%","").replace(".",""));
            // console.log(String(evt.target.value).replace("%",""))
            // console.log(`4`)
        }
    }

    const guardarTexto = (e) => {
        enviarPalabraBuscada(e);

        setTimeout(() => {
            if (String(url) !== '/' && typeSearch?.length > 2) {
                history.replace(url, null);
                window.location.href = `/resultados?query=${encodeURIComponent(String(typeSearch.replace("%","").replace(".","")))}`
                //console.log(`/resultados?query=${encodeURIComponent(String(typeSearch.replace("%","")))}`);
                //console.log(`5`)
            }
            else if (String(url) == '/' && typeSearch?.length > 2) {
                history.replace(url, null);
                window.location.href = `/resultados?query=${encodeURIComponent(String(typeSearch.replace("%","").replace(".","")))}`
                //history.push(`/resultados?query=${encodeURIComponent(String(typeSearch.replace("%","")))}`);
                //console.log(`6`)
            }
        }, 500);
        setShow(false);
    }

    //   saber si subio o bajo por el listado con las flechas
    const handleKeyMove = (evt) => {
        if (evt.keyCode == 38) {
            if (movimientoSubirBajar > 0)
                setMovimientoSubirBajar(movimientoSubirBajar - 1);
        }
        else if (evt.keyCode == 40) {
            setMovimientoSubirBajar(movimientoSubirBajar + 1);
        }
    }
    //----------------------------------------------------------

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown);
    })

    useEffect(() => {
        document.removeEventListener('mousedown', handleMouseDown);
    })

    const handleMouseDown = (event) => {
        let x = $(document).scrollLeft() + event.clientX; // event.offsetX
        let y = $(document).scrollTop() + event.clientY; // event.offsetY

        //no hizo clic en la entrada de búsqueda o en la lista de sugerencias
        if (show && !checkXYInElement(x, y, '.contAutocomplete') && !checkXYInElement(x, y, '.input')) {
            setShow(false);
        }
    }

    const checkXYInElement = (x, y, className) => {
        let elem = $(className);
        if (elem.length === 0) {
            return false;
        }

        let rect = { x: elem.offset().left, y: elem.offset().top, w: elem.outerWidth(), h: elem.outerHeight() };

        if (x < rect.x || y < rect.y || x > (rect.x + rect.w) || y > (rect.y + rect.h)) {
            return false;
        }

        return true;
    }

    // manejar el evento onChange la entrada de búsqueda
    // const handleSearcherInputChange = (event) => {
    //     let newSearchWords = event.target.value;
    //     console.log("new: ", newSearchWords)
    //     setTypeSearch(newSearchWords)
    //     setShow(true);
    // }

    // handle user click on the list of the suggestions
    // const handleClickSuggetionsKeywords = (event) => {
    //     console.log("new22: ", event)
    //     setTypeSearch(event.target.value)
    //     // setShow(false)
    // }

    const handleFocusSearcherInput = (e) => {
        nameInputDesk.current.focus();
        setShow(true);
    }

    const handleBlurSearcherInput = () => {
        setTimeout(function () {
            setShow(false);
        }, 100)
    }

    // muestra el historial de busqueda
    useEffect(() => {
        if (show == true) {
            setMostrarHistorial(true);
            if (String(url) !== '/buscador') {
                document.querySelector('.headerSup form.search').classList.add('searchFocus');
            } else {
                document.querySelector('form.search').classList.add('searchFocus');
            }
        } else {
            setMostrarHistorial(false)
            if (url !== '/buscador') {
                document.querySelector('.headerSup form.search').classList.remove('searchFocus');
            } else {
                document.querySelector('form.search').classList.add('searchFocus');
            }
        }
    }, [show])

    const handleSearcherInputKeyDown = (event) => {
        if (show === true) {
            // use keyboard to select the suggesions
            handleSelectSuggestions(event);
        } else {
            // just show the suggestions list
            setShow(true);
            // setAutocomplete([])
        }
    }

    // use teclados para seleccionar las sugerencias
    const handleSelectSuggestions = (event) => {
        let li = $('.searcher-suggs-word.selected');
        let tienda = "Por tienda";
        let categoria = "Por categoría";

        if (event.keyCode == 40 || event.keyCode == 38) { // 40 => down, 38 => up
            event.preventDefault();

            if (li.length === 0) {
                $('.searcher-suggs-word:first-child').toggleClass('selected');
            } else if (event.keyCode == 40) {

                if (String(li.text()).trim() == tienda || String(li.text()).trim() == categoria) {
                    li.removeClass('selected');
                    li.next().addClass('selected');
                } else {
                    li.removeClass('selected');
                    li.next().addClass('selected');
                }
            } else {

                if (String(li.text()).trim() === tienda || String(li.text()).trim() === categoria) {
                    li.removeClass('selected');
                    li.prev().addClass('selected');
                } else {
                    li.removeClass('selected');
                    li.prev().toggleClass('selected');
                }
            }
        } else {

            // if (event.keyCode == 13) {  // 13 => tecla enter
            //     // event.preventDefault()
            //     if (li.length > 0) {
            //         // setTypeSearch(li.text())

            //         setShow(false)
            //     } else {
            //         setShow(false)
            //         // setTypeSearch([])
            //     }
            // }
            if (String(li.text()).trim() !== tienda && String(li.text()).trim() !== categoria) {
                // setTypeSearch(li.text())
                if (event.keyCode === 13 || event.onClick) {
                    if (li.text()) {
                        setTypeSearch(String(li.text()).trim());
                    }
                }

            } else {
                if (event.keyCode == 13 || event.onClick) {
                    event.preventDefault();
                }
            }
        }
    }

    // evento flotante en la lista de sugerencias
    const handleHoverSearcherSuggestions = (event) => {
        $('.searcher-suggs-word.selected').removeClass('selected');
        $('.searcher-suggs-word:focus').removeClass('selected');
        $('.searcher-suggs-word:hover').addClass('selected');
    }

    function regresar() {
        history.go(-1);
    }

    // saber si el input esta limpio / vacio
    useEffect(() => {
        var elemento = document.getElementById("inputBusqueda").value;

        if (elemento == '') {
            setInputVacio(true);
        }
        else {
            setInputVacio(false);
        }
    })

    function focus() {
        if (document.activeElement.name === 'search') {
            setEnFocus(true);
        }
        else {
            setEnFocus(false);
        }
    }

    // agrega el valor de la palabra seleccionada en el input de busqueda
    const ReplicarEnBuscador = (e) => {
        e.preventDefault();
        let valor = String(e.target.value).replace("%","");
        let urlRedireccion = e.target.id;
        console.log(`7`)
        setTypeSearch(valor);

        SearchChange(e)
        setTimeout(() => {
            // history.replace(url, null);
            // history.push(`${String(urlRedireccion).replace("%","")}`);
            console.log(`${String(urlRedireccion).replace("%","")}`);
            console.log(`8`)
        }, 50);
    }

    useEffect(() => {
        $(document).ready(function () {
            $('body').on('click', 'main', function () {
                setShow(false);
            })
        })

        $(document).ready(function () {
            $('body').on('click', '.menuPrincipal', function () {
                setShow(false);
            })
        })

        $(document).ready(function () {
            $('body').on('click', '.barHeaderCP', function () {
                setShow(false);
            })
        })

        $(document).ready(function () {
            $('body').on('click', '.headerInf', function () {
                setShow(false);
            })
        })
    })

    // useEffect(() => {

    //     if (isMobile == "desktop") {
    //         nameInputDesk.current.focus();
    //     }

    //     console.log("nameInput desk: ", nameInputDesk.current)
    // }, [])

    return (
        <div className="moduleSearchAutocomplete">

            {isMobile
                ? isMobile === 'desktop'
                    ? null
                    : isMobile === 'movil'
                        ? <button onClick={regresar} className="searchBack" aria-label="boton regresar"></button>
                        : null
                : null
            }

            <form onSubmit={SearchText} className="search">
                <label className="line">
                    <input
                        type="search"
                        value={typeSearch}
                        className="input"
                        onChange={SearchChange}
                        onKeyDown={handleKeyMove}
                        // onChange={handleSearcherInputChange}
                        onKeyDown={handleSearcherInputKeyDown}
                        onFocus={(e) => handleFocusSearcherInput(e)}
                        // onBlur={handleBlurSearcherInput}
                        name="search"
                        placeholder="¿Qué es lo que buscas?"
                        aria-label="Campo de busqueda"
                        autoComplete="off"
                        id="inputBusqueda"
                        ref={nameInputDesk}
                    />

                    <button
                        className="btn rojo button"
                        value={typeSearch}
                        onClick={(e) => guardarTexto(e)}
                        aria-label="boton buscar"
                    // onClick={(e) => hiddenAutocomplete(e)}
                    ></button>
                </label>


                <div className="listado" style={{ display: 'none' }}>
                    <HistorySearch buscar={show} sebusco={palabraBuscada} setTypeSearch={setTypeSearch} onFocus={focus} name="historial" />
                </div>
                {/* {console.log("-------->  arrayBusquedas.length != 0 && inputVacio === true && show === true: ", arrayBusquedas.length, " - ", inputVacio, " - ", show)} */}
                {isMobile
                    ? isMobile === 'desktop'
                        ? (arrayBusquedas.length != 0 && inputVacio === true && show === true)
                            ? <HistorySearch buscar={show} sebusco={palabraBuscada} setTypeSearch={setTypeSearch} onFocus={focus} name="historial" />
                            :
                            (autocomplete &&
                                <ul className="contAutocomplete" onFocus={focus} name="sugerencias">
                                    {totalArray.map(({ clase, nombre }, ind) =>
                                        <li key={ind} className="searcher-suggs-word" onMouseOver={handleHoverSearcherSuggestions} >
                                            {String(clase) === "word_item"
                                                ? <>
                                                    <button
                                                        onClick={(e) => hiddenAutocomplete(e, clase)}
                                                        // id={`/resultados?query=${encodeURIComponent(ClearTextLink(nombre))}`}
                                                        className={clase}
                                                        value={ClearTextLink(nombre)}>
                                                        {ClearTextLink(nombre)}
                                                    </button>
                                                    {/* <button className="ir" onClick={(e) => ReplicarEnBuscador(e)} value={ClearTextLink(nombre)}></button> */}
                                                </>

                                                // : String(clase) === "category_item"
                                                //     ? <>
                                                //         <button className={clase}
                                                //             onClick={(e) => hiddenAutocomplete(e, clase)}
                                                //             value={ClearTextLink2(nombre)}
                                                //             id={`/categoria/${nombre.split('/')[2]}/${nombre.split('/')[3]}`}
                                                //         // name={ClearTextLink2(nombre)} // id={ClearTextLink2(nombre)}
                                                //         >
                                                //             {ClearTextLink2(nombre)}
                                                //         </button>
                                                //         {/* <button className="ir" onClick={(e) => ReplicarEnBuscador(e)} value={ClearTextLink2(nombre)}></button> */}
                                                //     </>
                                                // : String(clase) === "store_item"
                                                //     ? <>
                                                //         {/* <Link className={clase} onClick={(e) => hiddenAutocomplete(e)} value={ClearTextLink2(nombre)}
                                                //                     // to={`/tienda/${nombre.split('/')[2]}/${nombre.split('/')[3]}`}
                                                //                     name={ClearTextLink2(nombre)}id={ClearTextLink2(nombre)}>
                                                //                     {ClearTextLink(nombre)}  </Link> */}
                                                //         <button className={clase}
                                                //             onClick={(e) => hiddenAutocomplete(e, clase)}
                                                //             value={ClearTextLink2(nombre)}
                                                //             id={`/tienda/${nombre.split('/')[2]}/${nombre.split('/')[3]}`}>
                                                //             {/* {ClearTextLink2(nombre)} */}
                                                //             {nombre.split("/")[3].replace(/-/g, " ")}
                                                //         </button>
                                                //         {/* <button className="ir" onClick={(e) => ReplicarEnBuscador(e)} value={ClearTextLink2(nombre)}></button> */}
                                                //     </>
                                                : String(clase) === "disclaimerNotice" && String(nombre) !== "Por categoría"
                                                    ? <span className={clase}>{ClearTextLink(nombre)} </span>
                                                    : null
                                            }
                                        </li>
                                    )}
                                </ul>
                            )
                        : arrayBusquedas.length > 0 && inputVacio === true
                            ? <HistorySearch
                                buscar={show}
                                sebusco={palabraBuscada}
                                setTypeSearch={setTypeSearch}
                                // onFocus={focus}
                                name="historial" />
                            : (autocomplete &&
                                <AutocompleteComponent
                                    totalArray={autocomplete}
                                    //totalArray={totalArray}
                                    focus={focus}
                                    handleHoverSearcherSuggestions={handleHoverSearcherSuggestions}
                                    hiddenAutocomplete={hiddenAutocomplete}
                                    ClearTextLink={ClearTextLink}
                                    ClearTextLink2={ClearTextLink2}
                                    ReplicarEnBuscador={ReplicarEnBuscador}
                                />
                            )
                    : null
                }
            </form>
        </div>
    )
}