import React, { useState } from 'react'
import './style.sass'
import ModuleShopData from 'components/ProductDetail/ShopProduct/ModuleShopData'
import ModuleShopBuy from 'components/ProductDetail/ShopProduct/ModuleShopBuy'

export default function ShopProductCart({ ...props }) {
    const {
        id,
        title,
        store,
        discount,
        price,
        sale_price,
        size_color,
        tallaColor,
        is_digital,
        stock,
        updateStockTC,
        status,
        cyr,
        reviews,
        dataImage,
        isfree,
        fullfilment,
        brand,
        category,
        is_store_only,
        stockSizeColorSKU,
        stockSizeColorVariant,
        ifShopBuy,
        skuChildren,
        changeDirectionBar,
        skuChildrenFF,
        longitudDeliveryDate,
        payment_methods,
        setTallaText,
        setColorText,

        colorText,
        tallaText,
        promoDepart,
        catPadre,
        catHija_Hombre,
        catHija_Mujer,
        catHija_Nino,
        catHija_Nina,
        tallasNino,
        tallasNina,
        tallasMujer,
        tallasHombre,
        SKUsServicioTecnico,
        cex,
        super_express,
        ean,
        attributes,
        libro,
        last_modified
    } = props

    const [updateFF, setUpdateFF] = useState()
    const stockSizeColorSKUFF = (val) => {
        // console.log(" funcion stock size color sku ff---> ", val)
        setUpdateFF(val)
    }
    const [sizeColorSKUprincipal, setSizeColorSKUprincipal] = useState();

    return (
        <div className="moduleShopCardProduct">

            <ModuleShopData
                id={id}
                title={title}
                store={store}
                brand={brand}
                discount={discount}
                price={price}
                sale_price={sale_price}
                is_digital={is_digital}
                reviews={reviews}
                isfree={isfree}
                fullfilment={fullfilment}
                stock={stock}
                updateStockTC={updateStockTC}
                updateFF={updateFF}
                status={status}
                skuChildren={skuChildren}
                changeDirectionBar={changeDirectionBar}
                skuChildrenFF={skuChildrenFF}

                dataImage={dataImage}
                category={category}
                super_express={super_express}
                ean={ean}
                libro={libro}
                attributes={attributes}
            />

            <ModuleShopBuy
                id={id}
                size_color={size_color}
                tallaColor={tallaColor}
                stock={stock}
                cyr={cyr}
                status={status}
                title={title}
                price={price}
                sale_price={sale_price}
                dataImage={dataImage}
                stockSizeColorSKU={stockSizeColorSKU}
                stockSizeColorSKUFF={stockSizeColorSKUFF}
                stockSizeColorVariant={stockSizeColorVariant}
                ifShopBuy={ifShopBuy}
                is_digital={is_digital}
                brand={brand}
                category={category}
                is_store_only={is_store_only}
                payment_methods={payment_methods}
                skuChildren={skuChildren}
                changeDirectionBar={changeDirectionBar}
                skuChildrenFF={skuChildrenFF}
                longitudDeliveryDate={longitudDeliveryDate}
                fullfilment={fullfilment}
                isfree={isfree}
                updateStockTC={updateStockTC}
                updateFF={updateFF}
                skuProducto={skuChildren}
                setTallaText={setTallaText}
                setColorText={setColorText}
                store={store}
                discount={discount}

                colorText={colorText}
                tallaText={tallaText}
                setSizeColorSKUprincipal={setSizeColorSKUprincipal}

                promoDepart={promoDepart}
                catPadre={catPadre}
                catHija_Hombre={catHija_Hombre}
                catHija_Mujer={catHija_Mujer}
                catHija_Nino={catHija_Nino}
                catHija_Nina={catHija_Nina} 
                tallasNino={tallasNino} 
                tallasNina={tallasNina} 
                tallasMujer={tallasMujer} 
                tallasHombre={tallasHombre}
                SKUsServicioTecnico={SKUsServicioTecnico}
                cex={cex}
                super_express={super_express}
                libro={libro}
                last_modified={last_modified}
            />

        </div>
    )
}