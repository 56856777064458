import {BASE_PATH_API, TIMEOUT_TIME, STAGE_DIRNAME, BASE_PATH_API_DEV} from 'constants/general'
import axios from 'axios'


//- Get Home
export async function getHome () {
    const apiURL = `${BASE_PATH_API}/app/ApiRest/Home`

    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
        }
    }

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        let errorMsg = "Network Error"
        if(error.request.status !== 0){
            errorMsg = error.response.data.msg
        }
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': errorMsg
        }
    })
}

export async function getHomeNew () {
    let apiURL = null

    if(STAGE_DIRNAME === "dev"){
        apiURL = `${BASE_PATH_API_DEV.home}/management/v2/home-app`
    }else{
        apiURL = `${BASE_PATH_API}/management/v2/home-app`
    }

    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
        }
    }

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        let errorMsg = "Network Error"
        if(error.request.status !== 0){
            errorMsg = error.response.data.msg
        }
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': errorMsg
        }
    })
}