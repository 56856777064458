import React, { useState, useEffect, Fragment } from 'react';
import './style.sass';
import { decodeStorage } from 'functions/storageBase64';
import { Link, useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import Slider2 from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getlistProductsPixel } from 'services/pixel';
// import postProductCart from 'services/postProductCart';
import { useIsMobile } from 'hooks/useIsMobile';
import { getCleanedString } from 'functions/getCleanedString';
import { HIDDEN_DISCOUNT, NO_IMAGE } from "constants/general";
import CurrencyFormat from "react-currency-format";
import Loading from 'components/Loading';

import { useCart } from 'hooks/useCart'

export default function HistorialNav(props) {
    const { isMobile } = useIsMobile();
    const {
        respHomeV3
    } = props;

    const history = useHistory();
    const isLoged = decodeStorage('session_token');
    //const [style, setStyle] = useState(false);
    const [rawPixelProducts, setRawPixelProducts] = useState({});
    const [pixelProducts, setPixelProducts] = useState({});
    const [addCartProduct, setAddCartProduct] = useState(false);
    const [idp, setId] = useState('');
    const [order, setOrder] = useState(null);
    const [addCart, setAddCart] = useState('');
    const [responseCart, SetResponseCart] = useState(false);
    const sendProductToCart = { "id_product": idp, "children_sku": 0, "quantity": 1 };
    const cyr = false; // variable de prueba para cuando producto trae CYR
    let itemSlider = respHomeV3;
    const [loading, setLoading] = useState(false);

    const { 
        carrito,
        okAdd,
        Product_Add
    } = useCart({firstActive: false, name: "historyHome", timeRef: 1000})

    const settings = {
        dots: false,
        autoplay: false,
        infinite: false,
        centerMode: false,
        swipe: true,
        touchMove: true,
        slidesToShow: 5,
        adaptiveHeight: true,
        speed: 700,
        responsive: [
            {
                breakpoint: 980,
                settings: {
                    autoplay: false,
                    infinite: false,
                    centerMode: false,
                    swipe: true,
                    touchMove: true,
                    adaptiveHeight: true,
                    slidesToShow: 3,
                    speed: 700,
                }
            },
            {
                breakpoint: 780,
                settings: {
                    autoplay: false,
                    infinite: false,
                    centerMode: false,
                    swipe: true,
                    touchMove: true,
                    adaptiveHeight: true,
                    slidesToShow: 2,
                    speed: 700,
                }
            },
            {
                breakpoint: 580,
                settings: {
                    autoplay: false,
                    infinite: false,
                    centerMode: false,
                    swipe: true,
                    touchMove: true,
                    adaptiveHeight: true,
                    slidesToShow: 1,
                    speed: 700,
                }
            }
        ]
    };

    const settingsMovil = {
        dots: false,
        autoplay: false,
        infinite: false,
        centerMode: false,
        swipe: true,
        touchMove: true,
        slidesToShow: 3,
        adaptiveHeight: true,
        speed: 700,
        arrows: false
    }

    useEffect(() => {
        if(isLoged){
            if (rawPixelProducts?.statusCode >= 200 && rawPixelProducts?.statusCode <= 299) {
                pixelProducts.products.map(({ id, title }, ind) => {
                    if (ind === 0) { setId(id); }
                })
            }
        }
    }, [])

    useEffect(() => {
        if (respHomeV3) {
            respHomeV3.map(({ globalOrder, style }, index) => {
                if (style === 'h') {
                    setOrder(globalOrder);
                    if(isLoged){
                        getlistProductsPixel({ section: "Home" })
                        .then(setRawPixelProducts);
                    }
                }
            })
        }
    }, [respHomeV3])

    //- formatea la respuesta del servicio
    useEffect(() => {

        if (rawPixelProducts) {
            if (rawPixelProducts?.statusCode >= 200 && rawPixelProducts?.statusCode <= 299) {
                if (typeof (rawPixelProducts?.data) === 'object') {
                    if (rawPixelProducts?.data) {
                        if (rawPixelProducts?.data?.data) {
                            if(rawPixelProducts?.data?.data[0]?.products){
                                setPixelProducts({
                                    'products': rawPixelProducts?.data?.data[0]?.products,
                                    'total_items': 2
                                })
                            }else{
                                setPixelProducts({
                                    'products': rawPixelProducts?.data?.data,
                                    'total_items': 2
                                })
                            }
                        }
                    }
                }
                else {
                    setPixelProducts({});
                }
            }
            else if (rawPixelProducts?.statusCode !== undefined) {
                setPixelProducts({});              
            }
        }
    }, [rawPixelProducts])

    //se guarda id del primer producto de la lista
    useEffect(()=>{
        if(pixelProducts?.products){
            setId(pixelProducts?.products[0]?.id);
        }
    },[pixelProducts])

    // se activa guardar en carrito
    const sendToCart = () => {
        setAddCart(sendProductToCart);
        setAddCartProduct(true);
        setLoading(true);  
    }

    // ejecuta la funcion cuando el usuario le da comprar
    useEffect(() => {
        if (addCartProduct) {
            setAddCartProduct(false);
        }
    }, [addCartProduct, sendProductToCart])


    useEffect(() => {
        if (addCart) {
            Product_Add([addCart])
            // postProductCart(addCart)
            // .then(SetResponseCart);
        }
    }, [addCart])

    useEffect(()=>{
        // console.log('btn-addToCart add: ', okAdd)
        // console.log('btn-addToCart: ',carrito)
        if(okAdd){
            SetResponseCart(carrito?.data?.data)
        }
    },[okAdd, carrito])

    useEffect(() => {
        if (responseCart) {
            setLoading(false);
            history.push('/carrito/detalle');
        }
    }, [responseCart])


    return (<>
        {loading && <Loading />}

        {(isLoged) && 
        <>
            {respHomeV3 &&
                respHomeV3.map(({ style, items}, index) =>
                    (style === 'h') && 
                    <Fragment key={index}>
                        {(rawPixelProducts?.statusCode >= 200 && rawPixelProducts?.statusCode <= 299 && pixelProducts?.products) &&
                            <section className={`homePosition${order}`}>
                                <div className='moduleCarruselHome'>
                                    {(isMobile === 'desktop' && pixelProducts?.products?.length >= 5) 
                                    ?   <div className='containerHistorial'>
                                            <div className='containerSliderNav'>
                                                <div className='containerCards contRecomendation'>
                                                    <p className='h3'>
                                                        Visto últimamente
                                                        <span className='fontRed'> <Link to={`mi-cuenta/historial-navegacion/`}>Ver más</Link></span>
                                                    </p>

                                                    <Slider {...settings}>
                                                        {pixelProducts?.products?.map(({ id, images, title, price, sale_price, discount, size_color, is_digital, status, stock, shipping, features, brand }, index) =>
                                                                index <= 9 && <div key={id} className="myCard">
                                                                    <div key={id} className="cardNav">
                                                                        <Link to={`producto/${id}/${getCleanedString(title)}`}>
                                                                            <div className="img">
                                                                                {images?.length >= 1 ?
                                                                                    images?.map((i, k) =>
                                                                                        (k === 0) && <img key={k} src={images[k]?.thumbnail+"?scale=350"} alt={getCleanedString(title)} />
                                                                                    )
                                                                                    :
                                                                                    <img src={NO_IMAGE} alt={getCleanedString(title)} />
                                                                                }
                                                                            </div>
                                                                        </Link>
                                                                        <div className='description'>
                                                                            <div className='text'>{title}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                               
                                                            )}
                                                    </Slider>
                                                </div>
                                            </div>
                                        </div>
                                    : (isMobile !== 'desktop') &&
                                        <div className='containerNavHistorialMobile'>
                                            <div className='containerCardMobile'>
                                                {/* pestaña ultimos vistos */}
                                                <Link to={`mi-cuenta/historial-navegacion/`} className='listNav1Mobile'>
                                                    <span>Visto últimamente </span> <span className='iconChevron'></span>
                                                </Link>

                                                {/* card primer producto en el historial */}
                                                {pixelProducts?.products &&
                                                    pixelProducts?.products?.map(({ id, images, title, price, sale_price, discount, size_color, is_digital, status, stock, shipping, features, brand }, ind) =>
                                                        (ind === 0) &&
                                                        <div className={pixelProducts?.products?.length <= 3 ? 'cardNavMobile borderCardMobile' : 'cardNavMobile'} key={ind}>
                                                            <Link to={`producto/${id}/${getCleanedString(title)}`}>
                                                                <div className='imgMobile'>
                                                                    {images?.length >= 1 ?
                                                                        images?.map((i, k) =>
                                                                            (k === 0) && <img src={images[k]?.thumbnail+"?scale=350"} alt={getCleanedString(title)} className="imgHistorial" key={k}/>
                                                                        )
                                                                        :
                                                                        <img src={NO_IMAGE} alt={getCleanedString(title)} className="imgHistorial"/>
                                                                    }
                                                                </div>
                                                            </Link>
                                                            <div className='descriptionMobile'>
                                                                <div className='text'>{title}</div>

                                                                <div className='group'>
                                                                    {sale_price !== price
                                                                     ? <>
                                                                        <CurrencyFormat
                                                                            value={sale_price}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            prefix={'$'}
                                                                            renderText={value => <p className="Price">{value} <span className="mxn"> MXN</span></p>}
                                                                        />
                                                                        <CurrencyFormat
                                                                            value={price}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            prefix={'$'}
                                                                            renderText={value => <p className="beforePrice">{value} <span className="mxn"> MXN</span></p>}
                                                                        />
                                                                        {discount < HIDDEN_DISCOUNT 
                                                                            ? null
                                                                            : <span className={'discount1'}> -{discount}% </span>
                                                                        }
                                                                    </>
                                                                    : <>
                                                                        <CurrencyFormat
                                                                            value={price}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            prefix={'$'}
                                                                            renderText={value => <p className="Price">{value} <span className="mxn"> MXN</span></p>}
                                                                        />
                                                                    </>
                                                                    }
                                                                </div>
                    
                                                                <div className='groupFeatures'>
                                                                    {/* tiene cyr */}
                                                                    {(features.cyr === true) &&
                                                                        <div className='bxCYR'> </div>
                                                                    }

                                                                    {/* condicion para mostrar barra de sepacion */}
                                                                    {(shipping?.is_free === true && features.cyr === true) &&
                                                                        <div className='borderL'></div>
                                                                    }

                                                                    {/* copy envio gratis */}
                                                                    {(shipping?.is_free === true) 
                                                                        ? <div className='sendFree'> ENVÍO GRATIS </div>
                                                                        : null
                                                                    }

                                                                    {/* copy express */}
                                                                    {(features?.super_express === true) 
                                                                        ? <div className='fullFilment'></div>
                                                                        : null
                                                                    }

                                                                   
                                                                </div>
                                                            </div>

                                                            {/* valida que el producto tenga stock y que no sea color/talla para mostrar AGREGAR A LA BOLSA */}
                                                            { stock && status 
                                                                ? size_color?.length > 0
                                                                    ? null
                                                                    : <div className='containerBtn'>
                                                                        <button className='button' onClick={sendToCart}>Agregar a la bolsa</button>
                                                                    </div>
                                                                : null
                                                            }

                                                            {/* MUESTRA btn historial de navegacion cuando tiene 3 o menos productos  */}
                                                            {pixelProducts?.products?.length <= 3 
                                                                ? <Link to={`mi-cuenta/historial-navegacion/`} className="listNav2MobileSinBorde">
                                                                    <span>Ver historial de navegación</span> <span className='iconChevronRed'></span>
                                                                </Link>
                                                                : null
                                                            }
                                                        </div>

                                                    )}

                                                {/* slider para cuando tiene mas de 3 */}
                                                <Slider2 {...settingsMovil}>
                                                    {pixelProducts?.products?.map(({ id, images, title, price, sale_price, discount, size_color, is_digital, status, stock, shipping, features, brand }, index) =>
                                                        (index >= 1 && index <= 9) && 
                                                        <div className="myCardMobile" key={index}>
                                                            <div className="cardNavMobileSlider">
                                                                <Link to={`producto/${id}/${getCleanedString(title)}`}>
                                                                    <div className="img">
                                                                        {images?.length >= 1 ?
                                                                            images?.map((i, k) =>
                                                                                (k === 0) && <img key={k} src={images[k]?.thumbnail+"?scale=350"} alt={getCleanedString(title)} />
                                                                            )
                                                                            :
                                                                            <img src={NO_IMAGE} alt={getCleanedString(title)} />
                                                                        }
                                                                    </div>
                                                                </Link>
                                                                <div className='descriptionSlider'>
                                                                    <div className='text'>{title}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Slider2>

                                                {/* MUESTRA btn historial de navegacion cuando tiene 4 o mas productos  */}
                                                {pixelProducts?.products?.length >=  4 
                                                    ? <Link to={`mi-cuenta/historial-navegacion/`} className="listNav2Mobile">
                                                        <span>Ver historial de navegación</span> <span className='iconChevronRed'></span>
                                                    </Link>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </section>
                        }
                    </Fragment>
                )
            }
        </>}
    </>)
}
