import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Header from 'pages/Header/HeaderNavegacion';
import Footer from 'pages/Footer/FooterDesktop';
import { VistoslistTemplateDesktop } from 'pages/MiCuenta/Misproductosvistos/VistosDesktop';
import { VistoslistTemplateMobile } from 'pages/MiCuenta/Misproductosvistos/VistosMobile';
import BarraCP from 'components/BarCP/BarHeaderCP';
import Loading from 'components/Loading';

import { getlistProductsPixel, deletePixelProduct } from 'services/pixel';
import { useIsMobile } from 'hooks/useIsMobile';
import { decodeStorage, encodeStorage } from 'functions/storageBase64';
import { useValidateSession } from 'hooks/useValidateSession';
import { useToken } from 'hooks/useToken';

import { useCart } from 'hooks/useCart'

export default function PixelVistos() {

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'My Account | My history products view Page');
    }, [])

    const { isMobile } = useIsMobile();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [rawPixelProducts, setRawPixelProducts] = useState({});
    const [pixelProducts, setPixelProducts] = useState({}); 
    const [removeProduct, setRemoveProduct] = useState(false);              
    const [addCart, setAddCart] = useState('');
    const [responseCart, SetResponseCart] = useState(false);
    const [errorText, setErrorText] = useState('');

    const { 
        carrito,
        okAdd,
        Product_Add
    } = useCart({firstActive: false, name: "historialNavegacion", timeRef: 1000})

        
    /*  --------------------------- Inicio --------------------------------------  */
        //  Hooks para validar tiempo y refresh token mas state de boton
        const {checkValidateSession, atExp, actTime} = useValidateSession();
        const refreshCyR = useToken({firstActive: false, name: "wishlist", timeRef: 1000});
        const [actionOK, setActionOk] = useState(false);
    /*  --------------------------- Fin --------------------------------------  */
        
    useEffect(() => {        
        if (removeProduct) {
            checkValidateSession(true);
            setActionOk('remove');
        }else{
            setTimeout(function(){
            checkValidateSession(true);
            setActionOk('ready');
            },700)
        } 
    }, [removeProduct])    

    /*  --------------------------- Inicio --------------------------------------  */
    //  Ajusta Los tiempos y refresca token
    const [serviceRT, setServiceRT] = useState(false);
    const [uD, setUD] = useState(false);
    useEffect(() => {
        if(actionOK){
            let  actTimeLS = Date.now();
            let  atExpLS = decodeStorage('access_token_exp') * 1000;
            setUD(false);
                            
            if(actTimeLS <= atExpLS || Date.now() <= decodeStorage('access_token_exp') * 1000 ){
                setServiceRT(true);
            }
            else{
                setTimeout(function(){
                    refreshCyR.forceToken(true);
                },300)
                setTimeout(function(){
                    setUD(true);
                },900)
            }
        }
    }, [uD, actionOK])
    /* -------------------------------------------------------------------------- */
    // hace la peticion a servicio
    useEffect(() => {
        if(actionOK === 'ready'){
            setServiceRT(false);
            setLoading(true);                
            getlistProductsPixel({section:"general"})
            .then(setRawPixelProducts);
            setActionOk(false);
            //setTimeout(function(){
            //    setLoading(false)
            //},2000)
            // setLoading(false);
        }      
        else if(actionOK === 'remove'){
            setServiceRT(false);
            deletePixelProduct({ idProduct: removeProduct })                
            .then(setRawPixelProducts);
            setActionOk(false);
            setLoading(true);
        } 
    }, [serviceRT])
    /*  ----------------------------- Fin --------------------------------------  */


    //- formatea la respuesta del servicio
    useEffect(() => {     

        if (rawPixelProducts) {            
            if (rawPixelProducts?.statusCode >= 200 && rawPixelProducts?.statusCode <= 299) {
                if (typeof (rawPixelProducts?.data) === 'object') {
                    if (rawPixelProducts?.data) {
                        if (rawPixelProducts?.data?.data) {
                            setLoading(false);
                            setPixelProducts({
                                'products': rawPixelProducts?.data?.data,
                                'total_items': 2
                                //'total_items': rawPixelProducts?.data?.data?.total_items
                            })
                        }
                    }
                }              
                else {
                    setPixelProducts({});
                    setErrorText(rawPixelProducts?.data);
                    // setLoading(false);
                    setTimeout(function(){
                        setLoading(false)
                    },2000)
                }
            }
            else if (rawPixelProducts?.statusCode !== undefined ) {
                setPixelProducts({});
                setErrorText(rawPixelProducts?.data.metadata?.message);
                // setLoading(false);
                setTimeout(function(){
                    setLoading(false)
                },2000)
                //console.log("entra else, ", rawPixelProducts.data.metadata.message )                
            }
        }
    }, [rawPixelProducts])


    // remueve loading una vez que carga o actualiza
    // useEffect(() => {
    //     if (pixelProducts?.total_items >= 1) {
    //         setLoading(false);
    //     }
    //     else if (pixelProducts?.total_items === 0) {
    //         setLoading(false);
    //     }
    // }, [pixelProducts])

    // funcion que ejecuta eliminar del historial
    const updateDelete = (val) => {
        setRemoveProduct(val);
        setLoading(true);        
    }
 
    // refresca listado de historial al eliminar un producto
    useEffect(()=>{
        if( rawPixelProducts?.data?.message === 'Delete item' && rawPixelProducts?.data?.data === null){
            setActionOk('ready');
            setServiceRT(true);
        }
    },[rawPixelProducts])


    useEffect(() => {
        if (addCart) {
            Product_Add([addCart])
            // postProductCart(addCart)
            // .then(SetResponseCart);
        }
    }, [addCart])

    useEffect(()=>{
        // console.log('btn-addToCart add: ', okAdd)
        // console.log('btn-addToCart: ',carrito)
        if(okAdd){
            SetResponseCart(carrito?.data?.data)
        }
    },[okAdd, carrito])

    // redirecciona a carrito
    useEffect(() => {
        if (responseCart) {
            history.push('/carrito/detalle');
        }
    }, [responseCart])

    
    const addToCart = (val) => {
        setAddCart(val);
        setLoading(true);
    }

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const reciveDirection = (val) => {
        setUpdateCPBar(val);
    }

    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    const arrayPixel = [];
    useEffect(() => {
        if (pixelProducts) {
            if (pixelProducts.products) {
                pixelProducts.products.map(cosa =>
                    arrayPixel.push(String(cosa.id))
                )
            }
        }
        encodeStorage('productsVistoslist', arrayPixel);
    }, [pixelProducts])

    //recibe la direccion del CP   
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPostal] = useState(codigoP);
    const changeDirectionBar = (val) => {
        setCodigoPostal(val);
        setUpdateCPBar(val);
    };

    return (
        <>
            {loading && <Loading />}

            <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection} headerEnCategoria={"headerListaDeseos"} tituloPagina="Historial de Navegación" />
            
            {isMobile && isMobile !== 'desktop' && isMobile !== 'movile'
                ? <div className="barraCpTienda wlbarra">
                    <section className="barCpResponsive">
                        <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
                    </section>
                </div>
                : null
            }

            {isMobile
                ? isMobile === 'desktop'
                    ? <>
                        <section className="barCpResponsive">
                            <BarraCP changeCP={reciveDirection} clase="barHeaderCP" />
                        </section>
                        <VistoslistTemplateDesktop
                            rawPixelProducts={rawPixelProducts}
                            pixelProducts={pixelProducts}
                            errorText={errorText}
                            setLoading={setLoading}  
                            updateDelete={updateDelete}                      
                            addToCart={addToCart}
                        />
                    </>
                    : <VistoslistTemplateMobile
                            rawPixelProducts={rawPixelProducts}
                            pixelProducts={pixelProducts}
                            errorText={errorText}
                            setLoading={setLoading}
                            updateDelete={updateDelete}
                            addToCart={addToCart}
                        />
                : null
            }
            
            <Footer />
        </>
    )
}