import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuPoliticas } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { Link, useHistory } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';

export function AvisoPrivacidadProspectoCreditoDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad prospecto de credito Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuPoliticas />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Aviso de Privacidad Prospecto de Crédito</p>
                            </div>
                        </section>


                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Quién dará tratamiento a tus datos personales?</h2>
                                <p>Sanborn Hermanos, S. A. de C. V. (en adelante “Sanborns”) con domicilio en Lago Zúrich 245, edificio Presa Falcón piso 7, colonia Ampliación Granada, alcaldía Miguel Hidalgo, C.P. 11529, México, Ciudad de México, será el responsable del tratamiento de sus datos personales.</p>
                                <p>Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos de Sanborns a la dirección electrónica <a href='mailto:arco@sanborns.com.mx' className='link'>arco@sanborns.com.mx</a></p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Qué información vamos a recabar?</h2>
                                <p>Sanborns para cumplir con las finalidades señaladas en el presente Aviso recabará y tratará datos personales de identificación, incluyendo su imagen personal que podrá ser registrada a través de vídeos y/o fotografías cuando ingresa a nuestras sucursales, datos biométricos (huella dactilar y características de su rostro), datos personales de contacto, datos fiscales, datos patrimoniales y/o financieros. Le informamos que para efectos de lo señalado en el presente aviso de privacidad Sanborns no tratará datos personales sensibles.</p>
                                <p>Sanborns verificará que los datos personales contenidos en la credencial para votar que usted presente, incluyendo sus datos biométricos coincidan con los que obran en poder del Instituto Nacional Electoral (INE).</p>
                                <p>Asimismo, le informamos que para efectos de lo señalado en el presente Aviso de Privacidad Sanborns dará tratamiento a datos personales de terceros en calidad de referencias personales, por lo que al proporcionar los datos personales de dichos terceros para que Sanborns les contacte por medios físicos y/o electrónicos usted reconoce haber recabado el consentimiento requerido en términos de la normatividad aplicable.</p>
                                <p>En caso de que usted nos contacte por redes sociales, podremos recabar los datos de su perfil público de redes sociales para darle atención a sus peticiones y/o solicitudes de información en relación con los productos que ofrecemos, podremos dar tratamiento a la información contenida en su perfil público de la red social de que se trate, así como al contenido de sus publicaciones.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>No tratamiento de datos personales de menores de edad. </h2>
                                <p>Sanborns no recaba datos personales o información directamente de menores de edad. En el supuesto de que se proporcionen datos relacionados con personas menores de edad asumiremos que la persona que los proporciona es el padre, madre y/o tutor legal, quien reconoce haber leído y aceptado el presente aviso de privacidad.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>Tratamiento de datos sin decisión humana valorativa y actividades de perfilamiento.</h2>
                                <p>Le informamos que para dar cumplimiento a las finalidades previstas en este Aviso de Privacidad Sanborns realizará tratamientos de datos personales como parte de un proceso de toma de decisiones sin intervención humana valorativa y técnicas de análisis masivo de datos para realizar actividades de perfilamiento a partir de sus hábitos de consumo, gustos e intereses, monitorear su comportamiento y ofrecerle una experiencia personalizada en relación con el uso de los servicios, así como para dar cumplimiento a algunas(s) de las finalidades(es ) informadas en el presente Aviso de Privacidad.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Para qué fines usamos tu información?</h2>
                                <p>Sanborns tratará sus datos personales para las siguientes finalidades primarias y necesarias: </p>

                                <ul className="ulQuestionDisc">
                                    <li>Analizar si usted cumple con los requisitos necesarios para el otorgamiento del crédito solicitado;</li>
                                    <li>Realizar consultas, investigaciones y revisiones en relación con su comportamiento crediticio en Sociedades de Información Crediticia; </li>
                                    <li>Verificar y confirmar su identidad, incluyendo realizar procesos de verificación de datos mediante el uso de datos biométricos en cumplimiento a la normatividad aplicable;</li>
                                    <li>Acreditar, validar y verificar su identidad. Lo anterior incluye la verificación de los datos contenidos en su Credencial para Votar y datos biométricos (similitud de huellas dactilares y características del rostro) ante los registros del Instituto Nacional Electoral;</li>
                                    <li>Solicitar referencias personales sobre usted;</li>
                                    <li>Informarle sobre el estatus de su solicitud;</li>
                                    <li>Requerirle información adicional en caso de que ello resulte necesario para tramitar su solicitud; Requerirle información adicional en caso de que ello resulte necesario para tramitar su solicitud; </li>
                                    <li>Otorgamiento de créditos;</li>
                                    <li>En caso de que usted no cumpla con los requisitos establecidos por Sanborns para el otorgamiento del crédito solicitado, le informamos que sus datos serán cancelados después de tres años;</li>
                                    <li>Para alimentar la base de datos del buró de crédito;</li>
                                    <li>Atender sus solicitudes de información, dudas y/o reclamaciones en relación con el otorgamiento del crédito solicitado o cualquier asunto que resulte de nuestra competencia;</li>
                                    <li>Para contactar a través de llamadas telefónicas, envío de comunicaciones a través de correos electrónicos, mensajes cortos y demás medios de comunicación físicos y/o electrónicos, servicios de mensajería instantánea (WhatsApp, Telegram, entre otros que Sanborns considere convenientes). Lo anterior, para realizar actividades de contacto con el propósito de brindarle información sobre los Servicios que ofrecemos, informarle sobre cualquier beneficio al que se hubiere hecho acreedor como consecuencia de los mismos y dar cumplimiento a las obligaciones que usted haya adquirido con nosotros;</li>
                                    <li>Identificarlo como visitante en nuestras sucursales. Si usted ingresa a nuestras instalaciones se entenderá que otorga su consentimiento expreso para que podamos dar tratamiento a su imagen;</li>
                                    <li>Dar atención a sus solicitudes de información, quejas o reclamaciones;</li>
                                    <li>Dar cumplimiento a las obligaciones derivadas de la relación jurídica con usted;</li>
                                    <li>Dar cumplimiento a las políticas y procedimientos de carácter interno;</li>
                                    <li>Dar cumplimiento a obligaciones previstas en la normatividad aplicable, incluyendo la atención de requerimientos debidamente fundados y motivados emitidos por autoridades competentes.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Qué otros usos podemos darle a tu información?</h2>
                                <p>De manera adicional, si usted no se opone, Sanborns podrá mantener su información personal para las siguientes finalidades que no son necesarias, pero que nos permiten y facilitan brindarle una mejor atención:</p>

                                <ul className="ulQuestionDisc">
                                    <li>Informarle del lanzamiento o cambios de nuevos productos, bienes, servicios, promociones y/u ofertas de acuerdo a sus intereses, incluyendo el envío de comunicaciones a través de correos electrónicos, llamadas telefónicas, mensajes cortos y demás medios de comunicación físicos y/o electrónicos; </li>
                                    <li>Posibles contactos posteriores para ofrecimiento de productos, bienes y/o servicios que ofrezca Sanborns y/o Empresas del Grupo;</li>
                                    <li>Compartir sus perfiles y hábitos de consumo con nuestros socios comerciales y/o Empresas del Grupo para que éstas puedan contactarle y enviarle información de carácter publicitario, promocional y/o informativo que consideren de su interés y/o para que puedan valorar, conforme a sus procedimientos internos, si usted es susceptible de obtener el servicio que hubiere solicitado ante dicho tercero; </li>
                                    <li>Realizar estadísticas, encuestas o estudios sobre hábitos de mercado y comportamiento que tengan por finalidad evaluar la calidad de los servicios, bienes, productos y/o experiencia de compra;</li>
                                    <li>Para enviarle publicidad y comunicaciones con fines de mercadotecnia, tele-marketing entre otras.</li>
                                </ul>

                                <p>En caso de que no desee que sus datos personales sean tratados para alguna o todas las finalidades adicionales, desde este momento usted nos puede comunicar lo anterior al correo <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>
                                <p>Adicionalmente, te informamos que tú puedes en todo momento cancelar el envío de mensajes promocionales haciendo clic en el enlace Cancelar Suscripción que está integrado en el pie de página de todos los correos electrónicos enviados por Sanborns.</p>
                                <p>La negativa para el uso de sus datos personales para fines adicionales, no podrá ser un motivo para negarle los servicios solicitados o dar por terminada la relación establecida con nosotros.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿A qué terceros podemos transferir tu información?</h2>
                                <p>Sanborns podrá transferir, sin requerir de su consentimiento, los datos personales necesarios a autoridades competentes, tanto nacionales como internacionales, en los casos legalmente previstos, así como a cualquier empresa Subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común, las cuales operan bajo políticas de privacidad que cumplen con las disposiciones aplicables correspondientes, para dar cumplimiento a las finalidades señaladas en el presente aviso.</p>
                                <p>Para conocer cuáles son las Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común a quienes sus datos son transferidos puede consultarlo en http://www.gsanborns.com.mx/perfil-corporativo.html</p>
                                <p>En caso de que las operaciones que usted realice con Sanborns, y las cuales estén clasificadas en Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita, como actividades vulnerables, Sanborns, podrá entregar determinada información de usted sin requerir de su consentimiento a las autoridades fiscales competentes con la finalidad de dar cumplimiento a lo dispuesto por la Ley.</p>
                                <p>Sanborns podrá transferir  sin requerir de su consentimiento los datos personales necesarios  a:  socios comerciales y/o terceros con los que tengamos celebrados contratos de prestación de servicios para prestar servicios de entrega de bienes y productos, postventa, gestionar cuentas y registros de clientes, enviar comunicación relativa a productos o servicios que pueden ser de su interés, programas de lealtad, así como comunicaciones de marketing, con los que tengamos celebrados acuerdos comerciales o de colaboración para fines relacionados con la validación y autenticación de su identidad, conforme a las finalidades descritas en el presente Aviso de Privacidad.</p>
                                <p>En caso de que no desee que sus datos personales sean transferidos para alguna o todas las finalidades señaladas, desde este momento usted nos puede comunicar lo anterior al correo <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Cómo puedes ejercer tus derechos ARCO y/o revocar tu consentimiento?</h2>
                                <p>Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en lo sucesivo “derechos Arco”), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Protección de Datos de Sanborns a la dirección electrónica <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>
                                <p>Su petición deberá ser realizada a través del Formato de Solicitud de Derechos ARCO, el cual debe ser solicitado a la dirección de correo electrónico antes señalada. Para que el Departamento de Protección de Datos de Sanborns pueda darle seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo que es necesario que complete todos los campos indicados en el Formato y lo acompañe con copia de alguna de las identificaciones oficiales vigentes que se señalan en el mismo. En caso de que la información proporcionada sea errónea o insuficiente, o bien, no se acompañen los documentos de acreditación correspondientes, nuestro Departamento de Protección de Datos, dentro de los cinco (5) días hábiles siguientes a la recepción de la solicitud, podrá requerirle que aporte los elementos o documentos necesarios para dar trámite a la misma. Usted contará con diez (10) días hábiles para atender el requerimiento, contados a partir del día siguiente en que lo haya recibido. De no dar respuesta en dicho plazo, se tendrá por no presentada la solicitud correspondiente. Nuestro Departamento de Protección de Datos le comunicará la determinación adoptada, en un plazo máximo de veinte (20) días hábiles contados desde la fecha en que se recibió la solicitud, a efecto de que, si resulta procedente, haga efectiva la misma dentro de los quince (15) días hábiles siguientes a que se comunique la respuesta. Para obtener mayor información sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento contacte al Departamento de Protección de Datos de Sanborns.</p>
                                <p>Le recordamos que, si usted desea eliminar sus datos, deberá promover su derecho de cancelación, en los términos antes descritos.</p>
                                <p>En caso de que su solicitud de cancelación, oposición o revocación sea procedente, según corresponda se dejarán de usar sus datos, para la finalidad que nos hubiere requerido o se eliminarán de nuestros sistemas en términos de lo que establece la legislación de la materia.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Cómo puedes limitar el uso y/o divulgación de tus datos?</h2>
                                <p>Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la dirección <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a>. En caso de que su Solicitud sea procedente se le registrará en el listado de exclusión propio de Sanborns. Para mayor información favor de contactar al Departamento de Datos Personales de Sanborns.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>Uso de cookies y web beacons</h2>
                                <p>Le informamos que Sanborns en nuestro sitio web y/o aplicaciones podemos hacer uso de cookies, web beacons y otras tecnologías de rastreo que nos permiten cumplir con las finalidades informadas en el presente Aviso de Privacidad. Los diferentes tipos de cookies que utilizamos son:</p>

                                <ul className="ulQuestionDisc">
                                    <li><span className='bold'>Cookies esenciales:</span> para permitirle un uso adecuado de nuestro sitio web y/o aplicaciones, por lo que las mismas no pueden ser deshabilitadas.</li>
                                    <li><span className='bold'>Cookies para recordar sus preferencias y experiencia:</span> estas cookies nos permiten recordar sus preferencias, opciones de navegación y funciones personalizadas.</li>
                                    <li><span className='bold'>Cookies para objetivos de rendimiento y análisis: </span> podemos usar cookies propias y de terceros para identificar la forma en la cual usted utiliza nuestros Servicios con la finalidad de mejorar su rendimiento y futuros desarrollos.</li>
                                    <li>Cookies publicitarias propias y de terceros: podemos colocar cookies publicitarias propias y de terceros (como socios comerciales y empresas del grupo) para mostrarle publicidad que consideremos relevante para usted.</li>
                                </ul>

                                <p>Usted puede deshabilitar el uso de cookies, a través de su navegador siguiendo el procedimiento establecido por éste. Aquí alguna información del procedimiento establecido en los siguientes navegadores: </p>

                                <ul className="ulQuestionDisc">
                                    <li>Internet Explorer: Herramientas ➔ Opciones de Internet ➔ Privacidad ➔ Configuración. Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.</li>
                                    <li>Firefox: Herramientas ➔ Opciones ➔ Privacidad y Seguridad. Para más información, puedes consultar el soporte de Mozilla o la Ayuda del navegador.</li>
                                    <li>Chrome: Configuración ➔ Verificación de seguridad y/o ➔ Privacidad y seguridad. Para más información, puede consultar el soporte de Google o la Ayuda del navegador.</li>
                                    <li>Safari: Preferencias ➔ Seguridad. Para más información, puede consultar el soporte de Apple o la Ayuda del navegador.</li>
                                    <li>Edge: Configuración ➔ Privacidad, búsqueda y servicios y/o Permisos del sitio. Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Cómo te notificaremos de los cambios que efectuemos al presente Aviso de Privacidad?</h2>
                                <p>Sanborns le notificará de cualquier cambio a su aviso de privacidad a través de su página de internet <Link to="/">www.sanborns.com.mx</Link> por lo cual le pedimos revise la misma de manera periódica.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Cómo puedes hacernos llegar tus dudas?</h2>
                                <p>Nuestro Departamento de Datos Personales queda a sus órdenes para proporcionarle cualquier información adicional que requiera o, en su caso, para resolver cualquier duda que pudiera surgirle en materia de privacidad y protección de datos personales, para lo que podrá contactarnos a través del correo electrónico <a href='mailto:arco@sanborns.com.mx'>arco@sanborns.com.mx</a></p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Cómo protegemos sus transacciones?</h2>
                                <p>Con el propósito de proteger las transacciones que usted realiza a través de nuestro sitio web, tenemos implementado un servidor seguro bajo el protocolo SSL (Secure Socket Layer) de tal menara que la información que nos envía, se transmite encriptada para asegurar su protección.</p>
                                <p>Para verificar que se encuentra en un entorno protegido, asegúrese de que aparezca una "S" en la barra de navegación "httpS"://.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>Recomendaciones de seguridad</h2>
                                <p>Es importante que elija una contraseña única y distinta a la que uses en otros sitios de internet. Asimismo, es de vital importancia que no comparta su contraseña con ningún tercero, si cree que alguien pudiere llegar a tener conocimiento de su contraseña cámbiela de inmediato.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>INAI</h2>
                                <p>En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) para hacer efectivos sus derechos.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>Aceptación de Aviso de Privacidad</h2>
                                <p>Al hacer uso de nuestros servicios y proporcionar sus datos personales, significa que usted ha leído, entendido, y acordado los términos expuestos en el presente Aviso de Privacidad y consiente el tratamiento de sus datos personales según lo previsto en este último. </p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <p><span className='bold'>Fecha de última actualización:</span> Febrero de 2023.</p>
                            </div>
                        </div>

                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}

export function AvisoPrivacidadProspectoCreditoMobile() {
    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad prospecto de credito Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN">
                        <div className="btn closeBack" onClick={onClose} ></div>
                        <p className="title">Aviso de Privacidad Prospecto de Crédito</p>
                    </div>

                    <div className="dataModal">
                        <div className="boxAN">
                            <div className="bxSlider">

                                <p className="pSliderTitleL">¿Quién dará tratamiento a tus datos personales?</p>
                                <p className="pSliderL">Sanborn Hermanos, S. A. de C. V. (en adelante “Sanborns”) con domicilio en Lago Zúrich 245, edificio Presa Falcón piso 7, colonia Ampliación Granada, alcaldía Miguel Hidalgo, C.P. 11529, México, Ciudad de México, será el responsable del tratamiento de sus datos personales.</p>
                                <p className="pSliderL">Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos de Sanborns a la dirección electrónica <a href='mailto:arco@sanborns.com.mx' className='link'>arco@sanborns.com.mx</a></p>

                                <p className="pSliderTitleL">¿Qué información vamos a recabar?</p>
                                <p className="pSliderL">Sanborns para cumplir con las finalidades señaladas en el presente Aviso recabará y tratará datos personales de identificación, datos personales de contacto, datos fiscales, datos patrimoniales y/o financieros. Le informamos que para efectos de lo señalado en el presente aviso de privacidad Sanborns no tratará datos personales sensibles.</p>
                                <p className="pSliderL">Sanborns verificará que los datos personales contenidos en la credencial para votar que usted presente, incluyendo sus datos biométricos coincidan con los que obran en poder del Instituto Nacional Electoral (INE).</p>
                                <p className="pSliderL">Asimismo, le informamos que para efectos de lo señalado en el presente Aviso de Privacidad Sanborns dará tratamiento a datos personales de terceros en calidad de referencias personales, por lo que al proporcionar los datos personales de dichos terceros para que Sanborns les contacte por medios físicos y/o electrónicos usted reconoce haber recabado el consentimiento requerido en términos de la normatividad aplicable.</p>
                                <p className="pSliderL">En caso de que usted nos contacte por redes sociales, podremos recabar los datos de su perfil público de redes sociales para darle atención a sus peticiones y/o solicitudes de información en relación con los productos que ofrecemos, podremos dar tratamiento a la información contenida en su perfil público de la red social de que se trate, así como al contenido de sus publicaciones.</p>

                                <p className="pSliderTitleL">No tratamiento de datos personales de menores de edad. </p>
                                <p className="pSliderL">Sanborns no recaba datos personales o información directamente de menores de edad. En el supuesto de que se proporcionen datos relacionados con personas menores de edad asumiremos que la persona que los proporciona es el padre, madre y/o tutor legal, quien reconoce haber leído y aceptado el presente aviso de privacidad. </p>

                                <p className="pSliderTitleL">Tratamiento de datos sin decisión humana valorativa y actividades de perfilamiento.</p>
                                <p className="pSliderL">Le informamos que para dar cumplimiento a las finalidades previstas en este Aviso de Privacidad Sanborns realizará tratamientos de datos personales como parte de un proceso de toma de decisiones sin intervención humana valorativa y técnicas de análisis masivo de datos para realizar actividades de perfilamiento a partir de sus hábitos de consumo, gustos e intereses, monitorear su comportamiento y ofrecerle una experiencia personalizada en relación con el uso de los servicios, así como para dar cumplimiento a algunas(s) de las finalidades(es ) informadas en el presente Aviso de Privacidad.</p>

                                <p className="pSliderTitleL">¿Para qué fines usamos tu información?</p>
                                <p className="pSliderL">Sanborns tratará sus datos personales para las siguientes finalidades primarias y necesarias</p>
                                <ul className="ulDeclaracionesDisc">
                                <li>Analizar si usted cumple con los requisitos necesarios para el otorgamiento del crédito solicitado;</li>
                                    <li>Realizar consultas, investigaciones y revisiones en relación con su comportamiento crediticio en Sociedades de Información Crediticia; </li>
                                    <li>Verificar y confirmar su identidad, incluyendo realizar procesos de verificación de datos mediante el uso de datos biométricos en cumplimiento a la normatividad aplicable;</li>
                                    <li>Acreditar, validar y verificar su identidad. Lo anterior incluye la verificación de los datos contenidos en su Credencial para Votar y datos biométricos (similitud de huellas dactilares y características del rostro) ante los registros del Instituto Nacional Electoral;</li>
                                    <li>Solicitar referencias personales sobre usted;</li>
                                    <li>Informarle sobre el estatus de su solicitud;</li>
                                    <li>Requerirle información adicional en caso de que ello resulte necesario para tramitar su solicitud; Requerirle información adicional en caso de que ello resulte necesario para tramitar su solicitud; </li>
                                    <li>Otorgamiento de créditos;</li>
                                    <li>En caso de que usted no cumpla con los requisitos establecidos por Sanborns para el otorgamiento del crédito solicitado, le informamos que sus datos serán cancelados después de tres años;</li>
                                    <li>Para alimentar la base de datos del buró de crédito;</li>
                                    <li>Atender sus solicitudes de información, dudas y/o reclamaciones en relación con el otorgamiento del crédito solicitado o cualquier asunto que resulte de nuestra competencia;</li>
                                    <li>Para contactar a través de llamadas telefónicas, envío de comunicaciones a través de correos electrónicos, mensajes cortos y demás medios de comunicación físicos y/o electrónicos, servicios de mensajería instantánea (WhatsApp, Telegram, entre otros que Sanborns considere convenientes). Lo anterior, para realizar actividades de contacto con el propósito de brindarle información sobre los Servicios que ofrecemos, informarle sobre cualquier beneficio al que se hubiere hecho acreedor como consecuencia de los mismos y dar cumplimiento a las obligaciones que usted haya adquirido con nosotros;</li>
                                    <li>Identificarlo como visitante en nuestras sucursales. Si usted ingresa a nuestras instalaciones se entenderá que otorga su consentimiento expreso para que podamos dar tratamiento a su imagen;</li>
                                    <li>Dar atención a sus solicitudes de información, quejas o reclamaciones;</li>
                                    <li>Dar cumplimiento a las obligaciones derivadas de la relación jurídica con usted;</li>
                                    <li>Dar cumplimiento a las políticas y procedimientos de carácter interno;</li>
                                    <li>Dar cumplimiento a obligaciones previstas en la normatividad aplicable, incluyendo la atención de requerimientos debidamente fundados y motivados emitidos por autoridades competentes.</li>
                                </ul>
                             

                                <p className="pSliderTitleL">¿Qué otros usos podemos darle a tu información?</p>
                                <p className="pSliderL">De manera adicional, si usted no se opone, Sanborns podrá mantener su información personal para las siguientes finalidades que no son necesarias, pero que nos permiten y facilitan brindarle una mejor atención:</p>
                                <ul className="ulDeclaracionesDisc">
                                    <li>Informarle del lanzamiento o cambios de nuevos productos, bienes, servicios, promociones y/u ofertas de acuerdo a sus intereses, incluyendo el envío de comunicaciones a través de correos electrónicos, llamadas telefónicas, mensajes cortos y demás medios de comunicación físicos y/o electrónicos; </li>
                                    <li>Posibles contactos posteriores para ofrecimiento de productos, bienes y/o servicios que ofrezca Sanborns y/o Empresas del Grupo;</li>
                                    <li>Compartir sus perfiles y hábitos de consumo con nuestros socios comerciales y/o Empresas del Grupo para que éstas puedan contactarle y enviarle información de carácter publicitario, promocional y/o informativo que consideren de su interés y/o para que puedan valorar, conforme a sus procedimientos internos, si usted es susceptible de obtener el servicio que hubiere solicitado ante dicho tercero; </li>
                                    <li>Realizar estadísticas, encuestas o estudios sobre hábitos de mercado y comportamiento que tengan por finalidad evaluar la calidad de los servicios, bienes, productos y/o experiencia de compra;</li>
                                    <li>Para enviarle publicidad y comunicaciones con fines de mercadotecnia, tele-marketing entre otras.</li>
                                </ul>
                                <p className="pSliderL">En caso de que no desee que sus datos personales sean tratados para alguna o todas las finalidades adicionales, desde este momento usted nos puede comunicar lo anterior al correo <a href='mailto:arco@sanborns.com.mx' className='link'>arco@sanborns.com.mx</a></p>
                                <p className="pSliderL">Adicionalmente, te informamos que tú puedes en todo momento cancelar el envío de mensajes promocionales haciendo clic en el enlace Cancelar Suscripción que está integrado en el pie de página de todos los correos electrónicos enviados por Sanborns.</p>
                                <p className="pSliderL">La negativa para el uso de sus datos personales para fines adicionales, no podrá ser un motivo para negarle los servicios solicitados o dar por terminada la relación establecida con nosotros.</p>

                                <p className="pSliderTitleL">¿A qué terceros podemos transferir tu información?</p>
                                <p className="pSliderL">Sanborns podrá transferir, sin requerir de su consentimiento, los datos personales necesarios a autoridades competentes, tanto nacionales como internacionales, en los casos legalmente previstos, así como a cualquier empresa Subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común, las cuales operan bajo políticas de privacidad que cumplen con las disposiciones aplicables correspondientes, para dar cumplimiento a las finalidades señaladas en el presente aviso.</p>
                                <p className="pSliderL">Para conocer cuáles son las Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común a quienes sus datos son transferidos puede consultarlo en http://www.gsanborns.com.mx/perfil-corporativo.html</p>
                                <p className="pSliderL">En caso de que las operaciones que usted realice con Sanborns, y las cuales estén clasificadas en Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita, como actividades vulnerables, Sanborns, podrá entregar determinada información de usted sin requerir de su consentimiento a las autoridades fiscales competentes con la finalidad de dar cumplimiento a lo dispuesto por la Ley.</p>
                                <p className="pSliderL">Sanborns podrá transferir  sin requerir de su consentimiento los datos personales necesarios  a:  socios comerciales y/o terceros con los que tengamos celebrados contratos de prestación de servicios para prestar servicios de entrega de bienes y productos, postventa, gestionar cuentas y registros de clientes, enviar comunicación relativa a productos o servicios que pueden ser de su interés, programas de lealtad, así como comunicaciones de marketing, con los que tengamos celebrados acuerdos comerciales o de colaboración para fines relacionados con la validación y autenticación de su identidad, conforme a las finalidades descritas en el presente Aviso de Privacidad.</p>
                                <p className="pSliderL">En caso de que no desee que sus datos personales sean transferidos para alguna o todas las finalidades señaladas, desde este momento usted nos puede comunicar lo anterior al correo <a href='mailto:arco@sanborns.com.mx' className='link'>arco@sanborns.com.mx</a></p>

                                <p className="pSliderTitleL">¿Cómo puedes ejercer tus derechos ARCO y/o revocar tu consentimiento?</p>
                                <p className="pSliderL">Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en lo sucesivo “derechos Arco”), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Protección de Datos de Sanborns a la dirección electrónica <a href='mailto:arco@sanborns.com.mx' className='link'>arco@sanborns.com.mx</a></p>
                                <p className="pSliderL">Su petición deberá ser realizada a través del Formato de Solicitud de Derechos ARCO, el cual debe ser solicitado a la dirección de correo electrónico antes señalada. Para que el Departamento de Protección de Datos de Sanborns pueda darle seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo que es necesario que complete todos los campos indicados en el Formato y lo acompañe con copia de alguna de las identificaciones oficiales vigentes que se señalan en el mismo. En caso de que la información proporcionada sea errónea o insuficiente, o bien, no se acompañen los documentos de acreditación correspondientes, nuestro Departamento de Protección de Datos, dentro de los cinco (5) días hábiles siguientes a la recepción de la solicitud, podrá requerirle que aporte los elementos o documentos necesarios para dar trámite a la misma. Usted contará con diez (10) días hábiles para atender el requerimiento, contados a partir del día siguiente en que lo haya recibido. De no dar respuesta en dicho plazo, se tendrá por no presentada la solicitud correspondiente. Nuestro Departamento de Protección de Datos le comunicará la determinación adoptada, en un plazo máximo de veinte (20) días hábiles contados desde la fecha en que se recibió la solicitud, a efecto de que, si resulta procedente, haga efectiva la misma dentro de los quince (15) días hábiles siguientes a que se comunique la respuesta. Para obtener mayor información sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento contacte al Departamento de Protección de Datos de Sanborns.</p>
                                <p className='pSliderL'>Le recordamos que, si usted desea eliminar sus datos, deberá promover su derecho de cancelación, en los términos antes descritos.</p>
                                <p className="pSliderL">En caso de que su solicitud de cancelación, oposición o revocación sea procedente, según corresponda se dejarán de usar sus datos, para la finalidad que nos hubiere requerido o se eliminarán de nuestros sistemas en términos de lo que establece la legislación de la materia.</p>

                                <p className="pSliderTitleL">¿Cómo puedes limitar el uso y/o divulgación de tus datos?</p>
                                <p className="pSliderL">Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la dirección <a href='mailto:arco@sanborns.com.mx' className='link'>arco@sanborns.com.mx</a> En caso de que su Solicitud sea procedente se le registrará en el listado de exclusión propio de Sanborns. Para mayor información favor de contactar al Departamento de Datos Personales de Sanborns.</p>

                                <p className="pSliderTitleL">Uso de cookies y web beacons</p>
                                <p className="pSliderL">Le informamos que Sanborns en nuestro sitio web y/o aplicaciones podemos hacer uso de cookies, web beacons y otras tecnologías de rastreo que nos permiten cumplir con las finalidades informadas en el presente Aviso de Privacidad. Los diferentes tipos de cookies que utilizamos son:</p>
                                <ul className="ulDeclaracionesDisc">
                                    <li><span className='bold'>Cookies esenciales:</span> para permitirle un uso adecuado de nuestro sitio web y/o aplicaciones, por lo que las mismas no pueden ser deshabilitadas.</li>
                                    <li><span className='bold'>Cookies para recordar sus preferencias y experiencia:</span> estas cookies nos permiten recordar sus preferencias, opciones de navegación y funciones personalizadas.</li>
                                    <li><span className='bold'>Cookies para objetivos de rendimiento y análisis:</span> podemos usar cookies propias y de terceros para identificar la forma en la cual usted utiliza nuestros Servicios con la finalidad de mejorar su rendimiento y futuros desarrollos.</li>
                                    <li><span className='bold'>Cookies publicitarias propias y de terceros:</span> podemos colocar cookies publicitarias propias y de terceros (como socios comerciales y empresas del grupo) para mostrarle publicidad que consideremos relevante para usted.</li>
                                </ul>
                                <p className="pSliderL">Usted puede deshabilitar el uso de cookies, a través de su navegador siguiendo el procedimiento establecido por éste. Aquí alguna información del procedimiento establecido en los siguientes navegadores: </p>
                                <ul className="ulDeclaracionesDisc">
                                    <li>Internet Explorer: Herramientas ➔ Opciones de Internet ➔ Privacidad ➔ Configuración. Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.</li>
                                    <li>Firefox: Herramientas ➔ Opciones ➔ Privacidad y Seguridad. Para más información, puedes consultar el soporte de Mozilla o la Ayuda del navegador.</li>
                                    <li>Chrome: Configuración ➔ Verificación de seguridad y/o ➔ Privacidad y seguridad. Para más información, puede consultar el soporte de Google o la Ayuda del navegador.</li>
                                    <li>Safari: Preferencias ➔ Seguridad. Para más información, puede consultar el soporte de Apple o la Ayuda del navegador.</li>
                                    <li>Edge: Configuración ➔ Privacidad, búsqueda y servicios y/o Permisos del sitio. Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.</li>
                                </ul>

                                <p className="pSliderTitleL">¿Cómo te notificaremos de los cambios que efectuemos al presente Aviso de Privacidad?</p>
                                <p className="pSliderL">Sanborns le notificará de cualquier cambio a su aviso de privacidad a través de su página de internet <Link to="/">www.sanborns.com.mx</Link> por lo cual le pedimos revise la misma de manera periódica.</p>

                                <p className="pSliderTitleL">¿Cómo puedes hacernos llegar tus dudas?</p>
                                <p className="pSliderL">Nuestro Departamento de Datos Personales queda a sus órdenes para proporcionarle cualquier información adicional que requiera o, en su caso, para resolver cualquier duda que pudiera surgirle en materia de privacidad y protección de datos personales, para lo que podrá contactarnos a través del correo electrónico <a href='mailto:arco@sanborns.com.mx' className='link'>arco@sanborns.com.mx</a></p>

                                <p className="pSliderTitleL">¿Cómo protegemos sus transacciones?</p>
                                <p className="pSliderL">Con el propósito de proteger las transacciones que usted realiza a través de nuestro sitio web, tenemos implementado un servidor seguro bajo el protocolo SSL (Secure Socket Layer) de tal menara que la información que nos envía, se transmite encriptada para asegurar su protección.</p>
                                <p className="pSliderL">Para verificar que se encuentra en un entorno protegido, asegúrese de que aparezca una "S" en la barra de navegación "httpS"://.</p>

                                <p className="pSliderTitleL">Recomendaciones de seguridad</p>
                                <p className="pSliderL">Es importante que elija una contraseña única y distinta a la que uses en otros sitios de internet. Asimismo, es de vital importancia que no comparta su contraseña con ningún tercero, si cree que alguien pudiere llegar a tener conocimiento de su contraseña cámbiela de inmediato.</p>

                                <p className="pSliderTitleL">INAI</p>
                                <p className="pSliderL">En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) para hacer efectivos sus derechos.</p>

                                <p className="pSliderTitleL">Aceptación de Aviso de Privacidad</p>
                                <p className="pSliderL">Al hacer uso de nuestros servicios y proporcionar sus datos personales, significa que usted ha leído, entendido, y acordado los términos expuestos en el presente Aviso de Privacidad y consiente el tratamiento de sus datos personales según lo previsto en este último. </p>

                                <p className="pSliderL"><span className="bold">Fecha de última actualización:</span> Febrero de 2024.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function AvisoPrivacidadProspectoCredito() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad prospecto de credito');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {isMobile &&
                isMobile === 'desktop'
                ? <AvisoPrivacidadProspectoCreditoDesktop />

                : isMobile === 'movile'
                    ? null
                    : <AvisoPrivacidadProspectoCreditoMobile />
            }
        </>
    )
}