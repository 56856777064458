import React, { useState, useEffect } from 'react'
import './style.sass'
import { decodeStorage, encodeStorage } from 'functions/storageBase64'
import { getDeliveryDate } from 'services/DeliveryDate'
import BarraCP, { BarraCP_Detalle } from 'components/BarCP/BarHeaderCP'
import { Link } from 'react-router-dom'
import { ENVIO_GRATIS, VARIANTS_PER_PORTAL } from 'constants/general';
import { useIsMobile } from 'hooks/useIsMobile';

export function ModuleEnvioGratis(props){
    const {
        sizeColorLongitud,
        changeDirectionBar,
        sizeColorSKU,
        dataShipping,
        tallaColor,
        fullfilment,
        updateFF,
        super_express,
        clickPreseleccionarTalla,
        showExpress,
        isfree,
        sale_price,
        codigoP
    } = props

    return(
        <div className={codigoP ? "moduleEnvioGratis" : "moduleEnvioGratis SinCP"}>
            <div className="moduleShippingDetail">
                <div className={sizeColorLongitud > 0 && !sizeColorSKU ? "int2 mb20" : "int2"}>      
                    {fullfilment || String(updateFF) === "true" || String(super_express) === "true"
                        ? <Link to="#modalExpress" className="fullFilment" onClick={showExpress}>Express</Link>
                        : null
                    }  
                    {((isfree == true) || (Number(sale_price) >= Number(ENVIO_GRATIS)))
                        ? <span className="noIsFree">Envío gratis</span>
                        : null
                    }
                </div>

                <div className="int">
                    {sizeColorLongitud > 0
                        ? sizeColorSKU && sizeColorSKU != null
                            ? !dataShipping
                                ? <div className="cpDetallebox">
                                    <BarraCP_Detalle changeCP={changeDirectionBar} clase="cpDetalle" />
                                </div>

                                : <div className="dateDelivery1">
                                    <BarraCP_Detalle changeCP={changeDirectionBar} clase="cpDelivery" fechaEstimada={dataShipping.date_text} />
                                </div>
                            : <p className="deliverySinTalla" onClick={clickPreseleccionarTalla}>Selecciona
                                {tallaColor && tallaColor[0].color && tallaColor[0].sizes
                                    ? <> color/talla </>
                                    : tallaColor && tallaColor[0].color && !tallaColor[0].sizes
                                        ? <> color </>
                                        : null
                                } para conocer cuando te llega</p>
                        : sizeColorLongitud === 0 && (sizeColorSKU == null || sizeColorSKU === false) && !dataShipping
                            ? <div className="cpDetallebox">
                                <BarraCP_Detalle changeCP={changeDirectionBar} clase="cpDetalle" />
                            </div>
                            : sizeColorLongitud === 0 && (sizeColorSKU == null || sizeColorSKU === false) && dataShipping &&
                            <div className="dateDelivery1">
                                <BarraCP_Detalle changeCP={changeDirectionBar} clase="cpDelivery" fechaEstimada={dataShipping.date_text} />
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default function DeliveryDate({ ...props }) {
    const {
        is_digital,
        id_Product,
        skuChildren,
        stock,
        status,
        changeDirectionBar,
        skuChildrenFF,
        fullfilment,
        updateFF,
        isfree,
        sale_price,
        showExpress,
        sizeColorLongitud,
        sizeColorSKU,
        tallaColor,
        setPreseleccionar,
        colorText,
        super_express,
        libro
    } = props

    // console.log(" tiene talla colr--> ", sizeColorLongitud, " lo seleccino-->", sizeColorSKU, " cambio.. ", colorText)
    // if (tallaColor) {
    //     console.log(" contenido color - talla ------> ", tallaColor[0].color)
    //     // {tallaColor && tallaColor[0].color && tallaColor[0].sizes
    // }
    const { isMobile } = useIsMobile();
    const codigoPostal = decodeStorage('codigoPostal')
    const [dataShipping, getDataShipping] = useState()
    const [deliveryDateResp, setDeliveryDateResp] = useState(false);
    const [mostrarCP, setMostrarCP] = useState(false)
    const [cPostal, setCPostal] = useState(codigoPostal)
    const [tieneValorSeleccionado, setTieneValorSeleccionado] = useState(false);
    const codigoP = decodeStorage('codigoPostal');

    //- Funcion que va por la fecha estimada cuando existe un CP
    useEffect(() => {
        if (codigoPostal) {
            if (skuChildrenFF) {
                getDeliveryDate({ idProduct: id_Product, sku: String(skuChildrenFF), codPostal: codigoPostal })
                    .then(setDeliveryDateResp)
            } else {
                getDeliveryDate({ idProduct: id_Product, sku: skuChildren, codPostal: codigoPostal })
                    .then(setDeliveryDateResp)
            }
        }
    }, [codigoPostal, id_Product, skuChildrenFF])

    useEffect(() => {
        if (deliveryDateResp) {
            getDataShipping(deliveryDateResp.data.shipping)
        }
    }, [deliveryDateResp])

    //cambia valor para mostrar modulo de cp
    const addDeliveryDate = () => {
        setMostrarCP(!mostrarCP);
    }

    const clickPreseleccionarTalla = () => {
        // console.log("hice click-.....")

        setPreseleccionar(true);
    };

    // console.log("TC longitud ----> ", sizeColorLongitud, " TC seleccionado ---->", sizeColorSKU, " cambio color : ", colorText)
    const librof = true;
    return (
        <>
            {is_digital
                ? <div className="moduleIsDigital">
                    <p>Al aprobar el pago enviaremos tu producto por correo electrónico.</p>
                </div>
                : <>
                    {stock >= 1 && status &&
                        <>
                            {/* <div className={codigoP ? "moduleEnvioGratis" : "moduleEnvioGratis SinCP"}>
                                <div className="moduleShipping">
                                    <div className="int">
                                        {sizeColorLongitud > 0
                                            ? sizeColorSKU && sizeColorSKU != null
                                                ? !dataShipping
                                                    ? <div className="cpDetallebox">
                                                        <BarraCP changeCP={changeDirectionBar} clase="cpDetalle" />
                                                    </div>

                                                    : <p className="dateDelivery1">
                                                        <BarraCP changeCP={changeDirectionBar} clase="cpDelivery" fechaEstimada={dataShipping.date_text} />
                                                    </p>
                                                : <p className="deliverySinTalla" onClick={clickPreseleccionarTalla}>Selecciona
                                                    {tallaColor && tallaColor[0].color && tallaColor[0].sizes
                                                        ? <> color/talla </>
                                                        : tallaColor && tallaColor[0].color && !tallaColor[0].sizes
                                                            ? <> color </>
                                                            : null
                                                    } para conocer cuando te llega</p>
                                            : sizeColorLongitud === 0 && (sizeColorSKU == null || sizeColorSKU === false) && !dataShipping
                                                ? <div className="cpDetallebox">
                                                    <BarraCP changeCP={changeDirectionBar} clase="cpDetalle" />
                                                </div>
                                                : sizeColorLongitud === 0 && (sizeColorSKU == null || sizeColorSKU === false) && dataShipping &&
                                                <p className="dateDelivery1">
                                                    <BarraCP changeCP={changeDirectionBar} clase="cpDelivery" fechaEstimada={dataShipping.date_text} />
                                                </p>
                                        }

                                        <div className={sizeColorLongitud > 0 && !sizeColorSKU ? "int2 mb20" : "int2"}>
                                            
                                            {fullfilment || String(updateFF) === "true" || String(super_express) === "true"
                                                ? <Link to="#modalExpress" className="fullFilment" onClick={showExpress}>Express</Link>
                                                : null
                                            }
                                            {((isfree == true) || (Number(sale_price) >= Number(ENVIO_GRATIS)))
                                                ? <span className="noIsFree">Envío gratis</span>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <ModuleEnvioGratis
                                clickPreseleccionarTalla={clickPreseleccionarTalla}
                                showExpress={showExpress}
                                isfree={isfree}
                                sale_price={sale_price}
                                sizeColorLongitud={sizeColorLongitud}
                                changeDirectionBar={changeDirectionBar}
                                sizeColorSKU={sizeColorSKU}
                                dataShipping={dataShipping}
                                tallaColor={tallaColor}
                                fullfilment={fullfilment}
                                updateFF={updateFF}
                                super_express={super_express}
                                codigoP={codigoP}
                            />
                        </>
                    }
                </>
            }
        </>
    )
}