import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { SectionAutor } from './sectionAutor';

export function SinopsisModule(props) {
    const { description, attributes, brand } = props;

    const [show,setShow] = useState(false);
    const [descripcionRecortada, setDescripcionRecortada] = useState("");

    const ver = () =>{
        setShow(!show);
    }

    useEffect(()=>{
        if(description){
            setDescripcionRecortada(`${description.substr(0, 280)}...`)
        }
    },[description])

    useEffect(()=>{
        if(show){
            setDescripcionRecortada(description);
        }else{
            setDescripcionRecortada(`${description.substr(0, 280)}...`)
        }
    },[show])

    return (
        <div className="moduleLibroContent">
            <SectionAutor 
                attributes={attributes}
                brand={brand}
            />
            <div className="moduleDescription">
                <div className="modulePartDescription">
                    <p className="h3">Sinopsis</p>
                    <div className="textOculto">
                        {descripcionRecortada}
                    </div>
                    {show ?
                        <p onClick={ver} className="ver">Ver menos</p>
                        :
                        <p onClick={ver} className="ver">Ver más</p>
                    }
                </div>
            </div>
        </div>
    )
}
