import React, { useState, useEffect } from "react";
import "./style.sass";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useIsMobile } from "hooks/useIsMobile";
import { dataLayerHome } from "functions/dataLayerFunction";
import { removeStorage } from "functions/storageBase64";
import { getHomeNew } from "services/Home";
import { ErrorBoundary } from "components/Error/ErrorBoundary";

import Header from "pages/Header/HeaderNavegacion";
import Loading from "components/Loading";
import BarraCP from "components/BarCP/BarHeaderCP";
import { HomeTemplateDesktopNew } from "pages/Home/HomeDesktop";
import { HomeTemplateMobileNew } from "pages/Home/HomeMobile";
import { DownloadApp } from "components/DownloadApp";

import { useCart } from 'hooks/useCart'

function Home() {
  //- Codigo de instana para track de pagina
  useEffect(() => {
    window.ineum("page", "Home Page");
    window.scrollTo(0, 0);
    window.ineum("wrapEventHandlers", true);
    removeStorage("modalPostalCode");

    removeStorage("wishlistAfter");
    removeStorage("productAfter");
    removeStorage("typeButton");
    removeStorage("storeSelec");
  }, []);

  const { 
    cartCount, 
    carrito
  } = useCart({firstActive: true, name: "HomePage", timeRef: 1000})

  useEffect(()=>{},[cartCount, carrito])

  let SS_respHomeV3 = window.sessionStorage.getItem('respHomeV3')

  const { isMobile } = useIsMobile();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const [respHomeV3, setRespHomeV3] = useState( SS_respHomeV3 ? JSON.parse( SS_respHomeV3 ) : null  )
  const [responseM, setResponseM] = useState();

  useEffect(() => {
    if (isMobile) {
      if (isMobile === "desktop") {
        getHomeNew().then(setResponseM);
      } else {
        getHomeNew().then(setResponseM);
      }
    }
  }, [isMobile]);

  //- Ajsuta la respuesta del servicio home V3
  useEffect(() => {
    if (responseM) {
      if (responseM.statusCode === 200) {
        if (typeof responseM.data === "object") {
          if (responseM?.data?.data?.homeItems) {
            //console.log('responseM?.data?.data?.homeItems: ', responseM?.data?.data?.homeItems)
            setRespHomeV3(responseM?.data?.data?.homeItems);
            window.sessionStorage.setItem('respHomeV3', JSON.stringify(responseM?.data?.data?.homeItems) )
            dataLayerHome();
          }
        } else if (typeof responseM.data === "string") {
          setErrors({ error: responseM.statusCode, responseM: responseM.data });
          setLoading(false);
        }
      } else if (responseM.statusCode === 401) {
        setErrors({ error: 404, responseM: responseM.data });
        setLoading(false);
      } else {
        setErrors({ error: responseM.statusCode, responseM: responseM.data });
        setLoading(false);
      }
      window.scrollTo(0, 0);
    }
  }, [responseM]);

  //recibe la direccion del CP
  const [updateCPBar, setUpdateCPBar] = useState();
  const reciveDirection = (val) => {
    setUpdateCPBar(val);
  };
  const [upCP, setUpCP] = useState();
  useEffect(() => {
    if (upCP) {
      //console.log('')
    }
  }, [upCP, updateCPBar]);

  let structDataOrganization = `{
		"@context":"https://schema.org",
		"@type":"Organization",
		"url" : "https://www.sanborns.com.mx",
		"image": "https://www.sanborns.com.mx/c/seo/Sanborns_logo.svg",
		"brand": "SANBORNS",
		"name": "SANBORNS HERMANOS, S. A DE C.V",
		"legalName": "SANBORNS HERMANOS, S. A DE C.V",
		"slogan": "SOLO SANBORNS",
		"email": "sanborns@sanborns.com.mx",
		"telephone": "(52) 55 4976 0050",
		"logo": {
		  "@context": "https://schema.org",
		  "@type": "ImageObject",
		  "contentUrl" : "https://www.sanborns.com.mx/c/seo/Sanborns_logo.svg"
		},
		"address": {
		  "@type": "PostalAddress",
		  "addressLocality": "Ciudad de México, México",
		  "postalCode": "11529",
		  "streetAddress": "Lago Zurich 245, Amp Granada, Miguel Hidalgo, 11529 Ciudad de México, CDMX"
		},
		"foundingDate": "1903",
		"foundingLocation": "México",
		"parentOrganization": "Grupo sears",
		"hasMerchantReturnPolicy": "Podrás devolver cualquier artículo comprado en Sears Internet por las siguientes causas: Si el artículo presenta defectos de fabricación, Si existe equivocación en el artículo enviado, conservando la envoltura original de celofán (emplaye) y sin presentar muestras de maltrato."
	  }`
	
	  let structDataLocalBusiness = `{
		"@context":"https://schema.org",
		"@type":"LocalBusiness",
		"url":"https://www.sanborns.com.mx",
		"image": "https://www.sanborns.com.mx/c/seo/Sanborns_logo.svg",
		"brand": "SANBORNS",
		"name": "SANBORNS HERMANOS, S. A DE C.V",
		"legalName": "SANBORNS HERMANOS, S. A DE C.V",
		"slogan": "SOLO SANBORNS",
		"email": "sanborns@sanborns.com.mx",
		"telephone": "(52) 55 4976 0050",
		"location": "Lago Zurich 245, Amp Granada, Miguel Hidalgo, 11529 Ciudad de México, CDMX",
		"duns": "812523855",
		"hasPOS": "México",
		"priceRange": "$10 mxn a $210,000 mxn",
		"paymentAccepted": "Efectivo, Tarjeta de Crédito, Déposito bancario",
		"openingHours": "8 AM",
		"logo": {
		  "@context": "https://schema.org",
		  "@type": "ImageObject",
		  "contentUrl" : "https://www.sanborns.com.mx/c/seo/Sanborns_logo.svg"
		},
		"geo": "19.44186, -99.2046515",
		"latitude": "19.44186",
		"longitude": "-99.2046515",
		"hasMap": "https://www.google.com/maps/place/Sanborns+Plaza+Carso/@19.44186,-99.2046515,15z/data=!4m5!3m4!1s0x0:0x91ac8b28da935ff5!8m2!3d19.44186!4d-99.2046515?hl=es-419",
		"address": {
		  "@type": "PostalAddress",
		  "addressLocality": "Ciudad de México, México",
		  "postalCode": "11529",
		  "streetAddress": "Lago Zurich 245, Amp Granada, Miguel Hidalgo,"
		},
		"foundingDate": "1903",
		"foundingLocation": "México",
		"parentOrganization": "Grupo Sanborns",
		"currenciesAccepted": "MXN",
		"areaServed": {
		  "@type": "State",
		  "name": "México"
		},
		"hasMerchantReturnPolicy": "Podrás devolver cualquier artículo comprado en S@nborns Internet por las siguientes causas: Si el artículo presenta defectos de fabricación. Si existe equivocación en el artículo enviado, conservando la envoltura original de celofán (emplaye) y sin presentar muestras de maltrato."
	  }`
	
	  let structDataWebSite = `{
		"@context":"https://schema.org",
		"@type":"WebSite",
		"url":"https://www.sanborns.com.mx/",
		"potentialAction":{
		  "@type":"SearchAction",
		  "target": {
        "@type": "EntryPoint",
        "urlTemplate": "https://www.sanborns.com.mx/resultados/q={search_term_string}/pagina=1"
      },
		  "query-input":"required name=search_term_string"
		}
	  }`

  return (
    <>
      {loading && <Loading />}
      <Helmet>
        <title>Solo Sanborns</title>
        <link rel="canonical" href="https://www.sanborns.com.mx/" />
        <meta
          name="Description"
          content="SANBORNS.COM.MX | Envíos a todo México · Más de 500 mil productos con todas las Formas de Pago  Compras 100% Seguras · Lo mejor en Moda, Línea blanca, Pantallas, Computo, Celulares, Juguetes, Muebles, Ferretería y más · Click & Recoge en Tienda"
        />
                            
        <meta name="robots" content="index, follow" />

        <script type="application/ld+json">
            { structDataOrganization }
        </script>
        <script type="application/ld+json">
            { structDataLocalBusiness }
        </script>
        <script type="application/ld+json">
            { structDataWebSite }
        </script>
                            
      </Helmet>

        {isMobile === "desktop"
            ?
                <>
                    <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection} />
                    <section className="barCpResponsive">
                        <BarraCP changeCP={reciveDirection} clase="barHeaderCP" />
                    </section>

                    <HomeTemplateDesktopNew
                        respHomeV3={respHomeV3}
                        errors={errors}
                    />
                </>
            :
                <>
                    <Header page="pageHome" setUpCP={setUpCP} />
                    <Link className="fakeSearch" to="/buscador"  aria-label="Icono Buscador">
                    <span>¿Qué es lo que buscas?<div className="contenedorLogo"><span className="logoBuscador"></span></div></span>
                    </Link>
                    <ErrorBoundary>
                    <BarraCP changeCP={reciveDirection} clase="barHeaderCP" />
                    </ErrorBoundary>

                    <HomeTemplateMobileNew
                        respHomeV3={respHomeV3}
                        errors={errors}
                    />
                    {/* <DownloadApp section="home"></DownloadApp> */}
                </>
        }
    </>
  );
}

export default React.memo(Home);
