import { PORTAL_URL } from 'constants/general';
import React from 'react';
import { Link } from 'react-router-dom';

export function HeaderTiendasMenu() {

    return (
        <nav className="menuTiendas">
            <ul className="ulMenuTiendas">
                <li className="tiendas alone">
                    <Link to={`/localizador-tiendas/`} >Localiza tu tienda</Link>
                </li>
                <li className="tiendas alone">
                    <a href="https://facturaelectronica.sanborns.com.mx/" target="_blank" rel="noopener noreferrer">Facturación</a>
                </li>
                <li className="tiendas alone">
                    <a href={`${PORTAL_URL}/c/restaurante/`}>Restaurante</a>
                </li>
                <li className="tiendas alone">
                    <a href={`https://credito.sanborns.com.mx/`} target="_blank">Crédito Sanborns</a>
                </li>
            </ul>
        </nav>
    )
}