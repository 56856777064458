import { useState, useEffect} from 'react'
import { refreshToken } from 'services/Login'
import { getRefreshSSO } from 'services/SSO'
import { encodeStorage, decodeStorage, removeStorage } from 'functions/storageBase64'
import { getCookie } from 'functions/getCookie'
import { useLogout } from 'hooks/useLogin'
import { SSO } from 'constants/general'
import MD5 from "crypto-js/md5"

export function useToken(props = false){

    const {
        timeRef = 100,
        firstActive = true,
        name
    } = props

    const [readyActive, setReadyActive] = useState(firstActive)

    useEffect(()=>{
        if(firstActive){
            setReadyActive(true)
        }else{
            setReadyActive(false)
        }
        if(name){
            //console.log('nombreHookToken: ',name)
        }
    },[firstActive])

    const { logOut } = useLogout()

    const [tokenCookie, ] = useState( getCookie('access_token') )
    const [tokenRCookie, ] = useState( getCookie('refresh_token') )
    const [tokenStore, setTokenStore] = useState( decodeStorage('access_token') )
    const [tokenRStore, setTokenRStore] = useState( decodeStorage('refresh_token') )
    const [tokenTime, setTokenTime ] = useState( decodeStorage('access_token_exp') )
    const [tokenTimeR, setTokenTimeR ] = useState( decodeStorage('refresh_token_exp') )
    const domain = window.location.hostname

    /*  Actualiza el tiempo  */
    const [tiempoActual, setUpdateTiempoActual] = useState( Date.now() )
    const [updateFunction, setUpdateFunction] = useState()
    const liveToken = () =>{
        setUpdateTiempoActual( Date.now() )
        setUpdateFunction(Date.now())
    }

    const [forceUpdateToken, setForceUpdateToken] = useState(false)
    const forceToken = force =>{
        setForceUpdateToken(force)
        console.log('se intenta el forzado: ', force)
    }

    /*  Si existe storage toma los datos, si no existe busca datos de la cookie y los guarda al storage  */
    useEffect(()=>{
        if(tokenStore){
            // console.log('Existe en storage y toma los datos')
            setTokenTime( decodeStorage('access_token_exp') )
            setTokenTimeR( decodeStorage('refresh_token_exp') )
        }
        else if(tokenCookie && tokenRCookie){
            // console.log('No existe storage, busca datos de la cookie y los guarda al storage')
            encodeStorage('access_token', tokenCookie )
            encodeStorage('refresh_token', tokenRCookie )

            let tokenJWT = JSON.parse( atob(tokenCookie.split('.')[1]) )
            let tokenRefreshJWT = JSON.parse( atob(tokenRCookie.split('.')[1]) )
            
            
            encodeStorage('access_token_exp', tokenJWT.exp)
            encodeStorage('refresh_token_exp', tokenRefreshJWT.exp)

            setTokenTime( decodeStorage('access_token_exp') )
            setTokenTimeR( decodeStorage('refresh_token_exp') )
        }
        else{
            // console.log('No existe ni cookie ni storage')
        }

        
        if(readyActive){
            // console.log('cookie TC: ', tokenCookie)
            // console.log('storage TC: ', tokenStore)
            // console.log('cookie TRC: ', tokenRCookie)
            // console.log('storage TRC: ', tokenRStore)
            // console.log('tokenRefresh: ', tokenTimeR)
            // console.log('token: ', tokenTime)
            // console.log('actual: ', tiempoActual/1000 )
            // console.log('---------------------------------------')
            // let tokenActual = new Date(decodeStorage('access_token_exp') * 1000)
            // console.log('actual: ', Date() )
            // console.log('token: ', tokenActual )
            // let refreshActual = new Date(decodeStorage('refresh_token_exp') * 1000)
            // console.log('refresh: ', refreshActual )
        }

    },[updateFunction])
    

    const [tokenR, getTokenR] = useState()
    useEffect(()=>{
        
        if(readyActive || forceUpdateToken){
            let tokenStoreLocal = decodeStorage('access_token')
            let access_token_exp = decodeStorage('access_token_exp')
            let refresh_token_exp = decodeStorage('refresh_token_exp')

            setTimeout(function(){
                //console.log('timeRef: ', timeRef)

                if(tokenCookie || tokenStore || tokenStoreLocal){
                    /* Evalua que el tiempo no sea mayor a 3 meses, si es asi manda a login */
                    if((tokenTimeR || tokenTime) || (refresh_token_exp || access_token_exp) ){
                        /* Forza el update del token  */
                        if(forceUpdateToken){
                            setForceUpdateToken(false)
                            //console.log('Se forzo a actualizar el token')
                            if(SSO?.active){
                                getRefreshSSO()
                                .then(getTokenR)
                            }
                            else{
                                refreshToken()
                                .then(getTokenR)
                            }
                        }
                        else if(tiempoActual >= (tokenTimeR * 1000) && tiempoActual >= (refresh_token_exp * 1000) ){
                            console.log('El token lleva mas de 3 meses')
                            window.ineum('meta', 'Logout', "refresTokenExpOut")
                            logOut()
                            setTimeout(function(){
                                window.location.replace(`${window.location.origin}/login?redirect=${window.location.href}`)
                            },700)
                        }
                        /* Evalua que el tiempo no sea mayor a 30 minutos, si es asi actauliza el token  */
                        else if(tiempoActual >= ( (tokenTime * 1000) - 10000 ) && tiempoActual >= ( (decodeStorage('access_token_exp') * 1000) - 10000 ) ){
                        
                            // console.log('a:  ', tiempoActual >= ( (tokenTime * 1000) - 10000 ))
                            // console.log('b:  ', tiempoActual >= ( (decodeStorage('access_token_exp') * 1000) - 10000 ))
                            // console.log('c:  ', tiempoActual )
                            // console.log('d:  ', (tokenTime * 1000) - 10000  )
                            // console.log('e:  ', (decodeStorage('access_token_exp') * 1000) - 10000  )
                            // console.log('------------------------------------>')

                            // console.log('El token lleva mas de 30min')
                            if(SSO?.active){
                                getRefreshSSO()
                                .then(getTokenR)
                            }
                            else{
                                refreshToken()
                                .then(getTokenR)
                            }
                        }
                    }else{
                        console.log('si no tienen refresh token y acces token exp')
                        logOut()
                        setTimeout(function(){
                            window.location.replace(`${window.location.origin}/login?redirect=${window.location.href}`)
                        },700)
                    }
                }
            },timeRef)
        }

    },[updateFunction, forceUpdateToken])
    

    /* Actauliza los datos despues de que la respuesta sea success */
    const [newToken, setNewToken] = useState()
    useEffect(()=>{
        if(tokenR){
            //console.log('tokenR: ', tokenR)
            if(SSO?.active){
                //console.log('tokenR-data: ', tokenR?.data)
                if(tokenR?.statusCode === 200 ){
                    if(tokenR?.data){

                        let tokenJWT = JSON.parse( window.atob(tokenR?.data?.access_token?.split('.')[1]) )
                        let tokenRefreshJWT = JSON.parse(  window.atob(tokenR?.data?.refresh_token?.split('.')[1]) )

                        encodeStorage('session_token', tokenR?.data?.access_token)
                        encodeStorage('access_token', tokenR?.data?.access_token)
                        encodeStorage('refresh_token', tokenR?.data?.refresh_token)

                        encodeStorage('idCarrito', MD5(`${tokenJWT?.email}`).toString() )

                        document.cookie = `token_cart=${ MD5(`${tokenJWT?.email}`).toString() };Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                        document.cookie = `token_login=${tokenR?.data?.access_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                        
                        document.cookie = `jwt=${tokenR?.data?.access_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                        document.cookie = `access_token=${tokenR?.data?.access_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                        document.cookie = `refresh_token=${tokenR?.data?.refresh_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`

                        encodeStorage('access_token_exp', tokenJWT?.exp)
                        encodeStorage('refresh_token_exp', tokenRefreshJWT?.exp)

                        setTokenTime( decodeStorage('access_token_exp') )
                        setTokenStore( decodeStorage('access_token') )
                        setTokenTimeR( decodeStorage('refresh_token_exp') )
                        setTokenRStore( decodeStorage('refresh_token') )
                        
                        setNewToken(tokenJWT.exp)

                    }
                }
                else if( tokenR.statusCode === 400 ){

                    console.log('dio un 400: ', tokenR?.data?.error)
                    encodeStorage('reasonLastLogout', tokenR?.data?.error)
                    window.ineum('meta', 'reasonLastLogout', tokenR?.data?.error)

                    if(tokenR?.data?.error === "invalid_grant" ){
                        logOut()
                        setTimeout(function(){
                            window.location.replace(`${window.location.origin}/login?redirect=${window.location.href}`)
                        },1500)                  
                    }
                    else if( tokenR?.data?.error === "invalid_client" || tokenR?.data?.error === "unauthorized_client" ||  tokenR?.data?.error === "invalid_request"){
                        logOut()
                        setTimeout(function(){                  
                            window.location.replace(`${window.location.origin}`)
                        },1500)                  
                    }else{
                        logOut()
                        setTimeout(function(){                  
                            window.location.replace(`${window.location.origin}`)
                        },1500)                  
                    }
                }
                else if( tokenR?.statusCode === 401 ){
                    console.log('no tiene permisos el token, ¿Lo mandamos al login y le damos logout? ')
                    logOut()
                }
                else if( tokenR?.statusCode >= 500 ){
                    console.log('error en el server alias un 500 ton')
                    encodeStorage('access_token_exp', ((Date.now()/1000) + 300) )
                }

            }else{
                if(tokenR?.statusCode === 200 ){
                    if(tokenR?.data){
                        if(tokenR?.data?.data){
    
                            // console.log('la respuesta es success de la actualizacion de refresh token')
                            // console.log('tokenR?.data?.data: ',tokenR?.data?.data?.session_token)
                            // console.log('tokenR?.data?.data: ',tokenR?.data?.data?.cart_hash)
                            // console.log('tokenR?.data?.data: ',tokenR?.data?.data?.access_token)
                            // console.log('tokenR?.data?.data: ',tokenR?.data?.data?.refresh_token)
    
                            encodeStorage('session_token',tokenR?.data?.data?.session_token)
                            encodeStorage('idCarrito',tokenR?.data?.data?.cart_hash)
                            encodeStorage('access_token',tokenR?.data?.data?.access_token)
                            encodeStorage('refresh_token',tokenR?.data?.data?.refresh_token)
                            
                            let tokenJWT = JSON.parse( window.atob(tokenR?.data?.data?.access_token?.split('.')[1]) )
                            let tokenRefreshJWT = JSON.parse( window.atob(tokenR?.data?.data?.refresh_token?.split('.')[1]) )
                            
                            document.cookie = `token_cart=${tokenR?.data?.data?.cart_hash};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                            document.cookie = `token_login=${tokenR?.data?.data?.session_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                            
                            document.cookie = `jwt=${tokenR?.data?.data?.access_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                            document.cookie = `access_token=${tokenR?.data?.data?.access_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                            document.cookie = `refresh_token=${tokenR?.data?.data?.refresh_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                            
    
                            encodeStorage('access_token_exp', tokenJWT?.exp)
                            encodeStorage('refresh_token_exp', tokenRefreshJWT?.exp)
    
                            setTokenTime( decodeStorage('access_token_exp') )
                            setTokenStore( decodeStorage('access_token') )
                            setTokenTimeR( decodeStorage('refresh_token_exp') )
                            setTokenRStore( decodeStorage('refresh_token') )
                            
                            setNewToken(tokenJWT.exp)
                        }
                    }
                }else if( tokenR?.statusCode === 401 ){
                    console.log('no tiene permisos el token, ¿Lo mandamos al login y le damos logout? ')
                    logOut()
                }else if( tokenR?.statusCode >= 500 ){
                    console.log('error en el server alias un 500 ton')
                    encodeStorage('access_token_exp', ( (Date.now()/1000) + 300) )
                }
            }

        }
    },[tokenR])
    
    return { newToken, liveToken, forceToken }
}