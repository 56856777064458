import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuPoliticas } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { Link, useHistory } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';

export function AvisoPrivacidadCirculoPlusDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Circulo Plus Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuPoliticas />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Aviso de Privacidad Círculo Plus</p>
                            </div>
                        </section>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <p>El presente documento constituye el Aviso de Privacidad para efectos de lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo la “Ley”). Este Aviso de Privacidad aplica a la información personal recopilada por <span className='bold'>“CÍRCULO PLUS”</span>.</p>
                               </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>Identidad y domicilio del Responsable</h2>
                                <p> <span className='bold'> SANBORN HERMANOS, S. A. DE C. V. </span> (en adelante “CÍRCULO PLUS”) con domicilio en Lago Zúrich 245, edificio Presa Falcón, piso 7, colonia Ampliación Granada, alcaldía Miguel Hidalgo, 11529, Ciudad de México, serán responsables de recabar sus datos personales, del uso que se le dé a los mismos y de su protección.</p>
                                <p>Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos a la dirección electrónica <a href="mailto:sanbornsarco@circulo.plus">sanbornsarco@circulo.plus</a></p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Qué información vamos a recabar?</h2>
                                <p>Para cumplir con las finalidades informadas en el presente Aviso de Privacidad CÍRCULO PLUS tratará los siguientes  datos personales:  de identificación, datos de contacto, datos demográficos, datos fiscales, datos patrimoniales y financieros, información acerca de sus preferencias e intereses relacionados con los bienes, productos y servicios que ofrecemos, información generada  con motivo del uso de nuestros servicios así como información técnica del dispositivo que usted utilice para acceder a nuestras plataformas y servicios. </p>
                                <p>Le informamos que para efectos de lo señalado en el presente Aviso de Privacidad Círculo Plus no tratará datos personales sensibles. </p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>No tratamiento de datos personales de menores de edad.</h2>
                                <p>El Servicio de CÍRCULO PLUS no va dirigido a menores de 18 años y no obtenemos intencionalmente información personal alguna de ellos(as). Si eres menor de 18 años, no utilices el Servicio y no nos proporciones información personal alguna. Si descubrimos que un menor de 18 años nos ha proporcionado información personal, ésta será eliminada.</p>
                                <p>En el supuesto de que se proporcionen datos relacionados con personas menores de edad asumiremos que la persona que los proporciona es el padre, madre y/o tutor legal, quien reconoce haber leído y aceptado el presente aviso de privacidad. </p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Para qué fines usamos su información?</h2>
                                <p>CÍRCULO PLUS tratará los datos personales descritos en el presente Aviso para las siguientes finalidades primarias:</p>

                                <ul className="ulQuestionDisc">
                                    <li>Identificarle como cliente de CÍRCULO PLUS;</li>
                                    <li>Contactarle a través de diversos medios de comunicación física y electrónica;</li>
                                    <li>Realizar gestiones de cobro de diversas suscripciones, productos o servicios que adquieres con nosotros y que puede ser realizada directamente por nuestra empresa o a través de un intermediario contratado por nosotros; </li>
                                    <li>Procesar el envío a su domicilio, teléfono móvil, correo electrónico o a bajo cualquier otro mecanismo de comunicación, de los productos, boletos, servicios, promociones, información, contenido, notificaciones y cualquier otro objeto o información que sea necesaria para nuestra la correcta operación del programa de lealtad;</li>
                                    <li>Determinar la zona geográfica donde usted se encuentre para ofrecerle contenidos, promociones o beneficios correspondientes a esa zona; recomendarle establecimientos, eventos o actividades que creamos que pueden ser de su interés; identificar su proveedor de servicio de Internet y responder a sus consultas y solicitudes;</li>
                                    <li>Para contactar a través de llamadas telefónicas, envío de comunicaciones a través de correos electrónicos, mensajes cortos y demás medios de comunicación físicos y/o electrónicos, servicios de mensajería instantánea (WhatsApp, Telegram, entre otros que CÍRCULO PLUS considere convenientes). Lo anterior, para realizar actividades de contacto con el propósito de brindarle información sobre los Servicios que ofrecemos, informarle sobre cualquier beneficio al que se hubiere hecho acreedor como consecuencia de los mismos y dar cumplimiento a las obligaciones que usted haya adquirido con nosotros;</li>
                                    <li>Prevenir, detectar, impedir e investigar posibles actividades ilícitas de conformidad con nuestros procesos internos;</li>
                                    <li>Llevar un histórico de sus operaciones y movimientos;</li>
                                    <li>Procesar pagos y realizar gestiones de facturación relacionadas con los servicios prestados;</li>
                                    <li>Atender aclaraciones bancarias;</li>
                                    <li>Realizar consultas, investigaciones y revisiones en relación con quejas o reclamaciones;</li>
                                    <li>Realizar investigaciones internas y cumplir con políticas y procesos internos;</li>
                                    <li>Atender y resolver sus solicitudes, aclaraciones y dudas relacionadas con nuestros servicios;</li>
                                    <li>Dar cumplimiento a las obligaciones derivadas de la relación jurídica con usted;</li>
                                    <li>Dar cumplimiento a obligaciones previstas en la normatividad aplicable.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Qué otros usos podemos darle a su información?</h2>
                                <p>CÍRCULO PLUS, si usted no se opone, tratará sus datos personales para llevar a cabo alguna o todas las finalidades secundarias que se mencionan a continuación:</p>

                                <ul className="ulQuestionDisc">
                                    <li>Invitarle a participar promociones, descuentos, sorteos, concursos y encuestas a través de nuestras plataformas;</li>
                                    <li>Informarle del lanzamiento o cambios de nuevos productos, bienes, servicios, promociones y/u ofertas de CÍRCULO PLUS de acuerdo con sus intereses, así como de otras marcas y/o Empresas del Grupo incluyendo el envío de comunicaciones a través de correos electrónicos, llamadas telefónicas, mensajes cortos y demás medios de comunicación físicos y/o electrónicos;</li>
                                    <li>Posibles contactos posteriores para ofrecimiento de productos, bienes y/o servicios que ofrezca CÍRCULO PLUS y/o Empresas del Grupo;</li>
                                    <li>Compartir sus perfiles y hábitos de consumo con nuestros socios comerciales y/o Empresas del Grupo para que éstas puedan contactarle y enviarle información de carácter publicitario, promocional y/o informativo que consideren de su interés y/o para que puedan valorar, conforme a sus procedimientos internos, si usted es susceptible de obtener el servicio que hubiere solicitado ante dicho tercero; </li>
                                    <li>Para enviarle publicidad y comunicaciones con fines de mercadotecnia, tele-marketing entre otras.</li>
                                </ul>

                                <p>Si usted no desea que CÍRCULO PLUS trate sus datos personales para alguna de las finalidades descritas en este apartado, por favor envíe un correo electrónico a <a href="mailto:sanbornsarco@circulo.plus">sanbornsarco@circulo.plus</a> Usted podrá cambiar de opción en cualquier momento.</p>
                                <p>Le informamos que las anteriores finalidades secundarias tienen como base de legitimación su consentimiento. Lo anterior quiere decir que usted en cualquier momento puede oponerse a cualquiera de ellas, o bien, revocar su consentimiento. Es importante que tenga presente que, en caso de otorgar su consentimiento, éste seguirá siendo válido incluso cuando hubiere terminado la relación jurídica entre usted y CÍRCULO PLUS, lo que significa que CÍRCULO PLUS podrá seguir tratando sus datos personales necesarios para cumplir con las presentes finalidades secundarias.</p>
                                <p>La negativa para el uso de sus datos personales para fines adicionales, no podrá ser un motivo para negarle los servicios solicitados o dar por terminada la relación establecida con nosotros.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿A qué terceros podemos transferir su información?</h2>
                                <p>CÍRCULO PLUS podrá transferir, sin requerir de su consentimiento, los datos personales necesarios a autoridades competentes en los casos legalmente previstos, así como a empresas subsidiarias afiliada, parte relacionada, controlada, controladora o bajo control Común, las cuales operan bajo políticas de privacidad que cumplen con las disposiciones aplicables correspondientes para dar cumplimiento a las finalidades señaladas en el presente aviso. </p>

                                <p>Para conocer cuáles son las Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común a quienes sus datos son transferidos puede consultarlo en http://www.gsanborns.com.mx/perfil-corporativo.html</p>

                                <p>CÍRCULO PLUS podrá transferir sin requerir de su consentimiento los datos personales necesarios a socios comerciales y/o terceros con los que tengamos celebrados acuerdos comerciales o de colaboración para cumplir con las finalidades descritas en el presente Aviso de Privacidad.</p>

                                <p>Si usted no se opone, CÍRCULO PLUS podrá transferir sus datos personales a socios comerciales para que estos puedan contactarle y ofrecerle bienes y/o servicios que resulten de su interés, o bien, para que puedan determinar si usted es sujeto a adquirir los bienes y/o servicios que dichos terceros ofrecen.</p>

                                <p>En caso de que no desee que sus datos personales sean transferidos por favor envíe un correo electrónico a <a href='mailto:sanbornsarco@circulo.plus'>sanbornsarco@circulo.plus</a> Usted podrá cambiar de opción en cualquier momento. </p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Cómo puede ejercer sus derechos ARCO y/o revocar su consentimiento?</h2>
                                <p>Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en lo sucesivo “derechos Arco”), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Datos Personales de CÍRCULO PLUS a la dirección electrónica  <a href='mailto:sanbornsarco@circulo.plus'>sanbornsarco@circulo.plus</a>.</p>
                                <p>Su petición deberá ser realizada a través del Formato de Solicitud de Derechos ARCO, el cual debe ser solicitado a la dirección de correo electrónico antes señalada. Para que el Departamento de Protección de Datos de CÍRCULO PLUS pueda darle seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo que es necesario que complete todos los campos indicados en el Formato y lo acompañe con copia de alguna de las identificaciones oficiales vigentes que se señalan en el mismo. En caso de que la información proporcionada sea errónea o insuficiente, o bien, no se acompañen los documentos de acreditación correspondientes, nuestro Departamento de Protección de Datos, dentro de los cinco (5) días hábiles siguientes a la recepción de la solicitud, podrá requerirle que aporte los elementos o documentos necesarios para dar trámite a la misma. Usted contará con diez (10) días hábiles para atender el requerimiento, contados a partir del día siguiente en que lo haya recibido. De no dar respuesta en dicho plazo, se tendrá por no presentada la solicitud correspondiente. Nuestro Departamento de Protección de Datos le comunicará la determinación adoptada, en un plazo máximo de veinte (20) días hábiles contados desde la fecha en que se recibió la solicitud, a efecto de que, si resulta procedente, haga efectiva la misma dentro de los quince (15) días hábiles siguientes a que se comunique la respuesta. Para obtener mayor información sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento contacte al Departamento de Protección de Datos de CÍRCULO PLUS.</p>
                                <p>En caso de que su solicitud de cancelación, oposición o revocación sea procedente, según corresponda se dejarán de usar sus datos, para la finalidad que nos hubiere requerido o se eliminarán de nuestros sistemas en términos de lo que establece la legislación de la materia.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Cómo puede limitar el uso y/o divulgación de sus datos?</h2>
                                <p>Usted o su representante legal podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la dirección <a href='mailto:sanbornsarco@circulo.plus'>sanbornsarco@circulo.plus</a> En caso de que su Solicitud sea procedente se le registrará en el listado de exclusión propio de CÍRCULO PLUS. Para más información favor de contactar al Departamento de Datos Personales de CÍRCULO PLUS.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>Uso de cookies y web beacons</h2>
                                <p>En CÍRCULO PLUS utilizamos varias tecnologías para mejorar la eficiencia de nuestro sitio web https://circulo.plus/ (en adelante “Sitio Web”) y herramientas tecnológicas, incluyendo su experiencia cuando navega por dichos sitios o usa nuestra red. Entre estas tecnologías se incluye el uso de cookies, que son pequeñas cantidades de información que se almacenan en el navegador utilizado por cada usuario para que el servidor recuerde cierta información que posteriormente pueda utilizar. Esta información permite identificarle y guardar sus preferencias personales para brindarle una mejor experiencia de navegación. </p>
                                <p>El sitio web también usa web beacons, también conocidos como etiquetas de Internet, etiquetas de píxel y clear GIFs) que solos o en combinación con las cookies, para recopilar información sobre el uso de los sitios web y su interacción con el correo electrónico permiten reconocer información que es procesada en su computadora, como el caso de las cookies, la hora y fecha en que el sitio y sus secciones son visualizados. </p>
                                <p>Los diferentes tipos de cookies que utilizamos para obtener datos identificativos y datos relacionados con su comportamiento de compra y uso de servicios en internet son:</p>
                                <ul className="ulQuestionDisc">
                                    <li><span className='bold'>Cookies esenciales:</span> para permitirle un uso adecuado de nuestro sitio web y/o aplicaciones, por lo que las mismas no pueden ser deshabilitadas.</li>
                                    <li><span className='bold'>Cookies para recordar sus preferencias y experiencia:</span> estas cookies nos permiten recordar sus preferencias, opciones de navegación y funciones personalizadas.</li>
                                    <li><span className='bold'>Cookies para objetivos de rendimiento y análisis:</span> podemos usar cookies propias y de terceros para identificar la forma en la cual usted utiliza nuestros Servicios con la finalidad de mejorar su rendimiento y futuros desarrollos.</li>
                                    <li><span className='bold'>Cookies publicitarias propias y de terceros:</span> podemos colocar cookies publicitarias propias y de terceros (como socios comerciales y empresas del grupo) para mostrarle publicidad que consideremos relevante para usted.</li>
                                    <li><span className='bold'>Otros datos:</span> De la misma forma, como parte de nuestros procesos de mejora de nuestros productos y servicios, en ciertos casos podremos recabar respuestas anónimas a encuestas y requerimientos relacionados con el uso de nuestros productos y servicios. En determinados casos y cuando ello sea necesario podremos aplicar técnicas de anonimización de datos con la finalidad de realizar determinados estudios de carácter predictivo y estadístico a partir de la información generada con motivo del uso de nuestros Servicios. </li>
                                </ul>

                                <p>Algunas de las cookies que utilizamos en nuestro Sitio Web son necesarias para permitir su funcionamiento y no se pueden desactivar de nuestros sistemas. </p>
                                <p>No obstante, usted puede deshabilitar aquellas cookies no esenciales, siguiendo el aplicable de acuerdo con el tipo de navegador que utilice para acceder a nuestro Sitio Web:</p>
                                <ul className="ulQuestionDisc">
                                    <li>Internet Explorer: Herramientas - Opciones de Internet - Privacidad - Configuración. Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.</li>
                                    <li>Firefox: Herramientas - Opciones - Privacidad y Seguridad. Para más información, puedes consultar el soporte de Mozilla o la Ayuda del navegador.</li>
                                    <li>Chrome: Configuración - Verificación de seguridad y/o - Privacidad y seguridad. Para más información, puede consultar el soporte de Google o la Ayuda del navegador.</li>
                                    <li>Safari: Preferencias - Seguridad. Para más información, puede consultar el soporte de Apple o la Ayuda del navegador.</li>
                                    <li>Edge: Configuración - Privacidad, búsqueda y servicios y/o Permisos del sitio. Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.</li>
                                </ul>
                                <p>A través de su navegador de internet, usted también puede ver las cookies que están en su dispositivo, y borrarlas según crea conveniente.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>Seguridad de su información</h2>
                                <p>CÍRCULO PLUS utiliza medidas administrativas, técnicas, físicas y de gestión razonables para proteger tu información personal contra pérdidas, robos, acceso no autorizado, uso y modificación. Se pueden utilizar diversos protocolos estándares en la industria, en algunas de las transmisiones que nos envías, a fin de cifrar cierta información personal que se transmite durante el proceso de registro y de suscripción.</p>
                            </div>
                        </div>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Cómo puede hacernos llegar sus dudas?</h2>
                                <p>Nuestro Departamento de Protección de Datos queda a sus órdenes para proporcionarle cualquier información adicional que requiera o, en su caso, para resolver cualquier duda que pudiera surgirle en materia de privacidad y protección de datos personales, para lo que podrá contactarnos a través del correo electrónico <a href="mailto:sanbornsarco@circulo.plus">sanbornsarco@circulo.plus</a>.</p>
                            </div>
                        </div>

                    
                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>INAI</h2>
                                <p>En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) para hacer efectivos sus derechos.</p>
                            </div>
                        </div>

                        <div className='contentPreguntas'>
                            <div className='question1'>
                                <h2>¿Cómo le notificaremos de los cambios que efectuemos al presente Aviso de Privacidad?</h2>
                                <p>CÍRCULO PLUS le notificará de cualquier cambio a su aviso de privacidad a través de la página de internet https://circulo.plus/, en el apartado de Legales, en Aviso de Privacidad.</p>
                                <p>Fecha de última actualización: Agosto de 2023.</p>
                            </div>
                        </div>


                    </div>
                </section>
            </main>

            <Footer />

        </>
    )
}

export function AvisoPrivacidadCirculoPlusMobile() {
    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Circulo Plus Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN">
                        <div className="btn closeBack" onClick={onClose} ></div>
                        <p className="title">Aviso de Privacidad Círculo Plus</p>
                    </div>

                    <div className="dataModal">
                        <div className="boxAN">

                            <div className="bxSlider">

                                <p className='pSliderL'>El presente documento constituye el Aviso de Privacidad para efectos de lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo la “Ley”). Este Aviso de Privacidad aplica a la información personal recopilada por “CÍRCULO PLUS”.</p>

                                <p className="pSliderTitleL">Identidad y domicilio del Responsable</p>
                                <p className="pSliderL">SANBORN HERMANOS, S. A. DE C. V.  (en adelante “CÍRCULO PLUS”) con domicilio en Lago Zúrich 245, edificio Presa Falcón, piso 7, colonia Ampliación Granada, alcaldía Miguel Hidalgo, 11529, Ciudad de México, serán responsables de recabar sus datos personales, del uso que se le dé a los mismos y de su protección.</p>
                                <p className="pSliderL">Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos a la dirección electrónica <a href='mailto:sanbornsarco@circulo.plus'>sanbornsarco@circulo.plus</a></p>


                                <p className="pSliderTitleL">¿Qué información vamos a recabar?</p>
                                <p className="pSliderL">Para cumplir con las finalidades informadas en el presente Aviso de Privacidad CÍRCULO PLUS tratará los siguientes  datos personales:  de identificación, datos de contacto, datos demográficos, datos fiscales, datos patrimoniales y financieros, información acerca de sus preferencias e intereses relacionados con los bienes, productos y servicios que ofrecemos, información generada  con motivo del uso de nuestros servicios así como información técnica del dispositivo que usted utilice para acceder a nuestras plataformas y servicios.</p>
                                <p className="pSliderL">Le informamos que para efectos de lo señalado en el presente Aviso de Privacidad Círculo Plus no tratará datos personales sensibles. </p>
                               
                                <p className="pSliderTitleL">No tratamiento de datos personales de menores de edad.</p>
                                <p className="pSliderL">El Servicio de CÍRCULO PLUS no va dirigido a menores de 18 años y no obtenemos intencionalmente información personal alguna de ellos(as). Si eres menor de 18 años, no utilices el Servicio y no nos proporciones información personal alguna. Si descubrimos que un menor de 18 años nos ha proporcionado información personal, ésta será eliminada. </p>
                                <p className='pSliderL'>En el supuesto de que se proporcionen datos relacionados con personas menores de edad asumiremos que la persona que los proporciona es el padre, madre y/o tutor legal, quien reconoce haber leído y aceptado el presente aviso de privacidad. </p>

                                <p className="pSliderTitleL">¿Para qué fines usamos su información?</p>
                                <p className="pSliderL">CÍRCULO PLUS tratará los datos personales descritos en el presente Aviso para las siguientes finalidades primarias:</p>

                                <ul className="ulDeclaracionesDisc">
                                    <li>Identificarle como cliente de CÍRCULO PLUS;</li>
                                    <li>Contactarle a través de diversos medios de comunicación física y electrónica;</li>
                                    <li>Realizar gestiones de cobro de diversas suscripciones, productos o servicios que adquieres con nosotros y que puede ser realizada directamente por nuestra empresa o a través de un intermediario contratado por nosotros; </li>
                                    <li>Procesar el envío a su domicilio, teléfono móvil, correo electrónico o a bajo cualquier otro mecanismo de comunicación, de los productos, boletos, servicios, promociones, información, contenido, notificaciones y cualquier otro objeto o información que sea necesaria para nuestra la correcta operación del programa de lealtad;</li>
                                    <li>Determinar la zona geográfica donde usted se encuentre para ofrecerle contenidos, promociones o beneficios correspondientes a esa zona; recomendarle establecimientos, eventos o actividades que creamos que pueden ser de su interés; identificar su proveedor de servicio de Internet y responder a sus consultas y solicitudes;</li>
                                    <li>Para contactar a través de llamadas telefónicas, envío de comunicaciones a través de correos electrónicos, mensajes cortos y demás medios de comunicación físicos y/o electrónicos, servicios de mensajería instantánea (WhatsApp, Telegram, entre otros que CÍRCULO PLUS considere convenientes). Lo anterior, para realizar actividades de contacto con el propósito de brindarle información sobre los Servicios que ofrecemos, informarle sobre cualquier beneficio al que se hubiere hecho acreedor como consecuencia de los mismos y dar cumplimiento a las obligaciones que usted haya adquirido con nosotros;</li>
                                    <li>Prevenir, detectar, impedir e investigar posibles actividades ilícitas de conformidad con nuestros procesos internos;</li>
                                    <li>Llevar un histórico de sus operaciones y movimientos;</li>
                                    <li>Procesar pagos y realizar gestiones de facturación relacionadas con los servicios prestados;</li>
                                    <li>Atender aclaraciones bancarias;</li>
                                    <li>Realizar consultas, investigaciones y revisiones en relación con quejas o reclamaciones;</li>
                                    <li>Realizar investigaciones internas y cumplir con políticas y procesos internos;</li>
                                    <li>Atender y resolver sus solicitudes, aclaraciones y dudas relacionadas con nuestros servicios;</li>
                                    <li>Dar cumplimiento a las obligaciones derivadas de la relación jurídica con usted;</li>
                                    <li>Dar cumplimiento a obligaciones previstas en la normatividad aplicable.</li>
                                </ul>

                                <p className="pSliderTitleL">¿Qué otros usos podemos darle a su información?</p>
                                <p className="pSliderL">CÍRCULO PLUS, si usted no se opone, tratará sus datos personales para llevar a cabo alguna o todas las finalidades secundarias que se mencionan a continuación:</p>
                                
                                <ul className="ulDeclaracionesDisc">
                                  <li>Invitarle a participar promociones, descuentos, sorteos, concursos y encuestas a través de nuestras plataformas;</li>
                                  <li>Informarle del lanzamiento o cambios de nuevos productos, bienes, servicios, promociones y/u ofertas de CÍRCULO PLUS de acuerdo con sus intereses, así como de otras marcas y/o Empresas del Grupo incluyendo el envío de comunicaciones a través de correos electrónicos, llamadas telefónicas, mensajes cortos y demás medios de comunicación físicos y/o electrónicos;</li>
                                  <li>Posibles contactos posteriores para ofrecimiento de productos, bienes y/o servicios que ofrezca CÍRCULO PLUS y/o Empresas del Grupo;</li>
                                  <li>Compartir sus perfiles y hábitos de consumo con nuestros socios comerciales y/o Empresas del Grupo para que éstas puedan contactarle y enviarle información de carácter publicitario, promocional y/o informativo que consideren de su interés y/o para que puedan valorar, conforme a sus procedimientos internos, si usted es susceptible de obtener el servicio que hubiere solicitado ante dicho tercero; </li>
                                  <li>Para enviarle publicidad y comunicaciones con fines de mercadotecnia, tele-marketing entre otras.</li>
                                </ul>

                                <p className="pSliderL">Si usted no desea que CÍRCULO PLUS trate sus datos personales para alguna de las finalidades descritas en este apartado, por favor envíe un correo electrónico a sanbornsarco@circulo.plus Usted podrá cambiar de opción en cualquier momento.</p>
                                <p className='pSliderL'>Le informamos que las anteriores finalidades secundarias tienen como base de legitimación su consentimiento. Lo anterior quiere decir que usted en cualquier momento puede oponerse a cualquiera de ellas, o bien, revocar su consentimiento. Es importante que tenga presente que, en caso de otorgar su consentimiento, éste seguirá siendo válido incluso cuando hubiere terminado la relación jurídica entre usted y CÍRCULO PLUS, lo que significa que CÍRCULO PLUS podrá seguir tratando sus datos personales necesarios para cumplir con las presentes finalidades secundarias.</p>
                                <p className="pSliderL">La negativa para el uso de sus datos personales para fines adicionales, no podrá ser un motivo para negarle los servicios solicitados o dar por terminada la relación establecida con nosotros.</p>

                                <p className="pSliderTitleL">¿A qué terceros podemos transferir su información?</p>

                                <p className="pSliderL">CÍRCULO PLUS podrá transferir, sin requerir de su consentimiento, los datos personales necesarios a autoridades competentes en los casos legalmente previstos, así como a empresas subsidiarias afiliada, parte relacionada, controlada, controladora o bajo control Común, las cuales operan bajo políticas de privacidad que cumplen con las disposiciones aplicables correspondientes para dar cumplimiento a las finalidades señaladas en el presente aviso. </p>
                                <p className="pSliderL">Para conocer cuáles son las Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común a quienes sus datos son transferidos puede consultarlo en http://www.gsanborns.com.mx/perfil-corporativo.html</p>
                                <p className="pSliderL">CÍRCULO PLUS podrá transferir sin requerir de su consentimiento los datos personales necesarios a socios comerciales y/o terceros con los que tengamos celebrados acuerdos comerciales o de colaboración para cumplir con las finalidades descritas en el presente Aviso de Privacidad.</p>
                                <p className="pSliderL">Si usted no se opone, CÍRCULO PLUS podrá transferir sus datos personales a socios comerciales para que estos puedan contactarle y ofrecerle bienes y/o servicios que resulten de su interés, o bien, para que puedan determinar si usted es sujeto a adquirir los bienes y/o servicios que dichos terceros ofrecen.</p>
                                <p className="pSliderL">En caso de que no desee que sus datos personales sean transferidos por favor envíe un correo electrónico a sanbornsarco@circulo.plus Usted podrá cambiar de opción en cualquier momento.</p>

                                <p className="pSliderTitleL">¿Cómo puede ejercer sus derechos ARCO y/o revocar su consentimiento?</p>
                                <p className="pSliderL">Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en lo sucesivo “derechos Arco”), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Datos Personales de CÍRCULO PLUS a la dirección electrónica sanbornsarco@circulo.plus.</p>
                                <p className='pSliderL'>Su petición deberá ser realizada a través del Formato de Solicitud de Derechos ARCO, el cual debe ser solicitado a la dirección de correo electrónico antes señalada. Para que el Departamento de Protección de Datos de CÍRCULO PLUS pueda darle seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo que es necesario que complete todos los campos indicados en el Formato y lo acompañe con copia de alguna de las identificaciones oficiales vigentes que se señalan en el mismo. En caso de que la información proporcionada sea errónea o insuficiente, o bien, no se acompañen los documentos de acreditación correspondientes, nuestro Departamento de Protección de Datos, dentro de los cinco (5) días hábiles siguientes a la recepción de la solicitud, podrá requerirle que aporte los elementos o documentos necesarios para dar trámite a la misma. Usted contará con diez (10) días hábiles para atender el requerimiento, contados a partir del día siguiente en que lo haya recibido. De no dar respuesta en dicho plazo, se tendrá por no presentada la solicitud correspondiente. Nuestro Departamento de Protección de Datos le comunicará la determinación adoptada, en un plazo máximo de veinte (20) días hábiles contados desde la fecha en que se recibió la solicitud, a efecto de que, si resulta procedente, haga efectiva la misma dentro de los quince (15) días hábiles siguientes a que se comunique la respuesta. Para obtener mayor información sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento contacte al Departamento de Protección de Datos de CÍRCULO PLUS.</p>
                                <p className='pSliderL'>En caso de que su solicitud de cancelación, oposición o revocación sea procedente, según corresponda se dejarán de usar sus datos, para la finalidad que nos hubiere requerido o se eliminarán de nuestros sistemas en términos de lo que establece la legislación de la materia.</p>

                                <p className="pSliderTitleL">¿Cómo puede limitar el uso y/o divulgación de sus datos?</p>
                                <p className="pSliderL">Usted o su representante legal podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la dirección sanbornsarco@circulo.plus En caso de que su Solicitud sea procedente se le registrará en el listado de exclusión propio de CÍRCULO PLUS. Para más información favor de contactar al Departamento de Datos Personales de CÍRCULO PLUS.</p>

                                <p className="pSliderTitleL">Uso de cookies y web beacons</p>
                                <p className='pSliderL'>En CÍRCULO PLUS utilizamos varias tecnologías para mejorar la eficiencia de nuestro sitio web https://circulo.plus/ (en adelante “Sitio Web”) y herramientas tecnológicas, incluyendo su experiencia cuando navega por dichos sitios o usa nuestra red. Entre estas tecnologías se incluye el uso de cookies, que son pequeñas cantidades de información que se almacenan en el navegador utilizado por cada usuario para que el servidor recuerde cierta información que posteriormente pueda utilizar. Esta información permite identificarle y guardar sus preferencias personales para brindarle una mejor experiencia de navegación. </p>
                                <p className="pSliderL">El sitio web también usa web beacons, también conocidos como etiquetas de Internet, etiquetas de píxel y clear GIFs) que solos o en combinación con las cookies, para recopilar información sobre el uso de los sitios web y su interacción con el correo electrónico permiten reconocer información que es procesada en su computadora, como el caso de las cookies, la hora y fecha en que el sitio y sus secciones son visualizados. </p>
                                <p className='pSliderL'>Los diferentes tipos de cookies que utilizamos para obtener datos identificativos y datos relacionados con su comportamiento de compra y uso de servicios en internet son:</p>
                                <ul className="ulDeclaracionesDisc">
                                    <li><span className='bold'>Cookies esenciales:</span> para permitirle un uso adecuado de nuestro sitio web y/o aplicaciones, por lo que las mismas no pueden ser deshabilitadas.</li>
                                    <li><span className='bold'>Cookies para recordar sus preferencias y experiencia:</span> estas cookies nos permiten recordar sus preferencias, opciones de navegación y funciones personalizadas.</li>
                                    <li><span className='bold'>Cookies para objetivos de rendimiento y análisis:</span> podemos usar cookies propias y de terceros para identificar la forma en la cual usted utiliza nuestros Servicios con la finalidad de mejorar su rendimiento y futuros desarrollos.</li>
                                    <li><span className='bold'>Cookies publicitarias propias y de terceros:</span> podemos colocar cookies publicitarias propias y de terceros (como socios comerciales y empresas del grupo) para mostrarle publicidad que consideremos relevante para usted.</li>
                                    <li><span className='bold'>Otros Datos: </span>De la misma forma, como parte de nuestros procesos de mejora de nuestros productos y servicios, en ciertos casos podremos recabar respuestas anónimas a encuestas y requerimientos relacionados con el uso de nuestros productos y servicios. En determinados casos y cuando ello sea necesario podremos aplicar técnicas de anonimización de datos con la finalidad de realizar determinados estudios de carácter predictivo y estadístico a partir de la información generada con motivo del uso de nuestros Servicios.</li>
                                </ul>
                                <p className='pSliderL'>Algunas de las cookies que utilizamos en nuestro Sitio Web son necesarias para permitir su funcionamiento y no se pueden desactivar de nuestros sistemas. </p>
                                <p className="pSliderL">No obstante, usted puede deshabilitar aquellas cookies no esenciales, siguiendo el aplicable de acuerdo con el tipo de navegador que utilice para acceder a nuestro Sitio Web: </p>

                                <ul className="ulDeclaracionesDisc">
                                    <li>Internet Explorer: Herramientas - Opciones de Internet - Privacidad - Configuración. Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.</li>
                                    <li>Firefox: Herramientas - Opciones - Privacidad y Seguridad. Para más información, puedes consultar el soporte de Mozilla o la Ayuda del navegador.</li>
                                    <li>Chrome: Configuración - Verificación de seguridad y/o - Privacidad y seguridad. Para más información, puede consultar el soporte de Google o la Ayuda del navegador.</li>
                                    <li>Safari: Preferencias - Seguridad. Para más información, puede consultar el soporte de Apple o la Ayuda del navegador.</li>
                                    <li>Edge: Configuración - Privacidad, búsqueda y servicios y/o Permisos del sitio. Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.</li>
                                </ul>

                                <p className='pSliderL'>A través de su navegador de internet, usted también puede ver las cookies que están en su dispositivo, y borrarlas según crea conveniente.</p>

                                <p className="pSliderTitleL">Seguridad de su información</p>
                                <p className="pSliderL">CÍRCULO PLUS utiliza medidas administrativas, técnicas, físicas y de gestión razonables para proteger tu información personal contra pérdidas, robos, acceso no autorizado, uso y modificación. Se pueden utilizar diversos protocolos estándares en la industria, en algunas de las transmisiones que nos envías, a fin de cifrar cierta información personal que se transmite durante el proceso de registro y de suscripción.</p>

                                <p className="pSliderTitleL">¿Cómo puede hacernos llegar sus dudas?</p>
                                <p className="pSliderL">Nuestro Departamento de Datos Personales queda a sus órdenes para proporcionarle cualquier información adicional que requiera o, en su caso, para resolver cualquier duda que pudiera surgirle en materia de privacidad y protección de datos personales, para lo que podrá contactarnos a través del correo electrónico sanbornsarco@circulo.plus</p>

                                <p className="pSliderTitleL">INAI</p>
                                <p className="pSliderL">En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) para hacer efectivos sus derechos.</p>
                               
                                <p className="pSliderTitleL">¿Cómo le notificaremos de los cambios que efectuemos al presente Aviso de Privacidad?</p>
                                <p className="pSliderL">CÍRCULO PLUS le notificará de cualquier cambio a su aviso de privacidad a través de la página de internet https://circulo.plus/, en el apartado de Legales, en Aviso de Privacidad.</p>
                             
                                <p className="pSliderL">Fecha de última actualización: Agosto de 2023.</p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export function AvisoPrivacidadCirculoPlus() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Circulo Plus');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {isMobile &&
                isMobile === 'desktop'
                ? <AvisoPrivacidadCirculoPlusDesktop />

                : isMobile === 'movile'
                    ? null
                    : <AvisoPrivacidadCirculoPlusMobile />
            }
        </>
    )
}