import { BASE_PATH_API, TIMEOUT_TIME, VARIANTS_PER_PORTAL } from 'constants/general'
import { decodeStorage } from 'functions/storageBase64'
import axios from 'axios'


export default async function getMisPedidos({ pages = "", days = "" }) {

    const idSesionToken = decodeStorage('session_token')
    const accessToken = "Bearer " + decodeStorage("access_token");

    var dias
    if (days !== '0') {
        dias = `&max_days=${days}`
    }
    else if( days === '0') {
        dias = `&max_days=""`
    }

    var byPagination
    if (pages !== 0)
        byPagination = pages
    else {
        byPagination = 1
    }

    //const apiURL = `${BASE_PATH_API}/app/v1/orders?token=${idSesionToken}&page=${byPagination}${dias}`
    const apiURL = `${BASE_PATH_API}/app/v1/orders?page=${byPagination}${dias}`

    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        }
    };

    return await axios(config)
        .then(res => {
            const {
                // config,
                // headers, 
                status,
                data
            } = res
            //console.log(res)
            // console.log(config)
            //console.log(data)
            // console.log(headers)
            //console.log(status)
            const { data: pedidos, pagination: pagination } = data
            if (status === 200) {
                //console.log(Array.isArray(pedidos))
                if (Array.isArray(pedidos)) {
                    return { pedidos, pagination }
                }
                else {
                    return { 'error': '404', 'response': data }
                }
            }
            return []
        })
        .catch(error => {
            // console.log(error)
            // console.log(error.request)
            // console.log(error.request.status)
            if (error.message === 'Network Error') {
                console.log('Error de conexión')
            }
            else if (error.request) {
                console.log(error.request.responseText)
            }
            if (error.request.status === 0) {
                console.log('request 0')
                return { 'error': 'cors' }
            } else {
                if (error.response.status === 401) {
                    console.log(error.response.data.msg)
                    return error.response.data
                }
                else if (error.response.status === 404) {
                    // console.error(error.response);
                    // console.log('request 404')
                    return { 'error': '404' }
                }
                else if (error.response.status === 440) {
                    console.log('request 440')
                }
                else if (error.response.status >= 500) {
                    console.error(error.response);
                    console.log('request 500')
                }
                else {
                    console.error(error.response.status);
                    console.error(error.response);
                }
            }
        })
}