import React, { useState, useEffect } from 'react';
import Header from 'pages/Header/HeaderSimple';
import Footer from 'pages/Footer/FooterDesktop'
import Loading from 'components/Loading';
import { Link } from 'react-router-dom';
import { removeStorage } from 'functions/storageBase64';
import { useHistory } from 'react-router-dom';
import { postLogoutOldPortal } from 'services/postLogin';
import { dataLayerLogout } from 'functions/dataLayerFunction';
import { useLogout } from 'hooks/useLogin';
import './style.sass';
import ModalPortal from 'components/Modal';

export function ModalCerrarSesion(props) {
    const {
        remvoeAction,
        awaitAction
    } = props

    const elimiar = () => remvoeAction(true);

    const recapacitar = () => awaitAction(true);

    return (
        <>
            <div className="modalRemoveAddress">
                <div className="boxTextos">
                    <h3>¿Deseas cerrar tu sesión?</h3>
                </div>

                <div className="contBtns">
                    <div className="btn blanco" onClick={recapacitar}>Cancelar</div>
                    <div className="btn rojoSanborns" onClick={elimiar}>Aceptar</div>
                </div>
            </div>
        </>
    )

}

export default function Ajustes() {

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'My settings Page');
    }, [])

    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { logOut } = useLogout();

    const backHistory = () => {
        history.go(-1);
    }

    const [showModal, SetShowModal] = useState(false);
    // cerrar modal
    const handleClose = () => SetShowModal(false);

    const modalCerrar = () => {
        SetShowModal(true);
    }

    // cerrar modal y regresar 
    const noRemove = () => {
        SetShowModal(false);
    }

    // si cerrar sesion
    const siRemove = () => {
        SetShowModal(false);
        logOut();
    }

    return (
        <>
            {loading && <Loading />}
            <Header pageClass="hidden" />

            <main className="contenedorAjustes">
                <div className="sectionTitleAjustes">
                    <button onClick={backHistory}> Mis Ajustes</button>
                </div>
                <ul className="listaExternaAjustes">
                    <Link to="/mi-cuenta/borrar-cuenta" className="enlaces">
                        <li aria-label="Eliminar mi cuenta"><span className="nombre">Eliminar mi cuenta</span> <span className="icono"></span></li>
                    </Link>
                    <Link className="enlaces">
                        <li onClick={modalCerrar} aria-label="Cerrar sesion" className="logout"><span className="nombre">Cerrar sesión</span> <span className="icono"></span></li>
                    </Link>
                </ul>
            </main>
            {/* <Footer /> */}

            {/* mostrar modal estas seguro que deseas cancelar*/}
            {(showModal) &&
                <ModalPortal type="modalSB" onClose={handleClose} closeBody ="modalSinHeader" >
                    <ModalCerrarSesion remvoeAction={siRemove} awaitAction={noRemove} />
                </ModalPortal>
            }

        </>
    )
}