import React from 'react'
import {Link} from 'react-router-dom'

export default function ErrorPixelProductsServices () {
    return(
        <div className="errorProductServices">
            <img src="/img/empty_wish_list.svg" alt="Página no encontrada" width="450" height="450" loading="lazy"/>
            <p className="titleError">No tienes productos vistos </p>
            <p className="suggestion">Navega un poco más.</p>
            <a className="btn rojo" href={`/`} title="Ir al inicio">Ver Productos</a>
        </div>
    )
}