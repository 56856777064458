import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from 'pages/Header/HeaderSimple';
import './style.sass';

export default function ServicioCliente() {
    const history = useHistory()

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Client Section Services');
        window.scrollTo(0, 0)
    }, [])

    const backHistory = () => {
        history.go(-1)
    }

    return (
        <>
            <Header pageClass="hidden" />
            <div className="sectionTitleServicioCliente">
                <button onClick={backHistory}>¿Necesitas ayuda?</button>
            </div>

            <div className="opcionesCliente">
                <ul className="listaExterna">
                    <Link to="/como-comprar" className="enlaces">
                        <li className="telefono"><span className="nombre">¿Cómo comprar?</span> <span className="icono"></span></li>
                    </Link>

                    <Link to="/preguntas-frecuentes" className="enlaces">
                        <li className="telefono"><span className="nombre">Preguntas Frecuentes</span> <span className="icono"></span></li>
                    </Link>

                    <Link to="/sitio-de-interes" className="enlaces">
                        <li className="telefono"><span className="nombre">Sitios de Interés</span> <span className="icono"></span></li>
                    </Link>
                    <Link to="/departamentos" className="enlaces">
                        <li className="telefono"><span className="nombre">Categorías</span> <span className="icono"></span></li>
                    </Link>
                </ul>
            </div>
        </>
    )
}