import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import CurrencyFormat from 'react-currency-format'
import ModalCyR from 'components/Modal'
import Toast from 'components/Toast'
import { decodeStorage, encodeStorage, removeStorage } from 'functions/storageBase64'
import { useClicyRecoge } from 'hooks/useClicyRecoge'
import { BASE_PATH_CAJA } from 'constants/general'
import { repareTextSimple } from 'functions/repareDataService'
import { useToken } from 'hooks/useToken'
import { repareTextHtml } from 'functions/repareDataService';


export function ModuleClickRecoge(props) {
    const {
        cyr,
        id,
        title,
        dataImage,
        precioLista,
        // discount,
        skuChildren,
        skuChildrenFF,
        store,
        stock,
        // categories,
        status,
        size_color,
        colorCyR,
        tallaCyR,
        is_store_only,
        cex
    } = props

    const profileData = decodeStorage('profileData')
    // const idCarrito = decodeStorage('idCarrito')
    const session_token = decodeStorage('session_token')
    const preCyR = decodeStorage('preCyR');
    const history = useHistory()
    const refreshCyR = useToken()
    const domain = window.location.hostname

    //console.log('ModuleClickRecoge: ', props)

    const {
        dataCyR, dataToast, dataSucursales, errorMessage,
        showCyR, handleClose, updateSKU, updateID, handleToast
    } = useClicyRecoge(id, dataImage, skuChildren, store, size_color, skuChildrenFF)

    updateID(id)
    updateSKU(skuChildrenFF)

    const [loadingCyR ,setLoadingCyR] = useState(false)
    const [loadingClick ,setLoadingClick] = useState(false)

    const [idSuc, setIdSuc] = useState()
    const btnIrSucursal = (id_sucursal) => {
        if (session_token) {
            refreshCyR.forceToken(true)
            setIdSuc(id_sucursal)
            setLoadingClick(true)
            setTimeout(function(){
                setLoadingClick(false)
            },10000)
        } else {
            let sku_hijo;
            if (skuChildrenFF) { sku_hijo = skuChildrenFF; } else { sku_hijo = 0; }
            
            encodeStorage('preCyR', {id: id, id_sucursal: id_sucursal, skuProd: sku_hijo })
            history.push(`/login?redirect=${window.location.href}`)
        }
    }
    
    useEffect(()=>{
        if(preCyR){
            if(preCyR?.id === id){
                if(session_token) {
                    setTimeout(function(){
                        setLoadingCyR(true)
                    },150)
                    refreshCyR.forceToken(true);
                    setIdSuc(preCyR?.id_sucursal)
                }
            }else{
                removeStorage('preCyR')
                setLoadingCyR(false)
            }
        }
    },[preCyR])

    useEffect(() => {
        if (idSuc) {
            if (refreshCyR.newToken) {
                let sku_hijo
                if(preCyR){
                    if(preCyR?.skuProd){
                        sku_hijo = preCyR?.skuProd
                    }
                }
                else{
                    if (skuChildrenFF) {sku_hijo = skuChildrenFF; } else { sku_hijo = 0; }
                }
                let producto_id
                if (id) { producto_id = id }
                let cliente_id
                if (profileData) { cliente_id = profileData.idClaro }

                let urlCajaCyRB64 = `${cliente_id}/${producto_id}/${idSuc}/${sku_hijo}`
                let urlCajaCyR = `${BASE_PATH_CAJA.replace('/direcciones', '/click-recoge')}/${urlCajaCyRB64}`

                window.localStorage.setItem('CSC-KEY', window.btoa(decodeStorage('session_token')))
                window.localStorage.setItem('CSC-CR', window.btoa(urlCajaCyRB64))

                setTimeout(function () {
                    removeStorage('preCyR')
                   window.location.href = urlCajaCyR
                   //console.log("urlCajaCyR: ", urlCajaCyR)
                }, 300)

            }
        }
    }, [refreshCyR.newToken])


    const activeCard = (idCard) => {
        if (document.querySelectorAll('.cardCyR.active').length >= 1) {
            document.querySelector('.cardCyR.active').classList.remove('active')
        }
        if (document.querySelectorAll(`.cardCyR.card_id${String(idCard)}.active`).length === 0) {
            document.querySelector(`.card_id${String(idCard)}`).classList.add('active')
        }
    }

    const [searchCyR, setSearchCyR] = useState('')
    const [resultFilter, setResultFilter] = useState(false)
    let respuesta = []
    const updateSearch = (e) => {
        let searchText = e.target.value
        setSearchCyR(searchText)
        if (searchText.length >= 1) {
            setResultFilter(true)
        } else {
            setResultFilter(false)
        }
    }

   //const [dia, setDia] = useState(false);
   const [recoge, setRecoge] = useState(false);
   const hora = new Date();
   useEffect(() => {
       //if (hora.getHours() < 17) {
       //    setDia("hoy");
       //}else{
       //    setDia("mañana")
       //}
       const horaInicial = new Date(hora.getFullYear(), hora.getMonth(), hora.getDate(), 8, 0, 0);
       const horaFin = new Date(hora.getFullYear(), hora.getMonth(), hora.getDate(), 20, 0, 0);

       if (hora.getTime() > horaInicial.getTime() && hora.getTime() < horaFin.getTime()) {
           setRecoge("2 horas");
       }
       else {
           setRecoge("tienda");
       }
   }, [skuChildren, id])

    //skuChildren, id
    // console.log(" fecha recogida--> ", dataCyR.fechaDeRecogida, " dia--> ", dia)

    return (
        <>
            {cyr || is_store_only ?
                !cex &&
                stock >= 1 && status &&
                <>
                    {/*<div className="btnCyR">
                        {/* <p>Recoge el <span className="dateTextCyR">{dataCyR.fechaDeRecogida}</span> </p> 
                        <p>Recoge <span className="dateTextCyR">GRATIS en {recoge}</span> </p>
                        {/* <span className="link" onClick={showCyR}>Ver tiendas</span> 
                    </div>*/}

                    {dataCyR.showModal &&
                        <ModalCyR onClose={handleClose} type={dataCyR.isMobile === "desktop" ? "" : "full"} title={dataCyR.isMobile === "desktop" ? "" : "Elige tu tienda Sanborns"}>
                            <div className="containerCyR">
                                <div className="cardProductDetailsCyR">
                                    <img src={dataCyR.dataImagenNew.dataImage[0].thumbnail} alt={repareTextHtml(title)} width="100" height="100" loading="lazy" />
                                    <div>
                                        <p className="h1">{repareTextHtml(title)}</p>
                                        {size_color &&
                                            size_color.length >= 1 &&
                                            colorCyR &&
                                            <p>Color: {colorCyR}</p>
                                        }
                                        {size_color &&
                                            size_color.length >= 1 &&
                                            tallaCyR &&
                                            <p>Talla: {tallaCyR}</p>
                                        }
                                        <CurrencyFormat
                                            value={precioLista}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            renderText={value => <p><span className="strong">{value}</span> precio unitario</p>}
                                        />
                                        <p>Cantidad: <span className="strong">1</span></p>
                                    </div>
                                </div>
                                <div className="listadoCR">
                                    <div className="contSearchCyR">
                                        <input
                                            className="searchCyR"
                                            type="search"
                                            value={searchCyR}
                                            onChange={updateSearch}
                                            placeholder="Buscar por CP, Ciudad, Estado o Tienda"
                                            disabled={!dataSucursales ? 'disabled' : ''}
                                        />
                                    </div>
                                    <div className="contentCardCyR">
                                        {dataSucursales &&
                                            dataSucursales.filter((data) => {
                                                if (searchCyR == null || searchCyR == '') {
                                                    return data
                                                }
                                                else if (
                                                    repareTextSimple(String(data.name).toLowerCase()).includes(repareTextSimple(String(searchCyR).toLowerCase())) ||
                                                    repareTextSimple(String(data.state).toLowerCase()).includes(repareTextSimple(String(searchCyR).toLowerCase())) ||
                                                    repareTextSimple(String(data.suburb).toLowerCase()).includes(repareTextSimple(String(searchCyR).toLowerCase())) ||
                                                    repareTextSimple(String(data.zip_code).toLowerCase()).includes(repareTextSimple(String(searchCyR).toLowerCase()))
                                                ) {
                                                    respuesta.push(data)
                                                    return data
                                                }
                                            }).map((sucursalData, i) => {

                                                return (
                                                    <>
                                                        <div className={`cardCyR card_id${sucursalData.id}`} onClick={() => activeCard(sucursalData.id)} key={sucursalData.id}>
                                                            <span className="clircleRadio"></span>
                                                            <p className="h2">{sucursalData.name}</p>
                                                            {sucursalData.latitude && sucursalData.longitude &&
                                                                <a href={`https://maps.google.com?q=${sucursalData.latitude},${sucursalData.longitude}`} target="_blank" rel="noopener noreferrer" className="link showLocation">Ver ubicación</a>
                                                                //<a href={`https://maps.google.com?q=Sears+${sucursalData.name}`} target="_blank" rel="noopener noreferrer">ver ubicación</a>
                                                                //<a href={`https://www.google.com/maps/place/Sears+${sucursalData.name}/@${sucursalData.latitude},${sucursalData.longitude},17z/`} target="_blank" rel="noopener noreferrer">ver ubicación</a>
                                                            }
                                                            <p>{sucursalData.street} {sucursalData.number} </p>
                                                            <p>{sucursalData.suburb} {sucursalData.zip_code} {sucursalData.state}</p>
                                                            <p>Horario: {sucursalData.schedule}</p>
                                                            <p>Teléfono: <a href={`tel:${sucursalData.phone.lada}${sucursalData.phone.number}`} className="link">{sucursalData.phone.lada} {sucursalData.phone.number}</a></p>
                                                            <div className="btn rojo" onClick={() => btnIrSucursal(sucursalData.id)} >Recoger aqui</div>
                                                        </div>
                                                    </>

                                                )
                                            })
                                        }
                                        {resultFilter &&
                                            respuesta.length === 0 &&
                                            <>
                                                <div className="errorSearch">
                                                    <p className="h2">No se encontraron resultados, <br /> intenta otra búsqueda</p>
                                                    <p>Puedes buscar por CP, ciudad, estado o tienda</p>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            
                        </ModalCyR>
                    }
                    {dataToast.toast &&
                        <Toast
                            type={dataToast.messageToast.type}
                            time={dataToast.messageToast.time}
                            onClose={handleToast}
                        >
                            {dataToast.messageToast.textMessage}
                        </Toast>
                    }
                </>
                : null
            }
        </>
    )
}