import React from 'react';
import './style.sass';
import CurrencyFormat from 'react-currency-format';
import { Link } from "react-router-dom";
import StarsFive from 'components/StarsFive';
import { NO_IMAGE, HIDDEN_DISCOUNT } from 'constants/general';
import { repareImage, repareTextHtml, repareTitleSEO } from 'functions/repareDataService'

export function PromotionProduct({ promotionProduct }) {
    const {
        image,
        title,
        price,
        sale_price,
        product_id
        //call_to_action_message
    } = promotionProduct

    console.log('promotionProduct D: ', promotionProduct)

    const cantidad_descuento = price - sale_price;
    const porcentaje = (cantidad_descuento / price) * 100;
    const porcentaje_entero = Math.round(porcentaje);

    let newSeo = repareTitleSEO(title)
    let newImages
    if(image){
        if(image.length >=1){
            newImages =  repareImage(image)
        }
    }

    return (
        <Link className="contentromotionProduct" to={`/producto/${product_id}/${newSeo}`} >
            <div className="container">
                <img loading="lazy" src={newImages ? newImages+"?scale=100" : NO_IMAGE} alt={title} width="80" height="80" />
                <div className="contTextOferta">
                    <p>{title}</p>
                    <div className="contPrice">
                        <div className="precios">
                            <CurrencyFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <p className="textUnderline">{value} <span className="mxn">MXN</span></p>} />
                            <CurrencyFormat value={sale_price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <p className="sale">{value} <span className="mxn">MXN</span></p>} />
                            {/* {porcentaje_entero != 0  && porcentaje_entero >= HIDDEN_DISCOUNT && <span className="etiqueta-descuento"><p> {porcentaje_entero}%</p></span>} */}
                        </div>
                        <div className="btn rojo">Comprar</div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export function PromotionProductNew( props ) {

    const {
        promotionProduct,
        styleView
    } = props

    const {
        images,
        title,
        title_seo,
        price,
        sale_price,
        id
        //call_to_action_message
    } = promotionProduct

    const cantidad_descuento = price - sale_price;
    const porcentaje = (cantidad_descuento / price) * 100;
    const porcentaje_entero = Math.round(porcentaje);
    let newImages;
    if(images){
        if(images.length >=1){
            newImages =  repareImage(images[0].url);
        }
    }


    return (
        id 
            ?<Link className="contentromotionProduct" to={`/producto/${id}/${title_seo}`} >
                <div className="tituloPB">
                    <span>OFERTA DEL DÍA</span>
                </div>
                <div className="container">
                    <img loading="lazy" src={newImages ? newImages+"?scale=100" : NO_IMAGE} alt={title} width="80" height="80" />
                    <div className="contTextOferta">
                        <p>{repareTextHtml(title)}</p>
                        <div className="contPrice">
                            <div className="precios">
                                <CurrencyFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <p className="textUnderline">{value} <span className="mxn">MXN</span></p>} />
                                <CurrencyFormat value={sale_price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <p className="sale">{value} <span className="mxn">MXN</span></p>} />
                                {/* {porcentaje_entero != 0  && porcentaje_entero >= HIDDEN_DISCOUNT && <span className="etiqueta-descuento"><p> {porcentaje_entero}%</p></span>} */}
                            </div>
                            <div className="btn rojo">Comprar</div>
                        </div>
                    </div>
                </div>
            </Link>
            : null
    )
}


export function PromotionProductMobile({ promotionProduct }) {
    const {
        image,
        title,
        price,
        sale_price,
        product_id
        //call_to_action_message
    } = promotionProduct

    // console.log('promotionProduct  ',promotionProduct)

    const cantidad_descuento = price - sale_price;
    const porcentaje = (cantidad_descuento / price) * 100;
    const porcentaje_entero = Math.round(porcentaje);

    let newSeo = repareTitleSEO(title)
    let newImages
    if(image){
        if(image.length >=1){
            newImages =  repareImage(image)
        }
    }

    return (
        <div className="contenedorTitlePromotion">
            <div className="titlePromotion">
                <p className="h2">Oferta del día</p>
                <div className="borderT"></div>
            </div>

            <Link className="contentromotionProduct" to={`/producto/${product_id}/${newSeo}`} >
                <div className="container">
                    <img loading="lazy" src={newImages ? newImages+"?scale=100" : NO_IMAGE} alt={title} width="80" height="80" />
                    <div className="contTextOferta">
                        <p className="titleBombshellProduct">{title}</p>
                        <div className="contPrice">
                            <div className="precios">
                                <CurrencyFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <p className="textUnderline">{value} <span className="mxn">MXN</span></p>} />
                                <CurrencyFormat value={sale_price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <p className="sale">{value} <span className="mxn">MXN</span></p>} />
                                {/* {porcentaje_entero != 0  && porcentaje_entero >= HIDDEN_DISCOUNT && <span className="etiqueta-descuento"><p> {porcentaje_entero}%</p></span>} */}
                            </div>
                            <div className="btn rojo">Comprar</div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}


export function PromotionProductMobileNew( props ) {
    const {
        promotionProduct,
        styleView
    } = props

    const {
        images,
        title,
        title_seo,
        price,
        sale_price,
        shipping,
        reviews,
        features,
        id 
        //call_to_action_message
    } = promotionProduct

    // console.log('promotionProduct M: ', promotionProduct)

    const cantidad_descuento = price - sale_price;
    const porcentaje = (cantidad_descuento / price) * 100;
    const porcentaje_entero = Math.round(porcentaje);

    let newImages
    if(images){
        if(images.length >=1){
            newImages =  repareImage(images[0].url)
        }
    }

    return (
        id
            ?
                <div className="contenedorTitlePromotion">
                    <div className="titlePromotion">
                        <p className="h2">Oferta del día</p>
                        {/*<p className="borderT"></p> */}
                    </div>

                    <Link className="contentromotionProduct" to={`/producto/${id}/${title_seo}`} >
                        <div className="container">
                            <img loading="lazy" src={newImages ? newImages+"?scale=100" : NO_IMAGE} alt={title} width="80" height="80" />
                            <div className="contTextOferta">
                                <p className="titleBombshellProduct">{title}</p>
                                {/* {reviews
                                ? <StarsFive score={reviews.score} comments={reviews.total_reviews === 0 ? null : reviews.total_reviews} />
                                : null
                            } */}
                                <div className="contPrice">
                                    <div className="precios">
                                        <CurrencyFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <p className="textUnderline">{value} {/*<span className="mxn">MXN</span>*/}</p>} />
                                        <CurrencyFormat value={sale_price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <p className="sale">{value} <span className="mxn">MXN</span></p>} />
                                        {/* {porcentaje_entero != 0  && porcentaje_entero >= HIDDEN_DISCOUNT && <span className="etiqueta-descuento"><p> {porcentaje_entero}%</p></span>} */}
                                    </div>
                                    <div className="btn rojo">Comprar</div>
                                </div>
                                {/* {shipping &&
                                shipping.is_free
                                    ? <p className="envioGratis" aria-label="Envío gratis">Envío gratis</p>
                                    : null
                            } */}
                                {/* {features &&
                                features.fulfillment &&
                                <p className="fullFilment" aria-label="Express">Express</p>
                            } */}
                            </div>
                        </div>
                    </Link>
                </div>
            : null
    )
}